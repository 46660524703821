import React, { useMemo } from "react";
import UserGearIconSVG from "assets/svg/user-gear-icon";
import GraduationCapIconSVG from "assets/svg/graduation-cap-icon";
import ChatUserIconSVG from "assets/svg/chat-user-icon";
import BriefCaseExperienceIconSVG from "assets/svg/brief-case-icon";
import { Profile1 } from "constants/images";
import { socialTemplate } from "utils/constant";
import moment from "moment";
import _ from "lodash";

export default function TemplateThreeFullPageOne(props) {
  const {
    previewImage,
    color = {},
    details = {},
    personal = {},
    social = {},
    primaryColor,
    tertiaryColor,
  } = props;

  const activeColor = useMemo(() => {
    if (color.label === "Voilet + Pink") {
      return tertiaryColor;
    }
    return primaryColor;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  const socMed = useMemo(() => {
    let arrays = Object.entries(social);
    return arrays.map((obj) => {
      let key = obj[0];
      let value = obj[1];
      return { key, value };
    });
  }, [social]);

  // 1.371

  return (
    <div className="w-full">
      <div
        className="w-full bg-resumeTempThreePrimary h-full"
        style={{ minHeight: "1248px" }}>
        <div className="w-full h-full">
          <div className="w-full h-full grid relative grid-row-auto">
            <div className="w-full h-full px-13.5" style={{ minHeight: "1202px" }}>
              {/* page number */}
              <div className="w-full relative">
                <div className="w-8 h-8 bg-resumeTempThreeSecondary template-three-page-number">
                  <p className="text-xl text-resumeTempThreeTextSecondary tracking-resumeThree leading-3.2 font-semibold font-template-three">
                    01
                  </p>
                </div>
                {/* absolute */}
                <div className="absolute right-1 top-3.7 font-template-three w-35">
                  <p
                    className="text-base text-right leading-6"
                    style={{ color: activeColor }}>
                    {(personal.occupations || []).map((val, index) => {
                      const count = personal.occupations.length;
                      if (index >= count - 1 && count !== 1) {
                        return " and " + val;
                      } else {
                        if (index >= count - 2) {
                          return val;
                        } else {
                          return val + ", ";
                        }
                      }
                    })}
                  </p>
                </div>
              </div>
              {/* body */}
              <div className="w-full mt-3.4">
                <div className="w-full flex flex-row space-x-9.5">
                  {/* left */}
                  <div className="w-55">
                  {
                    personal?.profile_image &&
                    <>
                      <div className="w-55 h-55 rounded-full relative overflow-hidden">
                        {personal.hasOwnProperty("profile_image") ? (
                          <img
                            src={previewImage ?? personal.profile_image}
                            className="w-full h-full object-cover object-center"
                            alt="resume-profile"
                          />
                        ) : (
                          <img
                            alt="milestone-profile"
                            src={Profile1}
                            className="w-full h-full object-cover object-center"
                          />
                        )}
                      </div>
                    </>
                  }
                    {/* content */}
                    <div className="w-full mt-5.4 px-1.4">
                      {details.hasOwnProperty("bio") && details.bio && (
                        <div className="w-full border-bottom-resume-three">
                          {/* title */}
                          <p className="uppercase text-sm text-resumeTempThreeTextPrimary font-medium tracking-resumeThreeWide">
                            bio
                          </p>
                          {/* content */}
                          <div className="w-full mt-2.5 mb-3">
                            <p className="text-white text-6xs leading-4.5 font-light self-stretch">
                              {details.bio}
                            </p>
                          </div>
                        </div>
                      )}
                      {/*  */}
                      <div className="w-full mt-5">
                        {/* title */}
                        <p className="uppercase text-sm text-resumeTempThreeTextPrimary font-medium tracking-resumeThreeWide">
                          more information
                        </p>
                        <div className="w-full mt-3 border-bottom-resume-three">
                          <div className="w-full grid gap-y-3.5 mb-3.5">
                            {/* Date of Birth */}
                            {details.hasOwnProperty("birthdate") &&
                              details.birthdate && (
                                <div className="w-full flex flex-row items-center space-x-1.5 text-white">
                                  <div className="w-21.5">
                                    <p className="font-bold text-1xs uppercase leading-3.4">
                                      Date of Birth
                                    </p>
                                  </div>
                                  <div style={{ width: "calc(100% - 91px)" }}>
                                    <p className="font-light text-1xs leading-4.5">
                                      {moment(details.birthdate).format(
                                        "DD MMMM YYYY"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              )}
                            {/* Birth Location */}
                            {details.hasOwnProperty("birth_location") &&
                              details.birth_location && (
                                <div className="w-full flex flex-row items-center space-x-1.5 text-white">
                                  <div className="w-21.5">
                                    <p className="font-bold text-1xs uppercase leading-3.4">
                                      Birth Location
                                    </p>
                                  </div>
                                  <div style={{ width: "calc(100% - 91px)" }}>
                                    <p className="font-light text-1xs leading-4.5">
                                      {details.birth_location}
                                    </p>
                                  </div>
                                </div>
                              )}

                            {/*Civil Status */}
                            {details.hasOwnProperty("civil_status") &&
                              details.civil_status && (
                                <div className="w-full flex flex-row items-center space-x-1.5 text-white">
                                  <div className="w-21.5">
                                    <p className="font-bold text-1xs uppercase leading-3.4">
                                      Civil Status
                                    </p>
                                  </div>
                                  <div style={{ width: "calc(100% - 91px)" }}>
                                    <p className="font-light text-1xs leading-4.5">
                                      {details.civil_status}
                                    </p>
                                  </div>
                                </div>
                              )}
                            {/* Children */}
                            {details.hasOwnProperty("children") &&
                              details.children !== 0 && (
                                <div className="w-full flex flex-row items-center space-x-1.5 text-white">
                                  <div className="w-21.5">
                                    <p className="font-bold text-1xs uppercase leading-3.4">
                                      Children
                                    </p>
                                  </div>
                                  <div style={{ width: "calc(100% - 91px)" }}>
                                    <p className="font-light text-1xs leading-4.5">
                                      {details.children}
                                    </p>
                                  </div>
                                </div>
                              )}

                            {/* Other Name */}
                            {details.hasOwnProperty("other_name") &&
                              details.other_name && (
                                <div className="w-full flex flex-row items-center space-x-1.5 text-white">
                                  <div className="w-21.5">
                                    <p className="font-bold text-1xs uppercase leading-3.4">
                                      Other Name
                                    </p>
                                  </div>
                                  <div style={{ width: "calc(100% - 91px)" }}>
                                    <p className="font-light text-1xs leading-4.5">
                                      {details.other_name}
                                    </p>
                                  </div>
                                </div>
                              )}
                            {/*Hair Color */}
                            {details.hasOwnProperty("hair_color") &&
                              details.hair_color && (
                                <div className="w-full flex flex-row items-center space-x-1.5 text-white">
                                  <div className="w-21.5">
                                    <p className="font-bold text-1xs uppercase leading-3.4">
                                      Hair Color
                                    </p>
                                  </div>
                                  <div style={{ width: "calc(100% - 91px)" }}>
                                    <p className="font-light text-1xs leading-4.5">
                                      {details.hair_color}
                                    </p>
                                  </div>
                                </div>
                              )}
                            {/* Eye Color */}
                            {details.hasOwnProperty("eye_color") &&
                              details.eye_color && (
                                <div className="w-full flex flex-row items-center space-x-1.5 text-white">
                                  <div className="w-21.5">
                                    <p className="font-bold text-1xs uppercase leading-3.4">
                                      Eye Color
                                    </p>
                                  </div>
                                  <div style={{ width: "calc(100% - 91px)" }}>
                                    <p className="font-light text-1xs leading-4.5">
                                      {details.eye_color}
                                    </p>
                                  </div>
                                </div>
                              )}
                            {/* Height */}
                            {details.hasOwnProperty("height") &&
                              details.height && (
                                <div className="w-full flex flex-row items-center space-x-1.5 text-white">
                                  <div className="w-21.5">
                                    <p className="font-bold text-1xs uppercase leading-3.4">
                                      Height
                                    </p>
                                  </div>
                                  <div style={{ width: "calc(100% - 91px)" }}>
                                    <p className="font-light text-1xs leading-4.5">
                                      {details.height}
                                    </p>
                                  </div>
                                </div>
                              )}

                            {/* Phone  */}
                            {details.hasOwnProperty("phone_number") &&
                              details.phone_number && (
                                <div className="w-full flex flex-row items-center space-x-1.5 text-white">
                                  <div className="w-21.5">
                                    <p className="font-bold text-1xs uppercase leading-3.4">
                                      Phone
                                    </p>
                                  </div>
                                  <div style={{ width: "calc(100% - 91px)" }}>
                                    <p className="font-light text-1xs leading-4.5">
                                      {details.phone_number}
                                    </p>
                                  </div>
                                </div>
                              )}
                            {/* Email Address  */}
                            {details.hasOwnProperty("email") &&
                              details.email && (
                                <div className="w-full flex flex-row items-center space-x-1.5 text-white">
                                  <div className="w-21.5">
                                    <p className="font-bold text-1xs uppercase leading-3.4">
                                      Email Address
                                    </p>
                                  </div>
                                  <div style={{ width: "calc(100% - 91px)" }}>
                                    <p className="font-light text-1xs leading-4.5">
                                      {details.email}
                                    </p>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      {/* end */}
                    </div>
                  </div>
                  {/* right */}
                  <div style={{ width: "calc(100% - 258px)" }}>
                    <div className="w-full">
                      {/* Name */}
                      <div className="w-350">
                        <h1 className="font-template-three text-white text-6.5xl font-medium leading-62">
                          I’m {details.username}
                        </h1>
                      </div>
                      {/* Other info */}
                      <div className="w-full mt-3.7">
                        <div className="flex flex-row gap-x-1.5 text-white items-center font-medium">
                          {personal.hasOwnProperty("name") && personal.name && (
                            <p className="text-6xs leading-4">
                              {personal.name}
                            </p>
                          )}
                          {personal.hasOwnProperty("name") &&
                            personal.name &&
                            personal.hasOwnProperty("gender") &&
                            personal.gender && (
                              <span className="text-6xs opacity-30">
                                &#8226;
                              </span>
                            )}
                          {personal.hasOwnProperty("gender") &&
                            personal.gender && (
                              <p className="text-6xs leading-4">
                                {personal.gender}
                              </p>
                            )}
                          {personal.hasOwnProperty("gender") &&
                            personal.gender &&
                            personal.hasOwnProperty("pronoun") &&
                            personal.pronoun && (
                              <span className="text-6xs opacity-30">
                                &#8226;
                              </span>
                            )}
                          {personal.hasOwnProperty("pronoun") &&
                            personal.pronoun && (
                              <p className="text-6xs leading-4">
                                {personal.pronoun}
                              </p>
                            )}
                          {personal.hasOwnProperty("pronoun") &&
                            personal.pronoun && (
                              <p className="text-6xs leading-4">
                                {personal.pronoun}
                              </p>
                            )}
                          {personal.hasOwnProperty("address") &&
                            personal.address && (
                              <>
                                <span className="text-6xs opacity-30">
                                  &#8226;
                                </span>
                                <p className="text-6xs leading-4">
                                  {personal.address}
                                </p>
                              </>
                            )}
                        </div>
                      </div>
                      {/* extra */}
                      <div className="w-full mt-2">
                        <div className="flex flex-row flex-wrap gap-2 text-1xs font-bold text-white">
                          <span className="uppercase p-1.5 bg-resumeTempThreeSecondary rounded-sm">
                            Agency Company
                          </span>
                          {personal.hasOwnProperty("profile_type") &&
                            personal.profile_type.length > 0 && (
                              <>
                                {(personal.profile_type || []).map(
                                  (value, index) => {
                                    return (
                                      <span
                                        className="uppercase p-1.5 bg-resumeTempThreeSecondary rounded-sm"
                                        key={index}>
                                        {value}
                                      </span>
                                    );
                                  }
                                )}
                              </>
                            )}
                        </div>
                      </div>
                      {/*  */}
                      {((details.hasOwnProperty("occupations") &&
                        !_.isEmpty(details.occupations)) ||
                        details.education ||
                        details.training ||
                        details.experience) && (
                        <div className="w-full mt-14">
                          {/* title */}
                          <div className="w-full">
                            <p className="uppercase text-sm text-resumeTempThreeTextPrimary font-medium tracking-resumeThreeWide">
                              Featured Milestones
                            </p>
                          </div>
                          <div className="w-full mt-3">
                            <div className="w-full grid gap-y-7">
                              {/*  */}
                              <div className="w-full">
                                <div className="w-full flex flex-row items-center space-x-3.5">
                                  <div className="w-5.5 h-5.5">
                                    <UserGearIconSVG
                                      className="w-5.5 h-5.5"
                                      fill={activeColor}
                                    />
                                  </div>
                                  <div style={{ width: "calc(100% - 36px)" }}>
                                    <p className="font-template-three text-base font-bold text-white leading-6 text-opacity-90">
                                      Abilities / Skills / Specialties
                                    </p>
                                  </div>
                                </div>
                                {/* content */}
                                <div className="w-full mt-2.5">
                                  {personal.hasOwnProperty("occupations") &&
                                    personal.occupations.length > 0 && (
                                      <div className="flex flex-row flex-wrap gap-2 text-1xs font-bold text-white">
                                        {(personal.occupations || []).map(
                                          (val, index) => {
                                            return (
                                              <span
                                                className="uppercase p-1.5 bg-resumeTempThreeSecondary rounded-sm"
                                                key={index}>
                                                {val}
                                              </span>
                                            );
                                          }
                                        )}
                                      </div>
                                    )}
                                </div>
                              </div>
                              {/*  */}
                              <div className="w-full">
                                <div className="w-full flex flex-row items-center space-x-3.5">
                                  <div className="w-5.5 h-5.5">
                                    <GraduationCapIconSVG
                                      className="w-5.5 h-5.5"
                                      fill={activeColor}
                                    />
                                  </div>
                                  <div style={{ width: "calc(100% - 36px)" }}>
                                    <p className="font-template-three text-base font-bold text-white leading-6 text-opacity-90">
                                      Education
                                    </p>
                                  </div>
                                </div>
                                {/* content */}
                                <div className="w-full mt-2 text-white text-opacity-60">
                                  <p className="font-light text-6xs leading-4 self-stretch">
                                    {details.education}
                                  </p>
                                </div>
                              </div>
                              {/*  */}
                              <div className="w-full">
                                <div className="w-full flex flex-row items-center space-x-3.5">
                                  <div className="w-5.5 h-5.5">
                                    <ChatUserIconSVG
                                      className="w-5.5 h-5.5"
                                      fill={activeColor}
                                    />
                                  </div>
                                  <div style={{ width: "calc(100% - 36px)" }}>
                                    <p className="font-template-three text-base font-bold text-white leading-6 text-opacity-90">
                                      Training
                                    </p>
                                  </div>
                                </div>
                                {/* content */}
                                <div className="w-full mt-2 text-white text-opacity-60">
                                  <p className="font-light text-6xs leading-4 self-stretch">
                                    {details.training}
                                  </p>
                                </div>
                              </div>
                              {/*  */}
                              <div className="w-full">
                                <div className="w-full flex flex-row items-center space-x-3.5">
                                  <div className="w-5.5 h-5.5">
                                    <BriefCaseExperienceIconSVG
                                      className="w-5.5 h-5.5"
                                      fill={activeColor}
                                    />
                                  </div>
                                  <div style={{ width: "calc(100% - 36px)" }}>
                                    <p className="font-template-three text-base font-bold text-white leading-6 text-opacity-90">
                                      Experience
                                    </p>
                                  </div>
                                </div>
                                {/* content */}
                                <div className="w-full mt-2 text-white text-opacity-60">
                                  <p className="font-light text-6xs leading-4 self-stretch">
                                    {details.experience}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* end right */}
                </div>
              </div>
            </div>
            {/* footer */}
            <div className="w-full flex items-end">
              <div className="w-full h-11.5 bg-resumeTempThreeTextPrimary bg-opacity-20 relative">
                <div className="w-full h-full flex flex-row justify-center items-center space-x-3 text-resumeTempThreeTextPrimary">
                  {(socMed || []).map((obj, index) => {
                    const SocialIcon = socialTemplate[obj.key];
                    if (SocialIcon) {
                      return (
                        <div
                          className="flex items-center space-x-1.5"
                          key={index}>
                          <SocialIcon
                            className="text-xl"
                            style={{ color: activeColor }}
                          />
                          <p className="text-4xs leading-4">{obj.value}</p>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
    </div>
  );
}
