import React, { useEffect, useState } from "react";
import Layout from "layouts/layout";
import FAQItem from "components/faq/faq-item";
import { useParams } from "react-router-dom";
import { FaCircle, FaFacebookF, FaTwitter, FaShareAlt } from "react-icons/fa";
import Button from "components/button/button";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function FAQDetails() {
  const { key } = useParams();
  const { http } = global.services;
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({});
  const [faqs, setFaqs] = useState([]);

  const getFaqsDetails = async () => {
    setLoading(true);
    try {
      const result = await http.get(`public/faqs/${key}`);
      const res = result.data;
      if (res) {
        setDetails(res);
      }
    } catch (err) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const getRelatedFaqs = async () => {
    try {
      const result = await http.get("public/faqs/related");
      const res = result.data;
      console.log("res => ", res);
      if (res) {
        setFaqs(res);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    } finally {
    }
  };

  useEffect(() => {
    getFaqsDetails();
    getRelatedFaqs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  return (
    <Layout
      title={details?.title || ""}
      breadscrumbs={{
        parent: "FAQs",
        showBanner: false,
        parentUrl: "/faqs",
        currentUrl: `/faqs/${details?.id}`
      }}
    >
      <div className="website-page-wrapper py-24">
        {/* content */}
        <div className="w-full md:px-5 3md:px-32">
          {/* content */}
          {loading ? (
            <SkeletonTheme baseColor="#777777" highlightColor="#444">
              <Skeleton count={20} />
            </SkeletonTheme>
          ) : (
            <div className="w-full pb-10 border-b border-blueGray">
              <h1 className="text-5xl font-bold tracking-tighter text-lightBlack">
                {details?.title}
              </h1>
              <div className="w-full mt-3 text-base text-darkerGray tracking-tightest leading-7.5">
                <div className="w-full flex items-center space-x-5">
                  <p>{details?.category}</p> <FaCircle className="w-1 h-1" />
                  <p>{details?.published_at}</p>
                </div>
              </div>
              <div className="mt-7.5 text-darkerGray">
                <div
                  className="tracking-tightest leading-7.5"
                  dangerouslySetInnerHTML={{
                    __html: details?.content,
                  }}
                />
              </div>
            </div>
          )}

          {/* content footer */}
          <div className="w-full mt-10">
            <div className="flex space-x-5">
              <p className="font-bold text-2xl tracking-tighter text-lightBlack">
                Share
              </p>
              <div className="flex items-center space-x-3.5">
                <Button
                  buttonTextClass="text-sm text-lightBlack font-semibold"
                  buttonRadius="9999px"
                  buttonClass="p-2.5 border border-socialGray"
                  onClick={() => window.open("https://www.facebook.com")}
                  icon={<FaFacebookF />}
                />
                <Button
                  buttonTextClass="text-sm text-lightBlack font-semibold"
                  buttonRadius="9999px"
                  buttonClass="p-2.5 border border-socialGray"
                  onClick={() => window.open("https://www.facebook.com")}
                  icon={<FaTwitter />}
                />
                <Button
                  buttonTextClass="text-sm text-lightBlack font-semibold"
                  buttonRadius="9999px"
                  buttonClass="p-2.5 border border-socialGray"
                  onClick={() => window.open("https://www.facebook.com")}
                  icon={<FaShareAlt />}
                />
              </div>
            </div>
          </div>
        </div>
        {/*  related */}
        <div className="w-full mt-24">
          <h1 className="text-5xl text-center font-bold tracking-tighter text-lightBlack">
            Related FAQs
          </h1>
          <div className="w-full mt-8 grid md:grid-cols-2 2md:grid-cols-3 gap-7">
            {faqs.map((obj, index) => (
              <FAQItem
                key={index}
                id={obj.id}
                title={obj.title}
                description={obj.content}
              />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}
