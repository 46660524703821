import React from "react";
import Modal from "components/global/modal";
import _ from "lodash";
import { formatDate } from "utils/utils";
import { FaTimes } from "react-icons/fa";
import { DetailedViewCard } from "./detailed-view";
import "./media-button/styles.css";

const ViewMilestoneModal = (props) => {
  const {
    isYourProfile,
    profile,
    data = {},
    onWatchMedia = () => {},
    onViewImage = () => {},
    onEdit = () => {},
    onDelete = () => {},
    onReport = () => {},
    hide = () => {},
    onShare = () => {},
    handleViewPdf = () => {},
    share,
  } = props;

  const featuredDate = formatDate(data, profile?.dateFormat);

  return (
    <Modal
      width="custom-with-responsive"
      contentClassName="rounded-lg overflow-auto custom-style"
      wrapperClassName="overflow-visible custom-style"
      centerModal="overflow-x-hidden"
      >
      <div className="w-full">
        {/* <div className="flex flex-row-reverse items-baseline mb-4 px-8"> */}
        <div className="absolute -right-[53px] z-[9999] top-[1px] 2sm:relative 2sm:top-3 2sm:-right-2 2sm:flex flex-row-reverse items-baseline mb-4 px-8">
          <button onClick={hide} className="mr-4">
            <FaTimes className="text-white text-3xl 2sm:text-xl" />
          </button>
        </div>

        {/* content */}
        <div className="px-2 5sm:px-8.5 py-4 2sm:py-0">
          {!_.isEmpty(data) && (
            <DetailedViewCard
              profile={profile}
              featured={data}
              details={data}
              url={
                data.thumbnail &&
                (data.thumbnail.webp_url || data.thumbnail.url)
              }
              title={data.title}
              date={featuredDate}
              dateNote={data.date_note}
              people={data.people}
              positions={data.positions}
              platform={data.platform}
              current={false}
              description={data.description}
              privacy={data.privacy}
              isAdultContent={data.is_adult_content ? true : false}
              category={data.category.name}
              buttons={data.media_type}
              onWatchMedia={onWatchMedia}
              onViewImage={onViewImage}
              onEdit={() => onEdit(data)}
              onDelete={() => onDelete(data)}
              isYourProfile={isYourProfile}
              onReport={() => onReport(data)}
              hide={() => hide()}
              onShare={()=>onShare()}
              share={share}
              organizeBy ="Job/Role"
              handleViewPdf={handleViewPdf}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ViewMilestoneModal;
