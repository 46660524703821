import {
  REFRESH_MEDIA_SUB_STYPES,
	SET_MEDIA_SUB_TYPES,
} from './types';

export const setRefreshMediaSubTypes = () => {
	return {
    type: REFRESH_MEDIA_SUB_STYPES,
    payload: [],
  };
}

export const setMediaSubTypes = (params) => {
	return {
    type: SET_MEDIA_SUB_TYPES,
    payload: params,
  };
}