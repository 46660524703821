import Maintenance from "pages/maintenance/maintenance";
import Login from "pages/login/login";
//import Home from "pages/home/home";
import NotFoundPage from "pages/not-found-page/not-found-page";
import ForgotPassword from "pages/forgot-password/forgot-password";
import PasswordReset from "pages/password-reset/password-reset";
import SignUp from "pages/sign-up/sign-up";
import FAQ from "pages/faq/faq";
import PlanPage from "pages/plan/plan";
import FAQDetails from "pages/faq/details";
import Profile from "pages/profile/profile";
import ManageProfile from "pages/profile/manage";
import VerifyAccount from "pages/verify-account/verify-account";
import Settings from "pages/settings/settings";
import Search from "pages/search/search";
import Messages from "pages/messages/messages";
import SetupProfile from "pages/profile/setup-profile";
import EmailVerification from "pages/email-verification/email-verification";
import ResumeBuilder from "components/profile/resume/builder";
import PlanSubscriptionSuccess from "pages/plan/success";
import ResumeFullPreview from "components/profile/resume/full-preview";
import Following from "pages/profile/following/following";
import BillingHistory from "pages/settings/billing-history";

const routes = [
  // {
  //   path: "/",
  //   authRequired: false,
  //   validateUserValid: false,
  //   component: () => <Home />,
  // },
  {
    path: "/",
    authRequired: false,
    validateUserValid: true,
    component: () => <Login />,
  },
  {
    path: "/faqs",
    authRequired: false,
    validateUserValid: false,
    component: () => <FAQ />,
  },
  {
    path: "/faqs/:key",
    authRequired: false,
    validateUserValid: false,
    component: () => <FAQDetails />,
  },
  {
    path: "/plans",
    authRequired: false,
    validateUserValid: false,
    component: () => <PlanPage />,
  },
  {
    path: "/forgot-password",
    authRequired: false,
    validateUserValid: true,
    component: () => <ForgotPassword />,
  },
  {
    path: "/password-reset",
    authRequired: false,
    validateUserValid: true,
    component: () => <PasswordReset />,
  },
  {
    path: "/maintenance",
    authRequired: false,
    validateUserValid: false,
    component: () => <Maintenance />,
  },
  {
    path: "/login",
    authRequired: false,
    validateUserValid: true,
    component: () => <Login />,
  },
  {
    path: "/sign-up",
    authRequired: false,
    validateUserValid: true,
    component: () => <SignUp />,
  },
  {
    path: "/:username/profile/:profileId",
    authRequired: false,
    validateUserValid: false,
    component: () => <Profile />,
  },
  {
    path: "/:username/profile/:profileId/following",
    authRequired: false,
    validateUserValid: false,
    component: () => <Following />,
  },
  {
    path: "/resume/builder",
    authRequired: false,
    validateUserValid: false,
    component: () => <ResumeBuilder />,
  },
  {
    path: "/resume/preview",
    authRequired: false,
    validateUserValid: false,
    component: () => <ResumeFullPreview />,
  },
  {
    path: "/:username/manage",
    authRequired: false,
    validateUserValid: false,
    component: () => <ManageProfile />,
  },
  {
    path: "/:username/setup-profile",
    authRequired: true,
    validateUserValid: false,
    component: () => <SetupProfile />,
  },
  {
    path: "/:username/setup-profile/:profileId",
    authRequired: true,
    validateUserValid: false,
    component: () => <SetupProfile />,
  },
  {
    path: "/verify-account",
    authRequired: true,
    validateUserValid: false,
    component: () => <VerifyAccount />,
  },
  {
    path: "/settings",
    authRequired: true,
    validateUserValid: false,
    component: () => <Settings />,
  },
  {
    path: "/search",
    authRequired: false,
    validateUserValid: false,
    component: () => <Search />,
  },
  {
    path: "/messages",
    authRequired: true,
    validateUserValid: false,
    component: () => <Messages />,
  },
  {
    path: "/email-verification",
    authRequired: false,
    validateUserValid: false,
    component: () => <EmailVerification />,
  },
  {
    path: "/subscription/success",
    authRequired: true,
    validateUserValid: false,
    component: () => <PlanSubscriptionSuccess />,
  },
  {
    path: "/billings/history",
    authRequired: true,
    validateUserValid: false,
    component: () => <BillingHistory />,
  },
  {
    path: "*",
    exact: true,
    component: () => <NotFoundPage />,
  },
];

export default routes;
