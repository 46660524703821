import React, { useEffect, useState } from "react";
import Select from "components/select/select";
import Button from "components/button/button";
import {
  TemplateColorOptions,
  TemplateControl,
  colorOptions,
  stepsInfo,
  templatesPlaceholder,
  templatesPlaceholderNP,
  templatesPlaceholderTG,
  templatesPlaceholderNPTG,
} from "utils/constant";
import _ from "lodash";
import update from "immutability-helper";
import PopInfo from "components/info-popup/pop-info";
// import Toggle from "components/toggle/toggle";

export default function TemplatePanel({
  onChangeColor,
  onChangeTemplate,
  color,
  // onChangeToggleImage,
  show_image,
  profileType,
}) {
  const [templates, setTemplates] = useState([]);

  const onShowOtherImages = (index, page, current) => {
    let payload = _.clone(templates);
    let payloadImages = (current.images || []).map((obj, index) => {
      if (page === index) {
        return { ...obj, active: true };
      } else {
        return { ...obj, active: false };
      }
    });

    if (payload) {
      let data = update(payload, {
        [index]: {
          images: {
            $set: payloadImages,
          },
        },
      });
      setTemplates(data);
    }
  };

  useEffect(() => {
    let templateMod = templatesPlaceholderNP;

    if(profileType === "team" || profileType === "group") {
      if (show_image) {
        templateMod = templatesPlaceholderTG;
      } else {
        templateMod = templatesPlaceholderNPTG;
      }
    } else {
      if (show_image) {
        templateMod = templatesPlaceholder;
      }
    }

    let obj = _.find(templateMod, { key: color?.key });
    if (!_.isEmpty(obj)) {
      setTemplates(obj.templates);
    }
  }, [color, show_image]);

  return (
    <div className="w-full px-4 md:px-10 py-5 4md:px-23 4md:py-12.5">
      {/* header */}
      <header className="w-full">
        <div className="w-full grid grid-cols-3 9sm:grid-cols-5 gap-4 2smd:gap-16.5 items-center">
          {stepsInfo.map((obj, index) => (
            <div
              className="w-full flex flex-col justify-center 2smd:justify-start 2smd:flex-row 2smd:space-x-4.5 2smd:items-center"
              key={index}>
              {obj.icon}
              <div className="steps-resume-responsive">
                <p className="text-sm text-primary font-semibold">{`Step ${
                  index + 1
                }`}</p>
                <p className="text-sm font-medium text-darkerGray">
                  {obj.label}
                </p>
              </div>
            </div>
          ))}
        </div>
      </header>
      {/* body */}
      <div className="w-full mt-14">
        {/* header Title */}
        <div className="w-full grid justify-start">
          <div className="w-full flex flex-row gap-x-2 items-center">
            <h1 className="text-2xl text-lightBlack font-semibold">
              Select your Resume Template
            </h1>
            <PopInfo containerClass="relative" type="resume_template" />
          </div>
          <p className="text-darkerGray mt-3">
            You can replace them template freely at any time.
          </p>
        </div>
        {/* end header title */}
        <div className="w-full 4sm:flex 4sm:items-center 4sm:justify-between">
          <div className="w-full flex justify-center mt-6">
            <div className="w-full flex justify-start flex-row space-x-3 items-center">
              <p className="text-sm font-medium text-darkerGray">
                Select {`${JSON.parse(localStorage.getItem('user_data'))?.profile?.dateFormat ? 'Colour' : 'Color'} `}
              </p>
              <Select
                customOptions={true}
                optionComponent={{
                  Option: TemplateColorOptions,
                  Control: TemplateControl,
                }}
                formContainer="w-75"
                options={colorOptions}
                placeholder="Select Color"
                defaultValue={color}
                onChange={onChangeColor}
                height={50}
              />
            </div>
          </div>
          {/* <div className="w-full 3md:w-14/20 flex items-center mt-6 justify-end">
            <div className="flex space-x-6">
              <div>
                <Toggle
                  checked={show_image}
                  name="dateFormat"
                  onChange={(e) => onChangeToggleImage(e?.target?.checked)}
                />
              </div>
              <div>
                <p className="text-lg font-medium text-lightBlack">
                  Show/Hide Images
                </p>
              </div>
            </div>
          </div> */}
        </div>
        {/* template selector */}
        <div className="w-full mt-8.6">
          <div className="w-full flex justify-center flex-row gap-8.6 flex-wrap">
            {templates.map((obj, index) => {
              let current = _.find(obj.images, { active: true });
              return (
                <div
                  className="w-80.5 rounded-md border border-resumeBorder"
                  key={index}>
                  <div className="w-full relative cursor-pointer template-hover">
                    <div className="w-full h-full bg-white bg-opacity-40 absolute top-0 rounded-t-md hidden-template-hover">
                      <div className="w-full h-full grid justify-center items-center">
                        <Button
                          buttonName="Select this Template"
                          buttonTextClass="text-xs font-semibold text-white"
                          buttonRadius="6px"
                          buttonClass="bg-primary px-17.5 py-4.6"
                          onClick={() => onChangeTemplate(obj.name)}
                        />
                      </div>
                    </div>
                    <img
                      src={current.image}
                      className="rounded-t-md"
                      alt="resume-template-one"
                    />
                  </div>
                  <div className="w-full py-4.5 px-3 border-t border-resumeBorder template-hover-footer">
                    <div className="w-full flex flex-wrap justify-between items-center">
                      <p className="text-lightBlack text-lg font-medium">
                        {obj.name}
                      </p>
                      <div className="flex flex-start gap-1.5">
                        {(obj.images || []).map((image, subIndex) => (
                          <button
                            key={subIndex}
                            onClick={() =>
                              onShowOtherImages(index, subIndex, obj)
                            }
                            className={`w-2 h-2 rounded-full ${
                              image.active ? "bg-primary" : "bg-gray"
                            } `}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* end: */}
      </div>
    </div>
  );
}
