const FitSquareIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2.5"
        y="2.5"
        width="19"
        height="19"
        rx="1.5"
        stroke="currentColor"
      />
      <rect x="6" y="2" width="12" height="20" fill="currentColor" />
    </svg>
  );
};

export default FitSquareIconSVG;
