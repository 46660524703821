import React, { useState, useLayoutEffect } from "react";
import Layout from "layouts/layout";
import { AcceptedPayments } from "constants/images";
import Toggle from "components/toggle/toggle";
import PlanCardItem from "components/plan/plan-card";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useHistory } from "react-router-dom";
import { randomExtension } from "utils/utils";
import { PLANS } from "utils/constant";
import { BASE_URL } from "configs/api";

export default function PlanPage() {
  const { http, localstorage } = global.services;
  const history = useHistory();

  const [billing, setBilling] = useState("yearly");

  const extension = randomExtension();

  const checkout = async (plan, price_id) => {
    try {
      const { data } = await http.post("public/subscribes", {
        plan: plan,
        price_id: price_id,
        success_url: `${BASE_URL}/subscription/success`,
        cancel_url: `${BASE_URL}/plans`,
      });

      window.location.href = data.url;
    } catch (err) {
      console.log(err);
    }
  };

  const paypalCheckout = async (plan, plan_id) => {
    try {
      const { data } = await http.post("public/paypal/subscribes", {
        plan: plan,
        plan_id: plan_id,
        success_url: `${BASE_URL}/subscription/success`,
        cancel_url: `${BASE_URL}/plans`,
      });

      window.location.href = data.url;
    } catch (err) {
      console.log(err);
    }
  };

  const selectPlan = async (plan, pm) => {
    const userData = localstorage.get("user_data");
    if (userData.value) {
      const { user, profile } = JSON.parse(userData.value);
      if (plan.plan === "Free Account") {
        history.push(`/${user.username}-${extension}/profile/${profile.id}`);
      } else {
        if (pm === "paypal") {
          paypalCheckout(plan.plan, plan.plan_id);
        } else {
          checkout(plan.plan, plan.price_id);
        }
      }
    } else {
      history.push("/login");
    }
  };

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const monthTl = gsap.timeline({
      scrollTrigger: {
        trigger: ".plan-breadcrumbs",
        start: "top center",
        endTrigger: ".terms",
        toggleActions: "play reverse play reset",
      },
    });
    monthTl.fromTo(".monthly", { x: -50, opacity: 0 }, { x: 0, opacity: 1 });
    const yearlyTl = gsap.timeline({
      scrollTrigger: {
        trigger: ".plan-breadcrumbs",
        start: "top center",
        endTrigger: ".terms",
        toggleActions: "play reverse play reset",
      },
    });
    yearlyTl.fromTo(".yearly", { x: 50, opacity: 0 }, { x: 0, opacity: 1 });
    const planToggleTl = gsap.timeline({
      scrollTrigger: {
        trigger: ".plan-breadcrumbs",
        start: "top center",
        endTrigger: ".terms",
        toggleActions: "play reverse play reset",
      },
    });
    planToggleTl.fromTo(
      ".plan-toggle",
      { y: -20, opacity: 0 },
      { y: 0, opacity: 1 }
    );
    /** card plan */
    gsap.fromTo(
      ".plan-card-item",
      { scale: 0, opacity: 0 },
      { scale: 1, opacity: 1 }
    );
    gsap.fromTo(
      ".payment-heading",
      { y: -20, opacity: 0 },
      { y: 0, opacity: 1 }
    );
    gsap.fromTo(".payment-items", { y: 20, opacity: 0 }, { y: 0, opacity: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout
      title="Plans"
      breadscrumbs={{
        title: "Plans Page",
        subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        showBanner: true,
      }}>
      <div className="website-page-wrapper py-17">
        {/* plans */}
        <div className="w-full">
          {/* toggle */}
          {/* <div className="terms w-full flex items-center justify-center space-x-9">
            <p className="monthly text-2xl font-meduim text-lightBlack tracking-tighter">
              Monthly
            </p>
            <div className="plan-toggle">
              <Toggle
                checked={billing === "yearly"}
                name="plans"
                background="plans"
                onChange={(e) =>
                  setBilling(e?.target?.checked ? "yearly" : "monthly")
                }
              />
            </div>
            <p className="yearly text-2xl font-meduim text-lightBlack tracking-tighter">
              Yearly
            </p>
          </div> */}
          <div className="relative flex items-center justify-center">
            <div className="flex border rounded-full overflow-hidden bg-white border-[#d8d8d8] py-1 px-1">
              {['monthly', 'yearly'].map((option) => (
                <button
                  key={option}
                  onClick={() => setBilling(option)}
                  className={`px-4 py-2 font-medium transition-colors duration-200 capitalize rounded-full text-xs font-semibold ${
                    billing === option
                      ? 'bg-[#deeeff] text-[#0d6ed1] '
                      : 'text-[#bababa] hover:text-[#0d6ed1]'
                  }`}
                >
                  {option} 
                </button>
              ))}
            </div>
          </div>
          {/* plans */}
          <div className="plan-card w-full mt-15 space-y-11 md:flex md:flex-col md:items-center lg:flex-row lg:justify-center lg:items-center lg:space-x-11 lg:space-y-0">
            {PLANS.filter(
              (plan) => plan.term === billing.replace("ly", "") || !plan.term
            ).map((plan, index) => (
              <div key={index} className="">
                <PlanCardItem
                  obj={plan}
                  isFreeAccount={plan.plan === "Free Account"}
                  billing={billing}
                  onCheckout={selectPlan}
                />
              </div>
            ))}
          </div>
        </div>
        {/* footer */}
        <div className="payment-method w-full mt-28">
          <h1 className="payment-heading text-5xl text-center font-bold tracking-tighter text-lightBlack">
            What payment methods we accept
          </h1>
          <div className="payment-items w-full flex justify-center">
            <img
              src={AcceptedPayments}
              alt="milestones-page-accepted-payments"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}
