const ResumeUnderlineIconSVG = ({ className = "", stroke = "#3885FF" }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="2"
      viewBox="0 0 17 2"
      fill="none">
      <path d="M0 1H17" stroke={stroke} strokeWidth="2"/>
    </svg>
  );
};

export default ResumeUnderlineIconSVG;
