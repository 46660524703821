import {
  SET_USER_DATA,
  SET_PROFILES,
  ADD_PROFILE,
  UPDATE_PROFILE,
  SET_SOCIAL_MEDIA
} from "./types";

const initialState = {
  userData: null,
  initialize: true,
  profiles: [],
  socialMedia: []
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER_DATA:
      return {
        ...state,
        userData: payload,
      };

    case SET_PROFILES:
      // const profiles = payload.filter(p => p.id !== state.userData.profile.id);

      return {
        ...state,
        initialize: false,
        profiles: payload,
      };

    case ADD_PROFILE:
      return {
        ...state,
        profiles: [payload, ...state.profiles],
      };

    case UPDATE_PROFILE:
      const newProfiles = state.profiles.map((p) => {
        if (p.id === payload.id) {
          return payload;
        }
        return p;
      });

      return {
        ...state,
        profiles: newProfiles,
      };

    case SET_SOCIAL_MEDIA:
      return {
        ...state,
        socialMedia: payload
      }

    default:
      return state;
  }
};

export default reducer;
