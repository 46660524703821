const InfoQuestionIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.75275 7.80376V7.59475C2.75275 7.01701 2.80032 6.55652 2.89548 6.21328C2.99404 5.87004 3.13677 5.59476 3.32369 5.38746C3.5106 5.17675 3.7383 4.98474 4.00677 4.81142C4.22088 4.67548 4.41119 4.53784 4.57771 4.39851C4.74764 4.25577 4.88188 4.10284 4.98043 3.93972C5.07899 3.77659 5.12826 3.59138 5.12826 3.38407C5.12826 3.18356 5.08068 3.00684 4.98553 2.85391C4.89037 2.70098 4.75953 2.58374 4.59301 2.50217C4.42988 2.41721 4.24806 2.37473 4.04756 2.37473C3.84705 2.37473 3.65843 2.42061 3.48171 2.51237C3.30839 2.60413 3.16566 2.73497 3.05351 2.90489C2.94476 3.07141 2.88868 3.27362 2.88529 3.51151H0.540354C0.550549 2.79784 0.715374 2.2133 1.03483 1.75791C1.35768 1.30252 1.78419 0.966073 2.31435 0.748572C2.8445 0.527672 3.42904 0.417223 4.06795 0.417223C4.76803 0.417223 5.38994 0.525973 5.9337 0.743474C6.48085 0.960975 6.91075 1.28213 7.22341 1.70693C7.53607 2.13174 7.69239 2.6517 7.69239 3.26682C7.69239 3.67124 7.62443 4.02808 7.48849 4.33734C7.35255 4.6466 7.16224 4.92017 6.91755 5.15806C6.67626 5.39256 6.39249 5.60666 6.06624 5.80037C5.81135 5.9499 5.59895 6.10623 5.42903 6.26936C5.2625 6.43248 5.13676 6.6194 5.0518 6.8301C4.96684 7.03741 4.92436 7.29229 4.92436 7.59475V7.80376H2.75275ZM3.87933 11.1478C3.5123 11.1478 3.19794 11.0187 2.93626 10.7604C2.67798 10.5021 2.55054 10.1895 2.55394 9.82244C2.55054 9.4622 2.67798 9.15464 2.93626 8.89976C3.19794 8.64147 3.5123 8.51233 3.87933 8.51233C4.22937 8.51233 4.53693 8.64147 4.80201 8.89976C5.06709 9.15464 5.20133 9.4622 5.20473 9.82244C5.20133 10.0671 5.13676 10.2897 5.01102 10.4902C4.88867 10.6907 4.72725 10.8505 4.52674 10.9694C4.32963 11.0884 4.11383 11.1478 3.87933 11.1478Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default InfoQuestionIconSVG;
