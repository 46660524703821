const MailIconSVG = ({ className = "", stroke = "#EF6B33" }) => {
  return (
    <svg
      // width="15"
      // height="12"
      // viewBox="0 0 15 12"
      width="11"
      height="11"
      viewBox="0 0 11 11"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1383_1926)">
        <path d="M1.78109 2.16699H8.44775C8.90609 2.16699 9.28109 2.54199 9.28109 3.00033V8.00033C9.28109 8.45866 8.90609 8.83366 8.44775 8.83366H1.78109C1.32275 8.83366 0.947754 8.45866 0.947754 8.00033V3.00033C0.947754 2.54199 1.32275 2.16699 1.78109 2.16699Z" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.28109 3L5.11442 5.91667L0.947754 3" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_1383_1926">
          <rect width="10" height="10" fill="white" transform="translate(0.114258 0.5)"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default MailIconSVG;
