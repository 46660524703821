import React, { useState, useLayoutEffect, useEffect,useContext } from "react";
import Input from "components/input/input";
import toast from "react-hot-toast";
import Button from "components/button/button";
import LoadingIndicator from "assets/svg/loading-indicator";
import Layout from "layouts/layout";
import LogoComponent from "components/logo/logo";
import { Link, useHistory, useLocation } from "react-router-dom";
import userActions from "redux/modules/user/actions";
import { connect } from "react-redux";
import PasswordValidation from "components/password-validation/password-validation";
import { randomExtension } from "utils/utils";
import { gsap } from "gsap";
import CustomCheckbox from "components/checkbox/custom-checkbox";
import { RECAPTCHA_KEY } from "configs/api";
import ReCAPTCHA from "react-google-recaptcha";
import _ from "lodash";
import { SubscriptionContext } from "context/subscription";

const SignUp = ({ user, setUserData }) => {
  const { http, localstorage } = global.services;
  const history = useHistory();
  const query = useQuery();
  const status = query.get("status");
  const { getSubscription } = useContext(SubscriptionContext);
  const [recaptcha, setRecaptcha] = useState(null);
  const [recapthcaError, setRecapthcaError] = useState(null);
  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    email: "",
    username: "",
    password: "",
    password_confirmation: "",
    terms: false,
    loading: false,
    showPassword: false,
    showPasswordConfirm: false,
    validationErrors: null,
    success: false,
    role: "customer",
    recaptchaToken: "",
    over_18_years_old: false
  });
  const [passwordValidation, setPasswordValidation] = useState({
    passwordHasUpperCaseLetter: false,
    passwordHasLowerCaseLetter: false,
    passwordHasNumber: false,
    passwordHasEightCharacters: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "password") {
      const hasUpperCase = /[A-Z]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasEightCharacters = value.length >= 8;
      const hasNumber = /\d/.test(value);

      setPasswordValidation((prevState) => ({
        ...prevState,
        passwordHasUpperCaseLetter: hasUpperCase,
        passwordHasLowerCaseLetter: hasLowerCase,
        passwordHasEightCharacters: hasEightCharacters,
        passwordHasNumber: hasNumber,
      }));
    }
    setState((prev) => ({
      ...prev,
      [name]: value,
      validationErrors: { ...state.validationErrors, [name]: null },
    }));
  };

  const handleUpdateCheckbox = (e) => {
    const isChecked = e.target.checked;
    setState({ ...state, terms: isChecked });
  };

  const handleUpdateCheckboxOver18Years = (e) => {
    const isChecked = e.target.checked;
    setState({ ...state, over_18_years_old: isChecked });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (RECAPTCHA_KEY) {
      if (!recaptcha) {
        return setRecapthcaError("Recaptcha is required.");
      }
      
      state.recaptchaToken = recaptcha;
    }

    setState((prev) => ({ ...prev, loading: true }));

    let payload = { ...state }

    if (status) {
      payload.status = status
    }

    const urlParams = new URLSearchParams(window.location.search);
      let limit = urlParams.get('limit');
      if(!_.isEmpty(limit)){
        payload = {...payload, plan:urlParams.get('limit')}
      }
    try {
      await http.post("auth/register", payload);
      handleLogin(e);
    } catch (error) {
      if (error.status === 422) {
        return setState((prev) => ({
          ...prev,
          validationErrors: error.data,
          loading: false,
        }));
      }
      toast.error(error.data.message || "It appears that something went wrong");

      if(error.data.recaptchaError)
        window.grecaptcha.reset();

      return setState((prev) => ({
        ...prev,
        validationErrors: error.data,
        loading: false,
      }));
    }
  };

  const handleLogin = async (e) => {
    const params = {
      email: state.email,
      password: state.password,
      invalidateRecaptcha: !!state.recaptchaToken  //invalidate if recaptcha is already sent in sign-up
    };

    try {
      const req = await http.post("auth/login", params);
      const extension = randomExtension();
      const userData = req.data;
      localstorage.set("user_data", JSON.stringify(userData)); 
      setUserData(userData); 
      await getSubscription();
      await logOnline(userData);
      history.replace(
        `/${userData?.user?.username}-${extension}/profile/${userData?.profile?.id}`
      );
    } catch (error) {
      if (error.status === 422)
        return setState((prev) => ({ ...prev, validationErrors: error.data }));

      toast.error("It appears that something went wrong");
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  const logOnline = async (userData) => {
    let profileId = userData.profile.id;
    try {
      let { data } = await http.post(`public/profiles/${profileId}/log-online`);
      const profile = data.data;
      setUserData({
        ...userData,
        profile,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useLayoutEffect(() => {
    gsap.fromTo(
      ".signup-title",
      {
        y: -20,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
      }
    );
    gsap.fromTo(
      ".signup-form",
      {
        x: 100,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
      }
    );
    gsap.fromTo(
      ".back-login",
      {
        scale: 0,
        opacity: 0,
      },
      {
        scale: 1,
        opacity: 1,
      }
    );
  }, []);

  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const isFreePlan = atob(urlParams.get('freeplan')) === 'freeForLifePlan';
    if(isFreePlan){ 
      let limit = btoa(new Date().toISOString());
      window.location.href = `${window.location.origin}/sign-up?limit=${limit}`;
    }
  },[])
  

  return (
    <Layout title="Create Account">
      <div className="main-page-wrapper">
        <div className="login-container">
          {/*  */}
          <LogoComponent />
          {/*  */}
          <div className="w-full">
            <div className="signup-form form-container">
              <h2 className="signup-title form-column-title pb-2">
                Create an Account
              </h2>
              <form
                className="form-column-container"
                id="create-account-component"
                onSubmit={handleSubmit}
              >
                <div className="w-full 2md:flex 2md:space-x-5">
                  <Input
                    inputContainerClass="form-input-container"
                    value={state?.first_name}
                    autoFocus={true}
                    name="first_name"
                    outsideLabel="First Name"
                    outsideLabelClass="text-sm font-bold text-darkerGray"
                    noLabelPaddingY="1rem"
                    type="text"
                    onChange={handleInputChange}
                    errorMessage={state.validationErrors?.first_name}
                  />
                  <Input
                    inputContainerClass="form-input-container"
                    value={state?.last_name}
                    autoFocus={true}
                    name="last_name"
                    outsideLabel="Last Name"
                    outsideLabelClass="text-sm font-bold text-darkerGray"
                    noLabelPaddingY="1rem"
                    type="text"
                    onChange={handleInputChange}
                    errorMessage={state.validationErrors?.last_name}
                  />
                </div>
                <div className="w-full 2md:flex 2md:space-x-5">
                  <Input
                    inputContainerClass="form-input-container"
                    value={state?.username}
                    autoFocus={true}
                    name="username"
                    outsideLabel="Username"
                    outsideLabelClass="text-sm font-bold text-darkerGray"
                    noLabelPaddingY="1rem"
                    type="text"
                    onChange={handleInputChange}
                    errorMessage={state.validationErrors?.username}
                  />
                  <Input
                    inputContainerClass="form-input-container"
                    value={state.email}
                    autoFocus={true}
                    name="email"
                    outsideLabel="Email Address"
                    outsideLabelClass="text-sm font-bold text-darkerGray"
                    noLabelPaddingY="1rem"
                    type="email"
                    onChange={handleInputChange}
                    errorMessage={state.validationErrors?.email}
                  />
                </div>
                <Input
                  inputContainerClass="form-input-container"
                  showInputValidation={true}
                  value={state.password}
                  outsideLabel="Password"
                  outsideLabelClass="text-sm font-bold text-darkerGray"
                  noLabelPaddingY="1rem"
                  name="password"
                  masked={true}
                  validationPassed={Object.values(passwordValidation).every(
                    (item) => item === true
                  )}
                  visible={state.showPassword}
                  onChangeVisible={() =>
                    setState((prev) => ({
                      ...prev,
                      showPassword: !prev.showPassword,
                    }))
                  }
                  type={state.showPassword ? "text" : "password"}
                  onChange={handleInputChange}
                  errorMessage={state.validationErrors?.password}
                />
                <PasswordValidation passwordValidation={passwordValidation} />
                <Input
                  inputContainerClass="form-input-container-bottom"
                  value={state.password_confirmation}
                  outsideLabel="Confirm Password"
                  outsideLabelClass="text-sm font-bold text-darkerGray"
                  noLabelPaddingY="1rem"
                  name="password_confirmation"
                  masked={true}
                  visible={state.showPasswordConfirm}
                  onChangeVisible={() =>
                    setState((prev) => ({
                      ...prev,
                      showPasswordConfirm: !prev.showPasswordConfirm,
                    }))
                  }
                  type={state.showPasswordConfirm ? "text" : "password"}
                  onChange={handleInputChange}
                  errorMessage={state.validationErrors?.password_confirmation}
                />
                <div className={`checkbox-container mt-3 ${RECAPTCHA_KEY ? "mb-4 " : "mb-6"}`}>
                  <CustomCheckbox
                    label={
                      <p className="checkbox-text">
                        I agree to{" "}
                        <a
                          href="/terms-and-conditions"
                          target="_blank"
                          className="text-primary"
                        >
                          Terms & Conditions
                        </a>
                      </p>
                    }
                    name={"termsAndConditions"}
                    checked={state.terms}
                    onChange={handleUpdateCheckbox}
                  />
                </div>
                <div className={`checkbox-container mt-3 ${RECAPTCHA_KEY ? "mb-4 " : "mb-6"}`}>
                  <CustomCheckbox
                    label={
                      <p className="checkbox-text">
                        I certify that I am over 18 years old
                      </p>
                    }
                    name={"termsAndConditions"}
                    checked={state.over_18_years_old}
                    onChange={handleUpdateCheckboxOver18Years}
                  />
                </div>

                {RECAPTCHA_KEY && (
                  <div
                    className="w-fill mb-5"
                    id="recaptcha-v3"
                  >
                    <ReCAPTCHA
                      sitekey={RECAPTCHA_KEY}
                      onChange={(value) => setRecaptcha(value)}
                    />

                    {recapthcaError && <p className="text-red text-xs mt-1">{recapthcaError}</p>}
                  </div>
                )}

                <Button
                  buttonName="Create an Account"
                  disabled={state.loading || !state.terms || !state.over_18_years_old}
                  buttonTextClass="text-sm text-white font-semibold"
                  buttonRadius="6px"
                  buttonClass="relative bg-lightGreen py-3.5 mb-10"
                  buttonWidth="100%"
                  type="submit"
                  icon={
                    <div className="absolute right-3 bottom-0 top-0 flex items-center">
                      {state.loading && (
                        <LoadingIndicator className="text-white w-5 h-5" />
                      )}
                    </div>
                  }
                />

                {/* footer */}
                <div className="border-t border-gray text-sm font-semibold text-lightBlack py-5">
                  <div className="back-login w-full flex justify-center items-center">
                    <Link to="/login" className="text-primary">
                      Back to Login
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (params) => {
      dispatch(userActions.setUserData(params));
    },
  };
};

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
