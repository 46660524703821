import React, { useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import Button from "components/button/button";
import { SubscriptionContext } from "context/subscription";

import { randomExtension } from "utils/utils";

export default function PlanSubscriptionSuccess() {
  const { http } = global.services;

  const { userData } = useSelector((state) => state.user);
  const extension = randomExtension();

  const history = useHistory();
  const query = useQuery();
  const sessionId = query.get("session_id");
  const { getSubscription } = useContext(SubscriptionContext);

  const successCheckout = async () => {
    try {
      await http.post("public/subscribes/success", {
        session_id: sessionId,
      });
    } catch (err) {
      console.log("successCheckout error", err);
    }
  };

  useEffect(() => {
    if (sessionId) {
      successCheckout(sessionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  return (
    <div className="h-screen flex justify-center items-center">
      <div className="w-96 shadow-md rounded-md text-center p-8 mb-20">
        <h1 className="text-2xl text-green font-bold">
          Thank you for subscribing
        </h1>
        <p>You have completed your payment.</p>

        <Button
          buttonName="View Profile"
          buttonTextClass="text-sm text-white font-semibold"
          buttonClass="relative bg-primary py-3.5 px-6 mt-8"
          onClick={() => {
            getSubscription();
            history.push(
              `/${userData.user.username}-${extension}/profile/${userData.profile.id}`
            );
          }}
        />
      </div>
    </div>
  );
}

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};
