import PopInfo from "components/info-popup/pop-info";

export default function ModalHeader({
  padding = "pl-10 pr-7 py-3.5",
  className = "flex",
  title = "",
  hide = () => {},
  showPopInfo = false,
  popInfoType = "",
  showMediaHandle = ()=> {}, 
}) {
  return (
    <div
      className={`${padding} border-b border-gray ${className} justify-between items-center `}>
      <div className="w-full flex flex-row gap-x-2 items-center">
        <p className="text-base 2sm:text-3xl text-lightBlack font-bold tracking-tighter">
          {title}
        </p>
        {showPopInfo && <PopInfo type={popInfoType} showMediaHandle={showMediaHandle}/>}
      </div>
      {/* close */}
      <span className="close" onClick={() => hide()}></span>
    </div>
  );
}
