const HeroOtherIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 25"
      id="global"
      width="28px"
      height="28px">
      <path d="M21 8.28a9.54 9.54 0 0 0-6.76-5.11h-.17A9.61 9.61 0 0 0 12.5 3h-.8c-.27 0-.52.05-.78.1h-.16a9.49 9.49 0 0 0 0 18.66h.17a9.81 9.81 0 0 0 3.15 0h.16A9.49 9.49 0 0 0 21 8.28ZM17.53 16a13.4 13.4 0 0 0 .47-3h3a8.47 8.47 0 0 1-.74 3ZM4.77 16A8.47 8.47 0 0 1 4 13h3a13.39 13.39 0 0 0 .45 3Zm2.7-7A13.4 13.4 0 0 0 7 12H4a8.47 8.47 0 0 1 .74-3Zm3.78-4.91H12V8H8.86a12.55 12.55 0 0 1 2.39-3.91ZM16.49 9a12.37 12.37 0 0 1 .51 3h-4V9ZM13 8V4c.25 0 .51 0 .75.06A12.54 12.54 0 0 1 16.14 8Zm-1 1v3H8a12.38 12.38 0 0 1 .49-3Zm-4 4h4v3H8.51A12.37 12.37 0 0 1 8 13Zm4 4v4c-.25 0-.51 0-.75-.06A12.54 12.54 0 0 1 8.86 17Zm1.75 3.9c-.24 0-.5 0-.75.06V17h3.14a12.54 12.54 0 0 1-2.39 3.9ZM13 16v-3h4a12.38 12.38 0 0 1-.49 3Zm5-4a13.39 13.39 0 0 0-.45-3h2.7a8.47 8.47 0 0 1 .75 3Zm1.7-4h-2.49a13.44 13.44 0 0 0-1.84-3.48A8.53 8.53 0 0 1 19.7 8ZM9.63 4.52A13.44 13.44 0 0 0 7.79 8H5.3a8.54 8.54 0 0 1 4.33-3.48ZM5.3 17h2.49a13.44 13.44 0 0 0 1.84 3.48A8.53 8.53 0 0 1 5.3 17Zm10.07 3.48A13.44 13.44 0 0 0 17.21 17h2.49a8.54 8.54 0 0 1-4.33 3.48Z"></path>
    </svg>
  );
};

export default HeroOtherIcon;
