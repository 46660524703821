import * as React from "react"
const SvgComponent = ({ className = "", fill = "#9A9A9A", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
    className={className}
  >
    <rect width={20} height={10} fill={fill} rx={2} />
    <rect width={20} height={3} y={12} fill={fill} rx={1.5} />
    <rect width={14} height={3} y={17} fill={fill} rx={1.5} />
  </svg>
)
export default SvgComponent
