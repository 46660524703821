import React, { useCallback, useContext, useEffect, useState } from "react";
import EmptyVideosIconSVG from "assets/svg/empty-videos-icon";
import ViewButtonModal from "../milestones/media-button/watch";
import { FaEllipsisH, FaPlus } from "react-icons/fa";
import toast from "react-hot-toast";
import _ from "lodash";
import Loader from "components/loader/loader";
import { useDialogHook } from "utils/customhooks";
import { IoClose } from "react-icons/io5";
import Button from "components/button/button";
import LoadingIndicator from "assets/svg/loading-indicator";

import UpgradePremium from "components/subscription/upgrade-premium";
import LockOutlineIcon from "assets/svg/upgrade/lock-outline-icon";
import { SubscriptionContext } from "context/subscription";

import EmptyData from "./empty-data";
import AddVideoModal from "./add-video-modal";

import { TypeOject } from "./photos-videos";
import PopInfo from "components/info-popup/pop-info";
import Popup from "reactjs-popup";
import ReportPhotoVideo from "./report";
import { PiWarningOctagonLight } from "react-icons/pi";
import PlayIconCircleSVG from "assets/svg/play-icon-circle";
import PlayIconCircleWhiteSVG from "assets/svg/play-icon-circle-white";

const noop = () => {};

export default function ManageVideos({ userData = {}, profile = {} }) {
  const { http } = global.services;

  const { isSubscribed } = useContext(SubscriptionContext);

  const isOwner = profile?.id === userData?.profile.id;

  // ===================== state ============================
  const optionRef = React.useRef();
  const [showMoreOption, setShowMoreOption] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [loadmore, setLoadmore] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState({});
  const [hasMore, setHasMore] = useState(false);
  const [showDeleteBtn,setShowDeleteBtn] = useState(false)
  const viewVideo = useDialogHook(ViewButtonModal);
  const addVideo = useDialogHook(AddVideoModal);
  const emptyMessage = isOwner ? "No Videos yet. Start adding your videos now." : "No Videos yet.";
  let checkReported = null;
  const modalReport = useDialogHook(ReportPhotoVideo);
  const { user } = profile;
  const buttonProps = {
    edit: {
      btnName: "Edit",
      btnClass: "relative py-3.6 px-5.5 bg-modalGray hover:bg-gray",
      btnTextClass:
        "text-sm text-lightBlack font-semibold flex items-center space-x-1.6",
    },
  };
  if (!_.isEmpty(user?.reports))
    checkReported = user?.reports.find(
      (report) => report.user_id === userData?.user?.id
    );
  // ===================== functions =========================
  const onUploadImagesVideos = async (files) => {
    let payload = new FormData();
    payload.append("user_id", userData?.user?.id);
    payload.append("user_profile_id", userData?.profile?.id);
    payload.append("type", "video");

    if (!_.isEmpty(files)) {
      //loop
      _.forEach(files, function (obj, index) {
        payload.append(`media[${index}]`, obj);
      });
      //end loop -- start upload
      try {
        setUploadLoading(true);
        const { data } = await http.post(`public/user-galleries`, payload, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        if (data) {
          toast.success(data.message);
          getVideos("refetch");
          setUploadLoading(false);
        }
      } catch (error) {
        toast.error(
          error?.data?.message || "It appears that something went wrong"
        );
        setUploadLoading(false);
      }
    }
  };

  const getVideos = useCallback(
    async (e) => {
      if (e !== "refetch" && e !== "loadmore") {
        setLoading(true);
      }
      try {
        let currentPage = page;
        if (e === "loadmore") {
          setLoadmore(true);
          currentPage = page + 1;
        }
        const result = await http.get(`public/user-galleries`, {
          user_profile_id: profile?.id,
          type: "video",
          limit: 5,
          page: currentPage,
        });
        const res = result.data;
        if (!_.isEmpty(res.data)) {
          let newData = res.data;
          if (e === "loadmore") {
            //checking if empty
            if (_.isEmpty(list)) {
              setList(newData);
            } else {
              //if not empty
              let payload = _.clone(list || []);
              if (!_.isEmpty(newData)) {
                //loop
                newData.forEach((n) => {
                  payload.push(n);
                });
                //end loop
                setList(payload);
                setPage(currentPage);
              }
            }
          } else {
            setList(res.data);
          }

          setHasMore(res.current_page !== res.last_page);
          setLoading(false);
          setLoadmore(false);
        } else {
          setHasMore(false);
          setLoading(false);
          setLoadmore(false);
          if (e === "loadmore") {
            return toast.success("All Videos are already loaded.");
          }
        }
      } catch (error) {
        toast.error(
          error?.data?.message || "It appears that something went wrong"
        );
        setLoading(false);
        setLoadmore(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list, page]
  );

  const globalButtonClick = (e) => {
    onUploadImagesVideos(e);
  };

  const handleWatch = (url) => {
    viewVideo({ url }, (result) => {
      console.log("result =>", result);
    });
  };

  const onDeleteImageVideos = async (record) => {
    try {
      setLoadingDelete((prev) => ({ ...prev, [`video_${record.id}`]: true }));
      const result = await http.delete(`public/user-galleries/${record.id}`);
      const res = result.data;
      if (res) {
        toast.success(res.message);
        //remove image
        let payloadImages = _.clone(list);
        _.remove(payloadImages, function (obj) {
          return obj.id === record.id;
        });
        setList(payloadImages);
        //end remove
      }
    } catch (error) {
      toast.error(
        error?.data?.message || "It appears that something went wrong"
      );
      setLoadingDelete((prev) => ({
        ...prev,
        [`video_${record.id}`]: false,
      }));
    }
  };

  const onAdd = () => {
    addVideo(
      {
        userData,
        profile,
      },
      (callback) => {
        if (callback) {
          getVideos();
        }
      }
    );
  };

  const reportPhotoVideo = (media = {}, type="photo") => {
    modalReport({ userData, media, type }, (callback) => {
      console.log(callback);
      if (callback && callback.success) {
        if (type === "photo") {
        }
      }
    });
  };

  //============================ useEffects ==========================
  useEffect(() => {
    if (profile) {
      getVideos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  return (
    <div className="w-full bg-white rounded-md px-5 2md:pl-29 2md:pr-24.5 py-10 md:pb-14 md:pt-8">
      <div className="w-full grid gap-y-8">
        {/* ===================== title ========================== */}
        <div className="flex justify-between items-center gap-3">
        <div className="w-full flex flex-row items-center space-x-2">
          {isOwner && !isSubscribed && <LockOutlineIcon />}
          <h1 className="text-2xl font-semibold text-lightBlack">Videos</h1>
          {/* pop info */}
          <PopInfo containerClass="relative" type="videos" />
          {isOwner &&
            <Button
              buttonName="Add Videos"
              buttonTextClass="text-xs 2sm:text-sm text-white font-semibold flex items-center justify-center"
              buttonRadius="6px"
              iconPosition="left"
              icon={<FaPlus className="mr-2" />}
              buttonClass="relative py-3.5 2sm:py-5 px-5 2sm:px-10 bg-primary hover:bg-darkPrimary w-full 2sm:w-fit"
              onClick={onAdd}
            />
          }

        </div>
        {
          (isOwner && isSubscribed) && <>
          {showDeleteBtn ?
            <Button
                  buttonName="Cancel"
                  buttonTextClass={buttonProps.edit.btnTextClass}
                  buttonClass={buttonProps.edit.btnClass}
                  onClick={() => { 
                      setShowDeleteBtn((prev) => !prev); 
                  }}
                /> 
              :
              <Popup
                    ref={optionRef}
                    position="bottom right"
                    open={showMoreOption}
                    onClose={() => setShowMoreOption(false)}
                    arrow={false}
                    contentStyle={{
                      marginTop: 10,
                      marginLeft: 5,
                      zIndex: 9999,
                    }}
                    trigger={
                      <button
                        className="bg-thBg w-10 h-10 rounded-md"
                        onClick={() => setShowMoreOption((open) => !open)}>
                        <FaEllipsisH className="m-auto text-1xs 2sm:text-base" />
                      </button>
                    }>
                  <div className="flex flex-col shadow-lg rounded-md bg-white">
                    <ul>
                      <li className="hover:bg-gray hover:rounded-t-md">
                        <button
                          className="flex flex-row items-center text-lg px-6 py-2"
                          onClick={() => {
                            setShowDeleteBtn(true);   
                            optionRef.current.close()
                          }}>
                          Edit Videos
                        </button>
                      </li>
                      <li className="hover:bg-gray hover:rounded-b-md" >
                        <button
                          className="flex flex-row items-center text-lg px-6 py-2"
                          onClick={() => {
                            onAdd();
                            optionRef.current.close()
                          }}>
                          Add Videos
                        </button>
                      </li>
                    </ul>
                  </div>
                
              </Popup>
              }
          </>
        } 
          </div>
        {/* =================== Body ============================= */}
        {
          !isOwner
            ? <>
              <div className="w-full">
                {loading ? (
                  <Loader message={<EmptyVideosIconSVG />} />
                ) : (
                  <>
                    {_.isEmpty(list) ? (
                      <EmptyData
                        icon={TypeOject.emptIcon["video"]}
                        emptyMessage={emptyMessage}
                      />
                    ) : (
                      <ListVideos
                        handleAdd={noop}
                        handleWatch={(e) => handleWatch(e)}
                        loading={uploadLoading}
                        loadingDelete={{}}
                        onCallBack={(e) => globalButtonClick(e)}
                        list={list}
                        message="Add Videos"
                        handleDelete={isOwner ? (e) => onDeleteImageVideos(e) : noop}
                        readOnly={true}
                        reportPhotoVideo={reportPhotoVideo}
                        checkReported={checkReported}
                        showDeleteBtn={showDeleteBtn}
                      />
                    )}
                  </>
                )}
              </div>
            </>
            : (
              isSubscribed ? (
                <>
                  <div className="w-full">
                    {loading ? (
                      <Loader message={<EmptyVideosIconSVG />} />
                    ) : (
                      <>
                        {_.isEmpty(list) ? (
                          <EmptyData
                            icon={TypeOject.emptIcon["video"]}
                            emptyMessage={emptyMessage}
                          />
                        ) : (
                          <ListVideos
                            handleAdd={onAdd}
                            handleWatch={(e) => handleWatch(e)}
                            loading={uploadLoading}
                            loadingDelete={loadingDelete}
                            onCallBack={(e) => globalButtonClick(e)}
                            list={list}
                            message="Add Videos"
                            handleDelete={(e) => onDeleteImageVideos(e)}
                            isOwner={isOwner}
                            showDeleteBtn={showDeleteBtn}
                          />
                        )}
                      </>
                    )}
                  </div>
  
                  {/* =================== Footer ============================= */}
                  <div className="w-full grid justify-center">
                    {_.isEmpty(list) && !loading && isOwner ? (
                      <button
                        onClick={onAdd}
                        className={`relative py-4 px-6 rounded-md bg-modalGray ${
                          uploadLoading ? "opacity-50" : "hover:bg-gray"
                        }`}>
                        <div className="flex items-center space-x-2.5">
                          {!loading && <FaPlus className=" w-3 h-3" />}
                          <span>{loading ? "Uploading ...." : "Add Videos"}</span>
                          {loading && <LoadingIndicator className="w-5 h-5" />}
                        </div>
                      </button>
                    ) : (
                      <>
                        {!loading && hasMore && (
                          <Button
                            onClick={() => getVideos("loadmore")}
                            buttonName="Load More"
                            buttonTextClass={`text-sm text-primary font-semibold ${
                              loadmore
                                ? "flex items-center space-x-2"
                                : "load-more-text"
                            }`}
                            buttonRadius="6px"
                            buttonClass={`relative py-4 px-6 border-2 border-primary rounded-md ${
                              loadmore ? "" : "load-more-hover"
                            }`}
                            disabled={loadmore}
                            loadingIcon={
                              loadmore ? (
                                <LoadingIndicator className="text-primary w-4 h-4" />
                              ) : null
                            }
                          />
                        )}
                      </>
                    )}
                  </div>
                </>
              )
              : (
                <UpgradePremium styleProp="list" />
              )
            )
          }
      </div>
    </div>
  );
}

function ListVideos({
  list = [],
  type = "",
  message = "",
  onCallBack = noop,
  loading = false,
  loadingDelete = {},
  handleAdd = noop,
  handleWatch = noop,
  handleDelete = noop,
  readOnly = false,
  isOwner = false,
  reportPhotoVideo = noop,
  checkReported,
  showDeleteBtn=false
}) {
  return (
    <div className="w-full relative">
      <div className="w-full grid 1sm:grid-cols-2 1md:grid-cols-3 4md:grid-cols-4 lg:grid-cols-5 gap-5.6">
        {(list || []).map((obj, index) => (
          <Media
            key={`video-container-${index}`}
            index={index}
            obj={obj}
            readOnly={readOnly}
            loadingDelete={readOnly ? noop : loadingDelete}
            handleWatch={(e) => handleWatch(e)}
            handleDelete={readOnly ? noop : (e) => handleDelete(e)}
            reportPhotoVideo={reportPhotoVideo}
            checkReported={checkReported}
            showDeleteBtn={showDeleteBtn}
          />
        ))}
      </div>
    </div>
  );
}

function Media({
  index,
  obj,
  readOnly = false,
  loadingDelete = {},
  handleWatch,
  handleDelete,
  reportPhotoVideo,
  checkReported,
  showDeleteBtn=false
}) {
  const [showMoreOption, setShowMoreOption] = useState();

  return (
    <div
      className={`relative photos-videos-container cursor-pointer rounded-md${readOnly ? " photo-video-item-wrapper" : ""}`}
      key={`video-${index}`}>
      {
        readOnly ?
          <>
            <div
              className={`flex justify-center items-center photo-video-hover-menu${showMoreOption ? " active" : ""}`}
              onClick={() => setShowMoreOption((open) => !open)}>
              <FaEllipsisH className="p-0 m-0" />
            </div>

            <Popup
              position="bottom right"
              open={showMoreOption}
              onClose={() => setShowMoreOption(false)}
              arrow={false}
              contentStyle={{ marginTop: 10, marginLeft: 5 }}
              trigger={
                <div className="flex justify-center items-center photo-video-hover-menu-hidden"></div>
              }>
              <div className="flex flex-col shadow-lg rounded-md bg-white">
                <ul className="w-full">
                  <li className="hover:bg-gray hover:rounded-md">
                    <button
                      onClick={() => {
                        reportPhotoVideo(obj, "video");
                        setShowMoreOption(false);
                      }}
                      className={`flex flex-row items-center text-lg px-4 py-2 gap-2 ${
                        checkReported && "bg-gray cursor-not-allowed"
                      }`}>
                      <PiWarningOctagonLight className="text-lg" />
                      {`${checkReported ? "Reported" : "Report Video"}`}
                    </button>
                  </li>
                  {/* {isYourProfile && (
                    <li className="hover:bg-gray hover:rounded-md">
                      <button
                        onClick={() => {
                          // history.push("/settings", {
                          //   profile,
                          // });
                        }}
                        className="flex flex-row items-center text-lg px-4 py-2 gap-2">
                        <FiSettings className="text-lg" />
                        Settings
                      </button>
                    </li>
                  )} */}
                </ul>
              </div>
            </Popup>
          </>
          :
          <> 
          {
            showDeleteBtn && <button
            className="h-8 w-8 absolute -right-4 -top-4 bg-black opacity-90 rounded-full"
            onClick={() => handleDelete(obj)}
            disabled={loadingDelete[`video_${obj.id}`]}>
            {loadingDelete[`video_${obj.id}`] ? (
              <LoadingIndicator className="h-4 w-4 text-white m-auto" />
            ) : (
              <IoClose className="text-white text-lg m-auto" />
            )}
          </button>
          }          
          </>
          
      }
      <img
        onClick={() => handleWatch(obj.link)}
        src={obj.media?.webp_url || obj.media?.url || "https://вязники.рф/app/plugins/video-thumbnails/default.jpg" }//assume for thumbnail for now
        alt={obj.id}
        className="w-full h-full object-cover object-center rounded-md"
      />
      <div className="w-12 h-12 absolute top-[25%] right-[50%]" onClick={() => handleWatch(obj.link)}>
        <PlayIconCircleWhiteSVG/>
      </div>
    </div>
  );
}
