import React from "react";
import UserGearIconSVG from "assets/svg/user-gear-icon";
import GraduationCapIconSVG from "assets/svg/graduation-cap-icon";
import ChatUserIconSVG from "assets/svg/chat-user-icon";
import BriefCaseExperienceIconSVG from "assets/svg/brief-case-icon";
import { Profile1 } from "constants/images";
import { FiBriefcase } from "react-icons/fi";
import { BiBuildingHouse } from "react-icons/bi";
import { FaRegUserCircle } from "react-icons/fa";
import ResumeLineDividerIconSVG from "assets/svg/resume-line-divider-icon";
import ResumeLineVerticalDividerIconSVG from "assets/svg/resume-line-divider-icon-vertical";
import _ from "lodash";
import { groupArraysWithType, A4_SIZE } from "../../resume";
import { personalDateFormat } from "utils/utils";
import profile from "pages/profile/profile";

export default function TemplateFourPageOne(props) {
  // ===================== props ============================
  const {
    active,
    previewImage,
    details = {},
    personal = {},
    primaryColor,
    tertiaryColor,
    milestones,
    setTotalPage,
    page,
    totalPage,
  } = props;

  React.useEffect(() => {
    let groupAll = groupArraysWithType(arraysWithType, 10, 10);
    setTotalPage(groupAll.length + 1);
  }, [milestones]);

  const arraysWithType = [
    { array: milestones?.featured || [], type: 'featured' },
    { array: milestones?.current || [], type: 'current' },
    { array: milestones?.past || [], type: 'past' },
  ];

  return (
    <div className={`${!active ? "hidden" : ""} relative`} style={A4_SIZE}>
      <div className="w-full bg-white h-full" style={{ minHeight: "1123px" }}>
        {/* ouside padding */}
        <div className="w-full h-full template-four-padding">
          {/* inner padding */}
          <div className="w-full h-full grid p-2.5 relative grid-row-auto">
            {/* body start */}
            <div className="w-full grid gap-y-5">
              {/* header */}
              <div className="w-full flex flex-row justify-between items-center">
                <div className="flex flex-col gap-y-2">
                  <p
                    className="text-2.5xl font-bold leading-6.6 tracking-resumeName"
                    style={{ color: tertiaryColor }}>
                    {details.username}
                  </p>
                  <div className="flex flex-row gap-x-1.5 text-resumeTempFourTextColor items-center">
                    {personal.hasOwnProperty("name") && personal.name && (
                      <p className="text-6xs leading-2">{personal.name}</p>
                    )}
                    {personal.hasOwnProperty("name") &&
                      personal.name &&
                      personal.hasOwnProperty("gender") &&
                      personal.gender && (
                        <span className="text-1xs opacity-30">&#8226;</span>
                      )}
                    {personal.hasOwnProperty("gender") && personal.gender && (
                      <p className="text-6xs leading-2">{personal.gender}</p>
                    )}
                    {personal.hasOwnProperty("gender") &&
                      personal.gender &&
                      personal.hasOwnProperty("pronoun") &&
                      personal.pronoun && (
                        <span className="text-6xs opacity-30">&#8226;</span>
                      )}
                    {personal.hasOwnProperty("pronoun") && personal.pronoun && (
                      <p className="text-6xs leading-2">{personal.pronoun}</p>
                    )}
                    {personal.hasOwnProperty("address") && personal.address && (
                      <>
                        <span className="text-6xs opacity-30">&#8226;</span>
                        <p className="text-6xs leading-2">{personal.address}</p>
                      </>
                    )}
                  </div>
                </div>
                {
                    personal?.profile_image &&
                    <>
                      <div className="w-17.5 h-17.5 rounded-2xl relative overflow-auto">
                        {(personal.hasOwnProperty("profile_image") && personal.profile_image) || previewImage ? (
                          <img
                            src={previewImage ?? personal.profile_image}
                            className="w-full h-full object-cover object-center"
                            alt="resume-profile"
                          />
                        ) : (
                          <img
                            alt="resume-profile"
                            src={Profile1}
                            className="w-full h-full object-cover object-center"
                          />
                        )}
                      </div>
                    </>
                  }
              </div>
              {/* end header */}
              {/* Divider */}
              <div className="w-full">
                <ResumeLineDividerIconSVG full={true} />
              </div>
              {/*  */}
              <div className="w-full">
                <div className="w-full flex flex-row gap-x-6">
                  {/* Personal Info */}
                  <div className="w-full">
                    <p className="text-resumeTempFourTextPrimary text-6xs font-medium tracking-resumeFour">
                      Personal Information
                    </p>
                    <div className="w-full mt-1.5">
                      <ul className="w-full grid gap-y-0.5">
                        {personal.hasOwnProperty("occupations") &&
                          personal.occupations.length > 0 && (
                            <li className="w-full flex space-x-1.5">
                              <FiBriefcase
                                className="opacity-70 w-2.5 h-2.5"
                                style={{ color: primaryColor }}
                              />
                              <p className="text-7xs text-resumeTempFourTextPrimary leading-3">
                                {(personal.occupations || []).map(
                                  (val, index) => {
                                    const count = personal.occupations.length;
                                    if (index >= count - 1 && count !== 1) {
                                      return " and " + val;
                                    } else {
                                      if (index >= count - 2) {
                                        return val;
                                      } else {
                                        return val + ", ";
                                      }
                                    }
                                  }
                                )}
                              </p>
                            </li>
                          )}
                        <li className="w-full flex space-x-1.5">
                          <BiBuildingHouse
                            className="opacity-70 w-2.5 h-2.5"
                            style={{ color: primaryColor }}
                          />
                          <p className="text-7xs text-resumeTempFourTextPrimary leading-3">
                            Agency Company
                          </p>
                        </li>
                        {personal.hasOwnProperty("profile_type") &&
                          personal.profile_type.length > 0 && (
                            <li className="w-full flex space-x-1.5">
                              <FaRegUserCircle
                                className="opacity-70 w-2.5 h-2.5"
                                style={{ color: primaryColor }}
                              />
                              <div className="w-full flex flex-row gap-x-1.5 items-center">
                                {(personal.profile_type || []).map(
                                  (value, index) => {
                                    let seperator = (index + 1) % 2 === 0;
                                    if (seperator) {
                                      return (
                                        <React.Fragment key={index}>
                                          <span className="text-7xs opacity-30 leading-3">
                                            &#8226;
                                          </span>
                                          <p className="text-7xs text-resumeTempFourTextPrimary leading-3">
                                            {value}
                                          </p>
                                        </React.Fragment>
                                      );
                                    }
                                    return (
                                      <p
                                        className="text-7xs text-resumeTempFourTextPrimary leading-3"
                                        key={index}>
                                        {value}
                                      </p>
                                    );
                                  }
                                )}
                              </div>
                            </li>
                          )}
                      </ul>
                    </div>
                  </div>
                  {/* divider */}
                  {details.hasOwnProperty("bio") && details.bio && (
                    <div>
                      <ResumeLineVerticalDividerIconSVG className="h-full" />
                    </div>
                  )}
                  {/* Bio */}
                  <div className="w-full grid gap-y-0.5">
                    {details.hasOwnProperty("bio") && details.bio && (
                      <div className="w-full">
                        <p className="text-resumeTempFourTextPrimary text-6xs font-medium tracking-resumeFour">
                          Bio
                        </p>
                        <div className="w-full mt-1.5">
                          <p className="text-7xs text-resumeTempFourTextPrimary tracking-resume leading-3.5 self-stretch">
                            {details.bio}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* Divider */}
              <div className="w-full">
                <ResumeLineDividerIconSVG full={true} />
              </div>
              {/*  More Information */}
              <div className="w-full">
                <p className="text-resumeTempFourTextPrimary text-6xs font-medium tracking-resumeFour">
                  More Information
                </p>
                <div className="w-full grid grid-cols-2 gap-x-6 mt-2">
                  {/* More Information */}
                  <div className="w-full grid gap-y-0.5">
                    {/* Date of Birth */}
                    {details.hasOwnProperty("birthdate") &&
                      details.birthdate && (
                        <div className="w-full flex flex-row space-x-4">
                          <div className="w-15.5">
                            <p className="text-7xs text-resumeTempFourTextSecondary leading-3">
                              Date of Birth
                            </p>
                          </div>
                          <p className="text-7xs text-resumeTempFourTextPrimary leading-3">
                            {personalDateFormat(details.birthdate, profile?.dateFormat)}
                          </p>
                        </div>
                      )}
                    {/* Birth Location */}
                    {details.hasOwnProperty("birth_location") &&
                      details.birth_location && (
                        <div className="w-full flex flex-row space-x-4">
                          <div className="w-15.5">
                            <p className="text-7xs text-resumeTempFourTextSecondary leading-3">
                              Birth Location
                            </p>
                          </div>
                          <p className="text-7xs text-resumeTempFourTextPrimary leading-3">
                            {details.birth_location}
                          </p>
                        </div>
                      )}

                    {/* Civil Status */}
                    {details.hasOwnProperty("civil_status") &&
                      details.civil_status && (
                        <div className="w-full flex flex-row space-x-4">
                          <div className="w-15.5">
                            <p className="text-7xs text-resumeTempFourTextSecondary leading-3">
                              Civil Status
                            </p>
                          </div>
                          <p className="text-7xs text-resumeTempFourTextPrimary leading-3">
                            {details.civil_status}
                          </p>
                        </div>
                      )}

                    {/* Children */}
                    {details.hasOwnProperty("children") &&
                      details.children !== 0 && (
                        <div className="w-full flex flex-row space-x-4">
                          <div className="w-15.5">
                            <p className="text-7xs text-resumeTempFourTextSecondary leading-3">
                              Children
                            </p>
                          </div>
                          <p className="text-7xs text-resumeTempFourTextPrimary leading-3">
                            {details.children} Children
                          </p>
                        </div>
                      )}
                    {/* Other Name */}
                    {details.hasOwnProperty("other_name") &&
                      details.other_name && (
                        <div className="w-full flex flex-row space-x-4">
                          <div className="w-15.5">
                            <p className="text-7xs text-resumeTempFourTextSecondary leading-3">
                              Other Name
                            </p>
                          </div>
                          <p className="text-7xs text-resumeTempFourTextPrimary leading-3">
                            {details.other_name}
                          </p>
                        </div>
                      )}
                  </div>
                  {/* continue */}
                  <div className="w-full grid gap-y-0.5">
                    {/* Hair Color */}
                    {details.hasOwnProperty("hair_color") &&
                      details.hair_color && (
                        <div className="w-full flex flex-row space-x-4">
                          <div className="w-15.5">
                            <p className="text-7xs text-resumeTempFourTextSecondary leading-3">
                              Hair Color
                            </p>
                          </div>
                          <p className="text-7xs text-resumeTempFourTextPrimary leading-3">
                            {details.hair_color}
                          </p>
                        </div>
                      )}
                    {/* Eye Color */}
                    {details.hasOwnProperty("eye_color") &&
                      details.eye_color && (
                        <div className="w-full flex flex-row space-x-4">
                          <div className="w-15.5">
                            <p className="text-7xs text-resumeTempFourTextSecondary leading-3">
                              Eye Color
                            </p>
                          </div>
                          <p className="text-7xs text-resumeTempFourTextPrimary leading-3">
                            {details.eye_color}
                          </p>
                        </div>
                      )}

                    {/* Height */}
                    {details.hasOwnProperty("height") && details.height && (
                      <div className="w-full flex flex-row space-x-4">
                        <div className="w-15.5">
                          <p className="text-7xs text-resumeTempFourTextSecondary leading-3">
                            Height
                          </p>
                        </div>
                        <p className="text-7xs text-resumeTempFourTextPrimary leading-3">
                          {details.height}cm
                        </p>
                      </div>
                    )}

                    {/* Phone  */}
                    {details.hasOwnProperty("phone_number") &&
                      details.phone_number && (
                        <div className="w-full flex flex-row space-x-4">
                          <div className="w-15.5">
                            <p className="text-7xs text-resumeTempFourTextSecondary leading-3">
                              Phone
                            </p>
                          </div>
                          <p className="text-7xs text-resumeTempFourTextPrimary leading-3">
                            {details.phone_number}
                          </p>
                        </div>
                      )}
                    {/* Phone  */}
                    {details.hasOwnProperty("email") && details.email && (
                      <div className="w-full flex flex-row space-x-4">
                        <div className="w-15.5">
                          <p className="text-7xs text-resumeTempFourTextSecondary leading-3">
                            Email Address
                          </p>
                        </div>
                        <p className="text-7xs text-resumeTempFourTextPrimary leading-3">
                          {details.email}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* Divider */}
              <div className="w-full">
                <ResumeLineDividerIconSVG full={true} />
              </div>
              {/*  */}
              {((details.hasOwnProperty("occupations") &&
                !_.isEmpty(details.occupations)) ||
                details.education ||
                details.training ||
                details.experience) && (
                  <div className="w-full grid gap-y-4">
                    {/*  */}
                    {details.hasOwnProperty("occupations") &&
                      !_.isEmpty(details.occupations) && (
                        <div className="w-full flex flex-row space-x-1 items-start">
                          <div className="w-50 flex flex-row space-x-2 items-center">
                            <UserGearIconSVG
                              className="w-4 h-4"
                              fill={primaryColor}
                            />
                            <p className="text-6xs text-resumeTempFourTextPrimary font-medium tracking-resumeFour">
                              Abilities / Skills / Specialties
                            </p>
                          </div>
                          <div style={{ width: "calc(100% - 204px)" }}>
                            <div
                              className="w-full flex flex-row space-x-1.5 text-1xs font-bold"
                              style={{ color: tertiaryColor + "E6" }}>
                              {details.occupations.map((oc, i) => (
                                <span
                                  key={i}
                                  className="template-four-padding-skills rounded text-opacity-90 bg-opacity-7"
                                  style={{
                                    backgroundColor: tertiaryColor + "1F", // opcity 12%
                                  }}>
                                  {oc}
                                </span>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                    {/*  */}
                    {details.hasOwnProperty("education") && details.education && (
                      <div className="w-full flex flex-row space-x-1 items-start">
                        <div className="w-50 flex flex-row space-x-2 items-center">
                          <GraduationCapIconSVG
                            className="w-4 h-4"
                            fill={primaryColor}
                          />
                          <p className="text-6xs text-resumeTempFourTextPrimary font-medium tracking-resumeFour">
                            Education
                          </p>
                        </div>
                        <div style={{ width: "calc(100% - 204px)" }}>
                          <p className="text-7xs text-resumeTempFourTextPrimary tracking-resume leading-3.5 self-stretch opacity-90">
                            {details.education}
                          </p>
                        </div>
                      </div>
                    )}

                    {/*  */}
                    {details.hasOwnProperty("training") && details.training && (
                      <div className="w-full flex flex-row space-x-1 items-start">
                        <div className="w-50 flex flex-row space-x-2 items-center">
                          <ChatUserIconSVG
                            className="w-4 h-4"
                            fill={primaryColor}
                          />
                          <p className="text-6xs text-resumeTempFourTextPrimary font-medium tracking-resumeFour">
                            Training
                          </p>
                        </div>
                        <div style={{ width: "calc(100% - 204px)" }}>
                          <p className="text-7xs text-resumeTempFourTextPrimary tracking-resume leading-3.5 self-stretch opacity-90">
                            {details.training}
                          </p>
                        </div>
                      </div>
                    )}
                    {/*  */}
                    {details.hasOwnProperty("experience") &&
                      details.experience && (
                        <div className="w-full flex flex-row space-x-1 items-start">
                          <div className="w-50 flex flex-row space-x-2 items-center">
                            <BriefCaseExperienceIconSVG
                              className="w-4 h-4"
                              fill={primaryColor}
                            />
                            <p className="text-6xs text-resumeTempFourTextPrimary font-medium tracking-resumeFour">
                              Experience
                            </p>
                          </div>
                          <div style={{ width: "calc(100% - 204px)" }}>
                            <p className="text-7xs text-resumeTempFourTextPrimary tracking-resume leading-3.5 self-stretch opacity-90">
                              {details.experience}
                            </p>
                          </div>
                        </div>
                      )}
                  </div>
                )}
              {/* end */}
            </div>
            {/* footer */}
            <div className="w-full flex items-end pt-10">
              <div className="w-full">
                <ResumeLineDividerIconSVG full={true} />
                <div className="w-full grid pt-3.5 justify-center">
                  <p className="text-7xs text-resumeTempFourTextPrimary leading-3.5 opacity-90">
                    Page {page} of {totalPage}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
