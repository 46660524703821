const CropIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 7.5C19 6.121 17.879 5 16.5 5H8V2H5V5H2V8H16V22H19V19H22V16H19V7.5Z"
        fill="currentColor"
      />
      <path
        d="M8 10H5V16.5C5 17.879 6.121 19 7.5 19H14V16H8V10Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CropIconSVG;
