const UsersXLIconSVG = ({ className = "" }) => {
  return (
    <svg
      className={className}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8182 18.5456V16.8183C14.8182 15.9021 14.4542 15.0234 13.8064 14.3756C13.1585 13.7277 12.2798 13.3638 11.3636 13.3638H4.45455C3.53834 13.3638 2.65967 13.7277 2.01181 14.3756C1.36396 15.0234 1 15.9021 1 16.8183V18.5456"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.90917 9.90909C9.81706 9.90909 11.3637 8.36244 11.3637 6.45455C11.3637 4.54665 9.81706 3 7.90917 3C6.00127 3 4.45462 4.54665 4.45462 6.45455C4.45462 8.36244 6.00127 9.90909 7.90917 9.90909Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.9999 18.5456V16.8183C19.9994 16.0529 19.7446 15.3094 19.2757 14.7044C18.8067 14.0995 18.1501 13.6674 17.409 13.4761"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9546 3.1123C14.6977 3.30256 15.3563 3.73473 15.8267 4.34066C16.297 4.9466 16.5523 5.69184 16.5523 6.4589C16.5523 7.22595 16.297 7.97119 15.8267 8.57713C15.3563 9.18306 14.6977 9.61523 13.9546 9.80549"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UsersXLIconSVG;
