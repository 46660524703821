import {
  REFRESH_POSITION_FILTER,
	SET_POSITION_FILTER,
} from './types';

export const refreshPositionFilter = () => {
	return {
    type: REFRESH_POSITION_FILTER,
    payload: [],
  };
}

export const setPositionFilter = (params) => {
	return {
    type: SET_POSITION_FILTER,
    payload: params,
  };
}