import React from "react";
import "./styles.css";

const Divider = ({
  text = "",
  color = "resumeBorder",
  paddingY = "",
  paddingX = "",
}) => {
  return (
    <div
      className={`w-full flex items-center justify-between ${paddingX} ${paddingY}`}
    >
      <div className={`h-px border border-${color} w-full`} />
      {text && <p className="divider-text px-3">{text}</p>}
      <div className={`h-px border border-${color} w-full`} />
    </div>
  );
};

export default Divider;
