


const HeroPateonIcon = ({ className = "" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"  
            viewBox="0 0 100 100" 
            width="28px" height="28px">
            <rect width="10" height="62" x="24" y="19" fill="#f05b6c"/>
            <path fill="#1f212b" d="M34,82H24c-0.553,0-1-0.447-1-1V19c0-0.553,0.447-1,1-1h10c0.553,0,1,0.447,1,1v62 C35,81.553,34.553,82,34,82z M25,80h8V20h-8V80z"/><circle cx="63" cy="41" r="22" fill="#f05b6c"/><path fill="#1f212b" d="M63,64c-12.683,0-23-10.317-23-23s10.317-23,23-23s23,10.317,23,23S75.683,64,63,64z M63,20 c-11.579,0-21,9.421-21,21s9.421,21,21,21s21-9.421,21-21S74.579,20,63,20z"/><path fill="#1f212b" d="M30.5,27c-0.276,0-0.5-0.224-0.5-0.5v-4c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v4	C31,26.776,30.776,27,30.5,27z"/><path fill="#1f212b" d="M30.5,78c-0.276,0-0.5-0.224-0.5-0.5v-48c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v48	C31,77.776,30.776,78,30.5,78z"/><path fill="#1f212b" d="M73.25,27.314c-0.102,0-0.204-0.031-0.293-0.095c-0.985-0.713-2.047-1.32-3.157-1.806	c-0.253-0.11-0.368-0.405-0.258-0.658s0.407-0.365,0.658-0.258c1.176,0.514,2.3,1.156,3.343,1.911	c0.224,0.162,0.274,0.475,0.112,0.698C73.558,27.243,73.404,27.314,73.25,27.314z"/><path fill="#1f212b" d="M63,59c-0.276,0-0.5-0.224-0.5-0.5S62.724,58,63,58c9.374,0,17-7.626,17-17	c0-4.353-1.644-8.492-4.627-11.657c-0.189-0.201-0.18-0.518,0.021-0.707c0.201-0.188,0.517-0.18,0.707,0.021	C79.26,32.009,81,36.392,81,41C81,50.925,72.925,59,63,59z"/></svg>
    );
  };
  
  export default HeroPateonIcon;
  
  
  