import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";

/**
 * buttonName = for button text inside button container
 * buttonWidth = custom width for button
 * buttonClass = customized class for button
 * buttonTextClass = custom classname for button text inside
 * onClick = function for clicking the button
 */

const Button = ({
  buttonName = "",
  buttonWidth,
  buttonClass = "defaultButton",
  buttonRadius = "6px",
  buttonTextClass = "defaultButtonText",
  hover = "",
  iconPosition = "right",
  icon = null,
  loadingIcon = null,
  onClick = () => {},
  disabled = false,
  link = false,
  path = "/",
  ...props
}) => {
  if (!link) {
    return (
      <button
        className={buttonClass + ` ${!disabled && `hover:${hover}`}`}
        disabled={disabled}
        onClick={onClick}
        style={
          buttonWidth
            ? {
                width: buttonWidth,
                borderRadius: buttonRadius,
                opacity: (disabled || loadingIcon) && 0.5,
                cursor: disabled && "default",
              }
            : {
                borderRadius: buttonRadius,
                opacity: (disabled || loadingIcon) && 0.5,
                cursor: disabled && "default",
              }
        }
        {...props}>
        <div className={buttonTextClass}>
          {iconPosition === "left" && icon}
          <span>{buttonName}</span>
          {iconPosition === "right" && icon}
          {loadingIcon && loadingIcon}
        </div>
      </button>
    );
  } else {
    return (
      <Link
        to={path}
        className={buttonClass + ` ${!disabled && `hover:${hover}`}`}
        style={
          buttonWidth
            ? {
                width: buttonWidth,
                borderRadius: buttonRadius,
                opacity: disabled && 0.5,
                cursor: disabled && "default",
              }
            : {
                borderRadius: buttonRadius,
                opacity: disabled && 0.5,
                cursor: disabled && "default",
              }
        }>
        <span className={buttonTextClass}>
          {iconPosition === "left" && icon}
          <span>{buttonName}</span>
          {iconPosition === "right" && icon}
        </span>
      </Link>
    );
  }
};

export default Button;
