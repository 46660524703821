const FileUserRepresentationIconSVG = ({
  className = "",
  fill = "#333333",
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2447_3882)">
        <path
          d="M12 10C10.07 10 8.5 11.57 8.5 13.5C8.5 15.43 10.07 17 12 17C13.93 17 15.5 15.43 15.5 13.5C15.5 11.57 13.93 10 12 10ZM12 15C11.173 15 10.5 14.327 10.5 13.5C10.5 12.673 11.173 12 12 12C12.827 12 13.5 12.673 13.5 13.5C13.5 14.327 12.827 15 12 15ZM19.949 5.536L16.465 2.05C15.142 0.728 13.384 0 11.515 0H7C4.243 0 2 2.243 2 5V19C2 21.757 4.243 24 7 24H17C19.757 24 22 21.757 22 19V10.485C22 8.614 21.271 6.858 19.949 5.536ZM18.535 6.951C18.853 7.268 19.122 7.621 19.34 8.001H14.999C14.447 8.001 13.999 7.552 13.999 7.001V2.659C14.379 2.877 14.732 3.146 15.05 3.464L18.534 6.95L18.535 6.951ZM9.172 22.001C9.585 20.837 10.696 20.001 12 20.001C13.304 20.001 14.415 20.837 14.828 22.001H9.172ZM20 19.001C20 20.655 18.654 22.001 17 22.001H16.899C16.434 19.722 14.414 18.001 12 18.001C9.586 18.001 7.565 19.722 7.101 22.001H7C5.346 22.001 4 20.655 4 19.001V5C4 3.346 5.346 2 7 2H11.515C11.678 2 11.84 2.008 12 2.023V7C12 8.654 13.346 10 15 10H19.977C19.992 10.16 20 10.322 20 10.485V19.001Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2447_3882">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FileUserRepresentationIconSVG;
