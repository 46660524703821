import {
	REFRESH_COUNTRIES,
	SET_COUNTRIES,
} from './types'

const initialState = {
	countries: [],
	refresh: false,
	initialize: true
};

const reducer = (state = initialState, {type, payload}) => {

	switch (type) {

		case REFRESH_COUNTRIES:
			return {
				...state,
				refresh: true,
				initialize: true,
				countries: [],
			}
		
		case SET_COUNTRIES:
			return {
				...state,
				refresh: false,
				initialize: false,
				countries: [...state.countries, ...payload]
			}

		default:
			return state;

	}

}

export default reducer