import React, { useEffect, useRef, useState, useCallback } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { canvasToFile } from "utils/constant";

export default function CropCoverSquare({
  setParentCrop = () => {},
  setCropPreview = () => {},
  locked = false,
  ...props
}) {
  const { image, type, returnType } = props;
  const imgSrc = image;
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const zoom = 1;
  const [aspect, setAspect] = useState(undefined);
  const [lock, setLock] = useState(locked);

  const getCropData = useCallback(async () => {
    if (completedCrop?.width && completedCrop?.height && imgRef.current) {
      const { blobImage, image, base64Canvas } = await canvasToFile(
        imgRef.current,
        completedCrop,
        zoom,
        0,
        "crop-image-free-mode",
        type
      );
      if (blobImage) {
        if (returnType === "blob") {
          setParentCrop(blobImage);
        } else {
          setParentCrop(image);
        }
        setCropPreview(base64Canvas);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedCrop, zoom]);

  function onImageLoad(e) {
    const { width, height } = e.currentTarget;
    let defaultM = {
      width: width > 1400 ? 1400 : width,
      height: 400,
    };
    let finalW = defaultM.width;
    let finalH = defaultM.height;

    if (defaultM.width >= width) {
      finalW = width;
    }
    if (defaultM.height >= height) {
      finalH = height;
    }

    if (defaultM.width >= width && defaultM.height >= height) {
      setLock(true);
    }
    //calculate aspect ratio
    let aw = finalH / finalW;
    let aspectRatioW = aw * defaultM.width;

    let ah = finalW / finalH;
    let aspectRatioH = ah * defaultM.height;

    //set aspect ratio
    setAspect(aspectRatioH / aspectRatioW);

    setCrop({
      unit: "px",
      x: 0,
      y: 0,
      width: finalW,
      height: finalH,
    });
  }

  useEffect(() => {
    getCropData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedCrop]);

  return (
    <div className="w-full">
      <div
        className="w-full relative bg-passwordGray rounded-md flex justify-center items-center"
        style={{ minHeight: "400px" }}
      >
        <div
          className="w-full overflow-y-auto flex justify-center items-center"
          style={{ maxHeight: "73vh" }}
        >
          {!!imgSrc && (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
              locked={lock}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          )}
        </div>
      </div>
    </div>
  );
}
