import React, { useState } from "react";
import Input from "components/input/input";
import toast from "react-hot-toast";
import Button from "components/button/button";
import LoadingIndicator from "assets/svg/loading-indicator";
import Layout from "layouts/layout";
import LogoComponent from "components/logo/logo";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
  const { http } = global.services;
  const [state, setState] = useState({
    email: "",
    loading: false,
    validationErrors: null,
    success: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
      validationErrors: { ...state.validationErrors, [name]: null },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setState((prev) => ({ ...prev, loading: true }));

    try {
      await http.post("auth/forgot-password", state);
      setTimeout(() => {
        setState((prev) => ({
          ...prev,
          success: true,
          email: "",
          loading: false,
        }));
      }, 250);
    } catch (error) {
      if (error.status === 422) {
        return setState((prev) => ({
          ...prev,
          validationErrors: error.data,
          loading: false,
        }));
      }
      toast.error("It appears that something went wrong");
      return setState((prev) => ({
        ...prev,
        validationErrors: error.data,
        loading: false,
      }));
    }
  };

  return (
    <Layout title="Forgot Password">
      <div className="main-page-wrapper">
        <div className="login-container">
          {/*  */}
          <LogoComponent />
          {/*  */}
          <div className="w-full">
            <div className="form-container">
              <h2 className="form-column-title pb-2">Forgot Password </h2>
              {state.success ? (
                <p className="form-column-subtitle text-green">
                  Please check your inbox for the password reset link.
                </p>
              ) : (
                <p className="form-column-subtitle">
                  Provide your account's email for which you want to reset your
                  password
                </p>
              )}

              <form
                className="form-column-container"
                id="forgot-password-component"
                onSubmit={handleSubmit}
              >
                <Input
                  inputContainerClass="form-input-container"
                  value={state.email}
                  autoFocus={true}
                  name="email"
                  outsideLabel="E-mail Address"
                  outsideLabelClass="text-sm font-bold text-darkerGray"
                  noLabelPaddingY="1rem"
                  type="email"
                  onChange={handleInputChange}
                  errorMessage={state.validationErrors?.email}
                />

                <Button
                  buttonName="Request reset password link"
                  disabled={state.loading}
                  buttonTextClass="text-sm text-white font-semibold"
                  buttonRadius="6px"
                  buttonClass="relative bg-primary py-3.5 mb-10"
                  buttonWidth="100%"
                  type="submit"
                  icon={
                    <div className="absolute right-3 bottom-0 top-0 flex items-center">
                      {state.loading && (
                        <LoadingIndicator className="text-white w-5 h-5" />
                      )}
                    </div>
                  }
                />
                {/* footer */}
                <div className="border-t border-gray text-sm font-semibold text-lightBlack py-5">
                  <div className="w-full flex justify-center items-center">
                    <Link to="/login" className="text-primary">
                      Back to Login
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ForgotPassword;
