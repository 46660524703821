import {
	REFRESH_POSITIONS,
	SET_POSITIONS,
	ADD_POSITIONS
} from './types'

const initialState = {
	positions: [],
	refresh: false,
	initialize: true
};

const reducer = (state = initialState, {type, payload}) => {

	switch (type) {

		case REFRESH_POSITIONS:
			return {
				...state,
				refresh: true,
				initialize: true,
				positions: [],
			}
		
		case SET_POSITIONS:
			return {
				...state,
				refresh: false,
				initialize: false,
				positions: payload
			}
		
		case ADD_POSITIONS:
			return {
				...state,
				positions: [...state.positions, payload]
			}

		default:
			return state;

	}

}

export default reducer