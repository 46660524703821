import React, { useState } from "react";
import Modal from "components/global/modal";
import ReactPlayer from "react-player";
import "./styles.css";
import { FaTimes } from "react-icons/fa";
import { TikTokEmbed } from 'react-social-media-embed';

export default function WatchMediaModal({ hide, ...props }) {
  const { title, url, tiktok } = props;
  const [play, setPlay] = useState(true);

  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(()=>{
    const userAgent = window.navigator.userAgent;
    const mobileDevices = /Mobi|Android|iPhone|Ipad/;
    setIsMobile(mobileDevices.test(userAgent));
  },[])

  return (
    <Modal
      width={`w-full lg:w-16/20 ${isMobile && 'sm:my-0 2md:w-700 h-screen flex items-center'}`}
      contentClassName="rounded-lg overflow-auto custom-style"
      wrapperClassName={`overflow-visible custom-style shadow-none`}
      image_gallery={true}
    >
      <div className="w-full">
        <div className={`flex justify-between items-center 2md:mb-4 ${isMobile && 'bg-black px-3'}`}>
          <p className="text-xl text-white">
            <span className="font-bold mr-1">Watch:</span>
            {title}
          </p>
          {/* close */}
          <button onClick={() => hide()}>
            <FaTimes className="text-white text-xl" />
          </button>
        </div>

        {tiktok ? (
          <div className="flex justify-center">
            <TikTokEmbed url={url} />
          </div>
        ) : (
          <div className={`video-wrapper ${isMobile && 'mobile'}`}>
            <ReactPlayer
              className="player"
              url={url}
              controls={true}
              playing={play}
              width="100%"
              height="100%"
              onPlay={() => setPlay(true)}
              onPause={() => setPlay(false)}
            />
          </div>
        )}
      </div>
    </Modal>
  );
}
