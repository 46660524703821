import React, { useContext, useState, useEffect, useRef } from "react";
import { FaEllipsisH } from "react-icons/fa";
import { IoEllipse } from "react-icons/io5";
import { BsGlobe2, BsExclamationDiamondFill } from "react-icons/bs";
import UserTagIconSVG from "assets/svg/user-tag-icon";
import { Context } from "context/context";
import { formatDate } from "utils/utils";
import ViewMilestoneDialog from "../view-dialog";
import Popup from "reactjs-popup";
import OptionListItem from "../more-option/option-list-item";
import _ from "lodash";
import LazyImage from "../LazyImage";
import { useDialogHook } from "utils/customhooks";
import PrivacyBadge from "components/milestonelist/privacyBadge";
import { AiFillPushpin } from "react-icons/ai";
import { CoverPlaceholderMilestone } from "constants/images";

const ListViewCard = ({
  title,
  dateNote,
  date,
  privacy,
  people,
  position,
  featured,
  profile,
  positionDescription,
  platform,
  url = "",
  viewUrl,
  category,
  isAdultContent,
  isYourProfile,
  onView = () => {},
  onEdit = () => {},
  onDelete = () => {},
  onReport = () => {},
  dateMobile=null
}) => {
  const { showMaturedContent, setShowMaturedContent } = useContext(Context);
  const [showMoreOption, setShowMoreOption] = useState();
  const optionRef = useRef();


  useEffect(() => {
    window.addEventListener(
      "scroll",
      () => {
        if (optionRef.current) {
          optionRef.current.close();
        }
      },
      true
    );
  }, []);

  return (
    <div
      className="w-full bg-siteBg 3md:rounded-md mt-6 cursor-pointer overflow-hidden"
      onClick={onView}>
      <div className="w-full flex space-x-0.5">
        {/* image */}
        {url ? (
          <div className="relative bg-white h-auto w-23 2sm:h-auto 2sm:w-29.5 3md:w-50 3md:h-50 overflow-hidden">
            {/* thumbnail */}
            <LazyImage
              source={url}
              viewUrl={viewUrl}
              customClassName="w-full"
              secondView={true}
            />
            {/* restricted Image */}
            {!isAdultContent && !showMaturedContent && (
              <div className="absolute top-0 left-0 matured-content-blured">
                <button
                  className="w-full flex justify-center"
                  onClick={() => setShowMaturedContent((prev) => !prev)}>
                  <BsExclamationDiamondFill className="text-base 1sm:text-5xl cursor-pointer" />
                </button>
              </div>
            )}
          </div>
        ):
        <div className="relative h-auto w-23 2sm:h-auto 2sm:w-29.5 3md:w-50 3md:h-50 flex itmes-center justify-center bg-[#e9e9e9]">
            <div className="relative flex items-center justify-center h-full w-23 2sm:h-auto 2sm:w-29.5 3md:w-50 3md:h-50 bg-[#e9e9e9]">
              <img src={CoverPlaceholderMilestone} alt="Milestone" className="w-[43px] h-[41px] 3md:h-[81px]  3md:w-[83px] "/>
            </div>
        </div>
        }
        {/* content container */}
        <div
          className={`relative bg-white 2md:mt-0 px-4.5 py-3 3md:px-9 3md:py-6 ${
            url
              ? "width-responsive-list-view"
              : "width-responsive-list-view-full"
          }`}
          // style={{ width: "calc(100% - 200px)" }}
          >
          {/* content */}
          <div className="w-full flex justify-between items-center">
            <div className="w-full">
              {/* title */}
              <div className="flex items-center space-x-1 ">
                {/* <p className="whitespace-nowrap text-base 2sm:text-2xl 3md:text-2.5xl text-lightBlack font-medium tracking-tighter">
                  {title}
                </p> */}
                <div className="max-w-full max-h-12 overflow-hidden">
                <h1 className="text-[14px] leading-tight line-clamp-2 2sm:text-2xl 3md:text-2.5xl">
                {title}
                </h1>
              </div>
              </div>
              {/* category */}
              {/* <div className="flex items-center space-x-5 flex-wrap">
                <p className="px-4.5 py-1.5 bg-lightGreen text-white text-xs uppercase font-semibold rounded-md">
                  {category}
                </p>
              </div> */}
              {/* details */}
              {/* Hide when in mobile view */}
              <div className="w-full text-4xs 2sm:text-sm 3md:text-base text-darkerGray font-medium 
                      3md:leading-[16px] flex items-center space-x-2 3md:space-x-3 hidden 2sm:flex mt-0 2sm:mt-1.5">
                  <p className="relative break-words">
                    {date}
                    {dateNote && (
                      <span className="ml-1">{`(${dateNote})`}</span>
                    )}
                  </p>  
                <PrivacyBadge privacy={privacy} isYourProfile={isYourProfile}/>
                  {/* <p className="flex items-center capitalize">
                    {privacy === "public" ? (
                      <BsGlobe2 className="mr-1.5" />
                    ) : (
                      <BsExclamationDiamondFill className="mr-1.5" />
                    )}
                    {privacy}
                  </p> */}
                  {people && (
                    <>
                      <p className="hidden md:block">
                        <IoEllipse className="w-1 h-1" />
                      </p>
                      <p className="hidden md:flex items-center">
                        <UserTagIconSVG className="mr-1.5" />
                        {people}
                      </p>
                    </>
                  )}
                  <p className="inline px-1.5 py-1 hidden 2sm:inline 3md:px-4.5 3md:py-1.5 bg-lightGreen text-white text-1xs 2sm:text-xs uppercase font-semibold rounded-md">
                    {category}
                  </p>
              </div>
              {/* Author Name and description */}
               {/* Hide when in mobile view */}
              <div className="w-full">
                <p className="text-4xs 2sm:text-sm 3md:text-base font-bold text-primary 3md:leading-6 mt-1 mt-1">
                  {position}
                  {positionDescription && ` - ${positionDescription} `}
                  {platform && `(${platform})`}
                </p>
              </div>
              {/* Show when in mobile view */}
              <div className="w-full mt-4 3md:mt-4 inline 2sm:hidden"> 
                <p className="relative w-full break-words text-4xs 2sm:text-sm 3md:text-base text-darkerGray font-medium flex gap-2 items-center mt-1.5">
                    <>
                      { dateMobile && 
                      <>
                        {dateMobile?.start_year} 
                        {(dateMobile?.end_year || dateMobile?.is_present === 1) && 
                        <span>
                          - {dateMobile?.is_present ? 'Present' : dateMobile?.end_year}
                        </span>
                        }
                      </>
                      }
                    </>
                    <p className="px-1.5 py-1 3md:px-4.5 3md:py-1.5 bg-lightGreen text-white text-1xs 2sm:text-xs uppercase font-semibold rounded-md">
                      {category}
                    </p>
                  </p>
              </div>
            </div>
            {/* <button className="bg-thBg w-10 h-10 rounded-md hidden 4sm:block">
              <FaEllipsisH className="m-auto" />
            </button> */}
            <Popup
              ref={optionRef}
              position="bottom right"
              open={showMoreOption}
              onClose={() => setShowMoreOption(false)}
              arrow={false}
              contentStyle={{ marginTop: 10, marginLeft: 5 }}
              trigger={
                <button
                  className="bg-thBg w-10 h-10 rounded-md hidden 1sm:block"
                  onClick={() => setShowMoreOption((open) => !open)}>
                  <FaEllipsisH className="m-auto" />
                </button>
              }>
              <OptionListItem
                {...{
                  isYourProfile,
                  onEdit,
                  onDelete,
                  onReport,
                  featured,
                  profile,
                }}
              />
            </Popup>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function ListViewMilestones({
  showFeatured,
  isYourProfile,
  featured = {},
  profile = {},
  milestones = [],
  pin = [],
  onWatchMedia = () => {},
  onViewImage = () => {},
  onEdit = () => {},
  onDelete = () => {},
  onReport = () => {},
}) {
  // const [dialog, setDialog] = useState({
  //   milestone: {},
  //   open: false,
  // });
  const featuredThumbnail = featured.thumbnail
    ? featured.thumbnail.webp_url || featured.thumbnail.url
    : null;
  const milestoneKeys = Object.keys(milestones);
  const modalView = useDialogHook(ViewMilestoneDialog);

  /**
   * This method will handle viewing of milestone
   * setting dialog state
   */
  const onView = (milestone) => {
    modalView(
      {
        profile: profile,
        isYourProfile: isYourProfile,
        data: milestone,
        onWatchMedia: onWatchMedia,
        onViewImage: onViewImage,
        onEdit: onEdit,
        onDelete: onDelete,
        onReport: onReport,
      },
      (cb) => {
        // callback
      }
    );
  };

  /**
   * This method will handle close dialog
   * setting dialog state
   */
  // const closeDialog = () => {
  //   setDialog((prev) => ({
  //     milestone: {},
  //     open: false,
  //   }));
  // };

  return (
    <div className="w-full container-max-width px-4 3md:px-0">
      {/** dialog */}
      {/* <ViewMilestoneDialog
        data={dialog.milestone}
        open={dialog.open}
        isYourProfile={isYourProfile}
        onClose={closeDialog}
        onWatchMedia={onWatchMedia}
        onViewImage={onViewImage}
        onEdit={onEdit}
        onDelete={onDelete}
        onReport={onReport}
        profile={profile}
      /> */}
      {/** listview */}
      {showFeatured && (
        <div className="w-full">
          {/* <p className="px-7.5 3md:px-0 text-lightBlack font-bold text-2xl leading-7.5 tracking-tighter">
            Featured
          </p> */}
          {!_.isEmpty(featured) && (
            <ListViewCard
              profile={profile}
              featured={featured}
              url={featuredThumbnail}
              viewUrl={featured?.thumbnail?.url || null}
              title={featured.title}
              tag={featured.tag}
              date={featured.formatted_date}
              privacy={featured.privacy}
              people={featured.people}
              position={featured.name}
              positionDescription={featured.mp_description}
              platform={featured.platform}
              description={featured.description}
              isAdultContent={featured.is_adult_content ? true : false}
              category={featured.category && featured.category.name}
              onView={() => onView(featured)}
              onEdit={() => onEdit(featured)}
              onDelete={() => onDelete(featured)}
              isYourProfile={isYourProfile}
              onReport={() => onReport(featured)}
            />
          )}
        </div>
      )}

      <div className="w-full">
          <div >
            {pin.length > 0 &&
             <p className="milestone-title text-lightBlack font-bold text-base 2sm:text-xl 3md:text-2xl leading-7.5 tracking-tighter flex gap-3">
              <>
              Pinned
              </> 
              <AiFillPushpin className="text-2xl"/>
            </p>}
            {pin.map((obj, key) => {
              const date = formatDate(obj,profile?.dateFormat);
              const dateMobile = {start_year : obj?.start_year || null, end_year: obj?.end_year || null, is_present:obj?.is_present}
              const thumbnail = obj.thumbnail
                ? obj.thumbnail.webp_url || obj.thumbnail.url
                : null;
              return (
                <ListViewCard
                  dateMobile = {dateMobile}
                  profile={profile}
                  featured={obj}
                  key={`${key}`}
                  url={thumbnail}
                  viewUrl={obj?.thumbnail?.url || null}
                  title={obj.title}
                  tag={obj.tag}
                  date={date}
                  privacy={obj.privacy}
                  people={obj.people}
                  position={obj.name}
                  positionDescription={obj.mp_description}
                  platform={obj.platform}
                  description={obj.description}
                  isAdultContent={obj.is_adult_content ? true : false}
                  category={obj.category.name}
                  onView={() => onView(obj)}
                  onEdit={() => onEdit(obj)}
                  onDelete={() => onDelete(obj)}
                  isYourProfile={isYourProfile}
                  onReport={() => onReport(obj)}
                />
              );
            })}
          </div>
      </div>
      <div className="w-full mt-10">
        {milestoneKeys?.map?.((key) => (
          <div key={key}>
            <p className="px-0 text-lightBlack font-bold text-base 2sm:text-xl 3md:text-2xl leading-7.5 tracking-tighter mt-10">
              {key} - {milestones[key]?.length} credits
            </p>
            {milestones[key]?.map?.((obj, index) => {
              const date = formatDate(obj,profile?.dateFormat);
              const dateMobile = {start_year : obj?.start_year || null, end_year: obj?.end_year || null, is_present:obj?.is_present}
              const thumbnail = obj.thumbnail
                ? obj.thumbnail.webp_url || obj.thumbnail.url
                : null;
              return (
                <ListViewCard
                  dateMobile = {dateMobile}
                  profile={profile}
                  featured={obj}
                  key={`${key}-${index}`}
                  url={thumbnail}
                  viewUrl={obj?.thumbnail?.url || null}
                  title={obj.title}
                  tag={obj.tag}
                  date={date}
                  privacy={obj.privacy}
                  people={obj.people}
                  position={obj.name}
                  positionDescription={obj.mp_description}
                  platform={obj.platform}
                  description={obj.description}
                  isAdultContent={obj.is_adult_content ? true : false}
                  category={obj.category.name}
                  onView={() => onView(obj)}
                  onEdit={() => onEdit(obj)}
                  onDelete={() => onDelete(obj)}
                  isYourProfile={isYourProfile}
                  onReport={() => onReport(obj)}
                />
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
}
