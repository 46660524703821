import React from "react";
import ChecksDoubleIconSVG from "assets/svg/checks-icons";
import NotificationContentItem from "./notification-content-item";
import { useNotifications } from "redux/modules/notification/hooks";
import _ from "lodash";
import NotificationEmpty from "assets/svg/notificationEmpty";
export default function NotificationContainer({ 
  acceptInvite = () => {},
  onMarkRead = () => {},
  onMarkReadNotification = () => {}
}) {
  const { notifications } = useNotifications();
  let hideRead = notifications.filter((notification) => notification.is_read === 0)
  // console.log("====================notification=========================")
  // console.log(notifications);
  // console.log(hideRead)

  const markRead = async() => {
    await onMarkRead();
  }

  const markReadNotification = async(id) => {
    await onMarkReadNotification(id);
  }

  return (
    <div
      className="w-[370px] 2sm:w-419 notification-container p-6"
      style={{ minHeight: 200 }}
    >
      <div className="w-full">
        {/* header */}
        <div className="w--full flex justify-between items-center">
          <h1 className="text-lg font-semibold leading-5.5 text-lightBlack">
            Notification
          </h1>
          {!_.isEmpty(hideRead) && (hideRead.length >= 2) && (
            <button
              onClick={markRead}
              className="text-base font-medium leading-4.9 text-primary flex items-center"
            >
              <ChecksDoubleIconSVG className="mr-2" />
              Mark all as read
            </button>
          )}
        </div>
        {/* end header */}
        {/* content */}
        <div className="w-full mt-8">
          {!_.isEmpty(notifications) ? (
          <>
           {!_.isEmpty(hideRead) ? 
            <ul className="w-full grid gap-y-4">
              { 
               hideRead.map((obj, index) => (
                <>
                {console.log("::NOTIF", obj)}
                <NotificationContentItem
                  key={index}
                  {...obj}
                  acceptInvite={() => acceptInvite(obj)}
                  markReadNotification={markReadNotification}
                />
                </>
              ))
              }
            </ul>:
              <div className="text-center">
                <div className="flex justifya-center items-center flex-col">
                  <div>  
                    <NotificationEmpty/>
                  </div>
                  <div className="font-bold text-lg mt-4">No Notifications</div>
                  <div className="text-xs mt-1">There is no notification to show right now.</div>
                </div>
              </div>
          }
          </>
          ) : (
            <div className="text-center">
              <div className="flex justifya-center items-center flex-col">
                <div>  
                  <NotificationEmpty/>
                </div>
                <div className="font-bold text-lg mt-4">No Notifications</div>
                <div className="text-xs mt-1">There is no notification to show right now.</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
