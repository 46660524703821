import React, { useEffect, useState } from "react";
import AlertIconSVG from "assets/svg/alert-info-icon-sm-svg";

const MultipleFileUploadComponent = (
  {
    id = "media-upload",
    formContainer = "form-input-container",
    icon = null,
    buttonText = "Browse Images",
    onUpload = () => { },
    errorMessage = null,
    fileSizeError = null,
    ...props
  },
  ref
) => {
  const [error, setError] = useState(null)
  const onUploadImage = (e) => {
    onUpload([...e.target.files]);
  };

  useEffect(() => {
    if (errorMessage) {
      setError(errorMessage)
    }
  }, [errorMessage])

  return (
    <>
      <div className="relative h-14">
        <label
          htmlFor={id}
          className="absolute leading-4 p-5 w-full text-center bg-modalGray rounded-md text-[0.8rem] md:text-[0.8rem] lg:text-base xl:text-base"
        >
          {icon ?? buttonText}
        </label>
        <input
          ref={ref}
          type="file"
          id={id}
          name={id}
          onChange={onUploadImage}
          {...props}
        />
      </div>
      {error && (
        <p className="input-error-message mt-2 -mb-1 flex">
          <AlertIconSVG /> {error}
        </p>
      )}
    </>
  );
};

const MultipleFileUpload = React.forwardRef(MultipleFileUploadComponent);

export default MultipleFileUpload;
