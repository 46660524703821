import React, { useState, useRef, useEffect } from "react";
import PNGIMAGE from "assets/images/pdf.png";
import MessageSendIconSVG from "assets/svg/message-send-icon";
// import ImageUpload from "components/image-upload/image-upload";
import FileUpload from "components/file-upload";
import { FaTimes } from "react-icons/fa";
import { RiAttachment2} from "react-icons/ri";
import { IoImageOutline, IoDocumentOutline } from "react-icons/io5";
import _ from "lodash";
import { uuidv4 } from "utils/customhooks";
import {formatFileSize} from "utils/utils";
// import update from "immutability-helper";
import Editor from './editor';
import { $generateHtmlFromNodes } from '@lexical/html';
import { useDialogHook } from "utils/customhooks";
import UploadSizeModal from "../file-upload/upload-size-modal";
import { maxUploadSize } from "utils/constant";
import Popup from "reactjs-popup";

const fileToDataURL = (file) => {
  var reader = new FileReader();
  return new Promise(function (resolve, reject) {
    reader.onload = function (event) {
      const _id = uuidv4();
      resolve({
        id: _id,
        src: event.target.result,
      });
    };
    reader.readAsDataURL(file);
  });
}

export default function MessageFooter({
  submit,
  send = () => { },
  attachments = [],
  setAttachments = () => { },
  onSeen = () => { }
}) {
  const [previews, setPreviews] = useState([]);
  const uploadSizeModal = useDialogHook(UploadSizeModal);
  const editorRef = useRef(null);
  const [showMoreOption, setShowMoreOption] = useState();
  const optionRef = useRef();
  useEffect(() => {
    if (!_.isEmpty(attachments)) {
      initPreview()
    } else {
      setPreviews([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachments])

  const initPreview = async () => {
    let filesArray = Array.prototype.slice.call(attachments);
    const result = await Promise.all(filesArray.filter(arr => arr.type.includes("image")).map(fileToDataURL)); 
    setPreviews(result);
  }

  const showUploadAlert = () => {
    uploadSizeModal({}, (callback) => {
      console.log('callback', callback)
    })
  }

  const onUploadImage = async (e) => { 
    optionRef.current.close();
    let fileSize = 0;
    const files = Array.from(e.target.files);
    if (!_.isEmpty(files)) {
    files.map(async(file)=>{
      const {id,src} =  await(fileToDataURL(file)) 
      file.id = id;
      file.src = src;
      return file;
    }); 

      files.forEach(f => {
        fileSize += f.size
      }); 
      if (fileSize < maxUploadSize) {
        setAttachments((prev) => [...prev, ...files]);
      } else {
        showUploadAlert();
      }
    };

    //loop
    // let filesArray = Array.prototype.slice.call(files);
    // const result = await Promise.all(filesArray.map(fileToDataURL));
    // // console.log("result", result);
    // setPreviews((prev) => [...prev, ...result]);
  }

  const onAttachFile = async (e) => {
    optionRef.current.close();
    let fileSize = 0;
    const files = Array.from(e.target.files);
    if (!_.isEmpty(files)) {
      files.forEach(f => { fileSize += f.size })
      console.log(files)
      if (fileSize < maxUploadSize) {
        setAttachments((prev) => [...prev, ...files]);
      } else {
        showUploadAlert();
      }
    };
  }

  const onRemoveAttachment = (index) => {
    let a = _.clone(attachments);
    a.splice(index, 1);

    let p = _.clone(previews);
    p.splice(index, 1);

    setAttachments(a);
    setPreviews(p);
    // let attachment = update(a, { $splice: [[index, 1]] });
    // console.log("after attachment", attachment);
  };

 

  const onSumbit = () => {
    if (editorRef) {
      const editor = editorRef.current;

      editor.update(() => {
        const htmlString = $generateHtmlFromNodes(editor, null);
        if (htmlString !== "<p><br></p>") {
          // send message
          send(htmlString, attachments);

          // clear editor
          const editorState = editor.parseEditorState(`{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}`);
          editor.setEditorState(editorState);
        } else if (attachments.length > 0) {
          send(null, attachments);
        }
      })
    }
  }

  // set focus on the editor when clicking its placeholder
  const onFocus = () => {
    if (editorRef) {
      editorRef.current.focus();
      onSeen()
    }
  }

  return (
    <div className="w-full px-4.5 py-4.5 overflow-x-hidden">
      {/*  */}
      <div className="pb-3 flex gap-3 flex-wrap w-full">
      {/* {!_.isEmpty(previews) && (
          <>
          {previews.map((obj, index) => {
            return (
              <Attachment
                key={index}
                image={obj.src}
                remove={() => onRemoveAttachment(index)}
              />
            );
          })}
          </>
      )} */}
      {/* {!_.isEmpty(attachments.filter(a => a.type === 'application/pdf')) && (  */}
          <>
          {/* {attachments.filter(a => a.type === 'application/pdf').map((obj, index) => ( */}
          {attachments.map((obj, index) => {
            return(
                obj.type === 'application/pdf' ?
                <AttachmentPdf
                    key={index}
                    name={obj.name}
                    size={obj.size}
                    remove={() => onRemoveAttachment(index)}
                  /> :
                <Attachment
                  key={index}
                  image={obj.src}
                  name={obj.name}
                  size={obj.size}
                  remove={() => onRemoveAttachment(index)}
                />
            )
            
            })
        } 
          </>
      </div>

      <div className="w-full flex gap-2">
        {/*  */} 
        <Popup
              ref={optionRef}
              position="top left"
              open={showMoreOption}
              onClose={() => setShowMoreOption(false)}
              arrow={false}
              contentStyle={{ 
                marginTop: -16, 
                marginLeft: 2, 
                zIndex: 9999 
              }}
              trigger={
                <button
                  className="bg-thBg text-primary rounded-md p-3"
                  onClick={() => setShowMoreOption((open) => !open)}>
                  <RiAttachment2 className="m-auto text-xl" />
                </button>
              }>
             <div className="flex flex-col bg-white shadow-lg rounded-md bg-slate-50 w-55 px-2 py-2 border border-slate-100 ">
              <ul className="">  
                <li className=""> 
                  <FileUpload
                    icon={<IoImageOutline className="text-base" />}
                    id="profile-photo" 
                    wrapperClass="relative flex-1"
                    iconOnly={true}
                    accept="image/*"
                    onChange={onUploadImage}
                    label=""
                    multiple
                    className="hover:bg-gray flex flex-row h-8 items-center px-2 cursor-pointer text-xs gap-2 rounded"
                    innerLabel="Add a Photo"
                    labelClass="text-xs" 
                  >
                  </FileUpload>
                  
                </li>
                  <li className="">
                    <FileUpload
                      icon={<IoDocumentOutline className="text-base"/>} 
                      wrapperClass="relative flex-1"
                      iconOnly={true}
                      accept="application/pdf"
                      onChange={onAttachFile}
                      multiple
                      innerLabel="Attach a PDF"
                      labelClass="text-xs" 
                      className="hover:bg-gray flex flex-row h-8 items-center px-2 cursor-pointer text-xs gap-2 rounded"
                    /> 
                  </li> 
              </ul>
             </div>
            </Popup>
        <div className="message-input">
          <Editor placeholder="Start a new message" editorRef={editorRef} onFocus={onFocus} />
        </div>
        {/* send */}
        <button
          className="text-primary ml-4"
          onClick={onSumbit}
          disabled={submit}
        >
          <MessageSendIconSVG />
        </button>
      </div>
      {/*  */}
    </div>
  );
}

const Attachment = ({ image,name,size, remove = () => { } }) => {
  return (
    <div className="relative h-16 max-w-[15rem] flex border-slate-100 rounded-md bg-[#F5F5F5] items-center gap-2 px-2 truncate">
      <button
        className="rounded-full p-1 bg-white absolute top-4.5 right-1.5 cursor-pointer border border-borderGray hover:bg-borderGray"
        onClick={remove}
      >
        <FaTimes className="text-xs" />
      </button>
      <img
        src={image}
        className="h-10 object-cover object-center w-10"
        alt="attachments"
      />
      <div className="w-full overflow-hidden">
        <p className="text-[9px] 2sm:text-xs font-bold whitespace-nowrap text-ellipsis overflow-hidden w-full pr-6">{name}</p>
        <div className="text-[9px] 2sm:text-xs">{formatFileSize(size)}</div>
      </div>
    </div>
  );
};

const AttachmentPdf = ({ name,size, remove = () => { } }) => {
  return (
    // <div className="flex flex-row items-center gap-1 rounded-md hover:text-red px-1">
    //   <p className="text-sm">{name}</p>
    //   <button
    //     className="rounded-full p-1 cursor-pointer"
    //     onClick={remove}
    //   >
    //     <FaTimes className="text-xs" />
    //   </button>
    // </div>

    <div className="relative h-16 max-w-[15rem] flex border-slate-100 rounded-md bg-[#F5F5F5] items-center gap-2 px-2 truncate">
      <button
        className="rounded-full p-1 bg-white absolute top-4.5 right-1.5 cursor-pointer border border-borderGray hover:bg-borderGray"
        onClick={remove}
      >
        <FaTimes className="text-xs" />
      </button>
      <div className="bg-white rounded-md p-2 hidden 2sm:inline">
        <img
          src={PNGIMAGE}
          className="h-8 object-cover object-center w-8"
          alt="attachments"
        />
      </div>
      <div className="w-full overflow-hidden flex-1">
        <div className="text-[9px] 2sm:text-xs font-bold whitespace-nowrap text-ellipsis overflow-hidden w-full pr-6">{name}</div>
        <div className="text-[9px] 2sm:text-xs">{formatFileSize(size)}</div>
      </div>
    </div>
  );
};
