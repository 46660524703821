import React from "react";
import { BsExclamationDiamondFill } from "react-icons/bs";
import { HiOutlineCalendarDays } from "react-icons/hi2"; 
import ResumeUnderlineIconSVG from "assets/svg/resume-underline-icon";
import ResumeDotDividerIconSVG from "assets/svg/resume-dot-divider-icon";
import _ from "lodash";
import { formatDate } from "utils/utils";

export default function ItemMilestone({
    tertiaryColor,
    show_image_milestone = true,
    items,
    headerType = 'featured',
    profile,
    primaryColor
  }) {
  
    return (
          <>
           {/* featured, current and past milestones */}
            {items.some(element => element.type === headerType) &&
              !_.isEmpty(items) && (
                <>
                  <div className="w-full flex flex-row space-x-6.5">
                    <div className="w-14">
                      <p className="text-4xs font-medium text-lightBlack tracking-tight">
                      {
                          headerType === 'featured' ? 'Featured Milestones' :
                          headerType === 'current' ? 'Current Milestones'  : 'Past Milestones'
                      }
                      </p>
                      <ResumeUnderlineIconSVG
                        className="mt-3"
                        stroke={primaryColor}
                      />
                    </div>
                    <div style={{ width: "calc(100% - 81px)" }}>
                      {items.length > 0 && <div className="w-full grid gap-y-3">
                        {items.map((mf, i) => (
                          mf.type === headerType &&
                          <div
                            key={`featured-${i}`}
                            className="w-full flex flex-row space-x-2.6 h-23 overflow-hidden">
                            {show_image_milestone && <div className="h-23 w-23 bg-default rounded relative overflow-hidden">
                              {/* {Boolean(mf.is_adult_content) && (
                                <div className="absolute matured-content-blured-small">
                                  <button className="w-full flex justify-center">
                                    <BsExclamationDiamondFill className="text-1xl cursor-pointer" />
                                  </button>
                                  <p className="text-1xs leading-6 font-bold">
                                    Matured Content
                                  </p>
                                </div>
                              )} */}
                              {mf.thumbnail && (
                                <img
                                  alt="milestones"
                                  src={mf.thumbnail.url}
                                  className="w-full h-full object-contain object-center"
                                />
                              )}
                            </div>}
                            {/* style={{ width: "calc(100% - 111px)" }} */}
                            <div className="w-full">
                              {/* title and date */}
                              <div className="w-full">
                                <div className="w-full flex flex-row items-center justify-between">
                                  {/* title */}
                                  <div className="flex flex-row items-center space-x-1">
                                    <p className="text-4xs text-lightBlack font-semibold tracking-tight">
                                      {mf.title}
                                    </p>
                                    <span
                                      className="uppercase px-1 py-0.5 rounded-sm text-5xs font-bold text-white"
                                      style={{
                                        backgroundColor: tertiaryColor,
                                      }}>
                                      {mf.category.name}
                                    </span>
                                  </div>
                                  {/* date */}
                                  <div>
                                    <p className="text-3xs text-darkerGray font-medium tracking-resume leading-1 flex flex-row space-x-1">
                                      <HiOutlineCalendarDays className="w-2 h-2 text-darkerGray" />
                                      {formatDate(mf,profile?.dateFormat)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* sub title and episodes */}
                              <div className="w-full mt-1">
                                {mf.positions.map((p, i) => (
                                  <div
                                    key={`featured-position-${i}`}
                                    className="w-full leading-1 flex flex-row space-x-1"
                                    style={{ color: primaryColor }}>
                                    <>
                                      <p className="text-1xs leading-2 font-semibold">
                                        <span>{p.position.name}</span>
                                        {p.position.description && (
                                          <>
                                            <span> - </span>
                                            <span>
                                              {p.position.description}
                                            </span>
                                          </>
                                        )}
                                        {mf.platform && (
                                          <span>{` (${mf.platform})`}</span>
                                        )}
                                      </p>
                                      {mf.date_note && (
                                        <>
                                          <span className="text-1xs leading-2 text-darkerGray opacity-30">
                                            &#8226;
                                          </span>
                                          <p className="text-1xs leading-2 font-semibold">
                                            {mf.date_note}
                                          </p>
                                        </>
                                      )}
                                    </>
                                  </div>
                                ))}
                              </div>
                              {/* description */}
                              <div className="w-full mt-2">
                                <p
                                  className="text-3xs text-darkerGray tracking-resume leading-3.2"
                                  dangerouslySetInnerHTML={{
                                    __html: mf.description,
                                  }}></p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>}
                    </div>
                  </div>
                  {/* Divider */}
                  <div className="w-full py-4">
                    <ResumeDotDividerIconSVG full={true} />
                  </div>
                </>
              )} 
          </>  
    );
  }