import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { ContextProvider } from "context/context";
import { PusherProvider } from "context/pusher";
import { SubscriptionProvider } from "context/subscription";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import localstorageService from "services/local-storage";
import httpService from "services/https";
import validationService from "services/validation";

import store from "redux/index";
import "react-loading-skeleton/dist/skeleton.css";
import "./index.css";

global.services = {
  http: httpService,
  localstorage: localstorageService,
  validation: validationService,
};

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <ContextProvider>
    <Provider store={store}>
      <SubscriptionProvider>
        <PusherProvider>
          <App />
        </PusherProvider>
      </SubscriptionProvider>
    </Provider>
  </ContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
