import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from './actions'

export const usePositionFilter = () => {
    const {
        refresh,
        initialize,
        options = [],
    } = useSelector((state) => state.positionFilter);

    const dispatch = useDispatch();

    const refreshPositionFilter = useCallback(
        (payload) => dispatch(actions.refreshPositionFilter(payload)),
        [dispatch]
    );

    const setPositionFilter = useCallback(
        (payload) => dispatch(actions.setPositionFilter(payload)),
        [dispatch]
    );

    return {
        refresh,
        initialize,
        options,
        refreshPositionFilter,
        setPositionFilter
    };
} 