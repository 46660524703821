import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from './actions'

export const useUser = () => {

    const {
        userData,
        initialize,
        profiles,
        socialMedia
    } = useSelector((state) => state.user);

    const dispatch = useDispatch();

    const setSocialMedia = useCallback(
        (payload) => dispatch(actions.setSocialMedia(payload)),
        [dispatch]
    );

    return {
        userData,
        initialize,
        profiles,
        socialMedia,
        setSocialMedia
    };
} 