import React from "react";
import BriefCaseExperienceIconSVG from "assets/svg/brief-case-icon";
import CalendarIconSVG from "assets/svg/calendar-resume-icon";
import ChatUserIconSVG from "assets/svg/chat-user-icon";
import GraduationCapIconSVG from "assets/svg/graduation-cap-icon";
import MapPinIconSVG from "assets/svg/map-pin-icon";
import ResumeLineDividerIconSVG from "assets/svg/resume-line-divider-icon";
import ResumeLineVerticalDividerIconSVG from "assets/svg/resume-line-divider-icon-vertical";
import UserGearIconSVG from "assets/svg/user-gear-icon";
import { Profile1 } from "constants/images";
import { HiOutlineCalendarDays } from "react-icons/hi2";
import { renderStartDate, renderEndDate } from "../../resume";
import moment from "moment";
import _ from "lodash";
import MilestoneTG from "./milestone";
import { personalDateFormat } from "utils/utils";

export default function TemplateFourPageOne(props) {
  // ===================== props ============================
  const {
    active,
    previewImage,
    details = {},
    personal = {},
    primaryColor,
    tertiaryColor,
    milestones = {},
    show_image_milestone = true,
    setTotalPage,
    page = 1,
    totalPage = 1,
    profile
  } = props;

  const A4_SIZE = {
    width: "210mm", // 794 px
    height: "297mm", // 1123px
    overflow: "hidden",
    marginRight: "20px",
    heightTOpx:1123
    };

  const headerRef = React.useRef();
  const milstoneRef = React.useRef();
  const paperRef = React.useRef();
  const [mergeMilestone, setMergeMilestone] = React.useState([]);

  React.useEffect(() => {
    let groupAll = groupArraysWithType(arraysWithType);
    setMergeMilestone(groupAll)
    setTotalPage(groupAll.length) 
  }, [milestones]);

  const groupArraysWithType = (arraysWithType) => {
    const result = [];
    let group = [];
    let limit = 7;
  
    arraysWithType.forEach(({ array, type }) => {
      array.forEach((item, index) => {
        if (group.length < limit) {
          group.push({ ...item, type });
        }
        if (group.length === limit) {
          result.push(group);
          group = [];
          limit = 10;
        }
      });
    });
  
    if (group.length > 0) {
      result.push(group);
    }
  
    return result;
  };

  const arraysWithType = [
    { array: milestones?.featured || [], type: 'featured' },
    { array: milestones?.current || [], type: 'current' },
    { array: milestones?.past || [], type: 'past' },
  ];

  return (
    <div className={`w-full template-four`} style={A4_SIZE} ref={paperRef}>
      <div className="w-full bg-white h-full">
        {/* ouside padding */}
        <div className="w-full h-full template-four-padding-full">
          {/* inner padding */}
          <div className="w-full h-full grid p-2.5 relative grid-row-auto">
            {/* body start */}
            <div className="w-full grid gap-y-5">
              <div ref={headerRef} className={`${!active ? " hidden" : ""}`}>
                {/* header */}
                <div className={"w-full flex flex-row justify-between items-center mb-6"}>
                  <div className="flex flex-col gap-y-2">
                    <p
                      className="text-2.5xl font-bold leading-6.6 tracking-resumeName"
                      style={{ color: tertiaryColor }}>
                      {details.username}
                    </p>
                    <div className="flex flex-row gap-x-1.5 text-resumeTempFourTextColor items-center contact-info">
                      {details.hasOwnProperty("phone_number") && details.phone_number && (
                        <div className="flex items-center gap-x-1">
                          <p className="contact-label">Phone</p>
                          <p className="text-6xs leading-2">{details.phone_number}</p>
                        </div>
                      )}
                      {details.hasOwnProperty("phone_number") &&
                        details.phone_number &&
                        details.hasOwnProperty("email") &&
                        details.email && (
                          <span className="text-1xs opacity-30">&#8226;</span>
                        )}
                      {details.hasOwnProperty("email") && details.email && (
                        <div className="flex items-center gap-x-1">
                          <p className="contact-label">Email Address</p>
                          <p className="text-6xs leading-2 contact-value">{details.email}</p>
                        </div>
                      )}
                    </div>
                  </div>
                  {
                    personal?.profile_image &&
                    <>
                      <div className="w-17.5 h-17.5 rounded-2xl relative overflow-auto">
                        {(personal.hasOwnProperty("profile_image") && personal.profile_image) || previewImage ? (
                          <img
                            src={previewImage ?? personal.profile_image}
                            className="w-full h-full object-cover object-center"
                            alt="resume-profile"
                          />
                        ) : (
                          <img
                            alt="resume-profile"
                            src={Profile1}
                            className="w-full h-full object-cover object-center"
                          />
                        )}
                      </div>
                    </>
                  }
                </div>
                {/* end header */}
                {/* Divider */}
                <div className="w-full">
                  <ResumeLineDividerIconSVG full={true} />
                </div>
                {/*  */}
                <div className="w-full">
                  <div className="w-full flex flex-row gap-x-6">
                    {/* Formation Info */}
                    <div className="w-full py-4">
                      <p className="text-resumeTempFourTextPrimary text-6xs font-medium tracking-resumeFour">
                        Formation Information
                      </p>
                      <div className="w-full mt-1.5">
                        <ul className="w-full grid gap-y-0.5">
                          {details.hasOwnProperty("birthdate") &&
                            details.birthdate && (
                              <li className="w-full flex space-x-1.5">
                                <CalendarIconSVG
                                  className="opacity-70 w-2.5 h-2.5"
                                  stroke={primaryColor}
                                />
                                <p className="text-7xs text-resumeTempFourTextPrimary leading-3">
                                  {personalDateFormat(details.birthdate,profile?.dateFormat)}
                                </p>
                              </li>
                            )}
                          {details.hasOwnProperty("birth_location") &&
                            details.birth_location && (
                              <li className="w-full flex space-x-1.5">
                                <MapPinIconSVG
                                  className="opacity-70 w-2.5 h-2.5"
                                  stroke={primaryColor}
                                />
                                <p className="text-7xs text-resumeTempFourTextPrimary leading-3">
                                  {details.birth_location}
                                </p>
                              </li>
                            )}
                        </ul>
                      </div>
                    </div>
                    {/* divider */}
                    {details.hasOwnProperty("bio") && details.bio && (
                      <div>
                        <ResumeLineVerticalDividerIconSVG className="h-full" />
                      </div>
                    )}
                    {/* Bio */}
                    <div className="w-full grid gap-y-0.5 py-4">
                      {details.hasOwnProperty("bio") && details.bio && (
                        <div className="w-full">
                          <p className="text-resumeTempFourTextPrimary text-6xs font-medium tracking-resumeFour">
                            Bio
                          </p>
                          <div className="w-full mt-1.5">
                            <p className="text-7xs text-resumeTempFourTextPrimary tracking-resume leading-3.5 self-stretch">
                              {details.bio}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* Divider */}
                <div className="w-full">
                  <ResumeLineDividerIconSVG full={true} />
                </div>
                {/*  Formation Information */}
                <div className="w-full py-4">
                  <p className="text-resumeTempFourTextPrimary text-6xs font-medium tracking-resumeFour">
                    Team Members
                  </p>
                  <div className="w-full grid grid-cols-2 gap-x-6 mt-2">
                    {/* More Information */}
                    <div className="w-full grid gap-y-0.5">
                      {/* Team Members */}
                      {personal.hasOwnProperty("members") &&
                        personal.members.length > 0 && (
                          <div className="w-full flex flex-row space-x-4">
                            {/*  */}
                            {/* <span className="text-1xs opacity-30">&#8226;</span> */}
                            {
                              personal.members.map((member, index) => {
                                return (
                                  <div className="flex items-center gap-x-1">
                                    <span className="text-2xs opacity-30" style={{color: primaryColor}}>&#8226;</span>
                                    <p className="text-7xs text-resumeTempFourTextPrimary leading-3"
                                    dangerouslySetInnerHTML={{ __html: member?.mention || member.name }}>
                                    </p>
                                  </div>
                                )
                              })
                            }
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                {/* Divider */}
                <div className="w-full">
                  <ResumeLineDividerIconSVG full={true} />
                </div>
              </div>
              {/*  */}
              {/* MILESTONES */}
              <div ref={milstoneRef} className="h-full">
                {
                  mergeMilestone.map((item,index)=>{
                    return (
                      page === (index+1) &&
                      <div key={index}>
                        <MilestoneTG
                          items={item}
                          milestones={milestones}
                          primaryColor={primaryColor}
                          show_image_milestone={show_image_milestone}
                          tertiaryColor={tertiaryColor}
                          profile={profile}
                        />
                      </div>
                    )
                  })
                }
              </div>
              {((details.hasOwnProperty("occupations") &&
                !_.isEmpty(details.occupations)) ||
                details.education ||
                details.training ||
                details.experience) && (
                  <div className="w-full grid gap-y-4">
                    {/*  */}
                    {details.hasOwnProperty("occupations") &&
                      !_.isEmpty(details.occupations) && (
                        <div className="w-full flex flex-row space-x-1 items-start">
                          <div className="w-50 flex flex-row space-x-2 items-center">
                            <UserGearIconSVG
                              className="w-4 h-4"
                              fill={primaryColor}
                            />
                            <p className="text-6xs text-resumeTempFourTextPrimary font-medium tracking-resumeFour">
                              Abilities / Skills / Specialties
                            </p>
                          </div>
                          <div style={{ width: "calc(100% - 204px)" }}>
                            <div
                              className="w-full flex flex-row space-x-1.5 text-1xs font-bold"
                              style={{ color: tertiaryColor + "E6" }}>
                              {details.occupations.map((oc, i) => (
                                <span
                                  key={i}
                                  className="template-four-padding-skills rounded text-opacity-90 bg-opacity-7"
                                  style={{
                                    backgroundColor: tertiaryColor + "1F", // opcity 12%
                                  }}>
                                  {oc}
                                </span>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                    {/*  */}
                    {details.hasOwnProperty("education") && details.education && (
                      <div className="w-full flex flex-row space-x-1 items-start">
                        <div className="w-50 flex flex-row space-x-2 items-center">
                          <GraduationCapIconSVG
                            className="w-4 h-4"
                            fill={primaryColor}
                          />
                          <p className="text-6xs text-resumeTempFourTextPrimary font-medium tracking-resumeFour">
                            Education
                          </p>
                        </div>
                        <div style={{ width: "calc(100% - 204px)" }}>
                          <p className="text-7xs text-resumeTempFourTextPrimary tracking-resume leading-3.5 self-stretch opacity-90">
                            {details.education}
                          </p>
                        </div>
                      </div>
                    )}

                    {/*  */}
                    {details.hasOwnProperty("training") && details.training && (
                      <div className="w-full flex flex-row space-x-1 items-start">
                        <div className="w-50 flex flex-row space-x-2 items-center">
                          <ChatUserIconSVG
                            className="w-4 h-4"
                            fill={primaryColor}
                          />
                          <p className="text-6xs text-resumeTempFourTextPrimary font-medium tracking-resumeFour">
                            Training
                          </p>
                        </div>
                        <div style={{ width: "calc(100% - 204px)" }}>
                          <p className="text-7xs text-resumeTempFourTextPrimary tracking-resume leading-3.5 self-stretch opacity-90">
                            {details.training}
                          </p>
                        </div>
                      </div>
                    )}
                    {/*  */}
                    {details.hasOwnProperty("experience") &&
                      details.experience && (
                        <div className="w-full flex flex-row space-x-1 items-start">
                          <div className="w-50 flex flex-row space-x-2 items-center">
                            <BriefCaseExperienceIconSVG
                              className="w-4 h-4"
                              fill={primaryColor}
                            />
                            <p className="text-6xs text-resumeTempFourTextPrimary font-medium tracking-resumeFour">
                              Experience
                            </p>
                          </div>
                          <div style={{ width: "calc(100% - 204px)" }}>
                            <p className="text-7xs text-resumeTempFourTextPrimary tracking-resume leading-3.5 self-stretch opacity-90">
                              {details.experience}
                            </p>
                          </div>
                        </div>
                      )}
                  </div>
                )}
              {/* end */}
            </div>
            {/* footer */}
            <div className="w-full flex items-end pt-10">
              <div className="w-full">
                <ResumeLineDividerIconSVG full={true} />
                <div className="w-full grid pt-3.5 justify-center">
                  <p className="text-7xs text-resumeTempFourTextPrimary leading-3.5 opacity-90">
                    Page {page} of {totalPage}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
