import React from "react";
import FollowingItem from "./following-item";
import _ from "lodash";

export default function ProfileListFollowing({
  list,
  type = "following",
  onFollow = async () => {},
}) {
  return (
    <div className="w-full mt-6.5">
      {!_.isEmpty(list) ? (
        <>
          {(list || []).map((obj, index) => {
            return (
              <FollowingItem
                data={obj}
                key={index}
                type={type}
                onFollow={(e) => onFollow(e)}
              />
            );
          })}
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
