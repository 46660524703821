const PlayIconCircleWhiteSVG = ({ className = "", ...props }) => {
  return (
    <svg
      width="95"
      height="95"
      viewBox="0 0 95 95"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="47.5"
        cy="47.5"
        r="46"
        fill="black"
        fillOpacity="0.2"
        stroke="white"
        strokeWidth="3"
      />
      <path
        d="M67.5 45.4019C69.5 46.5566 69.5 49.4434 67.5 50.5981L39 67.0526C37 68.2073 34.5 66.7639 34.5 64.4545L34.5 31.5455C34.5 29.2361 37 27.7927 39 28.9474L67.5 45.4019Z"
        fill="white"
      />
    </svg>
  );
};

export default PlayIconCircleWhiteSVG;
