import React, { useMemo } from "react";
import _ from "lodash";
import { socialTemplate } from "utils/constant";
import { BsExclamationDiamondFill } from "react-icons/bs";
import { HiOutlineCalendarDays } from "react-icons/hi2";
import { renderStartDate, renderEndDate } from "../../resume";
import ItemMilestone from "./item-milestone";

export default function TemplateOnePageOne({
  details = {},
  social = {},
  primaryColor,
  secondaryColor,
  tertiaryColor,
  milestones = {},
  show_image_milestone = true,
  items,
  profile
}) {
  const socMed = useMemo(() => {
    let arrays = Object.entries(social);
    return arrays.map((obj) => {
      let key = obj[0];
      let value = obj[1];
      return { key, value };
    });
  }, [social]);

  return (
    <div className="w-full">
      <div className="w-full h-full">
        <div style={{ minHeight: "849px" }} className="h-full overflow-hidden">
        <ItemMilestone
          {...{
            secondaryColor,
            tertiaryColor ,
            items,
            profile
          }}
          headerType = 'featured'
          />
          <ItemMilestone
          {...{
            secondaryColor,
            tertiaryColor ,
            items,
            profile
          }}
          headerType = 'current'
          />
        <ItemMilestone
          {...{
            secondaryColor,
            tertiaryColor ,
            items,
            profile
          }}
          headerType = 'past'
          />
          {/** social links */}
          {!_.isEmpty(social) && (
            <div className="flex flex-col space-x-2 mb-8">
              <div
                className="px-2 py-1.5 rounded-sm mb-4"
                style={{ backgroundColor: secondaryColor }}>
                <p className="text-4xs font-bold text-resumeTempFourTextPrimary tracking-resumeOne">
                  Follow Me
                </p>
              </div>
              <div className="grid grid-cols-2 gap-4 pr-2">
                {(socMed || []).map((obj, index) => {
                  const SocialIcon = socialTemplate[obj.key];
                  if (SocialIcon) {
                    return (
                      <div
                        className="flex flex-row items-center break-all space-x-2"
                        key={index}>
                        <div className="w-3">
                          <SocialIcon
                            className="text-xs mt"
                            style={{ color: tertiaryColor }}
                          />
                        </div>
                        <div className="leading-1">
                          <p className="text-1xs text-resumeTempFourTextPrimary">
                            {obj.value}
                          </p>
                        </div>
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            </div>
          )}
        </div>
        {/* ========== footer ======== */}
        {/* <div
          className="w-full h-6 border-t px-2 "
          style={{ borderColor: "#DAE4EF" }}>
          <div className="w-full h-full flex flex-row justify-between items-center">
            <p className="font-medium text-1xs text-darkerGray">Page 1</p>
            <div className="flex flex-start gap-1.5">
              <button className="w-1.5 h-1.5 rounded-full bg-primary" />
              <button className="w-1.5 h-1.5 rounded-full bg-pageInactive" />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
