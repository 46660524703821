import _ from "lodash";
import React from "react";
import SelectComponent from "react-select";

export default function Select({
  options = [],
  label = "",
  otherLabel = "",
  labelClass = "text-sm font-bold text-darkerGray leading-4",
  innerLabelClass = "text-xs font-semibold color-select-label leading-3.5 absolute left-4.5 top-4",
  className = "",
  formContainer = "form-input-container",
  height = 52,
  innerLabel,
  optional = false,
  errorMessage,
  backgroundColor = "white",
  borderColor = "#dfdfdf",
  customOptions = false,
  optionComponent = {},
  onlyText = false,
  valueContainerClass = "-23px !important",
  ...props // make sure this pops here is for input only
}) {
  const [inputValue, setInputValue] = React.useState('');
  const padding = innerLabel ? { paddingTop: 30 } : {};
  const value_container = innerLabel ? { marginTop: valueContainerClass } : {};
  const indicators = innerLabel ? { marginTop: '-29px !important' } : {};
  const customStyles = {
    control: (styles) => {
      return {
        ...styles,
        backgroundColor: backgroundColor,
        borderColor: errorMessage ? "red" : borderColor,
        borderRadius: 6,
        minHeight: height,
        boxShadow: "none",
        "&:hover": {
          border: errorMessage
            ? "1px solid red !important"
            : `1px solid ${borderColor} !important`,
          cursor: "pointer",
        },
        paddingLeft: 8,
        ...padding,
      };
    },
    option: (provided) => {
      return {
        ...provided,
        padding: "8px 18px",
        zIndex: 2,
      };
    },
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#aaaaaa",
      };
    },
    menu: (base) => {
      return {
        ...base,
        zIndex: 9999,
      };
    },
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menuList: (base) => ({
      ...base,
      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "rgba(255, 255, 255, 0.1)",
        borderRadius: "10px",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#d1d1d1",
        borderRadius: "10px",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
    dropdownIndicator: (base) => {
      if (window.innerWidth <= 500) { 
        return {
          ...base,
          ...indicators
        };
      }
      return {
        ...base
      };
      
    },
    valueContainer: (base) => {
      if (window.innerWidth <= 500) { 
        return {
          ...base,
          ...value_container
        };
      } 
      return {
        ...base
      };
    },
  };
  

  const handleInputChange = (value) => { 
    if(onlyText){
      const filteredValue = value.replace(/[0-9]/g, '');
      setInputValue(filteredValue);
      return;
    } 
    setInputValue(value);
    
  };

  return (
    <div className={`${formContainer} cursor-pointer relative`}>
      {label && (
        <label htmlFor={label} className={labelClass}>
          {label}
          {optional && <span className="font-normal">{" (optional)"}</span>}
          {otherLabel && (
            <span className="font-normal leading-4">
              <br />
              {otherLabel}
            </span>
          )}
        </label>
      )}

      {innerLabel && (
        <label
          htmlFor={innerLabel}
          className={innerLabelClass}
          style={{ zIndex: 1 }}>
          {innerLabel}
        </label>
      )}

      <SelectComponent
      menuPortalTarget={document.body}
      inputValue={inputValue}
        onInputChange={handleInputChange}
        options={options}
        className={`${className} ${label && "mt-2"}`}
        classNamePrefix="milestone"
        styles={customStyles}
        components={
          customOptions
            ? { IndicatorSeparator: () => null, ...optionComponent }
            : {
                IndicatorSeparator: () => null,
              }
        }
        {...props}
      />

      {_.toString(errorMessage) && (
        <p className="input-error-message mt-2 -mb-1">* {errorMessage}</p>
      )}
    </div>
  );
}
