import {
    TransformWrapper,
    TransformComponent,
  } from "react-zoom-pan-pinch"; 
import "./styles.css";
 
import { SlMagnifierAdd,SlMagnifierRemove } from "react-icons/sl";
import { TbZoomReset } from "react-icons/tb";

const Zoom = ({children,fontSize='text-xl', padding="p-2", isMobile=false})=>{

    return( 
        <TransformWrapper
            centerOnInit={true}
        >    
            {({ zoomIn, zoomOut, resetTransform, positionX, positionY, ...rest }) => (
                <>
                    {
                        !isMobile && 
                            <div className="absolute bottom-1 z-40 space-x-4">
                                <button onClick={(e)=>  {
                                    e.stopPropagation();
                                    zoomIn()
                                }} className={`rounded-full bg-primary shadow-md ${padding}`}><SlMagnifierAdd  className={`text-white ${fontSize}`} /></button>
                                <button onClick={(e) =>{
                                    e.stopPropagation();
                                    zoomOut()}} 
                                    className={`rounded-full bg-primary shadow-md ${padding}`}><SlMagnifierRemove  className={`text-white ${fontSize}`} /></button> 
                                <button onClick={(e) => {
                                    e.stopPropagation();
                                    resetTransform()}}
                                    className={`rounded-full bg-primary shadow-md ${padding}`}><TbZoomReset  className={`text-white ${fontSize}`} /></button> 
                            </div> 
                    }
                    <TransformComponent wrapperClass="w-full h-full" >
                        {typeof children === 'function' ? children({
                            zoomIn, zoomOut, resetTransform, positionX, positionY, ...rest
                        }): children}
                    </TransformComponent> 
                </>
            )}
        </TransformWrapper> 
    )
}

export default (Zoom);