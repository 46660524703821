import { BsGlobe2, BsExclamationDiamondFill } from "react-icons/bs";
import { IoEllipse } from "react-icons/io5";
import { TbWorld } from "react-icons/tb";
const PrivacyBadge = ({privacy,isYourProfile})=>{
    return (
        <>
        {  isYourProfile && 
            <>
            <p>
                <IoEllipse className="w-1 h-1" />
            </p>
                <p className="flex items-center capitalize">
                    {privacy === "Public" ? (
                    <BsGlobe2 className="mr-1.5" />
                    ) : (
                    <BsExclamationDiamondFill className="mr-1.5" />
                    )}
                    {privacy}
                </p>
            <p>
                <IoEllipse className="w-1 h-1" />
            </p>
            </>
        }
        </>
    );
}

export default PrivacyBadge;