import ResumeLineDividerIconSVG from "assets/svg/resume-line-divider-icon";
import _ from "lodash";
import React from "react";
// import { BsExclamationDiamondFill } from "react-icons/bs";
import { groupArraysWithType, A4_SIZE } from "../../resume";
import Milestone from "./milestone";

export default function TemplateFourPageTwo(props) {
  const {
    active,
    milestones = {},
    primaryColor,
    tertiaryColor,
    show_image_milestone = true,
    setTotalPage,
    profile,
    page,
    totalPage
  } = props;

  const [mergeMilestone, setMergeMilestone] = React.useState([]);

  React.useEffect(() => {
      let groupAll = groupArraysWithType(arraysWithType, 10, 10);
      setMergeMilestone(groupAll);
      setTotalPage(groupAll.length + 1);
  }, [milestones]);

  const arraysWithType = [
    { array: milestones?.featured || [], type: 'featured' },
    { array: milestones?.current || [], type: 'current' },
    { array: milestones?.past || [], type: 'past' },
  ];

  return (
    <div className={`w-full`} style={A4_SIZE}>
      <div className="w-full bg-white h-full" style={{ minHeight: "1123px" }}>
        {/* ouside padding */}
        <div className="w-full h-full template-four-padding">
          {/* inner padding */}
          <div className="w-full h-full grid p-2.5 relative grid-row-auto">
            {/* body start */}
            <div className="w-full grid gap-y-7">
              {
                mergeMilestone.map((item,index)=>{
                  return (
                    page === (index + 2) &&
                    <div key={index}>
                      <Milestone
                        items={item}
                        milestones={milestones}
                        primaryColor={primaryColor}
                        show_image_milestone={show_image_milestone}
                        tertiaryColor={tertiaryColor}
                        profile={profile}
                      />
                    </div>
                  )
                })
              }
            </div>
            {/* end */}
            {/* footer */}
            <div className="w-full flex items-end">
              <div className="w-full">
                <ResumeLineDividerIconSVG full={true} />
                <div className="w-full grid pt-3.5 justify-center">
                  <p className="text-7xs text-resumeTempFourTextPrimary leading-3.5 opacity-90">
                    Page {page} of {totalPage}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
