import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { randomExtension } from "utils/utils";
import userActions from "redux/modules/user/actions";
import { connect } from "react-redux";
import toast from "react-hot-toast";

const EmailVerification = ({ setUserData }) => {
  const { http, localstorage } = global.services;

  const history = useHistory();
  const query = useQuery();
  const token = query.get("token");

  const verify = async () => {
    try {
      const { data } = await http.post("email-verification", { token });
      const extension = randomExtension();
      if (data.user.role !== "admin") {
        toast.success("Your email has been verified!");
        localstorage.set("user_data", JSON.stringify(data));
        setUserData(data);
        return history.replace(
          `/${data.user.username}-${extension}/profile/${data.profile.id}`
        );
      } else {
        toast.error("This site is only accessible by customer users");
      }
    } catch (err) {
      console.log("error@verify", err);
      throw err;
    }
  };

  useEffect(() => {
    if (token) verify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return <></>;
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (params) => {
      dispatch(userActions.setUserData(params));
    },
  };
};

export default connect(null, mapDispatchToProps)(EmailVerification);

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};
