const PasswordIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_820_3688)">
        <path
          d="M10.107 10.75C9.51522 10.7523 8.94053 10.9441 8.47132 11.2961C8.00212 11.648 7.66437 12.1406 7.51 12.6981C7.35563 13.2555 7.39319 13.8471 7.6169 14.3817C7.84061 14.9163 8.23809 15.3644 8.74824 15.6572V18.333H11.499V15.6572C11.9026 15.4242 12.2376 15.0933 12.4713 14.6967C12.7051 14.3 12.8297 13.8511 12.833 13.3936C12.833 13.0451 12.7623 12.7 12.6251 12.3782C12.488 12.0564 12.2869 11.7643 12.0336 11.5186C11.7802 11.2729 11.4796 11.0785 11.149 10.9466C10.8183 10.8147 10.4642 10.7479 10.107 10.75Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.4548 7.63477H4.15997C2.96705 7.63477 2 8.60182 2 9.79474V18.3396C2 19.5326 2.96705 20.4996 4.15997 20.4996H16.4548C17.6478 20.4996 18.6148 19.5326 18.6148 18.3396V9.79474C18.6148 8.60182 17.6478 7.63477 16.4548 7.63477Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.98511 7.63492V6.32994C4.98511 4.91635 5.54665 3.56066 6.54621 2.5611C7.54577 1.56155 8.90146 1 10.315 1C11.7286 1 13.0843 1.56155 14.0839 2.5611C15.0834 3.56066 15.645 4.91635 15.645 6.32994V7.63492"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_820_3688">
          <rect width="21.5" height="21.5" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PasswordIconSVG;
