import React from "react";
import Button from "components/button/button";
import Input from "components/input/input";
import { FaMinus, FaPlus } from "react-icons/fa";
import { social_icons } from "utils/constant";
import HeroOtherIcon from "assets/svg/hero-other";
import DragIcon from "assets/svg/drag-icon.png";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ToolTip from "components/tootltip/tootltip";
const noop = () => {};

export default function SocialSelect({
  options = [],
  items = [],
  errors,
  onAdd = noop,
  onRemove = noop,
  onSelect = noop,
  onChange = noop,
  onBlur = noop,
  setItems,
  customStyle={} //Draggable item is not appearing next to the cursor so add here the custom
}) { 

  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    var updatedList = [...items];
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    setItems(updatedList);
  };

  return (
    <div className="w-full">


<DragDropContext onDragEnd={handleDrop}>
        <Droppable droppableId="list-container">
          {(provided) => (
            <div
              className="list-container"
              {...provided.droppableProps}
              ref={provided.innerRef}>
              {items.map((obj, index) => (
                <Draggable
                  key={`${obj.name}${obj.role}${index}`}
                  draggableId={`${obj.name}${obj.role}${index}`}
                  index={index}>
                  {(provided) => (
                    <div
                      key={index}
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                      style={{ ...provided.draggableProps.style,
                        ...customStyle
                      }}
                      >
                      <div className="flex space-x-3 bg mb-2" key={index}> 
                        <div className="flex items-center">
                          <ToolTip 
                            value="Drag to re-order"
                            backgroundColor="#0c6ed1"
                            color="white"
                            width="w-[8rem]"
                          >
                            <img alt="milestones" src={DragIcon} />
                          </ToolTip> 
                        </div> 
                        <div>
                          <p className={`w-7.5 h-7.5 3md:w-12 3md:h-13 mb-2 bg-bGray rounded-md truncate ${
                              errors && errors[`social.${index}.type`]
                                ? "border-2 border-rose-500"
                                : ""
                            } flex justify-center items-center shadow-md`}>
                            {Object.keys(social_icons).includes(obj.social) ? (
                              social_icons[obj.social]
                            ) : obj?.fav_icon ? (
                              <p
                                className={`bg-bGray rounded-md truncate grayscale fill-cyan-500 flex justify-center w-[100%]`}>
                                <img className="w-[70%]" src={obj.fav_icon} alt="" />
                              </p>
                            ) : (
                              <p
                                className={`bg-bGray rounded-md truncate grayscale fill-cyan-500 flex justify-center`}>
                                {obj.link === "https://" ? (
                                  <HeroOtherIcon />
                                ) : obj.link ? (
                                  <img
                                    className="w-[70%]"
                                    src={`http://www.google.com/s2/favicons?domain=${obj.link}&sz=64`}
                                    alt=""
                                  />
                                ) : (
                                  <HeroOtherIcon />
                                )}
                              </p>
                            )}
                          </p>
                        </div>
                        <Input
                          onBlur={(e) => onBlur(e, index, "social")}
                          inputContainerClass="flex-1"
                          name={`link-${index}`}
                          value={obj.link}
                          placeholder="https://www.link.com/image1"
                          noLabelPaddingY="0.8rem"
                          type="text"
                          onChange={(e) => onChange(e, index, "social")}
                          errorMessage={
                            (errors && errors[`social.${index}.link`]) ||
                            (errors?.social && errors?.social[index]) ||
                            (errors && errors[`social.${index}.type`])
                          }
                          errorMessagePosition="bottom"
                        />
                        {/* button remove */}
                        <button
                          className="h-6.5 w-6.5 bg-lightBlack rounded-full md:mt-3"
                          onClick={() => onRemove(index, "social")}>
                          <FaMinus className="m-auto text-white" />
                        </button>
                        
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {/* {items.map((obj, index) => (
        <div key={index}>
          <div className="flex space-x-3 bg mb-1">
            
          </div>
        </div>
      ))} */}
      <Button
        buttonName="Add Another Social Media"
        buttonTextClass="text-sm text-primary font-semibold flex items-center"
        iconPosition="left"
        icon={<FaPlus className="mr-3" />}
        buttonRadius="6px"
        buttonClass="relative py-4 px-9 bg-white border-2 border-primary"
        onClick={() => {
          onAdd("social");
        }}
      />
    </div>
  );
}
