const CalendarIconSVG = ({ className = "", stroke = "#E5EFFA" }) => {
  return (
    <svg
      // width="24"
      // height="24"
      // viewBox="0 0 24 24"
      width="11"
      height="10"
      viewBox="0 0 11 10"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.7" clip-path="url(#clip0_1383_2367)">
        <path
          d="M8.06022 1.66669H2.22689C1.76665 1.66669 1.39355 2.03978 1.39355 2.50002V8.33335C1.39355 8.79359 1.76665 9.16669 2.22689 9.16669H8.06022C8.52046 9.16669 8.89355 8.79359 8.89355 8.33335V2.50002C8.89355 2.03978 8.52046 1.66669 8.06022 1.66669Z"
          stroke={stroke}
          stroke-width="0.833322"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.8103 0.833313V2.49998"
          stroke={stroke}
          stroke-width="0.833322"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.47681 0.833313V2.49998"
          stroke={stroke}
          stroke-width="0.833322"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.39355 4.16669H8.89355"
          stroke={stroke}
          stroke-width="0.833322"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1383_2367">
          <rect width="10" height="10" fill="white" transform="translate(0.143555)"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default CalendarIconSVG;
