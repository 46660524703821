const ResumeIconPlusSVG = ({ className = "" }) => {
  return (
    <svg
      width="54"
      height="56"
      viewBox="0 0 54 56"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.5452 39.6692C35.2554 39.5525 34.9867 39.5555 34.7512 39.5565H34.6657C32.456 39.5565 30.2463 39.5565 28.0365 39.5565H21.7274C19.2329 39.5565 16.7384 39.5565 14.2439 39.5565C14.2046 39.5565 14.1664 39.5565 14.1282 39.5565C14.0034 39.5545 13.8484 39.5525 13.6884 39.5696C12.8532 39.6601 12.2112 40.3756 12.2273 41.1987C12.2434 42.0419 12.8784 42.704 13.7357 42.7735C13.8575 42.7835 13.9732 42.7845 14.0698 42.7845H14.2771C21.034 42.7845 27.791 42.7845 34.546 42.7845H34.6546C34.8136 42.7855 34.9797 42.7865 35.1547 42.7674C35.9145 42.6839 36.5011 42.0932 36.5796 41.3325C36.6541 40.6161 36.2375 39.9479 35.5452 39.6702V39.6692Z"
        fill="#777777"
      />
      <path
        d="M14.4629 28.7875H14.4679C15.3162 28.7845 15.9914 28.1364 16.0719 27.2449C16.09 27.0406 16.092 26.8444 16.093 26.6492C16.093 26.5244 16.0951 26.3946 16.1011 26.2729C16.1967 24.4224 17.7121 22.9351 19.5505 22.8868C20.4521 22.8637 21.3789 22.8627 22.3841 22.8848C23.9911 22.9211 25.3868 24.0551 25.7792 25.6419C25.8567 25.9539 25.8617 26.2859 25.8678 26.6381C25.8708 26.8203 25.8738 27.0094 25.8879 27.2026C25.9513 28.1052 26.6436 28.7855 27.4979 28.7855H27.525C28.3733 28.7704 29.0244 28.1324 29.1079 27.2328C29.3957 24.1658 27.681 21.3141 24.8434 20.1358C24.2567 19.8923 23.6691 19.7655 23.0855 19.7001C24.7981 18.8991 25.9865 17.1452 25.9774 15.1196C25.9654 12.3726 23.7214 10.1377 20.9774 10.1377H20.9673C19.635 10.1407 18.3813 10.665 17.4384 11.6159C16.4955 12.5658 15.9803 13.8226 15.9874 15.1538C15.9944 16.5022 16.5217 17.765 17.4716 18.7099C17.8892 19.1245 18.3672 19.4565 18.8854 19.6991C17.2291 19.9003 15.6815 20.7083 14.5575 21.9772C13.2755 23.4252 12.6567 25.3501 12.859 27.257C12.9545 28.1576 13.6146 28.7865 14.4639 28.7865L14.4629 28.7875ZM22.7474 15.1579C22.7474 15.6378 22.5572 16.0876 22.2151 16.4237C21.877 16.7558 21.4322 16.9369 20.9573 16.9279C19.9832 16.9128 19.2185 16.1279 19.2174 15.1408C19.2164 14.1667 20.0003 13.3718 20.9643 13.3688H20.9693C21.4433 13.3688 21.889 13.5529 22.2231 13.888C22.5612 14.2261 22.7464 14.6769 22.7454 15.1569L22.7474 15.1579Z"
        fill="#777777"
      />
      <path
        d="M14.0512 36.9332C15.0152 36.9352 15.9781 36.9352 16.9421 36.9352H22.0086C23.686 36.9352 25.3624 36.9352 27.0408 36.9352H27.0509C27.3105 36.9352 27.6043 36.9342 27.9133 36.8144C28.5935 36.5508 29.0282 35.8464 28.9467 35.139C28.8591 34.3783 28.2675 33.7957 27.5077 33.7212C27.3417 33.7051 27.1847 33.7051 27.0127 33.7061H26.908C22.6959 33.7061 18.4837 33.7061 14.2725 33.7061H14.1639C13.9948 33.7041 13.8026 33.7021 13.6034 33.7293C13.1526 33.7897 12.7501 34.0422 12.4995 34.4226C12.246 34.808 12.1725 35.2849 12.2973 35.7317C12.4502 36.2781 12.8789 36.9291 14.0512 36.9312V36.9332Z"
        fill="#777777"
      />
      <path
        d="M53.5573 49.7229V46.5845H46.3389V40.3076H42.7297V46.5845H35.5112V49.7229H42.7297V55.9998H46.3389V49.7229H53.5573Z"
        fill="#777777"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.5019 14.3728C45.845 14.7149 46.1771 15.1034 46.3089 15.6397H46.3099L46.324 15.7383V37H43.0504C43.0506 36.072 43.0506 35.144 43.0506 34.216V33.8487C43.0508 33.16 43.0509 32.4682 43.051 31.7741C43.0518 27.0495 43.0525 22.2125 43.0587 17.4459H43.0577C40.6004 17.462 38.1945 17.459 35.5299 17.4529C34.1494 17.4509 32.9771 17.0042 32.1419 16.1639C31.3067 15.3237 30.865 14.1464 30.8629 12.7608V11.6811C30.8609 9.57 30.8589 7.38745 30.871 5.23508C27.2074 5.24213 23.5166 5.24213 19.9465 5.24213H19.9461H19.7338C17.3963 5.24213 12.2885 5.24213 9.95001 5.24414C9.05143 5.24515 8.41749 5.67683 8.16392 6.45868C8.08742 6.69513 8.08743 6.95414 8.08744 7.25411L8.08744 7.26569V7.3472V24.0418V31.0584C8.08744 31.9737 8.0874 32.889 8.08736 33.8043C8.0872 37.4661 8.08704 41.1276 8.08945 44.7897C8.09046 46.0958 8.74553 46.7589 10.0365 46.7589L10.0335 46.7579C16.5401 46.76 24.53 46.7606 31.9995 46.7598V50H9.0786L8.95181 49.9155C8.93973 49.9074 8.92665 49.8984 8.91458 49.8893C6.29028 49.2755 4.8443 47.4532 4.8443 44.7504C4.84229 34.7936 4.84229 24.8368 4.84229 14.88V7.25261C4.84229 6.68609 4.88757 6.25039 4.98819 5.83883C5.56377 3.48019 7.50583 2.01308 10.0567 2.01107C11.8438 2.01006 16.4001 2.01006 18.1872 2.01006H19.5617C24.0194 2.01107 28.0364 2.01006 32.0403 2C32.8 2 33.4027 2.25458 33.9451 2.80097C37.7532 6.6346 41.5451 10.4214 45.2182 14.0895L45.5019 14.3728ZM34.088 7.72152V8.64123H34.089C34.088 10.0299 34.088 11.4195 34.09 12.8081C34.091 13.7801 34.5307 14.2148 35.5148 14.2179C36.253 14.22 36.9917 14.2196 37.7306 14.2192C38.0261 14.219 38.3216 14.2189 38.6171 14.2189H40.5914L34.088 7.72152Z"
        fill="#777777"
      />
    </svg>
  );
};

export default ResumeIconPlusSVG;
