import {
	REFRESH_PRIVACIES,
	SET_PRIVACIES,
} from './types'

const initialState = {
	privacies: [],
	refresh: false,
	initialize: true
};

const reducer = (state = initialState, {type, payload}) => {

	switch (type) {

		case REFRESH_PRIVACIES:
			return {
				...state,
				refresh: true,
				initialize: true,
				privacies: [],
			}
		
		case SET_PRIVACIES:
			return {
				...state,
				refresh: false,
				initialize: false,
				privacies: [...state.privacies, ...payload]
			}

		default:
			return state;

	}

}

export default reducer