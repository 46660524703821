import React, { useEffect, useRef, useState, useCallback } from "react";
import ReactCrop, { centerCrop } from "react-image-crop";
import { canvasToFile } from "utils/constant";
import "react-image-crop/dist/ReactCrop.css";

export default function CropProfile({
  setParentCrop = () => {},
  setCropPreview = () => {},
  locked = false,
  ...props
}) {
  const { image, type, returnType } = props;
  const imgSrc = image;
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const zoom = 1;
  const [aspect, setAspect] = useState(undefined);

  const getCropData = useCallback(async () => {
    if (completedCrop?.width && completedCrop?.height && imgRef.current) {
      const { blobImage, image, base64Canvas } = await canvasToFile(
        imgRef.current,
        completedCrop,
        zoom,
        0,
        "crop-image-free-mode",
        type
      );
      if (blobImage) {
        if (returnType === "blob") {
          setParentCrop(blobImage);
        } else {
          setParentCrop(image);
        }
        setCropPreview(base64Canvas);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedCrop, zoom]);

  function onImageLoad(e) {
    const { width, height } = e.currentTarget;
    let cropSize = Math.min(width, height);
    let defaultM = {
      width: 180,
      height: 180,
    };
    let finalW = defaultM.width;
    let finalH = defaultM.height;

    //calculate aspect ratio
    let aw = finalH / finalW;
    let aspectRatioW = aw * defaultM.width;

    let ah = finalW / finalH;
    let aspectRatioH = ah * defaultM.height;

    //set aspect ratio
    let aspectRatio = aspectRatioH / aspectRatioW;
    setAspect(aspectRatio);

    setCrop(
      centerCrop(
        {
          unit: "px",
          width: cropSize - 10,
          height: cropSize - 10,
        },
        width,
        height
      )
    );
  }

  useEffect(() => {
    getCropData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedCrop]);

  return (
    <div className="w-[500px]">
      <div
        className="w-full relative bg-passwordGray rounded-md flex justify-center items-center"
        style={{ minHeight: "400px" }}
      >
        <div
          className="w-full overflow-y-auto custom-scroll flex justify-center items-center"
          style={{ maxHeight: "73vh" }}
        >
          {!!imgSrc && (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
              locked={locked}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                onLoad={onImageLoad}
                style={{ maxHeight: "500px", maxWidth: "500px", objectFit: "contain" }}
              />
            </ReactCrop>
          )}
        </div>
      </div>
    </div>
  );
}
