import LoadingIndicator from "assets/svg/loading-indicator";
import React from "react";

export default function Loader({ message }) {
  return (
    <div className="w-full flex flex-col justify-center items-center">
      <LoadingIndicator className="h-10 w-10 text-primary" />
      <p className="text-darkerGray font-semibold leading-6.7 mt-3">
        {message}
      </p>
    </div>
  );
}
