import React, { Suspense } from "react";
const Sidebar = React.lazy(() => import("./sidebar"));
const SideFilter = React.lazy(() => import("./filters/side-filter"));
const DetailedViewMilestones = React.lazy(() =>
  import("./detailed-third-view")
);
const OrganizeThirdView = React.lazy(() =>
  import("./organize-position/third-view")
);

export default function ColumnView({
  selectedTab,
  filterRoles = [],
  organizeBy,
  isYourProfile,
  showFeatured,
  permission,
  options = {},
  current = [],
  past = [],
  pin = [],
  featured = {},
  milestones = [],
  setSelectedTab = () => {},
  onWatchMedia = () => {},
  onViewImage = () => {},
  handleViewPdf = () => {},
  onEdit = () => {},
  onDelete = () => {},
  onChangeOrganizeBy = () => {},
  onChangeFeaturedOnly = () => {},
  //
  onShare = () => {},
  onReport = () => {},
  profile,
}) {
  return (
    <Suspense>
      <div className="w-full container-max-width mt-8">
        <div className="w-full 2sm:grid 2sm:grid-cols-5 gap-3">
          <div className="mb-5 2sm:mb-0">
            <div className="hidden md:flex bg-white rounded-md">
              <Sidebar  positions={options.positions}
                  {...{
                    organizeBy,
                    filterRoles,
                    showFeatured,
                    onChangeOrganizeBy,
                    onChangeFeaturedOnly,
                  }} />
            </div>
          </div>
          <div className="col-span-4">
            <div className="grid 2sm:grid-cols-3 gap-3">
              <div className="2sm:col-span-2 order-last 2sm:order-first">
                <div className="rounded-md">
                  {organizeBy === "Job/Role" ? (
                    <OrganizeThirdView
                      {...{
                        isYourProfile,
                        featured,
                        pin,
                        milestones,
                        showFeatured,
                        permission,
                        onWatchMedia,
                        onViewImage,
                        handleViewPdf,
                        onEdit,
                        onDelete,
                        onReport,
                        profile,
                      }}
                    />
                  ) : (
                    <DetailedViewMilestones
                      {...{
                        isYourProfile,
                        featured,
                        current,
                        past,
                        pin,
                        showFeatured,
                        permission,
                        onWatchMedia,
                        onViewImage,
                        handleViewPdf,
                        onEdit,
                        onDelete,
                        onShare,
                        onReport,
                        profile,
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="rounded-md mb-2 2sm:mb-0">
                <SideFilter
                  positions={options.positions}
                  {...{
                    organizeBy,
                    filterRoles,
                    showFeatured,
                    onChangeOrganizeBy,
                    onChangeFeaturedOnly,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  );
}
