import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from './actions'

export const usePrivacies = () => {

    const {
        refresh,
        initialize,
        privacies = [],
    } = useSelector((state) => state.privacy);

    const dispatch = useDispatch();

    const setRefreshPrivacies = useCallback(
        (payload) => dispatch(actions.setRefreshPrivacies(payload)),
        [dispatch]
    );

    const setPrivacies = useCallback(
        (payload) => dispatch(actions.setPrivacies(payload)),
        [dispatch]
    );

    return {
        refresh,
        initialize,
        privacies,
        setRefreshPrivacies,
        setPrivacies
    };
} 