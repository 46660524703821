const PlayIconSmallSVG = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      className={className}
      fill="none">
      <path
        d="M10.4451 4.41185C10.7333 4.5651 10.9743 4.79386 11.1424 5.07363C11.3105 5.35339 11.3993 5.67362 11.3993 6.00001C11.3993 6.32639 11.3105 6.64662 11.1424 6.92639C10.9743 7.20615 10.7333 7.43492 10.4451 7.58816L2.75812 11.7682C1.52035 12.442 0 11.566 0 10.1807V1.81993C0 0.433972 1.52035 -0.441402 2.75812 0.231178L10.4451 4.41185Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PlayIconSmallSVG;
