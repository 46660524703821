import React, { useEffect, useState } from "react";
import BioIconSVG from "assets/svg/bio-icon";
import Button from "components/button/button";
import CalendarDayIconSVG from "assets/svg/calendar-day-icon";
import MapMarkerIconSVG from "assets/svg/map-marker-icon";
import TagIconSVG from "assets/svg/tag-icon";
import ChildHeadIconSVG from "assets/svg/child-head-icon";
import IDBadgeIconSVG from "assets/svg/id-badge-icon";
import { HiOutlinePencil } from "react-icons/hi";
import { FiCheck } from "react-icons/fi";
import TextArea from "components/textarea/textarea";
import toast from "react-hot-toast";
import LoadingIndicator from "assets/svg/loading-indicator";
import Input from "components/input/input";
import { CHILD, CIVIL_STATUS, removeUndefinedProperties } from "utils/utils";
import _ from "lodash";
import Select from "components/select/select";
import DatePicker from "components/datepicker/datepicker";
import moment from "moment";
import PopInfo from "components/info-popup/pop-info";
import { personalDateFormat } from "utils/utils";

const buttonProps = {
  edit: {
    btnName: "Edit",
    icon: <HiOutlinePencil />,
    btnClass: "relative py-3.6 px-5.5 bg-modalGray hover:bg-gray",
    btnTextClass:
      "text-sm text-lightBlack font-semibold flex items-center space-x-1.6",
  },
  save: {
    btnName: "Save Changes",
    icon: <FiCheck className="text-lg" />,
    btnClass: "relative py-3.6 px-5.5 bg-primary hover:bg-darkPrimary",
    btnTextClass:
      "text-sm text-white font-semibold flex items-center space-x-1.6",
  },
};

const ICONS = {
  birthdate: <CalendarDayIconSVG className="m-auto" />,
  birth_location: <MapMarkerIconSVG className="m-auto" />,
  civil_status: <TagIconSVG className="m-auto" />,
  children: <ChildHeadIconSVG className="m-auto" />,
  other_name: <IDBadgeIconSVG className="m-auto" />,
};

export default function GroupTeam(props) {
  console.log("==============================");
  console.log(props.profile);
  const { profile, userData, setUserData } = props;
  const { http, localstorage } = global.services;
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    bio: profile?.bio,
    birthdate: new Date(profile?.birthdate),
    birth_location: profile?.birth_location,
    civil_status: profile?.civil_status,
    children: profile?.children,
    other_name: profile?.other_name,
  });
  const [personal, setPersonal] = useState([]);

  useEffect(() => {
    if (!_.isEmpty(profile)) {
      setState({
        bio: profile?.bio,
        birthdate: new Date(profile?.birthdate),
        birth_location: profile?.birth_location,
        civil_status: profile?.civil_status,
        children: profile?.children,
        other_name: profile?.other_name,
      });
      setPersonal([
        [
          {
            label: "Date of Formation",
            value: profile?.birthdate,
            icon: ICONS.birthdate,
            form: "datepicker",
            formProps: {
              name: "birthdate",
            },
          },
          {
            label: "Formation Location",
            value: profile?.birth_location,
            icon: ICONS.birth_location,
            form: "input",
            formProps: {
              name: "birth_location",
            },
          },
        ],
      ]);
    }
  }, [profile]);
  //=============================functions ======================================
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onClickSave = async () => {
    try {
      setLoading(true);
      const payload = removeUndefinedProperties(state);
      if (!_.isEmpty(payload)) {
        if (payload.birthdate) {
          //add 8 hours so that if save it's still the exact date
          payload.birthdate = moment(payload.birthdate).add(8, "hours");
        }
        const { data } = await http.put(
          `public/profiles/update-personal-information/${profile?.id}`,
          payload
        );
        if (data) {
          toast.success("Personal Information Successfully Updated");
          const resultValue = data.data;
          setPersonal([
            [
              {
                label: "Date of Formation",
                value: resultValue.birthdate,
                icon: ICONS.birthdate,
                form: "datepicker",
                formProps: {
                  name: "birthdate",
                },
              },
              {
                label: "Birth Formation",
                value: resultValue.birth_location,
                icon: ICONS.birth_location,
                form: "input",
                formProps: {
                  name: "birth_location",
                },
              },
            ],
          ]);
          //set userdata and update localstorange
          console.log("resultValue", resultValue);
          const updatedData = { ...userData, profile: resultValue };
          setUserData(updatedData);
          localstorage.set("user_data", JSON.stringify(updatedData));
          //update state to false
          setLoading(false);
          setEdit(false);
        }
      }
    } catch (error) {
      toast.error(
        error?.data?.message || "It appears that something went wrong"
      );
      setLoading(false);
      setEdit(false);
    } finally {
      setLoading(false);
      setEdit(false);
    }
  };

  const displayValue = (name, value) => {
    if (name === "children") {
      if (value) {
        return `${value} Children`;
      } else {
        return "--";
      }
    } else if (name === "birthdate") {
      if (value) {
        return personalDateFormat(value, profile?.dateFormat);
      } else {
        return "--";
      }
    } else {
      return value || "--";
    }
  };

  return (
    <div className="w-full bg-white rounded-md px-5 2md:pl-29 2md:pr-24.5 py-10 md:pb-14 md:pt-8">
      <div className="w-full flex justify-between flex-wrap items-center mb-8 ">
        <div className="flex flex-row gap-x-2 items-center">
          <h1 className="text-2xl font-semibold text-lightBlack">
            <span className="capitalize">{profile?.profile_type?.type}</span>{" "}
            Information
          </h1>
          <PopInfo containerClass="relative" type="personal_information" />
        </div>
        {props.isYourProfile && (
          <Button
            buttonName={
              edit ? buttonProps.save.btnName : buttonProps.edit.btnName
            }
            buttonTextClass={
              edit
                ? buttonProps.save.btnTextClass
                : buttonProps.edit.btnTextClass
            }
            iconPosition="left"
            icon={edit ? buttonProps.save.icon : buttonProps.edit.icon}
            buttonRadius="6px"
            buttonClass={
              edit ? buttonProps.save.btnClass : buttonProps.edit.btnClass
            }
            loadingIcon={
              loading ? (
                <LoadingIndicator className="text-white w-4 h-4" />
              ) : null
            }
            disabled={loading}
            onClick={() => {
              if (edit) {
                onClickSave();
              } else {
                setEdit((prev) => !prev);
              }
            }}
          />
        )}
      </div>
      <div className="w-full grid gap-y-12 max-w-screen-2smd">
        {/* ==================== BIO =================================== */}
        <div className="w-full">
          <div
            className={`${
              props.isYourProfile
                ? "w-full flex flex-start items-start space-x-6"
                : profile?.bio
                ? "w-full flex flex-start items-start space-x-6"
                : "hidden"
            }`}>
            <div className="w-15 h-15 rounded-full bg-bGray grid">
              <BioIconSVG className="m-auto" />
            </div>
            <div style={{ width: "calc(100% - 60px)" }}>
              <h3 className="text-xl text-lightBlack font-semibold">Bio</h3>
              {edit ? (
                <TextArea
                  formContainer="mt-3"
                  defaultValue={profile?.bio || ""}
                  name="bio"
                  inputClass="p-4.5 text-base leading-6 text-lightBlack"
                  rows={4}
                  type="text"
                  onChange={handleInputChange}
                />
              ) : (
                <p className="text-base leading-6.7 tracking-tightest text-darkerGray mt-1.5">
                  {profile?.bio || "--"}
                </p>
              )}
            </div>
          </div>
        </div>
        {/* ===================== Divider ============================================ */}
        <hr className="text-gray" />
        {/* ==================== OTHER INFORMATION =================================== */}
        <div className="w-full">
          <div className="w-full grid gap-y-12">
            {(personal || []).map((parent, index) => {
              return (
                <div
                  className="w-full space-y-4.5 4sm:flex 4sm:flex-start 4sm:space-x-4.5 4sm:space-y-0"
                  key={index}>
                  {(parent || []).map((obj, subIndex) => {
                    if (props.isYourProfile || obj?.value) {
                      return (
                        <div
                          className="w-full flex flex-start items-start space-x-6"
                          key={subIndex}>
                          <div className="w-15 h-15 rounded-full bg-bGray grid">
                            {obj.icon}
                          </div>
                          <div style={{ width: "calc(100% - 60px)" }}>
                            <h3 className="text-xl text-lightBlack font-semibold">
                              {obj.label}
                            </h3>
                            {edit ? (
                              <div className="w-full mt-3">
                                <FormBuilder
                                  type={obj.form}
                                  name={obj.formProps.name}
                                  placeholder={obj.label}
                                  onChange={handleInputChange}
                                  defaultValue={obj.value}
                                  state={state}
                                />
                              </div>
                            ) : (
                              <p className="text-base leading-6.7 tracking-tightest text-darkerGray mt-1.5">
                                {displayValue(obj.formProps.name, obj.value)}
                              </p>
                            )}
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

function FormBuilder(props) {
  const { type, name, placeholder, onChange, defaultValue, state } = props;
  let options = CIVIL_STATUS;
  let selectDefaultValue = { label: defaultValue, value: defaultValue };
  if (name === "children") {
    options = CHILD;
    let valueObject = _.find(CHILD, { value: defaultValue });
    selectDefaultValue = valueObject;
  }
  if (type === "input") {
    return (
      <Input
        inputContainerClass="w-full"
        defaultValue={defaultValue}
        name={name}
        placeholder={placeholder}
        outsideLabelClass="text-sm font-bold text-darkerGray"
        noLabelPaddingY="1rem"
        type="text"
        onChange={onChange}
      />
    );
  } else if (type === "select") {
    return (
      <Select
        formContainer="w-full"
        options={options}
        placeholder={placeholder}
        name={name}
        defaultValue={selectDefaultValue || null}
        height={58}
        onChange={(e) => onChange({ target: { name: name, value: e.value } })}
      />
    );
  } else if (type === "datepicker") {
    return (
      <DatePicker
        formContainer="w-full"
        icon="noLabel"
        selected={state[name] ? new Date(state[name]) : null}
        onChange={(date) =>
          onChange({ target: { name: name, value: new Date(date) } })
        }
        placeholderText={placeholder}
      />
    );
  }
  return <></>;
}
