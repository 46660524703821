import {
  REFRESH_OCCUPATIONS,
	SET_OCCUPATIONS,
  ADD_OCCUPATION
} from './types';

export const setRefreshOccupations = () => {
	return {
    type: REFRESH_OCCUPATIONS,
    payload: [],
  };
}

export const setOccupations = (params) => {
	return {
    type: SET_OCCUPATIONS,
    payload: params,
  };
}

export const addOccupation = (params) => {
	return {
    type: ADD_OCCUPATION,
    payload: params,
  };
}