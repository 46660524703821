import React from "react";
import Select from "components/select/select";
import SocialSelect from "components/settings/component/SocialSelect";
import { profileStyles, profileTypes } from "utils/constant";
import PopInfo from "components/info-popup/pop-info";

export default function SocialMediaForm({
  state,
  socials = [],
  onAddSocials = () => {},
  onTypeLinkSocial = () => {},
  onSelectSocial = () => {},
  onRemoveSocials = () => {},
  handleInputChange = () => {},
}) {
  return (
    <div className="">
      <div className="w-full mt-15 3md:flex -mb-6">
        <div className="w-full mb-5 3md:w-6/20 3md:mb-0">
          <div className="w-full flex flex-row gap-x-2 items-center">
            <p className="text-lg text-lightBlack font-semibold">
              Social Media
            </p>
            <PopInfo
              containerClass="relative"
              type="setup_profile_social_medial"
            />
          </div>
        </div>
        <div className="w-full 3md:w-14/20">
          <SocialSelect
            items={socials}
            errors={state.validationErrors}
            onAdd={onAddSocials}
            onChange={(e, index) => onTypeLinkSocial(e, index)}
            onSelect={(e, index) => onSelectSocial(e, index)}
            onRemove={(index) => onRemoveSocials(index)}
          />
        </div>
      </div>

      {/* Profile types */}
      <div className="w-full mt-15 3md:flex">
        <div className="w-full mb-5 3md:w-6/20 3md:mb-0">
          <div className="w-full flex flex-row gap-x-2 items-center">
            <p className="text-lg text-lightBlack font-semibold">
              Profile Types
            </p>
            <PopInfo
              containerClass="relative"
              type="profile_types"
            />
          </div>
        </div>
        {/* forms */}
        <div className="w-full 3md:w-14/20">
          <div className="w-full grid 4sm:grid-cols-2 gap-x-4 gap-y-2 items-center mb-4">
            <Select
              label="Type"
              options={profileTypes}
              placeholder="Select Type"
              name="type"
              value={
                state.profileTypes &&
                profileTypes.filter((pt) => pt.value === state.profileTypes)
              }
              onChange={(e) =>
                handleInputChange({
                  target: { name: "profileTypes", value: e.value },
                })
              }
              errorMessage={state.validationErrors?.profileTypes}
              labelClass="text-sm font-bold text-darkerGray leading-4 marked"
            />
            <Select
              label="Style"
              options={profileStyles}
              placeholder="Select Style"
              name="style"
              value={
                state.profileStyle &&
                profileStyles.filter((ps) => ps.value === state.profileStyle)
              }
              onChange={(e) =>
                handleInputChange({
                  target: { name: "profileStyle", value: e.value },
                })
              }
              errorMessage={state.validationErrors?.profileStyle}
              labelClass="text-sm font-bold text-darkerGray leading-4 marked"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
