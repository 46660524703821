const ResumeLineDividerIconSVG = ({ className = "", full = false }) => {
  let width = "520";
  let viewBox = "0 0 520 2";
  let d = "M0 1H520";
  if (full) {
    width = "100%";
    viewBox = "0 0 693 2";
    d = "M0 1H693";
  }

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height="2"
      viewBox={viewBox}
      fill="none">
      <path opacity="0.1" d={d} stroke="#212121" />
    </svg>
  );
};

export default ResumeLineDividerIconSVG;
