const ListViewIconSVG = ({ className = "", fill = "#9A9A9A" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="5.38462" height="5.38462" rx="2" fill={fill} />
      <rect y="7.30762" width="5.38462" height="5.38462" rx="2" fill={fill} />
      <rect y="14.6152" width="5.38462" height="5.38462" rx="2" fill={fill} />
      <rect
        x="7.69232"
        y="0.769531"
        width="11.9231"
        height="3.46154"
        rx="1.73077"
        fill={fill}
      />
      <rect
        x="7.69232"
        y="8.07715"
        width="11.9231"
        height="3.46154"
        rx="1.73077"
        fill={fill}
      />
      <rect
        x="7.69232"
        y="15.3848"
        width="11.9231"
        height="3.46154"
        rx="1.73077"
        fill={fill}
      />
    </svg>
  );
};

export default ListViewIconSVG;
