import React from "react";

const Card = ({
  children,
  padding = "p-9", // default
  background = "bg-white", // default background
  className = "",
}) => {
  return (
    <div
      className={`relative w-full ${background} ${padding} rounded-xl ${className}`}
    >
      {children}
    </div>
  );
};

export default Card;
