import React from "react";
import { BsExclamationDiamondFill } from "react-icons/bs";
import { HiOutlineCalendarDays } from "react-icons/hi2";
import { renderStartDate, renderEndDate } from "../../resume";
import ResumeUnderlineIconSVG from "assets/svg/resume-underline-icon";
import ResumeLineDividerIconSVG from "assets/svg/resume-line-divider-icon";
import _ from "lodash";
import { formatDate } from "utils/utils";


const MilestoneTG = ({milestones,primaryColor,show_image_milestone,tertiaryColor,items,active,profile})=>{
    return (
        <>
            {items.some(element => element.type === 'featured') &&
            !_.isEmpty(items) && (
              <>
                <div className="w-full flex flex-row space-x-6.5">
                  <div className="w-14">
                    <p className="text-6xs font-medium text-resumeTempFourTextPrimary tracking-resumeFour">
                      Featured Milestones
                    </p>
                  </div>
                  <div style={{ width: "calc(100% - 81px)" }}>
                    {items.length > 0 && <div className="w-full grid gap-y-3">
                      {items.map((mf, i) => (
                        mf.type === 'featured' &&
                        <div
                          className="w-full flex flex-row items-center space-x-2.6"
                          key={`featured-${i}`}>
                          {/* style={{ width: "calc(100% - 111px)" }} */}
                          <div className="w-full">
                            {/* title and date */}
                            <div className="w-full">
                              <div className="w-full flex flex-row items-center justify-between">
                                {/* title */}
                                <div className="flex flex-row items-center space-x-1">
                                  <p className="text-xs text-resumeTempFourTextPrimary font-medium tracking-tight">
                                    {mf.title}
                                  </p>
                                  <div
                                    className="text-1xs font-bold "
                                    style={{ color: tertiaryColor + "E6" }}>
                                    <span
                                      className="template-four-padding-skills rounded text-opacity-90"
                                      style={{
                                        backgroundColor:
                                          tertiaryColor + "1F", // opcity 12%
                                      }}>
                                      {mf.category.name}
                                    </span>
                                  </div>
                                </div>
                                {/* date */}
                                <div>
                                  <p className="text-1xs text-resumeTempFourTextPrimary leading-3.4 flex flex-row items-center space-x-1">
                                    <HiOutlineCalendarDays className="w-2 h-2 text-resumeTempFourIconColor" />
                                    <span className="mt-px">
                                    {formatDate(mf,profile?.dateFormat)} 
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                            {/* sub title and episodes */}
                            <div className="w-full mt-0.5">
                              {mf.positions.map((p, i) => (
                                <div
                                  key={`featured-position-${i}`}
                                  className="w-full  flex flex-row space-x-1"
                                  style={{ color: primaryColor }}>
                                  <>
                                    <p className="text-7xs leading-3.5 font-medium">
                                      <span>{p.position.name}</span>
                                      {p.position.description && (
                                        <>
                                          <span> - </span>
                                          <span>
                                            {p.position.description}
                                          </span>
                                        </>
                                      )}
                                      {mf.platform && (
                                        <span>{` (${mf.platform})`}</span>
                                      )}
                                    </p>
                                    {mf.date_note && (
                                      <>
                                        <span className="text-7xs leading-3.5">
                                          &#8226;
                                        </span>
                                        <p className="text-7xs leading-3.5 font-medium">
                                          {mf.date_note}
                                        </p>
                                      </>
                                    )}
                                  </>
                                </div>
                              ))}
                            </div>
                            {/* description */}
                            <div className="w-full mt-2">
                              <p
                                className="text-7xs text-resumeTempFourTextPrimary tracking-resume leading-3.2"
                                dangerouslySetInnerHTML={{
                                  __html: mf.description,
                                }}
                              />
                            </div>
                          </div>
                          {/* image */}
                          {mf.thumbnail && (
                            <div className="h-23 w-23 bg-default rounded relative overflow-hidden">
                              <img
                                alt="milestones"
                                src={mf.thumbnail.url}
                                className="w-full h-full object-contain object-center"
                              />
                            </div>
                          )}
                        </div>
                      ))}
                    </div>}
                  </div>
                </div>
                {/* Divider */}
                <div className="w-full py-4">
                  <ResumeLineDividerIconSVG full="true" />
                </div>
              </>
            )}
          {/* Current Milestones */}
          {items.some(element => element.type === 'current') &&
            !_.isEmpty(items) && (
              <>
                <div className="w-full flex flex-row space-x-6.5">
                  <div className="w-14">
                    <p className="text-6xs font-medium text-resumeTempFourTextPrimary tracking-resumeFour">
                      Current Milestones
                    </p>
                  </div>
                  <div style={{ width: "calc(100% - 81px)" }}>
                  {items.length && <div className="w-full grid gap-y-3">
                      {items.map((mc, i) => (
                        mc.type === 'current' && 
                        <div
                          className="w-full flex flex-row items-center space-x-2.6"
                          key={`current-${i}`}>
                          <div className="w-full">
                            {/* title and date */}
                            <div className="w-full">
                              <div className="w-full flex flex-row items-center justify-between">
                                {/* title */}
                                <div className="flex flex-row items-center space-x-1">
                                  <p className="text-xs text-resumeTempFourTextPrimary font-medium tracking-tight">
                                    {mc.title}
                                  </p>
                                  <div
                                    className="text-1xs font-bold"
                                    style={{ color: tertiaryColor + "E6" }}>
                                    <span
                                      className="template-four-padding-skills rounded text-opacity-90"
                                      style={{
                                        backgroundColor:
                                          tertiaryColor + "1F", // opcity 12%
                                      }}>
                                      {mc.category.name}
                                    </span>
                                  </div>
                                </div>
                                {/* date */}
                                <div>
                                  <p className="text-1xs text-resumeTempFourTextPrimary leading-3.4 flex flex-row items-center space-x-1">
                                    <HiOutlineCalendarDays className="w-2 h-2 text-resumeTempFourIconColor" />
                                    <span className="mt-px">
                                      {formatDate(mc,profile?.dateFormat)} 
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                            {/* sub title and episodes */}
                            <div className="w-full mt-0.5">
                              {mc.positions.map((p, i) => (
                                <div
                                  key={`featured-position-${i}`}
                                  className="w-full flex flex-row space-x-1"
                                  style={{ color: primaryColor }}>
                                  <>
                                    <p className="text-7xs leading-3.5 font-medium">
                                      <span>{p.position.name}</span>
                                      {p.position.description && (
                                        <>
                                          <span> - </span>
                                          <span>
                                            {p.position.description}
                                          </span>
                                        </>
                                      )}
                                      {mc.platform && (
                                        <span>{` (${mc.platform})`}</span>
                                      )}
                                    </p>
                                    {mc.date_note && (
                                      <>
                                        <span className="text-7xs leading-3.5">
                                          &#8226;
                                        </span>
                                        <p className="text-7xs leading-3.5 font-medium">
                                          {mc.date_note}
                                        </p>
                                      </>
                                    )}
                                  </>
                                </div>
                              ))}
                            </div>
                            {/* description */}
                            <div className="w-full mt-2">
                              <p
                                className="text-7xs text-resumeTempFourTextPrimary tracking-resume leading-3.2"
                                dangerouslySetInnerHTML={{
                                  __html: mc.description,
                                }}
                              />
                            </div>
                          </div>
                          {/* image */}
                          {mc.thumbnail && (
                            <div className="h-23 w-23 bg-default rounded relative overflow-hidden">
                              <img
                                alt="milestones"
                                src={mc.thumbnail.url}
                                className="w-full h-full object-contain object-center"
                              />
                            </div>
                          )}
                        </div>
                      ))}
                    </div>}
                  </div>
                </div>
                {/* Divider */}
                <div className="w-full py-4">
                  <ResumeLineDividerIconSVG full="true" />
                </div>
              </>
            )}
          {/* Past Milestones */}
          {items.some(element => element.type === 'past') &&
            !_.isEmpty(items) && (
              <>
                <div className="w-full flex flex-row space-x-6.5">
                  <div className="w-14">
                    <p className="text-6xs font-medium text-resumeTempFourTextPrimary tracking-resumeFour">
                      Past Milestones
                    </p>
                  </div>
                  <div style={{ width: "calc(100% - 81px)" }}>
                   { items.length &&  <div className="w-full grid gap-y-3">
                      {items.map((mp, i) => (
                        mp.type === 'past' &&
                        <div
                          className="w-full flex flex-row items-center space-x-2.6"
                          key={`past-${i}`}>
                          <div className="w-full">
                            {/* title and date */}
                            <div className="w-full">
                              <div className="w-full flex flex-row items-center justify-between">
                                {/* title */}
                                <div className="flex flex-row items-center space-x-1">
                                  <p className="text-xs text-resumeTempFourTextPrimary font-medium tracking-tight">
                                    {mp.title}
                                  </p>
                                  <div
                                    className="text-1xs font-bold"
                                    style={{ color: tertiaryColor + "E6" }}>
                                    <span
                                      className="template-four-padding-skills rounded text-opacity-90"
                                      style={{
                                        backgroundColor: tertiaryColor + "1F", // opcity 12%
                                      }}>
                                      {mp.category.name}
                                    </span>
                                  </div>
                                </div>
                                {/* date */}
                                <div>
                                  <p className="text-1xs text-resumeTempFourTextPrimary leading-3.4 flex flex-row items-center space-x-1">
                                    <HiOutlineCalendarDays className="w-2 h-2 text-resumeTempFourIconColor" />
                                    <span className="mt-px">
                                    {formatDate(mp,profile?.dateFormat)} 
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                            {/* sub title and episodes */}
                            <div className="w-full mt-0.5">
                              {mp.positions.map((p, i) => (
                                <div
                                  key={`featured-position-${i}`}
                                  className="w-full flex flex-row space-x-1"
                                  style={{ color: primaryColor }}>
                                  <>
                                    <p className="text-7xs leading-3.5 font-medium">
                                      <span>{p.position.name}</span>
                                      {p.position.description && (
                                        <>
                                          <span> - </span>
                                          <span>
                                            {p.position.description}
                                          </span>
                                        </>
                                      )}
                                      {mp.platform && (
                                        <span>{` (${mp.platform})`}</span>
                                      )}
                                    </p>
                                    {mp.date_note && (
                                      <>
                                        <span className="text-7xs leading-3.5">
                                          &#8226;
                                        </span>
                                        <p className="text-7xs leading-3.5 font-medium">
                                          {mp.date_note}
                                        </p>
                                      </>
                                    )}
                                  </>
                                </div>
                              ))}
                            </div>
                            {/* description */}
                            <div className="w-full mt-2">
                              <p
                                className="text-7xs text-resumeTempFourTextPrimary tracking-resume leading-3.2"
                                dangerouslySetInnerHTML={{
                                  __html: mp.description,
                                }}
                              />
                            </div>
                          </div>
                          {/* image */}
                          {mp.thumbnail && (
                            show_image_milestone && <div className="h-23 w-23 bg-default rounded relative overflow-hidden">
                              <img
                                alt="milestones"
                                src={mp.thumbnail.url}
                                className="w-full h-full object-contain object-center"
                              />
                            </div>
                          )}
                        </div>
                      ))}
                    </div>}
                  </div>
                </div>
                {/* Divider */}
                {/* <div className="w-full py-4">
                  <ResumeLineDividerIconSVG full="true" />
                </div> */}
              </>
            )}
        </>
    );
}

export default MilestoneTG