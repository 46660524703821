import React, { useEffect, useState } from "react";
import Select from "components/select/select";
import Toggle from "components/toggle/toggle";
import { ORGANIZE_BY } from "constants/options";
import PopInfo from "components/info-popup/pop-info";

export default function ProfileSettingsForm({
  state,
  setState,
  keyword = [],
  userData = null,
  handleInputChange = () => {},
}) {
  const { http } = global.services;

  const [options, setOptions] = useState({
    positions: [],
    categories: [],
  });

  const getPositions = async () => {
    try {
      const { data } = await http.get("public/positions");
      const options = data.map((position) => ({
        label: position.name,
        value: position.id,
      }));
      setOptions((prev) => ({ ...prev, positions: options }));
    } catch (err) {
      console.log("getPosition", err);
    }
  };

  const getCategory = async () => {
    try {
      const { data } = await http.get("public/categories");
      console.log("sssss", data);
      const options = data.map((category) => ({
        label: category.name,
        value: category.id,
      }));
      setOptions((prev) => ({ ...prev, categories: options }));
    } catch (err) {
      console.log("getPosition", err);
    }
  };

  /**
   * This effect will run on component initialize
   */
  useEffect(() => {
    getPositions();
    getCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="pt-12.5 pb-10">
      <div className="w-full mt-15 3md:flex">
        <div className="w-full  mb-5 3md:w-6/20 3md:mb-0">
          <div className="w-full flex flex-row gap-x-2 items-center">
            <p className="text-lg text-lightBlack font-semibold">
              Notification
            </p>
            <PopInfo containerClass="relative" type="notification" />
          </div>
        </div>
        {/* forms */}
        <div className="w-full 3md:w-14/20">
          <div className="flex space-x-6">
            <div>
              <Toggle
                checked={state.showNotifications}
                name="messaging"
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    showNotifications: e?.target?.checked,
                  }))
                }
              />
            </div>
            <div>
              <p className="text-lg font-medium text-lightBlack">Messaging</p>
              <p className="text-md text-lightBlack">
                Notify me when someone sends me a message
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Filter Milestone Option */}
      <div className="w-full mt-15 3md:flex">
        <div className="w-full mb-5 3md:w-6/20 3md:mb-0">
          <div className="w-full flex flex-row gap-x-2 items-center">
            <p className="text-lg text-lightBlack font-semibold">
              Filter Milestone Option
            </p>
            <PopInfo containerClass="relative" type="milestone_option" />
          </div>
          <p className="font-normal text-md">
            Default filter on your milestone page
          </p>
        </div>
        {/* forms */}
        <div className="w-full 3md:w-14/20 -mb-6">
          <div className="w-full grid 3md:grid-cols-2 gap-4">
            <Select
              formContainer="flex-1"
              options={options.positions}
              height={70}
              placeholder="Select"
              name="job_role"
              innerLabel="* Role/Job Title"
              value={options.positions.filter(
                (p) => p.label === state.job_role
              )}
              onChange={(e) =>
                handleInputChange({
                  target: { name: "job_role", value: e.label },
                })
              }
              errorMessage={state.validationErrors?.job_role}
            />
            <Select
              formContainer="flex-1"
              options={options.categories}
              height={70}
              placeholder="Select"
              name="category"
              innerLabel="* Category"
              value={options.categories.filter(
                (c) => c.label === state.category
              )}
              onChange={(e) =>
                handleInputChange({
                  target: { name: "category", value: e.label },
                })
              }
              errorMessage={state.validationErrors?.category}
            />
            <Select
              formContainer="flex-1"
              options={ORGANIZE_BY}
              height={70}
              placeholder="Select"
              name="sort"
              innerLabel="* Sort"
              value={ORGANIZE_BY.filter(
                (option) => option.label === state.sort
              )}
              onChange={(e) =>
                handleInputChange({
                  target: { name: "sort", value: e.label },
                })
              }
              errorMessage={state.validationErrors?.sort}
            />
          </div>
          {/* <div className="w-full mt-10 flex space-x-6">
            <div>
              <Toggle
                checked={state.featured}
                name="messaging"
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    featured: e?.target?.checked,
                  }))
                }
              />
            </div>
            <div>
              <p className="text-lg font-medium text-lightBlack">
                Featured Milestone Post
              </p>
              <p className="text-md text-lightBlack">
                Show featured by default
              </p>
            </div>
          </div> */}
        </div>
      </div>
      {/* Content rating */}
      <div className="w-full mt-15 3md:flex">
        <div className="w-full  mb-5 3md:w-6/20 3md:mb-0">
          <div className="w-full flex flex-row gap-x-2 items-center">
            <p className="text-lg text-lightBlack font-semibold">
              Content Rating
            </p>
            <PopInfo containerClass="relative" type="content_rating" />
          </div>
        </div>
        {/* forms */}
        <div className="w-full 3md:w-14/20">
          <div className="flex space-x-6">
            <div>
              <Toggle
                checked={state.adultContent}
                name="adultContent"
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    adultContent: e?.target?.checked,
                  }))
                }
              />
            </div>
            <div>
              <p className="text-lg font-medium text-lightBlack">
                Adult Content
              </p>
              <p className="text-md text-lightBlack">
                Show adult content to me
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
