import React, { useState } from "react";
import Button from "components/button/button";
import Input from "components/input/input";
import { FaMinus, FaPlus } from "react-icons/fa";
import DragIcon from "assets/svg/drag-icon.png";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ToolTip from "components/tootltip/tootltip";

const noop = () => {};

export default function Members({
  items = [],
  onAdd = noop,
  onRemove = noop,
  onChange = noop,
  setItems,
  showAddBTN = true,
  customStyle={} //Draggable item is not appearing next to the cursor so add here the custom
}) {
  const [focusedItemId, setFocusedItemId] = useState(null);

  // const [itemList, setItemList] = useState([...items]);
  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    var updatedList = [...items];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    // setItemList(updatedList);
    setItems(updatedList);
  };

  return (
    <div className="w-full flex flex-col gap-y-3">
      <div className="w-full flex flex-row space-x-3 font-bold text-sm text-darkerGray">
        <div className="w-5">&nbsp;</div>
        <div className="flex-1">Name</div>
        <div className="flex-1">Role/Additional Information</div>
        <div className="flex-1">Start Date & End Date</div>
        <div className="w-6.5">&nbsp;</div>
      </div>
      <DragDropContext onDragEnd={handleDrop}>
        <Droppable droppableId="list-container">
          {(provided) => (
            <div
              className="list-container"
              {...provided.droppableProps}
              ref={provided.innerRef}>
              {items.map((obj, index) => (
                <Draggable
                  key={`${obj.name}${obj.role}${index}`}
                  draggableId={`${obj.name}${obj.role}${index}`}
                  index={index}>
                  {(provided) => (
                    <div
                      key={index}
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                      style={{ ...provided.draggableProps.style,
                        ...customStyle
                      }}
                      >
                      <div className="flex space-x-3 bg mb-2" key={index}>
                        
                      <div className="flex items-center">
                        <ToolTip 
                          value="Drag to re-order"
                          backgroundColor="#0c6ed1"
                          color="white"
                          width="w-[8rem]"
                        >
                          <img alt="milestones" src={DragIcon} />
                        </ToolTip> 
                          </div>
                        <Input
                          inputContainerClass="flex-1"
                          name={`name-${index}`}
                          value={obj.name}
                          placeholder="John Doe"
                          noLabelPaddingY="0.8rem"
                          type="text"
                          onChange={(e) => onChange(e, index)}
                          inputClass="text-sm"
                          errorMessagePosition="bottom"
                          autoFocus={focusedItemId === `name-${index}`}
                          onClick={() => setFocusedItemId(`name-${index}`)}
                        />
                        <Input
                          inputContainerClass="flex-1"
                          name={`role-${index}`}
                          value={obj.role}
                          placeholder="Drummer"
                          noLabelPaddingY="0.8rem"
                          type="text"
                          onChange={(e) => onChange(e, index)}
                          inputClass="text-sm"
                          errorMessagePosition="bottom"
                          autoFocus={focusedItemId === `role-${index}`}
                          onClick={() => setFocusedItemId(`role-${index}`)}
                        />
                        <Input
                          inputContainerClass="flex-1"
                          name={`dateSE-${index}`}
                          value={obj.dateSE}
                          placeholder="Jan 2024 - Present"
                          noLabelPaddingY="0.8rem"
                          type="text"
                          onChange={(e) => onChange(e, index)}
                          inputClass="text-sm"
                          errorMessagePosition="bottom"
                          autoFocus={focusedItemId === `dateSE-${index}`}
                          onClick={() => setFocusedItemId(`dateSE-${index}`)}
                        />
                        {/* button remove */}
                        <button
                          className="h-6.5 w-6.5 bg-lightBlack rounded-full md:mt-3"
                          onClick={() => onRemove(index)}>
                          <FaMinus className="m-auto text-white" />
                        </button>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="w-full">
        <Button
          buttonName="Add Member"
          buttonTextClass="text-sm text-primary font-semibold flex items-center"
          iconPosition="left"
          icon={<FaPlus className="mr-3" />}
          buttonRadius="6px"
          buttonClass="relative py-4 px-9 bg-white border-2 border-primary"
          onClick={onAdd}
        />
      </div>
    </div>
  );
}
