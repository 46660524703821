import React, { useEffect, useMemo, useRef, useState } from "react";
import { FaPause, FaPlay, FaTimes } from "react-icons/fa";
import "./styles.css";

export function SeekBar({ appTime, duration, onInput }) {
  return (
    <input
      type="range"
      value={appTime}
      min={0}
      max={duration}
      onInput={onInput}
      className="seekbar bg-dark"
      style={{
        background: `linear-gradient(to right, black 0%, black ${
          ((appTime - 0) / (duration - 0)) * 100
        }%, #DEE2E6 ${((appTime - 0) / (duration - 0)) * 100}%, #DEE2E6 100%)`,
      }}
    />
  );
}

export default function AudioPlayer({
  media = {},
  hide = false,
  onClosePlayer = () => {},
}) {
  const audioRef = useRef();
  const [play, setPlay] = useState();
  const [playable, setPlayable] = useState(false);
  const [duration, setDuration] = useState(0);
  const [durationTime, setDurationTime] = useState("0:00");
  const [appTime, setAppTime] = useState(0);
  const [playedTime, setPlayedtime] = useState("0:00");
  const [seekTime, setSeekTime] = useState(0);

  const source = useMemo(() => {
    if (media.media.length > 0) {
      return media.media[0].media.url;
    }
    return media.url;
  }, [media]);

  useEffect(() => {
    const { sub_type } = media;
    if (sub_type.is_media_file) {
      setPlayable(true);
    }
  }, [media]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.currentTime = seekTime;
    }
  }, [seekTime]);

  useEffect(() => {
    if (appTime === duration) {
      setPlay(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appTime]);

  const onPlay = async () => {
    if (playable) {
      if (play) {
        setPlay(false);
        audioRef.current.pause();
      } else {
        if (audioRef.current.play) {
          setPlay(true);
          audioRef.current.play();
        }
      }
    } else {
      window.open(source, "_blank");
    }
  };

  const playingUpdate = async () => {
    setAppTime(audioRef.current.currentTime);
    var minutes = parseInt(audioRef.current.currentTime / 60, 10);
    var seconds = "0" + parseInt(audioRef.current.currentTime % 60);
    setPlayedtime(minutes + ":" + seconds.slice(-2));
  };

  const onLoad = async () => {
    setDuration(audioRef.current.duration);
    var minutes = parseInt(audioRef.current.duration / 60, 10);
    var seconds = "0" + parseInt(audioRef.current.duration % 60);
    setDurationTime(minutes + ":" + seconds.slice(-2));
  };
 
  return (
    <>
    <div
      className="w-full audio-player  hidden 5sm:block">
      <button
        className="absolute p-2 bg-default rounded-full -top-3 -right-2 hover:bg-gray"
        onClick={onClosePlayer}>
        <FaTimes />
      </button>
      <audio
        ref={audioRef}
        controls
        src={source}
        onLoadedData={onLoad}
        onTimeUpdate={playingUpdate}
        hidden
      />
      <div className="flex flex-row items-center w-65 2xs:w-81 md:w-448 py-4 px-6 shadow-lg bg-white rounded-md">
        <div className="mr-4">
          {play ? (
            <FaPause onClick={onPlay} className="h-4 cursor-pointer" />
          ) : (
            <FaPlay onClick={onPlay} className="h-4 cursor-pointer" />
          )}
        </div>
        <p className="text-lg mr-4">
          {playedTime}
          <span className="mx-1">/</span>
          {durationTime}
        </p>
        <SeekBar
          {...{
            appTime,
            duration,
          }}
          onInput={(event) => setSeekTime(event.target.value)}
        />
      </div>
    </div>
    {/* <div
      className=" audio-player  audio-container block sm:hidden">
      <audio
        ref={audioRef}
        controls
        src={source}
        onLoadedData={onLoad}
        onTimeUpdate={playingUpdate}
        hidden
      />
      <div className="flex flex-row items-center w-70 py-4 px-6 shadow-lg bg-white rounded-md">
        <div className="mr-4">
          {play ? (
            <FaPause onClick={onPlay} className="h-4 cursor-pointer" />
          ) : (
            <FaPlay onClick={onPlay} className="h-4 cursor-pointer" />
          )}
        </div>
        <p className="text-lg mr-4">
          {playedTime}
          <span className="mx-1">/</span>
          {durationTime}
        </p>
        <SeekBar
          {...{
            appTime,
            duration,
          }}
          onInput={(event) => setSeekTime(event.target.value)}
        />
          <button
        className="p-2  rounded-full top-0 right-0 "
        onClick={onClosePlayer}>
        <FaTimes />
      </button>
      </div>
    </div> */}
    </>
  );
}
