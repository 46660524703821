const UserGearIconSVG = ({ className = "", fill = "#333333" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3708_9648)">
        <path
          d="M15 6C15 2.691 12.309 0 8.99999 0C5.69099 0 2.99999 2.691 2.99999 6C2.99999 9.309 5.69099 12 8.99999 12C12.309 12 15 9.309 15 6ZM8.99999 10C6.79399 10 4.99999 8.206 4.99999 6C4.99999 3.794 6.79399 2 8.99999 2C11.206 2 13 3.794 13 6C13 8.206 11.206 10 8.99999 10ZM8.99199 14.938C9.05999 15.486 8.67199 15.985 8.12299 16.054C4.63199 16.49 1.99899 19.475 1.99899 23C1.99899 23.552 1.55099 24 0.998993 24C0.446993 24 -0.00100708 23.552 -0.00100708 23C-0.00100708 18.469 3.38499 14.63 7.87499 14.07C8.41699 14.001 8.92199 14.39 8.99099 14.939L8.99199 14.938ZM22.696 19.133L21.722 18.571C21.888 18.074 22 17.552 22 16.999C22 16.446 21.889 15.924 21.722 15.427L22.696 14.865C23.174 14.589 23.338 13.977 23.062 13.499C22.785 13.02 22.175 12.855 21.696 13.133L20.723 13.695C20.018 12.901 19.079 12.32 18 12.101V11C18 10.448 17.552 10 17 10C16.448 10 16 10.448 16 11V12.101C14.921 12.321 13.982 12.902 13.277 13.695L12.304 13.133C11.824 12.856 11.214 13.02 10.938 13.499C10.662 13.978 10.826 14.589 11.304 14.865L12.278 15.427C12.112 15.924 12 16.446 12 16.999C12 17.552 12.111 18.074 12.278 18.571L11.304 19.133C10.826 19.409 10.662 20.021 10.938 20.499C11.124 20.82 11.459 20.999 11.805 20.999C11.974 20.999 12.146 20.956 12.304 20.865L13.277 20.303C13.982 21.097 14.921 21.678 16 21.897V22.998C16 23.55 16.448 23.998 17 23.998C17.552 23.998 18 23.55 18 22.998V21.897C19.079 21.677 20.018 21.096 20.723 20.303L21.696 20.865C21.854 20.956 22.026 20.999 22.195 20.999C22.541 20.999 22.877 20.82 23.062 20.499C23.338 20.02 23.174 19.409 22.696 19.133ZM17 19.999C15.346 19.999 14 18.653 14 16.999C14 15.345 15.346 13.999 17 13.999C18.654 13.999 20 15.345 20 16.999C20 18.653 18.654 19.999 17 19.999Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_3708_9648">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UserGearIconSVG;
