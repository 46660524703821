import NotificationDotIconSVG from "assets/svg/notification-dot-icon";
import Button from "components/button/button";
import moment from "moment";
import React from "react";

import ProfileDefault from "assets/images/profile_default.png";

// notes
//type = follow or invitation
export default function NotificationContentItem({
  id,
  user_access_notification = {},
  is_read,
  created_at,
  type,
  acceptInvite, //for testing only please remove
  markReadNotification = () => {}
}) {
  const { from = {}, user_access = {} } = user_access_notification || {from: {}, user_access:{}};

  return (
    <li className="w-full flex justify-between" onClick={() => markReadNotification(id)}>
      <div className="w-16/20 flex">
        {/* new */}
        <div className="w-3.5">{!is_read && <NotificationDotIconSVG />}</div>
        {/*  */}
        <div className="w-full">
          {/* notification description */}
          {type === "profile_invite" && (
            <p className="text-base leading-6 text-lightBlack">
              <span className="font-semibold">
                {from?.first_name + " " + from?.last_name + " "}
              </span>
              has invited you to be {user_access?.permission?.name} on the
              profile
              <span className="font-semibold text-primary">
                {" " + from?.username}
              </span>
            </p>
          )}
          {type === "profile_invite_response" && (
            <p className="text-base leading-6 text-lightBlack">
              <span className="font-semibold">
                {from?.first_name + " " + from?.last_name + " "}
              </span>
              has {user_access?.status + " "}
              your invitation to be{" "}
              {user_access?.permission?.name === "Admin"
                ? `an ${user_access?.permission?.name}`
                : `a ${user_access?.permission?.name}`}{" "}
              of your profile
              <span className="font-semibold text-primary">
                {" " + from?.username}
              </span>
              .
            </p>
          )}
          {type === "following" && (
            <p className="text-base leading-6 text-lightBlack">
              {console.log("::from", from)}
              <span className="font-semibold">
                {`${from?.first_name} ${from?.last_name} `}
              </span>
              started following you.
            </p>
          )}

          {type === "new_milestone" && (
            <p className="text-base leading-6 text-lightBlack">
              <span className="font-semibold">
                {`${from?.first_name} ${from?.last_name} `}
              </span>
              posted a milestone.
            </p>
          )}

          {/* button */}
          {type === "profile_invite" && user_access?.status === "pending" && (
            <div className="w-full mt-2">
              <div className="w-full flex space-x-2">
                <Button
                  buttonWidth="100%"
                  buttonName="Decline"
                  buttonTextClass="text-sm text-lightBlack font-semibold leading-4.4"
                  buttonRadius="6px"
                  buttonClass="relative py-3 bg-thBg"
                  onClick={acceptInvite}
                />
                <Button
                  buttonWidth="100%"
                  buttonName="Accept"
                  buttonTextClass="text-sm text-white font-semibold leading-4.4"
                  buttonRadius="6px"
                  buttonClass="relative py-3 bg-primary"
                  onClick={acceptInvite}
                />
              </div>
            </div>
          )}

          {/* data */}
          <div className="w-full mt-2">
            <p className="text-xs font-semibold leading-3.5 text-notificationText">
              {moment(created_at).fromNow()}
            </p>
          </div>
        </div>
      </div>
      <div className="w-10.5 h-10.5 rounded-md">
        {type === "profile_invite" && (
          <img
            src={from?.profile?.profile_image_url || ProfileDefault}
            alt="milestone-profile"
            className="rounded-md w-10.5 h-10.5"
          />
        )}
        {type === "profile_invite_response" && (
          <img
            src={from?.profile?.profile_image_url || ProfileDefault}
            alt="milestone-profile"
            className="rounded-md w-10.5 h-10.5"
          />
        )}
      </div>
    </li>

    // <li className="w-full flex justify-between">
    //   <div className="w-16/20 flex">
    //     {/* new */}
    //     <div className="w-3.5">{isNew && <NotificationDotIconSVG />}</div>
    //     {/*  */}
    //     <div className="w-full">
    //       {/* notification description */}
    //       {type === "invitation" ? (
    //         <p className="text-base leading-6 text-lightBlack">
    //           <span className="font-semibold">{names[0] + " "}</span>has invited
    //           you to be {permission} on the profile
    //           <span className="font-semibold text-primary">
    //             {" " + names[1]}
    //           </span>
    //         </p>
    //       ) : (
    //         <p className="text-base leading-6 text-lightBlack">
    //           <span className="font-semibold">{names[0] + " "}</span>started
    //           following you.
    //         </p>
    //       )}

    //       {/* button */}
    //       {type === "invitation" && (
    //         <div className="w-full mt-2">
    //           <div className="w-full flex space-x-2">
    //             <Button
    //               buttonWidth="100%"
    //               buttonName="Decline"
    //               buttonTextClass="text-sm text-lightBlack font-semibold leading-4.4"
    //               buttonRadius="6px"
    //               buttonClass="relative py-3 bg-thBg"
    //               onClick={viewModal}
    //             />
    //             <Button
    //               buttonWidth="100%"
    //               buttonName="Accept"
    //               buttonTextClass="text-sm text-white font-semibold leading-4.4"
    //               buttonRadius="6px"
    //               buttonClass="relative py-3 bg-primary"
    //               onClick={viewModal}
    //             />
    //           </div>
    //         </div>
    //       )}

    //       {/* data */}
    //       <div className="w-full mt-2">
    //         <p className="text-xs font-semibold leading-3.5 text-notificationText">
    //           {moment(date).fromNow()}
    //         </p>
    //       </div>
    //     </div>
    //   </div>
    //   <div className="w-10.5 h-10.5 rounded-md">
    //     <img
    //       src={image}
    //       alt="milestone-profile"
    //       className="rounded-md w-10.5 h-10.5"
    //     />
    //   </div>
    // </li>
  );
}
