const lineGraphMockData = [
    1000,
    1000,
    1000,
    1200,
    1200,
    1000,
    900,
    900,
    1000,
    1200,
    1200,
    1200,
    1000,
    950,
    980,
    900,
    920,
    1000,
    1050,
    1020,
    800,
    800,
    900,
    850,
    800,
    750,
    700,
    650,
    600,
    550,
    550,
]

export {
    lineGraphMockData
}