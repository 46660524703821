import React from "react";
import {
  CoverDefaultPlaceholderSmall,
  CoverDefaultPlaceholder,
} from "constants/images";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function ProfileCoverPhoto({ image = CoverDefaultPlaceholder }) {
  return (
    <div
      className={`w-full relative h-38.5 3md:h-400 rounded-t-md overflow-hidden`}>
      <LazyLoadImage
        alt="milestone-page-cover-page"
        src={image}
        placeholderSrc={CoverDefaultPlaceholderSmall}
        effect="blur"
        wrapperProps={{
          style: { width: "100%" },
        }}
        className="w-full h-full object-cover"
      />
    </div>
  );
}
