import React, { useMemo } from "react";
import UserGearIconSVG from "assets/svg/user-gear-icon";
import GraduationCapIconSVG from "assets/svg/graduation-cap-icon";
import ChatUserIconSVG from "assets/svg/chat-user-icon";
import BriefCaseExperienceIconSVG from "assets/svg/brief-case-icon";

import _ from "lodash";
import { socialTemplate } from "utils/constant";

export default function TemplateOneFullPageOne({
  details = {},
  social = {},
  primaryColor,
  secondaryColor,
  tertiaryColor,
}) {
  const socMed = useMemo(() => {
    let arrays = Object.entries(social);
    return arrays.map((obj) => {
      let key = obj[0];
      let value = obj[1];
      return { key, value };
    });
  }, [social]);

  return (
    <div className="w-full">
      <div style={{ minHeight: "1118px" }} className="h-full overflow-hidden">
        {/** ================ professional ================ */}
        {((details.hasOwnProperty("occupations") &&
          !_.isEmpty(details.occupations)) ||
          details.education ||
          details.training ||
          details.experience) && (
          <div className="flex flex-col space-x-2 mb-8">
            <div
              className="px-2 py-1.5 rounded-sm mb-4"
              style={{ backgroundColor: secondaryColor }}>
              <p className="text-sm font-bold text-resumeTempFourTextPrimary tracking-resumeOne">
                Professional Information
              </p>
            </div>
            <div className="grid gap-y-4 pr-2">
              {/* ========== Abilities / Skills / Specialties  ======== */}
              {details.hasOwnProperty("occupations") &&
                !_.isEmpty(details.occupations) && (
                  <div className="flex flex-row space-x-2">
                    <UserGearIconSVG className="w-5.5 h-5.5" fill={primaryColor} />
                    <div>
                      <p className="text-sm text-resumeTempFourTextPrimary font-semibold tracking-resumeOne">
                        Abilities / Skills / Specialties
                      </p>
                      <div
                        className="flex flex-row flex-wrap gap-1 text-1xs font-bold mt-1"
                        style={{ color: tertiaryColor }}>
                        {details.occupations.map((oc, i) => (
                          <span
                            key={i}
                            className="uppercase rounded-sm border"
                            style={{
                              borderColor: tertiaryColor,
                              padding: "2px 6px 3px 6px",
                            }}>
                            {oc}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              {/* ========== Education ======== */}
              {details.hasOwnProperty("education") && details.education && (
                <div className="w-full flex flex-row space-x-2">
                  <GraduationCapIconSVG
                    className="w-5.5 h-5.5"
                    fill={primaryColor}
                  />
                  <div style={{ width: "calc(100% - 24px)" }}>
                    <p className="text-sm text-resumeTempFourTextPrimary font-semibold tracking-resumeOne">
                      Education
                    </p>
                    <div className="w-full mt-1">
                      <p className="text-4xs text-darkerGray tracking-resume leading-4.5">
                        {details.education}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {/* ========== Education ======== */}
              {details.hasOwnProperty("training") && details.training && (
                <div className="w-full flex flex-row space-x-2">
                  <ChatUserIconSVG className="w-5.5 h-5.5" fill={primaryColor} />
                  <div style={{ width: "calc(100% - 24px)" }}>
                    <p className="text-sm text-resumeTempFourTextPrimary font-semibold tracking-resumeOne">
                      Training
                    </p>
                    <div className="w-full mt-1">
                      <p className="text-4xs text-darkerGray tracking-resume leading-4.5">
                        {details.training}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {/* ========== Experience ======== */}
              {details.hasOwnProperty("experience") && details.experience && (
                <div className="w-full flex flex-row space-x-2">
                  <BriefCaseExperienceIconSVG
                    className="w-5.5 h-5.5"
                    fill={primaryColor}
                  />
                  <div style={{ width: "calc(100% - 24px)" }}>
                    <p className="text-sm text-resumeTempFourTextPrimary font-semibold tracking-resumeOne">
                      Experience
                    </p>
                    <div className="w-full mt-1">
                      <p className="text-4xs text-darkerGray tracking-resume leading-4.5">
                        {details.experience}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {/** social links */}
        {!_.isEmpty(social) && (
          <div className="flex flex-col space-x-2 mb-8">
            <div
              className="px-2 py-1.5 rounded-sm mb-4"
              style={{ backgroundColor: secondaryColor }}>
              <p className="text-sm font-bold text-resumeTempFourTextPrimary tracking-resumeOne">
                Follow Me
              </p>
            </div>
            <div className="grid grid-cols-2 gap-x-4 pr-2">
              {(socMed || []).map((obj, index) => {
                const SocialIcon = socialTemplate[obj.key];
                if (SocialIcon) {
                  return (
                    <div
                      className="flex flex-row items-center break-all space-x-2"
                      key={index}>
                      <SocialIcon
                        className="text-xl"
                        style={{ color: tertiaryColor }}
                      />
                      <div className="leading-1">
                        <span className="text-6xs text-resumeTempFourTextPrimary">
                          {obj.value}
                        </span>
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
        )}
      </div>
      {/* ========== footer ======== */}
      <div
        className="w-full h-8 border-t px-2"
        style={{ borderColor: "#DAE4EF" }}>
        <div className="w-full h-full flex flex-row justify-between items-center">
          <p className="font-medium text-6xs text-darkerGray">Page 1</p>
          <div className="flex flex-start gap-1.5">
            <button className="w-2 h-2 rounded-full bg-primary" />
            <button className="w-2 h-2 rounded-full bg-pageInactive" />
          </div>
        </div>
      </div>
    </div>
  );
}
