import React, { useMemo } from "react";
import { FiBriefcase } from "react-icons/fi";
import { BiBuildingHouse } from "react-icons/bi";
import {
  FaFacebookF,
  FaRegUserCircle,
  FaTwitter,
  FaVimeoV,
  FaYoutube,
  FaFlickr,
  FaInstagram,
  FaLinkedin,
  FaTiktok,
  FaPinterest,
  FaReddit,
  FaSnapchat,
  FaTumblr,
  FaBandcamp,
  FaDeezer,
  FaGooglePlay,
  FaItunes,
  FaSoundcloud,
  FaSpotify,
  FaWikipediaW,
} from "react-icons/fa";
import { SiDiscogs, SiFiverr, SiOnlyfans } from "react-icons/si";
import { HiOutlineCalendarDays } from "react-icons/hi2";
import ResumeUnderlineIconSVG from "assets/svg/resume-underline-icon";
import ResumeDotDividerIconSVG from "assets/svg/resume-dot-divider-icon";
import { BsExclamationDiamondFill } from "react-icons/bs";
import _ from "lodash";

import { renderStartDate, renderEndDate } from "../../resume";

export default function TemplateTwoPageOne({
  color = {},
  personal_info = {},
  more_info = {},
  social_media = {},
  milestones = {},
  primaryColor,
  secondaryColor,
  tertiaryColor,
  quarternary,
  profile_image_withURL,
}) {
  const [socialColor, socialBG] = useMemo(() => {
    if (color.label === "Yellow + Blue") {
      return ["#FFFFFF", primaryColor];
    } else if (color.label === "Pink + Dark Pink") {
      return [tertiaryColor, "#FFFFFF"];
    }

    return ["#FFFFFF", tertiaryColor];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  return (
    <div className={`w-full`} style={{ height: "400px" }}>
      {/* header  */}
      <div className="w-full">
        <div
          className="w-full pt-2 pb-2 px-4"
          style={{ backgroundColor: primaryColor }}>
          <div className="w-full flex flex-row gap-x-2 text-white">
            {personal_info?.profile_image && (
              <>
                <div className="w-10 h-10 rounded-md relative overflow-hidden">
                  {personal_info &&
                  personal_info.hasOwnProperty("profile_image") &&
                  personal_info.profile_image ? (
                    <img
                      alt="Personal Info Profile Milestone"
                      src={profile_image_withURL || personal_info.profile_image}
                      className="w-full object-cover rounded-md"
                    />
                  ) : (
                    <div className="w-full h-full bg-white"></div>
                  )}
                </div>
              </>
            )}
            <div className="flex-1">
              {more_info && (
                <h1 className="text-4xs font-bold tracking-verytight">
                  {more_info.username}
                </h1>
              )}
              <div className="w-full flex flex-row flex-wrap gap-x-1 text-white items-center">
                {personal_info &&
                  personal_info.hasOwnProperty("name") &&
                  personal_info.name && (
                    <p className="text-5xs leading-2 font-medium">
                      {personal_info.name}
                    </p>
                  )}
                {personal_info &&
                  personal_info.hasOwnProperty("name") &&
                  personal_info.name &&
                  personal_info.hasOwnProperty("gender") &&
                  personal_info.gender && (
                    <div className="w-0.5 h-0.5 bg-white opacity-30 rounded-full"></div>
                  )}
                {personal_info &&
                  personal_info.hasOwnProperty("gender") &&
                  personal_info.gender && (
                    <p className="text-5xs leading-2 font-medium">
                      {personal_info.gender}
                    </p>
                  )}
                {personal_info &&
                  personal_info.hasOwnProperty("gender") &&
                  personal_info.gender &&
                  personal_info.hasOwnProperty("pronoun") &&
                  personal_info.pronoun && (
                    <div className="w-0.5 h-0.5 bg-white opacity-30 rounded-full"></div>
                  )}
                {personal_info &&
                  personal_info.hasOwnProperty("pronoun") &&
                  personal_info.pronoun && (
                    <p className="text-5xs leading-2 font-medium">
                      {personal_info.pronoun}
                    </p>
                  )}
                {personal_info &&
                  personal_info.hasOwnProperty("address") &&
                  personal_info.address && (
                    <>
                      <div className="w-0.5 h-0.5 bg-white opacity-30 rounded-full"></div>
                      <p className="text-5xs leading-2 font-medium">
                        {personal_info.address}
                      </p>
                    </>
                  )}
              </div>
              {/*  */}
              <div className="w-full text-white">
                <ul className="w-full">
                  {personal_info &&
                    personal_info.hasOwnProperty("occupations") &&
                    personal_info.occupations.length > 0 && (
                      <li className="w-full flex items-center space-x-1">
                        <FiBriefcase className="text-resume-icon-header opacity-70 w-1.5 h-1.5" />
                        <p className="text-5xs leading-2 font-medium">
                          {personal_info.occupations.join(", ")}
                        </p>
                      </li>
                    )}
                  {more_info &&
                    more_info.hasOwnProperty("industry") &&
                    more_info.industry && (
                      <li className="w-full flex space-x-1">
                        <BiBuildingHouse className="text-resume-icon-header opacity-70 w-2.5 h-2.5" />
                        <p className="text-1xs leading-2 font-medium">
                          {more_info.industry}
                        </p>
                      </li>
                    )}
                  {more_info &&
                    ((more_info.hasOwnProperty("type") && more_info.type) ||
                      (more_info.hasOwnProperty("style") &&
                        more_info.style)) && (
                      <li className="w-full flex space-x-1">
                        <FaRegUserCircle className="text-resume-icon-header opacity-70 w-2.5 h-2.5" />
                        <div className="w-full flex flex-row gap-x-1.5 items-center">
                          {more_info.hasOwnProperty("type") &&
                            more_info.type && (
                              <p className="text-1xs leading-2 font-medium">
                                {more_info.type}
                              </p>
                            )}
                          {more_info.hasOwnProperty("type") &&
                            more_info.hasOwnProperty("style") && (
                              <span className="text-1xs opacity-30">
                                &#8226;
                              </span>
                            )}
                          {more_info.hasOwnProperty("style") &&
                            more_info.style && (
                              <p className="text-1xs leading-2 font-medium">
                                {more_info.style}
                              </p>
                            )}
                        </div>
                      </li>
                    )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* end header */}
        <div className="py-1 px-4" style={{ backgroundColor: quarternary }}>
          <div className="w-full flex flex-wrap flex-row gap-x-3 justify-center text-white">
            {social_media && social_media.hasOwnProperty("facebook") && (
              <div className="flex items-center space-x-1.5">
                <button
                  className="w-2 h-2 rounded-full"
                  style={{ backgroundColor: socialBG }}>
                  <FaFacebookF
                    className="w-1 h-1 m-auto"
                    style={{ color: socialColor }}
                  />
                </button>
                <p className="text-5xs leading-2">{social_media.facebook}</p>
              </div>
            )}
            {social_media && social_media.hasOwnProperty("twitter") && (
              <div className="flex items-center space-x-1.5">
                <button
                  className="w-2 h-2 rounded-full"
                  style={{ backgroundColor: socialBG }}>
                  <FaTwitter
                    className="w-1 h-1 m-auto"
                    style={{ color: socialColor }}
                  />
                </button>
                <p className="text-5xs leading-2">{social_media.twitter}</p>
              </div>
            )}
            {social_media && social_media.hasOwnProperty("instagram") && (
              <div className="flex items-center space-x-1.5">
                <button
                  className="w-2 h-2 rounded-full"
                  style={{ backgroundColor: socialBG }}>
                  <FaInstagram
                    className="w-1 h-1 m-auto"
                    style={{ color: socialColor }}
                  />
                </button>
                <p className="text-5xs leading-2">{social_media.instagram}</p>
              </div>
            )}
            {social_media && social_media.hasOwnProperty("fiverr") && (
              <div className="flex items-center space-x-1.5">
                <button
                  className="w-2 h-2 rounded-full"
                  style={{ backgroundColor: socialBG }}>
                  <FaFlickr
                    className="w-1 h-1 m-auto"
                    style={{ color: socialColor }}
                  />
                </button>
                <p className="text-5xs leading-2">{social_media.fiverr}</p>
              </div>
            )}
            {social_media && social_media.hasOwnProperty("youtube") && (
              <div className="flex items-center space-x-1.5">
                <button
                  className="w-2 h-2 rounded-full"
                  style={{ backgroundColor: socialBG }}>
                  <FaYoutube
                    className="w-1 h-1 m-auto"
                    style={{ color: socialColor }}
                  />
                </button>
                <p className="text-5xs leading-2">{social_media.youtube}</p>
              </div>
            )}
            {social_media && social_media.hasOwnProperty("vimeo") && (
              <div className="flex items-center space-x-1.5">
                <button
                  className="w-2 h-2 rounded-full"
                  style={{ backgroundColor: socialBG }}>
                  <FaVimeoV
                    className="w-1 h-1 m-auto"
                    style={{ color: socialColor }}
                  />
                </button>
                <p className="text-5xs leading-2">{social_media.vimeo}</p>
              </div>
            )}
            {social_media && social_media.hasOwnProperty("linkedin") && (
              <div className="flex items-center space-x-1.5">
                <button
                  className="w-2 h-2 rounded-full"
                  style={{ backgroundColor: socialBG }}>
                  <FaLinkedin
                    className="w-1 h-1 m-auto"
                    style={{ color: socialColor }}
                  />
                </button>
                <p className="text-5xs leading-2">{social_media.linkedin}</p>
              </div>
            )}
            {social_media && social_media.hasOwnProperty("tiktok") && (
              <div className="flex items-center space-x-1.5">
                <button
                  className="w-2 h-2 rounded-full"
                  style={{ backgroundColor: socialBG }}>
                  <FaTiktok
                    className="w-1 h-1 m-auto"
                    style={{ color: socialColor }}
                  />
                </button>
                <p className="text-5xs leading-2">{social_media.tiktok}</p>
              </div>
            )}
            {social_media && social_media.hasOwnProperty("pinterest") && (
              <div className="flex items-center space-x-1.5">
                <button
                  className="w-2 h-2 rounded-full"
                  style={{ backgroundColor: socialBG }}>
                  <FaPinterest
                    className="w-1 h-1 m-auto"
                    style={{ color: socialColor }}
                  />
                </button>
                <p className="text-5xs leading-2">{social_media.pinterest}</p>
              </div>
            )}
            {social_media && social_media.hasOwnProperty("reddit") && (
              <div className="flex items-center space-x-1.5">
                <button
                  className="w-2 h-2 rounded-full"
                  style={{ backgroundColor: socialBG }}>
                  <FaReddit
                    className="w-1 h-1 m-auto"
                    style={{ color: socialColor }}
                  />
                </button>
                <p className="text-5xs leading-2">{social_media.reddit}</p>
              </div>
            )}
            {social_media && social_media.hasOwnProperty("snapchat") && (
              <div className="flex items-center space-x-1.5">
                <button
                  className="w-2 h-2 rounded-full"
                  style={{ backgroundColor: socialBG }}>
                  <FaSnapchat
                    className="w-1 h-1 m-auto"
                    style={{ color: socialColor }}
                  />
                </button>
                <p className="text-5xs leading-2">{social_media.snapchat}</p>
              </div>
            )}
            {social_media && social_media.hasOwnProperty("tumblr") && (
              <div className="flex items-center space-x-1.5">
                <button
                  className="w-2 h-2 rounded-full"
                  style={{ backgroundColor: socialBG }}>
                  <FaTumblr
                    className="w-1 h-1 m-auto"
                    style={{ color: socialColor }}
                  />
                </button>
                <p className="text-5xs leading-2">{social_media.tumblr}</p>
              </div>
            )}
            {social_media && social_media.hasOwnProperty("bandcamp") && (
              <div className="flex items-center space-x-1.5">
                <button
                  className="w-2 h-2 rounded-full"
                  style={{ backgroundColor: socialBG }}>
                  <FaBandcamp
                    className="w-1 h-1 m-auto"
                    style={{ color: socialColor }}
                  />
                </button>
                <p className="text-5xs leading-2">{social_media.bandcamp}</p>
              </div>
            )}
            {social_media && social_media.hasOwnProperty("deezer") && (
              <div className="flex items-center space-x-1.5">
                <button
                  className="w-2 h-2 rounded-full"
                  style={{ backgroundColor: socialBG }}>
                  <FaDeezer
                    className="w-1 h-1 m-auto"
                    style={{ color: socialColor }}
                  />
                </button>
                <p className="text-5xs leading-2">{social_media.deezer}</p>
              </div>
            )}
            {social_media && social_media.hasOwnProperty("discogs") && (
              <div className="flex items-center space-x-1.5">
                <button
                  className="w-2 h-2 rounded-full"
                  style={{ backgroundColor: socialBG }}>
                  <SiDiscogs
                    className="w-1 h-1 m-auto"
                    style={{ color: socialColor }}
                  />
                </button>
                <p className="text-5xs leading-2">{social_media.discogs}</p>
              </div>
            )}
            {social_media && social_media.hasOwnProperty("fiverr") && (
              <div className="flex items-center space-x-1.5">
                <button
                  className="w-2 h-2 rounded-full"
                  style={{ backgroundColor: socialBG }}>
                  <SiFiverr
                    className="w-1 h-1 m-auto"
                    style={{ color: socialColor }}
                  />
                </button>
                <p className="text-5xs leading-2">{social_media.fiverr}</p>
              </div>
            )}
            {social_media && social_media.hasOwnProperty("googlePlay") && (
              <div className="flex items-center space-x-1.5">
                <button
                  className="w-2 h-2 rounded-full"
                  style={{ backgroundColor: socialBG }}>
                  <FaGooglePlay
                    className="w-1 h-1 m-auto"
                    style={{ color: socialColor }}
                  />
                </button>
                <p className="text-5xs leading-2">{social_media.googlePlay}</p>
              </div>
            )}
            {social_media && social_media.hasOwnProperty("itunes") && (
              <div className="flex items-center space-x-1.5">
                <button
                  className="w-2 h-2 rounded-full"
                  style={{ backgroundColor: socialBG }}>
                  <FaItunes
                    className="w-1 h-1 m-auto"
                    style={{ color: socialColor }}
                  />
                </button>
                <p className="text-5xs leading-2">{social_media.itunes}</p>
              </div>
            )}
            {social_media && social_media.hasOwnProperty("soundcloud") && (
              <div className="flex items-center space-x-1.5">
                <button
                  className="w-2 h-2 rounded-full"
                  style={{ backgroundColor: socialBG }}>
                  <FaSoundcloud
                    className="w-1 h-1 m-auto"
                    style={{ color: socialColor }}
                  />
                </button>
                <p className="text-5xs leading-2">{social_media.soundcloud}</p>
              </div>
            )}
            {social_media && social_media.hasOwnProperty("spotify") && (
              <div className="flex items-center space-x-1.5">
                <button
                  className="w-2 h-2 rounded-full"
                  style={{ backgroundColor: socialBG }}>
                  <FaSpotify
                    className="w-1 h-1 m-auto"
                    style={{ color: socialColor }}
                  />
                </button>
                <p className="text-5xs leading-2">{social_media.spotify}</p>
              </div>
            )}
            {social_media && social_media.hasOwnProperty("wikipedia") && (
              <div className="flex items-center space-x-1.5">
                <button
                  className="w-2 h-2 rounded-full"
                  style={{ backgroundColor: socialBG }}>
                  <FaWikipediaW
                    className="w-1 h-1 m-auto"
                    style={{ color: socialColor }}
                  />
                </button>
                <p className="text-5xs leading-2">{social_media.wikipedia}</p>
              </div>
            )}
            {social_media && social_media.hasOwnProperty("onlyfans") && (
              <div className="flex items-center space-x-1.5">
                <button
                  className="w-2 h-2 rounded-full"
                  style={{ backgroundColor: socialBG }}>
                  <SiOnlyfans
                    className="w-1 h-1 m-auto"
                    style={{ color: socialColor }}
                  />
                </button>
                <p className="text-5xs leading-2">{social_media.onlyfans}</p>
              </div>
            )}
          </div>
        </div>
        {/* end extra header */}
        {/* start body */}
        <div className="w-full">
          <div className="w-full grid gap-y-3 px-4 mt-4">
            <div className="mb-1">
              {milestones.hasOwnProperty("featured") &&
                !_.isEmpty(milestones.featured) && (
                  <>
                    <div className="w-full flex flex-row gap-x-4">
                      <div className="w-10">
                        <p className="text-5xs font-medium text-lightBlack tracking-tight">
                          Featured Milestones
                        </p>
                        <ResumeUnderlineIconSVG
                          className="mt-1"
                          stroke={primaryColor}
                        />
                      </div>
                      <div className="w-full grid gap-y-3">
                        {milestones.featured.map((mf, i) => (
                          <div
                            key={`t2-featured-${i}`}
                            className="w-full flex flex-row gap-1.5">
                            <div className="shrink-0">
                              <div className="relative h-8 w-8 bg-default rounded overflow-hidden">
                                {Boolean(mf.is_adult_content) && (
                                  <div className="absolute matured-content-blured-small">
                                    <button className="w-full flex justify-center">
                                      <BsExclamationDiamondFill className="text-sm" />
                                    </button>
                                    <p className="text-xxs leading-2 font-bold">
                                      Matured Content
                                    </p>
                                  </div>
                                )}
                                {mf.thumbnail && (
                                  <img
                                    alt="milestones"
                                    src={mf.thumbnail.url}
                                    className="w-full h-full object-contain object-center"
                                  />
                                )}
                              </div>
                            </div>
                            <div className="w-full">
                              {/* title and date */}
                              <div className="flex justify-between items-center gap-1.5">
                                {/* title */}
                                <div className="flex items-center gap-1">
                                  <p className="text-5xs text-lightBlack font-semibold">
                                    {mf.title}
                                  </p>
                                  <p
                                    className="font-medium rounded-sm text-xxs px-0.5 text-white"
                                    style={{ backgroundColor: tertiaryColor }}>
                                    {mf.category.name}
                                  </p>
                                </div>
                                {/* date */}
                                <div className="text-5xs text-darkerGray font-medium tracking-resume leading-1 flex flex-row gap-1">
                                  <HiOutlineCalendarDays className="w-2 h-2 text-darkerGray" />
                                  {renderStartDate(mf)}
                                  {renderEndDate(mf)}
                                </div>
                              </div>
                              {/* sub title and episodes */}
                              <div className="w-full">
                                {mf.positions.map((p, i) => (
                                  <div
                                    key={`t2-featured-position-${i}`}
                                    className="w-full leading-1 flex flex-row space-x-1"
                                    style={{ color: primaryColor }}>
                                    <>
                                      <p className="text-5xs leading-none font-semibold">
                                        <span>{p.position.name}</span>
                                        {p.position.description && (
                                          <>
                                            <span> - </span>
                                            <span>
                                              {p.position.description}
                                            </span>
                                          </>
                                        )}
                                        {p.platform && (
                                          <span>{` (${p.platform})`}</span>
                                        )}
                                      </p>
                                      {p.date_note && (
                                        <>
                                          <span className="text-5xs leading-none text-darkerGray opacity-30">
                                            &#8226;
                                          </span>
                                          <p className="text-5xs leading-none font-semibold">
                                            {p.date_note}
                                          </p>
                                        </>
                                      )}
                                    </>
                                  </div>
                                ))}
                              </div>
                              {/* description */}
                              <div className="w-full mt-0.5">
                                <p
                                  className="text-5xs text-darkerGray tracking-resume leading-1.2"
                                  dangerouslySetInnerHTML={{
                                    __html: mf.description,
                                  }}></p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    {/* Divider */}
                    <div className="w-full mt-2">
                      <ResumeDotDividerIconSVG className="w-full" />
                    </div>
                  </>
                )}
            </div>
            {milestones.hasOwnProperty("current") &&
              !_.isEmpty(milestones.current) && (
                <>
                  <div className="w-full flex flex-row gap-x-4">
                    <div className="w-10">
                      <p className="text-5xs font-medium text-lightBlack tracking-tight">
                        Current Milestones
                      </p>
                      <ResumeUnderlineIconSVG
                        className="mt-1"
                        stroke={primaryColor}
                      />
                    </div>
                    <div className="w-full grid gap-y-3">
                      {milestones.current.map((mc, i) => (
                        <div
                          key={`t2-current-${i}`}
                          className="w-full flex flex-row gap-1.5">
                          <div className="shrink-0">
                            <div className="relative h-8 w-8 bg-default rounded overflow-hidden">
                              {Boolean(mc.is_adult_content) && (
                                <div className="absolute matured-content-blured-small">
                                  <button className="w-full flex justify-center">
                                    <BsExclamationDiamondFill className="text-sm" />
                                  </button>
                                  <p className="text-xxs leading-2 font-bold">
                                    Matured Content
                                  </p>
                                </div>
                              )}
                              {mc.thumbnail && (
                                <img
                                  alt="milestones"
                                  src={mc.thumbnail.url}
                                  className="w-full h-full object-contain object-center"
                                />
                              )}
                            </div>
                          </div>
                          <div className="w-full">
                            {/* title and date */}
                            <div className="flex justify-between items-center gap-1.5">
                              {/* title */}
                              <div className="flex items-center gap-1">
                                <p className="text-5xs text-lightBlack font-semibold">
                                  {mc.title}
                                </p>
                                <p
                                  className="font-medium rounded-sm text-xxs px-0.5 text-white"
                                  style={{ backgroundColor: tertiaryColor }}>
                                  {mc.category.name}
                                </p>
                              </div>
                              {/* date */}
                              <div className="text-5xs text-darkerGray font-medium tracking-resume leading-1 flex flex-row gap-1">
                                <HiOutlineCalendarDays className="w-2 h-2 text-darkerGray" />
                                {renderStartDate(mc)}
                                {renderEndDate(mc)}
                              </div>
                            </div>
                            {/* sub title and episodes */}
                            <div className="w-full">
                              {mc.positions.map((p, i) => (
                                <div
                                  key={`t2-current-position-${i}`}
                                  className="w-full leading-1 flex flex-row space-x-1"
                                  style={{ color: primaryColor }}>
                                  <>
                                    <p className="text-5xs leading-none font-semibold">
                                      <span>{p.position.name}</span>
                                      {p.position.description && (
                                        <>
                                          <span> - </span>
                                          <span>{p.position.description}</span>
                                        </>
                                      )}
                                      {p.platform && (
                                        <span>{` (${p.platform})`}</span>
                                      )}
                                    </p>
                                    {p.date_note && (
                                      <>
                                        <span className="text-5xs leading-none text-darkerGray opacity-30">
                                          &#8226;
                                        </span>
                                        <p className="text-5xs leading-none font-semibold">
                                          {p.date_note}
                                        </p>
                                      </>
                                    )}
                                  </>
                                </div>
                              ))}
                            </div>
                            {/* description */}
                            <div className="w-full mt-0.5">
                              <p
                                className="text-5xs text-darkerGray tracking-resume leading-1.2"
                                dangerouslySetInnerHTML={{
                                  __html: mc.description,
                                }}></p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {/* Divider */}
                  <div className="w-full">
                    <ResumeDotDividerIconSVG className="w-full" />
                  </div>
                </>
              )}
            {milestones.hasOwnProperty("past") &&
              !_.isEmpty(milestones.past) && (
                <>
                  <div className="w-full flex flex-row gap-x-4">
                    <div className="w-10">
                      <p className="text-5xs font-medium text-lightBlack tracking-tight">
                        Past Milestones
                      </p>
                      <ResumeUnderlineIconSVG
                        className="mt-1"
                        stroke={primaryColor}
                      />
                    </div>
                    <div className="w-full grid gap-y-3">
                      {milestones.past.map((mp, i) => (
                        <div
                          key={`t2-past-${i}`}
                          className="w-full flex flex-row space-x-2.6">
                          <div className="shrink-0">
                            <div className="relative h-8 w-8 bg-default rounded overflow-hidden">
                              {Boolean(mp.is_adult_content) && (
                                <div className="absolute matured-content-blured-small">
                                  <button className="w-full flex justify-center">
                                    <BsExclamationDiamondFill className="text-sm" />
                                  </button>
                                  <p className="text-xxs leading-2 font-bold">
                                    Matured Content
                                  </p>
                                </div>
                              )}
                              {mp.thumbnail && (
                                <img
                                  alt="milestones"
                                  src={mp.thumbnail.url}
                                  className="w-full h-full object-contain object-center"
                                />
                              )}
                            </div>
                          </div>
                          <div className="w-full">
                            {/* title and date */}
                            <div className="flex justify-between items-center gap-1.5">
                              {/* title */}
                              <div className="flex items-center gap-1">
                                <p className="text-5xs text-lightBlack font-semibold">
                                  {mp.title}
                                </p>
                                <p
                                  className="font-medium rounded-sm text-xxs px-0.5 text-white"
                                  style={{ backgroundColor: tertiaryColor }}>
                                  {mp.category.name}
                                </p>
                              </div>
                              {/* date */}
                              <div className="text-5xs text-darkerGray font-medium tracking-resume leading-1 flex flex-row gap-1">
                                <HiOutlineCalendarDays className="w-2 h-2 text-darkerGray" />
                                {renderStartDate(mp)}
                                {renderEndDate(mp)}
                              </div>
                            </div>
                            {/* sub title and episodes */}
                            <div className="w-full">
                              {mp.positions.map((p, i) => (
                                <div
                                  key={`t2-past-position-${i}`}
                                  className="w-full leading-1 flex flex-row space-x-1"
                                  style={{ color: primaryColor }}>
                                  <>
                                    <p className="text-5xs leading-none font-semibold">
                                      <span>{p.position.name}</span>
                                      {p.position.description && (
                                        <>
                                          <span> - </span>
                                          <span>{p.position.description}</span>
                                        </>
                                      )}
                                      {p.platform && (
                                        <span>{` (${p.platform})`}</span>
                                      )}
                                    </p>
                                    {p.date_note && (
                                      <>
                                        <span className="text-5xs leading-none text-darkerGray opacity-30">
                                          &#8226;
                                        </span>
                                        <p className="text-5xs leading-none font-semibold">
                                          {p.date_note}
                                        </p>
                                      </>
                                    )}
                                  </>
                                </div>
                              ))}
                            </div>
                            {/* description */}
                            <div className="w-full mt-0.5">
                              <p
                                className="text-5xs text-darkerGray tracking-resume leading-1.2"
                                dangerouslySetInnerHTML={{
                                  __html: mp.description,
                                }}></p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {/* Divider */}
                  <div className="w-full">
                    <ResumeDotDividerIconSVG className="w-full" />
                  </div>
                </>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}
