const VideoUploadIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="58"
      height="54"
      viewBox="0 0 58 54"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.6667 45.8042H3.3C2.95522 45.8042 2.62456 45.6672 2.38076 45.4234C2.13696 45.1796 2 44.849 2 44.5042V8.1042C2 7.75942 2.13696 7.42876 2.38076 7.18496C2.62456 6.94116 2.95522 6.8042 3.3 6.8042H47.7C48.0448 6.8042 48.3754 6.94116 48.6192 7.18496C48.863 7.42876 49 7.75942 49 8.1042V33.9849M42.4657 45.7699H48.9657M48.9657 45.7699H55.4657M48.9657 45.7699V39.2699M48.9657 45.7699V52.2699"
        stroke="#777777"
        strokeWidth="3.25"
        strokeLinecap="square"
      />
      <path
        d="M21.4457 18.7488C21.2481 18.6358 21.0243 18.5766 20.7967 18.5772C20.569 18.5777 20.3455 18.6381 20.1486 18.7522C19.9516 18.8662 19.788 19.03 19.6742 19.2272C19.5603 19.4243 19.5003 19.6479 19.5 19.8755V32.7303C19.4999 32.9581 19.5597 33.182 19.6734 33.3794C19.787 33.5769 19.9506 33.741 20.1476 33.8553C20.3447 33.9696 20.5683 34.0302 20.7961 34.0308C21.0239 34.0315 21.2479 33.9723 21.4457 33.8592L32.6907 27.4328C32.8897 27.3191 33.0551 27.1548 33.1702 26.9566C33.2853 26.7584 33.3459 26.5332 33.3459 26.304C33.3459 26.0748 33.2853 25.8496 33.1702 25.6514C33.0551 25.4531 32.8897 25.2888 32.6907 25.1752L21.4457 18.7488Z"
        stroke="#777777"
        strokeWidth="3.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default VideoUploadIconSVG;
