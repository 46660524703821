import {
	SET_USERS,
} from './types'

const initialState = {
	users: [],
	refresh: false,
	initialize: true
};

const reducer = (state = initialState, {type, payload}) => {

	switch (type) {

		case SET_USERS:
			return {
				...state,
				refresh: false,
				initialize: false,
				users: payload,
			}

		default:
			return state;

	}

}

export default reducer