const TemplateColorIconSVG = ({
  className = "",
  colors = ["#0C6ED1", "#EF6B33"],
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="30"
      viewBox="0 0 48 30"
      className={className}
      fill="none"
    >
      <circle
        cx="15"
        cy="15"
        r="13.5"
        fill={colors[0]}
        stroke="white"
        strokeWidth="3"
      />
      <circle
        cx="33"
        cy="15"
        r="13.5"
        fill={colors[1]}
        stroke="white"
        strokeWidth="3"
      />
    </svg>
  );
};

export default TemplateColorIconSVG;
