import React, { useState } from "react";
import ReactPlayer from "react-player";
import "./styles.css";
import { FaTimes } from "react-icons/fa"; 
import LoadingIndicator from "assets/svg/loading-indicator";

export default function WatchMediaInstruction({ hide, ...props }) {
  const { url } = props; 
  const [play, setPlay] = useState(true);
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const handleVideoLoad = ()=>{  
    setIsVideoLoading(false);
}
  return (
      <div className="absolute z-[9999] bottom-4 right-4 w-300 sm:w-500">
        
        <div className="flex justify-end items-center mb-2">
          {/* close */}
          <button onClick={() => hide()}>
            <FaTimes className="text-white text-xl" />
          </button>
        </div>
          <div className="wrapper bg-passwordGray">
            {
              isVideoLoading &&
              <h1 className="absolute top-0 right-0 text-black w-full h-full flex justify-center items-center">
                <LoadingIndicator className="w-10 h-10"/>
              </h1>
            }
            <ReactPlayer
                className="player"
                url={url}
                controls={true}
                playing={play}
                width="100%"
                height="100%"
                onPlay={() => setPlay(true)}
                onPause={() => setPlay(false)}
                onBufferEnd={()=>handleVideoLoad()}
              />
          </div> 
      </div>
  );
}
