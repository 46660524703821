import {
	SET_USER_DATA,
  SET_PROFILES,
  ADD_PROFILE,
  UPDATE_PROFILE,
  SET_SOCIAL_MEDIA
} from './types';

const setUserData = (params) => {
	return {
    type: SET_USER_DATA,
    payload: params,
  };
}

const setProfiles = (params) => {
	return {
    type: SET_PROFILES,
    payload: params,
  };
}

const setAddProfile = (params) => {
	return {
    type: ADD_PROFILE,
    payload: params,
  };
}

const setUpdateProfile = (params) => {
	return {
    type: UPDATE_PROFILE,
    payload: params,
  };
}

export const setSocialMedia = (params) => {
	return {
    type: SET_SOCIAL_MEDIA,
    payload: params,
  };
}

const userActions = {
  setUserData,
  setProfiles,
  setAddProfile,
  setUpdateProfile,
  setSocialMedia,
}

export default userActions;