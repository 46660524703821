const TagIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_3708_9611)">
        <path
          d="M7.70701 9.25579C8.09801 9.64679 8.09801 10.2798 7.70701 10.6698C7.31601 11.0608 6.68301 11.0608 6.29301 10.6698C5.90201 10.2788 5.90201 9.64579 6.29301 9.25579C6.68401 8.86479 7.31701 8.86479 7.70701 9.25579ZM21.559 15.3408L20.994 15.9058C20.967 17.1388 20.489 18.3628 19.559 19.3048L16.392 22.5128C15.449 23.4678 14.191 23.9958 12.849 23.9998H12.832C11.497 23.9998 10.242 23.4798 9.29801 22.5358L1.88201 15.1828C1.23201 14.5338 0.918012 13.6408 1.01801 12.7298L1.78301 5.81379C1.83401 5.35779 2.18701 4.99479 2.64101 4.93279L9.53001 3.99079C10.462 3.86679 11.4 4.18379 12.058 4.84179L19.533 12.2538C19.92 12.6408 20.23 13.0768 20.464 13.5418C21.276 12.3758 21.162 10.7468 20.122 9.70679L12.531 2.30179C12.302 2.07279 11.986 1.96679 11.68 2.00979L4.79101 2.95179C4.24201 3.02579 3.73901 2.64279 3.66401 2.09679C3.59001 1.54979 3.97301 1.04579 4.51901 0.97079L11.409 0.0277901C12.33 -0.10321 13.278 0.21879 13.937 0.87979L21.526 8.28479C23.472 10.2298 23.483 13.3918 21.558 15.3418L21.559 15.3408ZM18.121 13.6708L10.646 6.25879C10.423 6.03579 10.11 5.93279 9.79901 5.97179L3.68401 6.80879L3.00501 12.9488C2.97201 13.2518 3.07601 13.5498 3.29201 13.7648L10.708 21.1178C11.277 21.6878 12.03 21.9988 12.831 21.9988H12.841C13.647 21.9968 14.402 21.6798 14.967 21.1058L18.134 17.8978C19.289 16.7278 19.283 14.8308 18.12 13.6688L18.121 13.6708Z"
          fill="#333333"
        />
      </g>
      <defs>
        <clipPath id="clip0_3708_9611">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TagIconSVG;
