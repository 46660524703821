import React, { useEffect, useRef, useState, useCallback } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { canvasToFile } from "utils/constant";

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 100,
        height: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export default function FitSquareCrop({
  setParentCrop = () => {},
  setCropPreview = () => {},
  ...props
}) {
  const { image, type, returnType } = props;
  const [imgSrc, setImgSrc] = useState(image);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  // const width = "500px";
  // const height = "500px";
  // const [source, setSource] = useState({
  //   width: "auto",
  //   height: "auto",
  // });
  const [customCss, setCustomCss] = useState("");
  const [completedCrop, setCompletedCrop] = useState();
  const zoom = 1;
  const aspect = 1 / 1;

  const getCropData = useCallback(async () => {
    if (completedCrop?.width && completedCrop?.height && imgRef.current) {
      const { blobImage, image, base64Canvas } = await canvasToFile(
        imgRef.current,
        completedCrop,
        zoom,
        0,
        "crop-image-fit",
        type
      );
      if (blobImage) {
        if (returnType === "blob") {
          setParentCrop(blobImage);
        } else {
          setParentCrop(image);
        }
        setCropPreview(base64Canvas);
        setImgSrc(`data:${type};base64,${base64Canvas}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedCrop, zoom]);

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;

      const isPortrait = height > width;

      if (isPortrait) {
        setCustomCss("h-500");
      } else {
        setCustomCss("w-500");
      }

      setCrop(centerAspectCrop(500, 500, aspect));
    }
  }

  useEffect(() => {
    getCropData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedCrop]);

  return (
    <div className="w-full flex justify-center items-center">
      <div
        className="relative bg-passwordGray rounded-md flex justify-center items-center object-cover"
        style={{ height: "500px", width: "500px" }}>
        {!!imgSrc && (
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={aspect}
            locked={true}>
            <img
              crossorigin="anonymous"
              ref={imgRef}
              alt="Crop me"
              src={imgSrc}
              onLoad={onImageLoad}
              className={customCss}
            />
          </ReactCrop>
        )}
      </div>
    </div>
  );
}
