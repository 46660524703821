import React from "react";
import Button from "components/button/button";
import Input from "components/input/input";
import { FaMinus, FaPlus } from "react-icons/fa";
// import CreatableSelect from "components/select/creatable";
// import { social_icons } from "utils/constant";
// import HeroOtherIcon from "assets/svg/hero-other";
import DragIcon from "assets/svg/drag-icon.png";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ToolTip from "components/tootltip/tootltip";
const noop = () => {};

export default function WebsiteSelect({
  items = [],
  errors,
  onAdd = noop,
  onRemove = noop,
  onChange = noop,
  onBlur = noop,
  setItems,
  customStyle={} 
}) {

  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    var updatedList = [...items];
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    setItems(updatedList);
  };

  return ( 
    <div className="w-full">
      <DragDropContext onDragEnd={handleDrop}>
        <Droppable droppableId="list-container">  
        {(provided) => (
            <div
              className="list-container"
              {...provided.droppableProps}
              ref={provided.innerRef}>
                {items.map((obj, index) => (
                  <Draggable
                  key={`${obj.name}${obj.role}${index}`}
                  draggableId={`${obj.name}${obj.role}${index}`}
                  index={index}>
                    {(provided) => (
                    <div
                      key={index}
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                      style={{ ...provided.draggableProps.style,
                        ...customStyle
                      }}
                      >
                    <div key={index} className="flex space-x-3 bg mb-2">
                        <div className="flex items-center">
                          <ToolTip 
                            value="Drag to re-order"
                            backgroundColor="#0c6ed1"
                            color="white"
                            width="w-[8rem]"
                          >
                            <img alt="milestones" src={DragIcon} />
                          </ToolTip> 
                        </div> 
                        <Input
                          onBlur={(e) => onBlur(e, index, "website")}
                          inputContainerClass="flex-1"
                          name={`link-${index}`}
                          value={obj.link}
                          placeholder="https://www.link.com/image1"
                          noLabelPaddingY="0.8rem"
                          type="text"
                          onChange={(e) => onChange(e, index, "website")}
                          errorMessage={
                            (errors && errors[`social.${index}.link`]) ||
                            (errors?.social && errors?.social[index]) ||
                            (errors && errors[`social.${index}.type`])
                          }
                          errorMessagePosition="bottom"
                        />
                        {/* button remove */}
                        <button
                          className="h-6.5 w-6.5 bg-lightBlack rounded-full md:mt-3"
                          onClick={() => onRemove(index, "website")}>
                          <FaMinus className="m-auto text-white" />
                        </button>
                    </div>
                    </div>
                  )}
                  </Draggable>
                ))}
            {provided.placeholder}
            </div>
            )}
      </Droppable>
      </DragDropContext>
      {items.length < 5 && (
        <Button
          buttonName="Add Another Website"
          buttonTextClass="text-sm text-primary font-semibold flex items-center"
          iconPosition="left"
          icon={<FaPlus className="mr-3" />}
          buttonRadius="6px"
          buttonClass="relative py-4 px-9 bg-white border-2 border-primary"
          onClick={() => {
            onAdd("website");
          }}
        />
      )}
    </div>
  );
}
