import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import toast from "react-hot-toast";
import moment from "moment";
import LoadingIndicator from "assets/svg/loading-indicator";
import MessageFooter from "./message-footer";
import DOMPurify from "dompurify";
import { useDialogHook } from "utils/customhooks";
import ImageGalleryModal from "components/profile/milestones/media-button/image-gallery";
import MessageBlock from "./message-block";

import { useMessages } from "redux/modules/messages/hooks";

export default function MessageContent({
  userId = null,
  userProfileId = null,
  threadId = null,
  participantId = null,
  type = null,
  message_block = null,
}) {
  const { http } = global.services;
  const bottomEl = useRef(null);
  // const [conversation, setConversation] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState([]);
  const [submit, setSubmit] = useState();
  const imageGalleryModal = useDialogHook(ImageGalleryModal);
  const {
    conversations,
    setConversations,
    addConversation,
    setUnreadMessages,
  } = useMessages();

  const getConversation = async () => {
    try {
      setLoading(true);
      const { data } = await http.get("public/messages", {
        thread_id: threadId,
        limit: 10,
        page: 1,
      });
      // console.log("asdasdasd => ", data);
      if (!_.isEmpty(data.data)) {
        // setConversation(_.sortBy(data.data, ["created_at"]));
        setConversations(_.sortBy(data.data, ["created_at"]));
      }
    } catch (err) {
      setLoading(false);
      console.log("error@getPositions", err);
      toast.error("An Error occured. Failed to fecth messages");
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const onSendMessage = async (body, files) => {
    setSubmit(true);
    // let payload = {
    //   user_profile_id: userProfileId,
    //   body: body,
    //   participants: [userProfileId, participantId],
    //   thread: threadId,
    //   type: type,
    // };

    let formData = new FormData();
    formData.append("user_id", userId);
    formData.append("user_profile_id", userProfileId);
    if (body) formData.append("body", body);
    formData.append(
      "participants",
      JSON.stringify([userProfileId, participantId])
    );
    formData.append("thread", threadId);
    formData.append("type", type);
    files.forEach((f, i) => {
      formData.append(`attachments[${i}]`, f);
    });

    try {
      // setConversation((prev) => [
      //   ...prev,
      //   {
      //     id: Id,
      //     thread_id: threadId,
      //     user_profile_id: userProfileId,
      //     body: body,
      //     created_at: moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
      //     attachments: [],
      //   },
      // ]);
      const { data } = await http.post("public/messages/send", formData);
      if (!_.isEmpty(data.data)) {
        addConversation(data.data);
        //do something here if send
      }
      setAttachments([]);
    } catch (err) {
      setLoading(false);
      setSubmit(false);
      console.log("error@getPositions", err);
      //put some error here
      throw err;
    } finally {
      setLoading(false);
      setSubmit(false);
    }
  };

  const getTimeNext = (obj) => {
    if (obj?.user_profile_id === userProfileId) {
      return obj.created_at;
    }
    return null;
  };

  const getTimeNextLeft = (obj) => {
    if (obj?.user_profile_id === participantId) {
      return obj.created_at;
    }
    return null;
  };

  const scrollToBottom = () => {
    bottomEl?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleImageGallery = (media, i) => {
    imageGalleryModal({ media, focus: i }, (result) => {
      console.log("result =>", result);
    });
  };

  const onSeen = async () => {
    try {
      const { data } = await http.put("public/messages/action/seen", {
        user_profile_id: userProfileId,
        thread_id: threadId,
      });
      setUnreadMessages(data.unread);
    } catch (err) {
      console.log("onSeen", err);
    }
  };

  useEffect(() => {
    getConversation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threadId]);

  useEffect(() => {
    scrollToBottom();
  }, [conversations]);

  return (
    <>
      <div className="w-full message-content border-b border-gray">
        <div className="w-full py-11 pl-5 pr-8.5">
          {loading ? (
            <div className="w-full grid items-center justify-center">
              <LoadingIndicator className="w-10 h-10 text-primary" />
            </div>
          ) : (
            <div className="grid gap-y-2">
              {conversations.map((obj, index) => {
                if (parseInt(obj.user_profile_id) === parseInt(userProfileId)) {
                  let nextObj = conversations[index + 1];
                  let hideTime = obj.created_at === getTimeNextLeft(nextObj);
                  return (
                    <MessageLeft
                      key={index}
                      content={obj.body}
                      date={hideTime ? null : obj.created_at}
                      attachments={obj.attachments}
                      onViewImage={(e, i) => handleImageGallery(e, i + 1)}
                    />
                  );
                } else {
                  let nextObj = conversations[index + 1];
                  let hideTime = obj.created_at === getTimeNext(nextObj);
                  return (
                    <MessageRight
                      key={index}
                      content={obj.body}
                      date={hideTime ? null : obj.created_at}
                      attachments={obj.attachments}
                      onViewImage={(e, i) => handleImageGallery(e, i + 1)}
                    />
                  );
                }
              })}

              <div ref={bottomEl}></div>
            </div>
          )}
        </div>
      </div>
      {
        message_block?.status === 1 ? (
          <MessageBlock message_block={message_block} owner={userProfileId} />
        ) : (
          // {/* message type */}
          <MessageFooter
            submit={submit}
            send={onSendMessage}
            {...{ attachments, setAttachments, onSeen }}
          />
        )
        // {/*end: message type */}
      }
    </>
  );
}

const MessageRight = ({
  content = "",
  date = "",
  attachments = [],
  onViewImage = () => {},
}) => {
  return (
    <div className="w-full">
      <div className="message-content-file mb-2">
        <div className="grid grid-cols-4 gap-2 mb-2">
          {attachments.map((a, i) => {
            if (!a.media.mime_type.includes("image")) return <></>;
            return (
              <div
                key={a.media.file_name}
                className="flex justify-center h-20 overflow-hidden cursor-pointer"
                onClick={() => onViewImage(attachments, i)}>
                <img
                  src={a?.media?.url}
                  alt={a?.media?.url}
                  className="object-cover"
                />
              </div>
            );
          })}
        </div>
        <div className="flex flex-row gap-1 flex-wrap">
          {attachments.map((a, i) => {
            if (!a.media.mime_type.includes("pdf")) return <></>;
            return (
              <div
                key={a.media.file_name}
                className="text-sm text-primary hover:font-bold cursor-pointer"
                onClick={() => onViewImage(attachments, i)}>
                <a href="#">
                  {a.media.title}.{a.media.file_extension}
                </a>
              </div>
            );
          })}
        </div>
      </div>
      {/* message item */}
      <div className="flex justify-start">
        {content && (
          <div
            className="message-content-item bg-primary text-white"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                content && !["<p><br></p>", "<p></p>"].includes(content)
                  ? content
                  : "Full messsage shown here"
              ),
            }}
          />
        )}
      </div>
      {/* date and time */}
      {date && (
        <p className="text-sm text-darkerGray leading-7.5 tracking-tightest">
          {moment(date).format("MMM DD, YYYY HH:mm")}
        </p>
      )}
    </div>
  );
};

const MessageLeft = ({
  content = "",
  date = "",
  attachments = [],
  onViewImage = () => {},
}) => {
  return (
    <div className="w-full">
      <div className="flex justify-end mb-2">
        <div className="message-content-file">
          <div dir="rtl" className="grid grid-cols-4 gap-2 mb-2">
            {attachments.map((a, i) => {
              if (!a?.media?.mime_type?.includes("image")) return <></>;
              return (
                <div
                  key={a.media.file_name}
                  className="flex justify-center h-20 overflow-hidden cursor-pointer"
                  onClick={() => onViewImage(attachments, i)}>
                  <img
                    src={a?.media?.url}
                    alt={a?.media?.url}
                    className="object-cover"
                  />
                </div>
              );
            })}
          </div>
          <div dir="rtl" className="flex flex-row gap-1 flex-wrap">
            {attachments.map((a, i) => {
              if (!a?.media?.mime_type.includes("pdf")) return <></>;
              return (
                <div
                  key={a.media.file_name}
                  className="text-sm text-primary hover:font-bold cursor-pointer"
                  onClick={() => onViewImage(attachments, i)}>
                  <a href="#">
                    {a.media.title}.{a.media.file_extension}
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* message item */}
      {content && (
        <div className="flex justify-end">
          {content && (
            <div
              className="message-content-item bg-messageBg text-lightBlack"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  content && !["<p><br></p>", "<p></p>"].includes(content)
                    ? content
                    : "Full messsage shown here"
                ),
              }}
            />
          )}
        </div>
      )}

      {date && (
        <p className="text-sm text-darkerGray leading-7.5 tracking-tightest text-right">
          {moment(date).format("MMM DD, YYYY HH:mm")}
        </p>
      )}
    </div>
  );
};
