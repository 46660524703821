import React, { useEffect, useState } from "react";
import Layout from "layouts/layout";
import FAQItem from "components/faq/faq-item";
import InputSearch from "components/input/input-search";
import Select from "components/select/select";
import Pagination from "components/pagination/pagination";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import LoadingIndicator from "assets/svg/loading-indicator";
import { SET_CATEGORIES } from "redux/modules/category/types";
import { useSelector, useDispatch } from "react-redux";

export default function FAQ() {
  const { http } = global.services;
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(1);
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState(null);
  const [faqs, setFaqs] = useState({
    list: [],
    size: 10,
    total: 1,
    lastPage: 1,
  });
  const {
    categories = [],
    initialize: initializeCategory,
    refresh: refreshCategory,
  } = useSelector((state) => state.category);
  const dispatch = useDispatch();

  // ======== functions =================

  const getFaqs = async () => {
    setLoading(true);
    try {
      const result = await http.get(`public/faqs/list`, {
        limit: faqs.size,
        page: current,
        search: search,
        category: category,
      });
      const res = result.data;
      console.log("res => ", res);
      if (res) {
        setFaqs((prev) => ({
          ...prev,
          list: res?.data,
          total: res?.total,
          lastPage: res?.last_page,
        }));
      }
    } catch (err) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const getCategory = async () => {
    try {
      const { data } = await http.get("public/categories");
      const categories = data.map((category) => ({
        label: category.name,
        value: category.id,
      }));
      dispatch({
        type: SET_CATEGORIES,
        payload: categories,
      });
    } catch (err) {
      console.log("getPosition", err);
    }
  };

  // ======== useEffects =================

  useEffect(() => {
    if (initializeCategory) getCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCategory, initializeCategory]);

  useEffect(() => {
    getFaqs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, category, current]);

  return (
    <Layout
      title="FAQs"
      breadscrumbs={{
        title: "Frequently Asked Questions",
        subtitle: "Your concerns are all answered here",
        showBanner: true,
        currentUrl: "/faqs"
      }}
    >
      <div className="website-page-wrapper py-14.5">
        {/* filters */}
        <div className="w-full">
          <div className="w-full 1md:flex 1md:justify-between">
            <div>
              <InputSearch
                placeholder="Search"
                buttonName="Search"
                onSearch={(e) => {
                  setSearch(e);
                }}
              />
            </div>
            <div className="w-full mt-4 1md:w-400 1md:mt-0">
              <Select
                isClearable={true}
                options={categories}
                placeholder="Select Category"
                name="category"
                onChange={(e) => setCategory(e?.label || null)}
              />
            </div>
          </div>
        </div>
        {loading ? (
          <div className="w-full flex items-center justify-center h-52">
            <LoadingIndicator className="w-20 h-20 text-secondary" />
          </div>
        ) : (
          <div className="w-full mt-8 grid md:grid-cols-2 2md:grid-cols-3 gap-7">
            {(faqs.list || []).map((obj, index) => (
              <FAQItem
                key={index}
                id={obj.id}
                title={obj.title}
                description={obj.content}
              />
            ))}
          </div>
        )}
        <div className="w-full overflow-auto">
          <Pagination
            pagination={{
              size: faqs.size,
              total: faqs.total,
              lastPage: faqs.lastPage,
              onChangePage: (page) => {
                setCurrent(page);
              },
              currentPage: current,
            }}
            loading={false}
            prevLabel={<FaChevronLeft />}
            nextLabel={<FaChevronRight />}
          />
        </div>
      </div>
    </Layout>
  );
}
