import React from "react";
import _ from "lodash";
import { BsExclamationDiamondFill } from "react-icons/bs";
import { HiOutlineCalendarDays } from "react-icons/hi2";
import { renderStartDate, renderEndDate } from "../../resume";
import { formatDate } from "utils/utils";

export default function ItemMilestone({
  secondaryColor,
  tertiaryColor,
  show_image_milestone = true,
  items,
  headerType = 'featured',
  profile
}) {

  return (
        <>
          {/* featured, current and past milestones */}
          {items.some(element => element.type === headerType) &&
            !_.isEmpty(items) && (
              <div className="w-full flex flex-col space-x-2 mb-4">
                <div
                  className="px-2 py-1.5 rounded-sm mb-4"
                  style={{ backgroundColor: secondaryColor }}>
                  <p className="text-4xs font-bold text-resumeTempFourTextPrimary tracking-resumeOne">
                    {
                        headerType === 'featured' ? 'Featured Milestones' :
                        headerType === 'current' ? 'Current Milestones'  : 'Past Milestones'
                    }
                  </p>
                </div>
                {items.map((m, i) => (
                  m.type === headerType &&
                  <div key={`featured-${i}`} className="mb-2 pr-2 w-full flex flex-row space-x-2.6  h-23 overflow-hidden">
                    { show_image_milestone && <div className="h-23 w-23 bg-default rounded relative overflow-hidden">
                          {/* {Boolean(m.is_adult_content) && (
                            <div className="absolute matured-content-blured-small">
                              <button className="w-full flex justify-center">
                                <BsExclamationDiamondFill className="text-1xl cursor-pointer" />
                              </button>
                              <p className="text-1xs leading-6 font-bold">
                                Matured Content
                              </p>
                            </div>
                          )} */}
                          {m.thumbnail && (
                            <img
                              alt="milestones"
                              src={m.thumbnail.url}
                              className="w-full h-full object-contain object-center"
                            />
                          )}
                        </div> }
                    <div style={{ width: "calc(100% - 111px)" }}>
                      <div className="flex justify-between items-center space-x-1.5">
                        <div className="flex flex-row items-center space-x-1">
                          {/** title */}
                          <span className="text-4xs font-semibold text-resumeTempFourTextPrimary mt-0.5">
                            {m.title}
                          </span>
                          {/** category */}
                          <span
                            className="font-bold uppercase rounded-sm border text-5xs"
                            style={{
                              color: tertiaryColor,
                              padding: "1.5px 4px 2px 4px",
                            }}>
                            {m?.category?.name}
                          </span>
                        </div>
                        {/** dates */}
                        <div className="flex flex-row flex-wrap text-3xs text-darkGray gap-1">
                          <HiOutlineCalendarDays className="w-2 h-2" />
                          {formatDate(m, profile?.dateFormat)}
                        </div>
                      </div>
                      <div className="mb-1">
                        {/** positions */}
                        {m.positions.map((p, i) => (
                          <div
                            key={`featured-position-${i}`}
                            className="text-1xs text-darkerGray font-semibold">
                            <span>
                              <span>{p.position.name}</span>
                              {p.position.description && (
                                <>
                                  <span> - </span>
                                  <span>{p.position.description}</span>
                                </>
                              )}
                              {m.platform && <span>{` (${m.platform})`}</span>}
                            </span>
                            {m.date_note && (
                              <>
                                <span> | </span>
                                <span>{`(${m.date_note})`}</span>
                              </>
                            )}
                          </div>
                        ))}
                      </div>
                      {/** description */}
                      <p
                        className="text-3xs text-darkerGray tracking-resume leading-[7px]"
                        dangerouslySetInnerHTML={{ __html: m.description }}></p>
                    </div>
                  </div>
                ))}
              </div>
            )}   
        </>  
  );
}
