import React from "react";
import Modal from "components/global/modal";
import CloseIconSVG from "assets/svg/close-icon";
import Button from "components/button/button";
import { AiFillWarning } from "react-icons/ai";

export default function UploadSizeModal({ hide, ...props }) {
  return (
    <Modal
      width="w-19/20 7sm:w-460"
      contentClassName="rounded-lg overflow-auto custom-scroll"
      wrapperClassName="overflow-visible"
      footerClass="border-t border-gray"
    >
      <div className="w-full">
        <div className="p-6">
          {/* close */}
          <div className="w-full flex justify-end">
            <button onClick={hide}>
              <CloseIconSVG />
            </button>
          </div>
          <div className="w-full">
            <div className="flex flex-row items-center">
              <AiFillWarning className="text-3xl text-red md:mr-2" />
              <p className="text-3xl text-red font-bold tracking-tighter">
                Upload Error
              </p>
            </div>
            <div className="w-full text-lg py-6">
              {/* <p>File size should not exceed 30mb</p> */}
              <p>File size should not exceed 50MB</p>
            </div>
            <div className="w-full flex justify-center">
              <Button
                buttonWidth="202px"
                buttonName="Ok"
                buttonTextClass="text-sm text-lightBlack font-semibold leading-4.4"
                buttonRadius="6px"
                buttonClass="bg-thBg py-4"
                onClick={hide}
              />
            </div>
          </div>
        </div>
        {/* end content */}
      </div>
    </Modal>
  );
}
