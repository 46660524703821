import {
  REFRESH_MILESTONES,
  GET_MILESTONES,
  SET_FEATURED_MILESTONE,
  SET_CURRENT_MILESTONES,
  SET_PAST_MILESTONES,
  SET_MILESTONES,
  SET_IS_FEATURED,
  SET_ORGANIZE_BY,
  SET_JOB_ROLE,
  SET_CATEGORY,
  SET_FILTERS,
  CLEAR_FILTERS,
  ADD_MILESTONES,
  UPDATE_MILESTONES,
  UPDATE_FEATURED_MILESTONE,
  UPDATE_CURRENT_MILESTONE,
  UPDATE_PAST_MILESTONE,
  DELETE_MILESTONE,
  FINISH_GET_MILESTONES,
  SET_PINNED_MILESTONES,
  SET_PLAY_MUSIC,
  UPDATE_PINNED_MILESTONES
} from "./types";

export const setRefreshMilestones = () => {
  return {
    type: REFRESH_MILESTONES,
    payload: [],
  };
};

export const getMilestones = () => {
  return {
    type: GET_MILESTONES,
    payload: [],
  };
};

export const setFeaturedMilestone = (params) => {
  return {
    type: SET_FEATURED_MILESTONE,
    payload: params,
  };
};

export const setCurrentMilestones = (params) => {
  return {
    type: SET_CURRENT_MILESTONES,
    payload: params,
  };
};

export const setPastMilestones = (params) => {
  return {
    type: SET_PAST_MILESTONES,
    payload: params,
  };
};

export const setPinMilestones = (params) => {
  return {
    type: SET_PINNED_MILESTONES,
    payload: params,
  };
};

export const updatePinMilestones = (params) => {
  return {
    type: UPDATE_PINNED_MILESTONES,
    payload: params,
  };
};

export const setMilestones = (params) => {
  return {
    type: SET_MILESTONES,
    payload: params,
  };
};

export const setIsFeatured = (params) => {
  return {
    type: SET_IS_FEATURED,
    payload: params,
  };
};

export const setOganizeBy = (params) => {
  return {
    type: SET_ORGANIZE_BY,
    payload: params,
  };
};

export const setJobRole = (params) => {
  return {
    type: SET_JOB_ROLE,
    payload: params,
  };
};

export const setCategory = (params) => {
  return {
    type: SET_CATEGORY,
    payload: params,
  };
};

export const setFilters = (params) => {
  return {
    type: SET_FILTERS,
    payload: params,
  };
};

export const setClearFilters = (params) => {
  return {
    type: CLEAR_FILTERS,
    payload: params,
  };
};

export const addMilestones = (params) => {
  return {
    type: ADD_MILESTONES,
    payload: params,
  };
};

export const updateMilestones = (params) => {
  return {
    type: UPDATE_MILESTONES,
    payload: params,
  };
};

export const updateFeaturedMilestone = (params) => {
  return {
    type: UPDATE_FEATURED_MILESTONE,
    payload: params,
  };
};

export const updateCurrentMilestone = (params) => {
  return {
    type: UPDATE_CURRENT_MILESTONE,
    payload: params,
  };
};

export const updatePastMilestone = (params) => {
  return {
    type: UPDATE_PAST_MILESTONE,
    payload: params,
  };
};

export const deleteMilestone = (params) => {
  return {
    type: DELETE_MILESTONE,
    payload: params,
  };
};

export const finishGetMilestone = (params) => {
  return {
    type: FINISH_GET_MILESTONES,
    payload: params,
  };
};

export const setPLayMusic = (params) => {
  // console.log('action --->', params[0])
  return {
    type: SET_PLAY_MUSIC,
    // payload: params[0],
    payload: params,
  };
};
