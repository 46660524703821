import React from "react";
import Button from "components/button/button";
import Card from "components/card/card";
import { useHistory } from "react-router-dom";
import _ from "lodash";

export default function FAQItem({ id, title, description }) {
  const history = useHistory();
  return (
    <Card>
      <div className="relative h-full flex flex-col justify-between">
        <h3 className="text-2xl font-bold tracking-tighter text-lightBlack leading-7.5">
          {title}
        </h3>
        <div
          className="text-base text-darkerGray tracking-tightest leading-7.5 mt-5"
          dangerouslySetInnerHTML={{
            __html: _.truncate(description, {
              length: 210,
              separator: "...",
            }),
          }}
        />
        <div className="mt-6">
          <Button
            buttonName="Read More"
            buttonTextClass="text-sm text-lightBlack font-semibold"
            buttonRadius="6px"
            buttonClass="relative bg-thBg py-3 px-7"
            onClick={() => history.push(`/faqs/${id}`)}
          />
        </div>
      </div>
    </Card>
  );
}
