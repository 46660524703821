import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import userActions from "redux/modules/user/actions";
import { connect } from "react-redux";
import Layout from "layouts/layout";
import { MdClose } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import ProfileCoverPhoto from "components/profile/cover-photo";
import ProfilePhoto from "components/profile/profile-photo";
import ProfileDetails from "components/profile/profile-details";
import ProfileSocials from "components/profile/profile-socials";
import { TabPanel } from "react-headless-tabs";
import LineVerticalSVG from "assets/svg/line-vertical";
import NoProfileFoundIconSvg from "assets/svg/no-profile-found-icon-svg";
import { useDialogHook, useLocalStorage } from "utils/customhooks";
import SendMessageModal from "components/message/send-message";
import GroupButton from "components/button/group-btn";
import DetailedViewMilestones from "components/profile/milestones/detailed-view";
import OrganizePositionDetailedListView from "components/profile/milestones/organize-position/detailed-view";
import OrganizePositionListView from "components/profile/milestones/organize-position/list-view";
import ListViewMilestones from "components/profile/milestones/list-view";
import ColumnViewMilestones from "components/profile/milestones/column-view";
import { useLocation, useHistory, useParams } from "react-router-dom";
import Button from "components/button/button";
import AddMilestoneModal from "components/profile/milestones/add-milestone-modal";
import EditMilestoneModal from "components/profile/milestones/edit-milestone-modal";
import moment from "moment";
import _ from "lodash";
import { urlSerializer, dateOrder,formatDate } from "utils/utils";
import LoadMore from "components/pagination/load-more";
import WatchMediaModal from "components/profile/milestones/media-button/watch";
import ImageGalleryModal from "components/profile/milestones/media-button/image-gallery";
import ConfirmDeleteModal from "components/profile/milestones/more-option/confirm-delete";
import SwitchProfileModal from "components/account/switch-profile-modal";
import ListFilter from "components/profile/milestones/filters/list-filter";
import DefaultTabs from "components/profile/tabs";
import { useMilestones } from "redux/modules/milestone/hooks";
import { useCategoryFilter } from "redux/modules/category-filter/hooks";
import { usePositionFilter } from "redux/modules/position-filter/hooks";
import PdfViewerModal from "components/profile/milestones/media-button/pdf-viewer";
import { gsap } from "gsap";
import PhotosVideos from "components/profile/photos-videos/photos-videos";
import ManageVideos from "components/profile/photos-videos/manage-videos";
import PersonalInformation from "components/profile/about/personal-information";
import ProfessionalInformation from "components/profile/about/professional-information";
import ResumeList from "components/profile/resume/resume";
import { heroIcon } from "utils/constant";
import FabButton from "components/fab-button/fab-button";
import { FiChevronUp } from "react-icons/fi";
import { BASE_URL } from "configs/api";
import SkeletonLoading from "./Skeleton/skeletonloading";
import ShareMilestoneModal from "components/profile/milestones/share-milestone-modal";
import { Context } from "context/context";
import ReportMilestone from "components/profile/milestones/more-option/report";
import ViewMilestoneDialog from "components/profile/milestones/view-dialog";
import ToolTip from "components/tootltip/tootltip";
import PopInfo from "components/info-popup/pop-info";
import SocialModal from "components/profile/share/socials";
import GroupMembers from "components/profile/about/GroupMembers";
import GroupTeam from "components/profile/about/GroupTeam";
import { DATE_NEWEST_TO_OLDEST, DATE_OLDEST_TO_NEWEST } from "constants/options";
import {
  CoverDefaultPlaceholder,
  ProfileDefaultPlaceholder,
} from "constants/images";
import VerifyIconSVG from "assets/svg/verified-icon";
import LoginSingup from "components/auth-mobile/LoginSignup";

const Profile = ({ userData, setUserData }) => {
  const { http, localstorage } = global.services;
  let { username, profileId } = useParams();
  const { route, setRoute, setFollowing, setFollowers, updateMetaData, metaData } = useContext(Context);
  const history = useHistory();
  const [profile, setProfile] = useState({});
  const [hasProInfo, setHasProInfo] = useState(true);
  const isWithPersonalInfo = useMemo(() => {
    let hasInfo = false;
    // todo: checking
    const personalInfoProps = [
      'bio', 
      'birthdate', 
      'birth_location', 
      'civil_status', 
      'children', 
      'height',
      'hair_color'
    ];

    for(const item of personalInfoProps) {
      if(!!profile[item]) hasInfo = true;
    }

    return hasInfo; 
  } ,[profile]);

  const [permission, setPermission] = useState(null);
  const shareModal = useDialogHook(SocialModal);
  // const [reportDetails, setReportDetails] = useState({
  //   milestone: {},
  //   open: false,
  // });
  const [selectedTab, setSelectedTab] = useLocalStorage(
    "profile-tab",
    "milestones",
    "photos-video",
    "resume"
  );
  const containerStickyRef = useRef();
  const [isSticky,setIsSticky] = useState(false);
  const [options, setOptions] = useState({
    positions: [],
    categories: [],
  });
  const [view, setView] = useState("detailed");
  const [filterRoles, setFilterRoles] = useState([]);
  const sendModal = useDialogHook(SendMessageModal);
  const modalAdd = useDialogHook(AddMilestoneModal);
  const modalEdit = useDialogHook(EditMilestoneModal);
  const watchMediaModal = useDialogHook(WatchMediaModal);
  const imageGalleryModal = useDialogHook(ImageGalleryModal);
  const pdfViewerModal = useDialogHook(PdfViewerModal);
  const modalDelete = useDialogHook(ConfirmDeleteModal);
  const switchProfileModal = useDialogHook(SwitchProfileModal);
  //
  const modalShare = useDialogHook(ShareMilestoneModal);
  const [_status, setStatus] = useState("");
  //
  const [scrollToTop, setScrollToTop] = useState(0);
  const modalReport = useDialogHook(ReportMilestone);
  const modalViewMilestone = useDialogHook(ViewMilestoneDialog);
  const [showNoProfileFound, setShowNoProfileFound] = useState(false);
  const [showSearch, setShowSearch] = useState(false);


  const contentRef = useRef();
  const profileRef = useRef();
  const query = useQuery();
  const {
    featured,
    organizeBy,
    jobRole,
    category,
    isRequesting,
    milestones,
    featuredMilestone,
    currentMilestones,
    pastMilestones,
    pinnedMilestones,
    total: totalMilestones,
    limit: milestonesLimit,
    loadMore: loadMoreMilestones,
    refresh: refreshMilestones,
    initialize: initializeMilestones,
    setRefreshMilestones,
    getMilestones,
    finishGetMilestone,
    setFeaturedMilestone,
    setCurrentMilestones,
    setPastMilestones,
    setMilestones,
    setIsFeatured,
    setOganizeBy,
    setJobRole,
    setCategory,
    setFilters,
    setClearFilters,
    addMilestones,
    updateMilestones,
    updateCurrentMilestone,
    updatePastMilestone,
    updateFeaturedMilestone,
    deleteMilestone,
    setPinMilestones,
    updatePinMilestones,
  } = useMilestones();
  const { setCategoryFilter } = useCategoryFilter();
  const { setPositionFilter } = usePositionFilter();

  const positionResolver = useRef(null);
  const positionPromise = useRef();

  if(!positionPromise.current) {
    positionPromise.current = new Promise(resolve => {
      positionResolver.current = resolve;
      // console.log("Promise created, resolver set:", positionResolver.current);
    });
  }
  const [proInfo, setProInfo] = useState(false); 


  const isYourProfile = userData?.profile?.id === profile?.id;
  
  const isWithProInfo = useMemo(() => proInfo, [proInfo]);
  const fetchProInfo = async () => {
    if (isYourProfile) {
        setProInfo(null);
        return;
    }
    try {
        const { data } = await http.get(`public/professional-informations`, {
            user_profile_id: profile.id,
        });

        const hasValue = Object.entries(data).some(([key, value]) => {
          // Exclude these keys
          const excludedKeys = ['id', 'user_profile_id', 'created_at', 'updated_at'];
          return !excludedKeys.includes(key) && value !== null;
          });
          setProInfo(hasValue)
          if(profile?.occupations.length > 0){
            setProInfo(true);
          } 
    } catch (error) {
        console.error('Error fetching professional information:', error);
        setProInfo(false); // Handle errors gracefully
    }
};
  /**
   * This function excecutes when
   * the user click the fab button
   */
  const scrollTop = () => {
    contentRef.current.scrollTo(0, 0);
  };
  const handleSendMessage = () => {
    sendModal(
      {
        userData,
        profile,
      },
      (result) => {
        console.log("result =>", result);
      }
    );
  };
  const addMilestone = () => {
    modalAdd(
      {
        userData,
        profile,
      },
      (callback) => {
        if (callback && callback.success) {
          addMilestones(callback.data);
          getPositions();
          getCategory();
        } else if (callback === "upgrade") {
          history.push("/plans");
        }
      }
    );
  };
  const switchProfile = () => {
    // uncomment below to show  switchProfileModalw
    // switchProfileModal({ userData }, (result) => {
    //   console.log("result =>", result);
    // });
  };
  const handleWatch = (title, url, tiktok = false) => {
    watchMediaModal({ title, url, tiktok }, (result) => {
      console.log("result =>", result);
    });
  };
  const handleImageGallery = (title, button, media) => {
    imageGalleryModal({ title, button, media }, (result) => {
      console.log("result =>", result);
    });
  };
  const handleViewPdf = (title, url) => {
    pdfViewerModal({ title, url }, (result) => {
      console.log("result =>", result);
    });
  };
  const editMilestone = (milestone, section) => {
    modalEdit({ userData, profile, milestone }, (callback) => {
      //
      history.push(`${history.location.pathname}`);
      //
      if (callback && callback.success) {
        if (organizeBy === "Date" || _.isEmpty(organizeBy)) {
          // feature
          if (featured) updateFeaturedMilestone(callback);

          if (section === "current") {
            updateCurrentMilestone(callback);
          } else if (section === "past") {
            updatePastMilestone(callback);
          } else if (section === "pin"){
            const changes = Array.isArray(callback.data) ? callback.data : [callback.data];
            updatePinMilestones({ data: changes });
          }
          else {
            updateCurrentMilestone(callback);
            updatePastMilestone(callback);
          }
        } else {
          updateMilestones(callback);
        }
        getPositions();
      } else if (callback === "upgrade") {
        history.push("/plans");
      }
    });
  };
  const removeMilestone = (milestone) => {
    modalDelete({ userData, milestone }, (callback) => {
      //
      history.push(`${history.location.pathname}`);
      //
      if (callback) {
        deleteMilestone(milestone);
        getPositions();
      }
    });
  };

  const reportMilestone = (milestone = {}, accountReport = {}, type) => {
    modalReport({ userData, milestone, accountReport, type }, (callback) => {
      console.log(callback);
      if (callback && callback.success) {
        if (type === "profile") {
          setProfile(callback.data);
        }
        console.log(profile);
      }
    });
  };

  const checkDomain = () => {
    const currentDomain = window.location.hostname;
    // localhost
    if (BASE_URL === currentDomain) {
      return false;
    } else {
      return true;
    }
  };
  /**
   * This method will fetch
   * position filter options
   */
  const getPositions = async () => {
    try {
      const url = urlSerializer("public/positions/filter", {
        user_profile_id: profile.id,
      });
      const { data } = await http.get(url);
      const positions = data.map((position) => ({
        label: position.name,
        value: position.id,
        count: position.milestone_position_count,
      }));
      setOptions((prev) => ({
        ...prev,
        positions,
      }));

      if (
        userData &&
        userData?.profile &&
        profile &&
        userData?.profile.id === profile.id
      ) {
        setPositionFilter(positions);
      }

      // console.log('positionResolver.current: ', positionResolver.current);
      // console.log('positionResolver.current type: ', typeof positionResolver.current);

      if(typeof positionResolver.current === 'function') {
        positionResolver.current(positions);
        // console.log("executed");
      }
    } catch (err) {
      console.log("error@getPositions", err);
      throw err;
    }
  };
  /**
   * This method will fetch
   * category filter options
   */
  const getCategory = async () => {
    try {
      const url = urlSerializer("public/categories/filter", {
        user_profile_id: profile.id,
      });
      const { data } = await http.get(url);
      const categories = data.map((category) => ({
        label: category.name,
        value: category.id,
      }));
      setOptions((prev) => ({
        ...prev,
        categories,
      }));

      if (
        userData &&
        userData?.profile &&
        profile &&
        userData?.profile.id === profile.id
      ) {
        setCategoryFilter(categories);
      }
    } catch (err) {
      console.log("getPosition", err);
    }
  };
  /**
   * This method will fetch
   * milestone list view data
   */
  const fetchMilestones = async () => {
    let privacy = ["public"];
    let current = [];
    let past = [];
    let pin = [];
    let loadMore = false;

    if (isRequesting || !loadMoreMilestones) return;

    await getMilestones();

    if (userData) {
      if (
        profile.user &&
        userData?.user &&
        userData?.user.id === profile.user.id
      ) {
        privacy.push("private");
        privacy.push("verified");
        privacy.push("unlisted");
      } else {
        if (
          userData?.account_verified &&
          !_.isEmpty(userData?.account_verified)
        ) {
          privacy.push("verified");
        } else if (!_.isEmpty(permission)) {
          privacy.push("unlisted");
        }
      }
    }
    try {
      const roles = jobRole.map((p) => p.label);
      const { data } = await http.get("public/milestones/list-view", {
        featured,
        organize_by: organizeBy,
        category: category,
        skip: totalMilestones,
        job_role: roles,
        user_profile_id: profile.id,
        limit: milestonesLimit,
        privacy,
      }); 
      loadMore = data.total > 0 ? true : false;
      const _total = parseInt(totalMilestones) + data.total;
      pin = data.pinned;
      setPinMilestones({ data: pin, total: _total })
      if (organizeBy === "Date" || _.isEmpty(organizeBy)) {
        data.data.forEach((m) => {
          let currentYear = moment().year();
          let year = moment(m.start_date).year();

          if (year >= currentYear) {
            current.push(m);
          } else {
            past.push(m);
          }
        }); 
        current = [...current].sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
        past = [...past].sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
        // if(organizeBy === "Date"){ 
        //     current.sort((a, b) => a.start_date - b.start_date);
        //     past.sort((a, b) => {
        //       if (a.end_year === null) return 1;
        //       if (b.end_year === null) return -1;
        //       return b.end_year - a.end_year;
        //     });
        // }
        setCurrentMilestones({ data: current, total: _total });
        setPastMilestones({ data: past, total: _total });
        // if(organizeBy === "Date"){
        //   dateOrder(current, past);
        // }
      }else if(organizeBy === DATE_NEWEST_TO_OLDEST){
        data.data.forEach((m) => {
          current.push(m);
        });
        current.sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
        setCurrentMilestones({ data: current, total: _total });
      }else if(organizeBy === DATE_OLDEST_TO_NEWEST){
        data.data.forEach((m) => {
          current.push(m);
        });
        current.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
        setCurrentMilestones({ data: current, total: _total });
      } else {
        setMilestones({ data: data.data, total: _total });
      }
    } catch (err) {
      console.log("error@fetchMilestones", err);
    } finally {
      await finishGetMilestone({
        loadMore: loadMore,
      });
    }
  };

  const viewReportMilestone = async () => {
    console.log("=============START===================");
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    if (code) {
      try {
        const { data } = await http.get(`public/viewReports/${code}`);
        // setReportDetails({ milestone: data, open: true });
        onView(data);
      } catch (e) {
        // setReportDetails({ milestone: {}, open: false });
        console.error(e);
      }
    }
    console.log("=============END===================");
  };

  const onView = (milestone) => {
    modalViewMilestone(
      {
        data: milestone,
        onWatchMedia: handleWatch,
        onViewImage: handleImageGallery,
        onEdit: editMilestone,
        onDelete: removeMilestone,
        isYourProfile: isYourProfile,
        profile: userData?.profile,
      },
      (cb) => {
        // callback
      }
    );
  };

  /**
   * This method will clear the list view filters
   */
  const clearFilters = async() => {
    localstorage.remove("filter.jobRole");
    localstorage.remove("filter.sort");
    localstorage.remove("filter.category");
    localstorage.remove("filter.featured"); 
    setClearFilters();
    let user_data = JSON.parse(localStorage.getItem("user_data")); 
    user_data.profile = {...user_data?.profile, milestone_filter:null}
    localstorage.set("user_data", JSON.stringify(user_data));
    console.log(user_data)
    try {
      http.post(
        `public/profiles/${userData?.profile.id}/clear-filter`
      ); 
    }catch(e){
      console.error(e);
    }
  };
  /**
   * This method will fetch
   * featured milestone
   */
  const getFeaturedMilestone = async () => {
    try {
      const { data } = await http.get("public/milestones/featured", {
        organize_by: organizeBy,
        job_role: jobRole,
        category: category,
        user_profile_id: profile.id,
      });

      if (!_.isEmpty(data)) {
        setFeaturedMilestone(data);
      } else {
        setFeaturedMilestone({});
      }
    } catch (err) {
      console.log("error@getFeaturedMilestone", err);
      throw err;
    }
  };
  /**
   * This method will fetch
   * featured milestone
   */
  const getUserProfile = async (uname) => {
    try {
      const url = urlSerializer("public/user-profiles/get-profile", {
        username: uname,
        id: profileId,
      });
      const { data } = await http.get(url);
      setProfile(data);
      setMilestoneFilters(data);
    } catch (err) {
      if (err.status === 401 && err?.data?.message === "Unauthorize for adult content" && userData == null) {
        setShowNoProfileFound(true);
      }

      console.log("::error@getUserProfile", err);console.log("error@getUserProfile", err);
    }
  };
  const setMilestoneFilters = async (profile) => {
    const filterJobRole = localstorage.get("filter.jobRole");
    const filterSort = localstorage.get("filter.sort");
    const filterCategory = localstorage.get("filter.category");
    const filterFeatured = localstorage.get("filter.featured");
    console.log('profile: ', profile);
    let milestone_filter = profile.milestone_filter;
    let filter = {};
    if (
      userData &&
      userData?.profile &&
      !_.isEmpty(profile) &&
      userData?.profile.id === profile.id
    ) {
      console.log('filterJobRole: ', filterJobRole);
        if(filterJobRole.success) {
          
          console.log(filterJobRole.value);
          if (
            filterJobRole.value.includes("[") &&
            filterJobRole.value.includes("]")
          ) {
            const roles = JSON.parse(filterJobRole.value).map((d) => d.label);
            setFilterRoles(roles);
            filter.job_role = JSON.parse(filterJobRole.value);
          } else {
            filter.job_role = filterJobRole.value ? [filterJobRole.value] : [];
          }
          
       } else {
    
        if (milestone_filter) {
            if (milestone_filter.job_role) {
              if(typeof milestone_filter.job_role === 'string') {
          
                filter.job_role = options.positions.filter(item => item.label === milestone_filter.job_role);
                /**
                 * sometimes options.position will be an empty array.
                 * so the code below will wait for the getPositions function to finish
                 * and get the return value of it and use that as the new option to be used
                 * in the array.filter function.
                 */
                if(options.positions.length === 0) {
                 
                  if(positionPromise.current instanceof Promise) {
                    const _options = await positionPromise.current;
                    filter.job_role = _options.filter(item => item.label === milestone_filter.job_role);
                  }
                }
              } 
      
              if(Array.isArray(milestone_filter.job_role)) {
                filter.job_role = milestone_filter.job_role;
              }
      
              console.log('filter.job_role: ', filter.job_role);
            }
          
            if (milestone_filter.category)
              filter.category = milestone_filter.category;
            if (milestone_filter.show_featured)
              filter.featured = milestone_filter.show_featured;
          }
          console.log(' filter.job_role: ',  filter.job_role);
          console.log('milestone_filter: ',  milestone_filter);
      }

      if(filterSort.success) {
        filter.sort = filterSort.value;
      } else {
        if(milestone_filter) {
          if (milestone_filter.sort) filter.sort = milestone_filter.sort;
        }
      }
  
      if(filterCategory.success) {
        filter.category = filterCategory.value;
      } else {
        if(milestone_filter) {
          if (milestone_filter.category)
            filter.category = milestone_filter.category;
        }
      }
  
      if(filterFeatured.success) {
        filter.featured = JSON.parse(filterFeatured.value);
      } else {
        if(milestone_filter) {
          if (milestone_filter.show_featured)
            filter.featured = milestone_filter.show_featured;
        }
      }
    }

    setFilters({
      featured: filter?.featured || false,
      jobRole: filter?.job_role || [],
      organizeBy: filter?.sort || null,
      category: filter?.category || null,
    });
    setTimeout(() => {
      setRefreshMilestones();
    }, 300);
  };
  /**
   * This method will fetch
   * featured milestone
   */
  const getUserProfilePermission = async (uname) => {
    try {
      const url = urlSerializer(`public/user-accesses/permission`, {
        username: uname,
        user_id: userData?.user.id,
      });
      const { data } = await http.get(url);
      if (!_.isEmpty(data)) {
        setPermission(data);
      }
    } catch (err) {
      console.log("error@getUserProfilePermission", err);
    }
  };
  const onChangeJobRole = (e) => {
    if (
      userData &&
      userData?.profile &&
      !_.isEmpty(profile) &&
      profile.id === userData?.profile.id
    ) {
      if (e.length > 0) {
        localstorage.set("filter.jobRole", JSON.stringify(e));
      } else {
        localstorage.remove("filter.jobRole");
      }
    }
    setJobRole(e);
  };

  const onChangeCategory = (e) => {
    if (
      userData &&
      userData?.profile &&
      !_.isEmpty(profile) &&
      profile.id === userData?.profile.id
    ) {
      if (!e.label) {
        localstorage.remove("filter.category");
      } else {
        localstorage.set("filter.category", e.label);
      }
    }
    setCategory(e.label);
  };

  const onChangeOrganizeBy = (e) => {
    if (
      userData &&
      userData?.profile &&
      !_.isEmpty(profile) &&
      profile.id === userData?.profile.id
    ) {
      if (!e.label) {
        localstorage.remove("filter.sort");
      } else {
        localstorage.set("filter.sort", e.label);
      }
    }
    setOganizeBy(e.label);
  };

  const onChangeFeaturedOnly = (e) => {
    if (
      userData &&
      userData?.profile &&
      !_.isEmpty(profile) &&
      profile.id === userData?.profile.id
    ) {
      localstorage.set("filter.featured", JSON.stringify(e.target.checked));
    }
    setIsFeatured(e.target.checked);
  };
  /* Add views on the user's profile if they are not the owner */
  const addProfileView = async () => {
    setRoute({ ...route, previousProfileVisit: route.to });

    if (route.to !== route.from && route.previousProfileVisit !== route.to) {
      if (userData?.profile?.id !== profile?.id) {
        try {
          await http.post("public/profile-views", {
            user_profile_id: profile.id,
            viewer_profile_id: userData?.profile.id,
          });
        } catch (err) {
          console.log("error@addViewsToProfile", err);
          throw err;
        }
      }
    } else {
      console.log("Not adding profile view: Refreshed on the same profile");
    }
  };

  const getFollowing = async (type = "following") => {
    try {
      const { data } = await http.get("public/user-followers", {
        user_profile_id: userData?.profile?.id,
        type: type,
      });
      if (!_.isEmpty(data?.data)) {
        if (type === "following") {
          setFollowing(data?.data);
        } else if (type === "followers") {
          setFollowers(data?.data);
        }
      }
    } catch (err) {
      console.log("error@getFollowing", err);
    }
  };

  /**
   * This method will render detailed list view
   */
  const renderDetailedListView = () => {
    if (organizeBy === "Job/Role") {
      return (
        <OrganizePositionDetailedListView
          showFeatured={featured}
          featured={featuredMilestone}
          milestones={milestones}
          pin={pinnedMilestones}
          onWatchMedia={handleWatch}
          onViewImage={handleImageGallery}
          handleViewPdf={handleViewPdf}
          onEdit={editMilestone}
          onDelete={removeMilestone}
          onReport={reportMilestone}
          isYourProfile={isYourProfile}
          profile={userData?.profile}
          permission={permission}
        />
      );
    }
    return (
      <DetailedViewMilestones
        showFeatured={featured}
        featured={featuredMilestone}
        current={currentMilestones}
        past={pastMilestones}
        pin={pinnedMilestones}
        onWatchMedia={handleWatch}
        onViewImage={handleImageGallery}
        handleViewPdf={handleViewPdf}
        onEdit={editMilestone}
        onDelete={removeMilestone}
        onReport={reportMilestone}
        isYourProfile={isYourProfile}
        profile={userData?.profile}
        permission={permission}
        onShare={shareMilestone}
      />
    );
  };
  /**
   * This method will render list view
   */
  const renderListView = () => {
    if (organizeBy === "Job/Role") {
      return (
        <OrganizePositionListView
          showFeatured={featured}
          featured={featuredMilestone}
          milestones={milestones}
          pin={pinnedMilestones}
          onWatchMedia={handleWatch}
          onViewImage={handleImageGallery}
          handleViewPdf={handleViewPdf}
          onEdit={editMilestone}
          onDelete={removeMilestone}
          onReport={reportMilestone}
          isYourProfile={isYourProfile}
          profile={userData?.profile}
          permission={permission}
        />
      );
    }
    return (
      <ListViewMilestones
        showFeatured={featured}
        featured={featuredMilestone}
        current={currentMilestones}
        past={pastMilestones}
        pin={pinnedMilestones}
        onWatchMedia={handleWatch}
        onViewImage={handleImageGallery}
        handleViewPdf={handleViewPdf}
        onEdit={editMilestone}
        onDelete={removeMilestone}
        onReport={reportMilestone}
        isYourProfile={isYourProfile}
        permission={permission}
        onShare={shareMilestone}
        profile={userData?.profile}
      />
    );
  };
  /**
   * This method will render column view
   */
  const renderColumnView = () => {
    return (
      <ColumnViewMilestones
        selectedTab={selectedTab}
        organizeBy={organizeBy}
        showFeatured={featured}
        featured={featuredMilestone}
        current={currentMilestones}
        past={pastMilestones}
        pin={pinnedMilestones}
        milestones={milestones}
        onWatchMedia={handleWatch}
        onViewImage={handleImageGallery}
        handleViewPdf={handleViewPdf}
        onEdit={editMilestone}
        onDelete={removeMilestone}
        onReport={reportMilestone}
        isYourProfile={isYourProfile}
        permission={permission}
        options={options}
        setSelectedTab={setSelectedTab}
        onChangeOrganizeBy={onChangeOrganizeBy}
        onChangeFeaturedOnly={onChangeFeaturedOnly}
        filterRoles={filterRoles}
        userData={userData}
        profile={userData?.profile}
        onShare={shareMilestone}
      />
    );
  };

  const shareMilestone = (milestone, status) => {
    
    console.log(
      `${process.env.REACT_APP_PUBLIC_DOMAIN}${history.location.pathname}?milestone=${milestone.hash_id}`
    );
    const featuredDate = formatDate(featured, profile?.dateFormat);
    updateMetaData({
      id: milestone?.id,
      name: '',
      description: `${featuredDate} ${milestone?.date_note}`,
      imageUrl: milestone.thumbnail?.url,
      url: `${process.env.REACT_APP_PUBLIC_DOMAIN}/${history.location.pathname}?milestone=${milestone.hash_id}`,
      title:milestone.title
    });
    console.log(metaData)
    setStatus(status);
    shareModal(
      {
        url: `${process.env.REACT_APP_PUBLIC_DOMAIN}${history.location.pathname}?milestone=${milestone.hash_id}`,
        image: profile.profile_image_url,
      },
      (callBack) => {
        console.log("showMoreSocial callback", callBack);
      }
    );
    // history.push(`${history.location.pathname}?milestone=${milestone.hash_id}`);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setView('list'); // Set Tab 2 for mobile
      } else {
        setView('detailed'); // Set Tab 1 for web
      }
    };

    // Set initial tab based on current size
    handleResize();

    // Add resize event listener
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (refreshMilestones) {
      setRefreshMilestones();
    } else if (initializeMilestones) {
      if (!_.isEmpty(profile)) {
        if (featured) getFeaturedMilestone();
        fetchMilestones();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initializeMilestones, refreshMilestones]);

  useEffect(() => {
    if (!_.isEmpty(profile)) {
      viewReportMilestone();
      getCategory();
      getPositions();
      addProfileView();
      getFollowing("following");
      getFollowing("followers");
      fetchProInfo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);
  

  useEffect(() => {
    if (username && profileId) {
      const uname = username.split("-")[0];

      if (userData) {
        if (userData?.user.username !== uname) getUserProfilePermission(uname);
        else setPermission({ status: "owner" });
      }

      getUserProfile(uname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username, profileId]);

  useEffect(() => {
    if (query?.switchProfile) {
      switchProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    let values = jobRole.map((jr) => jr.label);
    setFilterRoles(values);
  }, [jobRole]); 
 

  useEffect(() => {
    let profileCtx = gsap.context(() => {
      gsap
        .fromTo(
          ".profile-first-row",
          { y: -20, opacity: 0 },
          { y: 0, opacity: 1 }
        )
        .duration(0.5);

      gsap
        .fromTo(
          ".profile-second-row",
          { opacity: 0 },
          { opacity: 1, duration: 1.5 }
        )
        .duration(0.5);

      gsap
        .fromTo(".profile-about", { y: 30, opacity: 0 }, { y: 0, opacity: 1 })
        .duration(0.5);

      gsap
        .fromTo(
          ".profile-milestones",
          { y: 30, opacity: 0 },
          { y: 0, opacity: 1 }
        )
        .duration(0.5);
    }, profileRef);

    const container = containerStickyRef.current;
    container.addEventListener('scroll', handleScroll);

    return () => {
      profileCtx.revert();
      container.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const milestone = new URLSearchParams(window.location.search).get(
    "milestone"
  );
  const share_link = ()=>{
    history.push('/login'); 
  }
  useEffect(() => {
    if (milestone) {
      let share = true;
      const hash_id = milestone;
      modalShare(
        {
          milestone,
          featured,
          userData,
          username,
          profileId,
          handleWatch,
          handleImageGallery,
          handleViewPdf,
          editMilestone,
          removeMilestone,
          _status,
          share,
          hash_id,
          profile,
          share_link
        },
        () => history.push(`${history.location.pathname}`)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [milestone]);
  //
  /** This method handle on scroll event */
  const handleScroll = () => {
    if (contentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
      const middleThreshold = scrollHeight / 3;
      const topClientHeight = scrollTop + clientHeight;

      if (topClientHeight >= middleThreshold) {
        if (!_.isEmpty(profile)) {
          fetchMilestones();
        }
      }

      setScrollToTop(scrollTop);
    } 
    if (containerStickyRef.current) {
      const offsetTop = containerStickyRef.current.getBoundingClientRect().top; 
      setIsSticky(offsetTop <= 260);
    }
  };

  // ============= use memo =================
  const showFilters = useMemo(() => {
    let emptyRoles = !_.isEmpty(filterRoles);
    let emptyOrganizeBy = !_.isEmpty(organizeBy);
    let emptyCateogory = !_.isEmpty(category);
    if (featured || emptyRoles || emptyOrganizeBy || emptyCateogory) {
      return true;
    } else {
      return false;
    }
  }, [featured, filterRoles, organizeBy, category]);

  // ============== milestone permision ===========
  const profilePermission = useMemo(() => {
    let obj = { hasMilestone: false, hasSocialMedia: false };
    if (!_.isEmpty(permission)) {
      const allowPermission = ["Super Admin", "Admin", "Staff"];
      const socialPermission = ["Super Admin", "Admin"];
      let isOwner = permission.status === "owner";
      let isAllowed = _.includes(allowPermission, permission?.permission?.name);
      let socialAllowed = _.includes(
        socialPermission,
        permission?.permission?.name
      );
      if (isOwner || isAllowed) {
        obj.hasMilestone = true;
      } else {
        obj.hasMilestone = false;
      }

      if (isOwner || socialAllowed) {
        obj.hasSocialMedia = true;
      } else {
        obj.hasSocialMedia = false;
      }
    } else {
      obj.hasMilestone = false;
      obj.hasSocialMedia = false;
    }

    return obj;
  }, [permission]);

  // const isOwnerProfile = useMemo(() => {
  //   if (userData && userData?.profile) {
  //     if (profile && userData?.profile.id === profile.id) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return false;
  //   }
  // }, [userData, profile]);

  const filterData = useMemo(() => {
    let result = "";
    let isEptyFilterRoles = !_.isEmpty(filterRoles);
    let isEmptyCategory = !_.isEmpty(category);
    let isEmptyOrganizeBy = !_.isEmpty(organizeBy);
    if (featured) {
      if (isEptyFilterRoles || isEmptyCategory || isEmptyOrganizeBy) {
        result += ", ";
      }
    }
    if (isEptyFilterRoles) {
      result += filterRoles.join(", ");
    }
    if (isEptyFilterRoles) {
      if (isEmptyCategory || isEmptyOrganizeBy) {
        result += ", ";
      }
    }
    if (isEmptyCategory) {
      result += category;
    }
    if (isEmptyCategory) {
      if (isEmptyOrganizeBy) {
        result += ", ";
      }
    }
    if (isEmptyOrganizeBy) {
      result += `Organize By ${organizeBy}`;
    }
    return result;
  }, [featured, filterRoles, category, organizeBy]);


  const showPersonalInformation = () => {
    if(isYourProfile) {
      return true;
    }

    /**
     * if user is not the current
     * then check if there is any personal info
     */
    if(isWithPersonalInfo) {
      return true;
    }

    return false;
  }

  const showProInformation = (hasInfo) => {
    if(isYourProfile) {
      return true;
    }

    /**
     * if user is not the current
     * then check if there is any prof info
     */
    if(hasInfo) {
      return true;
    }

    return false;
  }

  return (
    showNoProfileFound
      ? <Layout
      title="Profile"
      contentRef={contentRef}
      handleScroll={handleScroll}
      setUserData={setUserData}
      containerStyle="lg:static lg:overflow-y-auto overflow-x-hidden"
      isYourProfile={isYourProfile}>
        {/* !!! */}
          <div ref={profileRef} className="profile-page-wrapper no-profile-found flex items-center justify-center">
            {/* No Profile Found Page -> Displayed or unauthenticated users accessing a profile with adult content. */}
            <div className="bg-white rounded-md w-11/12 h-5/6 xl:w-8/12:h-4/5 flex flex-col items-center justify-center">
            {/* <div className="bg-white rounded-md w-8/12 h-4/5 flex flex-col items-center justify-center"> */}
              <NoProfileFoundIconSvg />
              <p className="mt-6 font-semibold text-2xl">Profile cannot be found</p>
              <p className="mt-2 font-semibold text-base">This profile is not publicly available</p>
              <Button
                buttonName="Back to my profile"
                buttonClass="bg-thBg py-4 px-8 hover:bg-gray mt-6"
                buttonTextClass="text-black text-sm font-semibold"
                // onClick={() => hide()}
                path="/login"
                link={true}
              />
            </div>
          </div>
      </Layout>
      : <Layout
        title="Profile"
        contentRef={contentRef}
        handleScroll={handleScroll}
        setUserData={setUserData}
        containerStyle="lg:static lg:overflow-y-auto overflow-x-hidden"
        isYourProfile={isYourProfile}>  
        {/* dialog Report*/}
        {/* {reportViewDialog()} */}
        {/* overflow-y-auto scroll-smooth custom-scroll */}
        <div ref={profileRef} className="profile-page-wrapper pt-4 3md:pt-8.5  ">
        {
          isSticky &&
          <div className={`bg-white w-full fixed top-0 navbar ${isSticky ? 'fixed' : ''} flex z-50 py-3 profile-page-wrapper-padding justify-between shadow-md cursor-pointer gap-2`}>
            {
              !showSearch && 
              <>
                <div className="flex items-center gap-2">
                  <div className="relative rounded-md">
                    <ProfilePhoto
                      className="w-10 2sm:w-12"
                      image={
                        profile?.profile_image_webp_url ||
                        profile?.profile_image_url ||
                        ProfileDefaultPlaceholder
                      }
                      source = {profile?.profile_image_url || ProfileDefaultPlaceholder}
                    />
                  </div>
                    <p>{isYourProfile ? (profile?.profile_name || userData?.user?.username) : (profile?.profile_name || profile?.user?.username)}
                    {profile?.id3_verified && (
                        <span className="inline-flex items-center align-middle"><VerifyIconSVG className="w-5 h-5 3md:w-7 3md:h-7 mx-2 3md:ml-2.5" /></span>
                      )}
                    </p>
                </div>
                <ProfileSocials
                  onReport={reportMilestone}
                  userData={userData}
                  profile={profile}
                  isYourProfile={isYourProfile}
                  hasPermission={profilePermission.hasSocialMedia}
                  onClickMessage={handleSendMessage}
                  showSocialMedia={false}
                  isSticky={isSticky}
                />
              </>
            }
            {
              _.isEmpty(userData) &&
              <div className="transition-all duration-300 delay-250 flex items-center block 2md:hidden">
                  <LoginSingup showSearchIcon={true}
                    variant="black" 
                    textSignup="Join" 
                    textClass="text-black" 
                    buttonClass="flex items-center my-1 py-1.5"
                    searchBtn = {(value)=>{setShowSearch(value)}}
                    />
              </div>
            }
          </div>
        }
          {/* 1st row */}
          <div className="profile-first-row w-full profile-page-wrapper-padding">
            <div className="w-full bg-white rounded-md container-max-width">
              {/* cover photo */}
              {Object.keys(profile).length === 0 && (
                <SkeletonLoading classStyle="skeleton-item w-full h-400" />
              )}
              {Object.keys(profile).length !== 0 && (
                <ProfileCoverPhoto
                  image={
                    profile?.cover_image_webp_url ||
                    profile.cover_image_url ||
                    CoverDefaultPlaceholder
                  }
                />
              )}
              {/* profile details */}
              <div className="w-full relative px-5 2sm:px-10 3md:px-24.5 pb-4 pt-10 3md:pb-10">
                {/* profile picture */}
                {Object.keys(profile).length === 0 && (
                  <SkeletonLoading classStyle="skeleton-item w-40 h-40" />
                )}
                {Object.keys(profile).length !== 0 && (
                  <ProfilePhoto
                    image={
                      profile?.profile_image_webp_url ||
                      profile?.profile_image_url ||
                      ProfileDefaultPlaceholder
                    }
                    source = {profile?.profile_image_url || ProfileDefaultPlaceholder}
                  />
                )}
                {/* settings  */}
                <div className="w-full">
                  <div className="w-full flex justify-center 2sm:justify-end mt-8 2sm:mt-0">
                    {profile?.hero?.link ? (
                      <ToolTip
                        value={`${
                          profile?.hero?.heroCTA || "Show your support on"
                        } ${profile?.hero?.socialMedia}`}
                        backgroundColor="#0c6ed1"
                        color="white">
                        <div className="hidden 5sm:block">
                          <button
                          className="text-xs font-semibold text-lightBlack flex items-center
                          3md:mb-0 3md:py-4 px-3 bg-thBg hover:bg-gray gap-1 rounded-md group-hover:opacity-100 max-w-[14rem] justify-between"
                          onClick={() =>
                            window.open(profile?.hero.link, "_blank")
                          }>
                            <p className="mt-0.5">
                              {profile?.hero?.heroCTA || "Show your support on"}
                            </p>
                            {heroIcon(profile?.hero?.socialMedia,profile?.hero.link)}
                          </button>
                        </div>
                      </ToolTip>
                    ) : (
                      <div
                        className=" w-full flex justify-end hidden 5sm:block"
                        style={{ height: 45 }}>
                        &nbsp;
                      </div>
                    )}
                  </div>
                </div>
                {/* content details */}
                <div className="w-full 3md:flex 3md:justify-between 3md:items-center">
                  {/* 1st col */} 
                  <ProfileDetails data={profile} isYourProfile={isYourProfile} />
                  {/* 2nd col */}
                  {profile?.hero?.link && (
                  <div className="block 5sm:hidden">
                    
                      <button
                      className="w-full text-xs font-semibold text-lightBlack flex items-center
                      3md:mb-0 3md:py-4 px-3 bg-thBg hover:bg-gray gap-1 rounded-md group-hover:opacity-100  justify-center my-2 hero-button"
                      onClick={() =>
                        window.open(profile?.hero.link, "_blank")
                      }>
                        <p className="mt-0.5">
                          {profile?.hero?.heroCTA || "Show your support on"}
                        </p>
                        {heroIcon(profile?.hero?.socialMedia,profile?.hero.link)}
                      </button>
                    </div>
                    )}
                  {/* 3nd col */}
                  <ProfileSocials
                    onReport={reportMilestone}
                    userData={userData}
                    profile={profile}
                    isYourProfile={isYourProfile}
                    hasPermission={profilePermission.hasSocialMedia}
                    onClickMessage={handleSendMessage}
                  />
                </div>
                {/* end: content details */}
              </div>
            </div>
          </div>
          {/* 2nd row */}
          <DefaultTabs
            {...{
              profile,
              userData,
              selectedTab,
              setSelectedTab,
              checkDomain,
              isYourProfile,
            }}
            emptyAbout = {!isYourProfile && isWithProInfo === false && isWithPersonalInfo === false}
          />
          {/* 3rd row */}
          <div className="w-full mt-5" ref={containerStickyRef}>
            <TabPanel className="profile-about" hidden={selectedTab !== "about"}>
              <div className="w-full profile-page-wrapper-padding">
                {profile?.profile_type?.type === "group" ||
                profile?.profile_type?.type === "team" ? (
                  <>
                    {profile?.id && (
                      <div className="w-full mt-6">
                        <GroupMembers
                          userData={userData}
                          profile={profile}
                          setUserData={setUserData}
                          isYourProfile={isYourProfile}
                        />
                      </div>
                    )}
                    {profile?.id && (
                      <div className="w-full mt-6 container-max-width">
                        <GroupTeam
                          userData={userData}
                          profile={profile}
                          setUserData={setUserData}
                          isYourProfile={isYourProfile}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {profile?.id && showPersonalInformation() && (
                      <div className="w-full mt-6 container-max-width">
                        <PersonalInformation
                          userData={userData}
                          profile={profile}
                          setUserData={setUserData}
                          isYourProfile={isYourProfile}
                          onDoneUpdate={(data) => {
                           console.log('data:', data);
                           setProfile({
                            ...profile,
                            ...data.profile
                           });
                          }}
                        />
                      </div>
                    )}
                    {profile?.id && (
                      <div className={`w-full mt-6 container-max-width ${showProInformation(hasProInfo) ? "" : "hidden"}`}>
                        <ProfessionalInformation
                          userData={userData}
                          profile={profile}
                          setUserData={setUserData}
                          isYourProfile={isYourProfile}
                          onHasProfessionalInfoChange={(hasInfo) => {
                            setHasProInfo(hasInfo);
                          }}
                        />
                      </div>
                    )}

                    {  !showPersonalInformation() && !showProInformation(hasProInfo) && (
                      <div className="w-full mt-6 container-max-width p-6 rounded bg-white text-center font-bold text-darkerGray">No Information yet</div>
                    )}
                  </>
                )}
              </div>
            </TabPanel>
            {/*  */}
            <TabPanel
              className="profile-milestones"
              hidden={selectedTab !== "milestones"}>
              <div className="w-full profile-page-wrapper-padding ">
                {/* header */}
                <div className="block 3md:hidden">
                  <div className="w-full flex items-center justify-between">
                    <div className="flex space-x-2 items-center">
                      <p className="text-lg 2sm:text-2xl font-bold text-lightBlack">
                          Milestones
                      </p>
                    {/* pop info */}
                      <PopInfo type="milestones" />
                    </div>
                    {showFilters && (
                      <button
                        className="bg-tagBorder py-1 px-2 rounded-md text-4xs font-semibold text-lightBlack flex items-center 2sm:hidden 3md:flex"
                        onClick={clearFilters}>
                        <MdClose />
                        Clear filters
                      </button>
                    )}
                  </div>
                  <div className="w-full 2sm:flex 2sm:justify-between 2sm:items-center">
                    <div className="w-full 2sm:flex 2sm:items-center 2sm:space-x-3">
                      <p className="text-xs 2sm:text-sm text-lightBlack leading-6">
                        {showFilters && (
                          <span className="font-bold">Current filters: </span>
                        )}
                        <span>
                          {featured && "Pinned Only"}
                          {filterData}
                        </span>
                      </p>
                      {showFilters && (
                        <button
                          className="bg-tagBorder py-1 px-2 rounded-md text-4xs 2sm:text-xs font-semibold text-lightBlack hidden items-center 2sm:flex 3md:hidden"
                          onClick={clearFilters}>
                          <MdClose />
                          Clear filters
                        </button>
                      )}
                    </div>
                    <div className="hidden 2sm:block 3md:hidden">
                      <GroupButton
                        active={view}
                        onClick={(value) => setView(value)}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`w-full flex 2sm:hidden items-center justify-between 2md:space-x-4 container-max-width 3md:flex`}>
                  <div className="w-full flex items-center justify-start space-x-6 ">
                    <div className="w-full 2sm:w-fit">
                      {profilePermission.hasMilestone ? (
                        <Button
                          buttonName="Add a Milestone"
                          buttonTextClass="text-xs 2sm:text-sm text-white font-semibold flex items-center justify-center"
                          buttonRadius="6px"
                          iconPosition="left"
                          icon={<FaPlus className="mr-2" />}
                          buttonClass="relative py-3.5 2sm:py-5 px-5 2sm:px-10 bg-primary hover:bg-darkPrimary w-full 2sm:w-fit btn-add-milestone mt-4 mb-2"
                          onClick={addMilestone}
                        />
                      ) : (
                        <div className="flex space-x-2 items-center hidden 2sm:flex">
                          <p className="text-3xl text-lightBlack font-bold tracking-tighter">
                            Milestones
                          </p>
                          {/* pop info */}
                          <PopInfo type="milestones" />
                        </div>
                      )}
                    </div>
                    {!userData && (
                      <div className="hidden 2md:block">
                        <LineVerticalSVG />
                      </div>
                    )}
                    <div className="hidden 3md:flex items-center space-x-5 mt-1">
                      <p className="text-lg text-lightBlack leading-6">
                        {showFilters && (
                          <span className="font-bold">Current filters: </span>
                        )}
                        <span>
                          {featured && "Pinned Only"}
                          {filterData}
                        </span>
                      </p>
                      {showFilters && (
                        <button
                          className="bg-thBg py-2 px-3.5 rounded-md text-base font-semibold text-lightBlack leading-4 flex items-center"
                          onClick={clearFilters}>
                          <MdClose />
                          Clear filters
                        </button>
                      )}
                    </div>
                  </div>
                  {/*  */}
                  <div className="hidden 3md:flex justify-center">
                    <GroupButton
                      active={view}
                      onClick={(value) => setView(value)}
                    />
                  </div>
                </div>
                {/* selects component */}
                {view !== "column" && (
                  <ListFilter
                    positions={options.positions}
                    categories={options.categories}
                    active={view}
                    onClick={(value) => setView(value)}
                    permission={permission}
                    addMilestone={addMilestone}
                    {...{
                      jobRole,
                      category,
                      organizeBy,
                      featured,
                      onChangeJobRole,
                      onChangeCategory,
                      onChangeOrganizeBy,
                      onChangeFeaturedOnly,
                    }}
                  />
                )}
                {/*  */}
              </div>
              <div className="w-full mt-5">
                <div className="w-full milestone-page-wrapper">
                  {/*  */}
                  {view === "detailed"
                    ? renderDetailedListView()
                    : view === "list"
                    ? renderListView()
                    : renderColumnView()}
                  {/*  */}
                  <LoadMore
                    selectedTab={selectedTab}
                    result={
                      featuredMilestone.length ||
                      currentMilestones.length ||
                      pastMilestones.length ||
                      milestones.length
                        ? true
                        : false
                    }
                    requesting={isRequesting}
                    initialize={initializeMilestones}
                    loadMore={loadMoreMilestones}
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel
              className="profile-photos-videos"
              hidden={selectedTab !== "photos-video"}>
              <div className="w-full profile-page-wrapper-padding">
                <div className="w-full mt-6 container-max-width">
                  <PhotosVideos
                    userData={userData}
                    profile={profile}
                    title="Photos"
                    buttonTitle="Add Photos"
                    type="photo"
                    isOwner={profile?.id === userData?.profile.id}
                  />
                </div>
                <div className="w-full mt-6 container-max-width">
                  <ManageVideos userData={userData} profile={profile} />
                </div>
              </div>
            </TabPanel>
            <TabPanel className="resume" hidden={selectedTab !== "resume"}>
              <div className="w-full profile-page-wrapper-padding">
                <div className="w-full mt-6 container-max-width">
                  <ResumeList
                    profile={profile}
                    username={username}
                    profileId={profileId}
                  />
                </div>
              </div>
            </TabPanel>
          </div>
        </div>
        {/** floating button */}
        {selectedTab === "milestones" && scrollToTop > 1000 && (
          <FabButton onClick={scrollTop}>
            <FiChevronUp className="text-5xl" />
          </FabButton>
        )}
      </Layout>
  );
};
const mapStateToProps = (state) => {
  return {
    userData: state.user.userData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (params) => {
      dispatch(userActions.setUserData(params));
    },
  };
};
const useQuery = () => {
  const { state } = useLocation();

  return React.useMemo(() => state, [state]);
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);