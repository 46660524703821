import * as React from "react"
const LockOutlineIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#333"
      d="M7.576 0h.937c.05.011.1.028.15.033a5.7 5.7 0 0 1 2.23.655c2.2 1.202 3.35 3.063 3.431 5.572.024.742.003 1.484.006 2.226 0 .068-.04.16.08.188.569.136 1.119.334 1.677.504v9.491c-.038.009-.076.013-.112.026-2.405.83-4.886 1.225-7.423 1.297a23.835 23.835 0 0 1-4.45-.316c-1.343-.215-2.665-.515-3.95-.969C.046 18.67 0 18.625 0 18.503c.004-3.053.004-6.106 0-9.158 0-.12.044-.167.15-.204.472-.167.948-.32 1.435-.435.139-.033.185-.095.18-.238a16.692 16.692 0 0 1-.004-.918c.012-.721-.056-1.447.07-2.163.457-2.588 1.943-4.29 4.43-5.126C6.688.118 7.136.076 7.576 0Zm7.339 13.941c0-1.21-.006-2.42.005-3.631.002-.209-.05-.298-.262-.357a25.469 25.469 0 0 0-4.633-.844c-2.948-.256-5.84.066-8.682.875-.129.037-.173.084-.172.223.004 2.479.004 4.958 0 7.438 0 .142.052.182.177.218 1.496.43 3.02.709 4.57.857 3.002.288 5.942-.04 8.836-.859.139-.039.164-.102.163-.23-.004-1.23-.002-2.46-.002-3.69ZM2.931 7.103c0 .371.006.742-.003 1.113-.004.143.05.16.17.138.395-.074.79-.157 1.19-.207.185-.023.206-.102.208-.26.008-.683-.045-1.368.03-2.049.221-1.985 2.184-3.43 4.14-3.05 1.733.336 2.922 1.772 2.928 3.54.002.54 0 1.08.001 1.62 0 .081-.021.16.112.181.437.066.87.147 1.305.227.11.02.15-.005.147-.123-.007-.286.002-.573-.003-.86-.01-.636.034-1.274-.069-1.909a5.128 5.128 0 0 0-4.761-4.287 5.137 5.137 0 0 0-5.203 3.719c-.205.726-.201 1.465-.192 2.207Zm7.492-.18c-.015-.3.022-.67-.035-1.04-.172-1.114-1.194-1.978-2.347-1.977-1.152 0-2.182.865-2.339 1.983-.092.652-.02 1.312-.04 1.968-.004.13.076.108.154.1a22.726 22.726 0 0 1 4.458.004c.13.013.154-.031.151-.147-.007-.273-.002-.546-.002-.89v-.001Z"
    />
    <path
      fill="#333"
      d="M8.63 15.35c0 .221-.009.443.003.664.006.134-.044.163-.167.159a14.987 14.987 0 0 0-.858 0c-.122.004-.154-.034-.152-.153.006-.443-.003-.886.006-1.328.003-.136-.04-.2-.165-.259a1.746 1.746 0 0 1-.924-2.127c.276-.85 1.133-1.346 2.036-1.175.828.157 1.445.952 1.386 1.824-.047.685-.398 1.187-1.016 1.484-.124.06-.156.123-.152.247.009.22.003.442.003.664Zm-.001-2.503a.59.59 0 0 0-.583-.582.591.591 0 0 0-.587.578.593.593 0 0 0 .593.592.593.593 0 0 0 .577-.588Z"
    />
  </svg>
)
export default LockOutlineIcon
