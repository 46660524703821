import React, { useMemo } from "react";
import CalendarIconSVG from "assets/svg/calendar-resume-icon";
import MailIconSVG from "assets/svg/mail-icon-stroke";
import MapPinIconSVG from "assets/svg/map-pin-icon";
import NotificationDotIconSVG from "assets/svg/notification-dot-icon";
import PhoneIconSVG from "assets/svg/phone-icon-stroke";
import { Profile1 } from "constants/images";
import { BsExclamationDiamondFill } from "react-icons/bs";
import { HiOutlineCalendarDays } from "react-icons/hi2";
import { socialTemplate } from "utils/constant";
import { renderStartDate, renderEndDate } from "../../resume";
import ResumeUnderlineIconSVG from "assets/svg/resume-underline-icon";
import ResumeDotDividerIconSVG from "assets/svg/resume-dot-divider-icon";
import moment from "moment";
import _ from "lodash";
import MilestoneTG from "./milestone";
import { groupArraysWithType, A4_SIZE } from "../../resume";

export default function TemplateTwoPageOne(props) {
  // ===================== props ============================
  const {
    active,
    previewImage,
    color = {},
    details = {},
    personal = {},
    social = {},
    page = 1,
    primaryColor,
    tertiaryColor,
    quarternary,
    milestones = {},
    show_image_milestone = true,
    setTotalPage,
    profile
  } = props;

 

  const headerRef = React.useRef();
  const personalRef = React.useRef();
  const milstoneRef = React.useRef();
  const paperRef = React.useRef();
  
  const [mergeMilestone, setMergeMilestone] = React.useState([])

  React.useEffect(() => {
      let groupAll = groupArraysWithType(arraysWithType, 5, 7);
      console.log(groupAll);
      console.log(groupAll.length);
      setMergeMilestone(groupAll)
      setTotalPage(groupAll.length) 
  }, [milestones]);

  const arraysWithType = [
    { array: milestones?.featured || [], type: 'featured' },
    { array: milestones?.current || [], type: 'current' },
    { array: milestones?.past || [], type: 'past' },
  ];

  // ===================== states ============================
  const [socialColor, socialBG] = useMemo(() => {
    if (color.label === "Yellow + Blue") {
      return ["#FFFFFF", primaryColor];
    } else if (color.label === "Pink + Dark Pink") {
      return [tertiaryColor, "#FFFFFF"];
    }
    return ["#FFFFFF", tertiaryColor];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  const [pageBGActive, pageBGInactive] = useMemo(() => {
    if (color.label === "Blue + Orange") {
      return [tertiaryColor, "#16438A"];
    } else if (color.label === "Pink + Dark Pink") {
      return ["#FFFFFF", tertiaryColor];
    }
    return ["#FFFFFF", "#000000"];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  const socMed = useMemo(() => {
    let arrays = Object.entries(social);
    return arrays.map((obj) => {
      let key = obj[0];
      let value = obj[1];
      return { key, value };
    });
  }, [social]);
  // ===================== return Node ============================

  return (
    <div className={`w-full template-two`} style={A4_SIZE} ref={paperRef}>

      {/* header  */}
      <div className="w-full w-full">
        <div ref={headerRef} className={`${!active ? "hidden" : ""}`}>
          <div
            className="w-full pt-5.4 pb-3.7 pl-15 pr-12.5"
            style={{ backgroundColor: primaryColor }}>
            <div className="w-full flex flex-row gap-x-5.4 text-white items-center">
            {
              personal?.profile_image &&
              <>
                <div className="w-23 h-23 rounded-md bg-white relative overflow-hidden">
                  {(personal.hasOwnProperty("profile_image") && personal.profile_image) || previewImage ? (
                    <img
                      src={previewImage ?? personal.profile_image}
                      className="w-full h-full object-cover object-center"
                      alt="resume-profile"
                    />
                  ) : (
                    <img
                      src={Profile1}
                      className="w-full h-full object-cover object-center"
                      alt="resume-profile"
                    />
                  )}
                </div>
              </>
            }
              <div style={{ width: "calc(100% - 111px)" }}>
                <h1 className="text-xl font-bold tracking-verytight">
                  {details.username}
                </h1>
                <div className="w-full flex flex-row gap-x-1.5 text-white items-center mt-2.5">
                  {personal.hasOwnProperty("birthdate") && (
                    <>
                      <CalendarIconSVG className="text-resume-icon-header opacity-70 w-2.5 h-2.5" />
                      <p className="text-1xs leading-2 font-medium">
                        <em>Formation Date:</em> {moment(personal.birthdate).format("DD MMMM YYYY")}
                      </p>
                    </>
                  )}
                </div>
                <div className="w-full flex flex-row gap-x-1.5 text-white items-center mt-2.5">
                  {personal.hasOwnProperty("birth_location") && (
                    <>
                      <MapPinIconSVG className="text-resume-icon-header opacity-70 w-2.5 h-2.5" />
                      <p className="text-1xs leading-2 font-medium">
                        <em>Formation Location:</em> {personal.birth_location}
                      </p>
                    </>
                  )}
                </div>
                <div className="w-full flex flex-row gap-x-1.5 text-white items-center mt-2.5">
                  {details?.phone_number && (
                    <div className="flex flex-row gap-x-1.5 items-center">
                      <PhoneIconSVG
                        className="text-resume-icon-header opacity-70 w-2.5 h-2.5"
                        stroke="#E5EFFA"
                      />
                      <p className="text-1xs leading-2 font-medium">
                        <em>Phone:</em> {details.phone_number}
                      </p>
                    </div>
                  )}

                  {details.hasOwnProperty("email") && (
                    <div className="flex flex-row gap-x-1.5 items-center">
                      <MailIconSVG
                        className="text-resume-icon-header opacity-70 w-2.5 h-2.5"
                        stroke="#E5EFFA"
                      />
                      <p className="text-1xs leading-2 font-medium">
                        <em>Email Address:</em> {details.email}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* end header */}
          <div className="w-full py-2.4" style={{ backgroundColor: quarternary }}>
            <div className="w-full flex flex-row gap-x-3 justify-center text-white">
              {(socMed || []).map((obj, index) => {
                const SocialIcon = socialTemplate[obj.key];
                if (SocialIcon) {
                  return (
                    <div className="flex items-center space-x-1.5" key={index}>
                      <button
                        className="w-3 h-3 rounded-full"
                        style={{ backgroundColor: socialBG }}>
                        <SocialIcon
                          className="w-1.5 h-1.5 m-auto"
                          style={{ color: socialColor }}
                        />
                      </button>
                      <p className="text-3xs leading-2">{obj.value}</p>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
        </div>
        {/* end extra header */}
        {/* start body */}
        <div
          className="relative w-full h-full grid bg-white relative grid-row-auto"
          style={{ minHeight: active ? "692px" : "842px"}}>
          {/* body */}
          <div className="w-full px-15 pt-7.5 gap-y-7">
            <div className="w-full grid">
              <div ref={personalRef} className={`${!active ? "hidden" : ""}`}>
                <div className="w-full flex flex-row space-x-6.5">
                  <div className="w-14">
                    <p className="text-4xs font-medium text-lightBlack tracking-tight">
                      More Information
                    </p>
                    <ResumeUnderlineIconSVG
                      className="mt-3"
                      stroke={primaryColor}
                    />
                  </div>
                  <div style={{ width: "calc(100% - 81px)" }}>
                    <div className="w-full grid gap-y-2">
                      {/* =============Bio======================== */}
                      {details.hasOwnProperty("bio") && details.bio && (
                        <div className="w-full flex flex-row space-x-0.5 border-b border-resumeTempTwoBorder pb-1">
                          <div className="w-18.5">
                            <p className="text-3xs text-darkerGray tracking-resume leading-3.2">
                              Bio
                            </p>
                          </div>
                          <div style={{ width: "calc(100% - 75px)" }}>
                            <p className="text-3xs text-lightBlack font-medium tracking-resumeValue leading-3.2">
                              {details.bio}
                            </p>
                          </div>
                        </div>
                      )}
                      {/* ================Members==================== */}
                      {
                        personal?.members?.length &&
                          <div className="w-full flex flex-row space-x-0.5 border-b border-resumeTempTwoBorder pb-1 members-list">
                            <div className="w-18.5">
                              <p className="text-3xs text-darkerGray tracking-resume leading-3.2">
                                Members
                              </p>
                            </div>
                            <div style={{ width: "calc(100% - 75px)" }}>
                              {/* <p className="text-3xs text-lightBlack font-medium tracking-resumeValue leading-3.2">
                                {moment(details.birthdate).format(
                                  "DD MMMM YYYY"
                                )}
                              </p> */}
                              <div className="text-3xs text-lightBlack font-medium tracking-resumeValue leading-3.2 gap-x-4 flex flex-row ">
                                {
                                  personal.members.map((member, index) => {
                                    return (
                                      <div className="flex items-center members-list__member">
                                        <NotificationDotIconSVG className="w-2.5 h-2.5" />
                                        <span key={`member-${index}`}
                                        dangerouslySetInnerHTML={{ __html: member?.mention || member.name }}
                                        >
                                          {/* {member.name} */}
                                        </span>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            </div>
                          </div>
                      }
                    </div>
                  </div>
                </div>
                {/* Divider */}
                <div className="w-full py-4">
                  <ResumeDotDividerIconSVG full={true} />
                </div>
              </div>
              {/* ================Milestones==================== */}
              <div ref={milstoneRef} className="h-full bg-white">
                {
                  mergeMilestone.map((item,index)=>{
                    return (
                      page === (index+1) &&
                      <div key={index}>
                        <MilestoneTG
                          items = {item}
                          milestones={milestones}
                          primaryColor={primaryColor}
                          show_image_milestone={show_image_milestone}
                          tertiaryColor={tertiaryColor}
                        />
                      </div>
                    )
                  })
                }
                
              </div>
            </div>
          </div>
          {/* footer */}
          <div className="w-full absolute bottom-0 right-0 pt-10 flex items-end">
            <div
              className="w-full h-6 px-15 relative"
              style={{ backgroundColor: quarternary }}>
              <div className="w-full h-full flex flex-row justify-between items-center">
                <p className="text-white font-semibold text-1xs">Page {
                profile?.profile_type?.type === "group" || profile?.profile_type?.type === "team" ? page : 2}</p>
                <div className="flex flex-start gap-1.5">
                  <button
                    className="w-1.5 h-1.5 rounded-full"
                    style={{ backgroundColor: pageBGActive }}
                  />
                  <button
                    className="w-1.5 h-1.5 rounded-full"
                    style={{ backgroundColor: pageBGInactive }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
