const ResumeLineVerticalDividerIconSVG = ({ className = "", full = false }) => {
  let height = "69";
  let viewBox = "0 0 2 69";
  let d = "M1 0V69";
  if (full) {
    height = "100%";
    viewBox = "0 0 2 138";
    d = "M1 0V138";
  }
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="2"
      height={height}
      viewBox={viewBox}
      fill="none">
      <path opacity="0.1" d={d} stroke="#212121" />
    </svg>
  );
};

export default ResumeLineVerticalDividerIconSVG;
