const DetailedViewIconSVG = ({ className = "", fill = "#9A9A9A" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="9.01961" height="9.01961" rx="2" fill={fill} />
      <rect y="10.9805" width="9.01961" height="9.01961" rx="2" fill={fill} />
      <rect
        x="10.9804"
        width="9.01961"
        height="3.52941"
        rx="1.76471"
        fill={fill}
      />
      <rect
        x="10.9804"
        y="10.9805"
        width="9.01961"
        height="3.52941"
        rx="1.76471"
        fill={fill}
      />
      <rect
        x="10.9804"
        y="5.49023"
        width="9.01961"
        height="3.52941"
        rx="1.76471"
        fill={fill}
      />
      <rect
        x="10.9804"
        y="16.4707"
        width="9.01961"
        height="3.52941"
        rx="1.76471"
        fill={fill}
      />
    </svg>
  );
};

export default DetailedViewIconSVG;
