import React, { useMemo } from "react";
import Button from "components/button/button";
import GroupButton from "components/button/group-btn";
import Select from "components/select/select";
import Toggle from "components/toggle/toggle";
import { ORGANIZE_BY } from "constants/options";
import _ from "lodash";
import { FaPlus } from "react-icons/fa";

export default function ListFilter({
  positions = [],
  categories = [],
  jobRole,
  category,
  organizeBy,
  featured,
  onChangeJobRole = () => {},
  onChangeCategory = () => {},
  onChangeOrganizeBy = () => {},
  onChangeFeaturedOnly = () => {},
  active = {},
  onClick = () => {},
  permission = {},
  addMilestone = () => {},
}) {
  const hasAddMilestone = useMemo(() => {
    if (!_.isEmpty(permission)) {
      let permissions = ["Super Admin", "Admin", "Staff"];
      let isOwner = permission.status === "owner";
      if (isOwner || permissions.includes(permission.permission.name)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }, [permission]);

  return (
    <div className="w-full mt-2 pb-4.5 2sm:pb-7 3md:pb-13 border-b 2sm:border-b-0 3md:border-b border-gray container-max-width">
      <div className="w-full 2sm:flex 2sm:items-center 2sm:gap-9">
        {/* add milestone */}
        {hasAddMilestone ? (
          <Button
            buttonName="Add a Milestone"
            buttonTextClass="text-xs 3md:text-sm text-white font-semibold flex items-center justify-center whitespace-normal 2sm:whitespace-nowrap 3md:whitespace-normal"
            buttonRadius="6px"
            iconPosition="left"
            icon={<FaPlus className="mr-2" />}
            buttonClass="relative py-4.5 3md:py-5 px-5 3md:px-10 bg-primary hover:bg-darkPrimary w-full 2sm:w-fit hidden 2sm:block 3md:hidden"
            onClick={addMilestone}
          />
        ) : null}
        {/* selects */}
        <div className="w-full grid grid-cols-2 gap-2 3md:flex  3md:gap-x-3 3md:gap-y-0 filter-container">
          <Select
            value={jobRole}
            formContainer="w-full lg:w-64"
            options={positions}
            height={40}
            placeholder="Select"
            name="job"
            innerLabel="Role/Job Title"
            innerLabelClass="text-1xs 3md:text-xs font-semibold color-select-label leading-3.5 absolute left-4.5 top-3 3md:top-4"
            onChange={onChangeJobRole}
            isMulti={true}
            valueContainerClass = "-10px !important"
          />
          <Select
            value={categories.filter(function (opt) {
              return opt.label === category;
            })}
            formContainer="w-full hidden 3md:block lg:w-64"
            options={categories}
            height={40}
            placeholder="Select"
            name="category"
            innerLabel="Category"
            onChange={onChangeCategory}
            valueContainerClass = "-10px !important"
          />
          <Select
            value={ORGANIZE_BY.filter(function (option) {
              return option.label === organizeBy;
            })}
            formContainer="w-full lg:w-64"
            options={ORGANIZE_BY}
            height={40}
            placeholder="Select"
            name="sort"
            innerLabel="Organize By"
            innerLabelClass="text-1xs 3md:text-xs font-semibold color-select-label leading-3.5 absolute left-4.5 top-3 3md:top-4"
            onChange={onChangeOrganizeBy}
            valueContainerClass = "-10px !important"
          />
        </div>
        {/* toggle */}
        <div className="w-full mt-4 2sm:hidden 3md:block 3md:mt-0 3md:w-500">
          <div className="w-full flex items-center justify-between">
            {/* <div className="w-full flex 3md:justify-end items-center space-x-2 3md:space-x-4">
              <p className="text-xs 2sm:text-lg text-lightBlack font-medium">
                Pinned only
              </p>
              <Toggle
                className="w-8.6 h-5 2sm:w-14 2sm:h-8"
                hActiveClass="w-3.5 h-3.5 2sm:w-6 2sm:h-6 ml-auto mr-1 rounded-full bg-white"
                hInactiveClass="w-3.5 h-3.5 2sm:w-6 2sm:h-6 ml-1 rounded-full bg-white"
                checked={featured}
                name="featured"
                onChange={onChangeFeaturedOnly}
              />
            </div> */}
            <div className="flex 2sm:hidden justify-end flex-1">
              <GroupButton
                active={active}
                onClick={(value) => onClick(value)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
