import React from "react";

const CustomCheckbox = ({
  name,
  checked,
  label = "",
  labelClass = "",
  showMinus = false,
  onChange = null,
  containerStyle = {},
  isBoxType=false,
  v2 = false,  // v2 has white background and blue checkmark with a border-radius of 6px -> this is used in the Add Photo and Add Video modals
}) => {
  return (
    <div className={`checkbox-container-custom ${containerStyle}`}>
      <input
        type="checkbox"
        id={`checbox-${name}`}
        checked={checked || showMinus}
        onChange={(value) => (onChange ? onChange(value) : null)}
      />
      {showMinus ? (
        <div className="check-minus" />
      ) : (
        <div className={`checkmark ${v2 ? "-v2" : ""} ${isBoxType ? 'rounded' : 'rounded-full'}`}/>

      )}
      <label htmlFor={`checbox-${name}`} className={labelClass}>
        {label}
      </label>
    </div>
  );
};

export default CustomCheckbox;
