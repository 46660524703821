import React, { useContext, useMemo } from "react";
import EmptyLayout from "layouts/empty-layout";
import TemplateTwoFullPageOne from "./full-preview/template-two/page-one";
import TemplateTwoFullPageTwo from "./full-preview/template-two/page-two";
import { Context } from "context/context";
import _ from "lodash";
import TemplateThreeFullPageOne from "./full-preview/template-three/page-one";
import TemplateThreeFullPageTwo from "./full-preview/template-three/page-two";
import TemplateFourFullPageTwo from "./full-preview/template-four/page-two";
import TemplateFourFullPageOne from "./full-preview/template-four/page-one";
import TemplateOneFull from "./full-preview/template-one";

const LONG_SIZE = {
  width: "816px", // 8.5 inch
  maxWidth: "816px", // 8.5 inch
  minHeight: "1248px", // 13 inch (1056px is 11 inch)
};

export default function ResumeFullPreview(props) {
  const { state, milestones, previewImage } = useContext(Context);

  const [primaryColor, secondaryColor, tertiaryColor, quarternary] =
    useMemo(() => {
      if (!_.isEmpty(state.color)) {
        return state.color.value;
      }
      return ["", "", "", ""];
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.color]);

  return (
    <EmptyLayout
      title="Full Prevuew Resume"
      description="Full Preview Resume"
      backButton="Close Preview">
      <div className="w-full h-full grid bg-default justify-center py-5 gap-y-5 overflow-y-auto custom-scroll">
        <>
          <div
            className="w-full h-full relative bg-white border border-resume-border"
            style={LONG_SIZE}>
            {state.templateName === "Template Name 1" && (
              <TemplateOneFull
                {...{
                  page: 1,
                  previewImage,
                  milestones,
                  primaryColor,
                  secondaryColor,
                  tertiaryColor,
                }}
                details={state.more}
                personal={state.personal}
                social={state.social}
              />
            )}
            {state.templateName === "Template Name 2" && (
              <TemplateTwoFullPageOne
                {...{
                  previewImage,
                  primaryColor,
                  secondaryColor,
                  tertiaryColor,
                  quarternary,
                }}
                color={state.color}
                details={state.more}
                personal={state.personal}
                social={state.social}
              />
            )}
            {state.templateName === "Template Name 3" && (
              <TemplateThreeFullPageOne
                {...{
                  previewImage,
                  primaryColor,
                  secondaryColor,
                  tertiaryColor,
                  quarternary,
                }}
                color={state.color}
                details={state.more}
                personal={state.personal}
                social={state.social}
              />
            )}
            {state.templateName === "Template Name 4" && (
              <TemplateFourFullPageOne
                {...{
                  previewImage,
                  primaryColor,
                  secondaryColor,
                  tertiaryColor,
                  quarternary,
                }}
                color={state.color}
                details={state.more}
                personal={state.personal}
                social={state.social}
              />
            )}
          </div>
          <div
            className="w-full h-full relative bg-white border border-resume-border"
            style={LONG_SIZE}>
            {state.templateName === "Template Name 1" && (
              <TemplateOneFull
                {...{
                  page: 2,
                  previewImage,
                  milestones,
                  primaryColor,
                  secondaryColor,
                  tertiaryColor,
                }}
                details={state.more}
                personal={state.personal}
                social={state.social}
                show_image_milestone = {state.show_milestone_image}
              />
            )}
            {state.templateName === "Template Name 2" && (
              <TemplateTwoFullPageTwo
                {...{
                  previewImage,
                  milestones,
                  primaryColor,
                  secondaryColor,
                  tertiaryColor,
                  quarternary,
                }}
                color={state.color}
                social={state.social}
                show_image_milestone = {state.show_milestone_image}
              />
            )}
            {state.templateName === "Template Name 3" && (
              <TemplateThreeFullPageTwo
                {...{
                  previewImage,
                  milestones,
                  primaryColor,
                  secondaryColor,
                  tertiaryColor,
                  quarternary,
                }}
                color={state.color}
                details={state.more}
                personal={state.personal}
                social={state.social}
                show_image_milestone = {state.show_milestone_image}
              />
            )}
            {state.templateName === "Template Name 4" && (
              <TemplateFourFullPageTwo
                {...{
                  previewImage,
                  milestones,
                  primaryColor,
                  secondaryColor,
                  tertiaryColor,
                  quarternary,
                }}
                color={state.color}
                details={state.more}
                personal={state.personal}
                social={state.social}
                show_image_milestone = {state.show_milestone_image}
              />
            )}
          </div>
        </>
      </div>
    </EmptyLayout>
  );
}
