const Close = ({ className = "", fill = "#F7F7F7", strokeWidth = "1.5" }) => (
  <svg
    className={className}
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.5 1.5L10 10" stroke={fill} strokeWidth={strokeWidth} />
    <path d="M10 1.5L1.5 10" stroke={fill} strokeWidth={strokeWidth} />
  </svg>
);

const MegaPhone = ({ className = "" }) => {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.76 29.4401H15.34L12 26.0001H7.06L6 25.0001V20.1601L2.62 16.7201V15.3001L6 11.8601V7.00008L7.06 6.00008H12L15.34 2.58008H16.76L20.2 6.00008H25.06L26.06 6.98008V11.8601L29.48 15.3001V16.7201L26 20.1601V25.0001L25 26.0001H20.2L16.76 29.4401ZM13.46 20.9601H14.88L22.42 13.4201L21 12.0001L14.18 18.8401L11.42 16.0801L10 17.5001L13.46 20.9601Z"
        fill="#0C6ED1"
      />
    </svg>
  );
};

const Bell = ({ className = "", fill = "white" }) => (
  <svg
    className={className + " relative"}
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_820_3013)">
      <path
        d="M15.63 13.46H15.19C14.8284 11.7075 14.674 9.91856 14.73 8.13C14.7293 6.48391 14.1136 4.89751 13.0035 3.68201C11.8935 2.46651 10.3693 1.70964 8.72998 1.56V0.630005C8.72998 0.462919 8.66362 0.302718 8.54547 0.18457C8.42732 0.0664224 8.26706 0 8.09998 0C7.93289 0 7.77269 0.0664224 7.65454 0.18457C7.53639 0.302718 7.46997 0.462919 7.46997 0.630005V1.56C5.83069 1.70964 4.30652 2.46651 3.19647 3.68201C2.08643 4.89751 1.47064 6.48391 1.46997 8.13C1.52596 9.91856 1.37161 11.7075 1.01001 13.46H0.580017C0.410279 13.46 0.247465 13.5274 0.127441 13.6475C0.00741813 13.7675 -0.0599976 13.9303 -0.0599976 14.1C-0.0573722 14.268 0.0111805 14.4283 0.13092 14.5461C0.25066 14.664 0.411997 14.7301 0.580017 14.73H4.29999C4.44765 15.6421 4.91513 16.4718 5.61871 17.0706C6.3223 17.6695 7.21609 17.9984 8.14001 17.9984C9.06394 17.9984 9.95767 17.6695 10.6613 17.0706C11.3648 16.4718 11.8323 15.6421 11.98 14.73H15.64C15.8071 14.73 15.9673 14.6636 16.0854 14.5455C16.2036 14.4273 16.27 14.2671 16.27 14.1C16.27 13.9303 16.2025 13.7675 16.0825 13.6475C15.9625 13.5274 15.7997 13.46 15.63 13.46ZM8.13 16.73C7.54141 16.7298 6.96975 16.5328 6.50592 16.1704C6.04209 15.808 5.7127 15.3011 5.57001 14.73H10.68C10.5393 15.3003 10.2118 15.807 9.74963 16.1694C9.28749 16.5319 8.71734 16.7293 8.13 16.73ZM2.38 13.46C2.72071 11.705 2.85825 9.91652 2.78998 8.13C2.78998 6.71375 3.35262 5.35551 4.35406 4.35406C5.35551 3.35262 6.71375 2.79004 8.13 2.79004C9.54626 2.79004 10.9045 3.35262 11.9059 4.35406C12.9074 5.35551 13.47 6.71375 13.47 8.13C13.4055 9.916 13.5397 11.7037 13.87 13.46H2.38Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_820_3013">
        <rect width="16.26" height="18" fill={fill} />
      </clipPath>
    </defs>
  </svg>
);

const Logout = ({ className = "", fill = "#606B80" }) => (
  <svg
    className={className}
    width="21"
    height="21"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      {/* <path fill="none" d="M0 0h24v24H0z" /> */}
      <path
        d="M5 11h8v2H5v3l-5-4 5-4v3zm-1 7h2.708a8 8 0 1 0 0-12H4A9.985 9.985 0 0 1 12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10a9.985 9.985 0 0 1-8-4z"
        fill={fill}
      />
    </g>
  </svg>
);

const Message = ({ className = "", fill = "white" }) => (
  <svg
    className={className}
    width="19"
    height="16"
    viewBox="0 0 19 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_820_3011)">
      <path
        d="M18.8798 0.990015C18.8522 0.914336 18.8077 0.845925 18.7498 0.790003C18.6854 0.735433 18.6149 0.688443 18.5398 0.649988C18.4639 0.6349 18.3857 0.6349 18.3098 0.649988H0.619764C0.54384 0.6349 0.465708 0.6349 0.389784 0.649988C0.314644 0.688443 0.244159 0.735433 0.179762 0.790003C0.124088 0.847783 0.0799893 0.915678 0.0497876 0.990015C0.0393937 1.06298 0.0393937 1.13702 0.0497876 1.20999V14.75C0.0568348 14.8252 0.0736305 14.8991 0.0997753 14.97C0.128784 15.0449 0.173058 15.1131 0.22978 15.17C0.285275 15.2212 0.349668 15.2619 0.419783 15.29C0.492806 15.2995 0.56676 15.2995 0.639784 15.29H18.3098C18.3794 15.3004 18.4502 15.3004 18.5198 15.29C18.5899 15.2619 18.6543 15.2212 18.7098 15.17C18.7665 15.1131 18.8108 15.0449 18.8398 14.97C18.8659 14.8991 18.8827 14.8252 18.8898 14.75V1.20999C18.9002 1.13702 18.9002 1.06298 18.8898 0.990015H18.8798ZM11.2298 7.29L9.47978 9.00997L2.18977 1.83999H16.7598L11.2298 7.29ZM6.37977 7.76998L1.25978 13.21V2.74001L6.37977 7.76998ZM7.28978 8.66L9.01979 10.37C9.06253 10.4057 9.10955 10.4359 9.15977 10.46H9.22978C9.30549 10.493 9.38718 10.51 9.46977 10.51C9.54902 10.5089 9.62725 10.4919 9.69978 10.46H9.76979C9.82001 10.4359 9.86703 10.4057 9.90977 10.37L11.6398 8.66L16.8298 14.13H2.09978L7.28978 8.66ZM12.5498 7.76998L17.6598 2.76998V13.21L12.5498 7.76998Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_820_3011">
        <rect
          width="18.93"
          height="14.8"
          fill={fill}
          transform="translate(0 0.599609)"
        />
      </clipPath>
    </defs>
  </svg>
);

export { Close, Bell, MegaPhone, Logout, Message };
