import React, { createContext, useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";

export const Context = createContext();

const notificationDefaultState = {
  type: "push",
  title: "",
  message: "",
  scheduled: false,
  date: "",
  time: "",
  image: undefined,
  file: undefined,
  buttons: [
    { label: "", url: "" },
    { label: "", url: "" },
  ],
};

const defaultState = {
  templateName: null,
  color: {
    label: "Blue + Orange",
    value: ["#3885FF", "#E5EFFA", "#EF6B33", "#2063CD"],
    color: ["#3885FF", "#EF6B33"],
    key: "theme-one",
  },
  personal: {},
  social: {},
  more: {},
  show_image: true,
  show_milestone_image: true,
};

const defaultMilestones = {
  featured: [],
  current: [],
  past: [],
};

export const ContextProvider = ({ initials, children }) => {
  const [alerts, setAlerts] = useState([
    // <span>
    //   Get your Verified Badge by
    //   <Link to="/verify-account" className="ml-1 underline hover:text-gray">
    //     clicking here
    //   </Link>
    // </span>,
  ]);
  const [notification, defaultSetNotification] = useState(
    notificationDefaultState
  );
  const [showMaturedContent, setShowMaturedContent] = useState(false);

  const setNotification = useCallback(
    (payload) => defaultSetNotification(payload ?? notificationDefaultState),
    [defaultSetNotification]
  );

  const [state, setState] = useState({ ...defaultState });
  const resumeBuilderState = useMemo(() => ({ state, setState }), [state]);
  const resetState = useCallback(() => {
    setState({ ...defaultState });
  }, []);

  const [milestones, setMilestones] = useState({ ...defaultMilestones });
  const resumeMilestoneList = useMemo(
    () => ({ milestones, setMilestones }),
    [milestones]
  );
  const resetMilestones = useCallback(() => {
    setMilestones({ ...defaultMilestones });
  }, []);

  const [previewImage, setPreviewImage] = useState("");
  const resumePreviewImage = useMemo(
    () => ({ previewImage, setPreviewImage }),
    [previewImage]
  );

  const [route, setRoute] = useState({
    to: "",
    from: "",
    previousProfileVisit: "",
  });

  const [following, setFollowing] = useState([]);
  const followingList = useMemo(
    () => ({ following, setFollowing }),
    [following]
  );
  const [followers, setFollowers] = useState([]);
  const followerList = useMemo(
    () => ({ followers, setFollowers }),
    [followers]
  );

  const [metaData, setMetaData] = useState({
    id: null,
    name: null,
    description: null,
    imageUrl: null,
    url: null,
    title:null
  });

  const updateMetaData = (data) => {
    setMetaData(data);
  };

  return (
    <Context.Provider
      value={{
        alerts,
        setAlerts,
        resetState,
        notification,
        resetMilestones,
        setNotification,
        showMaturedContent,
        setShowMaturedContent,
        route,
        setRoute,
        ...resumeBuilderState,
        ...resumeMilestoneList,
        ...resumePreviewImage,
        ...followingList,
        ...followerList,
        updateMetaData,
        metaData
      }}>
      {children}
    </Context.Provider>
  );
};
