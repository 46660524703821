import EyeIconSVG from "assets/svg/eye-icon";
import Button from "components/button/button";
import React from "react";
import TemplateOne from "./template-one";
import TemplateTwoPageOne from "./template-two/page-one";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import TemplateTwoPageTwo from "./template-two/page-two";
import TemplateFourPageTwo from "./template-four/page-two";
import TemplateFourPageOne from "./template-four/page-one";
import _ from "lodash";
import TemplateThreePageOne from "./template-three/page-one";
import TemplateThreePageTwo from "./template-three/page-two";

/** TEAM/GROUP TEMPLATES */
import TemplateOneTG from "./template-one-tg";
import TemplateTwoPageOneTG from "./template-two-tg/page-one";
import TemplateThreePageOneTG from "./template-three-tg/page-one";
import TemplateFourPageOneTG from "./template-four-tg/page-one";
import { useHistory } from "react-router-dom";

export default function ResumePreview({
  state = {},
  previewImage,
  milestones = {},
  primaryColor,
  secondaryColor,
  tertiaryColor,
  quarternary,
  page,
  setPage,
  setSelectedTab = () => {},
  profile,
}) {
  const history = useHistory();
  const [totalPage, setTotalPage] = React.useState(1)
  const onChangePage = (staticPage,command) => {
    // if(profile?.profile_type?.type === "group" || profile?.profile_type?.type === "team"){
      if(command === 'increment' && page < totalPage){
        if(page >0 ){
          setPage((prev)=>prev+1);
          return;
        }
      }
      if(page > 1){
        setPage((prev)=>prev-1);
      }
    // }else{
    //   setPage(staticPage);
    // }
   
    
  };

  return (
    <div className={`w-full flex justify-center${profile?.profile_type?.type === "group" || profile?.profile_type?.type === "team" ? " resume-tg" : ""}`}>
      {/* header buttons */}
      <div className="w-full max-w-screen-9sm grid gap-y-6 pl-12">
        <div className="w-full flex justify-between">
          <Button
            buttonName="Change Template"
            buttonTextClass="text-base font-semibold text-lightBlack"
            buttonRadius="6px"
            buttonClass="bg-template px-3.5 py-3.4"
            onClick={() => setSelectedTab("template")}
          />
          <Button
            buttonName="See Full Preview"
            buttonTextClass="text-base font-semibold text-primary flex flex-row items-center gap-x-2.5"
            buttonRadius="6px"
            buttonClass="bg-white px-3.5 py-3.4"
            onClick={() =>
              history.push(`/resume/preview`)
            }
            iconPosition="left"
            icon={<EyeIconSVG />}
          />
        </div>
        {/* body */}
        {state.templateName === "Template Name 1" && (
          profile?.profile_type?.type === "group" || profile?.profile_type?.type === "team"
            ? <TemplateOneTG
                {...{
                  page,
                  previewImage,
                  milestones,
                  primaryColor,
                  secondaryColor,
                  tertiaryColor,
                  profile,
                }}
                details={state.more}
                personal={state.personal}
                social={state.social}
                show_image_milestone = {state.show_milestone_image}
                setTotalPage={setTotalPage}
              />
              : <TemplateOne
                  {...{
                    page,
                    previewImage,
                    milestones,
                    primaryColor,
                    secondaryColor,
                    tertiaryColor,
                    profile,
                  }}
                  details={state.more}
                  personal={state.personal}
                  social={state.social}
                  show_image_milestone = {state.show_milestone_image}
                  setTotalPage={setTotalPage}
                />
        )}
        {state.templateName === "Template Name 2" && (
          <>
            {
              profile?.profile_type?.type === "group" || profile?.profile_type?.type === "team"
                ? <TemplateTwoPageOneTG
                    {...{
                      page,
                      previewImage,
                      milestones,
                      primaryColor,
                      secondaryColor,
                      tertiaryColor,
                      quarternary,
                      profile,
                    }}
                    color={state.color}
                    active={page === 1}
                    details={state.more}
                    personal={state.personal}
                    social={state.social}
                    show_image_milestone = {state.show_milestone_image}
                    setTotalPage={setTotalPage}
                  />
                  : <>
                  <TemplateTwoPageOne
                      {...{
                        page,
                        previewImage,
                        primaryColor,
                        secondaryColor,
                        tertiaryColor,
                        quarternary,
                        profile,
                        milestones,
                      }}
                      color={state.color}
                      active={page === 1}
                      details={state.more}
                      personal={state.personal}
                      social={state.social}
                      show_image_milestone = {state.show_milestone_image}
                      setTotalPage={setTotalPage}
                    />
                    {
                      page >=2 &&
                        <TemplateTwoPageTwo
                        {...{
                          page,
                          previewImage,
                          milestones,
                          primaryColor,
                          secondaryColor,
                          tertiaryColor,
                          quarternary,
                          profile,
                        }}
                        color={state.color} 
                        social={state.social}
                        show_image_milestone = {state.show_milestone_image}
                        setTotalPage={setTotalPage}
                      />
                    }
                  </>
            }
            
          </>
        )}
        {state.templateName === "Template Name 3" && (
          <>
            {
              profile?.profile_type?.type === "group" || profile?.profile_type?.type === "team"
                ? <TemplateThreePageOneTG
                    {...{
                      page,
                      previewImage,
                      primaryColor,
                      secondaryColor,
                      tertiaryColor,
                      quarternary,
                      milestones,
                      profile,
                    }}
                    color={state.color}
                    active={page === 1}
                    details={state.more}
                    personal={state.personal}
                    social={state.social}
                    setTotalPage={setTotalPage}
                  />
                  : <>
                    <TemplateThreePageOne
                      {...{
                        page,
                        previewImage,
                        primaryColor,
                        secondaryColor,
                        tertiaryColor,
                        quarternary,
                        profile,
                        milestones
                      }}
                      color={state.color}
                      active={page === 1}
                      details={state.more}
                      personal={state.personal}
                      social={state.social}
                      setTotalPage={setTotalPage}
                    />
                    {
                      page >=2 &&
                        <TemplateThreePageTwo
                          {...{
                            page,
                            previewImage,
                            milestones,
                            primaryColor,
                            secondaryColor,
                            tertiaryColor,
                            quarternary,
                          }}
                          color={state.color}
                          active={page === 2}
                          details={state.more}
                          personal={state.personal}
                          social={state.social}
                          show_image_milestone = {state.show_milestone_image}
                          setTotalPage={setTotalPage}
                        />
                    }
                  </>
            }

          </>
        )}
        {state.templateName === "Template Name 4" && (
          <>
            {
              profile?.profile_type?.type === "group" || profile?.profile_type?.type === "team"
                ? <TemplateFourPageOneTG
                    {...{
                      page,
                      totalPage,
                      previewImage,
                      primaryColor,
                      secondaryColor,
                      tertiaryColor,
                      quarternary,
                      milestones,
                      profile,
                    }}
                    color={state.color}
                    active={page === 1}
                    details={state.more}
                    personal={state.personal}
                    social={state.social}
                    setTotalPage={setTotalPage}
                  />
                  : <>
                    <TemplateFourPageOne
                      {...{
                        page,
                        totalPage,
                        previewImage,
                        primaryColor,
                        secondaryColor,
                        tertiaryColor,
                        quarternary,
                        profile,
                        milestones
                      }}
                      color={state.color}
                      active={page === 1}
                      details={state.more}
                      personal={state.personal}
                      social={state.social}
                      setTotalPage={setTotalPage}
                    />
                    {
                      page >= 2 &&
                        <TemplateFourPageTwo
                        {...{
                          page,
                          totalPage,
                          previewImage,
                          milestones,
                          primaryColor,
                          secondaryColor,
                          tertiaryColor,
                          quarternary,
                        }}
                        color={state.color}
                        active={page === 2}
                        details={state.more}
                        personal={state.personal}
                        social={state.social}
                        show_image_milestone = {state.show_milestone_image}
                        setTotalPage={setTotalPage}
                      />
                    }
                  </>
            }
          </>
        )}
        {/* end body */}
        {/* start page */}
        <div className="w-full">
          {!_.isEmpty(state.templateName) && (
            <div className="w-full flex flex-row justify-between items-center">
              <p className="text-sm text-lightBlack font-semibold">
                Page {page} of {totalPage}
                {/* {
                profile?.profile_type?.type === "group" || profile?.profile_type?.type === "team" ? totalPage : 2} */}
                 
              </p>
              <div className="flex flex-row space-x-2.5">
                <button
                  className={`w-6 h-6 bg-lightBlack rounded-full flex items-center justify-center ${
                    page === 1 ? "opacity-40" : ""
                  }`}
                  onClick={() => onChangePage(1,'decrement')}>
                  <FaChevronLeft className="text-white margin-auto w-2.5 h-2.5" />
                </button>
                <button
                  className={`w-6 h-6 bg-lightBlack rounded-full flex items-center justify-center ${
                    page < totalPage ? "" : "opacity-40"
                  }`}
                  onClick={() => onChangePage(2,'increment')}>
                  <FaChevronRight className="text-white margin-auto w-2.5 h-2.5" />
                </button>
              </div>
            </div>
          )}
        </div>
        {/* end page */}
      </div>
    </div>
  );
}
