import Lottie from "lottie-react";
import React from "react";
import Spinner from "assets/json/spinner.json";

const LoadMore = ({ result, requesting, initialize, loadMore, selectedTab }) => {
  return (
    <>
      {requesting && !initialize && loadMore && selectedTab === 'milestones' && (
        <div className="flex flex-col justify-center items-center my-20">
          <Lottie animationData={Spinner} className="w-16 h-16" />
          <p className="text-md font-semibold text-gray-500">Load More</p>
        </div>
      )}
      {!result && !requesting && !initialize && !loadMore && selectedTab === 'milestones' && (
        <div className="flex justify-center my-10">
          <p className="text-gray-400">No result found.</p>
        </div>
      )}
      {result && !requesting && !initialize && !loadMore && selectedTab === 'milestones' && (
        <div className="flex justify-center my-10">
          <p className="text-gray-400">No more items to load.</p>
        </div>
      )}
    </>
  );
};

export default LoadMore;
