import Button from "components/button/button";
import DatePicker from "components/datepicker/datepicker";
import Input from "components/input/input";
import CreatableSelect from "components/select/creatable";
import Select from "components/select/select";
import TextArea from "components/textarea/textarea";
import React, { useEffect, useState } from "react";
import { useOccupations } from "redux/modules/occupation/hooks";
import { urlSerializer } from "utils/utils";
import { useParams } from "react-router-dom";
import _ from "lodash";
import PopInfo from "components/info-popup/pop-info";

export default function MoreInfoPanel(props) {
  const { http } = global.services;
  const { details, setState, setInitialMount, onNext, onBack, profile } = props;
  const { profileId } = useParams();
  const [fields, setFields] = useState({
    personal: {},
  });

  const {
    refresh: refreshOccupation,
    initialize: initializeOccupation,
    occupations,
    setOccupations,
  } = useOccupations();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      more: {
        ...details,
        [name]: value,
      },
    }));

    setInitialMount(false);
  };

  const handleChangeOccupation = (e) => {
    const { name, value } = e.target;
    const mappedValues = (value || []).map((obj) => {
      return obj.label;
    });
    setState((prev) => ({
      ...prev,
      more: {
        ...details,
        [name]: mappedValues,
      },
    }));

    setInitialMount(false);
  };

  const getOccupations = async () => {
    try {
      const url = urlSerializer("public/occupations", {
        user_profile_id: profileId,
      });
      const { data } = await http.get(url);
      const options = data.map((opt) => ({
        label: opt.name,
        value: opt.id,
      }));
      setOccupations(options);
    } catch (err) {
      console.error("getOccupations", err);
    }
  };

  useEffect(() => {
    if (details) {
      setFields({
        personal: {
          birthdate: {
            label: "Formation Date",
            type: "datepicker",
            value: new Date(details.birthdate),
            options: [],
          },
          birth_location: {
            label: "Formation Location",
            type: "text",
            value: details.birth_location,
            options: [],
          },
          phone_number: {
            label: "Phone",
            type: "text",
            value: details.phone_number,
            options: [],
          },
          email: {
            label: "Email Address",
            type: "text",
            value: details.email,
            options: [],
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details]);

  useEffect(() => {
    if (initializeOccupation) getOccupations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshOccupation, initializeOccupation]);

  return (
    <div className="w-full h-full border-r border-resumeBorder">
      <div
        className="w-full overflow-auto custom-scroll"
        style={{ height: "calc(100vh - 174px)" }}>
        <div className="w-full px-15.5 py-10.6">
          <div className="w-full flex flex-row gap-x-2 items-center">
            <h1 className="text-lightBlack text-xl font-semibold">More Info</h1>
            <PopInfo containerClass="relative" type="resume_more_info" />
          </div>
          <div className="w-full mt-6 mb-7">
            <TextArea
              formContainer="w-full"
              label="Bio"
              defaultValue={details.bio}
              name="bio"
              inputClass="p-4.5 text-base leading-6 text-lightBlack"
              rows={4}
              type="text"
              onChange={handleInputChange}
            />
          </div>
          <div className="w-full grid grid-cols-2 gap-7">
            {Object.keys(fields.personal).map((key) => (
              <FormBuilder
                key={key}
                label={fields.personal[key].label}
                type={fields.personal[key].type}
                name={key}
                defaultValue={fields.personal[key].value}
                placeholder={fields.personal[key].label}
                options={fields.personal[key].options}
                onChange={handleInputChange}
                profile={profile}
              />
            ))}
          </div>
        </div>
      </div>
      {/* footer */}
      <div className="w-full py-4 px-6 border-t border-resumeBorder">
        <div className="w-full flex justify-end space-x-5">
          <Button
            buttonName="Back"
            buttonTextClass="text-sm font-semibold text-lightBlack"
            buttonRadius="6px"
            buttonClass="bg-thBg px-17.5 py-5"
            onClick={onBack}
          />
          <Button
            buttonName="Next"
            buttonTextClass="text-sm font-semibold text-white"
            buttonRadius="6px"
            buttonClass="bg-primary px-17.5 py-5"
            onClick={onNext}
          />
        </div>
      </div>
    </div>
  );
}

function FormBuilder({
  type,
  name = "",
  label = "",
  defaultValue = "",
  placeholder = "",
  onChange = () => {},
  changeOccupation = () => {},
  options = [],
  profile
}) {
  if (type === "text") {
    return (
      <Input
        outsideLabel={label}
        inputContainerClass="w-full"
        defaultValue={defaultValue}
        name={name}
        placeholder={placeholder}
        outsideLabelClass="text-sm font-bold text-darkerGray"
        noLabelPaddingY="1rem"
        type="text"
        onChange={onChange}
      />
    );
  } else if (type === "select") {
    let selectValue = null;
    if (defaultValue) {
      selectValue = { label: defaultValue, value: defaultValue };
    }
    return (
      <Select
        label={label}
        formContainer="w-full"
        options={options}
        placeholder={placeholder}
        name={name}
        defaultValue={selectValue}
        height={58}
        onChange={(e) => onChange({ target: { name: name, value: e.value } })}
      />
    );
  } else if (type === "datepicker") {
    return (
      <DatePicker
        label={label}
        formContainer="w-full"
        icon="default"
        selected={defaultValue ? new Date(defaultValue) : null}
        onChange={(date) =>
          onChange({ target: { name: name, value: new Date(date) } })
        }
        placeholderText={placeholder}
        profile={profile}
      />
    );
  } else if (type === "textarea") {
    return (
      <TextArea
        formContainer="w-full"
        label={label}
        defaultValue={defaultValue}
        name={name}
        inputClass="p-4.5 text-base leading-6 text-lightBlack"
        rows={4}
        type="text"
        onChange={onChange}
      />
    );
  } else if (type === "creatable") {
    // ======= default value =============
    let selectDefaultValue = [];
    if (name === "occupations") {
      selectDefaultValue = (defaultValue || []).map((value) => {
        let obj = _.find(options, { label: value });
        return { label: value, value: obj?.value };
      });
    }
    return (
      <CreatableSelect
        isMulti
        label={label}
        options={options}
        placeholder={placeholder}
        name={name}
        value={selectDefaultValue || null}
        height={58}
        onChange={(e) => changeOccupation({ target: { name: name, value: e } })}
      />
    );
  }
}
