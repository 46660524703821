export default function CreditCardIconSVG({ className = "", fill = "#0C6ED1" }) {
    return (
        <svg
            width="36"
            height="26"
            viewBox="0 0 36 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}>
            <path
                d="M33 0.500061H3C2.33696 0.500061 1.70107 0.763453 1.23223 1.23229C0.763392 1.70113 0.5 2.33702 0.5 3.00006V23.0001C0.5 23.6631 0.763392 24.299 1.23223 24.7678C1.70107 25.2367 2.33696 25.5001 3 25.5001H33C33.663 25.5001 34.2989 25.2367 34.7678 24.7678C35.2366 24.299 35.5 23.6631 35.5 23.0001V3.00006C35.5 2.33702 35.2366 1.70113 34.7678 1.23229C34.2989 0.763453 33.663 0.500061 33 0.500061ZM19.25 20.5001H16.75C16.4185 20.5001 16.1005 20.3684 15.8661 20.1339C15.6317 19.8995 15.5 19.5816 15.5 19.2501C15.5 18.9185 15.6317 18.6006 15.8661 18.3662C16.1005 18.1318 16.4185 18.0001 16.75 18.0001H19.25C19.5815 18.0001 19.8995 18.1318 20.1339 18.3662C20.3683 18.6006 20.5 18.9185 20.5 19.2501C20.5 19.5816 20.3683 19.8995 20.1339 20.1339C19.8995 20.3684 19.5815 20.5001 19.25 20.5001ZM29.25 20.5001H24.25C23.9185 20.5001 23.6005 20.3684 23.3661 20.1339C23.1317 19.8995 23 19.5816 23 19.2501C23 18.9185 23.1317 18.6006 23.3661 18.3662C23.6005 18.1318 23.9185 18.0001 24.25 18.0001H29.25C29.5815 18.0001 29.8995 18.1318 30.1339 18.3662C30.3683 18.6006 30.5 18.9185 30.5 19.2501C30.5 19.5816 30.3683 19.8995 30.1339 20.1339C29.8995 20.3684 29.5815 20.5001 29.25 20.5001ZM3 6.75006V3.00006H33V6.75006H3Z"
                fill={fill}
            />
        </svg>
    )
}