import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "./actions";

export const useMilestones = () => {
  const {
    featured,
    organizeBy,
    jobRole,
    category,
    isRequesting,
    milestones = [],
    featuredMilestone = {},
    currentMilestones = [],
    pastMilestones = [],
    pinnedMilestones = [],
    total,
    limit,
    loadMore,
    refresh,
    initialize,
    playMusic,
  } = useSelector((state) => state.milestone);

  const dispatch = useDispatch();

  const setRefreshMilestones = useCallback(
    (payload) => dispatch(actions.setRefreshMilestones(payload)),
    [dispatch]
  );

  const getMilestones = useCallback(
    (payload) => dispatch(actions.getMilestones(payload)),
    [dispatch]
  );

  const setFeaturedMilestone = useCallback(
    (payload) => dispatch(actions.setFeaturedMilestone(payload)),
    [dispatch]
  );

  const setCurrentMilestones = useCallback(
    (payload) => dispatch(actions.setCurrentMilestones(payload)),
    [dispatch]
  );

  const setPastMilestones = useCallback(
    (payload) => dispatch(actions.setPastMilestones(payload)),
    [dispatch]
  );

  const setPinMilestones = useCallback(
    (payload) => dispatch(actions.setPinMilestones(payload)),
    [dispatch]
  );

  const updatePinMilestones = useCallback(
    (payload) => dispatch(actions.updatePinMilestones(payload)),
    [dispatch]
  );

  const setMilestones = useCallback(
    (payload) => dispatch(actions.setMilestones(payload)),
    [dispatch]
  );

  const setIsFeatured = useCallback(
    (payload) => dispatch(actions.setIsFeatured(payload)),
    [dispatch]
  );

  const setOganizeBy = useCallback(
    (payload) => dispatch(actions.setOganizeBy(payload)),
    [dispatch]
  );

  const setJobRole = useCallback(
    (payload) => dispatch(actions.setJobRole(payload)),
    [dispatch]
  );

  const setCategory = useCallback(
    (payload) => dispatch(actions.setCategory(payload)),
    [dispatch]
  );

  const setFilters = useCallback(
    (payload) => dispatch(actions.setFilters(payload)),
    [dispatch]
  );

  const setClearFilters = useCallback(
    (payload) => dispatch(actions.setClearFilters(payload)),
    [dispatch]
  );

  const addMilestones = useCallback(
    (payload) => dispatch(actions.addMilestones(payload)),
    [dispatch]
  );

  const updateMilestones = useCallback(
    (payload) => dispatch(actions.updateMilestones(payload)),
    [dispatch]
  );

  const updateFeaturedMilestone = useCallback(
    (payload) => dispatch(actions.updateFeaturedMilestone(payload)),
    [dispatch]
  );

  const updateCurrentMilestone = useCallback(
    (payload) => dispatch(actions.updateCurrentMilestone(payload)),
    [dispatch]
  );

  const updatePastMilestone = useCallback(
    (payload) => dispatch(actions.updatePastMilestone(payload)),
    [dispatch]
  );

  const deleteMilestone = useCallback(
    (payload) => dispatch(actions.deleteMilestone(payload)),
    [dispatch]
  );

  const finishGetMilestone = useCallback(
    (payload) => dispatch(actions.finishGetMilestone(payload)),
    [dispatch]
  );

  const setPLayMusic = useCallback(
    (payload) => dispatch(actions.setPLayMusic(payload)),
    [dispatch]
  );

  return {
    featured,
    organizeBy,
    jobRole,
    category,
    isRequesting,
    milestones,
    featuredMilestone,
    currentMilestones,
    pastMilestones,
    pinnedMilestones,
    total,
    limit,
    loadMore,
    refresh,
    initialize,
    playMusic,
    setRefreshMilestones,
    getMilestones,
    setFeaturedMilestone,
    setCurrentMilestones,
    setPastMilestones,
    setPinMilestones,
    setMilestones,
    setIsFeatured,
    setOganizeBy,
    setJobRole,
    setCategory,
    setFilters,
    setClearFilters,
    addMilestones,
    updateMilestones,
    updateFeaturedMilestone,
    updateCurrentMilestone,
    updatePastMilestone,
    deleteMilestone,
    finishGetMilestone,
    setPLayMusic,
    updatePinMilestones
  };
};
