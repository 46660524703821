import React, { useEffect, useState, useLayoutEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import LineGraph from "./component/LineGraph";
import Select from "components/select/select";
import { getMonths, getDays } from "utils/constant";
import { urlSerializer } from "utils/utils";
import { SET_PROFILE_VIEWS } from "redux/modules/profile-view/types";
import moment from "moment";
import { gsap } from "gsap";
import SubscriptionViews from "./component/subscription-views";
import { SubscriptionContext } from "context/subscription";
import PopInfo from "components/info-popup/pop-info";

export default function SettingsViews({ userData = null }) {
  const months = moment.months();
  const { http } = global.services;

  const [loading, setLoading] = useState(false);
  const [dateSelected, setDateSelected] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  });
  const { isSubscribed, subscription } = useContext(SubscriptionContext);

  const {
    views = [],
    initialize: initializeProfileViews,
    refresh: refreshProfileViews,
  } = useSelector((state) => state.profileView);

  const getProfileViews = async () => {
    setLoading(true);

    try {
      const url = urlSerializer("public/profile-views", {
        user_profile_id: userData.profile.id,
        month: dateSelected.month,
        year: dateSelected.year,
      });
      const { data } = await http.get(url);
      const profileViews = {
        results: data.results,
        today: data.today,
        past30: data.past_30,
        total: data.total,
      };
      dispatch({
        type: SET_PROFILE_VIEWS,
        payload: profileViews,
      });
    } catch (err) {
      console.log("getProfileViews", err);
    } finally {
      setLoading(false);
    }
  };

  const onChangeDate = (e) => {
    const date = e.value.split(" ");
    const month = months.findIndex((value) => value === date[0]);
    setDateSelected({ year: date[1], month: month + 1 });
  };

  const dispatch = useDispatch();

  /**
   * This effect will run every time when
   * initialize and refresh state change
   */
  useEffect(() => {
    if (initializeProfileViews) getProfileViews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshProfileViews, initializeProfileViews]);

  /**
   * This effect will run every time when
   * initialize and refresh state change
   */
  useEffect(() => {
    getProfileViews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateSelected]);

  useLayoutEffect(() => {
    gsap.fromTo(
      ".page-view-heading",
      { y: -20, opacity: 0 },
      { y: 0, opacity: 1 }
    );
    gsap.fromTo(
      ".page-view-graph",
      { scale: 0, opacity: 0 },
      { scale: 1, opacity: 1 }
    );
    gsap.fromTo(".page-view-item", { x: 50, opacity: 0 }, { x: 0, opacity: 1 });
  }, []);

  return isSubscribed ? (
    <div className="mt-6 w-full container-max-width bg-white rounded-md">
      {/* content */}
      <div className="w-full pt-12.5 pb-10 px-7.5 1md:px-24.5">
        <div className="w-full space-y-12.5 3md:flex 3md:items-center 3md:space-x-12.5 3md:space-y-0">
          <div className="w-full 3md:w-15/20">
            {/* headers */}
            <div className="page-view-heading w-full 2sm:flex 2sm:justify-between 2sm:items-center space">
              <div className="w-full flex flex-row gap-x-2 items-center">
                <h1 className="text-lg leading-5.5 text-lightBlack font-semibold">
                  Page Views/Visitors
                </h1>
                <PopInfo
                  containerClass="relative"
                  type="page_views"
                  responsive={{
                    left: "185px",
                    right: "185px",
                  }}
                />
              </div>

              <div className="w-full mt-4 3md:w-6/20">
                <Select
                  options={getMonths()}
                  placeholder="Select Date"
                  onChange={(e) => onChangeDate(e)}
                  height={40}
                  backgroundColor="#F2F2F2"
                  borderColor="#F2F2F2"
                  isDisabled={loading}
                />
              </div>
            </div>
            {/* graph */}
            <div className="page-view-graph mt-7.5 w-full">
              <LineGraph
                labels={getDays(dateSelected.year, dateSelected.month)}
                serverData={views.results}
                year={dateSelected.year}
                month={dateSelected.month}
              />
            </div>
          </div>
          {/* right side (summary)*/}
          <div className="w-full 3md:w-5/20">
            <div className="w-full gap-y-14 grid 1sm:grid-cols-3 1sm:gap-x-14 1sm:gap-y-0 3md:grid-cols-1 3md:gap-y-14 3md:gap-x-0">
              <div className="2sm:page-view-item w-full text-center 3md:text-left">
                <p className="text-2xl leading-7.4 font-bold tracking-tighter text-lightBlack">
                  {views.today ?? 0}
                </p>
                <p className="text-base leading-6 text-darkerGray">
                  Page Views Today
                </p>
              </div>

              <div className="page-view-item w-full text-center 3md:text-left">
                <p className="text-2xl leading-7.4 font-bold tracking-tighter text-lightBlack">
                  {views.past30 ?? 0}
                </p>
                <p className="text-base leading-6 text-darkerGray">
                  Page Views last 30 days
                </p>
              </div>

              <div className="page-view-item w-full text-center 3md:text-left">
                <p className="text-2xl leading-7.4 font-bold tracking-tighter text-lightBlack">
                  {views.total ?? 0}
                </p>
                <p className="text-base leading-6 text-darkerGray">
                  Total Page Views
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <SubscriptionViews
      year={dateSelected.year}
      month={dateSelected.month}
      subscription={subscription}
    />
  );
}
