import CalendarIconSVG from "assets/svg/calendar-icon";
import ClockIconSVG from "assets/svg/clock-icon";
import React from "react";
import DatePickerComponent from "react-datepicker";
import { GoChevronRight, GoChevronLeft } from "react-icons/go";

const listIcons = {
  default: <CalendarIconSVG className="absolute top-11.5 right-2" />,
  noLabel: <CalendarIconSVG className="absolute top-4.5 right-4.5" />,
  clock: <ClockIconSVG className="absolute top-11 right-2" />,
};

export default function DatePicker({
  selected = null,
  onChange = () => {},
  label = "",
  iconLeft = true,
  labelClass = "text-sm font-bold text-darkerGray",
  icon = "default",
  errorMessage = null,
  errorMessagePosition = "bottom",
  formContainer = "form-input-container",
  profile,
  ...props // make sure this pops here is for input only
}) {
  return (
    <div className={`relative ${formContainer}`}>
      {label && (
        <label htmlFor={label} className={labelClass}>
          {label}
        </label>
      )}
      {errorMessage && errorMessagePosition === "top" && (
        <p className="text-red text-xs mb-0.5">* {errorMessage}</p>
      )}
      {iconLeft && listIcons[icon]}
      <DatePickerComponent 
        dateFormat={!profile?.dateFormat ? "MMMM d yyyy" : "d MMMM yyyy"}
        className={`${label ? "mt-2" : ""}`}
        selected={selected}
        onChange={onChange}
        {...props}
        nextMonthButtonLabel={
          <GoChevronRight className="w-5 h-5 text-gray-600" />
        }
        previousMonthButtonLabel={
          <GoChevronLeft className="w-5 h-5 text-gray-600" />
        }
      />
      {errorMessage && errorMessagePosition === "bottom" && (
        <p className="text-red text-xs mb-0.5">* {errorMessage}</p>
      )}
    </div>
  );
}
