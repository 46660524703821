import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from './actions'

export const useStates = () => {

    const {
        initialize,
        refresh,
        states = [],
    } = useSelector((state) => state.states);

    const dispatch = useDispatch();

    const setRefreshStates = useCallback(
        (payload) => dispatch(actions.setRefreshStates(payload)),
        [dispatch]
    );

    const setStates = useCallback(
        (payload) => dispatch(actions.setStates(payload)),
        [dispatch]
    );

    return {
        refresh,
        initialize,
        states,
        setRefreshStates,
        setStates
    };
} 