import React from "react";
import Modal from "components/global/modal";
import CloseIconSVG from "assets/svg/close-icon";
import { InvitationAccepted } from "constants/images";
import Button from "components/button/button";

export default function InvitationDeclinedModal({
  notification,
  hide,
  success = true,
  ...props
}) {
  const { from, user_access } = notification.user_access_notification;

  return (
    <Modal
      width="w-19/20 7sm:w-460"
      contentClassName="rounded-lg overflow-auto custom-scroll"
      wrapperClassName="overflow-visible"
      footerClass="border-t border-gray"
    >
      <div className="w-full">
        <div className="w-full p-6">
          {/* header */}
          <div className="w-full flex justify-end">
            <button onClick={hide}>
              <CloseIconSVG />
            </button>
          </div>
          {/* end header */}
          <div className="w-full">
            <div className="w-24.5 h-24.5 mx-auto">
              <img
                src={InvitationAccepted}
                alt="invitation-sent-access"
                className="w-24.5 h-24.5"
              />
            </div>
            <div className="w-full mt-6 text-center leading-6 px-4">
              <p className="text-xl text-lightBlack font-semibold">
                Profile Invitation Declined
              </p>
              <p className="mt-3 text-lightBlack leading-6.5">
                You have declined the invitation to be an{" "}
                {user_access?.permission?.name} to profile{" "}
                {from?.username}.
              </p>
            </div>
            <div className="w-full mt-9 flex items-center space-x-2">
              <Button
                buttonWidth="100%"
                buttonName="Got it"
                buttonTextClass="text-sm text-lightBlack font-semibold leading-4.4"
                buttonRadius="6px"
                buttonClass="bg-thBg py-4"
                onClick={hide}
              />
            </div>
          </div>
        </div>
        {/* end content */}
      </div>
    </Modal>
  );
}
