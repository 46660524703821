import React, { useCallback, useState } from "react";
import Modal from "components/global/modal";
import Button from "components/button/button";
import ModalHeader from "components/global/modal-header";
import LoadingIndicator from "assets/svg/loading-indicator";
import _ from "lodash";
import { useDropzone } from "react-dropzone";
import CustomCheckbox from "components/checkbox/custom-checkbox";
import AddPhotoDropZone from "assets/svg/add-photo-dropzone-svg";
import { Close } from "assets/svg/sidebar/icons";
import { KB, MB, maxUploadSize } from "utils/constant";
import { useDialogHook } from "utils/customhooks";
import UploadSizeModal from "components/file-upload/upload-size-modal";

export default function AddPhotoModal({
  hide,
  userData,
  profile,
  onUploadImagesVideos = async () => {},
  ...props }) {
  const { http } = global.services;

  const [photo, setPhoto] = useState(null);
  const [preview, setPreview] = useState([]);
  const [adultContent, setAdultContent] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});
  const uploadSizeModal = useDialogHook(UploadSizeModal);

  const onDrop = useCallback(acceptedFiles => {
    for(const file of acceptedFiles) {
      if (file.size > maxUploadSize) {
        showUploadSizeModalError();
        return;
      }
    }

    setPhoto(acceptedFiles);
  }, []);

  React.useEffect(() => {
    console.log('useEffect photo: ', photo);
    if (photo) {
      setPhotoPreview(photo);
    }
  }, [photo]);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    noClickL: true,
    accept: {
      'image/*': [],
    }
  });

  const setPhotoPreview = (_photo = photo) => {
    /**
     * @type {Array<{ id: string, name: string, size: number, path: string}>}
     */
    const _previews = [];
    _photo.forEach((file, index) => {
      let size = _photo[index].size;
  
      if (size > MB) {
        size /= MB;
        size = `${Math.round(size * 10) / 10}MB`;
      } else if (size > KB) {
        size /= KB;
        size = `${Math.round(size * 10) / 10}KB`;
      } else {
        size = `${size}B`;
      }

      _previews.push({
        id: index,
        name: _photo[index].name,
        size: size,
        path: URL.createObjectURL(photo[index])
      });
      
    });

    // setPreview({
    //   name: photo[0].name,
    //   size: size,
    //   path: URL.createObjectURL(photo[0])
    // });
    setPreview(_previews);


  }

  /**
   * 
   * @param {string} index 
   */
  const removeUploadedPhoto = (index) => {
    const copy = photo;
    console.log('state', copy);
    const updatedPhotos = photo.filter((_, i) => i !== index); 
    console.log('newState: ', updatedPhotos);
    setPhoto(updatedPhotos);
  }

  const submit = async () => {
    setSubmitLoading(true);

    let errors = {};
    setErrorMessages(errors);

    try {
      let payload = new FormData();
      for(let i = 0; i<photo.length; i++) {
        payload.append(`media[${i}]`, photo[i]);
      }
      payload.append("is_adult_content", adultContent);
      const upload = await onUploadImagesVideos(payload);

      if (upload === 'success') {
        hide(true);
      }
    } catch(err) {
      if (err.status === 422) {
        errors = {
          ...errors,
          ...err.data?.errors,
        };

        console.log('::AddPhotoModal > 422 errors', errors);

        return setErrorMessages((prev) => ({
          ...prev,
          ...errors
        }));
      }

      console.log('::AddPhotoModal > error', err);
      setSubmitLoading(false);
    } finally {
      setSubmitLoading(false);
    }
  };

  const toggleAdultContentCheckbox = (e) => {
    setAdultContent(!e.target.checked);
  }

  const showUploadSizeModalError = () => {
    uploadSizeModal({}, (callback) => {
      console.log('callback', callback)
    })
  }

  return (
    <Modal
      width="w-full md:w-15/20 2md:w-11/20 lg:w-9/20 xl:w-6/20"
      contentClassName="rounded-lg overflow-auto custom-scroll"
      wrapperClassName="overflow-visible">
      <div className="w-full">
        {/* header */}
        <ModalHeader title="Add Photos" hide={hide} />
        {/* content */}
        <div className="px-8.5 pt-6 pb-4">
          {/* profile */}
          <div className="flex flex-column">
            {/* dropzone */}
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="add-photo-dropzone">
                <AddPhotoDropZone />
              </div>
            </div>

            {
              Array.isArray(preview) && preview.length > 0 && preview.map((preview, index) => (
                <div className="flex mt-4 items-center justify-center" key={preview.id || index}>
                  <div className="flex items-center p-2 space-x-4 add-photo-thumbnail">
                    <div className="add-photo-preview">
                      <img src={preview.path} width={70} height={70} />
                    </div>
                    <div className="flex flex-column add-photo-details">
                      <span className="add-photo-details__name">{preview.name.slice(0, 16) + "..."}</span>
                      <span className="add-photo-details__size">{preview.size}</span>
                    </div>
                    <div className="cursor-pointer" onClick={() => removeUploadedPhoto(preview.id || index)}>
                      <Close className="w-4 h-4" fill={"#333333"} />
                    </div>
                  </div>
                </div>
              ))
            }

            <div className="flex space-x-4.5 items-center mt-8 checkbox-v2">
              <div>
                <CustomCheckbox
                  label={
                    <p className="checkbox-text">
                      This photo does not contain adult content
                    </p>
                  }
                  name={"termsAndConditions"}
                  checked={!adultContent}
                  onChange={(e) => toggleAdultContentCheckbox(e)}
                  v2={true}
                />
              </div>
            </div>

            {/* Footer */}
            <div className="absolute left-0 w-full custom-modal-footer-separator border-t border-gray my-4"></div>
            <div className="w-full flex items-center justify-start 1sm:justify-end space-x-2 mt-10">
              <Button
                buttonName="Cancel"
                buttonClass="bg-thBg py-4 px-8 hover:bg-gray"
                buttonTextClass="text-black text-sm font-semibold"
                onClick={() => hide()}
                disabled={submitLoading}
              />
              <Button
                key="save-btn-category"
                buttonName="Upload"
                buttonClass="bg-primary py-4 px-8 hover:bg-darkPrimary"
                buttonTextClass="text-white text-sm font-semibold flex items-center"
                onClick={submit}
                disabled={submitLoading || !photo}
                loadingIcon={
                  submitLoading ? (
                    <LoadingIndicator className="text-white w-4 h-4 ml-2" />
                  ) : null
                }
              />
            </div>
          </div>
        </div>
        {/* end content */}
      </div>
    </Modal>
  );
}
