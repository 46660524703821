import React, { useState, useEffect } from "react";
import userActions from "redux/modules/user/actions";
import { connect } from "react-redux";
import { TabPanel, useTabs } from "react-headless-tabs";
import Button from "components/button/button";
import Input from "components/input/input";
import DatePicker from "components/datepicker/datepicker";
import Select from "components/select/select";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import toast from "react-hot-toast";
import LoadingIndicator from "assets/svg/loading-indicator";
import { randomExtension } from "utils/utils";
import TextArea from "components/textarea/textarea";
import update from "immutability-helper";
import FileUpload from "components/file-upload/file-upload";
import { FaMinus, FaPlus, FaPaperclip } from "react-icons/fa";
import CustomCheckbox from "components/checkbox/custom-checkbox"; 
import validator from "validator";
import { isValidHttpUrl } from "utils/constant";

const options = [
  { value: "file", label: "File", is_media:true },
  { value: "link", label: "Link", is_media:false }, 
];

const VerifyTeam = ({ userData, setUserData }) => {
  const history = useHistory();
  const { http } = global.services;
  const [selectedTab, setSelectedTab] = useTabs(["personal", "details"]); 
  const [loading, setLoading] = useState(false); 
  const [errors, setErrorMessages] = useState({});

  const [state, setState] = useState({
    proof: userData.profile?.written_proof || '',    
    condition: true,
    loading: false,
    validationErrors: null,
    profile: userData.profile
  });

  const [linkType, setLinkType] = useState([
    { details: "", subType: { value: "link", label: "Link", is_media:false }, value: "", },
  ]);
  
  useEffect(()=>{
    getTeam();
  },[]);

  const getTeam = async ()=>{
    const result = await http.get("public/team-details");
    if(result.status === 200){
      const data = result.data;
      if(data.length>0){
        let dataArrange = data.map((obj)=>{
          return {...obj, value: obj.attachment, subType: obj.type === 'file' ? options[0] : options[1]}
        });
        setLinkType(dataArrange);
        setState({...state,proof:data[0]?.profile?.written_proof || '' })
      }
    }
    
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
      validationErrors: { ...state.validationErrors, [name]: null },
    }));
  };

  const onTypeLink = (e, index) =>{
    const { name, value } = e.target;
    let payload = _.clone(linkType);
    let result = update(payload, {
      [index]: {
        [name]: { $set: value },
      },
    }); //remove
    setLinkType(result);

  }


  const onHandleSubmit = async () => {
    let verifiedUserData = null;  
    let errors = {};

    let payload = new FormData();
    payload.append("written_proof", state.proof);
    // payload.append("attachment", linkType);
    payload.append("user_id", userData.user.id);

    // media
    linkType.forEach(async (media, index) => {
      if (media?.type || media.subType) {  
        if (media.subType) {
          payload.append(`media[${index}][type]`, media.subType.value);
          if (media.subType.is_media) {
            payload.append(`media[${index}][is_media]`, true);
          } else {
            payload.append(`media[${index}][is_media]`, false);
          }
        }
        if (typeof media.value === "object") {
          if (Array.isArray(media.value)) {
            media.value.map(async (file, fileIndex) => {
              payload.append(`media[${index}][value][${fileIndex}]`, file);
            });
          } else {
            payload.append(`media[${index}][value][0]`, media.value);
          }
        } else {
          //validate
          if (!media?.subType?.is_media) { 
            if (media.value) {
              if (validator.isURL(media.value) && isValidHttpUrl(media.value)) {
                payload.append(`media[${index}][value]`, media.value);
              } else {
                errors = {
                  ...errors,
                  [`media.${index}.value`]: "Invalid url link.",
                };
              }
            } else {
              payload.append(`media[${index}][value]`, media.value);
            }
          } else {
            payload.append(`media[${index}][value]`, media.value);
          }
        }
        payload.append(`media[${index}][details]`, media.details);
        payload.append(`media[${index}][id]`, media.id || 0 );
      }
    });

    //return error
    if (!_.isEmpty(errors)) {
      return setErrorMessages((prev) => ({ ...prev, ...errors }));
    }


    try {
      setLoading(true);
      const result = await http.post("public/verify-team", payload);
      console.log(result)
      if (result.status === 202) {
        verifiedUserData = result.data;
        toast.success(verifiedUserData.message);

        setTimeout(() => {
          history.push(
            `${userData?.user?.username}-${randomExtension()}/profile/${
              state?.profile?.id
            }`
          );
        }, [1000]);
      }
    } catch (err) {
      console.log("error => ", err);
      toast.error("Something went wrong. Please contact adminstrator."); 
      const { data, status } = err;
      if (status === 422) {
        errors = data.errors;
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }

    console.log("errors => ", errors);
    setErrorMessages((prev) => ({ ...prev, ...errors }));
  };

  const onSelectTypeLink = (e, index) => {

    let type = options.find((item)=> e.value === item.value); 
    let isMedia = type.value === 'file' ? true : false;
    type = {...type, is_media:isMedia}; 
    console.log(type)
    let payload = _.clone(linkType);
    let result = update(payload, {
      [index]: {
        subType: { $set: type },
      },
    }); //remove 
    setLinkType(result);
  };

  const onAdd = () => {
    if (linkType.length === 6) return;

    let payload = _.clone(linkType);
    payload.push({ type: "", subType:{ value: "link", label: "Link", is_media:false }, value: "", details:"" });
    setLinkType(payload);
    console.log(payload)
  };

  const onUploadLink = async (e, index) => {
    let payload = _.clone(linkType);
    let result = update(payload, {
      [index]: {
        value: { $set: e },
      },
    }); //remove
    setLinkType(result);
  };

  const onRemove = (index) => {
    if (linkType.length === 1) return; 
    let payload = _.clone(linkType);
    let result = update(payload, { $splice: [[index, 1]] }); //remove
    setLinkType(result);
  };

  const handleUpdateCheckbox = (e) => {
    const isChecked = e.target.checked;
    setState({ ...state, condition: isChecked });
  };

  return (
    <>  
        <div className="mt-6 w-full bg-white rounded-md">
          {/* content */}
          <div className="pt-12.5 pb-10 px-7.5 1md:pl-24.5 1md:pr-24.5">
              <div className="w-full 3md:flex gap-8">
                <div className="w-full mb-5 3md:w-6/20 3md:mb-0">
                  <p className="text-lg text-lightBlack font-semibold">
                    Enter your proof
                  </p>
                  <p className="text-xs text-[#b9b9b9]">
                    Please provide written proof that you own or have authority to publish on 
                    behalf of this team or group
                  </p>
                </div>
                {/* forms */}
                <div className="w-full 3md:w-14/20">
                  <div className="w-full">
                  <TextArea
                    value={state?.proof || ""}
                    name="proof"
                    placeholder="Enter your proof"
                    labelClass="text-sm font-bold text-darkerGray marked"
                    type="text"
                    onChange={handleInputChange}
                    errorMessage={state.validationErrors?.proof}
                    rows={2}
                /> 
                  </div>
            
                </div>
              </div>
              <div className="w-full 3md:flex gap-8">
                <div className="w-full mb-5 3md:w-[43%] 3md:mb-0">
                  <p className="text-lg text-lightBlack font-semibold w-full">
                    Provide your Proof Files
                  </p> 
                </div>
                {/* forms */}
                <div className="w-full">
                <div className="flex font-bold text-sm">
                    <div className="w-32">File Type</div>
                    <div className="flex-1">Details</div>
                    <div className="flex-1">Attachment</div>
                </div>
                { 
                    linkType.map((obj, index) => {   
                        const { subType = {} } = obj;
                        const isMedia = subType.is_media ? true : false; 
                        return (
                            <div className="w-full flex gap-x-2">
                              <div className="w-28">
                                <Select
                                    // label="File Type"
                                    formContainer="flex-1"
                                    options={options}
                                    placeholder="Please Select"
                                    onChange={(e) => onSelectTypeLink(e, index)}
                                    height={58}
                                    value={options.filter((o) => o.value === subType.value)}
                                    name={`type-link-${index}`}
                                    errorMessage={errors[`media.${index}.type`]}
                                    className=""
                                />
                              </div>
                              <div className="flex-1">
                                <Input
                                        inputContainerClass="form-input-container"
                                        value={obj?.details}
                                        name="details"
                                        // outsideLabel="Details"
                                        placeholder="Details"
                                        outsideLabelClass="text-sm font-bold text-darkerGray"
                                        noLabelPaddingY="1rem"
                                        type="text" 
                                        onChange={(e) => onTypeLink(e, index)}
                                        errorMessage={errors[`media.${index}.details`]}
                                    />
                              </div> 
                              <div className="flex-1 relative">
                                {
                                    isMedia ? 
                                    <FileUpload
                                        // label="Attachment"
                                        image_name={obj.placeholder || ``}
                                        id={`singe-file-${index}`}
                                        formContainer="flex-1"
                                        showPreview={false}
                                        accept={".pdf,image/*,audio/*"}
                                        className="absolute top-1.5 right-2 bg-modalGray py-3.5 px-4 rounded-md text-lightBlack"
                                        icon={<FaPaperclip className="text-lg" />}
                                        onUpload={(e) => onUploadLink(e, index)}
                                        errorMessage={errors[`media.${index}.value`]}
                                    />
                                    :
                                <Input
                                    inputContainerClass="form-input-container"
                                    value={obj?.value}
                                    name="value"
                                    // outsideLabel="Attachment"
                                    placeholder="https://www.link.com/image1"
                                    outsideLabelClass="text-sm font-bold text-darkerGray"
                                    noLabelPaddingY="1rem"
                                    type="text" 
                                    onChange={(e) => onTypeLink(e, index)}
                                    errorMessage={errors[`media.${index}.value`]}
                                />
                                }
                                
                              </div>
                                <div className="flex mt-4 col-span-1">
                                    <button
                                    className="h-6.5 w-6.5 bg-lightBlack rounded-full"
                                    onClick={() => onRemove(index)}
                                    >
                                    <FaMinus className="m-auto text-white" />
                                    </button>
                                </div>
                            </div> 
                        );
                    })
                } 
                <Button
                    buttonName="Add Another File"
                    buttonTextClass="text-sm text-[#0c6ed1] font-semibold flex items-center"
                    iconPosition="left"
                    icon={<FaPlus className="mr-3" />}
                    buttonRadius="6px"
                    buttonClass="relative py-4 px-9 border-2 border-[#0c6ed1]"
                    onClick={onAdd}
                    />
                <div className="mt-4">
                    <CustomCheckbox
                        label={
                        <p className="mt-0.5 text-sm text-darkerGray">
                            I agree that my above information is checked with the
                            Issuer or Official Record Holder
                        </p>
                        }
                        name="condition"
                        checked={state.condition}
                        onChange={handleUpdateCheckbox}
                    />
                    </div>
               </div>
              </div>
            {/* details */} 
          </div>
          {/* footer */}
          <div className="py-5.5 px-7.5 1md:pl-24.5 1md:pr-56 border-t-2 border-gray">
            <div className="w-full flex justify-end">
              <Button
                buttonName={selectedTab === "personal" ? "Cancel" : "Back"}
                buttonTextClass="text-xs text-lightBlack font-semibold"
                buttonRadius="6px"
                buttonClass="relative py-4 px-7.5 bg-thBg mr-3.5"
                onClick={() => {
                  if (selectedTab === "personal") {
                    history.goBack();
                  } else {
                    setSelectedTab("personal");
                  }
                }}
              />
              <Button
                disabled={!state.condition}
                buttonName={"Complete"}
                buttonTextClass="text-xs text-white font-semibold"
                buttonRadius="6px"
                buttonClass="relative py-4 px-15 bg-primary"
                loading={loading ? 1 : 0}
                loadingIcon={
                  loading && (
                    <div className="absolute right-3 bottom-0 top-0 flex items-center">
                      <LoadingIndicator className="text-white w-5 h-5" />
                    </div>
                  )
                }
                onClick={()=>{onHandleSubmit()}}
              />
            </div>
          </div>
        </div> 
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (params) => {
      dispatch(userActions.setUserData(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyTeam);
