import React, { useState } from "react";
import Modal from "components/global/modal";
import Button from "components/button/button";
import ModalHeader from "components/global/modal-header";
// import { ProfileAvatar } from "constants/images";
import WYSIWYGEditor from "components/wysiwyg/wysiwyg";
import Select from "components/select/select";
import LoadingIndicator from "assets/svg/loading-indicator";
import toast from "react-hot-toast";
import _ from "lodash";

const MESSAGETYPE = [
  { value: "Availability check", label: "Availability check" },
  { value: "Invitation to audition", label: "Invitation to audition" },
  {
    value: "Invitation to submit a proposal",
    label: "Invitation to submit a proposal",
  },
  { value: "Offer", label: "Offer" },
  { value: "Other", label: "Other (write your own)" },
];

export default function SendMessageModal({ hide, userData, profile, ...props }) { 
  const { http } = global.services;
  const [message, setMessage] = useState(
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum platea est elit vulputate duis non ut commodo eget. Ornare in pellentesque hendrerit mauris, non. Donec a molestie lacus, sed mi interdum id in. "
  );

  const [type, setType] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSendMessage = async () => {
    const payload = {
      user_id: userData?.user?.id,
      user_profile_id: userData?.profile?.id,
      body: message,
      participants: [userData?.profile?.id, profile?.id],
      type: type?.value,
    };

    try {
      setLoading(true);
      const result = await http.post("public/messages/send", payload);
      console.log("result => ", result);
      if (result?.data?.data) {
        toast.success(result.data.message);
        setLoading(false);
        hide();
      }

      setLoading(false);
    } catch (err) {
      console.log("error => ", err);
      toast.error(`Somthing went wrong. ${err?.data?.message}.`);
      setLoading(false);
    }
  };

  const Footer = () => {
    return (
      <div className="relative grid gap-y-3 1sm:gap-y-0 1sm:flex 1sm:flex-row 1sm:items-center 1sm:justify-end 1sm:space-x-2">
        <Button
          buttonName="Cancel"
          buttonClass="bg-thBg py-5 px-12"
          buttonTextClass="text-black text-sm font-semibold"
          onClick={() => hide()}
        />
        <Button
          key="save-btn-category"
          buttonName="Send"
          buttonClass="bg-primary py-5 px-12"
          buttonTextClass={`text-white text-sm font-semibold  ${loading ? 'flex items-center justify-center' : ''}`}
          onClick={handleSendMessage}
          disabled={_.isEmpty(type) || _.isEmpty(message)}
          loadingIcon={
            loading ? (
              <LoadingIndicator className="text-white w-4 h-4 ml-2" />
            ) : null
          }
        />
      </div>
    );
  };

  return (
    <Modal
      footer={<Footer />}
      width="w-19/20 md:w-16/20 2md:w-12/20 3md:w-9/20 1xl:w-8/20"
      contentClassName="rounded-lg overflow-auto custom-scroll"
      wrapperClassName="overflow-visible"
      footerClass="border-t border-gray"
    >
      <div className="w-full">
        {/* header */}
        <ModalHeader title="Send Message" hide={hide} />
        {/* content */}
        <div className="px-8.5 py-6">
          {/* profile */}
          <div className="w-full">
            {/* <p className="text-sm font-semibold text-darkerGray">Send to</p>
            <div className="mt-3 w-full flex items-center space-x-5">
              <img
                src={ProfileAvatar}
                alt="milestone-profile"
                className="w-15 h-15 rounded-full"
              />
              <div>
                <p className="text-xl text-lightBlack font-bold tracking-tighter leading-6">
                  John Doe
                </p>
                <p className="text-base leading-7.5 tracking-tightest text-darkerGray">
                  johndoe@gmail.com
                </p>
              </div>
            </div> */}
            <Select
              label="Message Type"
              options={MESSAGETYPE}
              placeholder="Select Message Type"
              value={type}
              name="type"
              height={58}
              onChange={(e) => setType(e)}
            />
          </div>
          {/* message content */}
          <div className="mt-8">
            <WYSIWYGEditor
              label="Your Message"
              value={message}
              limitChar={1000}
              onChange={(value) => setMessage(value)}
            />
          </div>
        </div>
        {/* end content */}
      </div>
    </Modal>
  );
}
