import React from "react";

const ModalMobile = ({
  footer = null,
  width = "w-full",
  wrapperClassName = "",
  contentClassName = "p-4",
  footerClass = "",
  share,
  centerModal="",
  image_gallery = false,
  ...props
}) => {
  return (
    <div
    className={share ? "fixed inset-0 modal-animation" : image_gallery ? "fixed z-[1001] inset-0 modal-animation" :  "fixed z-999 inset-0 modal-animation"}
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      className="fixed inset-0 bg-black bg-opacity-70 transition-opacity"
      aria-hidden="true"
    />
    <div className={`fixed inset-0  ${centerModal}`}>
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0 ">
        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          className={`${width} relative inline-block align-bottom bg-white rounded-lg text-left transform transition-all sm:my-8 sm:align-middle ${wrapperClassName}`}
        >
          {/*  content here */}
          <div className={`bg-white  ${contentClassName}`}>
            {props?.children}
          </div>
          {/* footer here */}
          {footer && (
            <div className={`w-full pl-10 pr-7 py-3.5 ${footerClass}`}>
              {footer}
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
  );
};

export default ModalMobile;
