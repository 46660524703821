import * as React from "react"
const LockBlueIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={46}
    fill="none"
    {...props}
  >
    <path
      fill="#0C6ED1"
      d="M17.111 45.502h-3.6l.253-.16c-.203-.015-.365-.013-.522-.04-1.653-.282-3.205-.835-4.715-1.573-1.903-.93-2.784-2.504-2.809-4.501-.063-5.194-.045-10.39-.003-15.584.01-1.164.358-2.266 1.545-2.85.046-.023.07-.089.104-.136.29.006.583.017.873.017h16.317c.173 0 .347-.028.52-.042.188.007.376.022.563.02.868-.008 1.735-.017 2.534.408.515.361.959.784 1.245 1.361.161.326.184.69.34 1.013v.088c-.146.146-.085.332-.086.5-.004 4.927-.004 9.854-.001 14.782 0 .154-.056.325.086.455v.352c-.142.072-.127.222-.157.342-.357 1.495-1.232 2.572-2.626 3.2-2.5 1.123-5.13 1.758-7.844 2.098-.672.083-1.36.045-2.018.25h.001Zm-3.662-10.55c0 .336-.005.674.002 1.01.006.308.044.613.184.895.31.624.926.956 1.583.853.706-.11 1.196-.587 1.297-1.292.1-.7.058-1.406.033-2.106-.02-.56.148-.963.566-1.344 1.234-1.129 1.306-3.074.197-4.264-1.22-1.31-3.173-1.4-4.437-.205-1.227 1.163-1.33 3.153-.113 4.33.56.542.779 1.087.69 1.814-.012.101-.001.205 0 .307l-.002.001ZM25.1 30.733c0 .644-.004 1.29 0 1.933.006.703.459 1.148 1.156 1.145.665-.003 1.124-.456 1.127-1.129.005-1.26.004-2.52 0-3.78-.002-.71-.49-1.243-1.134-1.247-.645-.004-1.145.529-1.15 1.232-.005.615 0 1.23 0 1.846Zm2.283-6.128c0-.213.008-.374-.002-.534-.032-.572-.557-1.07-1.126-1.075-.591-.005-1.128.497-1.152 1.092a9.684 9.684 0 0 0 0 .92c.047.83.934 1.32 1.668.931.568-.301.658-.813.61-1.334h.002Z"
    />
    <path
      fill="#0D5DAE"
      d="M12.808 45.501c-.394-.184-.824-.114-1.234-.157-2.892-.301-5.696-.935-8.368-2.1-1.913-.836-2.921-2.322-2.937-4.386C.23 33.862.25 28.867.259 23.87c.003-1.221.55-2.16 1.576-2.82a4.606 4.606 0 0 1 1.144-.36c.65-.103 1.302-.012 1.95-.075l2.434.04c-.034.047-.058.114-.104.136-1.187.586-1.536 1.686-1.545 2.85-.042 5.195-.06 10.39.003 15.584.025 1.998.905 3.572 2.81 4.501 1.51.738 3.063 1.291 4.714 1.574.157.027.319.025.522.04l-.253.16h-.703.001Z"
    />
    <path
      fill="#FFE283"
      d="M29.756 39.26c-.142-.13-.087-.3-.087-.456-.002-4.927-.002-9.853.001-14.781 0-.168-.06-.354.086-.5v15.736Z"
    />
    <path
      fill="#000"
      d="M13.449 34.95c0-.102-.01-.206.002-.307.088-.728-.13-1.272-.69-1.814-1.218-1.177-1.115-3.168.113-4.33 1.263-1.196 3.216-1.106 4.436.204 1.11 1.19 1.039 3.135-.197 4.265-.417.381-.586.785-.566 1.343.026.7.068 1.408-.032 2.106-.102.705-.592 1.182-1.297 1.293-.658.104-1.274-.229-1.584-.853-.14-.283-.178-.587-.184-.895-.007-.337-.002-.674-.002-1.01l.001-.002Z"
    />
    <path
      fill="#4192E4"
      d="M25.1 30.732c0-.615-.003-1.23 0-1.846.005-.703.506-1.235 1.15-1.232.643.004 1.132.537 1.135 1.248.003 1.26.004 2.52 0 3.78-.003.673-.462 1.126-1.127 1.128-.697.003-1.151-.442-1.155-1.144-.005-.644-.001-1.29-.001-1.934H25.1ZM27.384 24.605c.047.521-.043 1.033-.61 1.334a1.132 1.132 0 0 1-1.67-.93 9.184 9.184 0 0 1 0-.921c.026-.595.562-1.097 1.154-1.092.569.005 1.094.503 1.126 1.075.008.16.001.322.001.534h-.001Z"
    />
    <path
      fill="#7E859B"
      d="m25.075 20.632.026-1.505c1.021 0 2.043.003 3.063.004.002.643.005 1.286.006 1.93-.798-.426-1.666-.417-2.534-.409-.188.002-.375-.013-.562-.02Z"
    />
    <path
      fill="#949DB8"
      d="m28.164 19.13-3.063-.003c-.003-1.73-.005-3.458-.011-5.186-.008-2.389-.66-4.57-2.152-6.457-1.64-2.074-3.763-3.357-6.387-3.746-3.315-.493-6.21.441-8.606 2.765-1.798 1.743-2.798 3.907-2.967 6.43-.138 2.064-.013 4.13-.071 6.194l-3.063.003c.008-2.209-.105-4.42.062-6.628.206-2.742 1.235-5.17 2.99-7.266 2.044-2.441 4.649-4 7.798-4.524 4.239-.708 8.01.364 11.198 3.268 2.77 2.52 4.171 5.706 4.27 9.442.052 1.901.005 3.806.002 5.71v-.002Z"
    />
    <path
      fill="#7E859B"
      d="M1.842 19.13c1.022 0 2.043-.002 3.063-.003l.023 1.49c-.647.063-1.298-.028-1.949.075a4.59 4.59 0 0 0-1.145.36l.008-1.921Z"
    />
  </svg>
)
export default LockBlueIcon
