import React, { useState,useEffect } from "react";
import { Context } from "context/context";
import { MegaPhone, Close } from "assets/svg/sidebar/icons";
import LoadingIndicator from "assets/svg/loading-indicator";
import { Link } from "react-router-dom";

export default function Alerts({userData}) {
  const { alerts, setAlerts } = React.useContext(Context);
  const [state] = useState({
    loading: false,
    verificationSent: false,
  });
  
  useEffect(()=>{
    checkType();
  },[])

  const checkType = ()=>{
    let badge = []
    const {profile={}} = userData;
    let profile_type_group = profile?.profile_type?.type === 'group' ||  profile?.profile_type?.type === 'team' ? true : false;
    badge.push(<span class="text-verified">
      Get your {profile_type_group ? 'Team' : ''} Verified Badge by
      <Link to="/verify-account" className="ml-1 underline hover:text-gray">
        clicking here
      </Link>
    </span>
    );
    let getUser_data = localStorage.getItem('user_data');
    getUser_data = JSON.parse(getUser_data); 
    if(getUser_data?.alert){
      setAlerts(getUser_data?.alert);
      return;
    }
    setAlerts(badge)
  }

  const removeAlert = (removeAlert) => {
    const updated = alerts.filter((item) => item !== removeAlert);
    setAlerts(updated); 
    let getUser_data = localStorage.getItem('user_data');
    getUser_data = JSON.parse(getUser_data); 
    localStorage.setItem('user_data', JSON.stringify({...getUser_data, alert:updated}));
  };

  return (
    <div className="alerts">
      {alerts &&
        alerts.length > 0 &&
        alerts.map((alert, i) => (
          <div className="w-full alert" key={`alert${i}`}>
            <div className="w-full flex justify-center items-center gap-4 font-semibold text-lg">
              <MegaPhone />
              {alert}
            </div>

            <button
              onClick={() => removeAlert(alert)}
              disabled={state.loading}
              className="items-center justify-center flex close-alert"
            >
              {state.loading ? (
                <LoadingIndicator className="text-white w-3 h-3" />
              ) : (
                <Close className="w-4 h-4 " fill={"white"} />
              )}
            </button>
          </div>
        ))}
    </div>
  );
}
