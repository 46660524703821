const VerifyIconXSVG = ({ className = "", fill = "#0C6ED1" }) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.42294 16H7.57707L5.58749 13.9509H2.64483L2.0134 13.3552V10.4721L0 8.42294V7.57707L2.0134 5.52793V2.63291L2.64483 2.03723H5.58749L7.57707 0H8.42294L10.4721 2.03723H13.3671L13.9628 2.621V5.52793L16 7.57707V8.42294L13.927 10.4721V13.3552L13.3314 13.9509H10.4721L8.42294 16ZM6.45719 10.9486H7.30306L11.7945 6.45719L10.9486 5.61132L6.88608 9.68578L5.242 8.0417L4.39613 8.88757L6.45719 10.9486Z"
      fill={fill}
    />
  </svg>
);
export default VerifyIconXSVG;
