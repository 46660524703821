import React from "react";
import { Profile1 } from "constants/images";
import moment from "moment";
import FirstPage from "./page-one";
import SecondPage from "./page-two";
import PhoneIconSVG from "assets/svg/phone-icon-stroke";
import MailIconSVG from "assets/svg/mail-icon-stroke";
import { personalDateFormat } from "utils/utils";
import { groupArraysWithType,A4_SIZE } from "../../resume";

export default function TemplateOne({
  previewImage,
  details = {},
  personal = {},
  social = {},
  milestones = {},
  page = 1,
  primaryColor,
  secondaryColor,
  tertiaryColor,
  show_image_milestone = true,
  profile,
  setTotalPage
}) {
  
  const [mergeMilestone, setMergeMilestone] = React.useState([])

  React.useEffect(() => {
    let groupAll = groupArraysWithType(arraysWithType, 6, 6);
      console.log(groupAll);
      console.log(groupAll.length);
      setMergeMilestone(groupAll)
      setTotalPage(groupAll.length) 
  }, [milestones]);

  const arraysWithType = [
    { array: milestones?.featured || [], type: 'featured' },
    { array: milestones?.current || [], type: 'current' },
    { array: milestones?.past || [], type: 'past' },
  ];
  
  return (
    <div style={A4_SIZE}>
      <div
        className="w-full flex flex-row h-full">
        {/** ================ left content ================ */}
        <div
          className="shrink-0 w-full py-6.6 pl-10 pr-8"
          style={{ backgroundColor: secondaryColor, width: "225px" }}>
          {
            personal?.profile_image &&
            <>
              <div className="w-full flex justify-center">
                <div className="w-25 h-25 flex rounded-full bg-white relative mb-2">
                  {/** picture */}
                  {(personal.hasOwnProperty("profile_image") && personal.profile_image) || previewImage ? (
                    <img
                      src={previewImage ?? personal.profile_image}
                      className="w-full object-cover rounded-full"
                      alt="resume-profile"
                    />
                  ) : (
                    <img
                      src={Profile1}
                      className="w-full object-cover rounded-full"
                      alt="resume-profile"
                    />
                  )}
                </div>
              </div>
            </>
          }
          {/** username */}
          <div className="border-b border-gray-300 py-3">
            <span
              className={"font-bold text-xl tracking-resumeOne"}
              style={{ color: tertiaryColor }}>
              {details.username}
            </span>
          </div>
          {/** ================ group/team information ================ */}
          <div className="border-b border-gray-300 pt-2 pb-4">
            <div className="mb-1.5">
              <span className="text-4xs font-bold text-resumeTempFourTextPrimary tracking-resumeOne">
                Group Information
              </span>
            </div>
            <div className="w-full grid gap-y-2">
              {/** formation date */}
              {personal.hasOwnProperty("birthdate") && (
                <div className="w-full flex flex-row space-x-1 items-start">
                  <div style={{ width: "calc(100% - 59px)" }}>
                    <p className="text-1xs text-darkerGray font-medium">
                      Formation Date
                    </p>
                  </div>
                  <div style={{ width: "calc(100% - 59px)" }}>
                    <p className="text-1xs text-resumeTempFourTextPrimary">
                      {personalDateFormat(personal.birthdate, profile?.dateFormat)}
                    </p>
                  </div>
                </div>
              )}
              {/** formation location */}
              {personal.hasOwnProperty("birth_location") && (
                <div className="w-full flex flex-row space-x-1 items-start">
                  <div style={{ width: "calc(100% - 59px)" }}>
                    <p className="text-1xs text-darkerGray font-medium">
                      Formation Location
                    </p>
                  </div>
                  <div style={{ width: "calc(100% - 59px)" }}>
                    <p className="text-1xs text-resumeTempFourTextPrimary">
                      {profile.birth_location}
                    </p>
                  </div>
                </div>
              )}
              {/** members */}
              {personal.hasOwnProperty("members") && personal.members.length > 0 && (
                <div className="w-full flex flex-row space-x-1 items-start">
                  <div style={{ width: "calc(100% - 59px)" }}>
                    <p className="text-1xs text-darkerGray font-medium">Members</p>
                  </div>
                  <div className="flex flex-column" style={{ width: "calc(100% - 59px)" }}>
                    {
                      personal.members.map((member, index) => {
                        return <p key={`member-${index}`} className="text-1xs text-resumeTempFourTextPrimary"
                        dangerouslySetInnerHTML={{ __html: member?.mention || member.name }}>
                        </p>
                      })
                    }
                  </div>
                </div>
              )}
            </div>
          </div>
          {/** bio */}
          {details.hasOwnProperty("bio") && (
            <div className="border-b border-gray-300 pt-2 pb-4">
              <div className="mb-1.5">
                <span className="text-4xs font-bold text-resumeTempFourTextPrimary tracking-resumeOne">
                  Bio
                </span>
              </div>
              <p className="text-1xs text-resumeTempFourTextPrimary">
                {details.bio}
              </p>
            </div>
          )}
          {/** ================ contact details ================ */}
          <div className="border-b border-gray-300 pt-2 pb-3">
            <div className="mb-1.5">
              <span className="text-4xs font-bold text-resumeTempFourTextPrimary tracking-resumeOne">
                Contact Details
              </span>
            </div>
            <div className="w-full grid gap-y-2">
              {/** phone number */}
              {details.hasOwnProperty("phone_number") &&
                details.phone_number && (
                  <div className="w-full flex flex-row space-x-1 items-start">
                    <PhoneIconSVG />
                    <div>
                      <p className="text-1xs text-resumeTempFourTextPrimary">
                        {details.phone_number}
                      </p>
                    </div>
                  </div>
                )}
              {/** email */}
              {details.hasOwnProperty("email") && details.email && (
                <div className="w-full flex flex-row space-x-1 items-center">
                  <MailIconSVG />
                  <div
                    className="break-all">
                    <p className="text-1xs text-resumeTempFourTextPrimary">
                      {details.email}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/** ================ right content ================ */}
        <div className="w-full h-full bg-white p-8.6 relative"> 
          { /**=====================Footer================== */}
          <div
            className="absolute left-0 bottom-0 w-full h-6 border-t px-2 z-50 bg-white"
            style={{ borderColor: "#DAE4EF" }}>
            <div className="w-full h-full flex flex-row justify-between items-center">
              <p className="font-medium text-1xs text-darkerGray">Page {page}</p>
              <div className="flex flex-start gap-1.5">
                <button className="w-1.5 h-1.5 rounded-full bg-primary" />
                <button className="w-1.5 h-1.5 rounded-full bg-pageInactive" />
              </div>
            </div>
          </div>
          {/** ================ main content ================ */}
          <div className="w-full relative">
            {/** first page */}
            {/* {page === 1 && (
              <div ref={firstPageRef} className="bg-white"> 
                <FirstPage 
                  {...{
                    details,
                    social,
                    primaryColor,
                    secondaryColor,
                    tertiaryColor,
                    milestones,
                  }}
                />
              </div>
            )} */}
            {/* {
              content.page.length > 0 && 
                content.page.map((item,index)=>
                  ( 
                      page === (index+1) &&
                        <>
                          <div key={index} className={`absolute left-0`} style={{top:item.top}}>
                            <FirstPage 
                            {...{
                              details,
                              social,
                              primaryColor,
                              secondaryColor,
                              tertiaryColor,
                              milestones,
                            }}
                          /> 
                          </div>  
                        </>
                  )
                ) 
            } */}
            {
                  mergeMilestone.map((item,index)=>{
                    return (
                      page === (index+1) &&
                      <div key={index}>
                        <FirstPage
                        {...{
                          details,
                          social,
                          primaryColor,
                          secondaryColor,
                          tertiaryColor,
                          milestones,
                          profile,
                          show_image_milestone
                        }}
                          items = {item}
                        />
                      </div>
                    )
                  })
                }
            {/** second page */}
            {/* {page === 2 && (
              <SecondPage
                {...{ milestones, primaryColor, secondaryColor, tertiaryColor, show_image_milestone }}
              />
            )} */}
            
          </div> 
        </div>
      </div>
    </div>
  );
}
