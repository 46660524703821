const SearchIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_820_2519)">
        <path
          d="M7.11983 14.7196C8.78914 14.7277 10.4064 14.1389 11.6798 13.0595L15.9398 17.3195C16.0594 17.439 16.2209 17.5071 16.3898 17.5095C16.5584 17.5051 16.7191 17.4372 16.8399 17.3195C16.9553 17.2002 17.0199 17.0406 17.0199 16.8745C17.0199 16.7085 16.9553 16.5489 16.8399 16.4295L12.5799 12.2096C13.6198 10.9671 14.2049 9.40717 14.2385 7.78731C14.2721 6.16744 13.7523 4.58457 12.7648 3.30007C11.7773 2.01556 10.3812 1.10623 8.80715 0.722307C7.23308 0.338387 5.57514 0.502826 4.10713 1.18843C2.63913 1.87404 1.44889 3.0398 0.732927 4.49324C0.0169646 5.94668 -0.181922 7.60087 0.169207 9.18257C0.520335 10.7643 1.40043 12.1789 2.66414 13.1929C3.92785 14.2069 5.49962 14.7595 7.11983 14.7595V14.7196ZM7.11983 1.71956C8.27962 1.70968 9.41626 2.0442 10.3858 2.68074C11.3554 3.31728 12.1142 4.22719 12.5663 5.2953C13.0184 6.36342 13.1433 7.54164 12.9253 8.6808C12.7073 9.81997 12.1561 10.8688 11.3416 11.6945C10.5271 12.5203 9.48595 13.0857 8.34987 13.3193C7.21379 13.5529 6.03393 13.444 4.95973 13.0066C3.88554 12.5692 2.96531 11.8228 2.31557 10.8621C1.66583 9.90132 1.31581 8.7694 1.30983 7.60957C1.30978 6.06403 1.92033 4.58101 3.0085 3.48348C4.09667 2.38594 5.57434 1.76272 7.11983 1.74953V1.71956Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_820_2519">
          <rect
            width="17.02"
            height="17.02"
            fill="currentColor"
            transform="translate(0 0.490234)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SearchIconSVG;
