import React from "react";
import CreatableSelectComponent from "react-select/creatable";

const CreatableSelect = ({
  options = [],
  label = "",
  otherLabel = "",
  labelClass = "text-sm font-bold text-darkerGray leading-4",
  innerLabelClass = "text-xs font-semibold color-select-label leading-3.5 absolute left-4.5 top-4",
  className = "",
  formContainer = "form-input-container",
  height = 52,
  innerLabel,
  optional = false,
  errorMessage,
  ...props // make sure this pops here is for input only
}) => {
  const padding = innerLabel ? { paddingTop: 20 } : {};
  const customStyles = {
    control: (styles) => {
      return {
        ...styles,
        backgroundColor: "white",
        borderColor: errorMessage ? "red" : "#dfdfdf",
        // height: height,
        borderRadius: 6,
        minHeight: height,
        boxShadow: "none",
        "&:hover": {
          border: "1px solid #dfdfdf !important",
          cursor: "pointer",
        },
        paddingLeft: 8,
        ...padding,
      };
    },
    option: (provided) => {
      return {
        ...provided,
        padding: "8px 18px",
        zIndex: 2,
      };
    },
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#aaaaaa",
      };
    },
    menu: (base) => {
      return {
        ...base,
        zIndex: 9999,
      };
    },
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menuList: (base) => ({
      ...base,
      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "rgba(255, 255, 255, 0.1)",
        borderRadius: "10px",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#d1d1d1",
        borderRadius: "10px",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
  };

  return (
    <div className={`${formContainer} cursor-pointer relative`}>
      {label && (
        <label htmlFor={label} className={labelClass}>
          {label}
          {optional && <span className="font-normal">{" (optional)"}</span>}
          {otherLabel && (
            <span className="font-normal leading-4">
              <br />
              {otherLabel}
            </span>
          )}
        </label>
      )}

      {innerLabel && (
        <label
          htmlFor={innerLabel}
          className={innerLabelClass}
          style={{ zIndex: 1 }}
        >
          {innerLabel}
        </label>
      )}
      <CreatableSelectComponent
        menuPortalTarget={document.body}
        className={`${className} ${label && "mt-2"}`}
        styles={customStyles}
        options={options}
        components={{
          IndicatorSeparator: () => null,
        }}
        isClearable
        {...props}
      />

      {errorMessage && (
        <p className="input-error-message mt-2 -mb-1">* {errorMessage}</p>
      )}
    </div>
  );
};

export default CreatableSelect;
