import React from "react";

const Modal = ({
  footer = null,
  width = "w-full",
  wrapperClassName = "",
  contentClassName = "p-4",
  footerClass = "",
  share,
  centerModal="",
  image_gallery = false,
  show_share_message = false,
  share_link=()=>{},
  ...props
}) => {
  return (
    <div
    className={share ? "fixed inset-0 modal-animation" : image_gallery ? "fixed z-[1001] inset-0 modal-animation" :  "fixed z-999 inset-0 modal-animation"}
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      className="fixed inset-0 bg-black bg-opacity-70 transition-opacity"
      aria-hidden="true"
    />
    <div className={`fixed inset-0 modal-scroll overflow-y-auto ${centerModal}`}>
      <div className="relative flex items-end justify-center min-h-screen pt-2 px-2 pb-20 text-center 5sm:block 5sm:p-0 ">
        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          // className={`${width} absolute inset-0 align-bottom flex flex-col w-full bg-white rounded-lg text-left transform transition-all sm:my-8 sm:align-middle ${wrapperClassName}`}
        className={`${width} relative inline-block align-bottom bg-white rounded-lg text-left transform transition-all sm:my-8 sm:align-middle ${wrapperClassName}`}
        >
          {/*  content here */}
          <div className={`bg-white overflow-visible flex-1 flex flex-col ${contentClassName} `}>
            {props?.children}
          </div>
          {/* footer here */}
          {footer && (
            <div className={`w-full pl-10 pr-7 py-3.5 ${footerClass}`}>
              {footer}
            </div>
          )}
        </div>
        {
          show_share_message && 
          <div className="absolute bottom-0 bg-[#333333] text-white w-full py-4">
              Show the word your achievements. Create your Milestones Profile - <span className="font-bold cursor-pointer" onClick={share_link}> Click Here -&gt;</span>
          </div> 
        }
      </div>
    </div>
  </div>
  );
};

export default Modal;
