import React, { useState } from "react";
import Modal from "components/global/modal";
import Button from "components/button/button";
import LoadingIndicator from "assets/svg/loading-indicator";
import toast from "react-hot-toast";
import { AiFillDelete } from "react-icons/ai";

export default function RemoveUserModal({ hide, access, ...props }) {
  const { http } = global.services;

  const [loading, setLoading] = useState(false);

  const remove = async () => {
    setLoading(true);
    try {
      let { data } = await http.delete(`public/user-accesses/${access?.id}`);
      if (data) {
        toast.success("User Access deleted");
        hide(true);
      }
    } catch (err) {
      console.log(err);
      toast.error(`Something went wrong. ${err.message}`);
    }
  };

  const Footer = () => {
    return (
      <div className="relative grid gap-y-3 1sm:gap-y-0 1sm:flex 1sm:flex-row 1sm:items-center 1sm:justify-end 1sm:space-x-2">
        <Button
          buttonName="Cancel"
          buttonClass="bg-thBg py-5 px-12"
          buttonTextClass="text-black text-sm font-semibold"
          onClick={() => hide()}
        />
        <Button
          key="save-btn-category"
          buttonName="Remove user"
          buttonClass="bg-red py-5 px-12"
          buttonTextClass="text-white text-sm font-semibold flex items-center"
          onClick={remove}
          loadingIcon={
            loading ? (
              <LoadingIndicator className="text-white w-4 h-4 ml-2" />
            ) : null
          }
        />
      </div>
    );
  };

  return (
    <Modal
      footer={<Footer />}
      width="w-19/20 md:w-16/20 2md:w-12/20 3md:w-9/20 1xl:w-8/20"
      contentClassName="rounded-lg overflow-auto custom-scroll"
      wrapperClassName="overflow-visible"
      footerClass="border-t border-gray">
      <div className="w-full">
        {/* header */}
        {/* <ModalHeader title="Remove User" hide={hide} /> */}

        {/* content */}
        <div className="px-8.5 py-6">
          <div className="flex flex-row items-center">
            <AiFillDelete className="text-3xl text-red md:mr-2" />
            <p className="text-3xl text-red font-bold tracking-tighter">
              Remove User
            </p>
          </div>
          {/* profile */}
          <div className="w-full text-lg py-6">
            <p>
              Are you sure you want to remove {access?.invitee?.first_name}{" "}
              {access?.invitee?.last_name} {`(${access?.invitee?.email})`}
            </p>
          </div>
        </div>
        {/* end content */}
      </div>
    </Modal>
  );
}
