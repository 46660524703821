const HeightIconSVG = ({ className = "", fill = "#333333" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2447_705)">
        <path
          d="M20.7567 0C21.1002 0.143986 21.3049 0.349846 21.4483 0.692174V1.15362C21.2806 1.52949 21.0493 1.79722 20.6127 1.8458H3.83745C2.78386 1.68389 2.71158 0.354472 3.69346 0H20.7567Z"
          fill={fill}
        />
        <path
          d="M21.4478 22.844V23.3055C21.2801 23.6813 21.0488 23.9491 20.6122 23.9976L3.89247 24C2.68565 23.8936 2.71687 22.2426 3.89247 22.1495L20.6122 22.1518C21.0488 22.201 21.2801 22.4687 21.4478 22.844Z"
          fill={fill}
        />
        <path
          d="M13.1471 6.86451V17.1326L15.3364 14.9682C16.2078 14.2853 17.3186 15.3944 16.6363 16.2687C15.3283 17.3443 14.1105 19.0981 12.803 20.1049C12.4277 20.394 12.023 20.394 11.6477 20.1049C10.3402 19.0981 9.12242 17.3443 7.81441 16.2687C7.2373 15.532 7.92717 14.5576 8.82636 14.7947C9.3572 14.9347 10.7196 16.7753 11.3036 17.1326V6.86451L9.11433 9.02892C8.24289 9.71185 7.13206 8.60275 7.81441 7.72842C9.12242 6.65286 10.3402 4.89901 11.6477 3.89226C12.023 3.60313 12.4277 3.60313 12.803 3.89226C14.1105 4.89901 15.3283 6.65286 16.6363 7.72842C17.2134 8.46512 16.5235 9.43949 15.6244 9.2024C15.0935 9.06246 13.7311 7.22187 13.1471 6.86451Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2447_705">
          <rect
            width="18.4487"
            height="24"
            fill="white"
            transform="translate(3)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HeightIconSVG;
