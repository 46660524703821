const PhotoUploadIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.75 14.625C35.75 14.1849 35.9108 13.804 36.2324 13.4824C36.554 13.1608 36.9349 13 37.375 13C37.8151 13 38.196 13.1608 38.5176 13.4824C38.8392 13.804 39 14.1849 39 14.625C39 15.0651 38.8392 15.446 38.5176 15.7676C38.196 16.0892 37.8151 16.25 37.375 16.25C36.9349 16.25 36.554 16.0892 36.2324 15.7676C35.9108 15.446 35.75 15.0651 35.75 14.625ZM52 39V42.25H45.5V48.75H42.25V42.25H35.75V39H42.25V32.5H45.5V39H52ZM23.4102 30.875L32.5 39.9395V42.25H0V6.5H45.5V29.25H42.25V9.75H3.25V20.4395L8.125 15.5898L21.125 28.5898L27.625 22.0898L39 33.4395V35.75H36.6895L27.625 26.6602L23.4102 30.875ZM8.125 20.1602L3.25 25.0605V39H26.9395L8.125 20.1602Z"
        fill="#777777"
      />
    </svg>
  );
};

export default PhotoUploadIconSVG;
