import _ from 'lodash';

import {
	SET_ACTIVE_THREAD,
	SET_MESSAGES,
	ADD_MESSAGE,
	SET_CONVERSATIONS,
	ADD_CONVERSATION,
	PUSH_MESSAGE,
	SET_UNREAD_MESSAGES,
	SET_BLOCK_MESSAGES
} from './types'

const initialState = {
	initialize: true,
	messages: [],
	selected: null,
	conversations: [],
	unread:[]
};

const reducer = (state = initialState, { type, payload }) => {

	switch (type) {

		case SET_ACTIVE_THREAD:
			return {
				...state,
				selected: payload,
			}

		case SET_MESSAGES:
			return {
				...state,
				messages: payload,
			}

		case ADD_MESSAGE:
			return {
				...state,
				messages: [payload, ...state.messages]
			}

		case SET_CONVERSATIONS:
			return {
				...state,
				initialize: false,
				conversations: payload,
			}

		case ADD_CONVERSATION:
			const { thread, ...props } = payload
			let threadMsg = state.messages.filter(m => parseInt(m.thread_id) === parseInt(payload.thread_id))[0]
			let newMessages = state.messages.filter(m => parseInt(m.thread_id) !== parseInt(payload.thread_id))
			let newThread = {
				...threadMsg,
				...props
			}

			let nSelected = state.selected
			nSelected.updated_at = payload.updated_at

			return {
				...state,
				selected: nSelected,
				conversations: [...state.conversations, payload],
				messages: [newThread, ...newMessages]
			}

		case PUSH_MESSAGE:
			let activeThread = state.selected
			let newConvo = state.conversations
			const msg = state.messages.filter(m => parseInt(m.thread_id) !== parseInt(payload.thread_id))

			if (!_.isEmpty(state.messages)) {
				if (state.selected && parseInt(state.selected.thread_id) === parseInt(payload.thread_id)) {
					newConvo = [...state.conversations, payload]
				}
			}
			
			if (!msg.length) {
				activeThread = payload
			}

			return {
				...state,
				selected: activeThread,
				conversations: newConvo,
				messages: [payload, ...msg]
			}
		
		case SET_UNREAD_MESSAGES:
			return {
				...state,
				unread: payload,
			}
		case SET_BLOCK_MESSAGES:
			return {
				...state,
				selected: payload,
			}

		default:
			return state;

	}

}

export default reducer