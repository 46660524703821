const PDFColoredIconSVG = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      fill="none"
    >
      <path
        d="M20.1949 8.22857L13.2429 1.27657C13.0662 1.0997 12.8265 1.00022 12.5766 1H6.95714C6.04025 1 5.16091 1.36423 4.51257 2.01257C3.86423 2.66091 3.5 3.54025 3.5 4.45714V19.5429C3.5 20.4597 3.86423 21.3391 4.51257 21.9874C5.16091 22.6358 6.04025 23 6.95714 23H17.0143C17.9312 23 18.8105 22.6358 19.4589 21.9874C20.1072 21.3391 20.4714 20.4597 20.4714 19.5429V8.85714C20.4615 8.6203 20.3628 8.39588 20.1949 8.22857ZM13.5571 4.21829L17.2531 7.91429H13.5571V4.21829ZM17.0143 21.1143H6.95714C6.54037 21.1143 6.14067 20.9487 5.84597 20.654C5.55128 20.3593 5.38571 19.9596 5.38571 19.5429V4.45714C5.38571 4.04037 5.55128 3.64068 5.84597 3.34598C6.14067 3.05128 6.54037 2.88571 6.95714 2.88571H11.6714V8.85714C11.6747 9.10619 11.7751 9.34412 11.9512 9.52024C12.1273 9.69636 12.3652 9.79674 12.6143 9.8H18.5857V19.5429C18.5857 19.9596 18.4202 20.3593 18.1255 20.654C17.8308 20.9487 17.4311 21.1143 17.0143 21.1143Z"
        fill="#FF0000"
      />
      <path
        d="M13.8589 15.5831C13.0859 15.098 12.5042 14.3609 12.2121 13.4963C12.4828 12.6874 12.5645 11.8273 12.4509 10.982C12.4147 10.769 12.3108 10.5734 12.1547 10.424C11.9986 10.2747 11.7986 10.1796 11.5842 10.1528C11.3699 10.126 11.1526 10.1689 10.9645 10.2753C10.7765 10.3816 10.6277 10.5456 10.5401 10.7431C10.397 11.7594 10.5049 12.7953 10.8544 13.7603C10.3769 14.8757 9.84406 15.9665 9.25781 17.0288C8.36523 17.5317 7.14581 18.286 6.95723 19.1534C6.80638 19.8574 8.12638 21.6677 10.3767 17.7454C11.3773 17.374 12.4021 17.0715 13.4441 16.8403C14.2143 17.2806 15.0735 17.5427 15.9584 17.6071C16.1616 17.6124 16.3618 17.5575 16.5338 17.4493C16.7059 17.3411 16.8421 17.1845 16.9254 16.9991C17.0087 16.8137 17.0353 16.6078 17.002 16.4073C16.9686 16.2068 16.8767 16.0207 16.7378 15.8723C16.2098 15.3317 14.6384 15.4825 13.8589 15.5831ZM7.84981 19.3545C8.20211 18.7517 8.66736 18.2224 9.22009 17.7957C8.36524 19.1534 7.84981 19.3923 7.84981 19.3671V19.3545ZM11.5207 10.7934C11.8475 10.7934 11.8224 12.2391 11.5961 12.6288C11.4256 12.036 11.3998 11.4109 11.5207 10.806V10.7934ZM10.4269 16.9283C10.8529 16.1511 11.2226 15.3445 11.5332 14.5145C11.8663 15.1343 12.3294 15.6746 12.891 16.0985C12.0475 16.3098 11.2232 16.5916 10.4269 16.9408V16.9283ZM16.3355 16.702C16.3355 16.702 16.1092 16.9785 14.6635 16.35C16.235 16.2494 16.499 16.614 16.3355 16.7145V16.702Z"
        fill="#FF0000"
      />
    </svg>
  );
};

export default PDFColoredIconSVG;
