import React from "react";
import _ from "lodash";
import { BsShare } from "react-icons/bs";
import { PiWarningOctagonLight } from "react-icons/pi";

const OptionListItem = ({
  isYourProfile,
  permission,
  profile = {},
  featured,
  onEdit = () => {},
  onDelete = () => {},
  onReport = () => {},
  onShare = () => {},
  share,
  closeModal = () => {},
  closeMoreOption = ()=>{},
  hide = ()=>{},
  ...props
}) => {
  const { user_id } = profile;
  const { reports } = featured;
  let checkRerported = null;

  if (!_.isEmpty(reports)) {
    checkRerported = reports.find((report) => report?.user_id === user_id);
  }

  return (
    <div className="flex flex-col shadow-lg rounded-md bg-white">
      <ul className="">
        {isYourProfile ||
        (!_.isEmpty(permission) &&
          (permission.status === "owner" ||
            permission.permission.name === "Super Admin" ||
            permission.permission.name === "Admin")) ? (
          <>
            <li className="hover:bg-gray hover:rounded-t-md" {...props}>
              <button
                className="flex flex-row items-center text-lg px-6 py-2"
                onClick={() => {
                  onEdit();
                  closeMoreOption();
                  closeModal();
                  hide();
                }}>
                Edit Milestone
              </button>
            </li>
            <li className="hover:bg-gray hover:rounded-b-md" {...props}>
              <button
                className="flex flex-row items-center text-lg px-6 py-2"
                onClick={() => {
                  closeMoreOption();
                  onDelete();
                  closeModal();
                  hide();
                }}>
                Delete Milestone
              </button>
            </li>

             {/*  */}
             {share && (
              <li className="hover:bg-gray hover:rounded-b-md" {...props}>
                <button
                  className="flex flex-row items-center text-lg px-6 py-2"
                  onClick={()=>{
                    closeMoreOption();
                    onShare(); 
                    hide();
                    }}>
                  <BsShare className="mr-3" />
                  Share Milestone
                </button>
              </li>
            )}
            
          </>
        ) : (
          <>
          {/*  */}
            {share && (
              <li className="hover:bg-gray hover:rounded-b-md" {...props}>
                <button
                  className="flex flex-row items-center text-lg px-6 py-2"
                  onClick={()=>{
                    closeMoreOption();
                    hide();
                    onShare();
                    }}>
                  <BsShare className="mr-3" />
                  Share Milestone
                </button>
              </li>
            )}
          <li className="hover:bg-gray hover:rounded-md" {...props}>
            <button
              onClick={()=>{
                hide();
                closeMoreOption();
                onReport();}}
              className={`flex flex-row items-center text-lg px-4 py-2 gap-2 ${
                checkRerported && "bg-gray cursor-not-allowed"
              }`}
              disabled={checkRerported ? true : false}>
              <PiWarningOctagonLight className="text-lg" />

              {`${checkRerported ? "Reported" : "Report Milestone"}`}
            </button>
          </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default OptionListItem;
