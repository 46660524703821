const FiverrIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 24 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.625 16.625V7.625H8.625V7.0625C8.625 6.1319 9.3819 5.375 10.3125 5.375H12V2H10.3125C8.97035 2.00167 7.68365 2.53557 6.73461 3.48461C5.78557 4.43365 5.25167 5.72035 5.25 7.0625V7.625H3V11H5.25V16.625H3V20H10.875V16.625H8.625V11H14.2815V16.625H12V20H19.875V16.625H17.625Z"
        fill="currentColor"
      />
      <path
        d="M16.875 5.75C17.9105 5.75 18.75 4.91053 18.75 3.875C18.75 2.83947 17.9105 2 16.875 2C15.8395 2 15 2.83947 15 3.875C15 4.91053 15.8395 5.75 16.875 5.75Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default FiverrIconSVG;
