import {
	REFRESH_RESUMES,
	SET_RESUMES,
	ADD_RESUME,
	UPDATE_RESUME
} from './types'

const initialState = {
	initialize: true,
	resumes: [],
};

const reducer = (state = initialState, {type, payload}) => {

	switch (type) {

		case REFRESH_RESUMES:
			return {
				...state,
				initialize: true,
				resumes: []
			}

		case SET_RESUMES:
			return {
				...state,
				initialize: false,
				resumes: payload
			}

		case ADD_RESUME:
			return {
				...state,
				resumes: [...state.resumes, payload]
			}

		case UPDATE_RESUME:
			let updatedResumes = state.resumes.map((r, i) => {
				if (r.id === payload.id) {
					return payload
				}
				return r;
			});
			
			return {
				...state,
				resumes: updatedResumes
			}

		default:
			return state;

	}

}

export default reducer