const ViewsIconSVG = ({ className = "" }) => {
  return (
    <svg
      className={className}
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.75 9.79174C10.7395 9.79174 11.5417 8.98958 11.5417 8.00007C11.5417 7.01056 10.7395 6.2084 9.75 6.2084C8.76049 6.2084 7.95834 7.01056 7.95834 8.00007C7.95834 8.98958 8.76049 9.79174 9.75 9.79174Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75 14.2709C6.16667 14.2709 3.18086 12.1809 0.791672 8.00008C3.18086 3.81923 6.16667 1.72925 9.75 1.72925C13.3333 1.72925 16.3192 3.81923 18.7083 8.00008C18.3321 8.65941 17.9397 9.26679 17.5339 9.82131M12.4375 14.2709L14.2292 16.0626L17.8125 12.4792"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ViewsIconSVG;
