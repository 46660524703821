import React, { useEffect, useRef, useState, useCallback } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { canvasToFile } from "utils/constant";

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "px",
        width: 500,
        height: 500,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export default function CropToSquare({
  setParentCrop = () => {},
  setCropPreview = () => {},
  locked = false,
  ...props
}) {
  const { image, type, returnType } = props;
  const imgSrc = image;
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const zoom = 1;
  const aspect = 1 / 1;

  const getCropData = useCallback(async () => {
    if (completedCrop?.width && completedCrop?.height && imgRef.current) {
      const { blobImage, image, base64Canvas } = await canvasToFile(
        imgRef.current,
        completedCrop,
        zoom,
        0,
        "crop-image-free-mode",
        type
      );
      if (blobImage) {
        if (returnType === "blob") {
          setParentCrop(blobImage);
        } else {
          setParentCrop(image);
        }
        setCropPreview(base64Canvas);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedCrop, zoom]);

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useEffect(() => {
    getCropData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedCrop]);

  return (
    <div className="w-full">
      <div
        className="w-full relative bg-passwordGray rounded-md flex justify-center items-center"
        style={{ minHeight: "400px" }}
      >
        {!!imgSrc && (
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={aspect}
            minWidth={200} 
            minHeight={200}
            // locked={locked}
          >
            <img crossorigin="anonymous" ref={imgRef} alt="Crop me" src={imgSrc} onLoad={onImageLoad} />
          </ReactCrop>
        )}
      </div>
      {/* <div className="w-full mt-10">
        <div className="w-full">
          <InputRange
            hideNumber={true}
            buttons={true}
            onClickButton={(value) => {
              setZoom(value);
            }}
            name="rating"
            min={0}
            max={10}
            step={0.1}
            defaultValue={zoom}
            onChange={(e) => setZoom(Number(e?.target?.value))}
          />
        </div>
      </div> */}
    </div>
  );
}
