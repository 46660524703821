const BlockIconSVG = ({ className = "" }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 18.3334C14.6024 18.3334 18.3334 14.6025 18.3334 10.0001C18.3334 5.39771 14.6024 1.66675 10 1.66675C5.39765 1.66675 1.66669 5.39771 1.66669 10.0001C1.66669 14.6025 5.39765 18.3334 10 18.3334Z"
      stroke="#27304E"
      strokeWidth="2"
    />
    <path
      d="M4.16669 15.8334L15.8334 4.16675"
      stroke="#27304E"
      strokeWidth="2"
    />
  </svg>
);
export default BlockIconSVG;
