import React, { useState } from "react";
import Modal from "components/global/modal";
import Button from "components/button/button";
import LoadingIndicator from "assets/svg/loading-indicator";
import toast from "react-hot-toast";
import { AiFillDelete } from "react-icons/ai";

export default function ConfirmDeleteModal({
  hide,
  userData,
  milestone,
  ...props
}) {
  const { http } = global.services;

  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    try {
      let payload = {};
      if (milestone.position_id) {
        payload.position_id = milestone.position_id;
      }
      setLoading(true);
      const result = await http.delete(
        `public/milestones/${milestone.id}`,
        payload
      );
      if (result.data.message) {
        toast.success(result.data.message);
      }
      hide(true);
    } catch (err) {
      console.log("error => ", err);
      toast.error(`Somthing went wrong. ${err?.data?.message}.`);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const Footer = () => {
    return (
      <div className="relative grid gap-y-3 1sm:gap-y-0 1sm:flex 1sm:flex-row 1sm:items-center 1sm:justify-end 1sm:space-x-2">
        <Button
          buttonName="Cancel"
          buttonClass="bg-thBg py-5 px-12 "
          buttonTextClass="text-black text-xs 2sm:text-base font-semibold"
          onClick={() => hide()}
        />
        <Button
          key="save-btn-category"
          buttonName="Delete Milestone"
          buttonClass="bg-red py-5 px-12"
          buttonTextClass="text-white text-xs 2sm:text-base font-semibold flex items-center justify-center"
          onClick={() => handleDelete()}
          loadingIcon={
            loading ? (
              <LoadingIndicator className="text-white w-4 h-4 ml-2" />
            ) : null
          }
        />
      </div>
    );
  };

  return (
    <Modal
      footer={<Footer />}
      width="w-19/20 md:w-16/20 2md:w-12/20 3md:w-9/20 1xl:w-8/20"
      contentClassName="rounded-lg overflow-auto custom-scroll"
      wrapperClassName="overflow-visible"
      footerClass="border-t border-gray">
      <div className="w-full">
        {/* header */}
        {/* <ModalHeader title="Delete Milestone" hide={hide} /> */}
        {/* content */}
        <div className="px-8.5 py-6">
          <div className="flex flex-row items-center">
            <AiFillDelete className="text-base 2sm:text-3xl text-red  mr-3 md:mr-2" />
            <p className="text-base 2sm:text-3xl text-red font-bold tracking-tighter">
              Delete Milestone
            </p>
          </div>
          {/* profile */}
          <div className="w-full py-6">
            <p className="text-xs 2sm:text-lg">
              Are you sure you want to delete {milestone.title}
              {
                (milestone?.name || milestone.platform) &&
                <>
                 -{" "} {milestone.name} {milestone.platform && `(${milestone.platform})`}
                </>

              }
              ?
            </p>
          </div>
        </div>
        {/* end content */}
      </div>
    </Modal>
  );
}
