import {
    SET_ACTIVE_THREAD,
    SET_MESSAGES,
    ADD_MESSAGE,
    ADD_CONVERSATION,
    SET_CONVERSATIONS,
    PUSH_MESSAGE,
    SET_UNREAD_MESSAGES,
    SET_BLOCK_MESSAGES
} from './types';

export const setActiveThread = (params) => {
    return {
        type: SET_ACTIVE_THREAD,
        payload: params,
    };
}

export const setMessages = (params) => {
    return {
        type: SET_MESSAGES,
        payload: params,
    };
}

export const setBlockMessages = (params) => {
    return {
        type: SET_BLOCK_MESSAGES,
        payload: params,
    };
}

export const addMessage = (params) => {
    return {
        type: ADD_MESSAGE,
        payload: params,
    };
}

export const setConversations = (params) => {
    return {
        type: SET_CONVERSATIONS,
        payload: params,
    };
}

export const addConversation = (params) => {
    return {
        type: ADD_CONVERSATION,
        payload: params,
    };
}

export const setPushMessage = (params) => {
    return {
        type: PUSH_MESSAGE,
        payload: params,
    };
}

export const setUnreadMessages = (params) => {
    return {
        type: SET_UNREAD_MESSAGES,
        payload: params,
    };
}