import React, { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import Button from "components/button/button";
import Input from "components/input/input";
import PasswordValidation from "components/password-validation/password-validation";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import LoadingIndicator from "assets/svg/loading-indicator";
import toast from "react-hot-toast";
import CCIconSVG from "assets/svg/cc-icon-svg";
import ChangePlanModal from "./component/change-plan";
import { useDialogHook } from "utils/customhooks";
import ChangePaymentMethod from "components/settings/component/change-payment-method";
import { SubscriptionContext } from "context/subscription";
import PaypalIconSVG from "assets/svg/paypal-icon-svg";
import moment from "moment";
import PopInfo from "components/info-popup/pop-info";
import { BASE_URL } from "configs/api";
import { AiFillDelete } from "react-icons/ai";
import LockBlueIcon from "assets/svg/upgrade/lock-blue-icon";
import DeleteAccount from "./component/delete-account";

export default function SettingsAccount({ userData }) {
  const history = useHistory();
  const { http,localstorage } = global.services;

  const [state, setState] = useState({
    email: userData?.user?.email,
    accountLink: `www.milestonespage/${userData?.user?.username}`,
    password: "",
    old_password: "",
    comfirm_password: "",
    showPasswordOld: false,
    showPassword: false,
    showPasswordConfirm: false,
    isEmailVerified: userData?.user?.email_verified_at ? true : false,
    loading: false,
    validationErrors: null,
  });
  const [loading, setLoading] = useState(false);
  const changePaymentMethodModal = useDialogHook(ChangePaymentMethod);
  const { subscription = {}, updateSubscription,resetSubscription } =
    useContext(SubscriptionContext);
  
    const deleteModal = useDialogHook(DeleteAccount);

  const [passwordValidation, setPasswordValidation] = useState({
    passwordHasUpperCaseLetter: false,
    passwordHasLowerCaseLetter: false,
    passwordHasNumber: false,
    passwordHasEightCharacters: false,
  });

  const changePlanModal = useDialogHook(ChangePlanModal);

  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "password") {
      const hasUpperCase = /[A-Z]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasEightCharacters = value.length >= 8;
      const hasNumber = /\d/.test(value);

      setPasswordValidation((prevState) => ({
        ...prevState,
        passwordHasUpperCaseLetter: hasUpperCase,
        passwordHasLowerCaseLetter: hasLowerCase,
        passwordHasEightCharacters: hasEightCharacters,
        passwordHasNumber: hasNumber,
      }));
    }
    setState((prev) => ({
      ...prev,
      [name]: value,
      validationErrors: { ...state.validationErrors, [name]: null },
    }));
  };

  const validateBeforeSave = () => {
    let errors = {};

    if (_.isEmpty(state.email)) {
      errors = { ...errors, email: "Email Address is required" };
    }
    if (_.isEmpty(state.accountLink)) {
      errors = { ...errors, accountLink: "Account Link is required" };
    }
    if (_.isEmpty(state.old_password)) {
      errors = { ...errors, old_password: "Old Password is required" };
    }
    if (_.isEmpty(state.password)) {
      errors = { ...errors, password: "New Password is required" };
    }
    if (_.isEmpty(state.comfirm_password)) {
      errors = { ...errors, comfirm_password: "Confirm Password is required" };
    }

    if (!_.isEmpty(state.password) && !_.isEmpty(state.comfirm_password)) {
      if (state.password !== state.comfirm_password) {
        errors = {
          ...errors,
          comfirm_password: "Password is mismatch",
        };
      }
      let strong = _.mapKeys(passwordValidation, function (value) {
        return value;
      });
      if (_.size(strong) !== 1) {
        errors = {
          ...errors,
          password: "Password is weak",
        };
      }
    }

    if (_.isEmpty(errors)) {
      return true;
    } else {
      setState((prev) => ({
        ...prev,
        validationErrors: { ...state.validationErrors, ...errors },
      }));
      return false;
    }
  };

  const handleSave = async () => {
    if (validateBeforeSave()) {
      const payload = {
        id: userData?.user?.id,
        email: state.email,
        account_link: state.accountLink,
        old_password: state.old_password,
        password: state.password,
        password_confirmation: state.comfirm_password,
      };

      try {
        setLoading(true);
        const result = await http.put(
          `public/users/${payload.id}/update-account`,
          payload
        );
        console.log("result => ", result);
        if (result?.data?.data) {
          toast.success(result.data.message);
        }
      } catch (err) {
        console.log("error => ", err);
        toast.error(
          `Somthing went wrong. ${err?.data?.message}. Please contact adminstrator.`
        );
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };

  const onChangePlan = () => {
    changePlanModal(
      {
        subscription: subscription?.subscription,
        cancelPlan: cancelSubscription,
        changePlan: changePlan,
      },
      (result) => {
        if (!_.isEmpty(result)) {
          console.log(result);
          if (result.type === "cancel") {
            let subs = {
              ...subscription,
              subscription: result.response,
            };
            updateSubscription(subs);
          }
          {
            let subs = {
              ...subscription,
              subscription: result.response,
            };
            updateSubscription(subs);
          }
        }
      }
    );
  };

  const onChangePaymentMethod = () => {
    changePaymentMethodModal(subscription, (cb) => {});
  };

  const cancelSubscription = async () => {
    const sub = subscription?.subscription;
    try {
      const url =
        subscription?.subscription?.provider_type === "paypal"
          ? "public/paypal/subscription/cancel"
          : "public/subscribes/cancel";
      const { data } = await http.post(url, {
        subscription_id: sub.subscription_id,
      });
      return data.data;
    } catch (err) {
      console.error("cancelSubscription", err);
    }
  };

  const changePlan = async (price_id) => {
    const sub = subscription?.subscription;
    try {
      const url =
        subscription?.subscription?.provider_type === "paypal"
          ? "public/paypal/subscription/change"
          : "public/subscribes/change";
      const { data } = await http.post(url, {
        subscription_id: sub.subscription_id,
        price_id: price_id,
        success_url: `${BASE_URL}/subscription/success`,
        cancel_url: `${BASE_URL}/plans`,
      });

      if (subscription?.subscription?.provider_type === "paypal") {
        window.location.href = data.url;
        return;
      }
      return data.data;
    } catch (err) {
      console.error("changePlan", err);
    }
  };

  const navigate = () => {
    if (typeof onClick === "undefined") {
      history.push("/plans");
    }
  };
  
  const deleteAccount = () => {
    deleteModal(
      {
        userData
      },
      (callback) => {
        if (callback && callback.success) {
          handleLogout();
        } 
      }
    );
  };

  const handleLogout = async () => {
    try {
      localstorage.clear();
      resetSubscription();
      dispatch({ type: "DESTROY_SESSION" });
      history.push(`/login`); // Redirect to current page
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className="mt-6 w-full container-max-width bg-white rounded-md">
      {/* content */}
      <div className="pt-12.5 pb-10 px-7.5 1md:pl-24.5 1md:pr-56">

      <div className="w-full 3md:flex">
          <div className="w-full mb-5 3md:w-6/20 3md:mb-0">
            <div className="w-full flex flex-row gap-x-2 items-center">
              <p className="text-lg text-lightBlack font-semibold">Your Plan</p>
              <PopInfo
                containerClass="relative"
                type="your_plan"
                responsive={{
                  left: "115px",
                  right: "115px",
                }}
              />
            </div>
          </div>
          {/* forms */}
          {!_.isEmpty(subscription) && subscription.subscription ? (
            <div className="w-full 3md:w-14/20">
              <div className="bg-modalGray rounded-lg p-8 mb-4">
                <div className="flex justify-between mb-2">
                  <h1 className="text-lg font-bold">
                    {subscription.subscription.name}
                  </h1>
                  <h1 className="text-lg text-primary font-bold">
                    {subscription.subscription?.term.toLowerCase() === "month"
                      ? "Monthly"
                      : subscription.subscription?.term.toLowerCase() === "year"
                      ? "Yearly"
                      : "Lifetime"}{" "}
                    Plan
                  </h1>
                </div>
                <p className="text-sm">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Odio facilisis mauris sit amet massa.
                </p>
              </div>
              <Button
                key="save-btn-category"
                buttonName="Change Plan"
                buttonClass="border-2 border-darkPrimary py-2 px-8 hover:border-dark"
                buttonTextClass="text-darkPrimary text-sm font-semibold flex items-center"
                onClick={onChangePlan}
              />
            </div>
          ) : (
            // <div className="w-full 3md:w-14/20 flex bg-modalGray rounded-lg p-8 mb-4">
            //   <h1 className="text-darkGray">No plan.</h1>
            // </div>
            <div className="w-full 3md:w-14/20">
              <div className="bg-modalGray rounded-lg p-8 mb-4">
                <div className="flex justify-between mb-2">
                  <h1 className="text-xl font-bold">
                  Free Plan
                  </h1>
                </div>
                <p className="text-sm flex items-center gap-6">
                <div className="pl-6">
                  <LockBlueIcon />
                </div>
                <div>
                {/* md:mt-0 md:text-start 
                md:flex-row justify-center md:justify-start md:mb-0.5*/}
                <div className={`flex-1 text-center mt-4`}>
                    <div className={`flex items-center`}>
                      <p className="font-semibold text-black text-xl">
                        Upgrade to Unlock
                      </p>
                      <p className="text-xs text-primary font-bold border rounded-md ml-2 px-2">
                        Premium
                      </p>
                    </div>
                    <p className="text-darkGray">
                      Join the 18,322 others and unlock more features
                    </p>
                  </div>
                </div>
                </p>
              </div>
              <Button
                key="save-btn-category"
                buttonName="Upgrade Plan"
                buttonClass="border-2 border-darkPrimary py-2 px-8 hover:border-dark"
                buttonTextClass="text-darkPrimary text-sm font-semibold flex items-center"
                onClick={navigate}
              />
            </div>
          )}
        </div>

        <div className="w-full 3md:flex mt-8">
          <div className="w-full mb-5 3md:w-6/20 3md:mb-0">
            <div className="w-full flex flex-row gap-x-2 items-center">
              <p className="text-lg text-lightBlack font-semibold">
                Payment / Billing
              </p>
              <PopInfo
                containerClass="relative"
                type="payment_billing"
                responsive={{
                  left: "155px",
                  right: "155px",
                }}
              />
            </div>
          </div>
          {/* forms */}
          {!_.isEmpty(subscription) && subscription.payment_method ? (
            <div className="w-full 3md:w-14/20">
              <div className="flex justify-between bg-modalGray rounded-lg p-8 mb-4">
                <div className="flex flex-col justify-between mb-2">
                  {subscription.payment_method.last_digits && (
                    <h1 className="text-lg font-bold">
                      **** **** **** {subscription.payment_method.last_digits}
                    </h1>
                  )}
                  {subscription.payment_method.subscriber && (
                    <h1 className="text-lg font-bold">
                      {subscription.payment_method.subscriber}
                    </h1>
                  )}
                  <p className="text-sm">
                    Your next billing date is{" "}
                    {moment(userData.user.next_payment_date).format(
                      "DD MMMM YYYY"
                    )}
                  </p>
                </div>
                {subscription.payment_method.last_digits && <CCIconSVG />}
                {subscription.payment_method.subscriber && <PaypalIconSVG />}
              </div>
              <div className="flex gap-2">
                <Button
                  key="change-payment-method"
                  buttonName="Change Payment Method"
                  buttonClass="border-2 border-darkPrimary py-2 px-8 hover:border-dark"
                  buttonTextClass="text-darkPrimary text-sm font-semibold flex items-center"
                  onClick={onChangePaymentMethod}
                />
                <Button
                  key="billing-history"
                  buttonName="Billing History"
                  buttonClass="border-2 border-darkPrimary py-2 px-8 hover:border-dark"
                  buttonTextClass="text-darkPrimary text-sm font-semibold flex items-center"
                  onClick={() => history.push("/billings/history")}
                />
              </div>
            </div>
          ) : (
            <div className="w-full 3md:w-14/20">
              <div className="w-full bg-modalGray rounded-lg p-8 mb-4">
                <h1 className="font-semibold text-black text-xl mb-2">No Cards added yet</h1>
                <p className="text-darkGray">Please add your payment method for easier transaction</p>
              </div>
              <div className="flex gap-2">
                <Button
                  key="add-payment-method"
                  buttonName="Add Payment Method"
                  buttonClass="border-2 border-darkPrimary py-2 px-8 hover:border-dark"
                  buttonTextClass="text-darkPrimary text-sm font-semibold flex items-center"
                  onClick={navigate}
                />
                <Button
                  disabled={true}
                  key="billing-history"
                  buttonName="Billing History"
                  buttonClass="border-2 border-darkPrimary py-2 px-8 hover:border-dark"
                  buttonTextClass="text-darkPrimary text-sm font-semibold flex items-center"
                  onClick={() => history.push("/billings/history")}
              />
              </div>
            </div>
          )}
        </div>
        
        <div className="w-full mt-8 3md:flex">
          <div className="w-full mb-5 3md:w-6/20 3md:mb-0">
            <div className="w-full flex flex-row gap-x-2 items-center">
              <p className="text-lg text-lightBlack font-semibold">
                Account Contact
              </p>
              <PopInfo
                containerClass="relative"
                type="account_contact"
                responsive={{
                  left: "155px",
                  right: "155px",
                }}
              />
            </div>
          </div>
          {/* forms */}
          <div className="w-full 3md:w-14/20">
            <Input
              inputContainerClass="form-input-container"
              value={state?.email}
              name="email"
              outsideLabel="Email Address"
              placeholder="tedlasso@gmail.com"
              outsideLabelClass="text-sm font-bold text-darkerGray marked"
              noLabelPaddingY="1rem"
              type="email"
              forEmailVerification={true}
              verified={false}
              onChange={handleInputChange}
              errorMessage={state.validationErrors?.email}
            />
            <Input
              inputContainerClass="form-input-container"
              value={state?.accountLink}
              name="accountLink"
              outsideLabel="Account Link"
              placeholder="John"
              outsideLabelClass="text-sm font-bold text-darkerGray marked"
              noLabelPaddingY="1rem"
              type="text"
              onChange={handleInputChange}
              errorMessage={state.validationErrors?.accountLink}
            />
          </div>
        </div>
        <div className="w-full mt-5 3md:flex">
          <div className="w-full mb-5 3md:w-6/20 3md:mb-0">
            <div className="w-full flex flex-row gap-x-2 items-center">
              <p className="text-lg text-lightBlack font-semibold">
                Change Password
              </p>
              <PopInfo
                containerClass="relative"
                type="change_password"
                responsive={{
                  left: "165px",
                  right: "165px",
                }}
              />
            </div>
          </div>
          {/* forms */}
          <div className="w-full 3md:w-14/20">
            <Input
              inputContainerClass="form-input-container"
              value={state.old_password}
              outsideLabel="Old Password"
              outsideLabelClass="text-sm font-bold text-darkerGray marked"
              noLabelPaddingY="1rem"
              name="old_password"
              masked={true}
              visible={state.showPasswordConfirm}
              onChangeVisible={() =>
                setState((prev) => ({
                  ...prev,
                  showPasswordOld: !prev.showPasswordOld,
                }))
              }
              type={state.showPasswordOld ? "text" : "password"}
              onChange={handleInputChange}
              errorMessage={state.validationErrors?.old_password}
            />
            <Input
              inputContainerClass="form-input-container"
              showInputValidation={true}
              value={state.password}
              outsideLabel="New Password"
              outsideLabelClass="text-sm font-bold text-darkerGray marked"
              noLabelPaddingY="1rem"
              name="password"
              masked={true}
              validationPassed={Object.values(passwordValidation).every(
                (item) => item === true
              )}
              visible={state.showPassword}
              onChangeVisible={() =>
                setState((prev) => ({
                  ...prev,
                  showPassword: !prev.showPassword,
                }))
              }
              type={state.showPassword ? "text" : "password"}
              onChange={handleInputChange}
              errorMessage={state.validationErrors?.password}
            />
            <PasswordValidation passwordValidation={passwordValidation} />
            <Input
              inputContainerClass="form-input-container"
              value={state.comfirm_password}
              outsideLabel="Confirm Password"
              outsideLabelClass="text-sm font-bold text-darkerGray marked"
              noLabelPaddingY="1rem"
              name="comfirm_password"
              masked={true}
              visible={state.showPasswordConfirm}
              onChangeVisible={() =>
                setState((prev) => ({
                  ...prev,
                  showPasswordConfirm: !prev.showPasswordConfirm,
                }))
              }
              type={state.showPasswordConfirm ? "text" : "password"}
              onChange={handleInputChange}
              errorMessage={state.validationErrors?.comfirm_password}
            />
          </div>
        </div>
        <div className="w-full mt-5 3md:flex">
          <div className="w-full mb-5 3md:w-6/20 3md:mb-0">
            <div className="w-full flex flex-row gap-x-2 items-center">
              <p className="text-lg text-lightBlack font-semibold">
                Delete Account
              </p>
              {/* <PopInfo
                containerClass="relative"
                type="change_password"
                responsive={{
                  left: "165px",
                  right: "165px",
                }}
              /> */}
            </div>
          </div>
          {/* forms */}
          <div className="w-full 3md:w-14/20">
            <p className="text-base">If you delete your account, you'll be permanently removing if from our systems - you can't undo it.</p>
            <div>
              <button className="text-red font-bold flex gap-1 items-center cursor-pointer w-auto" onClick={deleteAccount}>
                <AiFillDelete/>
                <p>Delete Account</p>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* footer */}
      <div className="py-5.5 px-7.5 1md:pl-24.5 1md:pr-56 border-t-2 border-gray">
        <div className="w-full flex justify-end">
          <Button
            buttonName="Cancel"
            buttonTextClass="text-xs text-lightBlack font-semibold"
            buttonRadius="6px"
            buttonClass="relative py-4 px-7.5 bg-thBg mr-3.5 hover:bg-gray"
            onClick={() => history.goBack()}
          />
          <Button
            buttonName="Save Changes"
            buttonTextClass="text-xs text-white font-semibold flex items-center"
            buttonRadius="6px"
            buttonClass="relative py-4 px-10 bg-primary hover:bg-darkPrimary"
            onClick={handleSave}
            loadingIcon={
              loading ? (
                <LoadingIndicator className="text-white w-4 h-4 ml-2" />
              ) : null
            }
          />
        </div>
      </div>
    </div>
  );
}
