import React from "react";
import ModalMobile from "components/global/modalMobile";
import "./styles.css";
import { FaTimes } from "react-icons/fa";
import { Document, pdfjs,Page  } from 'react-pdf'
import { FaCopy } from "react-icons/fa";
//TODO: config nginx to allow application/javascript js mjs;
//use cdn instead for now
// pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

// @ts-expect-error This does not exist outside of polyfill which this is doing
// Trying to resolve Promise.withResolvers is not a function for old version of ios < 15 or XR
if (typeof Promise.withResolvers === 'undefined') {
  if (window)
      window.Promise.withResolvers = function () {
          let resolve, reject;
          const promise = new Promise((res, rej) => {
              resolve = res;
              reject = rej;
          });
          return { promise, resolve, reject };
      };
}
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PdfViewerModal({ hide, url, ...props }) {
  const [numPages, setNumPages] = React.useState(null);
  const [isMobile, setIsMobile] = React.useState(false);
  const [pageWidth, setPageWidth] = React.useState(window.innerWidth);
  const [progress, setProgress] = React.useState(0);

  React.useEffect(()=>{
    const userAgent = window.navigator.userAgent;
    const mobileDevices = /Mobi|Android|iPhone|Ipad/;
    setIsMobile(mobileDevices.test(userAgent));
    const handleResize = () => setPageWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  },[])

  const onLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onLoadProgress = ({ loaded, total }) =>{
    const percentage = Math.round((loaded / total) * 100);
    setProgress(percentage);
  }

  const loadingSection = ()=>{
    return (
      <div className="relative w-screen h-screen">
        <div className=" flex flex-col w-full h-full items-center">
          <div className="relative w-36 h-36 mt-16">
            <div className="absolute inset-0 rounded-full bg-gray-200"></div>
              <div
                className="absolute inset-0 rounded-full"
                style={{
                  background: `conic-gradient(#2184db ${progress * 3.6}deg, #D1D5DB 0deg)`,
                }}
              >
              </div>
              <div className="absolute inset-2 rounded-full bg-white flex items-center justify-center">
                <span className="text-3xl font-semibold text-white rounded-full bg-[#2184db] flex items-center justify-center p-5">
                  <FaCopy/>
                </span>
              </div>
          </div> 
        <h1 className="text-xl text-[#2184db] mt-10 font-semibold h-16 w-full text-xl text-[#2184db] mt-10 font-semibold h-16 w-full flex justify-center">Loading File {progress}% ...</h1>
      </div>
      </div>
    );
  }

  return (
    <ModalMobile
      width={`${isMobile ? 'w-full' : 'w-19/20'}`}
      centerModal="modal-scroll overflow-y-auto"
      contentClassName="rounded-lg overflow-auto custom-style"
      wrapperClassName="overflow-visible custom-style shadow-none"
    >
      <div className="w-full">
        <div className="w-full flex justify-end">
          <button onClick={hide}>
            <FaTimes className="text-2xl text-white" />
          </button>
        </div>
        {
          !isMobile ?
            <iframe
              title={props?.title}
              className="w-full"
              style={{ height: "calc(100vh - 100px)" }}
              src={url}
        /> : 
            <div className=" flex justify-center" style={{ height: '90svh', overflowY: 'auto', background:'white' }}>
    
              <Document
                file={url}
                onLoadSuccess={onLoadSuccess}
                onLoadProgress={onLoadProgress}
                loading={loadingSection}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <div key={index} style={{ paddingBottom: '10px' }}>
                      <Page key={index} pageNumber={index + 1}  
                      renderTextLayer={false} 
                      renderAnnotationLayer={false}
                      width={pageWidth}
                      />
                  </div>
                ))}
              </Document>
            </div>
        }
      </div>
    </ModalMobile>
  );
}