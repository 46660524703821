import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from './actions'

export const useAccessPermissions = () => {
    const {
        refresh,
        initialize,
        permissions = [],
    } = useSelector((state) => state.accessPermission);

    const dispatch = useDispatch();

    const setPermissions = useCallback(
        (payload) => dispatch(actions.setPermissions(payload)),
        [dispatch]
    );

    return {
        refresh,
        initialize,
        permissions,
        setPermissions,
    };
} 