import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import _ from "lodash";

/** ======== Component ======== */
import Button from "components/button/button";
import TemplateOne from "./template-one";
import TemplateTwo from "./template-two";
import TemplateThree from "./template-three";
import TemplateFour from "./template-four";

export default function ResumeItem({
  link_id,
  template_name,
  template_color = {},
  personal_info = {},
  social_media = {},
  more_info = {},
  updated_at,
  resume_milestones = {},
  onDownload = () => {},
  onEdit = () => {},
  onPreview = () => {},
  profile_image_withURL,
}) {
  const [milestones, setMilestones] = useState({});

  const [primaryColor, secondaryColor, tertiaryColor, quarternary] =
    useMemo(() => {
      if (template_color) {
        return template_color.value;
      }
      return ["", "", "", ""];
    }, [template_color]);

  useEffect(() => {
    /** set featured milestone */
    if (resume_milestones.featured && !_.isEmpty(resume_milestones.featured)) {
      let featured = [];
      resume_milestones.featured.forEach((f) => {
        if(f.milestone){
          featured.push(f.milestone);
        }
      });
      setMilestones((prev) => ({
        ...prev,
        featured,
      }));
    }
    /** set current milestone */
    if (resume_milestones.current && !_.isEmpty(resume_milestones.current)) {
      let current = [];
      resume_milestones.current.forEach((c) => {
        if(c.milestone){
        current.push(c.milestone);
        }
      });
      setMilestones((prev) => ({
        ...prev,
        current,
      }));
    }
    /** set past milestone */
    if (resume_milestones.past && !_.isEmpty(resume_milestones.past)) {
      let past = [];
      resume_milestones.past.forEach((p) => {
        if(p.milestone){
          past.push(p.milestone);
        }
      });
      setMilestones((prev) => ({
        ...prev,
        past,
      }));
    }
  }, [resume_milestones]);

  return (
    <div className="w-full border border-primary rounded-md overflow-hidden">
      <div className="w-full relative cursor-pointer template-hover overflow-hidden">
        <div className="w-full h-full bg-white bg-opacity-40 absolute z-9999 top-0 rounded-t-md hidden-template-hover">
          <div className="w-full h-full grid justify-center items-center px-9.5">
            {/* actions */}
            <div className="w-full flex flex-col space-y-3">
              <Button
                buttonName="Preview Resume"
                buttonTextClass="text-xs font-semibold text-pr"
                buttonRadius="6px"
                buttonClass="bg-transparent border-2 border-primary px-8 py-4.6"
                onClick={onPreview}
              />
              <Button
                buttonName="Edit Resume"
                buttonTextClass="text-xs font-semibold text-white"
                buttonRadius="6px"
                buttonClass="bg-primary w-full px-8 py-4.6"
                onClick={onEdit}
              />
              <Button
                buttonName="Share/Download"
                buttonTextClass="text-xs font-semibold text-white"
                buttonRadius="6px"
                buttonClass="bg-lightBlack px-8 py-4.6"
                onClick={onDownload}
              />
            </div>
            {/* end actions */}
          </div>
        </div>
        {template_name === "Template Name 1" && (
          <TemplateOne
            {...{
              more_info,
              personal_info,
              social_media,
              primaryColor,
              secondaryColor,
              tertiaryColor,
              milestones,
              profile_image_withURL,
            }}
          />
        )}
        {template_name === "Template Name 2" && (
          <TemplateTwo
            {...{
              more_info,
              personal_info,
              social_media,
              primaryColor,
              secondaryColor,
              tertiaryColor,
              quarternary,
              milestones,
              profile_image_withURL,
            }}
          />
        )}
        {template_name === "Template Name 3" && (
          <TemplateThree
            {...{
              more_info,
              personal_info,
              social_media,
              primaryColor,
              secondaryColor,
              tertiaryColor,
              quarternary,
              milestones,
              profile_image_withURL,
            }}
          />
        )}
        {template_name === "Template Name 4" && (
          <TemplateFour
            {...{
              more_info,
              personal_info,
              social_media,
              primaryColor,
              secondaryColor,
              tertiaryColor,
              quarternary,
              milestones,
              profile_image_withURL,
            }}
          />
        )}
      </div>
      <div className="w-full py-4 px-3 border-t border-resumeBorder template-hover-footer">
        <p className="text-lightBlack text-base font-medium">{template_name}</p>
        <p className="text-darkerGray text-sm leading-6">
          Edited last {moment(updated_at).format("DD MMMM YYYY hh:ss A")}
        </p>
      </div>
    </div>
  );
}
