import React, {useState,useRef} from "react";
import { IoEllipsisVertical, IoBanOutline,IoTrashOutline} from "react-icons/io5";
import Popup from "reactjs-popup";

import ProfileDefault from "assets/images/profile_default.png";

export default function MessageHeader({ image, name = "", status = "", blockMessageHandler = ()=>{},
                                      message_block=null, owner_id=null , deleteConversation=()=>{}, conversations = [] }) {
  const [showMoreOption, setShowMoreOption] = useState();
  const optionRef = useRef();
  return (
    <div className="w-full px-5 py-4.5 border-b border-gray">
      <div className="flex justify-between items-center">
        {/* left */}
        <div className="flex items-center space-x-3 2sm:space-x-6">
          <div className="h-13 w-13 3sm:h-15 3sm:w-15 2sm:h-17.5 2sm:w-17.5 rounded-full">
            <img
              src={image || ProfileDefault}
              alt="milestone-profile-avatar"
              className="w-full h-full rounded-full"
            />
          </div>
          <div>
            <p className="hidden sm:block text-base 3sm:text-xl 2sm:text-2xl text-lightBlack font-bold tracking-tighter 2sm:leading-7.5">
              {name}
            </p>
            <p className="hidden sm:block text-xs 3sm:text-sm 2sm:text-base text-darkerGray tracking-tightest 2sm:leading-7.5">
              {status && `Last seen ${status}`}
            </p>
          </div>
        </div>
        {/* right */}
        {/* <button className="text-lightBlack">
          <IoEllipsisVertical className="text-xl 2sm:text-2xl" />
        </button> */}
        <Popup
              ref={optionRef}
              position="bottom right"
              open={showMoreOption}
              onClose={() => setShowMoreOption(false)}
              arrow={false}
              contentStyle={{ 
                marginTop: 10, 
                marginLeft: 5, 
                zIndex: 9999 
              }}
              trigger={
                <button
                  className="bg-thBg w-5 h-5 2sm:w-10 2sm:h-10 rounded-md"
                  onClick={() => setShowMoreOption((open) => !open)}>
                  <IoEllipsisVertical className="m-auto text-1xs 2sm:text-base" />
                </button>
              }>
             <div className="flex flex-col shadow-lg rounded-md bg-slate-50">
             <ul className="">  
                <li className="hover:bg-gray">
                  <button 
                    disabled={conversations[0]?.disable_delete_btn && conversations.length === 1} onClick={()=>{
                    optionRef.current.close();
                    setShowMoreOption(false); 
                    deleteConversation()
                  }} 
                      className={`flex flex-row items-center text-md px-6 py-2 ${(conversations[0]?.disable_delete_btn && conversations.length === 1) && 'cursor-not-allowed'}`} style={{'color':'red'}}>
                        <IoTrashOutline className="mr-3" />
                        <div >Delete this Conversation</div>
                    </button>
                </li> 
              {/* <li className="hover:bg-gray hover:rounded-b-md"> 
                <button onClick={()=>blockMessageHandler(message_block?.status || null)}
                  disabled={message_block?.status === 1 && owner_id !== message_block?.owner_id}
                  className={`flex flex-row items-center text-md px-6 py-2 ${message_block?.status === 1 && owner_id !== message_block?.owner_id && 'cursor-not-allowed'}`}>
                    <IoBanOutline className="mr-3" />
                    {
                      message_block ?
                     <div>
                        {
                          owner_id === message_block?.owner_id ?
                          <div>
                            {
                              message_block?.status === 0 ? 'Block' : 'Unblock'
                            }
                          </div>
                          :
                          <div>
                            Block
                          </div>
                        }
                     </div>
                     :
                     <div>
                      Block
                     </div>
                    }
                </button>
                </li> */}
            </ul>
             </div>
            </Popup>
      </div>
    </div>
  );
}
