import { createContext, useCallback, useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment";

export const SubscriptionContext = createContext();

let initialSubscription = {
  name: "Free Account",
};

export const SubscriptionProvider = ({ children }) => {
  const { http } = global.services;

  const [subscription, setSubscription] = useState(initialSubscription);
  const [billingHistory, setBillingHistory] = useState([]);

  const isSubscribed = useMemo(() => {
    console.log(subscription)
    if (
      (subscription?.subscription?.status?.toLowerCase() === 'active' ||
      subscription?.subscription?.status?.toLowerCase() === 'trialing') &&
      (subscription?.subscription?.ends_at && 
        moment(subscription?.subscription?.ends_at) > moment())
    ) {
      return true;
    }

    return true;
  }, [subscription]);

  const resetSubscription = useCallback((payload) => {
    setSubscription(initialSubscription);
  }, []);

  const getSubscription = async () => {
    try {
      const { data } = await http.get("public/subscribes");
      if (!_.isEmpty(data.payment_method) || !_.isEmpty(data.subscription)) {
        setSubscription(data);
      } else {
        setSubscription(initialSubscription);
      }
    } catch (error) {
      console.log("getSubscription", error);
    }
  };

  const updateSubscription = (payload) => {
    setSubscription(payload);
  };

  const getBillingHistory = async () => {
    try {
      const { data } = await http.get("public/billing-history");
      console.log("getBillingHistory", data);
      setBillingHistory(data.data);
    } catch (error) {
      console.log("getSubscription", error);
    }
  };

  return (
    <SubscriptionContext.Provider
      value={{
        isSubscribed,
        subscription,
        billingHistory,
        getSubscription,
        getBillingHistory,
        resetSubscription,
        updateSubscription,
      }}>
      {children}
    </SubscriptionContext.Provider>
  );
};
