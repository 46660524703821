const ResumeStepFiveIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3767_1855)">
        <path
          d="M6.17991 0C14.0664 0 21.9521 0 29.8378 0C29.8425 0.0125281 29.844 0.0336694 29.8519 0.0352354C30.9324 0.348439 31.58 1.0414 31.7021 2.17755C31.7217 2.35842 31.8164 2.34355 31.9347 2.34355C32.3637 2.34276 32.7936 2.34041 33.2227 2.34355C34.5977 2.35451 35.614 3.36146 35.614 4.73799C35.6172 15.6985 35.6172 26.6591 35.614 37.6197C35.614 38.9719 34.5938 39.9953 33.2446 39.9961C25.7121 40.0008 18.1795 40.0008 10.647 39.9961C9.31978 39.9953 8.30657 38.9711 8.29012 37.6463C8.28542 37.2556 8.27211 36.864 8.29482 36.4749C8.31205 36.186 8.25019 36.0583 7.92367 36.085C7.52277 36.1178 7.11796 36.0975 6.71471 36.0912C5.73438 36.0763 4.90752 35.5 4.53951 34.5894C4.49879 34.4876 4.5121 34.3529 4.38525 34.2958V1.79701C4.5168 1.72653 4.50192 1.58089 4.54969 1.46971C4.80651 0.875404 5.22777 0.447098 5.81738 0.179309C5.93953 0.123715 6.09926 0.140159 6.17991 0ZM30.1502 18.064C30.1502 12.9353 30.1502 7.80738 30.1502 2.67867C30.1502 1.78917 29.927 1.5621 29.0516 1.5621C21.7281 1.5621 14.4039 1.5621 7.08037 1.5621C6.16503 1.5621 5.94657 1.77743 5.94657 2.68102C5.94657 12.9251 5.94657 23.1684 5.94657 33.4125C5.94657 34.3013 6.17052 34.5299 7.04435 34.5299C14.3812 34.5299 21.718 34.5299 29.0548 34.5299C29.9153 34.5299 30.1502 34.2989 30.1502 33.4493C30.1502 28.3206 30.1502 23.1927 30.1502 18.064ZM34.0543 21.182C34.0543 15.805 34.0543 10.4273 34.0543 5.05041C34.0543 4.93295 34.059 4.8155 34.0519 4.69884C34.0253 4.23138 33.7246 3.92601 33.2564 3.90956C32.8923 3.89703 32.525 3.9354 32.1641 3.89782C31.7851 3.85867 31.7013 3.98082 31.7021 4.35744C31.7139 14.0707 31.7115 23.7831 31.7115 33.4963C31.7115 35.1391 30.7625 36.0928 29.1276 36.0928C23.1156 36.0928 17.1045 36.0928 11.0925 36.0944C10.6916 36.0944 10.1474 35.9558 9.92818 36.1593C9.67605 36.3935 9.85771 36.9424 9.85301 37.3542C9.84283 38.2053 10.0832 38.4363 10.9437 38.4363C18.2829 38.4363 25.6213 38.4363 32.9604 38.4363C33.8209 38.4363 34.0535 38.2053 34.0535 37.3526C34.0535 31.9624 34.0535 26.5722 34.0535 21.182H34.0543Z"
          fill="#333333"
        />
        <path
          d="M18.0487 28.2807C14.9527 28.2807 11.8574 28.2744 8.76142 28.2885C8.38793 28.2901 8.27674 28.1914 8.27909 27.8109C8.29632 25.4556 8.29397 23.0995 8.27987 20.7442C8.27752 20.3997 8.37618 20.3042 8.71992 20.3049C14.937 20.3143 21.1549 20.3143 27.372 20.3049C27.7118 20.3049 27.816 20.3942 27.8144 20.7419C27.7995 23.1105 27.8003 25.4791 27.8144 27.8477C27.8167 28.1922 27.7181 28.2885 27.3743 28.2869C24.2658 28.2744 21.1572 28.2799 18.0479 28.2799L18.0487 28.2807Z"
          fill="#0C6ED1"
        />
        <path
          d="M25.0747 10.9536C25.0739 14.8357 21.9121 17.9709 18.0018 17.9678C14.1306 17.9646 11.0173 14.7958 11.0212 10.8627C11.0252 7.01817 14.1995 3.90336 18.1114 3.9065C21.934 3.90963 25.0755 7.08864 25.0747 10.9536ZM21.173 8.93889C21.1651 8.90601 21.1526 8.81596 21.1221 8.73218C20.9028 8.13866 20.2615 8.01651 19.7706 8.49571C18.9985 9.24897 18.2445 10.0202 17.4795 10.7813C17.2877 10.9724 17.1037 11.2652 16.8813 11.3036C16.6636 11.3404 16.5086 10.9912 16.316 10.822C15.9244 10.4767 15.4954 10.4524 15.1673 10.7664C14.8282 11.0906 14.8353 11.5494 15.2056 11.9394C15.5455 12.298 15.8955 12.6464 16.2525 12.987C16.6808 13.3958 17.0849 13.3989 17.5132 12.9753C18.6407 11.8603 19.7581 10.7351 20.8762 9.6115C21.0438 9.44315 21.1698 9.24896 21.1722 8.93968L21.173 8.93889Z"
          fill="#0C6ED1"
        />
        <path
          d="M21.173 8.93889C21.1651 8.90601 21.1526 8.81596 21.1221 8.73218C20.9028 8.13866 20.2615 8.01651 19.7706 8.49571C18.9985 9.24897 18.2445 10.0202 17.4795 10.7813C17.2877 10.9724 17.1037 11.2652 16.8813 11.3036C16.6636 11.3404 16.5086 10.9912 16.316 10.822C15.9244 10.4767 15.4954 10.4524 15.1673 10.7664C14.8282 11.0906 14.8353 11.5494 15.2056 11.9394C15.5455 12.298 15.8955 12.6464 16.2525 12.987C16.6808 13.3958 17.0849 13.3989 17.5132 12.9753C18.6407 11.8603 19.7581 10.7351 20.8762 9.6115C21.0438 9.44315 21.1698 9.24896 21.1722 8.93968L21.173 8.93889Z"
          fill="#0C6ED1"
        />
        <path
          d="M18.0456 30.6243C20.9459 30.6243 23.8462 30.6243 26.7472 30.6243C26.8772 30.6243 27.0087 30.6157 27.1372 30.6345C27.5146 30.6877 27.7957 31.0095 27.8058 31.3838C27.816 31.773 27.5326 32.1183 27.1411 32.1762C27.0134 32.195 26.8819 32.1864 26.7519 32.1864C20.9514 32.1864 15.1501 32.1864 9.34953 32.1864C9.23286 32.1864 9.11463 32.1934 8.99874 32.1809C8.58375 32.1355 8.28307 31.7941 8.29168 31.3877C8.3003 30.997 8.59627 30.6744 8.99483 30.6305C9.11071 30.618 9.22895 30.6243 9.34562 30.6243C12.2459 30.6243 15.1461 30.6243 18.0472 30.6243H18.0456Z"
          fill="#333333"
        />
        <path
          d="M13.9158 11.8778C13.7468 11.7193 13.6519 11.5043 13.6519 11.2802C13.6519 11.056 13.7468 10.8411 13.9158 10.6826C14.0848 10.5241 14.3141 10.435 14.5531 10.435C14.7921 10.435 15.0214 10.5241 15.1904 10.6826L17.2514 12.6167V7.34369C17.2514 7.11993 17.3462 6.90533 17.5149 6.74711C17.6837 6.58889 17.9125 6.5 18.1511 6.5C18.3897 6.5 18.6186 6.58889 18.7873 6.74711C18.956 6.90533 19.0508 7.11993 19.0508 7.34369V12.6167L21.1133 10.6819C21.2823 10.5234 21.5116 10.4343 21.7506 10.4343C21.9896 10.4343 22.2189 10.5234 22.3879 10.6819C22.5569 10.8404 22.6519 11.0553 22.6519 11.2795C22.6519 11.5036 22.5569 11.7186 22.3879 11.8771L18.7891 15.2519C18.7055 15.3305 18.6062 15.3929 18.4969 15.4355C18.3875 15.4781 18.2703 15.5 18.1519 15.5C18.0334 15.5 17.9162 15.4781 17.8068 15.4355C17.6975 15.3929 17.5982 15.3305 17.5146 15.2519L13.9158 11.8778Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3767_1855">
          <rect
            width="31.2311"
            height="40"
            fill="white"
            transform="translate(4.38452)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ResumeStepFiveIconSVG;
