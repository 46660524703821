import React, { useState, useCallback } from "react";
import Modal from "components/global/modal";
import Button from "components/button/button";
import ModalHeader from "components/global/modal-header";
import Input from "components/input/input";
import { isValidYoutubeVideoLink } from "utils/utils";
import LoadingIndicator from "assets/svg/loading-indicator";
import _ from "lodash";
import update from "immutability-helper";
import { useDropzone } from "react-dropzone";
// import { FaPlus, FaMinus } from "react-icons/fa";
import validator from "validator";
import CustomCheckbox from "components/checkbox/custom-checkbox";
import { useDialogHook } from "utils/customhooks";
import UploadSizeModal from "components/file-upload/upload-size-modal";
import AddPhotoDropZone from "assets/svg/add-photo-dropzone-svg";  
import { KB, MB, maxUploadSize } from "utils/constant";
import { Close } from "assets/svg/sidebar/icons";

export default function AddVideoModal({ hide, userData, profile, ...props }) {
  const { http } = global.services;

  const [links, setLinks] = useState([""]);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [errorMessages, setErrorMessages] = useState({});
  const [adultContent, setAdultContent] = useState(false);
  const [photo, setPhoto] = useState(null); 
  const uploadSizeModal = useDialogHook(UploadSizeModal);

  React.useEffect(() => {
    if (photo) {
      setPhotoPreview();
    }
  }, [photo]);

  const [preview, setPreview] = useState({
    name: "",
    size: 0,
    path: ""
  });

  const submit = async () => {
    let errors = {};
    setErrorMessages(errors);

    try {
      setSubmitLoading(true); 
      let dataPayload = {
        // user_profile_id: profile.id,
        // type: "video",
        links: [],
        // is_adult_content: adultContent,
      };

      (links || []).forEach((link, index) => {
        if (link) {
          if (validator.isURL(link) && isValidYoutubeVideoLink(link)) {
            dataPayload.links.push(link);
          } else {
            errors = {
              ...errors,
              [`link.${index}`]: "Invalid video or youtube link.",
            };
          }
        } else {
          errors = {
            ...errors,
            [`link.${index}`]: "This field is required.",
          };
        }
      });

      if (!_.isEmpty(errors)) {
        return setErrorMessages((prev) => ({ ...prev, ...errors }));
      }

      let payload = new FormData();
      payload.append(`user_profile_id`, profile.id);
      payload.append("type", "video");
      payload.append("links", dataPayload.links); 
      payload.append("is_adult_content", adultContent);
      payload.append(`thumbnail`, photo ? photo[0] : null);

      const { data } = await http.post("public/user-galleries", payload);
      if (data) {
        hide(true);
      }
    } catch (err) {
      if (err.status === 422){
        errors = {
          ...errors,
          ...err.data?.errors,
        };
        console.log(errors)
        return setErrorMessages((prev) => ({
          ...prev,
          ...errors
        }));
      }
        
        
      console.log(err);
      setSubmitLoading(false);
    } finally {
      setSubmitLoading(false);
    }
  };

  // const onAdd = () => {
  //   const copyLinks = _.clone(links);
  //   copyLinks.push("");
  //   setLinks(copyLinks);
  // };

  // const onRemove = (index) => {
  //   let copyLinks = _.clone(links);
  //   let result = update(copyLinks, { $splice: [[index, 1]] });
  //   if (result.length === 0) {
  //     result.push("");
  //   }
  //   setLinks(result);
  // };

  const onBlur = (index) => {
    let copyLinks = _.clone(links);
    let value = copyLinks[index];

    if (value && !value.includes("http://") && !value.includes("https://")) {
      value = `https://${value}`;
    }

    let result = update(copyLinks, {
      [index]: { $set: value },
    }); //remove

    setLinks(result);
  };

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles[0].size > maxUploadSize) {
      showUploadSizeModalError();
    } else {
      setPhoto(acceptedFiles);
    }
  }, []);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    noClickL: true,
    accept: {
      'image/*': [],
    }
  });

  const showUploadSizeModalError = () => {
    uploadSizeModal({}, (callback) => {
      console.log('callback', callback)
    })
  }
  

  const handleInputChange = (event, index) => {
    const copyLinks = _.clone(links);
    copyLinks[index] = event.target.value;
    setLinks(copyLinks);
  };
  const removeUploadedPhoto = () => {
    setPhoto(null);
  }

  const setPhotoPreview = () => {
    let size = photo[0].size;

    if (size > MB) {
      size /= MB;
      size = `${Math.round(size * 10) / 10}MB`;
    } else if (size > KB) {
      size /= KB;
      size = `${Math.round(size * 10) / 10}KB`;
    } else {
      size = `${size}B`;
    }
    
    setPreview({
      name: photo[0].name,
      size: size,
      path: URL.createObjectURL(photo[0])
    });
  }

  const Footer = () => {
    return (
      <>
        <div className="w-full flex items-center justify-start 1sm:justify-end space-x-2">
          <Button
            buttonName="Cancel"
            buttonClass="bg-thBg py-4 px-8 hover:bg-gray"
            buttonTextClass="text-black text-sm font-semibold"
            onClick={() => hide()}
            disabled={submitLoading}
          />
          <Button
            key="save-btn-category"
            buttonName="Save Links"
            buttonClass="bg-primary py-4 px-8 hover:bg-darkPrimary"
            buttonTextClass="text-white text-sm font-semibold flex items-center"
            onClick={submit}
            disabled={submitLoading}
            loadingIcon={
              submitLoading ? (
                <LoadingIndicator className="text-white w-4 h-4 ml-2" />
              ) : null
            }
          />
        </div>
      </>
    );
  };

  const toggleAdultContentCheckbox = (e) => {
    setAdultContent(!e.target.checked);
  }

  return (
    <Modal
      footer={<Footer />}
      width="w-full md:w-15/20 2md:w-11/20 lg:w-9/20 xl:w-6/20"
      contentClassName="rounded-lg overflow-auto custom-scroll"
      wrapperClassName="overflow-visible"
      footerClass="border-t border-gray">
      <div className="w-full">
        {/* header */}
        <ModalHeader title="Add Videos" hide={hide} />
        {/* content */}
        <div className="px-8.5 py-6">
          {/* profile */}
          {links.map((link, index) => (
            <div key={index} className="flex flex-column">
              <Input
                inputContainerClass="form-input-container"
                value={link}
                name={`input-link-${index}`}
                placeholder="e.g. https://example.com/video"
                outsideLabelClass="text-sm font-bold text-darkerGray"
                noLabelPaddingY="1rem"
                type="text"
                onChange={(e) => handleInputChange(e, index)}
                onBlur={() => onBlur(index)}
                errorMessage={errorMessages[`link.${index}`] || errorMessages?.video?.link}
                errorMessagePosition="bottom"
              />

          <div className="">
            <h1>Add a Thumbnail</h1>
                {/* dropzone */}
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="add-photo-dropzone">
                <AddPhotoDropZone />
              </div>
            </div>
              {
                photo && <div className="flex mt-4 items-center justify-center">
                  <div className="flex items-center p-2 space-x-4 h-[120px] rounded-md shadow-md w-full justify-between border-slate-200">
                    <div className="flex items-center p-2 space-x-4 text-ellipsis overflow-hidden">
                      <img className="w-[100px] h-[100px] rounded-md" src={preview.path} width={60} height={60} />
                      <div className="flex flex-column ">
                        <span className="">{preview.name}</span>
                        <span className="">{preview.size}</span>
                      </div>
                    </div>
                    
                    <div className="cursor-pointer" onClick={removeUploadedPhoto}>
                      <Close className="w-4 h-4" fill={"#333333"} />
                    </div>
                  </div>
                </div>
              }
                <div className="mt-2">
                  <CustomCheckbox
                    label={
                      <p className="checkbox-text">
                        This video does not contain adult content
                      </p>
                    }
                    name={"termsAndConditions"}
                    checked={!adultContent}
                    onChange={(e) => toggleAdultContentCheckbox(e)}
                    v2={true}
                  />
                </div>
              </div>

              {/* <button
                className="h-6.5 w-6.5 bg-lightBlack rounded-full"
                onClick={() => onRemove(index)}>
                <FaMinus className="m-auto text-white" />
              </button> */}
            </div>
          ))}

          {/* <Button
            buttonName="Add Another Link"
            buttonTextClass="text-sm text-white font-semibold flex items-center"
            iconPosition="left"
            icon={<FaPlus className="mr-3" />}
            buttonRadius="6px"
            buttonClass="relative py-4 px-9 bg-lightBlack"
            onClick={onAdd}
          /> */}
        </div>
        {/* end content */}
      </div>
    </Modal>
  );
}
