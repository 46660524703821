const CalendarDayIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3708_9595)">
        <path
          d="M8 12H6C4.897 12 4 12.897 4 14V16C4 17.103 4.897 18 6 18H8C9.103 18 10 17.103 10 16V14C10 12.897 9.103 12 8 12ZM6 16V14H8V16H6ZM19 2H18V1C18 0.448 17.553 0 17 0C16.447 0 16 0.448 16 1V2H8V1C8 0.448 7.553 0 7 0C6.447 0 6 0.448 6 1V2H5C2.243 2 0 4.243 0 7V19C0 21.757 2.243 24 5 24H19C21.757 24 24 21.757 24 19V7C24 4.243 21.757 2 19 2ZM5 4H19C20.654 4 22 5.346 22 7V8H2V7C2 5.346 3.346 4 5 4ZM19 22H5C3.346 22 2 20.654 2 19V10H22V19C22 20.654 20.654 22 19 22Z"
          fill="#333333"
        />
      </g>
      <defs>
        <clipPath id="clip0_3708_9595">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CalendarDayIconSVG;
