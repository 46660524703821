import AddIconCircleSVG from "assets/svg/add-circle-icon";
import EyeIconSVG from "assets/svg/eye-icon";
import EyeSlashIconSVG from "assets/svg/eye-slash-icon";
import _ from "lodash";
import React from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { AiFillInfoCircle } from "react-icons/ai";
import "./styles.css";

const InputCm = (
  {
    autoFocus = false,
    validationPassed = false,
    showInputValidation = false,
    type = "text",
    name = "",
    label = null,
    inputContainerClass = "",
    inputIcon = null,
    iconPosition = "right", // ["left", "right"]
    placeholder = "",
    errorMessage = null,
    outsideLabel = "",
    inputClass = "",
    labelClass = "input-label",
    outsideLabelClass = "",
    noLabelPaddingY = "0.5rem",
    paddingTop = null,
    errorMessagePosition = "bottom",
    showPasswordStrength = false,
    showCounter = false,
    disabledInput = false,
    masked = false,
    visible = true,
    showInfo = false,
    forEmailVerification = false,
    verified = false,
    extra = { show: false, label: "" },
    optional = false,
    onChangeVisible = () => {},
    otherLabel = "",
    disableUpDownArrow = false,
    ...props // make sure this pops here is for input only
  },
  ref
) => {
  const padding = () => {
    let result = iconPosition === "right" && inputIcon ? "2.5rem" : "1rem";
    if (showPasswordStrength) {
      result = "9rem";
    } else if (showInputValidation) {
      result = "3rem";
      if (masked) {
        result = "5rem";
      }
    } else if (showCounter) {
      result = "3.7rem";
      if (masked) {
        result = "4.5rem";
      }
    } else if (masked) {
      result = "3rem";
    } else if (forEmailVerification) {
      result = "9rem";
      if (verified) {
        result = "7rem";
      }
    }
    return result;
  };

  const handleKeyDown = (event) => {
    if(!disableUpDownArrow){
      return
    }
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        event.preventDefault();
    }
};

  return (
    <div className={inputContainerClass}>
      {outsideLabel && (
        <label htmlFor={name} className={`${outsideLabelClass} leading-4`}>
          {outsideLabel}
          {showInfo && (
            <AiFillInfoCircle className="text-lg ml-3  cursor-pointer" />
          )}
          {optional && <span className="font-normal">{" (optional)"}</span>}
          {otherLabel && (
            <span className="font-normal leading-4">
              <br />
              {otherLabel}
            </span>
          )}
        </label>
      )}
      {errorMessage && errorMessagePosition === "top" && (
        <p className="input-error-message">* {errorMessage}</p>
      )}

      <div className={`input-wrapper`}>
        {props?.value?.length > 1 && showInputValidation && (
          <div
            className={`validation-container ${
              validationPassed ? "bg-lightGreen" : "bg-red"
            } ${outsideLabel && "top-4"}`}
          >
            {validationPassed ? (
              <FaCheck className="icon-input" />
            ) : (
              <FaTimes className="icon-input" />
            )}
          </div>
        )}

          <div
            className={`h-full absolute items-center flex right-3 font-bold`}
          >
            cm
          </div>

        <input
          id={name}
          disabled={disabledInput}
          className={`input-class input-border ${inputClass} ${outsideLabel && "mt-2"} ${
            disabledInput && "bg-disabled"} ${disableUpDownArrow && "number-to-text"}`}
          style={{
            paddingLeft: iconPosition === "left" ? "2.5rem" : "1.2rem",
            paddingRight: padding(),
            borderColor: errorMessage && "red",
            paddingTop: !label ? noLabelPaddingY : paddingTop,
            paddingBottom: !label && noLabelPaddingY,
          }}
          onWheel={(e) => e.target.blur()}
          type={type}
          autoFocus={autoFocus}
          name={name}
          placeholder={placeholder}
          {...props} // so that user can select whether controlled input or uncontrolled
          ref={ref}
          onKeyDown={handleKeyDown}
          //value={value} //controlled
          //defaultValue={defaultValue} // uncontrolled
        />
        {label && (
          <label
            htmlFor={name}
            className={labelClass}
            style={{ paddingLeft: iconPosition === "left" ? "2.5rem" : "1rem" }}
          >
            {label}
          </label>
        )}
        {extra.showAddButton && (
          <div className="absolute -bottom-0.5 left-28 ml-1">
            <button
              type="button"
              onClick={() => extra.onPressAdd()}
              className="btn-remove rounded-full"
            >
              <AddIconCircleSVG className="m-auto w-8 h-8" />
            </button>
          </div>
        )}
        {extra.show && (
          <span className={`extra-label ${extra.showAddButton && "ml-3"}`}>
            {extra.label}
          </span>
        )}
        {showCounter && (
          <span
            className={`text-xs opacity-70 absolute ${
              masked ? "top-5 right-10" : "top-3 right-2.5"
            }`}
          >
            {`${_.size(props?.value)}/${props?.maxLength}`}
          </span>
        )}
        {masked && (
          <button
            type="button"
            className="absolute right-4 top-4.5"
            onClick={onChangeVisible}
          >
            {visible ? (
              <EyeIconSVG className="w-5 h-5" />
            ) : (
              <EyeSlashIconSVG className="w-5 h-5" />
            )}
          </button>
        )}
        {forEmailVerification && (
          <>
            <p
              className={`absolute right-4 top-3.5 text-xs py-1.5 px-4 bg-${
                verified ? "lightGreen" : "red"
              } text-white rounded-md`}
            >
              {verified ? "VERIFIED" : "NOT VERIFIED"}
            </p>
          </>
        )}
      </div>

      {errorMessage && errorMessagePosition === "bottom" && (
        <p className="input-error-message mt-2 -mb-1">* {errorMessage}</p>
      )}
    </div>
  );
};

const InputField = React.forwardRef(InputCm);

export default InputField;
