import React, { useState } from "react";
import Modal from "components/global/modal";
import Button from "components/button/button";
import LoadingIndicator from "assets/svg/loading-indicator";
import toast from "react-hot-toast";
import { AiFillDelete } from "react-icons/ai";

export default function MessageDeleteModal({
  hide,
  userData,
  selected,
  ...props
}) {
  const { http } = global.services;

  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    try {
        setLoading(true); 
        const { data } = await http.post("public/messages/deleteMessage", { 
          thread_id: selected?.thread?.id, 
          deleted_by: userData?.profile?.id,
        }); 
        hide({success: true, data:data});
      } catch (err) {
        setLoading(false);
        console.log("error@getPositions", err);
        toast.error("An Error occured. Failed to fecth messages");
      } finally {
        setLoading(false);
      }
  };

  const Footer = () => {
    return (
      <div className="relative grid gap-y-3 1sm:gap-y-0 1sm:flex 1sm:flex-row 1sm:items-center 1sm:justify-end 1sm:space-x-2">
        <Button
          buttonName="Cancel"
          buttonClass="bg-thBg py-5 px-12"
          buttonTextClass="text-black text-sm font-semibold"
          onClick={() => hide()}
        />
        <Button
          key="save-btn-category"
          buttonName="Delete"
          buttonClass="bg-red py-5 px-12"
          // buttonTextClass="text-white text-sm font-semibold"
          buttonTextClass={`text-white text-sm font-semibold ${
            loading
              ? "flex items-center space-x-2 justify-center"
              : "load-more-text"
          }`}
          onClick={() => handleDelete()}
          loadingIcon={
            loading ? (
              <LoadingIndicator className="text-white w-4 h-4 ml-2" />
            ) : null
          }
        />
      </div>
    );
  };

  return (
    <Modal
      footer={<Footer />}
      width="w-19/20  3md:w-8/20"
      contentClassName="rounded-lg overflow-auto custom-scroll"
      wrapperClassName="overflow-visible"
      footerClass="border-t border-gray">
      <div className="w-full">
        {/* header */}
        {/* <ModalHeader title="Delete Milestone" hide={hide} /> */}
        {/* content */}
        <div className="px-8.5 py-6">
          <div className="flex flex-row items-center">
            <AiFillDelete className="text-3xl text-red md:mr-2" />
            <p className="text-base 2sm:text-3xl text-red font-bold tracking-tighter">
              Delete Conversation
            </p>
          </div>
          {/* profile */}
          <div className="w-full py-6">
            <p className="text-md">
                Are you sure you want to delete the conversation? 
            </p>
            <br/>
            <p className="text-md">
                If you delete this, you'll be permanently removing it from out system - you can't undo it.
            </p>
          </div>
        </div>
        {/* end content */}
      </div>
    </Modal>
  );
}
