import React, { useContext } from "react";
import { useSelector,useDispatch } from "react-redux";
import userActions from "redux/modules/user/actions";
import { connect } from "react-redux";
import Layout from "layouts/layout";
import HeaderProfileList from "components/global/component/header-profile-list";
import { useDialogHook } from "utils/customhooks";
import AddProfileModal from "components/account/add-profile";
import ConfirmDeleteProfile from "components/account/delete-profile";
import PlusCircleIconSVG from "assets/svg/plus-circle-icon";
import RemoveCircleIconSVG from "assets/svg/remove-icon";
import { useHistory } from "react-router-dom";
import { randomExtension } from "utils/utils";
import { SubscriptionContext } from "context/subscription";
import { SET_PROFILES } from "redux/modules/user/types";
import PopInfo from "components/info-popup/pop-info";

const ManageProfile = ({ userData, setUserData }) => {
  const history = useHistory();
  const addProfileModal = useDialogHook(AddProfileModal);
  const deleteProfileModal = useDialogHook(ConfirmDeleteProfile);
  const extension = randomExtension();
  const { profiles } = useSelector((state) => state.user);
  const { isSubscribed } = useContext(SubscriptionContext);
  const dispatch = useDispatch();


  const addProfile = () => {
    addProfileModal({ userData }, (result) => {
      console.log("result =>", result);
      if (result?.success) {
        history.push(
          `/${userData.user.username}-${extension}/setup-profile/${result.id}`
        );
      }
    });
  };

  const onDelete = (e) => {
    console.log("user date to edit => ", e);
    let userProfile = e; 
    let newProfiles = profiles.filter(item=> item.id !== e.id);
    deleteProfileModal({ userProfile }, (result) => {
      console.log("result =>", result);
      if (result?.success) {
        dispatch({
          type: SET_PROFILES,
          payload: newProfiles,
        });
      }
    });
    // history.push(`/profile/${userData.user.username}-${extension}/setup-profile/${e.id}`)
  };

  const switchProfile = (profile) => {
    let data = {
      ...userData,
      profile: profile,
    };
    setUserData(data);

    setTimeout(() => {
      history.push(
        `/${userData.user.username}-${extension}/profile/${profile.id}`,
        {
          switchProfile: true,
        }
      );
    }, 700);
  };

  return (
    <Layout title="Manage Profile" setUserData={setUserData}>
      {/* overflow-y-auto scroll-smooth custom-scroll */}
      <div className="profile-manage-wrapper">
        <div className="6sm:mx-auto w-full 6sm:w-656 bg-white profile-dropdown rounded-md">
          {/* header */}
          <div className="w-full px-6 py-7.5 border-b border-profileGray">
            <div className="w-full flex flex-row items-center space-x-2">
              <h1 className="text-2xl text-lightBlack leading-7.4 tracking-tighter font-bold">
                Manage Profiles ({profiles.length})
              </h1>
              {/* pop info */}
              <PopInfo containerClass="relative" type="manage_profiles" />
            </div>
          </div>
          {/* end: header */}
          <HeaderProfileList
            {...{
              profiles,
              userData,
              switchProfile,
            }}
            hideTitle={true}
            editable={true}
            border={true}
            onDelete={onDelete}
            deletable={true}
            isSubscribed={isSubscribed}
          />
          {/*  */}
          <div className="w-full bg-white px-6 py-3 rounded-b-md">
            <div className="w-full flex justify-between">
              <button
                className="text-sm font-semibold leading-4.4 py-4 flex items-center text-lightBlack"
                onClick={addProfile}>
                <PlusCircleIconSVG className="mr-2" />
                Add Profiles
              </button>
              {/* <button
                className={`text-sm font-semibold leading-4.4 py-4 flex items-center
                  ${isSubscribed ? `text-lightBlack` : `text-darkGray`}
                `}
                disabled={!isSubscribed}>
                <RemoveCircleIconSVG
                  className={`mr-2 ${!isSubscribed && "text-gray opacity-40"}`}
                />
                Remove Profiles
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (params) => {
      dispatch(userActions.setUserData(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageProfile);
