import React, { useState } from "react";
import Modal from "components/global/modal";
import Button from "components/button/button";
import FitSquareIconSVG from "assets/svg/fit-square";
import { TabSelector } from "components/global/tabselector";
import CropIconSVG from "assets/svg/crop-icon";
import { TabPanel, useTabs } from "react-headless-tabs";
import FitSquareCrop from "./crop-images/fit-square";
import CropToSquare from "./crop-images/crop-square";
import PopInfo from "components/info-popup/pop-info";

export default function EditThumbnailModal({ hide, ...props }) {
  const { image, type, returnType, title } = props;
  const [selectedTab, setSelectedTab] = useTabs(["fit-square", "crop-square"]);
  const [cropFile, setCropFile] = useState(null);
  const [cropPreview, setCropPreview] = useState(null);

  const getCropData = () => {
    if (cropFile) {
      hide({
        imageFile: cropFile,
        preview: `data:${type};base64,${cropPreview}`,
      });
    }
  };

  const Footer = () => {
    return (
      <div className="relative grid gap-y-3 1sm:gap-y-0 1sm:flex 1sm:flex-row 1sm:items-center 1sm:justify-end 1sm:space-x-2">
        <Button
          buttonName="Cancel"
          buttonClass="bg-thBg py-4 px-8"
          buttonTextClass="text-black text-sm font-semibold"
          onClick={() => hide()}
        />
        <Button
          key="save-btn-category"
          buttonName="Save"
          buttonClass="bg-primary py-4 px-10"
          buttonTextClass="text-white text-sm font-semibold"
          onClick={() => getCropData()}
        />
      </div>
    );
  };

  return (
    <Modal
      footer={<Footer />}
      width="w-18/20 md:w-15/20 2md:w-11/20 3md:w-10/20 4md:w-9/20 1xl:w-7/20"
      contentClassName="rounded-lg overflow-auto custom-scroll"
      wrapperClassName="overflow-visible"
      footerClass="border-t border-gray">
      <div className="w-full">
        {/* header */}
        <div className="w-full">
          <div className="pl-10 pr-7 pt-3.5 flex justify-between items-center">
            <div className="w-full flex flex-row gap-x-2 items-center">
              <p className="text-base 2sm:text-3xl text-lightBlack font-bold tracking-tighter">
                {title || "Edit Thumbnail"}
              </p>
              <PopInfo containerClass="relative" type="edit_thumbnail" />
            </div>

            {/* close */}
            <span className="close" onClick={() => hide()}></span>
          </div>
          <div className="px-10 w-full border-b-2 border-gray">
            <TabSelector
              isActive={selectedTab === "fit-square"}
              gap="mr-1.5"
              colorType="black"
              paddingY="py-3.5"
              onClick={() => {
                setCropFile(null);
                setSelectedTab("fit-square");
              }}>
              <div className="w-full flex justify-center items-center">
                <FitSquareIconSVG className="mr-2" />
                Fit to Square
              </div>
            </TabSelector>
            <TabSelector
              isActive={selectedTab === "crop-square"}
              gap="ml-1.5"
              colorType="black"
              paddingY="py-3.5"
              onClick={() => {
                setCropFile(null);
                setSelectedTab("crop-square");
              }}>
              <div className="w-full flex justify-center items-center">
                <CropIconSVG className="mr-2" />
                Crop to Square
              </div>
            </TabSelector>
          </div>
        </div>

        {/* content */}
        <TabPanel hidden={selectedTab !== "fit-square"}>
          <div className="mx-8.5 my-6">
            {/* images */}
            {selectedTab === "fit-square" && (
              <FitSquareCrop
                setParentCrop={setCropFile}
                setCropPreview={setCropPreview}
                image={image}
                type={type}
                returnType={returnType}
              />
            )}
          </div>
        </TabPanel>
        {/*  */}
        <TabPanel hidden={selectedTab !== "crop-square"}>
          <div className="mx-8.5 my-6">
            {/* images */}
            {selectedTab === "crop-square" && (
              <CropToSquare
                setParentCrop={setCropFile}
                setCropPreview={setCropPreview}
                image={image}
                type={type}
                returnType={returnType}
                locked={true}
              />
            )}
          </div>
        </TabPanel>
      </div>
    </Modal>
  );
}
