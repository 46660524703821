import React, { useEffect, useState } from "react";
import Input from "components/input/input";
import Select from "components/select/select";

import { useCountries } from "redux/modules/country/hooks";
import { useStates } from "redux/modules/states/hooks";
import CustomCheckbox from "components/checkbox/custom-checkbox";
import PopInfo from "components/info-popup/pop-info";

const genderPosition = {
  left:"50px",
  right:"50px"
}

export default function AdvanceSearch({
  state,
  handleInputChange = () => {},
  handleUpdateGender = () => {},
  open = false,
}) {
  const { http } = global.services;

  const {
    initialize: initializeCountries,
    refresh: refreshCountries,
    countries = [],
    setCountries,
  } = useCountries();

  const {
    initialize: initializeStates,
    refresh: refreshStates,
    states = [],
    setStates,
  } = useStates();

  const [overflow, setOverflow] = useState(null);
  const [statesOptions, setStatesOptions] = useState(states);
  const [countryOptions, setCountryOptions] = useState(countries);

  /**
   * This method will get countries
   */
  const getCountries = async () => {
    try {
      const { data } = await http.get("public/countries");
      const payload = data.map((d) => ({ label: d.name, value: d.name }));
      setCountries(payload);
      setCountryOptions(payload);
    } catch (err) {
      console.log("error@getCountries", err);
    }
  };

  /**
   * This method will get states
   */
  const getStates = async () => {
    try {
      const { data } = await http.get("public/states");
      const payload = data.map((d) => ({ label: d.name, value: d.name }));
      setStates(payload);
      setStatesOptions(payload);
    } catch (err) {
      console.log("error@getStates", err);
    }
  };

  useEffect(() => {
    if (state) {
      let countryOpt = countryOptions.map((co) => {
        if (co.value === state.country) {
          return {
            ...co,
            selected: true,
          };
        } else {
          const { selected, ...val } = co;
          return val;
        }
      });
      setCountryOptions((prev) => countryOpt);

      let stateOpt = statesOptions.map((so) => {
        if (so.value === state.state) {
          return {
            ...so,
            selected: true,
          };
        } else {
          const { selected, ...val } = so;
          return val;
        }
      });
      setStatesOptions((prev) => stateOpt);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (!open) {
      setOverflow(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  /**
   * This effect will run during the initialization
   * and run every time refresh countries state change
   */
  useEffect(() => {
    if (initializeCountries) getCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCountries]);

  /**
   * This effect will run during the initialization
   * and run every time refresh states state change
   */
  useEffect(() => {
    if (initializeStates) getStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshStates]);

  return (
    <div
      className={`w-full mt-3 advance-search ${
        open ? `open ${overflow}` : ""
      }`}>
      <div className="w-full grid 2md:grid-cols-2 gap-x-7 gap-y-3.5 ">
        <Input
          inputContainerClass="flex-1"
          value={state?.name}
          name="name"
          outsideLabel="Name"
          placeholder="Bobby Diggs"
          outsideLabelClass="text-sm font-bold text-darkerGray"
          noLabelPaddingY="1rem"
          type="text"
          onChange={handleInputChange}
        />
        <Input
          inputContainerClass="flex-1"
          value={state?.job_role}
          name="job_role"
          outsideLabel="Job / Role"
          placeholder="Type Job Role"
          outsideLabelClass="text-sm font-bold text-darkerGray"
          noLabelPaddingY="1rem"
          type="text"
          onChange={handleInputChange}
        />
      </div>
      <div className="w-full mt-3.5 grid 2md:grid-cols-3 gap-x-7 gap-y-3.5">
        <Input
          inputContainerClass="flex-1"
          value={state?.city}
          name="city"
          outsideLabel="City"
          placeholder="Type City"
          outsideLabelClass="text-sm font-bold text-darkerGray"
          noLabelPaddingY="1rem"
          type="text"
          onChange={handleInputChange}
        />
        {/* <Input
          inputContainerClass="flex-1"
          value={state?.state}
          name="state"
          outsideLabel="State"
          placeholder="Type State"
          outsideLabelClass="text-sm font-bold text-darkerGray"
          noLabelPaddingY="1rem"
          type="text"
          onChange={handleInputChange}
        /> */}
        <Select
          formContainer="flex-1"
          label="State"
          options={statesOptions.filter((so) => !so.selected)}
          placeholder="Please Select"
          onFocus={() => setOverflow("overflow-visible")}
          name="state"
          height={58}
          onChange={(e) =>
            handleInputChange({
              target: { name: "state", value: e ? e.value : "" },
            })
          }
          isClearable={true}
        />
        <Select
          formContainer="flex-1"
          label="Country"
          options={countryOptions.filter((co) => !co.selected)}
          placeholder="Please Select"
          onFocus={() => setOverflow("overflow-visible")}
          name="country"
          height={58}
          onChange={(e) =>
            handleInputChange({
              target: { name: "country", value: e ? e.value : "" },
            })
          }
          isClearable={true}
        />
      </div>
      <div className="w-full mt-3.5">
        <div className="w-full flex flex-row gap-x-2 items-center">
          <p className="text-sm text-darkerGray font-bold">Gender</p>
          <PopInfo
              type="gender_others"
              // direction="right"
              responsive={genderPosition}
              onClickCallBack={() => setOverflow("overflow-visible")}
            />
        </div>
        <div className="w-full flex items-center justify-between">
          <div className="w-full mt-1 md:mt-3.5 md:flex md:space-x-10 md:space-y-0">
            <CustomCheckbox
              label={
                <p className="text-sm text-darkerGray leading-6 ml-3 mt-0.5">
                  Male
                </p>
              }
              name="genderMale"
              checked={state.gender === "Male"}
              onChange={(e) => handleUpdateGender(e, "Male")}
            />
            <CustomCheckbox
              label={
                <p className="text-sm text-darkerGray leading-6 ml-3 mt-0.5">
                  Female
                </p>
              }
              name="genderFemale"
              checked={state.gender === "Female"}
              onChange={(e) => handleUpdateGender(e, "Female")}
            />
            <div className="flex">
              <CustomCheckbox
                label={
                  <p className="text-sm text-darkerGray leading-6 ml-3 mt-0.5">
                    Other
                  </p>
                }
                name="genderOthers"
                checked={state.gender === "others"}
                onChange={(e) => handleUpdateGender(e, "others")}
              />
              {/* <div className="others-input">
                <input />
              </div> */}
            </div>
          </div>
          {/*  */}
          
        </div>
      </div>
    </div>
  );
}
