import React from "react";
import _ from "lodash";
import { renderStartDate, renderEndDate } from "../../resume";
import { HiOutlineCalendarDays } from "react-icons/hi2";
import { BsExclamationDiamondFill } from "react-icons/bs";
import ItemMilestone from "../template-one-tg/item-milestone";
export default function TemplateOnePageTwo({
  milestones = {},
  secondaryColor,
  tertiaryColor,
  show_image_milestone = true,
  mergeMilestone,
  profile,
  items
}) {
  return (
    <div className="w-full">
      <div style={{ minHeight: "849px" }} className="h-full overflow-hidden">
      <ItemMilestone
          {...{
            secondaryColor,
            tertiaryColor ,
            items,
            profile,
            show_image_milestone
          }}
          headerType = 'featured'
          />
          <ItemMilestone
          {...{
            secondaryColor,
            tertiaryColor ,
            items,
            profile,
            show_image_milestone
          }}
          headerType = 'current'
          />
        <ItemMilestone
          {...{
            secondaryColor,
            tertiaryColor ,
            items,
            profile,
            show_image_milestone
          }}
          headerType = 'past'
          />
      </div>
      {/* ========== footer ======== */}
      {/* <div
        className="w-full h-6 border-t px-2"
        style={{ borderColor: "#DAE4EF" }}>
        <div className="w-full h-full flex flex-row justify-between items-center">
          <p className="font-semibold text-1xs text-darkerGray">Page 2</p>
          <div className="flex flex-start gap-1.5">
            <button className="w-1.5 h-1.5 rounded-full bg-pageInactive" />
            <button className="w-1.5 h-1.5 rounded-full bg-primary" />
          </div>
        </div>
      </div> */}
    </div>
  );
}
