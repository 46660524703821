import React, { useEffect } from "react";
import Button from "components/button/button";
import Input from "components/input/input";
import Select from "components/select/select";
import { FaMinus, FaPlus, FaPaperclip } from "react-icons/fa";
import FileUpload from "components/file-upload/file-upload";
import MultipleFileUpload from "components/file-upload/multiple-file-upload";
import ImagePreview from "components/file-upload/image-preview";
import BlockIconSVG from "assets/svg/block-icon";
import ToolTip from "components/tootltip/tootltip";

const noop = () => {};

export default function LinkGroupSelect({
    items = [],
    options = [],
    subOptions = [],
    label = "",
    labelClass = "text-sm font-bold text-darkerGray",
    onAdd = noop,
    onUpload = noop,
    onUploadMultiple = noop,
    onRemove = noop,
    onSelect = noop,
    onChange = noop,
    onBlur = noop,
    onSelectSubType = noop,
    optional = false,
    errors = {},
    onRemoveMedia = noop,
    onChangeDescription = noop,
    maxSelectedImages = 10,
}) {
    const [other, SetOther] = React.useState(false);
    const onSelectType = (e, index) => {
        if (e.label === "Other") {
            SetOther(true);
        } else {
            SetOther(false);
        }
        onSelect(e, index);
    };

    const ConditionalTooltip = items.length >= 6 ? ToolTip : ({ children }) => (<>{children}</>);

    return (
        <div className="w-full mb-6">
            {label && (
                <label className={`${labelClass} leading-4`}>
                    {label}
                    {optional && (
                        <span className="font-normal">{" (optional)"}</span>
                    )}
                    <span className="ml-1 font-bold">
                        ({`${items.length}/6`})
                    </span>
                </label>
            )}
            <div className={`w-full ${label && "mt-2"}`}>
                {items.map((obj, index) => {
                    const { subType = {} } = obj;
                    const isMedia = subType.is_media_file ? true : false;
                    const isMultiple = subType.multiple ? true : false;
                    const accept = subType.accept ? subType.accept : null;
                    const previews = obj.value ? obj.value : obj.media;
                    const disableMultipleImages =
                        Array.isArray(previews) && previews.length >= 10;

                    const idDescription =
                        options?.filter((o) => o.value === obj.type)[0]
                            ?.label === "Other" ;
                      console.log('obj  --->', obj)
                    return (
                        <div key={index}>
                            <div className="w-full grid 4sm:grid-cols-3 gap-x-4 gap-y-2 mb-4">
                                <Select
                                    formContainer="flex-1"
                                    options={options.sort((a, b) => a.label.localeCompare(b.label))}
                                    placeholder="Please Select"
                                    onChange={(e) => onSelectType(e, index)}
                                    height={58}
                                    value={options.filter(
                                        (o) => o.value === obj.type
                                    )}
                                    name={`type-link-${index}`}
                                    errorMessage={errors[`media.${index}.type`]}
                                />
                                <Select
                                    formContainer="flex-1"
                                    options={subOptions}
                                    placeholder="Please Select"
                                    onChange={(e) => onSelectSubType(e, index)}
                                    height={58}
                                    value={subOptions.filter(
                                        (o) => o.value === subType.value
                                    )}
                                    name={`subtype-link-${index}`}
                                    errorMessage={
                                        errors[`media.${index}.sub_type`]
                                    }
                                />
                                {/* <div className="flex space-x-3 items-center"> */}
                                <div className="flex flex-row space-x-3 ">
                                    <div className="w-full">
                                        {isMedia && isMultiple && (
                                            <MultipleFileUpload
                                                multiple={isMultiple}
                                                id={`multiple-file-${index}`}
                                                accept={accept}
                                                onUpload={(e) =>
                                                    onUploadMultiple(e, index)
                                                }
                                                buttonText={
                                                    Array.isArray(previews) &&
                                                    previews.length > 0
                                                        ? previews.length >= 10
                                                            ? "DISABLED"
                                                            : "Add More Images"
                                                        : "Browse Images"
                                                }
                                                disabled={disableMultipleImages}
                                                icon={
                                                    disableMultipleImages ? (
                                                        <BlockIconSVG className="mx-auto" />
                                                    ) : null
                                                }
                                                errorMessage={
                                                    errors[
                                                        `media.${index}.value`
                                                    ]
                                                }
                                            />
                                        )}

                                        {isMedia && !isMultiple && (
                                            <FileUpload
                                                image_name={
                                                    obj.placeholder || ``
                                                }
                                                id={`singe-file-${index}`}
                                                formContainer="flex-1"
                                                showPreview={false}
                                                accept={
                                                    accept ||
                                                    ".pdf,image/*,audio/*"
                                                }
                                                className="absolute top-1.5 right-2 bg-modalGray py-3.5 px-4 rounded-md text-lightBlack"
                                                icon={
                                                    <FaPaperclip className="text-lg" />
                                                }
                                                onUpload={(e) =>
                                                    onUpload(e, index)
                                                }
                                                errorMessage={
                                                    errors[
                                                        `media.${index}.value`
                                                    ]
                                                }
                                            />
                                        )}

                                        {!isMedia && (
                                            <Input
                                                inputContainerClass="flex-1"
                                                name={`link-${index}`}
                                                value={obj.value}
                                                placeholder="https://www.link.com/image1"
                                                noLabelPaddingY="1rem"
                                                type="text"
                                                onChange={(e) =>
                                                    onChange(e, index)
                                                }
                                                onBlur={() => onBlur(index)}
                                                errorMessage={
                                                    errors[
                                                        `media.${index}.value`
                                                    ] ||
                                                    (errors?.media &&
                                                        errors?.media[index])
                                                }
                                            />
                                        )}

                                        {/* {errors[`media.${index}.value`] && (
                      <p className="input-error-message mt-2 -mb-1">
                        * {errors[`media.${index}.value`]}
                      </p>
                    )} */}
                                    </div>

                                    {/* button remove */}
                                    <div className="flex items-center">
                                        <button
                                            className="h-6.5 w-6.5 bg-lightBlack rounded-full"
                                            onClick={() => onRemove(index)}
                                        >
                                            <FaMinus className="m-auto text-white" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {idDescription && (
                                <div className="other-description">
                                    <div className="other-description-line">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="43"
                                            height="49"
                                            viewBox="0 0 43 49"
                                            fill="none"
                                        >
                                            <path
                                                d="M1.56177 0.182617V41.3275C1.56177 44.6412 4.24806 47.3275 7.56177 47.3275H42.7437"
                                                stroke="#DDDDDD"
                                                stroke-width="1.5"
                                            />
                                        </svg>
                                    </div>
                                    <div className="other-description-input">
                                        <Input
                                            inputContainerClass="form-input-container"
                                            value={obj?.other_description && obj?.other_description !== 'undefined' ? obj?.other_description : ''}
                                            name="other_description"
                                            placeholder="Other Description"
                                            outsideLabelClass="text-sm font-bold text-darkerGray"
                                            noLabelPaddingY="1rem"
                                            type="text"
                                            onChange={(value) =>
                                                onChangeDescription(
                                                    value,
                                                    index
                                                )
                                            }
                                            // errorMessage={errorMessages?.date_note}
                                            errorMessagePosition="bottom"
                                        />
                                    </div>
                                </div>
                            )}

                            {Array.isArray(previews) && (
                                <div>
                                    <label
                                        className={`${labelClass} leading-4 ${
                                            previews.length > maxSelectedImages
                                                ? "text-red"
                                                : ""
                                        }`}
                                    >
                                        Uploaded Images ({previews.length}/
                                        {maxSelectedImages}){" "}
                                        {previews.length > maxSelectedImages
                                            ? errors?.multiple_images
                                                ? `- ${errors.multiple_images[0]}`
                                                : `- Please remove some images! The maximum number of images that can be uploaded is ${maxSelectedImages}.`
                                            : ""}
                                    </label>
                                    <ImagePreview
                                        images={previews}
                                        onRemove={(e) =>
                                            onRemoveMedia(e, index)
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    );
                })}
                <div className="flex flex-row items-center justify-start">
                    <ConditionalTooltip value={"Limit Reached"} className="">
                        <Button
                            buttonName={"Add Another Button"}
                            buttonTextClass="text-sm text-white font-semibold flex items-center"
                            iconPosition="left"
                            icon={<FaPlus className="mr-3" />}
                            buttonRadius="6px"
                            buttonClass="relative py-4 px-9 bg-lightBlack"
                            onClick={onAdd}
                            disabled={items.length >= 6}
                            
                        />
                    </ConditionalTooltip>
                </div>
            </div>
        </div>
    );
}
