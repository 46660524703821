const CCIconSVG = ({ className = "" }) => {
    return (
        <svg width="68" height="42" viewBox="0 0 68 42" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
        <path d="M24.835 4.49121H43.21V37.5078H24.835V4.49121Z" fill="#FF5F00"/>
        <path d="M26.0007 21C26.0007 14.2918 29.1506 8.34165 33.9921 4.49155C30.4341 1.69161 25.9425 0 21.0424 0C9.4336 0 0.0419922 9.39153 0.0419922 21C0.0419922 32.6082 9.4336 42 21.0422 42C25.9422 42 30.4338 40.3084 33.9921 37.5082C29.1506 33.7165 26.0007 27.7082 26.0007 21Z" fill="#EB001B"/>
        <path d="M68 21C68 32.6082 58.6083 42 46.9998 42C42.0998 42 37.6082 40.3084 34.0498 37.5082C38.9498 33.6584 42.0416 27.7082 42.0416 21C42.0416 14.2918 38.8914 8.34165 34.0498 4.49155C37.6079 1.69161 42.0998 0 46.9998 0C58.6083 0 68 9.44999 68 21Z" fill="#F79E1B"/>
        </svg>
    );
  };
  
  export default CCIconSVG;