const BackArrowFollowingIconSVG = ({ className = "" }) => (
  <svg
    width="36"
    height="31"
    viewBox="0 0 36 31"
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.0114 31L0.738636 15.7273L16.0114 0.454546L18.6364 3.04545L7.82955 13.8523H35.8182V17.6023H7.82955L18.6364 28.375L16.0114 31Z"
      fill="#333333"
    />
  </svg>
);
export default BackArrowFollowingIconSVG;
