import React, { useContext, useMemo, useEffect, useState } from "react";
// import { FiBriefcase } from "react-icons/fi"; 
import { FaRegUserCircle,FaBriefcase } from "react-icons/fa";
import { listFormat, capitalizeWords } from "utils/utils";
import VerifyIconSVG from "assets/svg/verified-icon";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";
import { Context } from "context/context";
import { TbWorld } from "react-icons/tb";
import ToolTip from "components/tootltip/tootltip";

export default function ProfileDetails({ data = {}, isYourProfile = false }) {
    const history = useHistory();
    const { username, profileId } = useParams();
    const { following, followers } = useContext(Context);

    const { http } = global.services;
    const [socialMedias, setSocialMedias] = useState([]);
    const occupation = useMemo(() => {
        if (data && data.occupations && data.occupations.length > 0) {
            var capitalizedArray = data.occupations.map(capitalizeWords);
            const list = capitalizedArray.map((o) => {
                if (typeof o === "object") {
                    return o.value;
                } else if (typeof o === "string") {
                    return o;
                } else {
                    return null;
                }
            });

            if (list) {
                return listFormat(list);
            }
        }
        return "";
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.occupations]);

    const getSocialMedias = async () => {
        if (!_.isEmpty(data)) {
            try {
                const socialMedias = await http.get("public/social-medias", {
                    user_profile_id: data.id,
                });
                setSocialMedias(socialMedias.data.social_media);
            } catch (error) {
                console.log(error);
            }
        }
    };
    const websiteOnly = useMemo(() => {
        let mappedData = [];
        if (!_.isEmpty(socialMedias)) {
            socialMedias.forEach((socialMedia) => {
                if (socialMedia?.media_type === "website") {
                    mappedData.push(socialMedia);
                }
            });
        }
        return mappedData;
    }, [socialMedias]);

    useEffect(() => {
        getSocialMedias();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.id]);

    const pushFollowing = () => {
        history.push(`/${username}/profile/${profileId}/following`);
    };

    const display_name = () => {
        if (data?.displayName) {
            switch (data?.displayName) {
                case "First Name":
                    return data?.user?.first_name;
                case "Surename":
                    return data?.user?.last_name;
                case "Other Name":
                    return data?.user?.other_name || data?.other_name;
                case "Username":
                    return data?.user?.username;
                case "First Name Surname":
                    return `${data?.user?.first_name} ${data?.user?.last_name}`;
                default:
                    return data?.user?.other_name || data?.other_name;
            }
        } else {
            return data?.user?.username;
        }
    };

    const notFullName = (profile_name,user)=>{
        if(profile_name !== `${user?.first_name} ${user?.last_name}`){
          return true;
        }else{
          return false;
        }
      }

    return (
        <div className="text-center 3md:text-left">
            <h2 className="text-2xl 2sm:text-2.5xl 3md:text-5xl 3md:mt-0  text-lightBlack tracking-tighter 3md:leading-58 font-bold flex flex-row items-center justify-center 2sm:justify-start name-header-name">
                {/* <>{display_name(data) || data?.user?.username}</> */}
                <>{data?.profile_name || data?.other_name || data?.user?.username}</>
                {data?.id3_verified && (
                    <VerifyIconSVG className="w-5 h-5 3md:w-7 3md:h-7 ml-1 3md:ml-2.5" />
                )}
            </h2>
            <div className="text-[14px] 2sm:text-sm 3md:text-base flex mt-1 3md:mt-0 justify-center 2sm:justify-start text-lightBlack space-x-2 2sm:space-x-3 leading-6 border-b border-gray pb-3 2sm:pb-3.5 flex-wrap name-header-username">
                {!_.isEmpty(data?.username) && (
                    <>
                        <p className="text-primary font-[500] sm:hidden sm:block">
                            @{data?.username}
                        </p>
                    </>
                )}
                {!_.isEmpty(data?.user) && (
                    <>
                        <div className="flex items-center name-header">
                            {!_.isEmpty(data?.username) && (
                                <>
                                    <p className="text-primary font-[500] hidden sm:block">
                                        @{data?.username}
                                    </p>
                                    {Object.keys(data).length !== 0 &&
                                        !_.isEmpty(data?.user) && (
                                            <p className="text-passwordGray hidden sm:block">
                                                &#8226;
                                            </p>
                                        )}
                                </>
                            )}
                            <span className="font-semibold pr-2">aka</span>
                            {/* {data?.displayName !== "First Name Surname"
                                ? `${data?.user?.first_name} ${data?.user?.last_name} `
                                : ""}
                            {data?.displayName !== "First Name Surname" &&
                            data?.id3_verified ? (
                                <span className="pr-2">
                                    <VerifyIconSVG className="w-3 h-3 3md:w-3 3md:h-3 ml-1 3md:ml-2.5" />
                                </span>
                            ) : (
                                <span className="px-1">/</span>
                            )} */}
                            {
                                notFullName(data.profile_name,data?.user) &&
                                <> 
                                    <>{data?.user?.first_name} {data?.user?.last_name}</>
                                
                                    {data?.id3_verified ? (
                                        <span className="pr-1">
                                            <VerifyIconSVG className="w-3 h-3 3md:w-3 3md:h-3 ml-[2px]" />
                                        </span>
                                    ) : (
                                        <span className=""></span>
                                    )}
                                </>
                            }
                            {/* {data?.username} */}
                            {Object.keys(data).length !== 0 &&
                              !_.isEmpty(data?.other_name) && (
                                <p className="text-passwordGray">&#8226;</p>
                            )}
                            <p>{data?.other_name}</p>
                            {/* {data?.other_names && (
                                <>
                                    <span className="px-1">/</span>
                                    {data?.other_names.map((p, i) => (
                                        <span className="" key={i}>
                                            {p.label}
                                            {i < data?.other_names.length - 1 &&
                                                " / "}
                                        </span>
                                    ))}
                                </>
                            )} */}
                            {Object.keys(data).length !== 0 &&
                                !_.isEmpty(data?.gender) && (
                                    <p className="text-passwordGray">&#8226;</p>
                                )}{" "}
                            {!_.isEmpty(data?.gender) && (
                                <>
                                    <p>{data?.gender}</p>
                                </>
                            )}
                             {Object.keys(data).length !== 0 &&
                                !_.isEmpty(data?.address) && (
                                    <p className="text-passwordGray">
                                        &#8226;
                                    </p>
                                )}
                            {!_.isEmpty(data?.address) && (
                                <p>{data?.address}</p>
                            )}
                        </div>{" "}
                    </>
                )}
            </div>
            <div className="mt-3.5 flex justify-start 3md:block">
                <ul className="text-lightBlack text-[14px] 2sm:text-sm 3md:text-base leading-5 grid gap-y-1 3md:gap-y-2.5">
                    {!_.isEmpty(occupation) && (
                        <li className="flex justify-start">
                            <FaBriefcase className="text-darkerGray mr-1.5 3md:mr-3 mt-1" />
                            <p className="w-full text-left">{occupation}</p>
                        </li>
                    )}
                    {!_.isEmpty(data?.profile_type?.type) && (
                        <li className="flex justify-start items-center">
                            <FaRegUserCircle className=" text-lg text-darkerGray mr-1.5 3md:mr-3" />
                            {/* Character */}
                            <div className="w-full flex justify-start flex-row gap-x-1.5 items-center">
                                <p>{_.upperFirst(data?.profile_type?.type)}</p>
                            </div>
                        </li>
                    )}
                    {!_.isEmpty(websiteOnly) && (
                        <li className="flex justify-start items-center">
                            <TbWorld className="text-lg text-darkerGray mr-1.5 3md:mr-3" />
                            <div className="flex space-x-1 flex-wrap">
                                {websiteOnly.map((socialMedia, i) => {
                                    let counter = i + 1;
                                    return (
                                        <React.Fragment key={i}>
                                            <ToolTip
                                                value={socialMedia.link}
                                                arrowClass="top-[23px]"
                                            >
                                                <a
                                                    style={{ color: "#0c6ed1" }}
                                                    href={socialMedia.link}
                                                >
                                                    {socialMedia.type}
                                                </a>
                                            </ToolTip>
                                            {counter < websiteOnly.length ? (
                                                <p className="text-passwordGray">
                                                    &#8226;
                                                </p>
                                            ) : (
                                                <></>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                        </li>
                    )}
                </ul>
            </div>
            {data?.user?.username && isYourProfile && (
                <div className="mt-1 3md:mt-4.5 flex justify-start 3md:justify-center 3md:block text-[14px] 2sm:text-sm 3md:text-base">
                    <div className="flex flex-row space-x-2.5 text-lightBlack">
                        <p className="cursor-pointer" onClick={pushFollowing}>
                            <span className="font-bold">
                                {_.size(following)}
                            </span>{" "}
                            Following
                        </p>
                        <p className="opacity-30">&#8226;</p>
                        <p className="cursor-pointer" onClick={pushFollowing}>
                            <span className="font-bold">
                                {_.size(followers)}
                            </span>{" "}
                            Followers
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
}
