const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
// const API_ENDPOINT = 'http://127.0.0.1:8000/api'
const PUSHER_API_KEY = process.env.REACT_APP_PUSHER_API_KEY;
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const PUSHER_CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER;
const BASE_URL = process.env.REACT_APP_PUBLIC_DOMAIN;
const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;

export {
  BASE_URL,
  API_ENDPOINT,
  PUSHER_API_KEY,
  GOOGLE_MAPS_API_KEY,
  PUSHER_CLUSTER,
  RECAPTCHA_KEY,
};
