
const MessageBlock = ({message_block,owner})=>{
    return(
        <div className="w-full px-4.5 py-4.5 flex justify-center items-center">
            <h1 className="text-md font-bold mb-2 text-slate-400">
                {`${message_block.owner_id === owner ? 
                     "You can't reply to this conversation." : 
                     "This person isn't availble right now."}`}
                
            </h1>
        </div>
    )
}

export default MessageBlock;