import React, { useContext, useState, useEffect, useRef, useMemo } from "react";
import { FaEllipsisH } from "react-icons/fa";
import { IoEllipse } from "react-icons/io5";
import UserTagIconSVG from "assets/svg/user-tag-icon";
import { Context } from "context/context";
import { formatDate } from "utils/utils";
import ViewMilestoneDialog from "./view-dialog";
import Popup from "reactjs-popup";
import OptionListItem from "./more-option/option-list-item";
import _ from "lodash";
import LazyImage from "./LazyImage";
// import { imageSquareOrPortrait } from "utils/utils";
import { useDialogHook } from "utils/customhooks";
import { useMilestones } from "redux/modules/milestone/hooks";
import PrivacyBadge from "components/milestonelist/privacyBadge";
import { AiFillPushpin } from "react-icons/ai";
import { DATE_NEWEST_TO_OLDEST, DATE_OLDEST_TO_NEWEST } from "constants/options";
import { CoverPlaceholderMilestone } from "constants/images";

const ListViewCard = ({
  title,
  dateNote,
  date,
  privacy,
  profile,
  featured,
  people,
  positions = [],
  platform,
  location,
  url = "",
  viewUrl = "",
  category,
  isAdultContent,
  isYourProfile,
  onView = () => {},
  onEdit = () => {},
  onDelete = () => {},
  onShare = () => {},
  share,
  closeModal = () => {},
  onReport = () => {},
  dateMobile=null
}) => {
  const { showMaturedContent, setShowMaturedContent } = useContext(Context);
  const [showMoreOption, setShowMoreOption] = useState();
  // const [customClass, setCustomClass] = useState("");
  const optionRef = useRef();

  // useEffect(() => {
  //   (async () => {
  //     const res = await imageSquareOrPortrait(url);
  //     if (res === "portrait") {
  //       setCustomClass(
  //         "h-23 w-23 2sm:h-29.5 2sm:w-29.5 3md:w-50 3md:h-50 bg-contain"
  //       );
  //     } else if (res === "landscape") {
  //       setCustomClass(
  //         "h-23 w-23 2sm:h-29.5 2sm:w-29.5 3md:w-50 3md:h-50 bg-contain"
  //       );
  //     } else {
  //       setCustomClass(
  //         "h-23 w-23 2sm:h-29.5 2sm:w-29.5 3md:w-50 3md:h-50 bg-cover"
  //       );
  //     }
  //   })();
  // }, [url]);

  useEffect(() => {
    window.addEventListener(
      "scroll",
      () => {
        if (optionRef.current) {
          optionRef.current.close();
        }
      },
      true
    );
  }, []);

  return (
    <div
      className="w-full bg-siteBg rounded-md mt-3 2sm:mt-4.5 3md:mt-6 cursor-pointer overflow-hidden"
      onClick={onView}>
      <div className="w-full flex space-x-0.5">
        {/* image */}
        {url ? (
          <div className="relative bg-white h-auto w-23 2sm:h-auto 2sm:w-29.5 3md:w-50 3md:h-50 overflow-hidden">
            {/* thumbnail */}
            <LazyImage
              source={url}
              viewUrl={viewUrl}
              customClassName="w-full"
              secondView={true}
            />
            {/* restricted Image */}
            {/* {!isAdultContent && !showMaturedContent && (
              <div className="absolute top-0 left-0 matured-content-blured">
                <button
                  className="w-full flex justify-center"
                  onClick={() => setShowMaturedContent((prev) => !prev)}>
                  <BsExclamationDiamondFill className="text-base 1sm:text-5xl cursor-pointer" />
                </button>
              </div>
            )} */}
          </div>
        ):
        <div className="relative h-auto w-23 2sm:h-auto 2sm:w-29.5 3md:w-50 3md:h-50 flex itmes-center justify-center bg-[#e9e9e9]">
            <div className="relative flex items-center justify-center h-full w-23 2sm:h-auto 2sm:w-29.5 3md:w-50 3md:h-50 bg-[#e9e9e9]">
              <img src={CoverPlaceholderMilestone} alt="Milestone" className="w-[43px] h-[41px] 3md:h-[81px]  3md:w-[83px] "/>
            </div>
        </div>
        }
        {/* content container */}
        <div
          className={`relative bg-white 2md:mt-0 px-4.5 py-3 3md:px-9 3md:py-6 ${
            url
              ? "width-responsive-list-view"
              : "width-responsive-list-view-full"
          }`}>
          {/* content */}
          <div className="w-full flex justify-between 2sm:items-center gap-6">
            <div className="w-full">
              {/* title */}
              <div className="flex items-center space-x-1 ">
                {/* <p className="whitespace-nowrap text-base 2sm:text-2xl 3md:text-2.5xl text-lightBlack font-medium tracking-tighter">
                  {title}
                </p> */}
                <div className="max-w-full max-h-12 overflow-hidden">
                <h1 className="text-[14px] leading-tight line-clamp-2 2sm:text-2xl 3md:text-2.5xl">
                {title}
                </h1>
              </div>
              </div>
              {/* category */}
              {/* <div className="items-center space-x-5 flex-wrap hidden 3md:flex">
                <p className="px-1.5 py-1 3md:px-4.5 3md:py-1.5 bg-lightGreen text-white text-1xs 2sm:text-xs uppercase font-semibold rounded-md">
                  {category}
                </p>
              </div> */}
              {/* details */}
              <div className="w-full mt-0 2sm:mt-1.5">
                {/* <div className="w-full whitespace-nowrap text-4xs 2sm:text-sm 3md:text-base text-darkerGray font-medium
                 3md:leading-6 flex items-center space-x-2 3md:space-x-3 flex-wrap"> */}
                 
              {/* Hide when in mobile view */}
                  <div className="w-full text-4xs 2sm:text-sm 3md:text-base text-darkerGray font-medium 
                      3md:leading-[16px] flex items-center space-x-2 3md:space-x-3 hidden 2sm:flex">
                    <p className="relative break-words">
                      {date}
                      {dateNote && (
                        <span className="ml-1">{`(${dateNote})`}</span>
                      )}
                    </p>
                  <PrivacyBadge privacy={privacy} isYourProfile={isYourProfile}/>
                  {/* <p className="flex items-center capitalize">
                    {privacy === "public" ? (
                      <BsGlobe2 className="mr-1.5" />
                    ) : (
                      <BsExclamationDiamondFill className="mr-1.5" />
                    )}
                    {privacy}
                  </p> */}
                  {people && (
                    <>
                      <p className="hidden md:block">
                        <IoEllipse className="w-1 h-1" />
                      </p>
                      <p className="hidden md:flex items-center">
                        <UserTagIconSVG className="mr-1.5" />
                        {people}
                      </p>
                    </>
                  )}
                  <p className="inline px-1.5 py-1 hidden 2sm:inline 3md:px-4.5 3md:py-1.5 bg-lightGreen text-white text-1xs 2sm:text-xs uppercase font-semibold rounded-md">
                    {category}
                  </p>
                </div> 
              </div>
              {/* Author Name and description */} 
              {/* Hide when in mobile view */}
              <div className="w-full mt-4 3md:mt-4 hidden 2sm:inline"> 
                <p className="text-4xs 2sm:text-sm 3md:text-base font-bold text-primary 3md:leading-6 mt-1.5 break-anywhere">
                  {positions.map((p, i) => (
                    <span key={i}>
                      {p.position.name}
                      {p.description && ` - ${p.description} `}
                      {i < positions.length - 1 && ", "}
                    </span>
                  ))}
                  <span className="pl-1">{platform && `(${platform}${location ? ` - ${location}` : ''})`}</span>
                </p>
              </div> 
              {/* Show when in mobile view */}
              <div className="w-full mt-4 3md:mt-4 inline 2sm:hidden">
                <p className="text-4xs 2sm:text-sm 3md:text-base font-bold text-primary 3md:leading-6 break-anywhere mt-1">
                    { positions.length > 0 &&
                      <>
                        <span>
                        {positions[0]?.position.name}
                        {positions[0]?.description && ` - ${positions[0]?.description} `} 
                      </span>
                      <span className="">{platform && `(${platform}${location ? ` - ${location}` : ''})`}</span>
                      {positions.length > 1 && <span>...</span>}
                      </>
                  }
                </p>
                <p className="relative w-full break-words text-1xs 2sm:text-sm 3md:text-base text-darkerGray font-medium flex gap-2 items-center mt-1.5">
                    <>
                      { dateMobile && 
                      <>
                        {dateMobile?.start_year} 
                        {(dateMobile?.end_year || dateMobile?.is_present === 1) && 
                        <span>
                          - {dateMobile?.is_present ? 'Present' : dateMobile?.end_year}
                        </span>
                        }
                      </>
                      }
                    </>
                    <p className="px-1.4 py-[2px] 3md:px-4.5 3md:py-1.5 bg-lightGreen text-white text-3xs 2sm:text-xs uppercase font-semibold rounded-md">
                      {category}
                    </p>
                  </p>
              </div>
            </div>
            {/* <button className="bg-thBg w-10 h-10 rounded-md hidden 4sm:block">
              <FaEllipsisH className="m-auto" />
            </button> */}
            <Popup
              ref={optionRef}
              position="bottom right"
              open={showMoreOption}
              onClose={() => setShowMoreOption(false)}
              arrow={false}
              contentStyle={{ marginTop: 10, marginLeft: 5 }}
              trigger={
                <button
                  className="bg-thBg w-5 h-5 3md:w-10 3md:h-10 rounded-md hidden 1sm:block"
                  onClick={() => setShowMoreOption((open) => !open)}>
                  <FaEllipsisH className="m-auto text-4xs 3md:text-base" />
                </button>
              }>
              <OptionListItem
                {...{
                  isYourProfile,
                  onEdit,
                  onDelete,
                  //
                  onShare,
                  share,
                  closeModal,
                  onReport,
                  profile,
                  featured,
                }}
              />
            </Popup>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function ListViewMilestones({
  isYourProfile,
  showFeatured,
  featured = {},
  profile = {},
  current = [],
  past = [],
  pin =[],
  onWatchMedia = () => {},
  onViewImage = () => {},
  onEdit = () => {},
  onDelete = () => {},
  onShare = () => {},
  onReport = () => {},
  handleViewPdf = () => {},

}) {

  const {
    organizeBy,
  } = useMilestones();
  const featuredDate = formatDate(featured,profile?.dateFormat);
  const modalView = useDialogHook(ViewMilestoneDialog);

  /**
   * This method will handle viewing of milestone
   * setting dialog state
   */
  const onView = (milestone, event) => {
    console.log(milestone)
    modalView(
      {
        profile: profile,
        isYourProfile: isYourProfile,
        data: milestone,
        onWatchMedia: onWatchMedia,
        onViewImage: onViewImage,
        onEdit: onEdit,
        onDelete: onDelete,
        onReport: onReport,
        handleViewPdf: handleViewPdf,
        onShare: ()=>{onShare(milestone, event)},
        share:true
      },
      (cb) => {}
    );
  };

  const featuredThumbnail = useMemo(() => {
    if (featured.thumbnail) {
      return featured.thumbnail.webp_url || featured.thumbnail.url;
    }
    return null;
  }, [featured.thumbnail]);

  return (
    <div className="w-full container-max-width px-4 3md:px-0">
      {/* listview */}
      {showFeatured && (
        <div className="w-full">
          {/* <p className="px-7.5 3md:px-0 text-lightBlack font-bold text-2xl leading-7.5 tracking-tighter">
            Featured
          </p> */}
          {!_.isEmpty(featured) && (
            <ListViewCard
              profile={profile}
              featured={featured}
              url={featuredThumbnail}
              viewUrl={featured.thumbnail.url}
              title={featured.title}
              tag={featured.tag}
              date={featuredDate}
              dateNote={featured.date_note}
              privacy={featured.privacy}
              people={featured.people}
              positions={featured.positions}
              platform={featured.platform}
              location={featured.location}
              description={featured.description}
              isAdultContent={featured.is_adult_content ? true : false}
              category={featured.category && featured.category.name}
              onView={() => onView(featured, "featured")}
              onEdit={() => onEdit(featured, "featured")}
              onDelete={() => onDelete(featured)}
              isYourProfile={isYourProfile}
              onShare={() => onShare(featured, "featured")}
              share={true}
              onReport={() => {
                onReport(featured);
              }}
            />
          )}
        </div>
      )}

      <div className="w-full mt-4.5 3md:mt-10">
        {
          pin.length > 0 &&
          <p className="milestone-title text-lightBlack font-bold text-base 2sm:text-xl 3md:text-2xl leading-7.5 tracking-tighter flex gap-3">
            <>
            Pinned
            </> 
            <AiFillPushpin className="text-2xl"/>
          </p>
        }
        {pin.map((obj, index) => {
          const date = formatDate(obj,profile?.dateFormat);
          const dateMobile = {start_year : obj?.start_year || null, end_year: obj?.end_year || null, is_present:obj?.is_present}
          const thumbnail = obj.thumbnail
            ? obj.thumbnail.webp_url || obj.thumbnail.url
            : null;
          return (
            <ListViewCard
              dateMobile = {dateMobile}
              profile={profile}
              featured={obj}
              key={`past-${index}`}
              details={obj}
              url={thumbnail}
              viewUrl={obj?.thumbnail?.url || null}
              title={obj.title}
              tag={obj.tag}
              date={date}
              dateNote={obj.date_note}
              privacy={obj.privacy}
              people={obj.people}
              positions={obj.positions}
              positionDescription={obj.mp_description}
              platform={obj.platform}
              location={obj.location}
              description={obj.description}
              isAdultContent={obj.is_adult_content ? true : false}
              category={obj.category.name}
              onView={() => onView(obj, "past")}
              onEdit={() => onEdit(obj, "past")}
              onDelete={() => onDelete(obj)}
              isYourProfile={isYourProfile}
              onShare={() => onShare(obj, "past")}
              share={true}
              onReport={() => onReport(obj)}
            />
          );
        })}
      </div>

      <div className="w-full mt-4.5 3md:mt-10">
        { current.length > 0 &&
          <p className="text-lightBlack font-bold text-base 2sm:text-xl 3md:text-2xl leading-7.5 tracking-tighter">
          {
            organizeBy === DATE_NEWEST_TO_OLDEST ? DATE_NEWEST_TO_OLDEST  :
            organizeBy === DATE_OLDEST_TO_NEWEST ? DATE_OLDEST_TO_NEWEST : 'Upcoming'
          }
        </p>}
        {current.map((obj, index) => {
          const date = formatDate(obj,profile?.dateFormat); 
          const dateMobile = {start_year : obj?.start_year || null, end_year: obj?.end_year || null, is_present:obj?.is_present}
          const thumbnail = obj.thumbnail
            ? obj.thumbnail.webp_url || obj.thumbnail.url
            : null;
          return (
            <ListViewCard
              dateMobile={dateMobile}
              profile={profile}
              featured={obj}
              key={`current-${index}`}
              details={obj}
              url={thumbnail}
              viewUrl={obj?.thumbnail?.url || null}
              title={obj.title}
              tag={obj.tag}
              date={date}
              dateNote={obj.date_note}
              privacy={obj.privacy}
              people={obj.people}
              positions={obj.positions}
              platform={obj.platform}
              location={obj.location}
              description={obj.description}
              isAdultContent={obj.is_adult_content ? true : false}
              category={obj.category.name}
              onView={() => onView(obj, "current")}
              onEdit={() => onEdit(obj, "current")}
              onDelete={() => onDelete(obj)}
              isYourProfile={isYourProfile}
              onShare={() => onShare(obj, "current")}
              share={true}
              onReport={() => onReport(obj)}
            />
          );
        })}
      </div>
      <div className="w-full mt-4.5 3md:mt-10">
      {past.length > 0 &&
        <p className="text-lightBlack font-bold text-base 2sm:text-xl 3md:text-2xl leading-7.5 tracking-tighter">
          {
            (organizeBy === DATE_NEWEST_TO_OLDEST || organizeBy === DATE_OLDEST_TO_NEWEST) ? '' : 'Previous'
          }
        </p>
        }
        {past.map((obj, index) => {
          const date = formatDate(obj,profile?.dateFormat);
          const dateMobile = {start_year : obj?.start_year || null, end_year: obj?.end_year || null, is_present:obj?.is_present}
          const thumbnail = obj.thumbnail
            ? obj.thumbnail.webp_url || obj.thumbnail.url
            : null;
          return (
            <ListViewCard
              dateMobile={dateMobile}
              profile={profile}
              featured={obj}
              key={`past-${index}`}
              details={obj}
              url={thumbnail}
              viewUrl={obj?.thumbnail?.url || null}
              title={obj.title}
              tag={obj.tag}
              date={date}
              dateNote={obj.date_note}
              privacy={obj.privacy}
              people={obj.people}
              positions={obj.positions}
              positionDescription={obj.mp_description}
              platform={obj.platform}
              location={obj.location}
              description={obj.description}
              isAdultContent={obj.is_adult_content ? true : false}
              category={obj.category.name}
              onView={() => onView(obj, "past")}
              onEdit={() => onEdit(obj, "past")}
              onDelete={() => onDelete(obj)}
              isYourProfile={isYourProfile}
              onShare={() => onShare(obj, "past")}
              share={true}
              onReport={() => onReport(obj)}
            />
          );
        })}
      </div>
    </div>
  );
}

export { ListViewCard };
