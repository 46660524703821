import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "components/global/modal";
import Button from "components/button/button";
import ModalHeader from "components/global/modal-header";
import Input from "components/input/input";
import Select from "components/select/select";
import _ from "lodash";
import LoadingIndicator from "assets/svg/loading-indicator";
import { gender, pronoun } from "utils/constant";
import toast from "react-hot-toast";
import { ADD_PROFILE } from "redux/modules/user/types";
import { usePlacesWidget } from "react-google-autocomplete";
import CreatableSelect from "components/select/creatable";
export default function AddProfile({ hide, userData, ...props }) {
  const { http } = global.services;

  const [options, setOptions] = useState({
    occupations: [],
  });
  const [occupation, setOccupation] = useState([]);
  const [state, setState] = useState({
    profile_name: null,
    gender: null,
    validationErrors: null,
    loading: false,
  });

  const { ref } = usePlacesWidget({
    apiKey:process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place) => {
      setState((prev) => ({
        ...prev,
        address: place.formatted_address,
        validationErrors: { ...state.validationErrors, address: null },
      }));
    }
  });

  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
      validationErrors: { ...state.validationErrors, [name]: null },
    }));
  };

  const onChangeOccupation = (e) => {
    setOccupation(e);
  };

  const submit = async (e) => {
    e.preventDefault();
    setState((prev) => ({ ...prev, loading: true }));

    let payload = _.clone(state);

    if (occupation.length > 0) {
      let occupationValues = occupation.map((o) => o.label);
      payload.occupations = JSON.stringify(occupationValues);
    } else {
      payload.occupations = null;
    }

    payload.user_id = userData.user.id;

    try {
      const { data } = await http.post("public/profiles/add-profile", payload);

      dispatch({
        type: ADD_PROFILE,
        payload: data.data,
      });

      hide({ success: true, id: data.data.id });
    } catch (error) {
      if (error.status === 422)
        return setState((prev) => ({
          ...prev,
          validationErrors: error.data?.errors,
        }));

      toast.error(
        error.status === 401
          ? "Invalid Credentials"
          : error?.data?.message || "It appears that something went wrong"
      );
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  const Footer = () => {
    return (
      <>
        <div className="w-full flex items-center justify-start 1sm:justify-end space-x-2">
          <Button
            buttonName="Cancel"
            buttonClass="bg-thBg py-4 px-8"
            buttonTextClass="text-black text-sm font-semibold"
            onClick={() => hide()}
          />
          <Button
            key="save-btn-category"
            buttonName="Create Profile"
            buttonClass="bg-primary py-4 px-8"
            buttonTextClass="text-white text-sm font-semibold flex items-center"
            onClick={submit}
            loadingIcon={
              state.loading ? (
                <LoadingIndicator className="text-white w-4 h-4 ml-2" />
              ) : null
            }
          />
        </div>
      </>
    );
  };

  const getOccupations = async () => {
    try {
      const { data } = await http.get("public/occupations");
      const options = data.map((opt) => ({
        label: opt.name,
        value: opt.id,
      }));
      setOptions((prev) => ({ ...prev, occupations: options }));
    } catch (err) {
      console.log("getOccupations", err);
    }
  };

  const createOccupation = async (value) => {
    try { 
      setOccupation([...occupation, { label: value, value: randomNumber(options.occupations.length + 2, options.occupations.length+50) }]);
      let tempOccupation = [...options.occupations, { label: value, value: randomNumber(occupation.length + 2, occupation.length+50) }]
      setOptions((prev) => ({...prev, occupations:tempOccupation}));
    } catch (err) {
      console.log(err);
    }
  };

  const randomNumber = (min, max) => {
    return Math.random() * (max - min) + min;
  }

  useEffect(() => {
    getOccupations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      footer={<Footer />}
      width="w-19/20 md:w-17/20 2md:w-16/20 3md:w-14/20 4md:w-12/20 1xl:w-9/20"
      contentClassName="rounded-lg overflow-auto custom-scroll"
      wrapperClassName="overflow-visible"
      footerClass="border-t border-gray">
      <div className="w-full">
        {/* header */}
        <ModalHeader
          title="Add Account"
          hide={hide}
          showPopInfo
          popInfoType="add_account"
        />
        {/* content */}
        <div className="px-8.5 py-6">
          {/* profile */}
          <div className="w-full">
            {/* forms */}
            <Input
              inputContainerClass="form-input-container"
              value={state?.profile_name || ""}
              name="profile_name"
              outsideLabel="Profile Name"
              placeholder="Dame Edna Everage"
              outsideLabelClass="text-sm font-bold text-darkerGray marked"
              noLabelPaddingY="1rem"
              type="text"
              onChange={handleInputChange}
              errorMessage={state.validationErrors?.profile_name}
            />
          </div>
          <div className="w-full">
            <div className="w-full grid 4sm:grid-cols-2 gap-x-4 gap-y-2 mb-2">
              <Select
                label="Gender"
                options={gender}
                placeholder="Select Gender"
                name="gender"
                height={58}
                value={
                  state.gender && gender.filter((g) => g.value === state.gender)
                }
                onChange={(e) =>
                  handleInputChange({
                    target: { name: "gender", value: e.value },
                  })
                }
                errorMessage={state.validationErrors?.gender}
                labelClass="text-sm font-bold text-darkerGray leading-4 marked"
              />
              <Select
                label="Pronouns"
                optional={true}
                options={pronoun}
                placeholder="Select Pronouns"
                name="pronoun"
                height={58}
                value={
                  state.pronoun &&
                  pronoun.filter((g) => g.value === state.pronoun)
                }
                onChange={(e) =>
                  handleInputChange({
                    target: { name: "pronoun", value: e.value },
                  })
                }
                errorMessagePosition="bottom"
              />
            </div>
            {/* <Input
              inputContainerClass="form-input-container"
              value={state?.address || ""}
              name="address"
              outsideLabel="Address"
              placeholder="Melbourne, Australia"
              outsideLabelClass="text-sm font-bold text-darkerGray marked"
              noLabelPaddingY="1rem"
              type="text"
              onChange={handleInputChange}
              errorMessage={state.validationErrors?.address}
              errorMessagePosition="bottom"
            /> */}
             <div className="form-input-container">
                <label for="address" className="text-sm font-bold text-darkerGray leading-4">Location (eg city, state, country)</label>
                <div className="input-wrapper">
                  <input ref={ref}  
                    className="input-class w-full" type="text" name="address" placeholder="Melbourne VIC, Australia" 
                    value={state?.address || ""}
                    onChange={handleInputChange}
                    style={{
                      padding:  "1rem",
                    }}
                    />
                    {
                      state.validationErrors?.address && 
                      <p className="input-error-message">* {state.validationErrors?.address}</p>
                    }
                  </div>
                </div>
            {/* <Select
              label="Occupations"
              options={options.occupations}
              placeholder="Select occupations"
              name="occupations"
              height={58}
              isMulti
              value={occupation}
              onChange={(e) => onChangeOccupation(e)}
              errorMessage={state.validationErrors?.occupations}
              labelClass="text-sm font-bold text-darkerGray leading-4 marked"
            /> */}
            <CreatableSelect
              className="capitalize"
              label="Occupations / Skills"
              options={options.occupations}
              placeholder="Select occupations"
              name="occupations"
              isMulti
              value={occupation}
              onChange={(e) => onChangeOccupation(e)}
              onCreateOption={createOccupation}
            />
          </div>
        </div>
        {/* end content */}
      </div>
    </Modal>
  );
}
