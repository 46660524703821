import { combineReducers, createStore, applyMiddleware } from "redux";
import subscribeActionMiddleware from "redux-subscribe-action";
import UserReducer from "./modules/user/reducer";
import MilestoneReducer from "./modules/milestone/reducer";
import PositionReducer from "./modules/position/reducer";
import SearchProfileReducer from "./modules/search-profile/reducer";
import NotificationReducer from "./modules/notification/reducer";
import CountryReducer from "./modules/country/reducer";
import StatesReducer from "./modules/states/reducer";
import CategoryReducer from "./modules/category/reducer";
import PrivacyReducer from "./modules/privacy/reducer";
import MediaTypeReducer from "./modules/media-type/reducer";
import MediaSubTypeReducer from "./modules/media-sub-type/reducer";
import ProfileViewReducer from "./modules/profile-view/reducer";
import AccessPermissionsReducer from "./modules/access-permissions/reducer";
import UserAccessReducer from "./modules/user-access/reducer";
import MessagesReducer from "./modules/messages/reducer";
import OccupationReducer from "./modules/occupation/reducer";
import CategoryFilterReducer from "./modules/category-filter/reducer";
import PositionFilterReducer from "./modules/position-filter/reducer";
import PlanReducer from "./modules/plan/reducer"
import ResumeReducer from "./modules/resume/reducer"

const allReducers = combineReducers({
  user: UserReducer,
  milestone: MilestoneReducer,
  position: PositionReducer,
  searchProfile: SearchProfileReducer,
  notifications: NotificationReducer,
  country: CountryReducer,
  states: StatesReducer,
  category: CategoryReducer,
  privacy: PrivacyReducer,
  mediaType: MediaTypeReducer,
  mediaSubType: MediaSubTypeReducer,
  profileView: ProfileViewReducer,
  accessPermission: AccessPermissionsReducer,
  userAccess: UserAccessReducer,
  messages: MessagesReducer,
  occupation: OccupationReducer,
  categoryFilter: CategoryFilterReducer,
  positionFilter: PositionFilterReducer,
  plan: PlanReducer,
  resume: ResumeReducer
});

const rootReducer = (state, action) => {   
  // Clear all data in redux store to initial.
  if(action.type === 'DESTROY_SESSION') {
    state = undefined;
  }
  
  return allReducers(state, action);
};

const store = createStore(
  rootReducer,
  applyMiddleware(subscribeActionMiddleware),
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
export default store;
