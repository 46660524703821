import {
	SET_PERMISSIONS,
} from './types'

const initialState = {
	permissions: [],
	refresh: false,
	initialize: true
};

const reducer = (state = initialState, {type, payload}) => {

	switch (type) {

		case SET_PERMISSIONS:
			return {
				...state,
				refresh: false,
				initialize: false,
				permissions: payload,
			}

		default:
			return state;

	}

}

export default reducer