import React from "react";
import MessagePremiumUpgradeHeaderLocIconSVG from "assets/svg/mesages-premium-upgrade-header-lock-svg";

export default function HeaderMessagesUpgradePremium() {

  return (
    <div className="bg-white px-2 py-2">
      <div className="relative w-full flex items-center gap-x-5 bg-white px-6 py-4 rounded-md">
        <MessagePremiumUpgradeHeaderLocIconSVG className="upgrade-premium-header-lock" />
        <div className="w-15/20">
          <p className="text-lightBlack font-bold text-lg leading-5.5 tracking-tighter flex items-center">
            Messages
          </p>
      </div>
    </div>
    </div>
  );
}
