import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { SidebarMenu } from "components/global/tabselector";
import { sideBarMenuList } from "./builder";

const variants = {
  open: { display: "block", x: 0 },
  closed: { display: "none", x: "-100%" },
};

export default function ResumeSideBarMobile({
  isOpen = false,
  selectedTab,
  onChangeTabs = () => {},
}) {
  return (
    <DivComponent open={isOpen}>
      <motion.div
        className="sidebar-mobile"
        animate={isOpen ? "open" : "closed"}
        variants={variants}
        transition={{ duration: 0.5 }}>
        <div className="sidebar-mobile-container">
          {sideBarMenuList.map((obj, index) => (
            <SidebarMenu
              key={index}
              isActive={selectedTab === obj.value}
              colorType="blue"
              onClick={() => onChangeTabs(obj.value)}>
              <div className="w-full flex flex-row justify-start items-center space-x-3">
                <div className="relative w-6 h-6 rounded-full border-2 resume-side-number">
                  {index + 1}
                </div>
                <span>{obj.label}</span>
              </div>
            </SidebarMenu>
          ))}
        </div>
      </motion.div>
    </DivComponent>
  );
}

const DivComponent = styled.div`
  position: absolute;
  top: 0;
  transition: left 0.5s;
  left: ${({ open }) => (open ? "0" : "-323px")};
  z-index: 9999;

  .sidebar-mobile {
    z-index: 9999;
    background-color: white;
    width: 323px;
    height: 100vh;

    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 23px;
    padding-right: 20px;

    border-right: 1px solid #d6d6d6;
  }

  .sidebar-mobile-container {
    width: 100%;
    display: grid;
    row-gap: 4px;
  }
`;
