import React, { useEffect, useContext } from "react";
import ResumeIconPlusSVG from "assets/svg/resume-icon-pus";
import { useHistory } from "react-router-dom";
import ResumeItem from "./item";
import { useResume } from "redux/modules/resume/hooks";
import moment from "moment";
import { Context } from "context/context";
import _ from "lodash";
import PopInfo from "components/info-popup/pop-info";

export const renderStartDate = (data) => {
  const { start_day, start_month, start_year } = data;
  if (start_day && start_month && start_year) {
    return (
      <span>
        {moment(`${start_year} ${start_month} ${start_day}`).format(
          "DD MMM YYYY"
        )}
      </span>
    );
  } else if (start_month && start_year) {
    return (
      <span>
        {moment(start_month).format("MMM")} {start_year}
      </span>
    );
  } else if (start_year) {
    return <span>{start_year}</span>;
  }

  return null;
};

export const renderEndDate = (data) => {
  const { end_day, end_month, end_year } = data;
  if (end_day && end_month && end_year) {
    return (
      <>
        <span> - </span>
        <span>
          {moment(`${end_year} ${end_month} ${end_day}`).format("DD MMM YYYY")}
        </span>
      </>
    );
  } else if (end_month && end_year) {
    return (
      <>
        <span> - </span>
        <span>
          {moment(end_month).format("MMM")} {end_year}
        </span>
      </>
    );
  } else if (end_year) {
    return (
      <>
        <span> - </span>
        <span>{end_year}</span>
      </>
    );
  }

  return null;
};

export default function ResumeList(props) {
  const { http } = global.services;
  const history = useHistory();
  const { profileId } = props;
  const { resumes, initialize, setResumes } = useResume();

  const {
    state,
    setState,
    resetState,
    setMilestones,
    resetMilestones,
    setPreviewImage,
  } = useContext(Context);

  /******** functions ********/
  const fetchResume = async () => {
    try {
      const { data } = await http.get("public/resumes", {
        user_profile_id: profileId,
      });
      setResumes(data);
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * share/download resume
   *
   * @param id - link id
   */
  const create = async () => {
    if (state.hasOwnProperty("id")) {
      resetState();
      resetMilestones();
    }

    setTimeout(() => {
      history.push(`/resume/builder`);
    });
  };

  /**
   * share/download resume
   *
   * @param id - link id
   */
  const download = async (id) => {
    window.open(`${process.env.REACT_APP_API_DOMAIN}/resume/${id}`, "_blank");
  };

  /**
   * preview / edit resume
   *
   * @param item - resume
   */
  const onNavigate = (item, route) => {
    let data = {
      personal: item.personal_info,
      more: item.more_info,
      show_milestone_image: item.show_milestone_image,
    };

    if (item.social_media) {
      data.social = item.social_media;
    }

    if (item.personal_info.hasOwnProperty("profile_image")) {
      setPreviewImage(
        item?.profile_image_withURL || item.personal_info.profile_image
      );
    } else {
      setPreviewImage("");
    }

    setState((prev) => ({
      ...prev,
      ...data,
      templateName: item.template_name,
      color: item.template_color,
      link_id: item.link_id,
      id: item.id,
    }));

    if (!_.isEmpty(item.resume_milestones)) {
      Object.keys(item.resume_milestones).forEach((key) => {
        const milestones = item.resume_milestones[key]?.map?.((m) => m.milestone);
        setMilestones((prev) => ({
          ...prev,
          [key]: milestones,
        }));
      });
    } else {
      resetMilestones();
    }

    if (route === "preview") {
      history.push("/resume/preview");
    } else if (route === "edit") {
      history.push("/resume/builder", {
        isEdit: true,
        resumeDetails: item,
      });
    }
  };

  /******** use effects ********/
  useEffect(() => {
    if (initialize) {
      fetchResume();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialize]);

  return (
    <div className="w-full bg-white rounded-md px-5 2md:pl-29 2md:pr-24.5 py-10 md:pb-14 md:pt-8">
      <div className="w-full grid gap-y-8">
        <div className="w-full flex flex-row gap-x-2 items-center">
          <h1 className="text-2xl font-semibold text-lightBlack">My Resumes</h1>
          <PopInfo containerClass="relative" type="resume" />
        </div>

        {/* resume lists */}
        <div className="w-full">
          <div className="w-full grid 4sm:grid-cols-2 1smd:grid-cols-3 xl:grid-cols-4 gap-7.5">
            <div
              className="w-full h-470 4sm:h-full bg-modalGray resume-create flex flex-col items-center justify-center space-y-4.5 cursor-pointer"
              style={{ minHeight: 470 }}
              onClick={() => create()}>
              <ResumeIconPlusSVG />
              <p className="text-darkerGray font-semibold">Create New Resume</p>
            </div>
            {/* list resume */}
            {resumes.map((obj, index) => (
              <ResumeItem
                {...obj}
                key={`resume-${index}`}
                onDownload={() => download(obj.link_id)}
                onEdit={() => onNavigate(obj, "edit")}
                onPreview={() => onNavigate(obj, "preview")}
              />
            ))}
            {/* end here */}
          </div>
        </div>
        {/* end: lists */}
      </div>
    </div>
  );
}

export const groupArraysWithType = (arraysWithType, minLimit = 9,maxLimit = 9) => { //minLimit is for first page and maxLimit for the second page depend on template
  const result = [];
  let group = [];
  let limit = minLimit;

  arraysWithType.forEach(({ array, type }) => {
    array.forEach((item, index) => {
      if (group.length < limit) {
        group.push({ ...item, type });
      }
      if (group.length === limit) {
        result.push(group);
        group = [];
        limit = maxLimit;
      }
    });
  });

  if (group.length > 0) {
    result.push(group);
  }

  return result;
};

export const A4_SIZE = {
  // width: "210mm", // 794 px
  // height: "297mm", // 1123px
  width: "595px", 
  height: "842px",
  overflow: "hidden",
  // marginRight: "20px",
  heightTOpx:1123
  };
