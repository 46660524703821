import React, { useMemo } from "react";
import { HiOutlineCalendarDays } from "react-icons/hi2";
import { renderEndDate, renderStartDate } from "../../resume";
import { socialTemplate } from "utils/constant";
import _ from "lodash";

export default function TemplateThreeFullPageTwo(props) {
  const {
    color = {},
    milestones = {},
    social = {},
    primaryColor,
    tertiaryColor,
    show_image_milestone = true
  } = props;

  const activeColor = useMemo(() => {
    if (color.label === "Voilet + Pink") {
      return tertiaryColor;
    }
    return primaryColor;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  const socMed = useMemo(() => {
    let arrays = Object.entries(social);
    return arrays.map((obj) => {
      let key = obj[0];
      let value = obj[1];
      return { key, value };
    });
  }, [social]);

  // 1.371

  return (
    <div className="w-full">
      <div
        className="w-full bg-resumeTempThreePrimary h-full"
        style={{ minHeight: "1248px" }}>
        <div className="w-full h-full">
          <div className="w-full h-full grid relative grid-row-auto">
            <div
              className="w-full h-full px-13.5"
              style={{ minHeight: "1202px" }}>
              {/* page number */}
              <div className="w-full relative">
                <div className="w-8 h-8 bg-resumeTempThreeSecondary template-three-page-number">
                  <p className="text-xl text-resumeTempThreeTextSecondary tracking-resumeThree leading-3.2 font-semibold font-template-three">
                    02
                  </p>
                </div>
              </div>
              {/* body */}
              <div className="w-full mt-8.6">
                <div className="w-full grid gap-y-8">
                  {/* Featured  */}
                  {milestones.hasOwnProperty("featured") &&
                    !_.isEmpty(milestones.featured) && (
                      <div className="w-full">
                        <p className="uppercase text-sm text-resumeTempThreeTextPrimary font-medium tracking-resumeThreeWide">
                          Featured Milestones
                        </p>
                        {/* content */}
                        {milestones.featured.map((mf, i) => (
                          <div className="w-full mt-3" key={`featured-${i}`}>
                            <div className="w-full grid gap-y-2">
                              <div className="w-full">
                                {/* title */}
                                <div className="w-full flex flex-row justify-between items-center">
                                  <div className="font-template-three text-white">
                                    <div className="flex flex-row space-x-2 items-center">
                                    {show_image_milestone && <div className="w-5 h-5 rounded-full relative overflow-hidden bg-passwordGray">
                                        {mf.thumbnail && (
                                          <img
                                            alt="milestone"
                                            src={mf.thumbnail.url}
                                            className="w-full h-full object-contain object-center"
                                          />
                                        )}
                                      </div>}
                                      <p className="text-xl font-bold leading-7 text-opacity-90">
                                        {mf.title}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="text-1xs font-bold text-white">
                                    <p className="uppercase p-1.5 bg-resumeTempThreeSecondary rounded-sm">
                                      {mf.category.name}
                                    </p>
                                  </div>
                                </div>
                                {/* date */}
                                <div className="w-full text-white text-opacity-60 mt-1">
                                  <p className="text-4xs font-light leading-2 flex flex-row space-x-1 items-center">
                                    <HiOutlineCalendarDays className="w-4 h-4" />
                                    <span className="mt-px">
                                      {renderStartDate(mf)}
                                      {renderEndDate(mf)}
                                    </span>
                                  </p>
                                </div>
                              </div>
                              {/* actor */}
                              {mf.positions.map((p, i) => (
                                <div
                                  key={`featured-position-${i}`}
                                  className="w-full flex flex-row space-x-2"
                                  style={{ color: activeColor }}>
                                  <>
                                    <p className="text-6xs leading-2 font-medium">
                                      <span>{p.position.name}</span>
                                      {p.position.description && (
                                        <>
                                          <span> - </span>
                                          <span>{p.position.description}</span>
                                        </>
                                      )}
                                      {mf.platform && (
                                        <span>{` (${mf.platform})`}</span>
                                      )}
                                    </p>
                                    {mf.date_note && (
                                      <>
                                        <span className="text-6xs leading-2">
                                          &#8226;
                                        </span>
                                        <p className="text-6xs leading-2 font-medium">
                                          {mf.date_note}
                                        </p>
                                      </>
                                    )}
                                  </>
                                </div>
                              ))}
                              {/* description */}
                              <div className="w-full text-white text-opacity-60">
                                <p
                                  className="text-6xs font-light leading-4 self-stretch"
                                  dangerouslySetInnerHTML={{
                                    __html: mf.description,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  {/* Current Milestones  */}
                  {milestones.hasOwnProperty("current") &&
                    !_.isEmpty(milestones.current) && (
                      <div className="w-full">
                        <p className="uppercase text-sm text-resumeTempThreeTextPrimary font-medium tracking-resumeThreeWide">
                          Current Milestones
                        </p>
                        {/* content */}
                        {milestones.current.map((mf, i) => (
                          <div className="w-full mt-3" key={`current-${i}`}>
                            <div className="w-full grid gap-y-2">
                              <div className="w-full">
                                {/* title */}
                                <div className="w-full flex flex-row justify-between items-center">
                                  <div className="font-template-three text-white">
                                    <div className="flex flex-row space-x-2 items-center">
                                      {show_image_milestone && <div className="w-5 h-5 rounded-full relative overflow-hidden bg-passwordGray">
                                        {mf.thumbnail && (
                                          <img
                                            alt="milestone"
                                            src={mf.thumbnail.url}
                                            className="w-full h-full object-contain object-center"
                                          />
                                        )}
                                      </div>}
                                      <p className="text-xl font-bold leading-7 text-opacity-90">
                                        {mf.title}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="text-1xs font-bold text-white">
                                    <p className="uppercase p-1.5 bg-resumeTempThreeSecondary rounded-sm">
                                      {mf.category.name}
                                    </p>
                                  </div>
                                </div>
                                {/* date */}
                                <div className="w-full text-white text-opacity-60 mt-1">
                                  <p className="text-4xs font-light leading-2 flex flex-row space-x-1 items-center">
                                    <HiOutlineCalendarDays className="w-4 h-4" />
                                    <span className="mt-px">
                                      {renderStartDate(mf)}
                                      {renderEndDate(mf)}
                                    </span>
                                  </p>
                                </div>
                              </div>
                              {/* actor */}
                              {mf.positions.map((p, i) => (
                                <div
                                  key={`featured-position-${i}`}
                                  className="w-full flex flex-row space-x-2"
                                  style={{ color: activeColor }}>
                                  <>
                                    <p className="text-6xs leading-2 font-medium">
                                      <span>{p.position.name}</span>
                                      {p.position.description && (
                                        <>
                                          <span> - </span>
                                          <span>{p.position.description}</span>
                                        </>
                                      )}
                                      {mf.platform && (
                                        <span>{` (${mf.platform})`}</span>
                                      )}
                                    </p>
                                    {mf.date_note && (
                                      <>
                                        <span className="text-6xs leading-2">
                                          &#8226;
                                        </span>
                                        <p className="text-6xs leading-2 font-medium">
                                          {mf.date_note}
                                        </p>
                                      </>
                                    )}
                                  </>
                                </div>
                              ))}
                              {/* description */}
                              <div className="w-full text-white text-opacity-60">
                                <p
                                  className="text-6xs font-light leading-4 self-stretch"
                                  dangerouslySetInnerHTML={{
                                    __html: mf.description,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  {/* Past Milestones  */}
                  {milestones.hasOwnProperty("past") &&
                    !_.isEmpty(milestones.past) && (
                      <div className="w-full">
                        <p className="uppercase text-sm text-resumeTempThreeTextPrimary font-medium tracking-resumeThreeWide">
                          Past Milestones
                        </p>
                        {/* content */}
                        {milestones.past.map((mf, i) => (
                          <div className="w-full mt-3" key={`past-${i}`}>
                            <div className="w-full grid gap-y-2">
                              <div className="w-full">
                                {/* title */}
                                <div className="w-full flex flex-row justify-between items-center">
                                  <div className="font-template-three text-white">
                                    <div className="flex flex-row space-x-2 items-center">
                                    {show_image_milestone && <div className="w-5 h-5 rounded-full relative overflow-hidden bg-passwordGray">
                                        {mf.thumbnail && (
                                          <img
                                            alt="milestone"
                                            src={mf.thumbnail.url}
                                            className="w-full h-full object-contain object-center"
                                          />
                                        )}
                                      </div>}
                                      <p className="text-xl font-bold leading-7 text-opacity-90">
                                        {mf.title}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="text-1xs font-bold text-white">
                                    <p className="uppercase p-1.5 bg-resumeTempThreeSecondary rounded-sm">
                                      {mf.category.name}
                                    </p>
                                  </div>
                                </div>
                                {/* date */}
                                <div className="w-full text-white text-opacity-60 mt-1">
                                  <p className="text-4xs font-light leading-2 flex flex-row space-x-1 items-center">
                                    <HiOutlineCalendarDays className="w-4 h-4" />
                                    <span className="mt-px">
                                      {renderStartDate(mf)}
                                      {renderEndDate(mf)}
                                    </span>
                                  </p>
                                </div>
                              </div>
                              {/* actor */}
                              {mf.positions.map((p, i) => (
                                <div
                                  key={`featured-position-${i}`}
                                  className="w-full flex flex-row space-x-2"
                                  style={{ color: activeColor }}>
                                  <>
                                    <p className="text-6xs leading-2 font-medium">
                                      <span>{p.position.name}</span>
                                      {p.position.description && (
                                        <>
                                          <span> - </span>
                                          <span>{p.position.description}</span>
                                        </>
                                      )}
                                      {mf.platform && (
                                        <span>{` (${mf.platform})`}</span>
                                      )}
                                    </p>
                                    {mf.date_note && (
                                      <>
                                        <span className="text-6xs leading-2">
                                          &#8226;
                                        </span>
                                        <p className="text-6xs leading-2 font-medium">
                                          {mf.date_note}
                                        </p>
                                      </>
                                    )}
                                  </>
                                </div>
                              ))}
                              {/* description */}
                              <div className="w-full text-white text-opacity-60">
                                <p
                                  className="text-6xs font-light leading-4 self-stretch"
                                  dangerouslySetInnerHTML={{
                                    __html: mf.description,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}

                  {/* end */}
                </div>
              </div>
            </div>
            {/* footer */}
            <div className="w-full flex items-end">
              <div className="w-full h-11.5 bg-resumeTempThreeTextPrimary bg-opacity-20 relative">
                <div className="w-full h-full flex flex-row justify-center items-center space-x-3 text-resumeTempThreeTextPrimary">
                  {(socMed || []).map((obj, index) => {
                    const SocialIcon = socialTemplate[obj.key];
                    if (SocialIcon) {
                      return (
                        <div
                          className="flex items-center space-x-1.5"
                          key={index}>
                          <SocialIcon
                            className="text-xl"
                            style={{ color: activeColor }}
                          />
                          <p className="text-4xs leading-4">{obj.value}</p>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
