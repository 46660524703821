const PaypalIconSVG = ({ className = "" }) => {
    return (
        <svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g clipPath="url(#clip0_7494_7511)">
                <path d="M26.6285 2.80206C24.9675 0.908837 21.965 0.097168 18.1238 0.097168H6.97575C6.5957 0.097182 6.22813 0.232769 5.93911 0.479551C5.65009 0.726334 5.45858 1.06812 5.39902 1.44347L0.757163 30.8832C0.664898 31.4638 1.11442 31.9895 1.70282 31.9895H8.58526L10.3137 21.026L10.2601 21.3694C10.3832 20.5944 11.046 20.0228 11.8309 20.0228H15.1014C21.5263 20.0228 26.5571 17.4132 28.0267 9.86402C28.0703 9.64074 28.1081 9.42343 28.1408 9.21113C27.9553 9.1129 27.9553 9.1129 28.1408 9.21113C28.5784 6.42089 28.1378 4.52159 26.6285 2.80206Z" fill="#27346A" />
                <path d="M12.9472 8.20599C13.1354 8.1164 13.3412 8.06997 13.5496 8.0701H22.2895C23.3244 8.0701 24.2897 8.13745 25.1718 8.27942C25.4187 8.31878 25.6642 8.36577 25.9082 8.42032C26.2539 8.49661 26.5956 8.58998 26.9321 8.7001C27.3658 8.84493 27.7697 9.01361 28.1409 9.21113C28.5784 6.41982 28.1378 4.52159 26.6285 2.80206C24.9666 0.908837 21.965 0.097168 18.1238 0.097168H6.97479C6.18983 0.097168 5.52204 0.668638 5.39902 1.44347L0.757164 30.8822C0.664899 31.4637 1.11442 31.9886 1.70187 31.9886H8.58526L12.1713 9.24784C12.2066 9.02432 12.2956 8.81271 12.4308 8.63122C12.5659 8.44974 12.7432 8.30381 12.9472 8.20599Z" fill="#27346A" />
                <path d="M28.0279 9.86407C26.5583 17.4121 21.5276 20.0229 15.1026 20.0229H11.8311C11.0462 20.0229 10.3833 20.5945 10.2614 21.3694L8.11104 35.0011C8.0307 35.5092 8.4236 35.9696 8.93761 35.9696H14.7393C15.0717 35.9695 15.3931 35.8508 15.6457 35.6349C15.8984 35.419 16.0658 35.1201 16.1177 34.7918L16.1742 34.4962L17.2677 27.5661L17.3381 27.183C17.3901 26.8548 17.5574 26.5559 17.81 26.34C18.0627 26.1241 18.3841 26.0054 18.7164 26.0053H19.5847C25.2048 26.0053 29.6055 23.722 30.8915 17.1185C31.4283 14.3589 31.1506 12.0549 29.7306 10.4366C29.2999 9.94644 28.765 9.54162 28.1419 9.21118C28.1082 9.42456 28.0716 9.6408 28.0279 9.86407Z" fill="#2790C3" />
                <path d="M26.6025 8.59779C26.3733 8.53088 26.142 8.47164 25.9089 8.42017C25.6649 8.36651 25.4193 8.31984 25.1726 8.28023C24.2895 8.1373 23.3249 8.06983 22.289 8.06983H13.5502C13.3417 8.06935 13.1357 8.11619 12.9479 8.20679C12.7436 8.30431 12.5662 8.45015 12.4309 8.63169C12.2957 8.81323 12.2068 9.025 12.1718 9.24865L10.3144 21.0259L10.2607 21.3692C10.3829 20.5943 11.0457 20.0227 11.8307 20.0227H15.1021C21.5271 20.0227 26.5578 17.413 28.0273 9.86387C28.0711 9.6406 28.1078 9.42424 28.1415 9.21098C27.7694 9.01453 27.3666 8.84478 26.9328 8.7009C26.8233 8.66461 26.7133 8.63024 26.6026 8.59779" fill="#1F264F" />
            </g>
            <defs>
                <clipPath id="clip0_7494_7511">
                    <rect width="30.5166" height="36" fill="white" transform="translate(0.741699)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default PaypalIconSVG;
