const NotificationEmpty = ()=>{
    return (
        <svg width="49" height="55" viewBox="0 0 49 55" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.422866 39.5962V38.1125C0.74992 37.287 1.14338 36.5258 1.83614 35.9134C5.13244 32.9966 6.75681 29.3178 6.75087 24.9174C6.74888 23.1057 6.75582 21.2931 6.74889 19.4814C6.74492 18.2733 6.88466 17.084 7.15027 15.9066C8.88267 8.25747 16.7439 3.23768 24.4197 4.86007C24.8905 4.95918 24.8072 5.11577 24.6833 5.43489C23.2651 9.07808 23.1947 12.7559 24.6982 16.3625C26.7775 21.3495 30.5515 24.3485 35.8517 25.412C36.1312 25.4684 36.3076 25.5031 36.3225 25.8738C36.4127 28.1671 37.048 30.3098 38.1917 32.3009C39.0123 33.729 40.0826 34.9451 41.3086 36.0402C42.4364 37.0481 42.8487 38.3167 42.4652 39.7904C42.0212 41.4951 40.5831 42.5298 38.705 42.5367C35.7903 42.5476 32.8746 42.5506 29.9598 42.5575C24.3325 42.5595 18.7052 42.5615 13.0779 42.5625C12.8975 42.5545 12.7181 42.5387 12.5377 42.5387C9.81031 42.5367 7.08288 42.5407 4.35544 42.5367C2.46349 42.5337 1.17014 41.6081 0.558643 39.837C0.528911 39.7498 0.541795 39.6338 0.421875 39.5962H0.422866Z" fill="#DADADA"/>
        <path d="M20.2951 54.3418C20.197 54.1644 20.0057 54.209 19.8581 54.1772C15.871 53.321 13.1346 49.9999 13.0781 45.9365C18.7054 45.9345 24.3328 45.9325 29.9601 45.9315C29.7787 50.316 27.0027 53.5073 22.7232 54.2476C22.6568 54.2595 22.5954 54.2684 22.5696 54.3408H20.2961L20.2951 54.3418Z" fill="#DADADA"/>
        <path d="M38.4009 21.4258C32.5892 21.4367 27.8638 16.74 27.8361 10.9254C27.8083 5.11079 32.5506 0.347688 38.3741 0.341741C44.2036 0.335795 48.9469 5.0761 48.939 10.8987C48.931 16.7123 44.2235 21.4149 38.4009 21.4258Z" fill="#EDEDED"/>
        </svg>
            )
}

export default NotificationEmpty


