import React from "react";
import Button from "components/button/button";
import { FaCheck } from "react-icons/fa";

import CardIconSVG from "assets/svg/card-icon-svg";

export default function PlanCardItem({
  obj = {},
  isFreeAccount = false,
  billing = "monthly",
  onCheckout = () => {},
}) {
  return (
    <div className="plan-card-item w-full md:w-555 bg-white rounded-2xl">
      {/* banner */}
      <div className="w-full">
        <img
          src={obj.banner}
          alt="milestone-page-banner-plan"
          className="w-full"
        />
      </div>
      {/* content */}
      <div className="py-10 px-12">
        {/* plan and price*/}
        <div className="w-full text-2.5xl text-lightBlack font-bold leading-8.5 tracking-tighter flex flex-wrap justify-between">
          <h1>{obj.plan}</h1>
          <h1 className="text-primary">
            {isFreeAccount ? (
              "FREE"
            ) : (
              <span>
                {`$${obj.unit_amount}/`}
                <small>{billing}</small>
              </span>
            )}
          </h1>
        </div>
        {/* description */}
        <p className="mt-6 text-base text-darkerGray tracking-tightest leading-7.5">
          {obj?.description}
        </p>
        {/* inclusions */}
        <ul className="text-base text-lightBlack tracking-tightest leading-7.5 mt-7 1sm:ml-8 md:ml-0 2md:ml-8">
          {(obj?.inclusions || []).map((obj, index) => (
            <li key={index} className="flex items-center py-1">
              <FaCheck
                className={`text-${obj.status ? "green" : "transparent"} mr-3`}
              />
              <span className={!obj.status ? "opacity-40" : ""}>
                {obj.name}
              </span>
            </li>
          ))}
        </ul>
        {/* button */}
        <div className="w-full flex flex-row gap-2 mt-12">
          {isFreeAccount ? (
            <Button
              buttonName="Get Started for Free"
              buttonTextClass="text-sm text-lightBlack font-semibold"
              buttonRadius="6px"
              buttonWidth="100%"
              buttonClass="relative py-4 bg-thBg hover:bg-gray"
              onClick={() => onCheckout(obj)}
            />
          ) : (
            <>
              <Button
                icon={<CardIconSVG className="mr-2" />}
                iconPosition="left"
                buttonName="Pay via Credit Card"
                buttonTextClass="text-sm text-white font-semibold flex items-center justify-center"
                buttonRadius="6px"
                buttonWidth="100%"
                buttonClass="relative py-4 px-2 bg-lightBlack hover:bg-lightBlack/90"
                onClick={() => onCheckout(obj, "credit")}
              />
              <Button
                buttonName="Pay via Paypal"
                buttonTextClass="text-sm text-white font-semibold"
                buttonRadius="6px"
                buttonWidth="100%"
                buttonClass="relative py-4 bg-primary hover:bg-darkPrimary"
                onClick={() => onCheckout(obj, "paypal")}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
