const LineVerticalSVG = ({ className = "", stroke = "#DDDDDD" }) => {
  return (
    <svg
      width="1"
      height="24"
      viewBox="0 0 1 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="0.5" y1="2.18557e-08" x2="0.499999" y2="24" stroke={stroke} />
    </svg>
  );
};

export default LineVerticalSVG;
