import {
	REFRESH_SEARCH_PROFILE,
	GET_SEARCH_PROFILE,
	SET_SEARCH_PROFILE,
} from './types'

const initialState = {
	refresh: false,
	loadMore: true,
	initialize: true,
	isRequesting: false,
	page: 1,
	results: []
};

const reducer = (state = initialState, { type, payload }) => {

	switch (type) {

		case REFRESH_SEARCH_PROFILE:
			return {
				...state,
				refresh: true,
				loadMore: true,
				initialize: true,
				isRequesting: false,
				page: 1,
				results: [],
			}

		case GET_SEARCH_PROFILE:
			return {
				...state,
				isRequesting: true,
			}

		case SET_SEARCH_PROFILE:
			let newResults = state.results

			payload.data.forEach((p) => {
				const result = state.results.filter(
					(u) => u.id === p.id
				);
				if (!result.length) {
					newResults.push(p);
				}
			});

			return {
				...state,
				refresh: false,
				initialize: false,
				isRequesting: false,
				page: payload.page,
				loadMore: payload.loadMore,
				results: newResults
			}

		default:
			return state;

	}

}

export default reducer