import React, { useEffect, useMemo, useState, useRef } from "react";
import Button from "components/button/button";
import Input from "components/input/input";
import Select from "components/select/select";
import CreatableSelect from "components/select/creatable";
import {
  gender,
  profileTypes,
  heroTypes,
  heroCTAs,
  heroIcon,
  maxUploadSize
} from "utils/constant";
import { FiCamera } from "react-icons/fi";
import Toggle from "components/toggle/toggle";
import update from "immutability-helper";
import SocialSelect from "./component/SocialSelect";
import ImageUpload from "components/image-upload/image-upload";
import { CoverPlaceholder, ProfilePlaceholder } from "constants/images";
import toast from "react-hot-toast";
import LoadingIndicator from "assets/svg/loading-indicator";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { urlSerializer } from "utils/utils";
import { ORGANIZE_BY } from "constants/options";
import { useDialogHook } from "utils/customhooks";
import CropThumbnailModal from "components/profile/milestones/crop-thumbnail";
import CropCoverModal from "components/profile/milestones/crop-cover";
import { useOccupations } from "redux/modules/occupation/hooks";
import { usePositionFilter } from "redux/modules/position-filter/hooks";
import { useCategoryFilter } from "redux/modules/category-filter/hooks";
import { social } from "utils/constant";
import PopInfo from "components/info-popup/pop-info";
import Members from "./component/Members";
import WebsiteSelect from "./component/WebsiteSelect";
import ToolTip from "components/tootltip/tootltip";
import { usePlacesWidget } from "react-google-autocomplete";
import { FiAlertCircle } from "react-icons/fi";
import DragDropSelect from "./component/DragDropSelect";

export default function SettingsGeneral({
  userData = null,
  setUserData = () => {},
}) {
  const history = useHistory();
  const { http, localstorage } = global.services;
  // const crop = useDialogHook(EditThumbnailModal);
  const cropProfile = useDialogHook(CropThumbnailModal);
  const cropCover = useDialogHook(CropCoverModal);

  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const user = user_data?.user || null;
  const profUsername = user_data?.profile || null;
  const sets_display_name = [
    { label: user?.first_name || '', value: "First Name" },
    { label: user?.last_name || '', value: "Surename" },
    { label: `${user?.first_name || ''} ${user?.last_name || ''}`, value: "First Name Surname" },
    { label: profUsername?.username || '', value: "Username" },
    // { label: 'Other Name' || '', value: "Other Name" },
  ];

  const [displayName, setDisplayName] = useState(sets_display_name)
  const fileInputRef = useRef(null);
  const {
    refresh: refreshPoisitionFilter,
    initialize: initializePositionFilter,
    options: positionOptions,
    setPositionFilter,
  } = usePositionFilter();

  const { ref } = usePlacesWidget({
    apiKey:process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place) => {
      setState((prev) => ({
        ...prev,
        address: place.formatted_address,
        validationErrors: { ...state.validationErrors, address: null },
      }));
    }
  });

  const {
    refresh: refreshCategoryFilter,
    initialize: initializeCategoryFilter,
    options: categoryOptions,
    setCategoryFilter,
  } = useCategoryFilter();

  const {
    refresh: refreshOccupation,
    initialize: initializeOccupation,
    occupations,
    setOccupations,
    addOccupation,
  } = useOccupations();

  const [websites, setWebsites] = useState([
    { social: "", link: "", media_type: "website" },
  ]);
  //const [websitesClone, setWebsitesClone] = useState();
  const [socials, setSocials] = useState([
    { social: "", link: "", media_type: "social" },
  ]);
  const [socialsClone, setSocialsClone] = useState([
    { social: "", link: "", media_type: "social" },
  ]);
  const [otherNames, setOtherNames] = useState([
    { index: "", label:"" , value:""},
  ]);
  const [members, setMembers] = useState([]);
  const [profile, setProfile] = useState(ProfilePlaceholder);
  const [cover, setCover] = useState(CoverPlaceholder);
  // const [keyword, setKeyword] = useState([]);
  const [occupation, setOccupation] = useState([]);
  const [state, setState] = useState({
    profile_image_file: null,
    cover_image_file: null,
    username: null,
    email: null,
    profile_username: null,
    other_name: null,
    address: null,
    condition: true,
    loading: false,
    validationErrors: null,
    adultContent: false,
    featured: true,
    showNotifications: true,
    metadata: {},
    other_gender: null,
    hero: {},
    dateFormat: true,
    profile_type: {},
    profileTypes: "",
    displayName:null,
    profile_name:null
  });
  const [socialOptions, setSocialOptions] = useState(social);
  const [uploadErrors, setUploadErrors] = useState(null);

  const handleInputChange = (e, others_name = false) => {
    const { name, value } = e.target; 
    if(others_name){
      let isOtherName = sets_display_name.find(item => item.value === value);
      if(typeof isOtherName === 'undefined'){
        setState((prev) => ({
          ...prev,
          [name]: value,
          other_name: value,
          validationErrors: { ...state.validationErrors, [name]: null },
        })); 
        return;
      }
    } 
    setState((prev) => ({
      ...prev,
      [name]: value,
      validationErrors: { ...state.validationErrors, [name]: null },
    }));
  };

  const handleInputObjectChange = (e, object) => {
    const { name, value } = e.target;

    setState((prev) => ({
      ...prev,
      [object]: { ...prev[object], [name]: value },
    }));
  };

  const onChangeOccupation = (e) => {
    setOccupation(e);
  };

  const createOccupation = async (value) => {
    try {
      let { data } = await http.post("public/occupations", {
        user_id: userData.user.id,
        user_profile_id: userData.profile.id,
        occupation: value,
      });
      setOccupation((prev) => [...prev, { label: value, value: data.data.id }]);
      addOccupation({
        label: value,
        value: data.data.id,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const onAddSocials = (media_type) => {
    if (media_type === "social") {
      let payload = _.clone(socials);
      payload.push({ social: "", link: "", media_type: "social" });
      setSocials(payload);
    } else if (media_type === "website") {
      let payload = _.clone(websites);
      payload.push({ social: "", link: "", media_type: "website" });
      setWebsites(payload);
    }
  };

  const onAddOtherName = ()=>{
    let payload = _.clone(otherNames);
    payload.push({ index: "", label: "", value: "",});
    setOtherNames(payload);
    let newArray = [...sets_display_name, ...payload];
    setDisplayName(newArray);
  }

  const onRemoveSocials = (index, media_type) => {
    if (media_type === "social") {
      let options = socialOptions.map((so) => {
        if (so.value === socials[index].social) {
          const { selected, ...val } = so;
          return val;
        }
        return so;
      });
      
      let removeValidation = removeSocialErrorByIndex(state.validationErrors, index,socials);
      setState((prev) => ({
        ...prev,
        validationErrors: {...removeValidation },
      }));

      setSocialOptions((prev) => options);
      let payload = _.clone(socials);
      let result = update(payload, { $splice: [[index, 1]] }); //remove 
      setSocials(result);
    } else if (media_type === "website") {
      let payload = _.clone(websites);
      let result = update(payload, { $splice: [[index, 1]] }); //remove
      setWebsites(result);
    } 
  };

  const removeSocialErrorByIndex = (errorObj, indexToRemove,options) => {
      if(!_.isEmpty(errorObj)){ 
        const socialKeys = Object.keys(errorObj).filter(key => key.startsWith('social'));
        
        if(!_.isEmpty(socialKeys)){  
          const keyToRemove = socialKeys[indexToRemove];
          let { [keyToRemove]: _, ...remainingErrors } = errorObj;
          const newErrors = Object.keys(remainingErrors).reduce((acc, key) => {
            const match = key.split(".");
            const index = parseInt(match[1], 10); 
            if (key.match(/^social\.(\d+)\.type$/) && index !== indexToRemove) {
              if(index > indexToRemove){
                let newIndex = index - 1;
                const newKey = `social.${newIndex}.${match[2]}`;
                acc[newKey] = errorObj[key];
              }else{
                const newKey = `social.${index}.${match[2]}`;
                acc[newKey] = errorObj[key];
              }
            }
            return acc;
          }, {});
  
          return newErrors;
        }
       
      }
      
  };

  const onRemoveOtherName = (index) => { 
      let payload = _.clone(otherNames);
      let result = update(payload, { $splice: [[index, 1]] }); //remove
      setOtherNames(result); 
      let newArray = [...sets_display_name, ...result];
      setDisplayName(newArray);
    
  };

  const onSelectSocial = (e, index) => {
    let _remove = "";
    let value = e ? e.value : "";

    if (!value) {
      _remove = socials[index].social;
    }

    let options = socialOptions.map((so) => {
      if (so.value === value) {
        return {
          ...so,
          selected: true,
        };
      } else if (so.value === _remove) {
        const { selected, ...val } = so;
        return val;
      }

      return so;
    });
    setSocialOptions((prev) => options);

    let payload = _.clone(socials);
    let result = update(payload, {
      [index]: {
        social: { $set: value },
      },
    }); //remove
    setSocials(result);
  };

  const onAddMembers = () => {
    let payload = _.clone(members);
    payload.push({ name: "", role: "", dateSE: "" });
    setMembers(payload);
  };

  const onRemoveMembers = (index) => {
    let payload = _.clone(members);
    let result = update(payload, { $splice: [[index, 1]] }); //remove
    setMembers(result);
  };

  const onChangeMembers = (e, index) => {
    e.stopPropagation();

    let name = "";
    if (e.target.name === `name-${index}`) {
      name = e.target.value;
    } else {
      name = members[index].name;
    }

    let role = "";
    if (e.target.name === `role-${index}`) {
      role = e.target.value;
    } else {
      role = members[index].role;
    }

    let dateSE = "";
    if (e.target.name === `dateSE-${index}`) {
      dateSE = e.target.value;
    } else {
      dateSE = members[index].dateSE;
    }

    let payload = _.clone(members);
    let result = update(payload, {
      [index]: {
        name: { $set: name },
        role: { $set: role },
        dateSE: { $set: dateSE },
      },
    });
    setMembers(result);
  };

  const checkIconLink = (link) => {
    let tempSocial = [...social];
    let setType = link.replace(/.+\/\/|www.|\..+/g, "").toLowerCase(); //get domain name and set into type
    return tempSocial
      .map((obj) =>
        link.toLowerCase().includes(obj.site) ? obj.value : setType
      )
      .filter((value) => value !== setType);
  };

  const checkUrl = (e, index, media_type) => {
    if (
      !e.target.value.includes("https://") &&
      !e.target.value.includes("http://")
    ) {

      if(_.isEmpty(e.target.value)){
        return;
      } 
      let addProtocol = `https://${e.target.value}`;
      let payload = [];
      if (media_type === "social") {
        payload = _.clone(socials);
      } else if (media_type === "website") {
        payload = _.clone(websites);
      }

      let result = update(payload, {
        [index]: {
          link: { $set: addProtocol },
        },
      }); //remove

      if (media_type === "social") {
        setSocials(result);
      } else if (media_type === "website") {
        setWebsites(result);
      }
    } else {
      return;
    }
  };

  const onTypeLinkSocial = (e, index, media_type) => {
    let setType = e.target.value.replace(/.+\/\/|www.|\..+/g, "").toLowerCase();
    let payload = [];
    if (media_type === "social") {
      payload = _.clone(socials);
    } else if (media_type === "website") {
      payload = _.clone(websites);
    }

    let socialVal = checkIconLink(e.target.value)[0] || setType;
    let checkFav =
      payload[index]?.social === socialsClone[index]?.social
        ? socialsClone[index]?.fav_icon
        : null;
    let result = update(payload, {
      [index]: {
        link: { $set: e.target.value },
        social: { $set: socialVal },
        fav_icon: { $set: checkFav },
      },
    }); //remove

    if (media_type === "social") {
      setSocials(result);
    } else if (media_type === "website") {
      setWebsites(result);
    }
  };

  const onChangeOtherName = (e, index) => { 
    let payload = [];
    payload = _.clone(otherNames); 
    let result = update(payload, {
      [index]: {
        label: { $set: e.target.value }, 
        index: { $set: index },
        value: { $set: e.target.value }, 
      },
    }); //remove
    setOtherNames(result);
    let newArray = [...sets_display_name, ...result];
    setDisplayName(newArray);
    
  };

  const handleSave = async (e) => {
    e.preventDefault();
    let errors = {};
    setState((prev) => ({ ...prev, loading: true }));

    let payload = new FormData();
    let params = _.clone(state);
    let tempMember = [...members];
    params.profileGroup = JSON.stringify(tempMember);
    if (state?.heroSocialMedia)
      //re-assign the heroSocialMedia into hero object
      params.hero = {
        ...state.hero,
        socialMedia: state?.heroSocialMedia,
      };
    if (state?.heroCTA)
      //re-assign the heroCTA into hero object
      params.hero = {
        ...state.hero,
        socialMedia: state?.heroSocialMedia,
        heroCTA: state?.heroCTA,
      };
    if (state?.heroCTA)
      //re-assign the heroCTA into hero object
      params.hero = {
        ...state.hero,
        socialMedia: state?.heroSocialMedia,
        heroCTA: state?.heroCTA
      };

    Object.keys(params).forEach((key) => {
      if (key === "gender") return;

      if (key === "hero") params[key] = JSON.stringify(params[key]);

      if (
        (key === "profile_image_file" || key === "cover_image_file") &&
        params[key]
      )
        payload.append(key, params[key]);

      if (typeof params[key] === "boolean" || !_.isEmpty(params[key])) {
        payload.append(key, params[key]);
      }
    });

    let mergeSocialWebsite = [...socials, ...websites];
    mergeSocialWebsite = removeDuplicateSocial(mergeSocialWebsite);
    mergeSocialWebsite.forEach((social, index) => {
      if (social.social || social.link) {
        payload.append(`social[${index}][type]`, social.social);
        payload.append(`social[${index}][link]`, social.link);
        payload.append(`social[${index}][media_type]`, social.media_type);
      }
    });

    if (occupation.length > 0) {
      let occupationValues = occupation.map((o) => o.label);
      payload.append("occupations", JSON.stringify(occupationValues));
    } else {
      payload.append("occupations", "");
    }

    // if (otherNames.length > 0) { 
    //   payload.append("other_names", JSON.stringify(otherNames));
    // } else {
    //   payload.append("other_names", "");
    // }

    payload.append("user_id", userData.user.id);
    payload.append("user_profile_id", userData.profile.id);
    payload.append("_method", "PUT");

    if (state.gender === "Other" && !state.other_gender) {
      return setState((prev) => ({
        ...prev,
        loading: false,
        validationErrors: {
          ...errors,
          [`other_gender`]: "Please specify gender",
        },
      }));
    } else if (state.gender === "Other" && state.other_gender) {
      payload.append("gender", state.other_gender);
    } else {
      payload.append("gender", state.gender);
    }

    try {
      const { data, status } = await http.post(
        `profiles/${userData.profile.id}`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (status === 200) {
        toast.success(data.message);
        const newUserData = {
          ...userData,
          user: {
            ...userData.user,
            username: state.username,
            email: state.email,
            first_name: state.first_name,
            last_name: state.last_name
          },
          profile: data.profile,
        };

        localstorage.set("user_data", JSON.stringify(newUserData));
        setState((prev) => ({
          ...prev,
          profile_image_file: null,
          cover_image_file: null,
          validationErrors: null,
        }));
        setUserData(newUserData);
      }
    } catch (error) {
      if (error.status === 422) {
        toast.error("It appears that something went wrong");
        return setState((prev) => ({
          ...prev,
          validationErrors: error.data?.errors,
        }));
      }

      toast.error(
        error.status === 401
          ? "Invalid Credentials"
          : error?.data?.message || "It appears that something went wrong"
      );
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  const removeDuplicateSocial = (socailWebsite) => {
    
    const uniqueData = socailWebsite.filter((item, index, self) =>
      index === self.findIndex((t) => t.link === item.link)
    );
    return uniqueData;
    
  }

  const onUploadImage = async (e, type = "profile") => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      function () {
        if (file.size > maxUploadSize) {
          setUploadErrors({
            ...uploadErrors,
            [type]: "The file is too large. Maximum size is 50MB.",
          });
          return;
        }

        // convert image file to base64 string
        const image = reader.result?.toString() || "";
        let title =
          type === "profile" ? "Crop Profile Picture" : "Crop Cover Photo";
        const Iprops = {
          image: image,
          type: file.type,
          returnType: "file",
          title: title,
        };
        if (image) {
          if (type === "profile") {
            cropProfile(Iprops, (result) => {
              if (result) {
                const { imageFile, preview } = result;
                setState((prev) => ({
                  ...prev,
                  profile_image_file: imageFile,
                }));
                setProfile(preview);
              }
            });
            fileInputRef.current.value = null;
          } else {
            cropCover(Iprops, (result) => {
              if (result) {
                const { imageFile, preview } = result;
                setState((prev) => ({ ...prev, cover_image_file: imageFile }));
                setCover(preview);
              }
            });
          }
        }
      },
      false
    );

    if (file) {
      if (file.size > maxUploadSize) {
        setUploadErrors({
          ...uploadErrors,
          [type]: "The file is too large. Maximum size is 50MB.",
        });
      } else {
        setUploadErrors(null);
        reader.readAsDataURL(file);
      }
    }
  };

  const getPositions = async () => {
    try {
      const url = urlSerializer("public/positions/filter", {
        user_profile_id: userData.profile.id,
      });
      const { data } = await http.get(url);
      const positions = data.map((position) => ({
        label: position.name,
        value: position.id,
      }));
      setPositionFilter(positions);
    } catch (err) {
      console.log("getPosition", err);
    }
  };

  const getCategory = async () => {
    try {
      const url = urlSerializer("public/categories/filter", {
        user_profile_id: userData.profile.id,
      });
      const { data } = await http.get(url);
      const categories = data.map((category) => ({
        label: category.name,
        value: category.id,
      }));
      setCategoryFilter(categories);
    } catch (err) {
      console.log("getCategory", err);
    }
  };

  const getOccupations = async () => {
    try {
      const url = urlSerializer("public/occupations", {
        user_profile_id: userData.profile.id,
      });
      const { data } = await http.get(url);
      const options = data.map((opt) => ({
        label: opt.name,
        value: opt.id,
      }));
      setOccupations(options);
    } catch (err) {
      console.log("getOccupations", err);
    }
  };

  const getSocialMedia = async () => {
    let socialMedia = [];
    let websiteMedia = [];

    try {
      const { data } = await http.get("public/social-medias", {
        user_profile_id: userData?.profile.id,
      });
      let result = data?.social_media ?? [];
      if (result.length > 0) {
        result.forEach((sm) => {
          if (sm.media_type === "social") {
            socialMedia.push({
              social: sm.type,
              link: sm.link,
              fav_icon: sm?.fav_icon?.url || null,
              media_type: "social",
            });

            let options = socialOptions.map((so) => {
              if (so.value === sm.type) {
                return {
                  ...so,
                  selected: true,
                };
              }
              return so;
            });
            setSocialOptions((prev) => options);
          }
          if (sm.media_type === "website") {
            websiteMedia.push({
              social: sm.type,
              link: sm.link,
              fav_icon: sm?.fav_icon?.url || null,
              media_type: "website",
            });
          }
        });

        setSocials(socialMedia);
        setSocialsClone(socialMedia);
        setWebsites(websiteMedia);
      }
    } catch (err) {
      console.log("getSocialMedia", err);
    }
  };

  useEffect(() => {
    if (userData.profile?.profile_image_url)
      setProfile(
        userData.profile.profile_image_webp_url ||
          userData.profile.profile_image_url
      );

    if (userData.profile?.cover_image_url)
      setCover(
        userData.profile.cover_image_webp_url ||
          userData.profile.cover_image_url
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * This effect will run every time when
   * initialize and refresh state change
   */
  useEffect(() => {
    if (initializePositionFilter) getPositions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPoisitionFilter, initializePositionFilter]);

  /**
   * This effect will run every time when
   * initialize and refresh state change
   */
  useEffect(() => {
    if (initializeCategoryFilter) getCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCategoryFilter, initializeCategoryFilter]);

  /**
   * This effect will run every time when
   * initialize and refresh state change
   */
  useEffect(() => {
    if (initializeOccupation) getOccupations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshOccupation, initializeOccupation]);

  /**
   * This effect will run on the
   * initialization of the component
   */
  useEffect(() => {
    const user = userData.user;
    const profile = userData.profile;
    let filter = {};
    let gender = null;
    if (typeof profile.milestone_filter === "string") {
      filter = JSON.parse(profile.milestone_filter);
    } else if (typeof profile.milestone_filter === "object") {
      filter = profile.milestone_filter;
    }

    if (profile && profile.id) {
      setState((prev) => ({
        ...prev,
        username: user.username,
        email: user.email,
        profile_username: profile.username,
        other_name: profile.other_name,
        gender: gender,
        pronoun: profile.pronoun,
        address: profile.address,
        adultContent: profile.show_adult,
        featured: profile.show_featured,
        showNotifications: profile.dateFormat ? true : false,
        metadata: profile.metadata ? profile.metadata : {},
        profileTypes: profile.profile_type ? profile.profile_type.type : null,
        profileStyle: profile.profile_type ? profile.profile_type.style : null,
        profileGroup: profile.profile_type ? profile.profile_type.group : [],
        job_role: filter?.job_role || null,
        category: filter?.category || null,
        sort: filter?.sort || null,
        hero: profile?.hero ? profile?.hero : {},
        heroSocialMedia: profile.hero ? profile.hero.socialMedia : null,
        heroCTA: profile.hero ? profile.hero.heroCTA : null,
        dateFormat: profile.dateFormat ? true : false,
        first_name: user.first_name,
        last_name: user.last_name,
        displayName: profile?.displayName,
        profile_name: profile?.profile_name
      }));

      if (profile.gender === "Male" || profile.gender === "Female") {
        setState((prev) => ({
          ...prev,
          gender: profile.gender,
        }));
      } else if (profile.gender || profile.gender === "Other") {
        setState((prev) => ({
          ...prev,
          gender: "Other",
          other_gender: profile.gender,
        }));
      }
      setMembers(
        profile.profile_type?.members
          ? JSON.parse(profile.profile_type.members)
          : []
      );
      if(profile?.other_names){ 
        console.log(profile?.other_names);
        setOtherNames(profile?.other_names || []);
        let newArray = [...sets_display_name, ...profile?.other_names];
        setDisplayName(newArray); 
      }
      getSocialMedia();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.profile, userData?.username]); 

  useEffect(() => {
    const profile = userData.profile;

    if (!occupation.length) {
      if (profile.occupations) {
        let values = occupations.filter(
          (o) => profile.occupations.indexOf(o.label) !== -1
        );
        setOccupation(values);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [occupations, userData?.profile]);

  const showMembers = useMemo(() => {
    let isGroup = state?.profileTypes === "group";
    let isTeam = state?.profileTypes === "team";
    if (isGroup || isTeam) {
      return true;
    } else {
      return false;
    }
  }, [state.profileTypes]);

  const showGender = useMemo(() => {
    let hasGender = !gender.filter((g) => g.value === state.gender).length;
    if (state.gender) {
      if (state.gender === "Other" || hasGender) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }, [state.gender]);

  return (
    <div className="mt-6 w-full container-max-width bg-white rounded-md">
      {/* content */}
      <div className="pt-12.5 pb-10 px-7.5 1md:pl-24.5 1md:pr-56">
        <div className="w-full 3md:flex -mb-6">
          <div className="w-full mb-5 3md:w-6/20 3md:mb-0">
            <div className="w-full flex flex-row gap-x-2 items-center">
              <p className="text-lg text-lightBlack font-semibold">
                Personal Information
              </p>
              <PopInfo
                containerClass="relative"
                type="personal_information"
                responsive={{
                  left: "200px",
                  right: "200px",
                }}
              />
            </div>
          </div>
          {/* forms */}
          <div className="w-full 3md:w-14/20">
            <ImageUpload
              ref={fileInputRef}
              label="Profile Picture"
              image={profile}
              accept="image/*"
              icon={<FiCamera className="text-white text-lg" />}
              id="profile-photo"
              onChange={(e) => onUploadImage(e, "profile")}
              error={uploadErrors ? uploadErrors["profile"] : null}
            />
            <ImageUpload
              label="Cover Photo"
              accept="image/*"
              image={cover}
              icon={<FiCamera className="text-white text-lg mr-2" />}
              className="absolute px-4 text-xs text-white font-semibold right-2 top-2 h-9 bg-black bg-opacity-50 rounded-md flex items-center justify-center"
              innerLabel={cover ? "Change Photo" : "Upload Photo"}
              wrapperClass="relative w-full 4sm:w-538 h-38 bg-thBg rounded-md"
              id="cover-photo"
              onChange={(e) => onUploadImage(e, "cover")}
              error={uploadErrors ? uploadErrors["cover"] : null}
            />
            <div className="flex gap-4">
            <Input
              inputContainerClass="form-input-container"
              value={state?.first_name || ""}
              name="first_name"
              outsideLabel="First Name"
              placeholder="first name"
              outsideLabelClass="text-sm font-bold text-darkerGray marked"
              noLabelPaddingY="1rem"
              type="text"
              onChange={handleInputChange}
              errorMessage={state.validationErrors?.first_name}
              errorMessagePosition="bottom"
            />
            <Input
              inputContainerClass="form-input-container"
              value={state?.last_name || ""}
              name="last_name"
              outsideLabel="Last Name"
              placeholder="last name"
              outsideLabelClass="text-sm font-bold text-darkerGray marked"
              noLabelPaddingY="1rem"
              type="text"
              onChange={handleInputChange}
              errorMessage={state.validationErrors?.last_name}
              errorMessagePosition="bottom"
            />
            </div>
            <Input
              inputContainerClass="form-input-container"
              value={state?.profile_username || ""}
              name="profile_username"
              outsideLabel="Username"
              placeholder="username"
              outsideLabelClass="text-sm font-bold text-darkerGray marked"
              noLabelPaddingY="1rem"
              type="text"
              onChange={handleInputChange}
              errorMessage={state.validationErrors?.profile_username}
              errorMessagePosition="bottom"
            />

            <Input
              inputContainerClass="form-input-container"
              value={state?.profile_name || ""}
              name="profile_name"
              outsideLabel="Profile name"
              placeholder="Profile Name"
              outsideLabelClass="text-sm font-bold text-darkerGray marked"
              noLabelPaddingY="1rem"
              type="text"
              onChange={handleInputChange}
              errorMessage={state.validationErrors?.profile_name}
              errorMessagePosition="bottom"
            />
            <Input
              inputContainerClass="form-input-container"
              value={state?.other_name || ""}
              name="other_name"
              outsideLabel="Other Names"
              optional={true}
              placeholder="e.g Dame Edna Everage"
              outsideLabelClass="text-sm font-bold text-darkerGray"
              noLabelPaddingY="1rem"
              type="text"
              onChange={handleInputChange}
            />
            {/* <div className="w-full">
              <div className="text-sm font-bold text-darkerGray mb-2">Other Name <span className="font-normal">{" (optional)"}</span></div>
                <DragDropSelect
                  items={otherNames}
                  errors={state.validationErrors}
                  onAdd={onAddOtherName}
                  onChange={(e, index) => onChangeOtherName(e, index)}  
                  onRemove={(index) => onRemoveOtherName(index)}
                  setItems={setOtherNames}
                />
              </div> */}
            {/* <div className="flex gap-2 my-5">
              <Select
                  label="Display Name"
                  options={displayName}
                  placeholder="Select Name"
                  name="displayName"
                  className="h-[3rem]"
                  value={
                    state.displayName &&
                    displayName.filter((g) => g.value === state.displayName)
                  }
                  onChange={(e) =>
                    handleInputChange({
                      target: { name: "displayName", value: e.value },
                    }, true)
                  }
                  errorMessage={state.validationErrors?.gender}
                />
                {
                  state.displayName === 'Other Name' &&
                  <Select
                  label="Other Name"
                  options={otherNames}
                  placeholder="Select Name"
                  name="other_name"
                  className="h-[3rem]"
                  value={
                    state.other_name &&
                    otherNames.filter((g) => g.value === state.other_name)
                  }
                  onChange={(e) =>
                    handleInputChange({
                      target: { name: "other_name", value: e.value },
                    })
                  }
                  errorMessage={state.validationErrors?.gender}
                />
                }
            </div> */}
            <div className="w-full grid 3md:grid-cols-2 gap-4">
              <div>
                <Select
                  label="Gender"
                  options={gender}
                  placeholder="Select Gender"
                  name="gender"
                  className="h-[3rem]"
                  value={
                    state.gender &&
                    gender.filter((g) => g.value === state.gender)
                  }
                  onChange={(e) =>
                    handleInputChange({
                      target: { name: "gender", value: e.value },
                    })
                  }
                  errorMessage={state.validationErrors?.gender}
                />
                {showGender && (
                  <Input
                    inputContainerClass="form-input-container"
                    outsideLabelClass="text-sm font-bold text-darkerGray mr-4"
                    value={state.other_gender || ""}
                    outsideLabel="Specify"
                    noLabelPaddingY="1rem"
                    type="text"
                    errorMessage={state.validationErrors?.other_gender}
                    onChange={(e) =>
                      handleInputChange({
                        target: {
                          name: "other_gender",
                          value: e.target.value,
                        },
                      })
                    }
                  />
                )}
              </div>
              <div>
                <Input
                  inputContainerClass="form-input-container"
                  value={state.pronoun || ""}
                  name="pronoun"
                  outsideLabel="Pronouns (Optional)"
                  placeholder="Pronouns"
                  outsideLabelClass="text-sm font-bold text-darkerGray"
                  noLabelPaddingY="0.8rem"
                  type="text"
                  onChange={handleInputChange}
                  errorMessagePosition="bottom"
                />
              </div>
            </div>
            {/* <Input
              inputContainerClass="form-input-container"
              value={state?.address || ""}
              name="address"
              outsideLabel="Address"
              placeholder="Melbourne, Australia"
              outsideLabelClass="text-sm font-bold text-darkerGray"
              noLabelPaddingY="1rem"
              type="text"
              onChange={handleInputChange}
              errorMessage={state.validationErrors?.address}
              errorMessagePosition="bottom" 
            /> */}

            <div className="form-input-container">
              <label for="address" className="text-sm font-bold text-darkerGray leading-4">Location (eg city, state, country)</label>
              <div className="input-wrapper">
                <input ref={ref}  
                  className="input-class w-full" type="text" name="address" placeholder="Melbourne VIC, Australia" 
                  value={state?.address || ""}
                  onChange={handleInputChange}
                  style={{
                    padding:  "1rem",
                  }}
                  />
                  {
                    state.validationErrors?.address && 
                    <p className="input-error-message">* {state.validationErrors?.address}</p>
                  }
                </div>
              </div>

              
             
            <CreatableSelect
              className="capitalize"
              label="Occupations / Skills"
              options={occupations}
              placeholder="Select occupations"
              name="occupations"
              isMulti
              value={occupation}
              onChange={(e) => onChangeOccupation(e)}
              onCreateOption={createOccupation}
            />
          </div>
        </div>
        {/* social media */}
        <div className="w-full mt-15 3md:flex -mb-6">
          <div className="w-full mb-5 3md:w-6/20 3md:mb-0">
            <div className="w-full flex flex-row gap-x-2 items-center">
              <p className="text-lg text-lightBlack font-semibold">
                <div>Social Media </div>
                <div>or Profile Page</div>
              </p>
              <PopInfo containerClass="relative" type="social_media" />
            </div>
            <p className="text-[12px] text-slate-500">
              e.g. Facebook, Instagram, IMDb
            </p>
          </div>
          {/* forms */}
          <div className="w-full 3md:w-14/20">
            <SocialSelect
              setItems={setSocials}
              options={socialOptions}
              items={socials}
              errors={state.validationErrors}
              onAdd={onAddSocials}
              onChange={(e, index) => onTypeLinkSocial(e, index, "social")}
              onSelect={(e, index) => onSelectSocial(e, index)}
              onBlur={(e, index) => checkUrl(e, index, "social")}
              onRemove={(index) => onRemoveSocials(index, "social")}
            />
          </div>
        </div>
        {/* website media */}
        <div className="w-full mt-15 3md:flex -mb-6">
          <div className="w-full mb-5 3md:w-6/20 3md:mb-0">
            <div className="w-full flex flex-row gap-x-2 items-center">
              <p className="text-lg text-lightBlack font-semibold">Websites</p>
              <PopInfo
                containerClass="relative"
                type="website"
                responsive={{
                  left: "125px",
                  right: "125px",
                }}
              />
            </div>
          </div>
          {/* forms */}
          <div className="w-full 3md:w-14/20">
            <WebsiteSelect
              items={websites}
              errors={state.validationErrors}
              onAdd={onAddSocials}
              onChange={(e, index) => onTypeLinkSocial(e, index, "website")}
              onSelect={(e, index) => onSelectSocial(e, index)}
              onBlur={(e, index) => checkUrl(e, index, "website")}
              onRemove={(index) => onRemoveSocials(index, "website")}
              setItems={setWebsites}
            />
          </div>
        </div>
        {/* hero button */}
        <div className="w-full mt-15 3md:flex -mb-6">
          <div className="w-full mb-5 3md:w-6/20 3md:mb-0">
            <div className="w-full flex flex-row gap-x-2 items-center">
              <p className="text-lg text-lightBlack font-semibold">
                Hero Button
              </p>
              <PopInfo containerClass="relative" type="hero_button" />
            </div>
            <p className="text-[12px] text-slate-500">
              {/* Link to where you can take payments */}
              {`Link to where you can take payments (e.g. Patreon, GoFundMe, Fiverr, UpWork, etc)`}
            </p>
          </div>
          {/* forms */}
          <div className="w-full 3md:w-14/20">
            <div className="w-full 7sm:grid 4sm:grid-cols-3 gap-x-4 gap-y-2 items-center">
              <Select
                options={heroCTAs}
                placeholder="Select"
                name="heroCTA"
                value={
                  state.heroCTA &&
                  heroCTAs.filter((hs) => hs.value === state.heroCTA)
                }
                onChange={(e) =>
                  handleInputChange({
                    target: { name: "heroCTA", value: e.value },
                  })
                }
              />
              <Select
                options={heroTypes}
                placeholder="Select"
                name="heroSocialMedia"
                value={
                  state.heroSocialMedia &&
                  heroTypes.filter((hs) => hs.value === state.heroSocialMedia)
                }
                onChange={(e) =>
                  handleInputChange({
                    target: { name: "heroSocialMedia", value: e.value },
                  })
                }
              />
              <Input
                inputContainerClass="form-input-container"
                value={state?.hero?.link}
                name="link"
                placeholder="https://www.link.com/"
                outsideLabelClass="text-sm font-bold text-darkerGray"
                noLabelPaddingY="13px"
                type="text"
                onChange={(e) => handleInputObjectChange(e, "hero")}
                errorMessage={state.validationErrors?.hero?.link}
              />
            </div>
            <div className="w-full flex items-center">
              {state?.hero?.link && (
                  <div className="w-full 7sm:w-[350px] flex justify-start">
                    <ToolTip
                      value={`${state.heroCTA || "Support me on"} ${
                        state.heroSocialMedia
                      }`}
                      backgroundColor="#0c6ed1"
                      color="white">
                      <button
                        className="text-xs font-semibold text-lightBlack hidden 3md:flex items-center
                                  py-2 mb-5 3md:mb-0 3md:py-1 px-2 bg-thBg hover:bg-gray gap-1 rounded-md group-hover:opacity-100 w-[10rem] justify-between h-12"
                        onClick={() => window.open(state?.hero?.link, "_blank")}>
                        <p className="mt-0.5 w-full text-center">
                          {state.heroCTA || "Support me on"}
                        </p>
                        {heroIcon(state.heroSocialMedia,state?.hero?.link)}
                      </button>
                    </ToolTip>
                  </div>
                )}
                <div className="w-full">
                  <div className="flex space-x-6 items-center">
                  </div>
                </div>
            </div>
          </div>
        </div>
        {/* Profile types */}
        <div className="w-full mt-15 3md:flex">
          <div className="w-full mb-5 3md:w-6/20 3md:mb-0">
            <div className="w-full flex flex-row gap-x-2 items-center">
              <p className="text-lg text-lightBlack font-semibold">
                Profile Types
              </p>
              <PopInfo containerClass="relative" type="profile_types" />
            </div>
          </div>
          {/* forms */}
          <div className="w-full 3md:w-14/20">
            <div className="w-full grid 4sm:grid-cols-2 gap-x-4 items-center">
              <Select
                label="Type"
                options={profileTypes}
                placeholder="Select Type"
                name="type"
                value={
                  state.profileTypes &&
                  profileTypes.filter((pt) => pt.value === state.profileTypes)
                }
                onChange={(e) =>
                  handleInputChange({
                    target: { name: "profileTypes", value: e.value },
                  })
                }
              />
            </div>
            {
                showMembers && 
                <div className="text-sm text-darkerGray mb-8">
                  <p className="inline-block">
                  <span className="font-bold text-[#ffa24c] gap-2 items-center inline-block pr-1"><FiAlertCircle className="inline-block"/> Warning:</span>
                  Changing the your profile type will <span className="font-bold text-darkerGray">remove your verified badge</span>.
                  This means that you have to verify your account once you update your type again.
                  </p> 
                </div>
              }
          </div>
        </div>
        {/* Profile types Group/Team */}
        {showMembers && (
          <div className="w-full mt-2 3md:flex">
            <div className="w-full mb-5 3md:w-6/20 3md:mb-0">
              <div className="w-full flex flex-row gap-x-2 items-center">
                <p className="text-lg text-lightBlack font-semibold">Members</p>
                <PopInfo containerClass="relative" type="members" />
              </div>
            </div>
            {/* forms */}
            <div className="w-full 3md:w-14/20">
              <Members
                items={members}
                setItems={setMembers}
                errors={state.validationErrors}
                onAdd={onAddMembers}
                onChange={(e, index) => onChangeMembers(e, index)}
                onRemove={(index) => onRemoveMembers(index)}
              />
            </div>
          </div>
        )}
        {/* Notification */}
        <div className="w-full mt-6 3md:flex">
          <div className="w-full  mb-5 3md:w-6/20 3md:mb-0">
            <div className="w-full flex flex-row gap-x-2 items-center">
              <p className="text-lg text-lightBlack font-semibold">
                Notification
              </p>
              <PopInfo containerClass="relative" type="notification" />
            </div>
          </div>
          {/* forms */}
          <div className="w-full 3md:w-14/20">
            <div className="flex space-x-6">
              <div>
                <Toggle
                  checked={state.showNotifications}
                  name="messaging"
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                      showNotifications: e?.target?.checked,
                    }))
                  }
                />
              </div>
              <div>
                <p className="text-lg font-medium text-lightBlack">Messaging</p>
                <p className="text-md text-lightBlack">
                  Notify me when someone sends me a message
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Filter Milestone Option */}
        <div className="w-full mt-15 3md:flex">
          <div className="w-full mb-5 3md:w-6/20 3md:mb-0">
            <div className="w-full flex flex-row gap-x-2 items-center">
              <p className="text-lg text-lightBlack font-semibold">
                Filter Milestone Option <br />
              </p>
              <PopInfo
                containerClass="relative"
                type="milestone_option"
                responsive={{
                  left: "220px",
                  right: "220px",
                }}
              />
            </div>
            <p className="font-normal text-md">
              Default filter on your milestone page
            </p>
          </div>
          {/* forms */}
          <div className="w-full 3md:w-14/20 -mb-6">
            <div className="w-full grid 3md:grid-cols-2 gap-4">
              <Select
                formContainer="flex-1"
                options={positionOptions}
                height={70}
                placeholder="Select"
                name="job_role"
                innerLabel="Role/Job Title"
                value={positionOptions.filter(
                  (p) => p.label === state.job_role
                )}
                onChange={(e) =>
                  handleInputChange({
                    target: { name: "job_role", value: e.label },
                  })
                }
              />
              <Select
                formContainer="flex-1"
                options={categoryOptions}
                height={70}
                placeholder="Select"
                name="category"
                innerLabel="Category"
                value={categoryOptions.filter(
                  (c) => c.label === state.category
                )}
                onChange={(e) =>
                  handleInputChange({
                    target: { name: "category", value: e.label },
                  })
                }
              />
              <Select
                formContainer="flex-1"
                options={ORGANIZE_BY}
                height={70}
                placeholder="Select"
                name="sort"
                innerLabel="Sort"
                value={ORGANIZE_BY.filter(
                  (option) => option.label === state.sort
                )}
                onChange={(e) =>
                  handleInputChange({
                    target: { name: "sort", value: e.label },
                  })
                }
              />
            </div>
            {/* <div className="w-full mt-10 flex space-x-6">
              <div>
                <Toggle
                  checked={state.featured}
                  name="messaging"
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                      featured: e?.target?.checked,
                    }))
                  }
                />
              </div>
              <div>
                <p className="text-lg font-medium text-lightBlack">
                  Pinned Milestone Post
                </p>
                <p className="text-md text-lightBlack">
                  Show Pinned by default
                </p>
              </div>
            </div> */}
          </div>
        </div>
        {/* Content rating */}
        <div className="w-full mt-15 3md:flex">
          <div className="w-full  mb-5 3md:w-6/20 3md:mb-0">
            <div className="w-full flex flex-row gap-x-2 items-center">
              <p className="text-lg text-lightBlack font-semibold">
                Content Rating
              </p>
              <PopInfo
                containerClass="relative"
                type="content_rating"
                responsive={{
                  left: "140px",
                  right: "140px",
                }}
              />
            </div>
          </div>
          {/* forms */}
          <div className="w-full 3md:w-14/20">
            <div className="flex space-x-6">
              <div>
                <Toggle
                  checked={state.adultContent}
                  name="adultContent"
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                      adultContent: e?.target?.checked,
                    }))
                  }
                />
              </div>
              <div>
                <p className="text-lg font-medium text-lightBlack">
                  Adult Content
                </p>
                <p className="text-md text-lightBlack">
                  Show adult content to me
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Date format */}
        <div className="w-full mt-15 3md:flex">
          <div className="w-full  mb-5 3md:w-6/20 3md:mb-0">
            <div className="w-full flex flex-row gap-x-2 items-center">
              <p className="text-lg text-lightBlack font-semibold">
                Date Format
              </p>
              <PopInfo containerClass="relative" type="date_format" />
            </div>
          </div>
          {/* forms */}
          <div className="w-full 3md:w-14/20">
            <div className="flex space-x-6">
              <div>
                <Toggle
                  checked={state.dateFormat}
                  name="dateFormat"
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                      dateFormat: e?.target?.checked,
                    }))
                  }
                />
              </div>
              <div>
                <p className="text-lg font-medium text-lightBlack">
                  {!state.dateFormat ? "USA" : "International"}
                </p>
                <p className="text-md text-lightBlack">
                  {!state.dateFormat
                    ? "Month Day Year Format"
                    : "Day Month Year Format"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* footer */}
      <div className="py-5.5 px-7.5 md:pl-24.5 md:pr-56 border-t-2 border-gray">
        <div className="w-full flex justify-end">
          <Button
            disabled={state.loading}
            buttonName="Cancel"
            buttonTextClass="text-xs text-lightBlack font-semibold"
            buttonRadius="6px"
            buttonClass="relative py-4 px-7.5 bg-thBg mr-3.5 hover:bg-gray"
            onClick={() => history.goBack()}
          />
          <Button
            disabled={state.loading}
            buttonName="Save Changes"
            buttonTextClass="text-xs text-white font-semibold flex flex-row items-center gap-2"
            buttonRadius="6px"
            icon={
              state.loading ? (
                <LoadingIndicator className="text-white w-4 h-4" />
              ) : null
            }
            buttonClass="relative py-4 px-10 bg-primary hover:bg-darkPrimary"
            onClick={handleSave}
          />
        </div>
      </div>
    </div>
  );
}
