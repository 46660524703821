const NotificationDotIconSVG = ({ className = "", fill = "#0C6ED1" }) => {
  return (
    <svg
      className={className}
      width="6"
      height="26"
      viewBox="0 0 6 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="3" cy="13" r="3" fill={fill} />
    </svg>
  );
};

export default NotificationDotIconSVG;
