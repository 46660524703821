// SkeletonLoader.js
import React from "react";
import "./skeletonloading.css";

const skeletonloading = ({ classStyle }) => {
  return (
    <div className="skeleton-loader">
      <div className={classStyle} />
    </div>
  );
};

export default skeletonloading;
