import React, {useState,useRef} from "react";
import { IoEllipsisHorizontal } from "react-icons/io5";
import { BsExclamationDiamondFill,BsPersonFillCheck } from "react-icons/bs";
import { listFormat, capitalizeWords, randomExtension } from "utils/utils";
import { PiWarningOctagonLight } from "react-icons/pi";
import { urlSerializer } from "utils/utils";
import Button from "components/button/button";
import SendMessageModal from "components/message/send-message";
import { useDialogHook } from "utils/customhooks";
import ReportMilestone from "components/profile/milestones/more-option/report";
import SocialsModal from "components/profile/share/socials";
import _ from "lodash";import {
  BsChatText,
  BsShare,
  BsPersonPlus,
} from "react-icons/bs";
import Popup from "reactjs-popup"; 
import LoadingIndicator from "assets/svg/loading-indicator";

export default function SearchItem({
  image,
  data = {},
  onProfileClick = () => {},
}) { 

  const fullName = data.user
    ? `${data.user.first_name} ${data.user.last_name}`
    : null;
  const occupations = !_.isEmpty(data.occupations)
    ? listFormat(data.occupations.map(capitalizeWords))
    : null;
  // const isAdultContent = data.show_adult ? true : true;
  const isAdultContent = data?.milestones_count > 0 ? true : false;
  const extension = randomExtension();
  const [showMoreOption, setShowMoreOption] = useState();
  const optionRef = useRef();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [follow, setFollow] = useState(data?.followers ? (data?.followers[0]?.status ? data?.followers[0]?.status : 0) : 0);
  const { http } = global.services;
  const userData = JSON.parse(localStorage.getItem('user_data'));
  const sendModal = useDialogHook(SendMessageModal); 
  const modalReport = useDialogHook(ReportMilestone);
  const shareModal = useDialogHook(SocialsModal);
  const profile = data;

  const onFollow = async () => {
    setSubmitLoading(true);
    const url = urlSerializer("/follow", {
      followed_id: profile?.id,
      follower_id: userData?.profile?.id,
    });
    const { data } = await http.post(url);
    const { follow } = data;
    setFollow(follow?.status);
    setSubmitLoading(false);
  };

  const messageThisUser = ()=>{
    sendModal(
      {
        userData,
        profile,
      },
      (result) => {
        console.log("result =>", result);
      }
    );
  }

  const reportMilestone = (milestone = {}, accountReport = profile, type="profile") => {
    modalReport({ userData, milestone, accountReport, type }, (callback) => {
      console.log(callback);
      if (callback && callback.success) {
        if (type === "profile") {
        }
        console.log(profile);
      }
    });
  };

  const showMoreSocial = () => {
    let profileUrl = `${process.env.REACT_APP_PUBLIC_DOMAIN}/${profile.user.username}/profile/${profile.id}`;
    console.log(profileUrl);
    console.log(profile);
    shareModal(
      {
        url: profileUrl,
        image: profile.profile_image_url,
      },
      (callBack) => {
        console.log("showMoreSocial callback", callBack);
      }
    );
  };


  const [imageSize, setImageSize] = useState({ width: 'auto', height: 'auto' });


  const handleImageLoad = (event) => {
    const { naturalWidth, naturalHeight } = event.target;
    
    setImageSize({
      width: naturalWidth > 60 ? '60px' : `${naturalWidth}px`,  
      height: naturalHeight > 60 ? '60px' : `${naturalHeight}px`, 
    });
  };


  return (
    <div className="w-full bg-white rounded-md p-5 4sm:p-10 mb-5">
      <div className="w-full flex items-center justify-between">
        {/* left */}
        <div className="flex space-x-5 items-center">
          {/* image */}
          <div className="w-15 h-15 rounded-md">
            <img
              src={image}
              alt="milestone-avatar"
              className="w-full h-full rounded-md"
              style={{ width: imageSize.width, height: imageSize.height }}
              onLoad={handleImageLoad}  
            />
          </div>
          {/* details */}
          <div className="">
            <div className="flex flex-col md:flex-row md:space-x-2">
              <button
                className={`flex items-center ${!data.is_login && data?.milestones_count > 0 ? 'cursor-no-drop' : 'cursor-pointer'}`}
                onClick={() => {
                  if(!data.is_login && data?.milestones_count > 0){ 
                    return;
                  } 
                  onProfileClick(`${data.user.username}-${extension}/profile/${data.id}`)
                }}
              >
                <p className="text-base 4sm:text-lg font-bold leading-5.5 tracking-tighter">
                  {fullName}
                </p>
              </button>
              {isAdultContent && (
                <div className="flex flex-row">
                  <BsExclamationDiamondFill className="text-red text-xl mr-2" />
                  <p className="text-red text-base 4sm:text-lg leading-5.5 tracking-tighter">
                    Adult Content
                  </p>
                </div>
              )}
            </div>
            <p className="text-xs text-darkerGray leading-6">{data.address}</p>
            <p className="text-base leading-5 hidden 4sm:block">
              {_.toString(occupations)}
            </p>
          </div>
        </div>
        {/* right */}
        <Popup
              ref={optionRef}
              position="bottom right"
              open={showMoreOption}
              onClose={() => setShowMoreOption(false)}
              arrow={false}
              contentStyle={{ 
                marginTop: 10, 
                marginLeft: 5, 
                zIndex: 9999 
              }}
              trigger={
                <button
                  className="bg-thBg w-5 h-5 2sm:w-10 2sm:h-10 rounded-md flex justify-center items-center"
                  onClick={() => setShowMoreOption((open) => !open)}
                  >  
                  <IoEllipsisHorizontal className="text-base 4sm:text-2xl text-darkerGray" />
                </button>
              }>
             <div className="flex flex-col shadow-lg rounded-md bg-slate-50">
             <ul className="">  
                <li className="hover:bg-gray">
                  {/* <button onClick={()=>{
                    optionRef.current.close();
                    setShowMoreOption(false);  
                  }} 
                      className={`flex flex-row items-center text-md px-6 py-2`}>
                        <BsPersonPlus className="mr-3" />
                        <div >Follow this User</div>
                    </button> */}
                    {
                      data?.followers &&
                      <Button
                        buttonName={`${follow === 0 ? "Follow" : "Following"}`}
                        buttonTextClass={`text-xs 3md:text-sm flex items-center`}
                        iconPosition="left"
                        icon={
                          follow === 1 ? (
                            <BsPersonFillCheck className="mr-1.5 3md:mr-3 text-lg" />
                          ) : (
                            <BsPersonPlus className="mr-1.5 3md:mr-3 text-lg" />
                          )
                        }
                        buttonRadius="6px"
                        buttonClass={`flex flex-row items-center text-md px-6 py-2`} 
                        loadingIcon={
                          submitLoading ? (
                            <LoadingIndicator className="text-blue-500 w-4 h-4 ml-2" />
                          ) : null
                        }
                        onClick={onFollow}
                      />
                    }
                </li> 
                {
                  data?.is_login &&
                  <li className="hover:bg-gray">
                    <button onClick={()=>{
                      messageThisUser()
                      optionRef.current.close();
                      setShowMoreOption(false);  
                    }} 
                        className={`flex flex-row items-center text-md px-6 py-2`}>
                          <BsChatText className="mr-3" />
                          <div >Message</div>
                      </button>
                  </li> 
                }
                <li className="hover:bg-gray">
                  <button onClick={()=>{
                    optionRef.current.close();
                    setShowMoreOption(false); 
                    showMoreSocial() 
                  }} 
                      className={`flex flex-row items-center text-md px-6 py-2`}>
                        <BsShare className="mr-3" />
                        <div >Share</div>
                    </button>
                </li> 
                {
                  data?.is_login &&
                  <li className="hover:bg-gray">
                    <button onClick={()=>{
                      optionRef.current.close();
                      setShowMoreOption(false);  
                      reportMilestone()
                    }} 
                        className={`flex flex-row items-center text-md px-6 py-2`}>
                          <PiWarningOctagonLight className="mr-3" />
                          <div >Report</div>
                      </button>
                  </li> 
                }
            </ul>
             </div>
        </Popup>
      </div>
    </div>
  );
}
