import moment from "moment";
import _ from "lodash";
import {
  REFRESH_MILESTONES,
  GET_MILESTONES,
  SET_FEATURED_MILESTONE,
  SET_CURRENT_MILESTONES,
  SET_PAST_MILESTONES,
  SET_IS_FEATURED,
  SET_ORGANIZE_BY,
  SET_JOB_ROLE,
  SET_MILESTONES,
  SET_CATEGORY,
  SET_FILTERS,
  CLEAR_FILTERS,
  ADD_MILESTONES,
  UPDATE_MILESTONES,
  UPDATE_CURRENT_MILESTONE,
  UPDATE_PAST_MILESTONE,
  UPDATE_FEATURED_MILESTONE,
  DELETE_MILESTONE,
  FINISH_GET_MILESTONES,
  SET_PINNED_MILESTONES,
  SET_PLAY_MUSIC,
  UPDATE_PINNED_MILESTONES
} from "./types";

const initialState = {
  refresh: false,
  loadMore: true,
  initialize: false,
  featured: false,
  organizeBy: null,
  jobRole: [],
  category: null,
  limit: 30,
  total: 0,
  isRequesting: false,
  featuredMilestone: {},
  currentMilestones: [],
  pastMilestones: [],
  pinnedMilestones:[],
  milestones: [],
  playMusic: null,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REFRESH_MILESTONES:
      return {
        ...state,
        refresh: false,
        loadMore: true,
        initialize: true,
        isRequesting: false,
        total: 0,
        featuredMilestone: {},
        currentMilestones: [],
        pastMilestones: [],
        milestones: [],
        pinnedMilestones:[],
      };

    case GET_MILESTONES:
      return {
        ...state,
        isRequesting: true,
      };

    case FINISH_GET_MILESTONES:
      return {
        ...state,
        isRequesting: false,
        loadMore: payload.loadMore,
      };

    case SET_FEATURED_MILESTONE:
      return {
        ...state,
        featuredMilestone: payload,
      };

    case SET_CURRENT_MILESTONES:
      let newCurrentMilestones = state.currentMilestones;

      payload.data.forEach((m) => {
        const milestone = state.currentMilestones.filter(
          (cm) => cm.position_id === m.position_id && cm.id === m.id
        );
        if (!milestone.length) {
          newCurrentMilestones.push(m);
        }
      });

      return {
        ...state,
        refresh: false,
        initialize: false,
        total: payload.total,
        currentMilestones: newCurrentMilestones,
      };

    case SET_PAST_MILESTONES:
      const newPastMilestones = state.pastMilestones;

      payload.data.forEach((m) => {
        const milestone = state.pastMilestones.filter(
          (pm) => pm.position_id === m.position_id && pm.id === m.id
        );
        if (!milestone.length) {
          newPastMilestones.push(m);
        }
      });

      return {
        ...state,
        refresh: false,
        initialize: false,
        total: payload.total,
        pastMilestones: newPastMilestones,
      };

    case SET_PINNED_MILESTONES:
      const newPinnedMilestones = state.pinnedMilestones;

      payload.data.forEach((m) => {
        const milestone = state.pinnedMilestones.filter(
          (pm) => pm.position_id === m.position_id && pm.id === m.id
        );
        if (!milestone.length) {
          newPinnedMilestones.push(m);
        }
      });

      return {
        ...state,
        refresh: false,
        initialize: false,
        total: payload.total,
        pinnedMilestones: newPinnedMilestones,
      };

    case UPDATE_PINNED_MILESTONES:
      let currentPinnedMilestoneList = state.pinnedMilestones;
      let current_milestones = state.currentMilestones;
      let past_milestone_pin = state.pastMilestones;
      currentPinnedMilestoneList.forEach((cpm, index) => {

        const payloadArray = Array.isArray(payload.data) ? payload.data : [payload.data];
        payloadArray.forEach(item => {
          if (cpm.id === item.id) {
            currentPinnedMilestoneList[index] = {
              ...cpm,
              ...item,
            };
          }
        });
       
      });
      currentPinnedMilestoneList = currentPinnedMilestoneList.filter(pinned => pinned.is_featured === 1); //removed if unpinned the milestone in edit
      if(payload.data[0].is_featured === 0){ // I assumed that this is only 1 array since the return from response is object and converted to array.
        let currentYear = new Date().getFullYear();
        if(payload.data[0].is_present === 1 || payload.data[0].end_year === currentYear){
            current_milestones = [...current_milestones, ...payload.data];
        }else{
            past_milestone_pin = [...past_milestone_pin, ...payload.data];
        }
      }
      return {
        ...state,
        pinnedMilestones: currentPinnedMilestoneList,
        currentMilestones: current_milestones,
        pastMilestones: past_milestone_pin
      };

    case SET_MILESTONES:
      const keys = Object.keys(payload.data);
      let newMilestone = state.milestones;

      keys.forEach((k) => {
        if (newMilestone.hasOwnProperty(k)) {
          const newList = newMilestone[k];
          payload.data[k].forEach((m) => {
            const milestone = state.milestones[k].filter(
              (om) => om.id === m.id && om.position_id === m.position_id
            );
            if (!milestone.length) {
              newList.push(m);
            }
          });
          newMilestone = { ...newMilestone, [k]: newList };
        } else {
          newMilestone = { ...newMilestone, [k]: payload.data[k] };
        }
      });

      return {
        ...state,
        refresh: false,
        initialize: false,
        total: payload.total,
        milestones: newMilestone,
      };

    case SET_IS_FEATURED:
      return {
        ...state,
        refresh: true,
        featured: payload,
      };

    case SET_ORGANIZE_BY:
      return {
        ...state,
        refresh: true,
        organizeBy: payload,
      };

    case SET_JOB_ROLE:
      return {
        ...state,
        refresh: true,
        jobRole: payload,
      };

    case SET_CATEGORY:
      return {
        ...state,
        refresh: true,
        category: payload,
      };

    case SET_FILTERS:
      return {
        ...state,
        featured: payload.featured,
        organizeBy: payload.organizeBy,
        jobRole: payload.jobRole,
        category: payload.category,
      };

    case CLEAR_FILTERS:
      return {
        ...state,
        refresh: true,
        initialize: false,
        organizeBy: null,
        jobRole: [],
        category: null,
        featured: false,
      };

    case ADD_MILESTONES:
      let current = [];
      let past = [];
      let currentMilestones = state.currentMilestones;
      let pastMilestones = state.pastMilestones;
      let milestones = state.milestones;
      let currentMilestonesKeys = Object.keys(state.milestones);

      if (!state.organizeBy || state.organizeBy === "Date" || state.organizeBy === "Date - Newest to oldest") {
        let isAdd = false;

        if (!_.isEmpty(state.jobRole) || state.category) {
          // let positions = payload.data.positions.filter((p) => {
          //   if (
          //     (state.jobRole && state.jobRole === p.position.name) ||
          //     (state.category && state.category === p.position.name)
          //   ) {
          //     return true;
          //   }
          // });

          let positions = _.filter(payload.data.positions, (p) => {
            if (
              (state.jobRole && state.jobRole === p.position.name) ||
              (state.category && state.category === p.position.name)
            ) {
              return true;
            }
          });

          if (positions.length > 0) {
            isAdd = true;
          }
        } else {
          isAdd = true;
        }

        if (isAdd) {
          if (
            moment().year() <= payload.data.start_year ||
            moment().year() <= payload.data.end_year ||
            payload.data.is_present
          ) {
            payload.data.start_date = `${payload.data?.start_year}-${payload.data?.start_month || 12}-${payload.data?.start_day || 1}`
            current.push(payload.data);
          } else {
            payload.data.start_date = `${payload.data?.start_year}-${payload.data?.start_month || 12}-${payload.data?.start_day || 1}`
            past.push(payload.data);
          }

          if (current.length > 0) {
            currentMilestones = [...current, ...currentMilestones];
            currentMilestones = currentMilestones.sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
            console.log(currentMilestones)
          } else if (past.length > 0) {
            pastMilestones = [...past, ...pastMilestones];
            pastMilestones = pastMilestones.sort((a, b) => new Date(b.start_date) - new Date(a.start_date));

          }
        }
      } else {
        let notListedMilestones = [];

        payload.data.forEach((d) => {
          if (
            (state.jobRole && d.name !== state.jobRole) ||
            (state.category && d.category.name !== state.category)
          ) {
            return;
          }

          if (currentMilestonesKeys.indexOf(d.name) === -1) {
            notListedMilestones.push(d);
          } else {
            currentMilestonesKeys.forEach((k) => {
              if (k === d.name) {
                milestones[k] = [d, ...milestones[k]];
              }
            });
          }
        });

        notListedMilestones.forEach((nl) => {
          let list = [];
          if (currentMilestonesKeys.indexOf(nl.name) === -1) {
            currentMilestonesKeys.push(nl.name);
            list.push(nl);
          } else {
            list = milestones[nl.name];
            list.push(nl);
          }

          milestones[nl.name] = list;
        });
      }

      return {
        ...state,
        currentMilestones: currentMilestones,
        pastMilestones: pastMilestones,
        milestones: milestones,
      };

    case UPDATE_CURRENT_MILESTONE:
      let currentMilestoneList = state.currentMilestones;
      let pin_milestones = state.pinnedMilestones;
      state.currentMilestones.forEach((cm, index) => {
        if (cm.id === payload.data.id) {
          payload.data.start_date = `${payload.data?.start_year}-${payload.data?.start_month || 12}-${payload.data?.start_day || 1}`
          currentMilestoneList[index] = {
            ...cm,
            ...payload.data,
          };
        }
      });
      currentMilestoneList = currentMilestoneList.filter(pinned => pinned.is_featured === 0).sort((a, b) => new Date(b.start_date) - new Date(a.start_date)); //removed if unpinned the milestone in edit
      
      if(payload.data.is_featured === 1){ // I assumed that this is only 1 array since the return from response is object and converted to array.
        pin_milestones = [...pin_milestones, ...[payload.data]];
      }
      return {
        ...state,
        currentMilestones: currentMilestoneList,
        pinnedMilestones: pin_milestones
      };

    case UPDATE_PAST_MILESTONE:
      let pastMilestoneList = state.pastMilestones;
      let pin_past_milestones = state.pinnedMilestones;
      state.pastMilestones.forEach((cm, index) => {
        if (cm.id === payload.data.id) {
          payload.data.start_date = `${payload.data?.start_year}-${payload.data?.start_month || 12}-${payload.data?.start_day || 1}`
          pastMilestoneList[index] = {
            ...cm,
            ...payload.data,
          };
        }
      });

      pastMilestoneList = pastMilestoneList.filter(pinned => pinned.is_featured === 0).sort((a, b) => new Date(b.start_date) - new Date(a.start_date)); //removed if unpinned the milestone in edit
      if(payload.data.is_featured === 1){
        pin_past_milestones = [...pin_past_milestones, ...[payload.data]];
      }

      return {
        ...state,
        pastMilestones: pastMilestoneList,
        pinnedMilestones: pin_past_milestones
      };

    case UPDATE_FEATURED_MILESTONE:
      let featuredMilestone = state.featuredMilestone;

      if (state.organizeBy === "Job/Role") {
        for (let count = 0; count < payload.data.length; count++) {
          if (
            payload.data[count].position_id === featuredMilestone.position_id &&
            payload.data[count].id === featuredMilestone.id
          ) {
            featuredMilestone = payload.data[count];
            break;
          }
        }
      } else {
        if (state.featuredMilestone.id === payload.data.id) {
          featuredMilestone = payload.data;
        }
      }

      return {
        ...state,
        featuredMilestone: featuredMilestone,
      };

    case UPDATE_MILESTONES:
      let newMilestones = [];
      let milestonesList = state.milestones;
      let updatedFeaturedMilestone = state.featuredMilestone;
      let milestonesKeys = Object.keys(state.milestones);
      let notListedMilestones = [];

      if (state.featured) {
        for (let count = 0; count < payload.data.length; count++) {
          if (
            payload.data[count].id === state.featuredMilestone.id &&
            payload.data[count].position_id ===
              state.featuredMilestone.position_id
          ) {
            updatedFeaturedMilestone = {
              ...state.featuredMilestone,
              ...payload.data[count],
            };
            break;
          }
        }
      }

      milestonesKeys.forEach((m) => {
        let keyMilestones = [];

        state.milestones[m].forEach((km, index) => {
          for (let count = 0; count < payload.data.length; count++) {
            if (
              payload.data[count].id === km.id &&
              payload.data[count].position_id === km.position_id
            ) {
              milestonesList[m][index] = {
                ...km,
                ...payload.data[count],
              };
            }
          }

          // let exist = payload.data.filter((d) => {
          //   if (d.id === km.id) {
          //     if (d.name === m && d.position_id === km.position_id) {
          //       return true;
          //     }
          //   } else {
          //     return true;
          //   }
          // });

          let exist = _.filter(payload.data, (d) => {
            if (d?.id === km?.id) {
              if (d.name === m && d.position_id === km.position_id) {
                return true;
              }
            } else {
              return true;
            }
          });

          if (exist.length > 0) {
            keyMilestones.push(milestonesList[m][index]);
          }
        });

        newMilestones = {
          ...newMilestones,
          [m]: keyMilestones,
        };
      });

      const payloadData = Array.isArray(payload.data) ? payload.data : [payload.data];

      payloadData.forEach((p) => {
        if (milestonesKeys.indexOf(p.name) === -1) {
          notListedMilestones.push(p);
        } else {
          milestonesKeys.forEach((m) => {
            if (m === p.name) {
              let exist = newMilestones[m].filter(
                (d) => d.id === p.id && d.position_id === p.position_id
              );

              if (!exist.length) {
                if (
                  (!state.jobRole || state.jobRole === p.name) &&
                  (!state.category || state.category === p.category?.name)
                ) {
                  newMilestones[m] = [p, ...newMilestones[m]];
                }
              }
            }
          });
        }
      });

      // update current milestones
      let updatedCurrentMilestones = state.currentMilestones.map(m => {
        const newData = payloadData.find(payload => payload.id === m.id);
        return newData || m;
      });

      notListedMilestones.forEach((nl) => {
        let list = [];
        if (milestonesKeys.indexOf(nl.name) === -1) {
          milestonesKeys.push(nl.name);
          list.push(nl);
        } else {
          list = newMilestones[nl.name];
          list.push(nl);
        }
        newMilestones[nl.name] = list;
      });

      return {
        ...state,
        milestones: newMilestones,
        featuredMilestone: updatedFeaturedMilestone,
        currentMilestones: updatedCurrentMilestones,
      };

    case DELETE_MILESTONE:
      // const udCurrentMilestoneList = state.currentMilestones.filter((cm) => {
      //   if (cm.id === payload.id) {
      //     if (cm.position_id !== payload.position_id) {
      //       return true;
      //     }
      //   } else {
      //     return true;
      //   }
      // });
      const udCurrentMilestoneList = _.filter(state.currentMilestones, (cm) => {
        if (cm.id === payload.id) {
          if (cm.position_id !== payload.position_id) {
            return true;
          }
        } else {
          return true;
        }
      });

      // const udPastMilestoneList = state.pastMilestones.filter((pm) => {
      //   if (pm.id === payload.id) {
      //     if (pm.position_id !== payload.position_id) {
      //       return true;
      //     }
      //   } else {
      //     return true;
      //   }
      // });

      //converted to git rid of the console log warning for eslint
      // the current code expect a returns but there are conditions that are not returning any
      const udPastMilestoneList = _.filter(state.pastMilestones, (pm) => {
        if (pm.id === payload.id) {
          if (pm.position_id !== payload.position_id) {
            return true;
          }
        } else {
          return true;
        }
      });

      const udMilestonesKeys = Object.keys(state.milestones);
      let udMilestones = state.milestones;

      udMilestonesKeys.forEach((m) => {
        if (m === payload.name) {
          // let milestones = state.milestones[m].filter((d) => {
          //   if (d.id === payload.id) {
          //     if (d.position_id !== payload.position_id) {
          //       return true;
          //     }
          //   } else {
          //     return true;
          //   }
          // });
          let milestones = _.filter(state.milestones[m], (d) => {
            if (d.id === payload.id) {
              if (d.position_id !== payload.position_id) {
                return true;
              }
            } else {
              return true;
            }
          });

          udMilestones = {
            ...udMilestones,
            [m]: milestones,
          };
        }
      });

      return {
        ...state,
        currentMilestones: udCurrentMilestoneList,
        pastMilestones: udPastMilestoneList,
        milestones: udMilestones,
      };
    
    case SET_PLAY_MUSIC:
        return {
          ...state,
          playMusic: payload
        };

    default:
      return state;
  }
};

export default reducer;
