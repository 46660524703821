import React, { useEffect, useState } from "react";
import Button from "components/button/button";
import { HiOutlinePencil } from "react-icons/hi";
import { FiCheck } from "react-icons/fi";
import toast from "react-hot-toast";
import LoadingIndicator from "assets/svg/loading-indicator";
import Input from "components/input/input";
import _ from "lodash";
import PopInfo from "components/info-popup/pop-info";
import update from "immutability-helper";
import { FaMinus } from "react-icons/fa";
import Members from "components/settings/component/Members";

const buttonProps = {
  edit: {
    btnName: "Edit",
    icon: <HiOutlinePencil />,
    btnClass: "relative py-3.6 px-5.5 bg-modalGray hover:bg-gray",
    btnTextClass:
      "text-sm text-lightBlack font-semibold flex items-center space-x-1.6",
  },
  save: {
    btnName: "Save Changes",
    icon: <FiCheck className="text-lg" />,
    btnClass: "relative py-3.6 px-5.5 bg-primary hover:bg-darkPrimary",
    btnTextClass:
      "text-sm text-white font-semibold flex items-center space-x-1.6",
  },
};

const GroupMembers = (props) => {
  const { profile, userData, setUserData } = props;
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState([]);
  const { http, localstorage } = global.services;

  const onClickSave = async () => {
    setLoading(true);
    let payload = {
      profileGroup: JSON.stringify(members),
      profileTypes: profile?.profile_type?.type || "",
    };
    const { data } = await http.put(
      `public/profiles/update-members-information/${profile?.id}`,
      payload
    );

    if (data) {
      toast.success("Members Information Successfully Updated");
      console.log(data.data);
      const updatedData = { ...userData, profile: data.data };
      setUserData(updatedData);
      localstorage.set("user_data", JSON.stringify(updatedData));
      //update state to false
      setLoading(false);
      setEdit(false);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(profile)) {
      let members = profile?.profile_type?.members;
      if (members) {
        setMembers(JSON.parse(members));
      } else {
        setMembers([]);
      }
    }
  }, [profile]);

  const onChange = (e, index) => {
    e.stopPropagation();
    let name = "";
    if (e.target.name === `name-${index}`) {
      name = e.target.value;
    } else {
      name = members[index].name;
    }

    let role = "";
    if (e.target.name === `role-${index}`) {
      role = e.target.value;
    } else {
      role = members[index].role;
    }

    let dateSE = "";
    if (e.target.name === `dateSE-${index}`) {
      dateSE = e.target.value;
    } else {
      dateSE = members[index].dateSE;
    }

    let payload = _.clone(members);
    let result = update(payload, {
      [index]: {
        name: { $set: name },
        role: { $set: role },
        dateSE: { $set: dateSE },
      },
    }); //remove
    setMembers(result);
  };

  const onRemoveMembers = (index) => {
    let payload = _.clone(members);
    let result = update(payload, { $splice: [[index, 1]] }); //remove
    setMembers(result);
  };

  const onAddMembers = () => {
    let payload = _.clone(members);
    payload.push({ name: "", role: "", dateSE: "" });
    setMembers(payload);
  };

  return (
    <div className="w-full bg-white rounded-md px-5 2md:pl-29 2md:pr-24.5 py-10 md:pb-14 md:pt-8">
      <div className="w-full flex justify-between flex-wrap items-center mb-4">
        <div className="flex flex-row gap-x-2 items-center">
          <h1 className="text-2xl font-semibold text-lightBlack">Members</h1>
          <PopInfo containerClass="relative" type="personal_information" />
        </div>
        {props.isYourProfile && (
          <div className="flex gap-2">
          <Button
            buttonName={
              edit ? buttonProps.save.btnName : buttonProps.edit.btnName
            }
            buttonTextClass={
              edit
                ? buttonProps.save.btnTextClass
                : buttonProps.edit.btnTextClass
            }
            iconPosition="left"
            icon={edit ? buttonProps.save.icon : buttonProps.edit.icon}
            buttonRadius="6px"
            buttonClass={
              edit ? buttonProps.save.btnClass : buttonProps.edit.btnClass
            }
            loadingIcon={
              loading ? (
                <LoadingIndicator className="text-white w-4 h-4" />
              ) : null
            }
            disabled={loading}
            onClick={() => {
              if (edit) {
                onClickSave();
              } else {
                setEdit((prev) => !prev);
              }
            }}
          />  

          {
            edit && !loading && 
            <Button
              buttonName="Cancel"
              buttonTextClass={buttonProps.edit.btnTextClass}
              buttonClass={buttonProps.edit.btnClass}
              onClick={() => { 
                  setEdit((prev) => !prev); 
              }}
            /> 
          }
          </div>
        )}
      </div>
      <div>
        {!edit && members.map((mem, index) => {
          return (
            <> 
              <div className="flex w-3/4 mt-1" key={index}>
                <div className="border-l-[3px] border-slate-200 font-bold text-sm flex-1 pl-4 capitalize"
                  dangerouslySetInnerHTML={{ __html: mem?.mention || mem?.name }}
                >
                  {/* {mem.name} */}
                </div>
                <div className="text-sm flex-1">{mem.role}</div>
                <div className="text-sm flex-1">{mem.dateSE}</div>
              </div> 
            </>
          );
        })}
        {
          edit && 
          <div className="w-full 3md:w-14/20">
          <Members
            items={members}
            setItems={setMembers}
            // errors={state.validationErrors}
            onAdd={onAddMembers}
            onChange={(e, index) => onChange(e, index)}
            onRemove={(index) => onRemoveMembers(index)}
            showAddBTN={false}
            customStyle={
              { 
                left: "auto !important",
                top: "auto !important",
              }
            }
          />
        </div>
        }
      </div>
    </div>
  );
};

export default GroupMembers;
