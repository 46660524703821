import React from "react";

const TabColor = {
  default: {
    active: "tab-animation active text-primary",
    inactive:
      "border-transparent text-inactiveGray hover:text-primary tab-animation ",
  },
  black: {
    active: "tab-animation active text-primary",
    inactive:
      "border-transparent text-lightBlack hover:text-primary tab-animation ",
  },
};

const MenuColor = {
  default: {
    active: "menu active text-primary",
    inactive: "border-transparent text-inactiveGray hover:text-primary ",
  },
  black: {
    active: "menu active text-primary",
    inactive:
      "border-transparent text-lightBlack hover:text-primary menu-animation ",
  },
  blue: {
    active: "bg-resumeMenu text-primary",
    inactive:
      "border-transparent text-inactiveGray hover:text-primary hover:bg-resumeMenu ",
  },
};

export const TabSelector = ({
  isActive,
  colorType = "default",
  children,
  gap = "mr-8",
  paddingY = "py-4",
  paddingX = "px-2",
  onClick = () => {},
  flex_1="",
  ...props
}) => (
  <button
    type="button"
    className={`${gap} relative group inline-flex items-center ${paddingX} border-b-4 ${paddingY} text-lg font-semibold leading-5 cursor-pointer whitespace-nowrap ${flex_1} ${
      isActive ? TabColor[colorType].active : TabColor[colorType].inactive
    }`}
    onClick={onClick}
    {...props}
  >
    {children}
  </button>
);

export const MenuSelector = ({
  isActive,
  colorType = "default",
  children,
  onClick = () => {},
  ...props
}) => (
  <button
    type="button"
    className={`relative group inline-flex items-center text-lg font-semibold leading-5 cursor-pointer py-3 px-6 ${
      isActive ? MenuColor[colorType].active : MenuColor[colorType].inactive
    }`}
    onClick={onClick}
    {...props}
  >
    {children}
  </button>
);

export const SidebarMenu = ({
  isActive,
  colorType = "default",
  children,
  onClick = () => {},
  ...props
}) => (
  <button
    type="button"
    className={`relative w-full group inline-flex items-center text-xl font-semibold cursor-pointer whitespace-nowrap py-4.5 px-6 rounded-xl ${
      isActive ? MenuColor[colorType].active : MenuColor[colorType].inactive
    }`}
    onClick={onClick}
    {...props}
  >
    {children}
  </button>
);
