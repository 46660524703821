import { createContext, useContext, useEffect, useState } from "react";
import Pusher from "pusher-js";
import LocalStorageService from "services/local-storage";

export const PusherContext = createContext();

let pusher;

export const PusherProvider = ({ children }) => {
  const [client, setClient] = useState(null);
  const rawUserData = LocalStorageService.get("user_data");

  let token = null;
  let userData = null;

  if (rawUserData.value) {
    userData = JSON.parse(rawUserData.value);
    token = userData.access_token;
  }

  Pusher.logToConsole = false;
  pusher =
    pusher ??
    new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      authEndpoint: `${process.env.REACT_APP_API_ENDPOINT}/broadcasting/auth`,
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      },
    });

  useEffect(() => {
    if (pusher && !client) {
      setClient(pusher);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pusher]);

  return (
    <PusherContext.Provider value={client}>{children}</PusherContext.Provider>
  );
};

export const usePusher = () => {
  return useContext(PusherContext);
};

export const usePusherChannel = (channelName) => {
  const client = usePusher();
  const [channel, setChannel] = useState(null);

  useEffect(() => {
    if (client && !channel) {
      setChannel(client.subscribe(channelName));
    }
    return () => {
      if (channel) {
        client.unsubscribe(channelName);
      }
    };
  }, [channelName, client, channel]);

  return channel;
};

export const usePusherEvent = (channelName, eventName, cb) => {
  const channel = usePusherChannel(channelName);
  const [event, setEvent] = useState(false);
  useEffect(() => {
    if (channel && !event) {
      channel.bind(eventName, cb);
      setEvent(true);
    }
    return () => {
      if (event) {
        channel.unbind(eventName);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, eventName, event]);
};
