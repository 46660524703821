import {
  REFRESH_PRIVACIES,
	SET_PRIVACIES,
} from './types';

export const setRefreshPrivacies = () => {
	return {
    type: REFRESH_PRIVACIES,
    payload: [],
  };
}

export const setPrivacies = (params) => {
	return {
    type: SET_PRIVACIES,
    payload: params,
  };
}