const AboutIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_820_2954)">
        <path
          d="M13.79 7.69059C13.7994 8.14157 13.707 8.58884 13.5197 8.99919C13.3324 9.40953 13.0549 9.77229 12.708 10.0606C12.3611 10.3489 11.9536 10.5553 11.5159 10.6643C11.0782 10.7734 10.6216 10.7824 10.18 10.6906C9.4808 10.5107 8.86018 10.1058 8.41382 9.53837C7.96746 8.97093 7.72011 8.27247 7.70996 7.55058V6.63054C7.72177 5.90909 7.96973 5.21144 8.41583 4.64433C8.86193 4.07722 9.48162 3.6719 10.18 3.49052C10.6216 3.3987 11.0782 3.40771 11.5159 3.51677C11.9536 3.62582 12.3611 3.8321 12.708 4.1204C13.0549 4.4087 13.3324 4.77158 13.5197 5.18193C13.707 5.59227 13.7994 6.03954 13.79 6.49052V7.69059Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.75 21C16.2728 21 20.75 16.5228 20.75 11C20.75 5.47715 16.2728 1 10.75 1C5.22715 1 0.75 5.47715 0.75 11C0.75 16.5228 5.22715 21 10.75 21Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.93018 18.3109C4.5513 17.0304 5.52033 15.9507 6.72644 15.1952C7.93255 14.4397 9.32695 14.0391 10.7501 14.0391C12.1733 14.0391 13.5678 14.4397 14.7739 15.1952C15.98 15.9507 16.949 17.0304 17.5701 18.3109"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_820_2954">
          <rect
            width="21.5"
            height="21.5"
            fill="white"
            transform="translate(0 0.25)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AboutIconSVG;
