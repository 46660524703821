import React, { useMemo } from "react";
import UserGearIconSVG from "assets/svg/user-gear-icon";
import GraduationCapIconSVG from "assets/svg/graduation-cap-icon";
import ChatUserIconSVG from "assets/svg/chat-user-icon";
import BriefCaseExperienceIconSVG from "assets/svg/brief-case-icon";
import { Profile1 } from "constants/images";
import { socialTemplate } from "utils/constant";
import _ from "lodash";
import { groupArraysWithType, A4_SIZE } from "../../resume";
import { personalDateFormat } from "utils/utils";
import profile from "pages/profile/profile";

export default function TemplateThreePageOne(props) {
  const {
    active,
    previewImage,
    color = {},
    details = {},
    personal = {},
    social = {},
    primaryColor,
    tertiaryColor,
    milestones,
    setTotalPage,
  } = props;

  React.useEffect(() => {
    let groupAll = groupArraysWithType(arraysWithType, 9, 9);
    setTotalPage(groupAll.length + 1);
  }, [milestones]);

  const arraysWithType = [
    { array: milestones?.featured || [], type: 'featured' },
    { array: milestones?.current || [], type: 'current' },
    { array: milestones?.past || [], type: 'past' },
  ];

  const activeColor = useMemo(() => {
    if (color.label === "Voilet + Pink") {
      return tertiaryColor;
    }
    return primaryColor;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  const socMed = useMemo(() => {
    let arrays = Object.entries(social);
    return arrays.map((obj) => {
      let key = obj[0];
      let value = obj[1];
      return { key, value };
    });
  }, [social]);

  return (
    <div className={`${!active ? "hidden" : ""} relative`} style={A4_SIZE}>
      <div
        className="w-full bg-resumeTempThreePrimary h-full"
        style={{ minHeight: "1123px" }}>
        <div className="w-full h-full">
          <div className="w-full h-full grid relative grid-row-auto">
            <div className="w-full h-full px-10">
              {/* page number */}
              <div className="w-full relative">
                <div className="w-6 h-6 bg-resumeTempThreeSecondary template-three-page-number">
                  <p className="text-sm text-resumeTempThreeTextSecondary tracking-resumeThree leading-3.2 font-semibold font-template-three">
                    01
                  </p>
                </div>
                {/* absolute */}
                <div className="absolute right-1 top-3.7 font-template-three w-24.6">
                  <p
                    className="text-xs text-right leading-4.5"
                    style={{ color: activeColor }}>
                    {(personal.occupations || []).map((val, index) => {
                      const count = personal.occupations.length;
                      if (index >= count - 1 && count !== 1) {
                        return " and " + val;
                      } else {
                        if (index >= count - 2) {
                          return val;
                        } else {
                          return val + ", ";
                        }
                      }
                    })}
                  </p>
                </div>
              </div>
              {/* body */}
              <div className="w-full mt-3.4">
                <div className="w-full flex flex-row space-x-7">
                  {/* left */}
                  <div className="w-40">
                  {
                    personal?.profile_image &&
                    <>
                      <div className="w-40 h-40 bg-white rounded-full relative overflow-hidden">
                        {(personal.hasOwnProperty("profile_image") && personal.profile_image) || previewImage ? (
                          <img
                            src={previewImage ?? personal.profile_image}
                            className="w-full h-full object-cover object-center"
                            alt="resume-profile"
                          />
                        ) : (
                          <img
                            alt="milestone-profile"
                            src={Profile1}
                            className="w-full h-full object-cover object-center"
                          />
                        )}
                      </div>
                    </>
                  }
                    {/* content */}
                    <div className="w-full mt-5.4 px-1.4">
                      {details.hasOwnProperty("bio") && details.bio && (
                        <div className="w-full border-bottom-resume-three">
                          {/* title */}
                          <p className="uppercase text-4xs text-resumeTempThreeTextPrimary font-medium tracking-resumeThreeWide">
                            bio
                          </p>
                          {/* content */}
                          <div className="w-full mt-2.5 mb-3">
                            <p className="text-white text-1xs leading-3.4 font-light self-stretch">
                              {details.bio}
                            </p>
                          </div>
                        </div>
                      )}
                      {/*  */}
                      <div className="w-full mt-5">
                        {/* title */}
                        <p className="uppercase text-4xs text-resumeTempThreeTextPrimary font-medium tracking-resumeThreeWide">
                          more information
                        </p>
                        <div className="w-full mt-3 border-bottom-resume-three">
                          <div className="w-full grid gap-y-2 mb-3.5">
                            {/* Date of Birth */}
                            {details.hasOwnProperty("birthdate") &&
                              details.birthdate && (
                                <div className="w-full flex flex-row items-center space-x-0.5 text-white">
                                  <div className="w-15.5">
                                    <p className="font-bold text-5xs uppercase leading-1.5">
                                      Date of Birth
                                    </p>
                                  </div>
                                  <div style={{ width: "calc(100% - 64px)" }}>
                                    <p className="font-light text-5xs leading-3.4">
                                      {personalDateFormat(details.birthdate, profile?.dateFormat)}
                                    </p>
                                  </div>
                                </div>
                              )}
                            {/* Birth Location */}
                            {details.hasOwnProperty("birth_location") &&
                              details.birth_location && (
                                <div className="w-full flex flex-row items-center space-x-0.5 text-white">
                                  <div className="w-15.5">
                                    <p className="font-bold text-5xs uppercase leading-1.5">
                                      Birth Location
                                    </p>
                                  </div>
                                  <div style={{ width: "calc(100% - 64px)" }}>
                                    <p className="font-light text-5xs leading-3.4">
                                      {details.birth_location}
                                    </p>
                                  </div>
                                </div>
                              )}

                            {/*Civil Status */}
                            {details.hasOwnProperty("civil_status") &&
                              details.civil_status && (
                                <div className="w-full flex flex-row items-center space-x-0.5 text-white">
                                  <div className="w-15.5">
                                    <p className="font-bold text-5xs uppercase leading-1.5">
                                      Civil Status
                                    </p>
                                  </div>
                                  <div style={{ width: "calc(100% - 64px)" }}>
                                    <p className="font-light text-5xs leading-3.4">
                                      {details.civil_status}
                                    </p>
                                  </div>
                                </div>
                              )}
                            {/* Children */}
                            {details.hasOwnProperty("children") &&
                              details.children !== 0 && (
                                <div className="w-full flex flex-row items-center space-x-0.5 text-white">
                                  <div className="w-15.5">
                                    <p className="font-bold text-5xs uppercase leading-1.5">
                                      Children
                                    </p>
                                  </div>
                                  <div style={{ width: "calc(100% - 64px)" }}>
                                    <p className="font-light text-5xs leading-3.4">
                                      {details.children}
                                    </p>
                                  </div>
                                </div>
                              )}

                            {/* Other Name */}
                            {details.hasOwnProperty("other_name") &&
                              details.other_name && (
                                <div className="w-full flex flex-row items-center space-x-0.5 text-white">
                                  <div className="w-15.5">
                                    <p className="font-bold text-5xs uppercase leading-1.5">
                                      Other Name
                                    </p>
                                  </div>
                                  <div style={{ width: "calc(100% - 64px)" }}>
                                    <p className="font-light text-5xs leading-3.4">
                                      {details.other_name}
                                    </p>
                                  </div>
                                </div>
                              )}
                            {/*Hair Color */}
                            {details.hasOwnProperty("hair_color") &&
                              details.hair_color && (
                                <div className="w-full flex flex-row items-center space-x-0.5 text-white">
                                  <div className="w-15.5">
                                    <p className="font-bold text-5xs uppercase leading-1.5">
                                      Hair Color
                                    </p>
                                  </div>
                                  <div style={{ width: "calc(100% - 64px)" }}>
                                    <p className="font-light text-5xs leading-3.4">
                                      {details.hair_color}
                                    </p>
                                  </div>
                                </div>
                              )}
                            {/* Eye Color */}
                            {details.hasOwnProperty("eye_color") &&
                              details.eye_color && (
                                <div className="w-full flex flex-row items-center space-x-0.5 text-white">
                                  <div className="w-15.5">
                                    <p className="font-bold text-5xs uppercase leading-1.5">
                                      Eye Color
                                    </p>
                                  </div>
                                  <div style={{ width: "calc(100% - 64px)" }}>
                                    <p className="font-light text-5xs leading-3.4">
                                      {details.eye_color}
                                    </p>
                                  </div>
                                </div>
                              )}
                            {/* Height */}
                            {details.hasOwnProperty("height") &&
                              details.height && (
                                <div className="w-full flex flex-row items-center space-x-0.5 text-white">
                                  <div className="w-15.5">
                                    <p className="font-bold text-5xs uppercase leading-1.5">
                                      Height
                                    </p>
                                  </div>
                                  <div style={{ width: "calc(100% - 64px)" }}>
                                    <p className="font-light text-5xs leading-3.4">
                                      {details.height}
                                    </p>
                                  </div>
                                </div>
                              )}

                            {/* Phone  */}
                            {details.hasOwnProperty("phone_number") &&
                              details.phone_number && (
                                <div className="w-full flex flex-row items-center space-x-0.5 text-white">
                                  <div className="w-15.5">
                                    <p className="font-bold text-5xs uppercase leading-1.5">
                                      Phone
                                    </p>
                                  </div>
                                  <div style={{ width: "calc(100% - 64px)" }}>
                                    <p className="font-light text-5xs leading-3.4">
                                      {details.phone_number}
                                    </p>
                                  </div>
                                </div>
                              )}
                            {/* Email Address  */}
                            {details.hasOwnProperty("email") &&
                              details.email && (
                                <div className="w-full flex flex-row items-center space-x-0.5 text-white">
                                  <div className="w-15.5">
                                    <p className="font-bold text-5xs uppercase leading-1.5">
                                      Email Address
                                    </p>
                                  </div>
                                  <div style={{ width: "calc(100% - 64px)" }}>
                                    <p className="font-light text-5xs leading-3.4">
                                      {details.email}
                                    </p>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      {/* end */}
                    </div>
                  </div>
                  {/* right */}
                  <div style={{ width: "calc(100% - 188px)" }}>
                    <div className="w-full">
                      {/* Name */}
                      <div className="w-57">
                        <h1 className="font-template-three text-white text-5.4xl font-medium leading-12">
                          I’m {details.username}
                        </h1>
                      </div>
                      {/* Other info */}
                      <div className="w-full mt-3.7">
                        <div className="flex flex-row gap-x-1.5 text-white items-center font-medium">
                          {personal.hasOwnProperty("name") && personal.name && (
                            <p className="text-1xs leading-2">
                              {personal.name}
                            </p>
                          )}
                          {personal.hasOwnProperty("name") &&
                            personal.name &&
                            personal.hasOwnProperty("gender") &&
                            personal.gender && (
                              <span className="text-1xs opacity-30">
                                &#8226;
                              </span>
                            )}
                          {personal.hasOwnProperty("gender") &&
                            personal.gender && (
                              <p className="text-1xs leading-2">
                                {personal.gender}
                              </p>
                            )}
                          {personal.hasOwnProperty("gender") &&
                            personal.gender &&
                            personal.hasOwnProperty("pronoun") &&
                            personal.pronoun && (
                              <span className="text-1xs opacity-30">
                                &#8226;
                              </span>
                            )}
                          {personal.hasOwnProperty("pronoun") &&
                            personal.pronoun && (
                              <p className="text-1xs leading-2">
                                {personal.pronoun}
                              </p>
                            )}
                          {personal.hasOwnProperty("pronoun") &&
                            personal.pronoun && (
                              <p className="text-1xs leading-2">
                                {personal.pronoun}
                              </p>
                            )}
                          {personal.hasOwnProperty("address") &&
                            personal.address && (
                              <>
                                <span className="text-1xs opacity-30">
                                  &#8226;
                                </span>
                                <p className="text-1xs leading-2">
                                  {personal.address}
                                </p>
                              </>
                            )}
                        </div>
                      </div>
                      {/* extra */}
                      <div className="w-full mt-2">
                        <div className="flex flex-row flex-wrap gap-1.4 text-5xs font-bold text-white">
                          <span className="uppercase p-1 bg-resumeTempThreeSecondary rounded-sm">
                            Agency Company
                          </span>
                          {personal.hasOwnProperty("profile_type") &&
                            personal.profile_type.length > 0 && (
                              <>
                                {(personal.profile_type || []).map(
                                  (value, index) => {
                                    return (
                                      <span
                                        className="uppercase p-1 bg-resumeTempThreeSecondary rounded-sm"
                                        key={index}>
                                        {value}
                                      </span>
                                    );
                                  }
                                )}
                              </>
                            )}
                        </div>
                      </div>
                      {/*  */}
                      {((details.hasOwnProperty("occupations") &&
                        !_.isEmpty(details.occupations)) ||
                        details.education ||
                        details.training ||
                        details.experience) && (
                          <div className="w-full mt-8.6">
                            {/* title */}
                            <div className="w-full">
                              <p className="uppercase text-4xs text-resumeTempThreeTextPrimary font-medium tracking-resumeThreeWide">
                                Featured Milestones
                              </p>
                            </div>
                            <div className="w-full mt-3">
                              <div className="w-full grid gap-y-5">
                                {/*  */}
                                <div className="w-full">
                                  <div className="w-full flex flex-row items-center space-x-2">
                                    <div className="w-4 h-4">
                                      <UserGearIconSVG
                                        className="w-4 h-4"
                                        fill={activeColor}
                                      />
                                    </div>
                                    <div style={{ width: "calc(100% - 24px)" }}>
                                      <p className="font-template-three text-xs font-bold text-white leading-4.5 text-opacity-90">
                                        Abilities / Skills / Specialties
                                      </p>
                                    </div>
                                  </div>
                                  {/* content */}
                                  <div className="w-full mt-2.5">
                                    {details.hasOwnProperty("occupations") && (
                                      <div className="flex flex-row flex-wrap gap-1.4 text-5xs font-bold text-white">
                                        {(details.occupations || []).map(
                                          (val, index) => {
                                            return (
                                              <span
                                                className="uppercase p-1 bg-resumeTempThreeSecondary rounded-sm"
                                                key={index}>
                                                {val}
                                              </span>
                                            );
                                          }
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                {/*  */}
                                <div className="w-full">
                                  <div className="w-full flex flex-row items-center space-x-2">
                                    <div className="w-4 h-4">
                                      <GraduationCapIconSVG
                                        className="w-4 h-4"
                                        fill={activeColor}
                                      />
                                    </div>
                                    <div style={{ width: "calc(100% - 24px)" }}>
                                      <p className="font-template-three text-xs font-bold text-white leading-4.5 text-opacity-90">
                                        Education
                                      </p>
                                    </div>
                                  </div>
                                  {/* content */}
                                  <div className="w-full mt-2 text-white text-opacity-60">
                                    <p className="font-light text-1xs leading-2 self-stretch">
                                      {details.education}
                                    </p>
                                  </div>
                                </div>
                                {/*  */}
                                <div className="w-full">
                                  <div className="w-full flex flex-row items-center space-x-2">
                                    <div className="w-4 h-4">
                                      <ChatUserIconSVG
                                        className="w-4 h-4"
                                        fill={activeColor}
                                      />
                                    </div>
                                    <div style={{ width: "calc(100% - 24px)" }}>
                                      <p className="font-template-three text-xs font-bold text-white leading-4.5 text-opacity-90">
                                        Training
                                      </p>
                                    </div>
                                  </div>
                                  {/* content */}
                                  <div className="w-full mt-2 text-white text-opacity-60">
                                    <p className="font-light text-1xs leading-2 self-stretch">
                                      {details.training}
                                    </p>
                                  </div>
                                </div>
                                {/*  */}
                                <div className="w-full">
                                  <div className="w-full flex flex-row items-center space-x-2">
                                    <div className="w-4 h-4">
                                      <BriefCaseExperienceIconSVG
                                        className="w-4 h-4"
                                        fill={activeColor}
                                      />
                                    </div>
                                    <div style={{ width: "calc(100% - 24px)" }}>
                                      <p className="font-template-three text-xs font-bold text-white leading-4.5 text-opacity-90">
                                        Experience
                                      </p>
                                    </div>
                                  </div>
                                  {/* content */}
                                  <div className="w-full mt-2 text-white text-opacity-60">
                                    <p className="font-light text-1xs leading-2 self-stretch">
                                      {details.experience}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                  {/* end right */}
                </div>
              </div>
            </div>
            {/* footer */}
            <div className="w-full flex items-end">
              <div className="w-full h-8.5 bg-resumeTempThreeTextPrimary bg-opacity-20 relative">
                <div className="w-full h-full flex flex-row justify-center items-center space-x-3 text-resumeTempThreeTextPrimary">
                  {(socMed || []).map((obj, index) => {
                    const SocialIcon = socialTemplate[obj.key];
                    if (SocialIcon) {
                      return (
                        <div
                          className="flex items-center space-x-1.5"
                          key={index}>
                          <SocialIcon
                            className="text-4xs"
                            style={{ color: activeColor }}
                          />
                          <p className="text-3xs leading-2">{obj.value}</p>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          className="flex items-center space-x-1.5"
                          key={index}>
                          <div className="w-3">
                            <img
                              className="text-4xs"
                              src={`https://s2.googleusercontent.com/s2/favicons?domain=${obj.value}&sz=64`}
                              alt=""
                            />
                          </div>
                          <p className="text-3xs leading-2">{obj.value}</p>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
