import {
	REFRESH_OCCUPATIONS,
	SET_OCCUPATIONS,
	ADD_OCCUPATION
} from './types'

const initialState = {
	occupations: [],
	refresh: false,
	initialize: true
};

const reducer = (state = initialState, {type, payload}) => {

	switch (type) {

		case REFRESH_OCCUPATIONS:
			return {
				...state,
				refresh: true,
				initialize: true,
				occupations: [],
			}
		
		case SET_OCCUPATIONS:
			return {
				...state,
				refresh: false,
				initialize: false,
				occupations: payload
			}
		
		case ADD_OCCUPATION:
			return {
				...state,
				occupations: [...state.occupations, payload]
			}

		default:
			return state;

	}

}

export default reducer