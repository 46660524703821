import Modal from "components/global/modal";
import { LiaTimesSolid } from "react-icons/lia";
import Select from "components/select/select";
import { useState } from "react";
import Button from "components/button/button";
import LoadingIndicator from "assets/svg/loading-indicator";
import { reportType } from "utils/constant";
import TextArea from "components/textarea/textarea";
import toast from "react-hot-toast";
const ReportMilestone = ({
  hide,
  userData = {},
  milestone = {},
  accountReport = {}, // Use this variable for report the account
  type = "milestone",
  ...props
}) => {
  const [state, setState] = useState({
    comments: "",
    problem: "",
  });

  const [loading, setLoading] = useState(false);
  const { http } = global.services;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
      validationErrors: { ...state.validationErrors, [name]: null },
    }));
  };

  const handlerOnSubmit = async () => {
    try {
      let payload = {};
      if (type === "milestone") {
        const { id, user_profile_id, profile } = milestone; //Use this data when the report is about post milestone
        const { user } = profile;
        payload = {
          ...state,
          milestone_id: id || null,
          account_id: user_profile_id,
          type: type,
          sendTo: user.email || userData?.user?.email,
          link: window.location.href,
        };
      } else {
        const { user } = accountReport;
        payload = {
          ...state,
          milestone_id: null,
          account_id: user.id,
          type: type,
          sendTo: user.email || userData?.user?.email,
          link: window.location.href,
        };
      }
      setLoading(true);
      const result = await http.post(`public/reportMilestone`, payload);
      if (result.data.message) {
        toast.success(result.data.message);
      }
      if (type === "milestone") {
          const { reports } = milestone;
          milestone.reports = [...reports, result.data.report];
          hide({ success: true, data: milestone });
      } else {
          console.log(accountReport);
          if(accountReport?.user?.reports){
            accountReport.user.reports = [...accountReport.user?.reports, result.data.report];
          }
          hide({ success: true, data: accountReport });
      }
    } catch (err) {
      if (err.status === 422)
        return setState((prev) => ({
          ...prev,
          validationErrors: err.data?.errors,
        }));
      console.log("error => ", err);
      toast.error(`It appears that something went wrong`);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const Footer = () => {
    return (
      <div className="relative grid gap-y-3 1sm:gap-y-0 1sm:flex 1sm:flex-row 1sm:items-center 1sm:justify-end 1sm:space-x-2">
        <Button
          buttonName="Cancel"
          buttonClass="bg-thBg py-5 px-12"
          buttonTextClass="text-black text-sm font-semibold"
          onClick={() => hide()}
        />
        <Button
          onClick={handlerOnSubmit}
          key="save-btn-category"
          buttonName="Send"
          buttonClass="bg-primary py-5 px-12"
          // buttonTextClass="text-white text-sm font-semibold flex items-center"
          buttonTextClass={`text-white text-sm font-semibold  ${loading ? 'flex items-center justify-center' : ''}`}
          loadingIcon={
            loading ? (
              <LoadingIndicator className="text-white w-4 h-4 ml-2" />
            ) : null
          }
        />
      </div>
    );
  };

  return (
    <Modal
      footer={<Footer />}
      width="w-19/20 md:w-16/20 2md:w-12/20 3md:w-7/20 1xl:w-8/20"
      contentClassName="rounded-lg overflow-auto custom-scroll"
      wrapperClassName="overflow-visible"
      footerClass="border-t border-gray">
      <div className="w-full">
        <div className="flex justify-between border-b border-gray w-full px-4 py-6">
          <div className="font-bold text-[1.5rem]">Report Milestone</div>
          <div>
            <LiaTimesSolid
              className="text-[1.5rem] cursor-pointer"
              onClick={() => hide()}
            />
          </div>
        </div>
        <div className="px-4 py-6">
          <div>
            <Select
              label="Please select a problem"
              options={reportType}
              placeholder="Please Select"
              name="type"
              height={58}
              value={
                state.problem &&
                reportType.filter((rm) => rm.value === state.problem)
              }
              onChange={(e) =>
                handleInputChange({
                  target: { name: "problem", value: e.value },
                })
              }
              errorMessage={state?.validationErrors?.problem}
            />
          </div>
          <div>
            <TextArea
              defaultValue={state.comments}
              type="text"
              onChange={handleInputChange}
              placeholder={`e.g. Star Wars`}
              name="comments"
              label="Comments"
              errorMessage={state?.validationErrors?.comments}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReportMilestone;
