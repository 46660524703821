import React, { useState, useEffect, useRef, useMemo } from "react";
import InfoQuestionIconSVG from "assets/svg/info-question-mark-icon";
import PlayIconSmallSVG from "assets/svg/play-icon";
import json from "assets/json/popup-info.json";
import _ from "lodash";
import styled from "styled-components";

export default function PopInfo({
  containerClass = "relative top-0.5",
  type = "milestones",
  direction = "left",
  onClickCallBack = () => {},
  responsive = { left: "135px", right: "135px" },
  setShowMedia = ()=> false,
  showMediaHandle = ()=> {}, 
}) {
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef(null);
  // outside clicks
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (open) {
            setOpen(false);
          }
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, open]);
  };

  useOutsideAlerter(wrapperRef);
  // =========================== useMemo ================================
  const content = useMemo(() => {
    let result = _.find(json, { key: type });
    return result;
  }, [type]);

  return (
    <div
      ref={wrapperRef}
      className={`${containerClass ? ` ${containerClass}` : ""}`}>
      <button
        className="rounded-full w-5 h-5 border-2 border-lightBlack"
        onClick={() => {
          setOpen((prev) => !prev);
          onClickCallBack();
        }}>
        <InfoQuestionIconSVG className="m-auto" />
      </button>
      <ContentContainer
        open={open}
        direction={direction}
        responsive={responsive}>
        {/* arrow */}
        <div className={`arrow-${direction}`}>
          <ArrowIcon />
        </div>
        <div className="content-container">
          <div className="content">
            {/* title */}
            <p className="content-title">{content?.label}</p>
            {/* content */}
            <p className="content-sub-title">{content?.description}</p>
          </div>
          {/* footer */}
          <div className="footer-continer">
            <div className="footer">
              <PopButton
                label={content?.leftButton?.label}
                withIcon={content?.leftButton?.withIcon}
                type={content?.leftButton?.type}
                url={content?.leftButton?.url}
                showMediaHandle={showMediaHandle}
              />
              <DividerIcon />
              <PopButton
                label={content?.rightButton?.label}
                withIcon={content?.rightButton?.withIcon}
                type={content?.rightButton?.type}
                url={content?.rightButton?.url}
                showMediaHandle={showMediaHandle}
              />
            </div>
          </div>
        </div>
      </ContentContainer>
    </div>
  );
}

const PopButton = ({ label, withIcon = false, type = "link", url = "" ,showMediaHandle}) => {
  let defaultClass = "footer-btn";
  if (withIcon) {
    defaultClass += " footer-icon";
  }
  if (type === "link") {
    return (
      <a className={defaultClass} href={url} target="_blank" rel="noreferrer">
        {withIcon ? <PlayIconSmallSVG /> : null}
        {label}
      </a>
    );
  }else if(type === "media"){
    // setShowMedia(true)
    return (
      <button className={defaultClass} onClick={()=>{showMediaHandle(url)}}>
        {withIcon ? <PlayIconSmallSVG /> : null}
        {label}
      </button>
    );
  } else {
    return (
      <button className={defaultClass}>
        {withIcon ? <PlayIconSmallSVG /> : null}
        {label}
      </button>
    );
  }
};

const DividerIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2"
      height="16"
      viewBox="0 0 2 16"
      fill="none">
      <path d="M1 0L1 16" stroke="#D0D0D0" />
    </svg>
  );
};

const ArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      style={{
        filter: "drop-shadow(rgba(0, 0, 0, 0.12) 0px -2px 2px)",
      }}>
      <path d="M8.66025 0L17.3205 12H0L8.66025 0Z" fill="white" />
    </svg>
  );
};

const ContentContainer = styled.div`
  width: 348px;
  position: absolute;
  top: 36px;
  z-index: 999999;

  ${({ direction }) => {
    if (direction === "right") {
      return `right: -24px;`;
    } else {
      return `left: -24px;`;
    }
  }}

  padding: 24px;
  border-radius: 6px;
  background-color: #ffffff;

  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.12) !important;

  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.33s, opacity 0.33s linear;

  ${({ open }) => {
    if (open) {
      return `
      visibility: visible;
      opacity: 1;
      transition-delay: 0s;
      `;
    }
  }}

  .arrow-right {
    position: absolute;
    top: -12px;
    right: 24px;
  }

  .arrow-left {
    position: absolute;
    top: -12px;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 18px;
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .content-title {
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    color: #333333;
  }

  content-sub-title {
    font-size: 16px;
    line-height: 24px;
    color: #333333;
  }

  .footer-continer {
    width: 100%;
  }

  .footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 18px;
  }

  .footer-btn {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #0c6ed1;
  }

  .footer-btn.footer-icon {
    display: flex;
    flex-direction: row;
    column-gap: 12px;
    align-items: center;
  }

  /* media queries */
  @media (max-width: 593px) {
    /* global*/
    width: 300px;
    padding: 15px;

    .arrow-left {
      left: 24px;
    }

    .arrow-right {
      right: 24px;
    }

    .content-title {
      font-size: 18px;
      line-height: 28px;
    }

    .content-sub-title {
      font-size: 14px;
      line-height: 20px;
    }

    .footer-btn {
      font-size: 14px;
      line-height: 20px;
    }
  }

  @media (max-width: 593px) {
    ${({ direction }) => {
      if (direction === "right") {
        return `right: -24px;`;
      } else {
        return `left: -135px;`;
      }
    }}

    .arrow-left {
      left: 135px;
    }

    .arrow-right {
      right: 24px;
    }
  }

  @media (max-width: 429px) {
    width: 250px;

    ${({ direction, responsive }) => {
      if (direction === "right") {
        return `
          right: -${responsive?.right};
          .arrow-right {
            right: ${responsive?.right};
          }
        `;
      } else {
        return `
          left: -${responsive?.left};
          .arrow-left {
            left: ${responsive?.left};
          }
        `;
      }
    }}

    .content-container {
      gap: 8px;
    }

    .content-title {
      font-size: 16px;
      line-height: 24px;
    }

    .content-sub-title {
      font-size: 12px;
      line-height: 16px;
    }

    .footer-btn {
      font-size: 12px;
      line-height: 16px;
    }
  }
`;
