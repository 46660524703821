import React from "react";
import { BsExclamationDiamondFill } from "react-icons/bs";
import { HiOutlineCalendarDays } from "react-icons/hi2";
import { renderStartDate, renderEndDate } from "../../resume";
import ResumeUnderlineIconSVG from "assets/svg/resume-underline-icon";
import ResumeDotDividerIconSVG from "assets/svg/resume-dot-divider-icon";
import { formatDate } from "utils/utils";
import _ from "lodash";


const MilestoneTG = ({milestones,primaryColor,show_image_milestone,tertiaryColor,activeColor,items, active, profile})=>{
    return (
        <>
          {/* Featured  */}
          {items.some(element => element.type === 'featured') &&
          !_.isEmpty(items) && (
            <>
              <div className="w-full">
                <p className="uppercase text-4xs text-resumeTempThreeTextPrimary font-medium tracking-resumeThreeWide">
                  Featured Milestones
                </p>
                {/* content */}
                {items.length > 0 && <div className="w-full grid gap-y-3 mb-6">
                  {items.map((mf, i) => (
                    mf.type === 'featured' &&
                      <div className="w-full mt-3" key={`featured-${i}`}>
                        <div className="w-full grid gap-y-2">
                          <div className="w-full">
                            {/* title */}
                            <div className="w-full flex flex-row justify-between items-center">
                              <div className="font-template-three text-white">
                                <div className="flex flex-row space-x-2 items-center">
                                  { show_image_milestone && <div className="w-5 h-5 rounded-full relative overflow-hidden bg-passwordGray">
                                    {mf.thumbnail && (
                                      <img
                                        alt="milestone"
                                        src={mf.thumbnail.url}
                                        className="w-full h-full object-contain object-center"
                                      />
                                    )}
                                  </div>}
                                  <p className="text-sm font-bold leading-5.4 text-opacity-90">
                                    {mf.title}
                                  </p>
                                </div>
                              </div>
                              <div className="text-5xs font-bold text-white">
                                <p className="uppercase p-1 bg-resumeTempThreeSecondary rounded-sm">
                                  {mf.category.name}
                                </p>
                              </div>
                            </div>
                            {/* date */}
                            <div className="w-full text-white text-opacity-60 mt-1">
                              <p className="text-1xs font-light leading-2 flex flex-row space-x-1 items-center">
                                <HiOutlineCalendarDays className="w-2 h-2" />
                                <span className="mt-px">
                                {formatDate(mf, profile?.dateFormat)} 
                                </span>
                              </p>
                            </div>
                          </div>
                          {/* actor */}
                          {mf.positions.map((p, i) => (
                            <div
                              key={`featured-position-${i}`}
                              className="w-full flex flex-row space-x-2"
                              style={{ color: activeColor }}>
                              <>
                                <p className="text-1xs leading-2 font-medium">
                                  <span>{p.position.name}</span>
                                  {p.position.description && (
                                    <>
                                      <span> - </span>
                                      <span>{p.position.description}</span>
                                    </>
                                  )}
                                  {mf.platform && (
                                    <span>{` (${mf.platform})`}</span>
                                  )}
                                </p>
                                {mf.date_note && (
                                  <>
                                    <span className="text-1xs leading-2">
                                      &#8226;
                                    </span>
                                    <p className="text-1xs leading-2 font-medium">
                                      {mf.date_note}
                                    </p>
                                  </>
                                )}
                              </>
                            </div>
                          ))}
                          {/* description */}
                          <div className="w-full text-white text-opacity-60">
                            <p
                              className="text-1xs font-light leading-2 self-stretch"
                              dangerouslySetInnerHTML={{
                                __html: mf.description,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                  ))}
                </div>
                }
              </div>
            </>
          )}

          {/* Current Milestones */}
          {items.some(element => element.type === 'current') &&
            !_.isEmpty(items) && (
              <>
                <div className="w-full">
                  <p className="uppercase text-4xs text-resumeTempThreeTextPrimary font-medium tracking-resumeThreeWide">
                    Current Milestones
                  </p>
                  {/* content */}
                  {items.length > 0 && <div className="w-full grid gap-y-3 mb-6">
                    {items.map((mf, i) => (
                      mf.type === 'current' &&
                        <div className="w-full mt-3" key={`current-${i}`}>
                          <div className="w-full grid gap-y-2">
                            <div className="w-full">
                              {/* title */}
                              <div className="w-full flex flex-row justify-between items-center">
                                <div className="font-template-three text-white">
                                  <div className="flex flex-row space-x-2 items-center">
                                    { show_image_milestone && <div className="w-5 h-5 rounded-full relative overflow-hidden bg-passwordGray">
                                      {mf.thumbnail && (
                                        <img
                                          alt="milestone"
                                          src={mf.thumbnail.url}
                                          className="w-full h-full object-contain object-center"
                                        />
                                      )}
                                    </div>}
                                    <p className="text-sm font-bold leading-5.4 text-opacity-90">
                                      {mf.title}
                                    </p>
                                  </div>
                                </div>
                                <div className="text-5xs font-bold text-white">
                                  <p className="uppercase p-1 bg-resumeTempThreeSecondary rounded-sm">
                                    {mf.category.name}
                                  </p>
                                </div>
                              </div>
                              {/* date */}
                              <div className="w-full text-white text-opacity-60 mt-1">
                                <p className="text-1xs font-light leading-2 flex flex-row space-x-1 items-center">
                                  <HiOutlineCalendarDays className="w-2 h-2" />
                                  <span className="mt-px">
                                    {formatDate(mf, profile?.dateFormat)} 
                                  </span>
                                </p>
                              </div>
                            </div>
                            {/* actor */}
                            {mf.positions.map((p, i) => (
                              <div
                                key={`featured-position-${i}`}
                                className="w-full flex flex-row space-x-2"
                                style={{ color: activeColor }}>
                                <>
                                  <p className="text-1xs leading-2 font-medium">
                                    <span>{p.position.name}</span>
                                    {p.position.description && (
                                      <>
                                        <span> - </span>
                                        <span>{p.position.description}</span>
                                      </>
                                    )}
                                    {mf.platform && (
                                      <span>{` (${mf.platform})`}</span>
                                    )}
                                  </p>
                                  {mf.date_note && (
                                    <>
                                      <span className="text-1xs leading-2">
                                        &#8226;
                                      </span>
                                      <p className="text-1xs leading-2 font-medium">
                                        {mf.date_note}
                                      </p>
                                    </>
                                  )}
                                </>
                              </div>
                            ))}
                            {/* description */}
                            <div className="w-full text-white text-opacity-60">
                              <p
                                className="text-1xs font-light leading-2 self-stretch"
                                dangerouslySetInnerHTML={{
                                  __html: mf.description,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                    ))}
                  </div>
                }
              </div>
            </>
          )}

          {/* Past Milestones */}
          {items.some(element => element.type === 'past') &&
            !_.isEmpty(items) && (
              <>
                <div className="w-full">
                  <p className="uppercase text-4xs text-resumeTempThreeTextPrimary font-medium tracking-resumeThreeWide">
                    Past Milestones
                  </p>
                  {/* content */}
                  {items.length > 0 && <div className="w-full grid gap-y-3">
                    {items.map((mf, i) => (
                      mf.type === 'past' &&
                        <div className="w-full mt-3" key={`past-${i}`}>
                          <div className="w-full grid gap-y-2">
                            <div className="w-full">
                              {/* title */}
                              <div className="w-full flex flex-row justify-between items-center">
                                <div className="font-template-three text-white">
                                  <div className="flex flex-row space-x-2 items-center">
                                    { show_image_milestone && <div className="w-5 h-5 rounded-full relative overflow-hidden bg-passwordGray">
                                      {mf.thumbnail && (
                                        <img
                                          alt="milestone"
                                          src={mf.thumbnail.url}
                                          className="w-full h-full object-contain object-center"
                                        />
                                      )}
                                    </div>}
                                    <p className="text-sm font-bold leading-5.4 text-opacity-90">
                                      {mf.title}
                                    </p>
                                  </div>
                                </div>
                                <div className="text-5xs font-bold text-white">
                                  <p className="uppercase p-1 bg-resumeTempThreeSecondary rounded-sm">
                                    {mf.category.name}
                                  </p>
                                </div>
                              </div>
                              {/* date */}
                              <div className="w-full text-white text-opacity-60 mt-1">
                                <p className="text-1xs font-light leading-2 flex flex-row space-x-1 items-center">
                                  <HiOutlineCalendarDays className="w-2 h-2" />
                                  <span className="mt-px">
                                    {formatDate(mf, profile?.dateFormat)} 
                                  </span>
                                </p>
                              </div>
                            </div>
                            {/* actor */}
                            {mf.positions.map((p, i) => (
                              <div
                                key={`featured-position-${i}`}
                                className="w-full flex flex-row space-x-2"
                                style={{ color: activeColor }}>
                                <>
                                  <p className="text-1xs leading-2 font-medium">
                                    <span>{p.position.name}</span>
                                    {p.position.description && (
                                      <>
                                        <span> - </span>
                                        <span>{p.position.description}</span>
                                      </>
                                    )}
                                    {mf.platform && (
                                      <span>{` (${mf.platform})`}</span>
                                    )}
                                  </p>
                                  {mf.date_note && (
                                    <>
                                      <span className="text-1xs leading-2">
                                        &#8226;
                                      </span>
                                      <p className="text-1xs leading-2 font-medium">
                                        {mf.date_note}
                                      </p>
                                    </>
                                  )}
                                </>
                              </div>
                            ))}
                            {/* description */}
                            <div className="w-full text-white text-opacity-60">
                              <p
                                className="text-1xs font-light leading-2 self-stretch"
                                dangerouslySetInnerHTML={{
                                  __html: mf.description,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                  ))}
                  </div>
                }
              </div>
            </>
          )}
        </>
    );
}

export default MilestoneTG