const ResumeIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3708_10130)">
        <path
          d="M2 19.0937C2 13.5308 2 7.96784 2 2.40492C2.0155 2.35324 2.03524 2.30203 2.04557 2.24942C2.31336 0.923166 3.39766 0.00752232 4.75116 0.00564311C9.41441 -0.00140391 14.0772 -0.00234351 18.7404 0.00564311C20.3011 0.00846192 21.4949 1.22008 21.4958 2.77982C21.4986 8.09234 21.4968 13.4049 21.4977 18.7178C21.4977 18.9377 21.4756 19.1543 21.423 19.3676C21.1012 20.668 20.0394 21.4944 18.6709 21.4953C15.1718 21.4981 11.6723 21.4967 8.17319 21.4962C7.00151 21.4962 5.82982 21.5089 4.65861 21.4892C3.47612 21.4695 2.44114 20.6544 2.11228 19.5221C2.07 19.3807 2.03711 19.2365 2 19.0937ZM3.49961 10.7373C3.49961 13.3546 3.49961 15.9718 3.49961 18.5891C3.49961 19.5015 3.99055 19.9957 4.89962 19.9957C9.4689 19.9962 14.0382 19.9962 18.6075 19.9957C19.5001 19.9957 19.9962 19.4958 19.9967 18.6009C19.9967 13.3663 19.9967 8.1318 19.9967 2.89727C19.9967 2.00136 19.501 1.5029 18.608 1.5029C14.0307 1.50243 9.4534 1.50196 4.87659 1.5029C3.99995 1.5029 3.50008 2.00653 3.49961 2.88553C3.49961 5.50279 3.49961 8.12006 3.49961 10.7373Z"
          fill="currentColor"
        />
        <path
          d="M14.9927 5.95388C14.9843 6.64402 14.67 7.40463 14.0508 8.0337C13.9347 8.15162 13.9361 8.20376 14.0667 8.30853C14.9711 9.0339 15.4465 9.9829 15.4945 11.14C15.5151 11.6371 15.2116 11.9843 14.7686 12.0007C14.3369 12.0167 14.0151 11.6751 13.9963 11.1809C13.954 10.0745 13.1149 9.14196 12.0518 9.01934C10.8904 8.88592 9.85685 9.58357 9.5698 10.697C9.52705 10.8633 9.50403 11.0329 9.49839 11.2058C9.48336 11.6798 9.15356 12.0167 8.72651 12.0002C8.29382 11.9838 7.98516 11.639 8.0016 11.1616C8.04107 10.0031 8.51228 9.05223 9.41289 8.32215C9.55618 8.20611 9.56181 8.15303 9.4298 8.01819C8.48221 7.05228 8.23463 5.61187 8.79369 4.39837C9.36168 3.16514 10.6128 2.4191 11.9695 2.50413C13.6651 2.61031 14.9913 4.03803 14.9927 5.95341V5.95388ZM11.7229 7.48497C12.7029 7.4953 13.4785 6.7502 13.4922 5.7857C13.5063 4.80663 12.7419 4.01266 11.7727 3.99998C10.7997 3.98682 10.0109 4.76106 10.0039 5.73496C9.99685 6.70745 10.7509 7.4751 11.7229 7.48544V7.48497Z"
          fill="currentColor"
        />
        <path
          d="M11.7474 13.5036C13.2076 13.5036 14.6682 13.5036 16.1283 13.5036C16.2932 13.5036 16.4553 13.5074 16.6052 13.5924C16.8777 13.7465 17.0374 14.0617 16.9904 14.361C16.9416 14.6711 16.7099 14.9163 16.3966 14.9788C16.2979 14.9985 16.195 15.0032 16.0945 15.0032C13.1972 15.0046 10.3 15.0041 7.4027 15.0046C7.25378 15.0046 7.10579 15.0004 6.96579 14.9417C6.6421 14.8064 6.44948 14.4634 6.50726 14.1275C6.57069 13.7596 6.87089 13.5069 7.27257 13.5059C8.65473 13.5017 10.0369 13.5041 11.4195 13.5041C11.529 13.5041 11.638 13.5041 11.7474 13.5041V13.5036Z"
          fill="currentColor"
        />
        <path
          d="M11.7601 16.504C13.2438 16.504 14.7279 16.5007 16.2115 16.5058C16.7729 16.5077 17.1375 17.0095 16.9486 17.5079C16.8537 17.7579 16.6738 17.9232 16.4074 17.9763C16.3012 17.9975 16.1908 18.0031 16.0818 18.0031C13.1926 18.0045 10.3028 18.004 7.41353 18.005C7.2646 18.005 7.11614 18.0026 6.97567 17.9467C6.65245 17.8185 6.45419 17.4755 6.50634 17.1387C6.56225 16.7783 6.85822 16.5101 7.23876 16.5077C8.2695 16.5007 9.30025 16.5044 10.3315 16.5044C10.8078 16.5044 11.2842 16.5044 11.7606 16.5044L11.7601 16.504Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3708_10130">
          <rect
            width="19.4972"
            height="21.5"
            fill="currentColor"
            transform="translate(2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ResumeIconSVG;
