import React from "react";
import { ProfilePlaceholder, ProfilePlaceholderSmall } from "constants/images";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDialogHook } from "utils/customhooks";
import ImageModal from "./milestones/image-modal";

export default function ProfilePhoto({ 
  image = ProfilePlaceholder,
  source,
  className= 'w-24.5 h-24.5 3md:w-40 3md:h-40 bg-white rounded-md absolute -top-12 3md:-top-20 mx-auto 2sm:mx-0 2sm:left-11.5 3md:left-25 left-0 right-0 ring-8 ring-white cursor-pointer'}) {
  const imageGalleryModal = useDialogHook(ImageModal);
  const handleImageGallery = (e) => {
    e.stopPropagation();
    imageGalleryModal({source, viewUrl:image, isProfile:true });
  };

  return (
    <div
      onClick={handleImageGallery}
      className={`${className}`}>
      <LazyLoadImage
        alt="milestone-page-cover-page"
        src={image}
        placeholderSrc={ProfilePlaceholderSmall}
        effect="blur"
        wrapperProps={{
          // If you need to, you can tweak the effect transition using the wrapper style.
          style: { width: "100%" },
        }}
        className="w-full h-full rounded-md object-cover"
      />
    </div>
  );
}
