import React, { useEffect, useState } from "react";
import { FiX } from "react-icons/fi";

const ImagePreview = ({ images = [], onRemove = () => { } }) => {
  const [previews, setPreviews] = useState([]);

  const FileNameEllipsis = ({ filename, maxChars }) => {
    if (filename.length <= maxChars) {
      return <span>{filename}</span>;
    } else {
      const ellipsis = "...";
      const truncatedFilename =
        filename.substring(0, maxChars - ellipsis.length) + ellipsis;
      return truncatedFilename;
    }
  };

  useEffect(() => {
    setPreviews([]);

    for (let i = 0; i < images.length; i++) {
      if (images[i].hasOwnProperty('media')) {
        setPreviews((prev) => [
          ...prev,
          {
            url: images[i].media.url ? images[i].media.url : images[i].url,
            name: FileNameEllipsis({ filename: images[i].media.file_name, maxChars: 20 }),
            extension: images[i].media.file_extension,
          },
        ]);
      } else {
        const fileName = images[i].name.split(".");
        const reader = new FileReader();

        reader.readAsDataURL(images[i]);

        reader.onload = () => {
          const result = reader.result?.toString() || "";
          if (result) {
            setPreviews((prev) => [
              ...prev,
              {
                url: result,
                name: FileNameEllipsis({ filename: fileName[0], maxChars: 20 }),
                extension: fileName[1],
              },
            ]);
          }
        };
      }
    }
  }, [images]);

  return (
    <div className="w-full grid 4sm:grid-cols-2 gap-x-4 grap-y-3 mb-4 mt-2">
      {previews.map((prev, index) => (
        <div
          key={index}
          className="flex flex-row items-center bg-modalGray mb-2 p-1 rounded-lg"
        >
          <img src={prev.url} className="w-14 h-14 mr-4" alt="preview" />
          <div className="flex-1 flex-row items-center truncate">
            <p>
              {prev.name}
              {"."}
              {prev.extension}
            </p>
          </div>
          <div className="px-2">
            <FiX className="cursor-pointer" onClick={() => onRemove(index)} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ImagePreview;
