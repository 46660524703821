const NoProfileFoundIconSvg = ({ className = "" }) => {
  return (
    <svg
      width="90"
      height="90"
      viewBox="0 0 90 90"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M54.5617 90.0009H5.78887C5.76249 89.7495 5.59544 89.6985 5.37211 89.6457C2.68518 88.9933 1.0076 87.3158 0.35521 84.6288C0.300698 84.4055 0.25146 84.2384 0 84.2121V35.4375C0.508196 35.3637 0.379828 34.8889 0.474786 34.6023C1.42612 31.6955 3.72091 29.9986 6.77361 29.9986C22.4433 29.9986 38.1112 29.9986 53.7809 29.9986C57.0587 29.9986 60.0006 32.9405 60.0006 36.2183C60.0006 51.8 59.9619 67.3818 60.0375 82.9636C60.0516 85.8773 58.2052 88.8316 55.149 89.607C54.9081 89.6686 54.5915 89.6229 54.5634 90.0009H54.5617ZM30.0715 71.0922C32.878 71.0922 35.6828 71.108 38.4893 71.0851C40.0332 71.0728 41.1393 70.0529 41.3433 68.5371C41.9218 64.2201 39.0696 60.5115 34.7156 59.9945C32.0973 59.6832 29.4613 59.8943 26.8324 59.8732C22.9603 59.8433 18.4973 62.5056 18.8877 68.0096C19.0266 69.9826 20.0166 71.0851 22.0054 71.0922C24.6941 71.101 27.3828 71.0939 30.0715 71.0922ZM30.1717 44.9139C27.0417 44.8963 24.5394 47.3705 24.5271 50.4935C24.5148 53.6236 26.9942 56.1329 30.1084 56.1399C33.1928 56.147 35.7549 53.5902 35.7443 50.5146C35.7355 47.4654 33.2139 44.9315 30.1717 44.9139Z"
        fill="#CACACA"
      />
      <path
        d="M54.737 -0.000854492C54.876 0.298085 55.1942 0.21192 55.4211 0.285775C58.1555 1.17028 59.9755 3.49497 60.0195 6.35776C60.0705 9.57223 60.0757 12.7885 60.023 16.0029C59.9632 19.6764 57.1268 22.4389 53.4358 22.4477C45.5139 22.4671 37.592 22.453 29.6701 22.453C22.1861 22.453 14.7038 22.4547 7.2198 22.453C2.90277 22.453 0.17715 19.7115 0.175391 15.3752C0.175391 12.5405 0.171874 9.70411 0.175391 6.86947C0.182425 3.41233 2.05343 1.02257 5.42089 0.148615C5.49299 0.129272 5.54926 0.050141 5.61432 -0.000854492H54.737Z"
        fill="#DADADA"
      />
      <path
        d="M89.9993 78.4214C89.7144 78.6834 89.7531 79.0633 89.6757 79.3903C88.5697 84.0679 84.0487 88.3409 77.7762 87.9189C72.8701 87.5883 68.4599 83.5122 67.6879 78.3863C66.7313 72.0435 71.2752 66.2388 77.581 65.5706C83.646 64.927 88.9706 69.288 89.8305 74.8834C89.8534 75.0276 89.8569 75.1753 89.9975 75.265V78.4232L89.9993 78.4214Z"
        fill="#DADADA"
      />
      <path
        d="M90.0005 20.3499C89.6505 20.9091 89.4026 21.5315 88.8241 21.9342C87.7022 22.7132 86.2514 22.6253 85.2192 21.6265C84.0446 20.4905 82.8892 19.3317 81.755 18.1571C81.4033 17.7931 81.1308 17.7192 80.6349 17.9267C74.1549 20.647 67.253 15.8306 67.5607 8.82312C67.7418 4.68721 70.7576 1.18611 74.999 0.18906C75.1573 0.152132 75.3613 0.220712 75.4404 -0.000854492H78.4227C78.6338 0.30336 78.9942 0.23478 79.2809 0.312152C84.6055 1.71892 87.6283 7.41635 85.6588 12.5651C85.279 13.5587 85.4548 14.0757 86.1776 14.6858C86.844 15.2486 87.4278 15.9115 88.0451 16.5322C88.7801 17.2708 89.5908 17.9478 90.0022 18.9484V20.3516L90.0005 20.3499ZM73.1597 9.31198C73.1403 11.3571 74.8196 13.0821 76.856 13.1103C78.8799 13.1366 80.6068 11.4485 80.6525 9.3999C80.6964 7.38822 78.9802 5.63678 76.9439 5.61744C74.8882 5.59634 73.1808 7.26512 73.1614 9.31374L73.1597 9.31198Z"
        fill="#DADADA"
      />
      <path
        d="M90.0005 33.5085C89.2461 35.172 87.9009 35.6274 86.16 35.6081C81.0745 35.5483 75.989 35.5852 70.9035 35.5887C70.2529 35.5887 69.6199 35.5219 69.0361 35.2159C67.9493 34.6479 67.3374 33.352 67.5994 32.1914C67.8878 30.9165 68.92 29.9898 70.1966 29.9845C75.8958 29.9652 81.595 29.9687 87.2942 29.9792C88.7309 29.981 89.4624 30.9323 90.0005 32.1052V33.5085Z"
        fill="#EDEDED"
      />
      <path
        d="M90.0005 44.7362C89.2461 46.4015 87.9009 46.8552 86.16 46.8359C81.0745 46.7761 75.989 46.813 70.9035 46.8165C70.2529 46.8165 69.6199 46.7497 69.0361 46.4437C67.9493 45.8757 67.3374 44.5797 67.5994 43.4192C67.8878 42.1443 68.92 41.2176 70.1966 41.2123C75.8958 41.1929 81.595 41.1965 87.2942 41.207C88.7309 41.2088 89.4624 42.1601 90.0005 43.333V44.7362Z"
        fill="#EDEDED"
      />
      <path
        d="M90.0005 55.9641C89.2461 57.6294 87.9009 58.083 86.16 58.0637C81.0745 58.0039 75.989 58.0408 70.9035 58.0444C70.2529 58.0444 69.6199 57.9775 69.0361 57.6716C67.9493 57.1036 67.3374 55.8076 67.5994 54.647C67.8878 53.3721 68.92 52.4454 70.1966 52.4401C75.8958 52.4208 81.595 52.4243 87.2942 52.4349C88.7309 52.4366 89.4624 53.3879 90.0005 54.5608V55.9641Z"
        fill="#EDEDED"
      />
      <path
        d="M30.0708 71.0921C27.3821 71.0921 24.6934 71.0992 22.0048 71.0921C20.0159 71.0851 19.0259 69.9825 18.887 68.0095C18.4984 62.5055 22.9596 59.8432 26.8317 59.8731C29.4589 59.8925 32.0966 59.6832 34.7149 59.9944C39.0689 60.5114 41.9211 64.2218 41.3426 68.5371C41.1404 70.0529 40.0325 71.0728 38.4886 71.0851C35.6839 71.1079 32.8773 71.0921 30.0708 71.0921Z"
        fill="white"
      />
      <path
        d="M30.1721 44.9139C33.2142 44.9297 35.7358 47.4636 35.7446 50.5146C35.7534 53.5901 33.1931 56.1469 30.1088 56.1399C26.9963 56.1329 24.5151 53.6235 24.5274 50.4935C24.5397 47.3704 27.0438 44.8963 30.1721 44.9139Z"
        fill="white"
      />
    </svg>
  );
};

export default NoProfileFoundIconSvg;
