import React from "react";
import Modal from "components/global/modal";
import CloseIconSVG from "assets/svg/close-icon";
import Button from "components/button/button";

import ProfileDefault from "assets/images/profile_default.png";
import { toast } from "react-hot-toast";
import { useState } from "react";
import LoadingIndicator from "assets/svg/loading-indicator";

export default function InvitationNotificationModal({
  notification,
  hide,
  success = true,
  ...props
}) {
  const { http } = global.services;
  const { from, user_access } = notification.user_access_notification;
  const [loading, setLoading] = useState(false);
  const [loadingDecline, setLoadingDecline] = useState(false);

  const onResponse = async (status) => {
    try {
      if (status === "declined") {
        setLoadingDecline(true);
      } else {
        setLoading(true);
      }
      const { data } = await http.put(`public/user-accesses/${user_access.id}/response`, {
        status,
        notification: notification.id,
      });
      if (status === "declined") {
        hide({ success: false, data: data.data });
      } else {
        hide({ success: true, data: data.data });
      }
    } catch (err) {
      let error = err.data
      toast.error(`Something went wrong. ${error?.message}`);
    } finally {
      if (status === "declined") {
        setLoadingDecline(false);
      } else {
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      width="w-19/20 7sm:w-460"
      contentClassName="rounded-lg overflow-auto custom-scroll"
      wrapperClassName="overflow-visible"
      footerClass="border-t border-gray"
    >
      <div className="w-full">
        <div className="w-full p-6">
          {/* header */}
          <div className="w-full flex justify-between items-center text-center">
            <p className="w-full text-lg text-lightBlack leading-5.5 font-semibold">
              New Profile Invitation
            </p>
            <button onClick={() => hide({})}>
              <CloseIconSVG className="w-4 h-4" />
            </button>
          </div>
          {/* end header */}
          <div className="w-full mt-6 border border-notification-border-gray rounded-xl">
            <div className="w-full py-6 px-7">
              <div className="w-full">
                <div className="w-20 h-20 rounded-md mx-auto">
                  <img
                    src={
                      from?.profile?.profile_image_url ||
                      ProfileDefault
                    }
                    alt="milestone-profile"
                    className="rounded-md w-20 h-20"
                  />
                </div>
              </div>
              {/*  */}
              <div className="w-full mt-6">
                <p className="text-xl text-center text-lightBlack leading-7.5">
                  <span className="font-semibold">
                    {from?.first_name + " " + from?.last_name}
                  </span>{" "}
                  has invited you to be {user_access?.permission?.name} on the
                  profile{" "}
                  <span className="font-semibold text-primary">
                    {from?.username}
                  </span>
                </p>
                <p className="w-full mt-3 text-base text-lightBlack text-center leading-6.5">
                  Would you like to join and be an{" "}
                  {user_access?.permission?.name} to this profile?{" "}
                </p>
              </div>
            </div>
          </div>
          {/* footer */}
          <div className="w-full mt-6">
            <div className="w-full flex items-center space-x-2">
              <Button
                buttonWidth="100%"
                buttonName="Decline"
                buttonTextClass="text-sm text-lightBlack font-semibold leading-4.4 flex items-center justify-center"
                buttonRadius="6px"
                buttonClass="relative py-4 bg-thBg"
                disabled={loadingDecline}
                onClick={() => onResponse("declined")}
                loadingIcon={
                  loadingDecline ? (
                    <LoadingIndicator className="text-white w-4 h-4 ml-2" />
                  ) : null
                }
              />
              <Button
                buttonWidth="100%"
                buttonName="Accept"
                buttonTextClass="text-sm text-white font-semibold leading-4.4 flex items-center justify-center"
                buttonRadius="6px"
                buttonClass="relative py-4 bg-primary"
                onClick={() => onResponse("accepted")}
                disabled={loading}
                loadingIcon={
                  loading ? (
                    <LoadingIndicator className="text-white w-4 h-4 ml-2" />
                  ) : null
                }
              />
            </div>
          </div>
        </div>
        {/* end content */}
      </div>
    </Modal>
  );
}
