import {
	REFRESH_MEDIA_STYPES,
	SET_MEDIA_TYPES,
} from './types'

const initialState = {
	types: [],
	refresh: false,
	initialize: true
};

const reducer = (state = initialState, {type, payload}) => {

	switch (type) {

		case REFRESH_MEDIA_STYPES:
			return {
				...state,
				refresh: true,
				initialize: true,
				types: [],
			}
		
		case SET_MEDIA_TYPES:
			return {
				...state,
				refresh: false,
				initialize: false,
				types: [...state.types, ...payload]
			}

		default:
			return state;

	}

}

export default reducer