import React from "react";
import ItemMilestone from "./item-milestone";

const MilestoneTG = ({milestones,primaryColor,show_image_milestone,tertiaryColor,items,profile})=>{
    return (
        <>
          <ItemMilestone
          {...{
            primaryColor,
            tertiaryColor ,
            items,
            profile,
            show_image_milestone
          }}
          headerType = 'featured'
          />
          <ItemMilestone
          {...{
            primaryColor,
            tertiaryColor ,
            items,
            profile,
            show_image_milestone
          }}
          headerType = 'current'
          />
        <ItemMilestone
          {...{
            primaryColor,
            tertiaryColor ,
            items,
            profile,
            show_image_milestone
          }}
          headerType = 'past'
          />
        </>
    );
}

export default MilestoneTG