import React, { useState, useMemo } from "react";
import Modal from "components/global/modal";
import ModalHeader from "components/global/modal-header";
import Button from "components/button/button";
import { PLANS } from "utils/constant";
import _ from "lodash";
import LoadingIndicator from "assets/svg/loading-indicator";
import moment from "moment";

export default function ChangePlanModal({
  hide,
  subscription,
  cancelPlan = () => {},
  changePlan = () => {},
  ...props
}) {
  const [loading, setLoading] = useState(false);
  const [loadingContinue, setLoadingContinue] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});

  const disabledBtnContinue = useMemo(() => {
    if (
      subscription.term === selectedPlan.term &&
      subscription.ends_at === null
    ) {
      return true;
    }

    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlan]);

  const onSelectPlan = (plan) => {
    if(subscription?.term.toLowerCase() === plan.term &&  moment(subscription?.ends_at) > moment()) return;
    setSelectedPlan(plan);
  };

  const onContinue = () => {
    setLoadingContinue(true);
    let priceId =
      subscription.provider_type === "paypal"
        ? selectedPlan.plan_id
        : selectedPlan.price_id;

    changePlan(priceId)
      .then((response) => {
        setLoadingContinue(false);
        if (response) {
          hide({ response, type: "change" });
        } else {
          hide();
        }
      })
      .catch(() => setLoadingContinue(false));
  };

  const onCancel = () => {
    setLoading(true);

    cancelPlan()
      .then((response) => {
        setLoading(false);
        hide({ response, type: "cancel" });
      })
      .catch(() => setLoading(false));
  };

  const Footer = () => {
    return (
      <>
        <div className="w-full flex items-center justify-start 1sm:justify-end space-x-2">
          <Button
            buttonName="Cancel"
            buttonClass="bg-thBg py-4 px-8 hover:bg-gray"
            buttonTextClass="text-black text-sm font-semibold"
            onClick={() => hide()}
          />
          <Button
            key="save-btn-category"
            buttonName="Continue"
            buttonClass="bg-primary py-4 px-8 hover:bg-darkPrimary"
            buttonTextClass="text-white text-sm font-semibold flex items-center"
            disabled={_.isEmpty(selectedPlan) || disabledBtnContinue}
            onClick={onContinue}
            loadingIcon={
              loadingContinue ? (
                <LoadingIndicator className="text-white w-4 h-4" />
              ) : null
            }
          />
        </div>
      </>
    );
  };

  return (
    <Modal
      footer={<Footer />}
      width="w-19/20 md:w-18/20 2md:w-17/20 3md:w-15/20 4md:w-13/20 1xl:w-11/20"
      contentClassName="rounded-lg overflow-auto custom-scroll"
      wrapperClassName="overflow-visible"
      footerClass="border-t border-gray">
      <div className="w-full">
        {/* header */}
        <ModalHeader
          title="Change Plan"
          hide={hide}
          showPopInfo
          popInfoType="change_plan"
        />
        <div className="p-8 px-1 2md:px-12">
          {PLANS.filter((p) => p.plan !== "Free Account").map((plan, index) => (
            <div
              key={index}
              className={`border-2 rounded-md p-3 2sm:p-6 cursor-pointer mb-4 
                ${
                  selectedPlan.term === plan.term
                    ? "border-primary bg-planBg"
                    : "border-gray"
                }`}
              onClick={() => onSelectPlan(plan)}>
              <div className="flex flex-col 2sm:flex-row items-center">
                <div className="w-64 text-center pr-8 pl-2">
                  {subscription?.term.toLowerCase() === plan.term &&
                    subscription.ends_at === null && (
                      <p className="text-xs">Current Plan:</p>
                    )}
                  <h1 className="font-bold text-lg">Premium</h1>
                  {plan.term === "month" && (
                    <h1 className="font-bold text-lg">Monthly Plan</h1>
                  )}
                  {plan.term === "year" && (
                    <h1 className="font-bold text-lg">Yearly Plan</h1>
                  )}
                  {plan.term === "lifetime" && (
                    <h1 className="font-bold text-lg">Lifetime Plan</h1>
                  )}
                </div>
                <div className="2sm:border-l border-gray px-6">
                  <p className="text-sm">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                  {/* put some valid https link */}
                  <a href="/" className="text-sm underline font-semibold">
                    Learn more
                  </a>
                </div>
                <div className="shrink-0 text-center">
                  <h1 className="text-xl text-primary font-bold">
                    ${plan.unit_amount}
                    {plan.term === "month" && (
                      <span className="text-xs">/monthly</span>
                    )}
                    {plan.term === "year" && (
                      <span className="text-xs">/yearly</span>
                    )}
                  </h1>
                  {subscription?.term.toLowerCase() === plan.term &&
                    moment(subscription?.ends_at) > moment() && (
                      <Button
                        key="save-btn-category"
                        buttonName="Cancel Plan"
                        buttonClass="border-2 border-dark py-2 px-6 hover:border-dark"
                        buttonTextClass="text-dark text-xs font-semibold flex items-center"
                        onClick={onCancel}
                        disabled={loading}
                        loadingIcon={
                          loading ? (
                            <LoadingIndicator className="text-primary w-4 h-4 ml-2" />
                          ) : null
                        }
                      />
                    )}
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* end content */}
      </div>
    </Modal>
  );
}
