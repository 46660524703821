import React from "react";
import _ from "lodash";
import { renderStartDate, renderEndDate } from "../../resume";
import { HiOutlineCalendarDays } from "react-icons/hi2";
import { BsExclamationDiamondFill } from "react-icons/bs";

export default function TemplateOneFullPageTwo({
  milestones = {},
  secondaryColor,
  tertiaryColor,
  show_image_milestone
}) {
  return (
    <div className="w-full">
      <div style={{ minHeight: "1118px" }} className="h-full overflow-hidden">
        {/* featured milestones */}
        {milestones.hasOwnProperty("featured") &&
          !_.isEmpty(milestones.featured) && (
            <div className="w-full flex flex-col space-x-2 mb-4">
              <div
                className="px-2 py-1.5 rounded-sm mb-4"
                style={{ backgroundColor: secondaryColor }}>
                <p className="text-sm font-bold text-resumeTempFourTextPrimary tracking-resumeOne">
                  Featured Milestones
                </p>
              </div>
              {milestones.featured.map((m, i) => (
                <div key={`featured-${i}`} className="w-full flex flex-row space-x-2.6 mb-2.5">
                { show_image_milestone && <div className="h-23 w-23 bg-default rounded relative overflow-hidden">
                  {Boolean(m.is_adult_content) && (
                    <div className="absolute matured-content-blured-small">
                      <button className="w-full flex justify-center">
                        <BsExclamationDiamondFill className="text-1xl cursor-pointer" />
                      </button>
                      <p className="text-1xs leading-6 font-bold">
                        Matured Content
                      </p>
                    </div>  
                  )}
                    {m.thumbnail && (
                      <img
                        alt="milestones"
                        src={m.thumbnail.url}
                        className="w-full h-full object-contain object-center"
                      />
                    )}
                  </div>}
                  <div style={{ width: "calc(100% - 111px)" }}>
                    <div className="flex justify-between items-center space-x-1.5">
                      <div className="flex flex-row items-center space-x-1">
                        {/** title */}
                        <span className="text-sm font-semibold text-resumeTempFourTextPrimary ">
                          {m.title}
                        </span>
                        {/** category */}
                        <span
                          className="font-bold uppercase rounded-sm border text-6xs"
                          style={{
                            color: tertiaryColor,
                            padding: "2px 6px 3px 6px",
                          }}>
                          {m?.category?.name}
                        </span>
                      </div>
                      {/** dates */}
                      <div className="flex flex-row flex-wrap text-4xs text-darkGray gap-1">
                        <HiOutlineCalendarDays className="w-4 h-4" />
                        {renderStartDate(m)}
                        {renderEndDate(m)}
                      </div>
                    </div>
                    <div className="mb-2.6">
                      {/** positions */}
                      {m.positions.map((p, i) => (
                        <div
                          key={`featured-position-${i}`}
                          className="text-6xs text-darkerGray font-semibold">
                          <span>
                            <span>{p.position.name}</span>
                            {p.position.description && (
                              <>
                                <span> - </span>
                                <span>{p.position.description}</span>
                              </>
                            )}
                            {m.platform && <span>{` (${m.platform})`}</span>}
                          </span>
                          {m.date_note && (
                            <>
                              <span> | </span>
                              <span>{`(${m.date_note})`}</span>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                    {/** description */}
                    <p
                      className="text-4xs text-darkerGray tracking-resume leading-4.5"
                      dangerouslySetInnerHTML={{ __html: m.description }}></p>
                  </div>
                </div>
              ))}
            </div>
          )}
        {/* current milestones */}
        {milestones.hasOwnProperty("current") &&
          !_.isEmpty(milestones.current) && (
            <div className="w-full flex flex-col space-x-2 mb-4">
              <div
                className="px-2 py-1.5 rounded-sm mb-4"
                style={{ backgroundColor: secondaryColor }}>
                <p className="text-sm font-bold text-resumeTempFourTextPrimary tracking-resumeOne">
                  Current Milestones
                </p>
              </div>
              {milestones.current.map((mc, i) => (
                <div key={`current-${i}`} className="w-full flex flex-row space-x-2.6 mb-2.5">
                { show_image_milestone && <div className="h-23 w-23 bg-default rounded relative overflow-hidden">
                  {Boolean(mc.is_adult_content) && (
                    <div className="absolute matured-content-blured-small">
                      <button className="w-full flex justify-center">
                        <BsExclamationDiamondFill className="text-1xl cursor-pointer" />
                      </button>
                      <p className="text-1xs leading-6 font-bold">
                        Matured Content
                      </p>
                    </div>  
                  )}
                    {mc.thumbnail && (
                      <img
                        alt="milestones"
                        src={mc.thumbnail.url}
                        className="w-full h-full object-contain object-center"
                      />
                    )}
                  </div>}
                  <div style={{ width: "calc(100% - 111px)" }}>
                    <div className="flex justify-between items-center space-x-1.5">
                      <div className="flex flex-row items-center space-x-1">
                        {/** title */}
                        <span className="text-sm font-semibold text-resumeTempFourTextPrimary ">
                          {mc.title}
                        </span>
                        {/** category */}
                        <span
                          className="font-bold uppercase rounded-sm border text-6xs"
                          style={{
                            color: tertiaryColor,
                            padding: "2px 6px 3px 6px",
                          }}>
                          {mc?.category?.name}
                        </span>
                      </div>
                      {/** dates */}
                      <div className="flex flex-row flex-wrap text-4xs text-darkGray gap-1">
                        <HiOutlineCalendarDays className="w-4 h-4" />
                        {renderStartDate(mc)}
                        {renderEndDate(mc)}
                      </div>
                    </div>
                    {/** positions */}
                    <div className="mb-2.6">
                      {mc.positions.map((p, i) => (
                        <div
                          key={`current-position-${i}`}
                          className="text-6xs text-darkerGray font-semibold">
                          <span>
                            <span>{p.position.name}</span>
                            {p.position.description && (
                              <>
                                <span> - </span>
                                <span>{p.position.description}</span>
                              </>
                            )}
                            {mc.platform && <span>{` (${mc.platform})`}</span>}
                          </span>
                          {mc.date_note && (
                            <>
                              <span> | </span>
                              <span>{`(${mc.date_note})`}</span>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                    <p
                      className="text-4xs text-darkerGray tracking-resume leading-4.5"
                      dangerouslySetInnerHTML={{ __html: mc.description }}></p>
                  </div>
                </div>
              ))}
            </div>
          )}
        {/* past milestones */}
        {milestones.hasOwnProperty("past") && !_.isEmpty(milestones.past) && (
          <div className="w-full flex flex-col space-x-2 mb-4">
            <div
              className="px-2 py-1.5 rounded-sm mb-4"
              style={{ backgroundColor: secondaryColor }}>
              <p className="text-sm font-bold text-resumeTempFourTextPrimary tracking-resumeOne">
                Past Milestones
              </p>
            </div>
            {milestones.past.map((mc, i) => (
              <div key={`past-${i}`} className="w-full flex flex-row space-x-2.6 mb-2.5">
              { show_image_milestone && <div className="h-23 w-23 bg-default rounded relative overflow-hidden">
                {Boolean(mc.is_adult_content) && (
                  <div className="absolute matured-content-blured-small">
                    <button className="w-full flex justify-center">
                      <BsExclamationDiamondFill className="text-1xl cursor-pointer" />
                    </button>
                    <p className="text-1xs leading-6 font-bold">
                      Matured Content
                    </p>
                  </div>  
                )}
                  {mc.thumbnail && (
                    <img
                      alt="milestones"
                      src={mc.thumbnail.url}
                      className="w-full h-full object-contain object-center"
                    />
                  )}
                </div>}
                <div style={{ width: "calc(100% - 111px)" }}>
                  <div className="flex justify-between items-center space-x-1.5">
                    <div className="flex flex-row items-center space-x-1">
                      {/** title */}
                      <span className="text-sm font-semibold text-resumeTempFourTextPrimary ">
                        {mc.title}
                      </span>
                      {/** category */}
                      <span
                        className="font-bold uppercase rounded-sm border text-6xs"
                        style={{
                          color: tertiaryColor,
                          padding: "2px 6px 3px 6px",
                        }}>
                        {mc?.category?.name}
                      </span>
                    </div>
                    {/** dates */}
                    <div className="flex flex-row flex-wrap text-4xs text-darkGray gap-1">
                      <HiOutlineCalendarDays className="w-4 h-4" />
                      {renderStartDate(mc)}
                      {renderEndDate(mc)}
                    </div>
                  </div>
                  {/** positions */}
                  <div className="mb-2.6">
                    {mc.positions.map((p, i) => (
                      <div
                        key={`past-position-${i}`}
                        className="text-6xs text-darkerGray font-semibold">
                        <span>
                          <span>{p.position.name}</span>
                          {p.position.description && (
                            <>
                              <span> - </span>
                              <span>{p.position.description}</span>
                            </>
                          )}
                          {mc.platform && <span>{` (${mc.platform})`}</span>}
                        </span>
                        {mc.date_note && (
                          <>
                            <span> | </span>
                            <span>{`(${mc.date_note})`}</span>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                  {/** description */}
                  <p
                    className="text-4xs text-darkerGray tracking-resume leading-4.5"
                    dangerouslySetInnerHTML={{ __html: mc.description }}></p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {/* ========== footer ======== */}
      <div
        className="w-full h-8 border-t px-2"
        style={{ borderColor: "#DAE4EF" }}>
        <div className="w-full h-full flex flex-row justify-between items-center">
          <p className="font-medium text-6xs text-darkerGray">Page 2</p>
          <div className="flex flex-start gap-1.5">
            <button className="w-2 h-2 rounded-full bg-pageInactive" />
            <button className="w-2 h-2 rounded-full bg-primary" />
          </div>
        </div>
      </div>
    </div>
  );
}
