import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "./actions";

export const useMessages = () => {
  const {
    initialize,
    messages = [],
    selected,
    conversations = [],
    unread = [],
  } = useSelector((state) => state.messages);

  const dispatch = useDispatch();

  const setActiveThread = useCallback(
    (payload) => dispatch(actions.setActiveThread(payload)),
    [dispatch]
  );

  const setMessages = useCallback(
    (payload) => dispatch(actions.setMessages(payload)),
    [dispatch]
  );
  const setBlockMessages = useCallback(
    (payload) => dispatch(actions.setBlockMessages(payload)),
    [dispatch]
  );

  const addMessage = useCallback(
    (payload) => dispatch(actions.addMessage(payload)),
    [dispatch]
  );

  const setConversations = useCallback(
    (payload) => dispatch(actions.setConversations(payload)),
    [dispatch]
  );

  const addConversation = useCallback(
    (payload) => dispatch(actions.addConversation(payload)),
    [dispatch]
  );

  const setPushMessage = useCallback(
    (payload) => dispatch(actions.setPushMessage(payload)),
    [dispatch]
  );

  const setUnreadMessages = useCallback(
    (payload) => dispatch(actions.setUnreadMessages(payload)),
    [dispatch]
  );

  return {
    initialize,
    messages,
    selected,
    conversations,
    unread,
    setActiveThread,
    setMessages,
    addMessage,
    setConversations,
    addConversation,
    setPushMessage,
    setUnreadMessages,
    setBlockMessages
  };
};
