import React, { useEffect, useRef, useState } from "react";
import { maxUploadSize } from "utils/constant";
import { useDialogHook } from "utils/customhooks";
import UploadSizeModal from "./upload-size-modal";
import AlertIconSVG from "assets/svg/alert-info-icon-sm-svg";

const FileUploadComponent = (
  {
    id = "media-upload",
    className = "absolute top-1.5 right-2 bg-modalGray py-3.5 px-5 rounded-md text-sm leading-4 font-semibold text-lightBlack",
    outsideLabel = "",
    outsideLabelClass = "text-sm font-bold text-darkerGray leading-4",
    optional = false,
    formContainer = "form-input-container",
    image_name = "",
    showPreview = true,
    preview = null,
    setPreview = () => { },
    label = "",
    placeholder = "",
    icon = null,
    onUpload = () => { },
    onEdit = () => { },
    cropOnload = false,
    errorMessage = null,
    ...props
  },
  ref
) => {
  const [fileName, setFileName] = useState(image_name);
  const [type, setType] = useState(null);
  const [error, setError] = useState(null);
  const uploadSizeModal = useDialogHook(UploadSizeModal)

  const showUploadAlert = () => {
    uploadSizeModal({}, (callback) => {
      console.log('callback', callback)
    })
  }

  const onUploadImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    console.log("FILE => ", file);
    reader.addEventListener(
      "load",
      function () {
        // if (file.type === 'application/pdf') {
        //   if (file.size > maxUploadSize) {
        //     return;
        //   }
        // }
        if (file.size > maxUploadSize) {
          setError("The file is too large. Maximum size is 50MB.");
          return;
        }

        // convert image file to base64 string
        const result = reader.result?.toString() || "";
        setPreview(result);
        setFileName(file.name);
        setType(file.type);
        if (cropOnload) {
          onEdit(result, file.type);
          console.log("reader.result", result);
        }
      },
      false
    );

    // !!!
    if (file) {
      if (file.size > maxUploadSize) {
        setError("The file is too large. Maximum size is 50MB.");
        return showUploadAlert()
      }

      setError(null);
      onUpload(file);
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (errorMessage) {
      setError((prev) => errorMessage)
    }
  }, [errorMessage])

  return (
    <div className={formContainer}>
      {outsideLabel && (
        <label htmlFor={outsideLabel} className={outsideLabelClass}>
          {outsideLabel}
          {optional && <span className="font-normal">{" (optional)"}</span>}
        </label>
      )}
      <div className={`w-full 4sm:flex 4sm:flex-row ${outsideLabel && "mt-2"}`}>
        {/* ---- Left Section (Preview) ---- */}
        {showPreview && (
          <button
            className="w-43 4sm:w-54 h-43 bg-passwordGray rounded-md mr-5 cursor-pointer"
            onClick={(e) => {
              if(!!preview) {
                onEdit(preview, type)
              } else {
                /**
                 * @type {HTMLInputElement}
                 */
                const target = e.target.parentNode.querySelector("input[type='file']");
                target?.click();
              }
            }}
          >
            {preview && (
              <img
                src={preview}
                alt="preview-upload"
                className="w-full h-full rounded-md max-h-43 max-w-43"
                style={{objectFit: "contain" }}
              />
            )}
          </button>
        )}
        {/* ---- Right Section ---- */}
        <div className="w-full relative mt-2 4sm:mt-0">
          <input
            type="text"
            value={fileName}
            style={{
              paddingTop: "1rem",
              paddingBottom: "1rem",
              paddingRight: label ? "8rem" : "4rem",
            }}
            placeholder={placeholder}
            className="w-full"
            readOnly
          />
          <div className="cursor-pointer">
            <label htmlFor={id} className={"cursor-pointer  " + className}>
              {label}
              {icon}
            </label>
            <input
              ref={ref}
              type="file"
              id={id}
              name={id}
              onChange={onUploadImage}
              {...props}
            />
            {error && (
              <p className="input-error-message mt-2 -mb-1 flex">
                <AlertIconSVG /> {error}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const FileUpload = React.forwardRef(FileUploadComponent);

export default FileUpload;
