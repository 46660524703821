const ChatUserIconSVG = ({ className = "", fill = "#333333" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3708_9672)">
        <path
          d="M7 14C9.21 14 11 12.21 11 10C11 7.79 9.21 6 7 6C4.79 6 3 7.79 3 10C3 12.21 4.79 14 7 14ZM7 8C8.1 8 9 8.9 9 10C9 11.1 8.1 12 7 12C5.9 12 5 11.1 5 10C5 8.9 5.9 8 7 8ZM14 23C14 23.55 13.55 24 13 24C12.45 24 12 23.55 12 23C12 20.24 9.76 18 7 18C4.24 18 2 20.24 2 23C2 23.55 1.55 24 1 24C0.45 24 0 23.55 0 23C0 19.14 3.14 16 7 16C10.86 16 14 19.14 14 23ZM24 5V13C24 15.76 21.76 18 19 18H15C14.45 18 14 17.55 14 17C14 16.45 14.45 16 15 16H19C20.65 16 22 14.65 22 13V5C22 3.35 20.65 2 19 2H9.46C8.39 2 7.4 2.58 6.86 3.5C6.58 3.98 5.97 4.14 5.49 3.87C5.01 3.59 4.85 2.98 5.13 2.5C6.03 0.96 7.69 0 9.46 0H19C21.76 0 24 2.24 24 5ZM13.67 10.92L16.59 8H15C14.45 8 14 7.55 14 7C14 6.45 14.45 6 15 6H18C19.1 6 20 6.9 20 8V11C20 11.55 19.55 12 19 12C18.45 12 18 11.55 18 11V9.41L15.08 12.33C14.62 12.79 14 13.04 13.37 13.04C13.15 13.04 12.93 13.01 12.71 12.95C12.18 12.8 11.87 12.25 12.02 11.72C12.17 11.19 12.72 10.88 13.26 11.03C13.38 11.06 13.54 11.05 13.67 10.92Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_3708_9672">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChatUserIconSVG;
