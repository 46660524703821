import moment from "moment";
import React, { useMemo } from "react";
import { IoEllipse } from "react-icons/io5";
import DOMPurify from "dompurify";

import ProfileDefault from "assets/images/profile_default.png";

export default function ConversationList({
  selected,
  obj,
  onClick = () => { },
}) {

  const lastOnline = useMemo(() => {
    if (!obj || !obj?.thread?.participant?.profile?.last_online) {
      return null
    }

    return moment.utc(obj?.thread?.participant?.profile?.last_online).fromNow()
  }, [obj])

  if(obj.body === '<em data="deleted">Deleted old conversation</em>'){
    return<></>;
  }
 

  return (
    <div
      className={`px-2.5 sm:px-6 py-3 cursor-pointer ${selected?.id === obj?.id && "message-active"
        }`}
      onClick={() => onClick(obj)}
    >
      <div className="w-full flex space-x-4.5 items-center">
        {/* image */}
        <div>
          <div className="h-13 w-13 2sm:h-15 2sm:w-15 rounded-full">
            <img
              src={obj.thread?.participant?.profile?.profile_image_url || ProfileDefault}
              alt="milestone-profile-avatar"
              className="w-full h-full rounded-full"
            />
          </div>
        </div>
        <div className="w-16/20 hidden 3md:block">
          <div className="flex space-x-2 items-center">
            <p className="text-primary text-xl font-bold tracking-tighter leading-6">
              {obj?.thread?.participant?.profile?.user?.first_name + " " + obj?.thread?.participant?.profile?.user?.last_name}
            </p>
            {lastOnline &&
              <>
                <p className="text-passwordGray hidden 4md:block">
                  <IoEllipse className="w-1 h-1" />
                </p>
                <p className="text-sm font-semibold text-darkerGray leading-4 hidden 4md:block">
                  {lastOnline}
                </p>
              </>
            }
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                obj.body &&
                  !["<p><br></p>", "<p></p>"].includes(
                    obj.body
                  )
                  ? obj.body.replace('<p', '<p className="text-base text-darkerGray tracking-tightest leading-7.5 truncate"').replaceAll('<br>', ' ')
                  : "Truncated messsage shown here"
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
}
