const AboutIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 8.5C16 12.9183 12.4183 16.5 8 16.5C3.58172 16.5 0 12.9183 0 8.5C0 4.08172 3.58172 0.5 8 0.5C12.4183 0.5 16 4.08172 16 8.5Z"
        fill="#FF4C61"
      />
      <path
        d="M7.99635 12.4227C7.54172 12.4227 7.17316 12.0542 7.17316 11.5995V8.19855C7.17316 7.74392 7.54172 7.37536 7.99635 7.37536C8.45099 7.37536 8.81954 7.74392 8.81954 8.19855V11.5995C8.81954 12.0542 8.45099 12.4227 7.99635 12.4227ZM8.00022 5.72126C7.75545 5.72126 7.54547 5.6401 7.37027 5.47778C7.19764 5.31288 7.11133 5.11578 7.11133 4.88648C7.11133 4.65974 7.19764 4.46522 7.37027 4.3029C7.54547 4.138 7.75545 4.05556 8.00022 4.05556C8.24498 4.05556 8.45368 4.138 8.6263 4.3029C8.80151 4.46522 8.88911 4.65974 8.88911 4.88648C8.88911 5.11578 8.80151 5.31288 8.6263 5.47778C8.45368 5.6401 8.24498 5.72126 8.00022 5.72126Z"
        fill="white"
      />
    </svg>
  );
};

export default AboutIconSVG;
