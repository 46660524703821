import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer
      style={{ minHeight: "80px" }}
      className="relative w-full flex items-center bg-primary site-footer">
      <div className="w-full md:flex md:justify-between md:items-center text-xs sm:text-sm 1smd:text-base">
        <p className="text-footerText text-center pb-2 md:text-left md:pb-0">
          Copyright © {currentYear} Milestones Page. All rights reserved.
        </p>
        <div className="flex flex-col text-center w-full md:w-fit xxs:flex-row xxs:space-x-2 sm:space-x-5 justify-center">
          <Link to="/terms-and-conditions" className="text-footerText">
            Terms & Conditions
          </Link>
          <p className="hidden xxs:block text-footerText">|</p>
          <Link to="/privacy-policy" className="text-footerText">
            Privacy Policy
          </Link>
          <p className="hidden xxs:block text-footerText">|</p>
          <Link to="/faqs" className="text-footerText">
            FAQs
          </Link>
        </div>
      </div>
    </footer>
  );
}
