import React, { useLayoutEffect, useRef } from "react";
import { Logo } from "constants/images";
import { gsap } from "gsap";

export default function LogoComponent() {
  const logoComponent = useRef(null);

  useLayoutEffect(() => {
    if (logoComponent) {
      gsap
        .fromTo(
          ".logo-component",
          {
            x: -100,
            opacity: 0,
          },
          {
            x: 0,
            opacity: 1,
          }
        )
        .duration(0.7);
    }
  }, [logoComponent]);

  return (
    <div
      ref={logoComponent}
      className="logo-component w-full flex flex-row 3sm:flex-col items-center 3sm:items-start justify-start mt-5 gap-2">
      <img src={Logo} alt="component-brand-logo" className="form-logo" />
      <div className="mt-0 2md:mt-8 text-lightBlack">
        <h1 className="text-3xl 1md:text-7xl font-light tracking-tighter leading-none">
          Welcome to <br />
          <span className="font-bold">Milestones Page</span>
        </h1>
      </div>
    </div>
  );
}
