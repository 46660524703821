import Button from "components/button/button"
import SearchIconSVG from "assets/svg/search-icon";
import React, {useState} from "react";
import { FaChevronLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import _ from "lodash";


const COLORS = {
    white: [
      "bg-landing",
      "site-header-input-alt",
      "text-lightBlack",
      "bg-lightBlack",
      "site-header-input-alt-mobile",
    ],
    default: [
      "bg-primary",
      "site-header-input",
      "text-white",
      "bg-primary border-2 border-white",
      "site-header-input-mobile",
    ],
    black: [
        "bg-landing",
        "site-header-input",
        "text-white",
        "border-black text-[black]",
        "site-header-input-mobile",
        "text-black",
      ],
  };

const LoginSingup = ({
        variant="white",
        textSignup="Join", 
        textClass="text-white", 
        buttonClass="" , 
        showSearchIcon=false,
        searchBtn = ()=>{}
    })=>{
    const [showSearch, setShowSearch] = useState(false);
    const [search, setSearch] = useState("");
    const history = useHistory();

    const handleOnSearch = async (e) => {
        e.preventDefault();
        if (!_.isEmpty(search)) {
          history.push(`/search?keyword=${search}`);
        }
      };

    return(
        <>
            { !showSearch && 
                <div className="block 2md:hidden flex gap-2">
                    <Button
                    buttonName="Login"
                    buttonTextClass={`text-4xs font-semibold 1xs:text-xs ${textClass}`}
                    buttonRadius="6px"
                    buttonClass={`relative px-5 ${COLORS[variant][3]} border-[1px] ${buttonClass}`}
                    path="/login"
                    link={true}
                    />
                    <Button
                    buttonName={textSignup}
                    buttonTextClass={`text-4xs font-semibold 1xs:text-xs ${textClass}`}
                    buttonRadius="6px"
                    buttonClass={`relative px-5 ${COLORS[variant][3]} border-[1px] ${buttonClass}`}
                    path="/sign-up"
                    link={true}
                    />
                    { //show when you enable the showSearchIcon and it's related to the code below 
                        showSearchIcon &&
                        <button onClick={() => {
                            setShowSearch((prev) => !prev)
                            searchBtn(true)
                            }}>
                            <SearchIconSVG className={COLORS[variant][5]} />
                        </button>
                    }
                </div>
            }
            {showSearch && (
                <div className="w-full flex items-center">
                    <div className="mr-2">
                        <button onClick={() => {
                            setShowSearch((prev) => !prev)
                            searchBtn(false)
                            }}>
                        <FaChevronLeft
                            className={COLORS[variant][5] + " text-2xl mt-1"}
                        />
                        </button>
                    </div>
                    <div className={COLORS[variant][4]}>
                        <form
                            id="header-search-mobile"
                            onSubmit={handleOnSearch}
                            className="w-full">
                            <SearchIconSVG
                                className={`${COLORS[variant][2]} absolute top-2.5 left-5`}
                            />
                        <input
                            className="pr-5.5"
                            value={search}
                            onChange={(e) => setSearch(e?.target?.value)}
                            placeholder="Search"
                        />
                        </form>
                    </div>
                </div>
            )}
        </>
    )
}

export default LoginSingup;