import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from './actions'

export const useOccupations = () => {
    const {
        refresh,
        initialize,
        occupations = [],
    } = useSelector((state) => state.occupation);

    const dispatch = useDispatch();

    const setRefreshOccupations = useCallback(
        (payload) => dispatch(actions.setRefreshOccupations(payload)),
        [dispatch]
    );

    const setOccupations = useCallback(
        (payload) => dispatch(actions.setOccupations(payload)),
        [dispatch]
    );

    const addOccupation = useCallback(
        (payload) => dispatch(actions.addOccupation(payload)),
        [dispatch]
    );

    return {
        refresh,
        initialize,
        occupations,
        setRefreshOccupations,
        setOccupations,
        addOccupation
    };
} 