import React, { useEffect } from "react";
import { connect } from "react-redux";
import Pusher from "pusher-js";
import { useNotifications } from "redux/modules/notification/hooks";
import { useMessages } from "redux/modules/messages/hooks";
import { urlSerializer } from "utils/utils";

let messageListener;
let profileInviteListener;

const NotificationHandler = ({ userData }) => {
  const { http } = global.services;

  const { setPushMessage, setUnreadMessages } = useMessages();

  const { addNotification, setNotifications } = useNotifications();

  async function getNotifications() {
    try {
      const url = urlSerializer("notifications", {
        limit: 5,
        offset: 0,
        user_id: userData.user.id,
        user_profile_id: userData.profile.id,
      });
      const { data } = await http.get(url);
      setNotifications(data);
    } catch (err) {
      console.log(err);
    }
  }

  async function getUnreadMessage (){ 
    try {
      const url = urlSerializer("public/messages/unread", {
        user_id: userData.profile.id,
      });
      const { data } = await http.get(url);
      setUnreadMessages(data);
    } catch (err) {
      console.log(err);
    }
  }

  const initializeConnection = () => {
    const { access_token, user, profile } = userData;

    const pusher = new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      channelAuthorization: {
        endpoint: `${process.env.REACT_APP_API_DOMAIN}/broadcasting/auth`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          Accept: "application/json",
        },
      },
      forceTLS: true,
    });

    Pusher.ready(() => {});

    Pusher.log = (msg) => {
      // console.log(msg);
    };

    messageListener = pusher.subscribe(`private-message.profile.${profile.id}`);
    messageListener.bind("message-notification-event", function ({ data }) {
      const { message, unread } = data;
      setPushMessage(message);
      setUnreadMessages(unread);
    });

    profileInviteListener = pusher.subscribe(
      `private-profile-invites.${user.id}`
    );
    profileInviteListener.bind(
      "profile-invites-notification-event",
      function ({ data }) {
        const { notification } = data;
        addNotification({ ...notification, isNew: true });
      }
    );
  };

  const unbindConnection = () => {
    if (messageListener) 
      messageListener.unbind("message-notification-event");
    if (profileInviteListener)
      profileInviteListener.unbind("profile-invites-notification-event");
  };

  useEffect(() => {
    if (userData) {
      initializeConnection();
      getNotifications();
      getUnreadMessage();
    } else {
      unbindConnection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return <></>;
};

const mapStateToProps = (state) => {
  return {
    userData: state.user.userData,
    push: state.notifications.push,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // setNotifications: params => {
    //     dispatch(NotificationsActions.setNotifications(params))
    // }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationHandler);
