import React, { useMemo } from "react";
import UserGearIconSVG from "assets/svg/user-gear-icon";
import GraduationCapIconSVG from "assets/svg/graduation-cap-icon";
import ChatUserIconSVG from "assets/svg/chat-user-icon";
import BriefCaseExperienceIconSVG from "assets/svg/brief-case-icon";
import { Profile1 } from "constants/images";
import { FiBriefcase } from "react-icons/fi";
import { BiBuildingHouse } from "react-icons/bi";
import { FaRegUserCircle } from "react-icons/fa";
import ResumeUnderlineIconSVG from "assets/svg/resume-underline-icon";
import ResumeDotDividerIconSVG from "assets/svg/resume-dot-divider-icon";
import moment from "moment";
import _ from "lodash";
import { socialTemplate } from "utils/constant";
import { groupArraysWithType, A4_SIZE } from "../../resume";
import { personalDateFormat } from "utils/utils";
import profile from "pages/profile/profile";

export default function TemplateTwoPageOne(props) {
  // ===================== props ============================
  const {
    active,
    previewImage,
    color = {},
    details = {},
    personal = {},
    social = {},
    page = 1,
    primaryColor,
    tertiaryColor,
    quarternary, 
    milestones,
    setTotalPage, 
  } = props;


  React.useEffect(() => {
    let groupAll = groupArraysWithType(arraysWithType, 8, 8); 
    setTotalPage(groupAll.length+1) 
}, [milestones]);

const arraysWithType = [
  { array: milestones?.featured || [], type: 'featured' },
  { array: milestones?.current || [], type: 'current' },
  { array: milestones?.past || [], type: 'past' },
];

  // ===================== states ============================
  const [socialColor, socialBG] = useMemo(() => {
    if (color.label === "Yellow + Blue") {
      return ["#FFFFFF", primaryColor];
    } else if (color.label === "Pink + Dark Pink") {
      return [tertiaryColor, "#FFFFFF"];
    }
    return ["#FFFFFF", tertiaryColor];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  const [pageBGActive, pageBGInactive] = useMemo(() => {
    if (color.label === "Blue + Orange") {
      return [tertiaryColor, "#16438A"];
    } else if (color.label === "Pink + Dark Pink") {
      return ["#FFFFFF", tertiaryColor];
    }
    return ["#FFFFFF", "#000000"];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  const socMed = useMemo(() => {
    let arrays = Object.entries(social);
    return arrays.map((obj) => {
      let key = obj[0];
      let value = obj[1];
      return { key, value };
    });
  }, [social]);
  // ===================== return Node ============================

  return (
    <div className={`${!active ? "hidden" : ""} relative`} style={A4_SIZE}>
      {/* header  */}
      <div className="w-full h-full">
        <div
          className="w-full pt-5.4 pb-3.7 pl-15 pr-12.5"
          style={{ backgroundColor: primaryColor }}>
          <div className="w-full flex flex-row gap-x-5.4 text-white items-center">
          {
            personal?.profile_image &&
            <>
              <div className="w-23 h-23 rounded-md bg-white relative overflow-hidden">
                {(personal.hasOwnProperty("profile_image") && personal.profile_image) || previewImage ? (
                  <img
                    src={previewImage ?? personal.profile_image}
                    className="w-full h-full object-cover object-center"
                    alt="resume-profile"
                  />
                ) : (
                  <img
                    src={Profile1}
                    className="w-full h-full object-cover object-center"
                    alt="resume-profile"
                  />
                )}
              </div>
            </>
          }
            <div style={{ width: "calc(100% - 111px)" }}>
              <h1 className="text-xl font-bold tracking-verytight">
                {details.username}
              </h1>
              <div className="w-full flex flex-row gap-x-1.5 text-white items-center">
                {personal.hasOwnProperty("name") && personal.name && (
                  <p className="text-1xs leading-2 font-medium">
                    {personal.name}
                  </p>
                )}
                {personal.hasOwnProperty("name") &&
                  personal.name &&
                  personal.hasOwnProperty("gender") &&
                  personal.gender && (
                    <span className="text-1xs opacity-30">&#8226;</span>
                  )}
                {personal.hasOwnProperty("gender") && personal.gender && (
                  <p className="text-1xs leading-2 font-medium">
                    {personal.gender}
                  </p>
                )}
                {personal.hasOwnProperty("gender") &&
                  personal.gender &&
                  personal.hasOwnProperty("pronoun") &&
                  personal.pronoun && (
                    <span className="text-1xs opacity-30">&#8226;</span>
                  )}
                {personal.hasOwnProperty("pronoun") && personal.pronoun && (
                  <p className="text-1xs leading-2 font-medium">
                    {personal.pronoun}
                  </p>
                )}
                {personal.hasOwnProperty("address") && personal.address && (
                  <>
                    <span className="text-1xs opacity-30">&#8226;</span>
                    <p className="text-1xs leading-2 font-medium">
                      {personal.address}
                    </p>
                  </>
                )}
              </div>
              {/*  */}
              <div className="w-full text-white mt-2.5">
                <ul className="w-full grid gap-y-1">
                  {personal.hasOwnProperty("occupations") &&
                    personal.occupations.length > 0 && (
                      <li className="w-full flex space-x-1.5 items-center">
                        <FiBriefcase className="text-resume-icon-header opacity-70 w-2.5 h-2.5" />
                        <p className="text-1xs leading-2 font-medium">
                          {(personal.occupations || []).map((val, index) => {
                            const count = personal.occupations.length;
                            if (index >= count - 1 && count !== 1) {
                              return " and " + val;
                            } else {
                              if (index >= count - 2) {
                                return val;
                              } else {
                                return val + ", ";
                              }
                            }
                          })}
                        </p>
                      </li>
                    )}
                  {/* ========================== show dummy data for now .. replace it later ==================== */}
                  <li className="w-full flex space-x-1.5 items-center">
                    <BiBuildingHouse className="text-resume-icon-header opacity-70 w-2.5 h-2.5" />
                    <p className="text-1xs leading-2 font-medium">
                      Agency Company
                    </p>
                  </li>
                  {/* ========================== end: show dummy data for now .. replace it later ==================== */}
                  {personal.hasOwnProperty("profile_type") &&
                    personal.profile_type.length > 0 && (
                      <li className="w-full flex space-x-1.5 items-center">
                        <FaRegUserCircle className="text-resume-icon-header opacity-70 w-2.5 h-2.5" />
                        <div className="w-full flex flex-row gap-x-1.5 items-center">
                          {(personal.profile_type || []).map((value, index) => {
                            let seperator = (index + 1) % 2 === 0;
                            if (seperator) {
                              return (
                                <React.Fragment key={index}>
                                  <span className="text-1xs opacity-30">
                                    &#8226;
                                  </span>
                                  <p className="text-1xs leading-2 font-medium">
                                    {value}
                                  </p>
                                </React.Fragment>
                              );
                            }
                            return (
                              <p
                                className="text-1xs leading-2 font-medium"
                                key={index}>
                                {value}
                              </p>
                            );
                          })}
                        </div>
                      </li>
                    )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* end header */}
        <div className="w-full py-2.4" style={{ backgroundColor: quarternary }}>
          <div className="w-full flex flex-row gap-x-3 justify-center text-white">
            {(socMed || []).map((obj, index) => {
              const SocialIcon = socialTemplate[obj.key];
              if (SocialIcon) {
                return (
                  <div className="flex items-center space-x-1.5" key={index}>
                    <button
                      className="w-3 h-3 rounded-full"
                      style={{ backgroundColor: socialBG }}>
                      <SocialIcon
                        className="w-1.5 h-1.5 m-auto"
                        style={{ color: socialColor }}
                      />
                    </button>
                    <p className="text-3xs leading-2">{obj.value}</p>
                  </div>
                );
              } else {
                const Default = socialTemplate['default']
                return (
                  <div className="flex items-center space-x-1.5" key={index}>
                    <button
                      className="w-3 h-3 rounded-full"
                      style={{ backgroundColor: socialBG }}>
                      <img
                        className="w-1.5 h-1.5 m-auto"
                        src={`https://s2.googleusercontent.com/s2/favicons?domain=${obj.value}&sz=64`}
                        alt=""
                      />
                    </button>
                    <p className="text-3xs leading-2">{obj.value}</p>
                  </div>
                );
              }
            })}
          </div>
        </div>
        {/* end extra header */}
        {/* start body */}
        <div
          className="w-full grid bg-white relative grid-row-auto"
          style={{ minHeight: "1123px" }}
          >
          {/* body */}
          <div className="w-full px-15 pt-7.5">
            <div className="w-full grid gap-y-7">
              <div className="w-full flex flex-row space-x-6.5">
                <div className="w-14">
                  <p className="text-4xs font-medium text-lightBlack tracking-tight">
                    More Information
                  </p>
                  <ResumeUnderlineIconSVG
                    className="mt-3"
                    stroke={primaryColor}
                  />
                </div>
                <div style={{ width: "calc(100% - 81px)" }}>
                  <div className="w-full grid gap-y-2">
                    {/* =============1st======================== */}
                    {details.hasOwnProperty("bio") && details.bio && (
                      <div className="w-full flex flex-row space-x-0.5 border-b border-resumeTempTwoBorder pb-1">
                        <div className="w-18.5">
                          <p className="text-3xs text-darkerGray tracking-resume leading-3.2">
                            Bio
                          </p>
                        </div>
                        <div style={{ width: "calc(100% - 75px)" }}>
                          <p className="text-3xs text-lightBlack font-medium tracking-resumeValue leading-3.2">
                            {details.bio}
                          </p>
                        </div>
                      </div>
                    )}
                    {/* =============2nd======================== */}
                    <div className="w-full grid grid-cols-2 gap-x-3">
                      {/* ==============left============================== */}
                      <div className="w-full grid gap-y-2">
                        {/* ============= Date of Birth======================= */}
                        {details.hasOwnProperty("birthdate") &&
                          details.birthdate && (
                            <div className="w-full flex flex-row space-x-0.5 border-b border-resumeTempTwoBorder pb-1">
                              <div className="w-18.5">
                                <p className="text-3xs text-darkerGray tracking-resume leading-3.2">
                                  Date of Birth
                                </p>
                              </div>
                              <div style={{ width: "calc(100% - 75px)" }}>
                                <p className="text-3xs text-lightBlack font-medium tracking-resumeValue leading-3.2">
                                  {personalDateFormat(details.birthdate,profile?.dateFormat)}
                                </p>
                              </div>
                            </div>
                          )}
                        {/* ==============Civil Status====================== */}
                        {details.hasOwnProperty("civil_status") &&
                          details.civil_status && (
                            <div className="w-full flex flex-row space-x-0.5 border-b border-resumeTempTwoBorder pb-1">
                              <div className="w-18.5">
                                <p className="text-3xs text-darkerGray tracking-resume leading-3.2">
                                  Civil Status
                                </p>
                              </div>
                              <div style={{ width: "calc(100% - 75px)" }}>
                                <p className="text-3xs text-lightBlack font-medium tracking-resumeValue leading-3.2">
                                  {details.civil_status}
                                </p>
                              </div>
                            </div>
                          )}
                        {/* ================Other Name==================== */}
                        {details.hasOwnProperty("other_name") &&
                          details.other_name && (
                            <div className="w-full flex flex-row space-x-0.5 border-b border-resumeTempTwoBorder pb-1">
                              <div className="w-18.5">
                                <p className="text-3xs text-darkerGray tracking-resume leading-3.2">
                                  Other Name
                                </p>
                              </div>
                              <div style={{ width: "calc(100% - 75px)" }}>
                                <p className="text-3xs text-lightBlack font-medium tracking-resumeValue leading-3.2">
                                  {details.other_name}
                                </p>
                              </div>
                            </div>
                          )}
                        {/* ===============Eye Color===================== */}
                        {details.hasOwnProperty("eye_color") &&
                          details.eye_color && (
                            <div className="w-full flex flex-row space-x-0.5 border-b border-resumeTempTwoBorder pb-1">
                              <div className="w-18.5">
                                <p className="text-3xs text-darkerGray tracking-resume leading-3.2">
                                  Eye Color
                                </p>
                              </div>
                              <div style={{ width: "calc(100% - 75px)" }}>
                                <p className="text-3xs text-lightBlack font-medium tracking-resumeValue leading-3.2">
                                  {details.eye_color}
                                </p>
                              </div>
                            </div>
                          )}
                        {/* ================Phone==================== */}
                        {details.hasOwnProperty("phone_number") &&
                          details.phone_number && (
                            <div className="w-full flex flex-row space-x-0.5 border-b border-resumeTempTwoBorder pb-1">
                              <div className="w-18.5">
                                <p className="text-3xs text-darkerGray tracking-resume leading-3.2">
                                  Phone
                                </p>
                              </div>
                              <div style={{ width: "calc(100% - 75px)" }}>
                                <p className="text-3xs text-lightBlack font-medium tracking-resumeValue leading-3.2">
                                  {details.phone_number}
                                </p>
                              </div>
                            </div>
                          )}
                      </div>
                      {/* ==============right============================== */}
                      <div className="w-full grid gap-y-2">
                        {/* ============= Birth Location======================= */}
                        {details.hasOwnProperty("birth_location") &&
                          details.birth_location && (
                            <div className="w-full flex flex-row space-x-0.5 border-b border-resumeTempTwoBorder pb-1">
                              <div className="w-18.5">
                                <p className="text-3xs text-darkerGray tracking-resume leading-3.2">
                                  Birth Location
                                </p>
                              </div>
                              <div style={{ width: "calc(100% - 75px)" }}>
                                <p className="text-3xs text-lightBlack font-medium tracking-resumeValue leading-3.2">
                                  {details.birth_location}
                                </p>
                              </div>
                            </div>
                          )}
                        {/* ==============Children====================== */}
                        {details.hasOwnProperty("children") &&
                          details.children !== 0 && (
                            <div className="w-full flex flex-row space-x-0.5 border-b border-resumeTempTwoBorder pb-1">
                              <div className="w-18.5">
                                <p className="text-3xs text-darkerGray tracking-resume leading-3.2">
                                  Children
                                </p>
                              </div>
                              <div style={{ width: "calc(100% - 75px)" }}>
                                <p className="text-3xs text-lightBlack font-medium tracking-resumeValue leading-3.2">
                                  {details.children} Children
                                </p>
                              </div>
                            </div>
                          )}
                        {/* ================Hair Color==================== */}
                        {details.hasOwnProperty("hair_color") &&
                          details.hair_color && (
                            <div className="w-full flex flex-row space-x-0.5 border-b border-resumeTempTwoBorder pb-1">
                              <div className="w-18.5">
                                <p className="text-3xs text-darkerGray tracking-resume leading-3.2">
                                  Hair Color
                                </p>
                              </div>
                              <div style={{ width: "calc(100% - 75px)" }}>
                                <p className="text-3xs text-lightBlack font-medium tracking-resumeValue leading-3.2">
                                  {details.hair_color}
                                </p>
                              </div>
                            </div>
                          )}
                        {/* ===============Height===================== */}
                        {details.hasOwnProperty("height") && details.height && (
                          <div className="w-full flex flex-row space-x-0.5 border-b border-resumeTempTwoBorder pb-1">
                            <div className="w-18.5">
                              <p className="text-3xs text-darkerGray tracking-resume leading-3.2">
                                Height
                              </p>
                            </div>
                            <div style={{ width: "calc(100% - 75px)" }}>
                              <p className="text-3xs text-lightBlack font-medium tracking-resumeValue leading-3.2">
                                {details.height}cm
                              </p>
                            </div>
                          </div>
                        )}
                        {/* ================Email Address ==================== */}
                        {details.hasOwnProperty("email") && details.email && (
                          <div className="w-full flex flex-row space-x-0.5 border-b border-resumeTempTwoBorder pb-1">
                            <div className="w-18.5">
                              <p className="text-3xs text-darkerGray tracking-resume leading-3.2">
                                Email Address
                              </p>
                            </div>
                            <div style={{ width: "calc(100% - 75px)" }}>
                              <p className="text-3xs text-lightBlack font-medium tracking-resumeValue leading-3.2">
                                {details.email}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Divider */}
              <div className="w-full">
                <ResumeDotDividerIconSVG />
              </div>
              {/* professional */}
              {((details.hasOwnProperty("occupations") &&
                !_.isEmpty(details.occupations)) ||
                details.education ||
                details.training ||
                details.experience) && (
                  <>
                    <div className="w-full flex flex-row space-x-6.5">
                      <div className="w-14">
                        <p className="text-4xs font-medium text-lightBlack tracking-tight">
                          Professional Information
                        </p>
                        <ResumeUnderlineIconSVG
                          className="mt-3"
                          stroke={primaryColor}
                        />
                      </div>
                      <div style={{ width: "calc(100% - 81px)" }}>
                        <div className="w-full grid gap-y-4">
                          {/* ========== Abilities / Skills / Specialties  ======== */}
                          {details.hasOwnProperty("occupations") &&
                            !_.isEmpty(details.occupations) && (
                              <div className="w-full flex flex-row space-x-2">
                                <UserGearIconSVG
                                  className="w-4 h-4"
                                  fill={primaryColor}
                                />
                                <div style={{ width: "calc(100% - 24px)" }}>
                                  <p className="text-4xs text-lightBlack font-semibold tracking-tight">
                                    Abilities / Skills / Specialties
                                  </p>
                                  <div className="w-full flex flex-row flex-wrap gap-x-1.5 gap-y-0.5 text-5xs text-white font-bold mt-1">
                                    {details.occupations.map((oc, i) => (
                                      <span
                                        key={i}
                                        className="uppercase px-1 py-0.5 rounded-sm border"
                                        style={{
                                          backgroundColor: tertiaryColor,
                                        }}>
                                        {oc}
                                      </span>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            )}
                          {/* ========== Education ======== */}
                          {details.hasOwnProperty("education") &&
                            details.education && (
                              <div className="w-full flex flex-row space-x-2">
                                <GraduationCapIconSVG
                                  className="w-4 h-4"
                                  fill={primaryColor}
                                />
                                <div style={{ width: "calc(100% - 24px)" }}>
                                  <p className="text-4xs text-lightBlack font-semibold tracking-tight">
                                    Education
                                  </p>
                                  <div className="w-full mt-1">
                                    <p className="text-3xs text-darkerGray tracking-resume leading-3.2">
                                      {details.education}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                          {/* ========== Education ======== */}
                          {details.hasOwnProperty("training") &&
                            details.training && (
                              <div className="w-full flex flex-row space-x-2">
                                <ChatUserIconSVG
                                  className="w-4 h-4"
                                  fill={primaryColor}
                                />
                                <div style={{ width: "calc(100% - 24px)" }}>
                                  <p className="text-4xs text-lightBlack font-semibold tracking-tight">
                                    Training
                                  </p>
                                  <div className="w-full mt-1">
                                    <p className="text-3xs text-darkerGray tracking-resume leading-3.2">
                                      {details.training}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                          {/* ========== Experience ======== */}
                          {details.hasOwnProperty("experience") &&
                            details.experience && (
                              <div className="w-full flex flex-row space-x-2">
                                <BriefCaseExperienceIconSVG
                                  className="w-4 h-4"
                                  fill={primaryColor}
                                />
                                <div style={{ width: "calc(100% - 24px)" }}>
                                  <p className="text-4xs text-lightBlack font-semibold tracking-tight">
                                    Experience
                                  </p>
                                  <div className="w-full mt-1">
                                    <p className="text-3xs text-darkerGray tracking-resume leading-3.2">
                                      {details.experience}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    {/* Divider */}
                    <div className="w-full">
                      <ResumeDotDividerIconSVG />
                    </div>
                  </>
                )}
            </div>
          </div> 
        </div>
      </div>
      {/* footer */}
      <div className="absolute bottom-0 right-0 w-full pt-10 flex items-end absolute bottom-0 right-0">
        <div
          className="w-full h-6 px-15 relative"
          style={{ backgroundColor: quarternary }}>
          <div className="w-full h-full flex flex-row justify-between items-center">
            <p className="text-white font-semibold text-1xs">Page {page}</p>
            <div className="flex flex-start gap-1.5">
              <button
                className="w-1.5 h-1.5 rounded-full"
                style={{ backgroundColor: pageBGActive }}
              />
              <button
                className="w-1.5 h-1.5 rounded-full"
                style={{ backgroundColor: pageBGInactive }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
