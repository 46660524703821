const MapMarkerIconSVG = ({ className = "", stroke = "#E5EFFA" }) => {
  return (
    <svg
      // width="25"
      // height="24"
      // viewBox="0 0 25 24"
      width="11"
      height="10"
      viewBox="0 0 11 10"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.7" clip-path="url(#clip0_1383_2374)">
        <path
          d="M8.89355 4.16669C8.89355 7.08335 5.14355 9.58335 5.14355 9.58335C5.14355 9.58335 1.39355 7.08335 1.39355 4.16669C1.39355 3.17213 1.78864 2.2183 2.4919 1.51504C3.19517 0.811775 4.14899 0.416687 5.14355 0.416687C6.13812 0.416687 7.09194 0.811775 7.7952 1.51504C8.49847 2.2183 8.89355 3.17213 8.89355 4.16669Z"
          stroke={stroke}
          stroke-width="0.833322"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.14355 5.41669C5.83391 5.41669 6.39355 4.85704 6.39355 4.16669C6.39355 3.47633 5.83391 2.91669 5.14355 2.91669C4.4532 2.91669 3.89355 3.47633 3.89355 4.16669C3.89355 4.85704 4.4532 5.41669 5.14355 5.41669Z"
          stroke={stroke}
          stroke-width="0.833322"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1383_2374">
          <rect width="10" height="10" fill="white" transform="translate(0.143555)"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default MapMarkerIconSVG;
