import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function LineGraph({ labels, serverData = [], year, month }) {
  const data = {
    labels,
    datasets: [
      {
        label: "Views",
        data: labels.map((label) => serverData[label] ?? 0),
        borderColor: "#8DB4DC",
        backgroundColor: "#8DB4DC",
        pointBorderWidth: 0,
        pointHoverBackgroundColor: "#FFFFFF",
        pointHoverBorderWidth: 10,
        hoverBorderWidth: 10,
        fill: true,
        tension: 0.4,
      },
    ],
  };

  // const customTooltip = ({ chart, tooltip }) => {
  //   if (!tooltip.opacity) {
  //     return null;
  //   }

  //   const tooltipElement = document.createElement("div");
  //   tooltipElement.className = "custom-tooltip";

  //   if (tooltip.body) {
  //     const titleElement = document.createElement("div");
  //     titleElement.className = "tooltip-title";
  //     titleElement.innerHTML = tooltip.title[0];
  //     tooltipElement.appendChild(titleElement);

  //     const valueElement = document.createElement("div");
  //     valueElement.className = "tooltip-value";
  //     valueElement.innerHTML = "Value: " + tooltip.body[0].lines[0];
  //     tooltipElement.appendChild(valueElement);
  //   }

  //   tooltipElement.style.position = "absolute";
  //   tooltipElement.style.left = tooltip.caretX + "px";
  //   tooltipElement.style.top = tooltip.caretY + "px";
  //   tooltipElement.style.pointerEvents = "none";

  //   chart.canvas.parentNode.appendChild(tooltipElement);

  //   return null;
  // };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        // position: 'nearest',
        // external: customTooltip,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          zeroLineColor: "transparent",
          borderWidth: 0,
        },
      },
      y: {
        grid: {
          borderDash: [8, 4],
          color: "#EDEFF5",
          borderWidth: 0,
        },
        beginAtZero: true,
        ticks: {
          precision: 0,
        },
      },
    },
  };

  return <Line options={options} data={data} />;
}
