import React from 'react';
import {LexicalComposer} from '@lexical/react/LexicalComposer';
import {PlainTextPlugin} from '@lexical/react/LexicalPlainTextPlugin';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';

const MyCustomRefPlugin = React.forwardRef((props, ref) => {
  const [editor] = useLexicalComposerContext();
  console.log('EDITOR::', JSON.stringify(editor.getEditorState()));
  React.useEffect(() => {
    ref.current = editor;
    return () => {
      ref.current = null;
    };
  }, [editor, ref]);

  return null;
});

const Editor = ({ placeholder, editorRef, onFocus }) => {
  return (
    <div className='relative'>
      <LexicalComposer
        initialConfig={{
          onError(error) {
            throw error;
          },
        }}
      >
        <PlainTextPlugin
          contentEditable={
            <ContentEditable className="w-full px-4 py-5" style={{
              border: "1px solid #DDDDDD",
              borderRadius: 6,
              paddingTop: 10,
              paddingBottom: 10,
              paddingLeft: 26,
              paddingRight: 22,
              maxHeight: 100,
              minHeight: 46, 
              overflowY: "auto"
            }} />
          }
          placeholder={
            <div className="absolute top-[11px] left-[26px] text-[10px] 1md:text-base" style={{ color: '#AAAAAA' }} onClick={onFocus}>
            { placeholder }
            </div>
          }
        ></PlainTextPlugin>
        <HistoryPlugin />
        <MyCustomRefPlugin ref={editorRef} />
      </LexicalComposer>
    </div>
  )
}

export default Editor;