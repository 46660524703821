const EmptyPhotosIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M110.948 21.3901V59.2861L110.74 59.7041C110.498 59.4781 110.25 59.2621 110.016 59.0281C102.874 51.8921 95.7462 44.7441 88.5902 37.6221C85.3302 34.3781 80.8582 34.3881 77.5922 37.6081C75.5822 39.5901 73.5942 41.5941 71.6002 43.5941C67.6862 47.5201 63.7742 51.4521 59.8602 55.3801C59.4322 55.4981 59.2842 55.1201 59.0622 54.9061C58.3442 54.2141 57.6482 53.5001 56.9422 52.7961C53.3122 49.1761 48.9882 49.1741 45.3662 52.7961C39.1362 59.0221 32.9102 65.2481 26.6782 71.4721C26.3982 71.7521 26.0922 72.0041 25.8002 72.2681C25.3842 71.9341 25.5562 71.4661 25.5562 71.0581C25.5482 55.1101 25.5362 39.1621 25.5562 23.2121C25.5622 18.3001 28.6802 14.2941 33.3522 13.4201C33.6262 13.3681 102.578 13.4101 102.84 13.4101C107.256 13.4101 110.948 15.9641 110.948 21.3881V21.3901ZM46.8702 42.9721C51.5922 42.9861 55.4402 39.1741 55.4442 34.4821C55.4482 29.8921 51.6062 25.9901 47.0722 25.9781C42.2442 25.9641 38.4022 29.6881 38.3482 34.4301C38.2962 39.0921 42.1542 42.9601 46.8702 42.9741V42.9721Z"
        fill="#DADADA"
      />
      <path
        d="M9.12629 76.4981C11.6403 68.1201 14.1443 59.7381 16.6463 51.3561C17.4223 48.7581 18.1943 46.1601 19.0003 43.4541C19.2523 43.8861 19.1583 44.2361 19.1583 44.5621C19.1643 53.5361 19.1003 62.5121 19.1823 71.4861C19.2543 79.4381 24.8063 86.1221 32.5183 87.4321C33.2103 87.5501 33.8943 87.6141 34.5903 87.6141C54.9723 87.6121 75.3563 87.6141 95.7383 87.6141C96.9983 87.6141 96.9903 87.6141 96.6003 88.8401C95.3983 92.6321 94.2203 96.4341 92.9983 100.22C91.8983 103.632 89.5243 105.692 86.0783 106.554C85.8243 106.618 82.0663 106.624 81.8363 106.564C79.8843 106.048 77.9383 105.514 75.9883 104.992C67.5763 102.738 59.1643 100.482 50.7503 98.2361C39.1683 95.1461 27.5843 92.0701 16.0023 88.9781C12.6543 88.0841 10.3723 85.9981 9.12829 82.7621C9.02029 82.4801 9.03429 76.7861 9.12229 76.4981H9.12629Z"
        fill="#DADADA"
      />
      <path
        d="M110.74 59.7062C110.742 63.6222 110.758 67.5362 110.746 71.4522C110.73 77.0722 106.662 81.2182 101.05 81.2662C95.7743 81.3102 90.4963 81.2702 85.2183 81.2662C85.0323 81.0362 84.8663 80.7862 84.6583 80.5762C79.0783 74.9282 73.4983 69.2802 67.9083 63.6422C65.4523 61.1642 62.9723 58.7082 60.5083 56.2362C60.2563 55.9822 59.9263 55.7762 59.8623 55.3782C63.7743 51.4502 67.6863 47.5182 71.6023 43.5922C73.5963 41.5922 75.5823 39.5882 77.5943 37.6062C80.8583 34.3882 85.3303 34.3762 88.5923 37.6202C95.7483 44.7422 102.878 51.8902 110.018 59.0262C110.252 59.2602 110.5 59.4782 110.742 59.7022L110.74 59.7062Z"
        fill="#BDBDBD"
      />
      <path
        d="M59.8623 55.3783C59.9263 55.7783 60.2543 55.9843 60.5083 56.2363C62.9723 58.7083 65.4523 61.1643 67.9083 63.6423C73.4983 69.2803 79.0783 74.9283 84.6583 80.5763C84.8663 80.7863 85.0323 81.0343 85.2183 81.2663C84.9243 81.2923 84.6283 81.3403 84.3343 81.3403C68.1383 81.3443 51.9443 81.3503 35.7483 81.3403C30.7043 81.3363 26.8063 78.2923 25.8243 73.6423C25.7283 73.1903 25.6243 72.7303 25.8003 72.2683C26.0943 72.0043 26.4003 71.7503 26.6783 71.4723C32.9083 65.2483 39.1363 59.0203 45.3663 52.7963C48.9903 49.1763 53.3123 49.1783 56.9423 52.7963C57.6483 53.5003 58.3443 54.2143 59.0623 54.9063C59.2843 55.1203 59.4343 55.4963 59.8603 55.3803L59.8623 55.3783Z"
        fill="#CACACA"
      />
      <path
        d="M46.8702 42.9721C42.1562 42.9581 38.2962 39.0901 38.3482 34.4281C38.4002 29.6861 42.2442 25.9621 47.0722 25.9761C51.6062 25.9881 55.4462 29.8901 55.4442 34.4801C55.4402 39.1741 51.5942 42.9841 46.8702 42.9701V42.9721Z"
        fill="white"
      />
    </svg>
  );
};

export default EmptyPhotosIconSVG;
