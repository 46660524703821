import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from './actions'

export const useCountries = () => {

    const {
        initialize,
        refresh,
        countries = [],
    } = useSelector((state) => state.country);

    const dispatch = useDispatch();

    const setRefreshCountries = useCallback(
        (payload) => dispatch(actions.setRefreshCountries(payload)),
        [dispatch]
    );

    const setCountries = useCallback(
        (payload) => dispatch(actions.setCountries(payload)),
        [dispatch]
    );

    return {
        initialize,
        refresh,
        countries,
        setRefreshCountries,
        setCountries
    };
} 