import React from "react";
import Modal from "components/global/modal";
import CloseIconSVG from "assets/svg/close-icon";
import { InvitationNotSent, InvitationSent } from "constants/images";
import Button from "components/button/button";

export default function InvitationStatusSentModal({
  hide,
  success = true,
  message = "",
  ...props
}) {
  const printMessage = (email = "johndoe@gmail.com") => {
    if (success) {
      return `Your invite has been sent to ${email}. You will receive an email once the user has accepted the invite.`;
    } else if (message) {
      return message;
    }
    return `User ${email} doesn’t exist in the milestone platform. Please try again.`;
  };

  return (
    <Modal
      width="w-19/20 7sm:w-460"
      contentClassName="rounded-lg overflow-auto custom-scroll"
      wrapperClassName="overflow-visible"
      footerClass="border-t border-gray">
      <div className="w-full">
        <div className="p-6">
          {/* close */}
          <div className="w-full flex justify-end">
            <button onClick={hide}>
              <CloseIconSVG />
            </button>
          </div>
          <div className="w-full">
            <div className="w-24.5 h-24.5 mx-auto">
              <img
                src={success ? InvitationSent : InvitationNotSent}
                alt="invitation-sent-access"
                className="w-24.5 h-24.5"
              />
            </div>
            <div className="w-full mt-6 text-center leading-6 px-4">
              <p className="text-xl text-lightBlack font-semibold">
                {success
                  ? "Invitation was sent!"
                  : "Sorry, Invitation was not sent."}
              </p>
              <p className="mt-3 text-lightBlack leading-6.5">
                {printMessage(props?.email)}
              </p>
            </div>
            <div className="w-full mt-9 flex justify-center">
              <Button
                buttonWidth="202px"
                buttonName="Done"
                buttonTextClass="text-sm text-lightBlack font-semibold leading-4.4"
                buttonRadius="6px"
                buttonClass="bg-thBg py-4"
                onClick={hide}
              />
            </div>
          </div>
        </div>
        {/* end content */}
      </div>
    </Modal>
  );
}
