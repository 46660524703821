
import React from "react";
import Button from "components/button/button";
import Input from "components/input/input";
import CreatableSelect from "components/select/creatable";
import { FaPlus, FaMinus } from "react-icons/fa";

const InputGroup = ({
  title,
  subTitleLeft,
  subTitleRight,
  options = [],
  items = [],
  fieldKey,
  onAdd,
  hideAddButton = false,
  onChange,
  onSelect,
  onRemove,
  errors,
  customClass= ''
}) => {
  return (
    <div className="w-full mb-6">
      <div className="w-full grid 4sm:grid-cols-2 4sm:gap-6">
        <label className={`text-sm font-bold text-darkerGray mb-2 capitalize ${customClass}`}>
          {title}
          <span className="ml-1">({items.length}/5)</span>
        </label>
      </div>
      <div className="w-full">
        <div className="w-full grid 4sm:grid-cols-2 gap-x-4 mb-2">
          <p className="text-sm text-darkerGray">{subTitleLeft}</p>
          <p className="text-sm font-bold text-darkerGray">{subTitleRight}</p>
        </div>
        {items.map((item, index) => (
          <div
            key={index}
            className="w-full grid 4sm:grid-cols-2 gap-x-4 gap-y-2 mb-4"
          >
            <CreatableSelect
              formContainer="flex-1"
              options={options.filter(op => !op.selected)}
              placeholder="Select"
              name={`positions-${index}`}
              value={item.job_role && { label: item.job_role?.label, value: item.job_role?.value }}
              height={58}
              onChange={(e) => onSelect(e, index)}
              errorMessage={errors[`${title}.${index}.${fieldKey}`]}
            />
            <div className="flex space-x-3 items-baseline">
              <Input
                inputContainerClass="flex-1"
                name="role"
                placeholder="e.g. co-writer with John Smith / Hamlet"
                optional={true}
                noLabelPaddingY="1rem"
                type="text"
                value={item.description}
                onChange={(e) => onChange(e, index)}
              />
              {/* button remove */}
              <button
                className="h-6.5 w-6.5 bg-lightBlack rounded-full"
                onClick={() => onRemove(index)}
              >
                <FaMinus className="m-auto text-white" />
              </button>
            </div>
          </div>
        ))}
      </div>
      {!hideAddButton && (
        <Button
          buttonName="Add Another Position"
          buttonTextClass="text-sm text-white font-semibold flex items-center"
          iconPosition="left"
          icon={<FaPlus className="mr-3" />}
          buttonRadius="6px"
          buttonClass="relative py-4 px-9 bg-lightBlack"
          onClick={onAdd}
        />
      )}
    </div>
  );
};

export default InputGroup;
