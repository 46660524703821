import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from './actions'

export const useCategoryFilter = () => {
    const {
        refresh,
        initialize,
        options = [],
    } = useSelector((state) => state.categoryFilter);

    const dispatch = useDispatch();

    const refreshCategoryFilter = useCallback(
        (payload) => dispatch(actions.refreshCategoryFilter(payload)),
        [dispatch]
    );

    const setCategoryFilter = useCallback(
        (payload) => dispatch(actions.setCategoryFilter(payload)),
        [dispatch]
    );

    return {
        refresh,
        initialize,
        options,
        refreshCategoryFilter,
        setCategoryFilter
    };
} 