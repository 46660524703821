import Button from "components/button/button";
import { useHistory } from "react-router-dom";

import LockBlueIcon from "assets/svg/upgrade/lock-blue-icon";

export default function UpgradePremium({ styleProp = "", onClick }) {
  const history = useHistory();

  const navigate = () => {
    if (typeof onClick === "undefined") {
      history.push("/plans");
    } else {
      onClick();
    }
  };

  return (
    <div className="w-full">
      <div
        className={
          styleProp === "list" ? "border-2 border-gray rounded-md" : "p-6"
        }>
        <div
          className={`flex flex-col justify-center items-center p-6 ${
            styleProp === "list" && "md:flex-row md:space-x-4"
          }`}>
          <LockBlueIcon />

          <div
            className={`flex-1 text-center mt-4 ${
              styleProp === "list" && "md:mt-0 md:text-start"
            }`}>
            <div
              className={`flex flex-col items-center mb-4 ${
                styleProp === "list"
                  ? "md:flex-row justify-center md:justify-start md:mb-0.5"
                  : "md:flex-row sm:justify-center"
              }`}>
              <p className="font-semibold text-black text-xl">
                Upgrade to Unlock
              </p>
              <p className="text-xs text-primary font-bold border rounded-md ml-2 px-2 py-1">
                Premium
              </p>
            </div>
            <p className="text-darkGray">
              Upgrade to connect with others and to access all milestones
              features.
            </p>
          </div>

          <Button
            onClick={navigate}
            // onClick={() => history.push('/plans')}
            buttonName="Upgrade"
            buttonTextClass={`text-sm text-white font-semibold`}
            buttonRadius="6px"
            buttonClass={`relative py-4 px-8 border-2 border-black rounded-md bg-black mt-8 ${
              styleProp === "list" && "md:mt-0"
            }`}
          />
        </div>
      </div>
    </div>
  );
}
