const ResumeStepThreeIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3767_1809)">
        <path
          d="M3.18379 0H31.0712C31.4871 0.261545 31.5928 0.643684 31.5912 1.11979C31.5779 4.51596 31.5912 7.91292 31.5748 11.3091C31.5732 11.667 31.6773 11.7288 32.0062 11.7249C33.5152 11.7069 35.025 11.7194 36.5347 11.7147C36.8245 11.7139 37.0743 11.7813 37.2638 12.0107C37.4533 12.241 37.4431 12.5166 37.4431 12.7907C37.4431 17.3716 37.4431 21.9526 37.4431 26.5335C37.4431 29.7222 37.4501 32.9101 37.4407 36.0987C37.4337 38.3328 35.8707 39.9906 33.7376 39.993C24.6156 40.0023 15.4929 40.0008 6.37088 39.993C4.62072 39.9914 3.18222 38.809 2.76563 37.1003C2.73431 36.9734 2.78756 36.8066 2.63799 36.715V36.5584C2.75702 36.3525 2.68106 36.1293 2.68106 35.9171C2.68576 24.3402 2.68576 12.7633 2.68263 1.18557C2.68263 1.02112 2.73274 0.848065 2.63721 0.690668C2.7484 0.404847 2.92694 0.171492 3.18379 0ZM30.7118 38.4339C30.058 37.5459 30.0071 36.5842 30.0078 35.5976C30.0141 24.4028 30.0094 13.208 30.0212 2.01327C30.0212 1.6327 29.9256 1.55283 29.5552 1.55361C21.2508 1.56536 12.9463 1.56457 4.64187 1.55518C4.32002 1.55518 4.23624 1.62644 4.23624 1.95533C4.24563 13.3192 4.24407 24.6831 4.24485 36.0471C4.24485 37.5748 5.11014 38.4339 6.64261 38.4339C13.5156 38.4339 20.3878 38.4339 27.2608 38.4339H30.7126H30.7118ZM35.8707 24.9972C35.8707 21.222 35.866 17.446 35.8793 13.6708C35.8801 13.338 35.7892 13.2668 35.4705 13.2723C34.3124 13.2926 33.1534 13.2965 31.9953 13.2707C31.6405 13.2629 31.5771 13.3686 31.5771 13.6998C31.5873 21.1468 31.5842 28.593 31.5857 36.04C31.5857 36.2217 31.5912 36.4049 31.6116 36.5858C31.7455 37.7589 32.9342 38.6273 34.0908 38.4002C35.1972 38.1833 35.8675 37.3368 35.8691 36.1285C35.873 32.4183 35.8707 28.7081 35.8707 24.9972Z"
          fill="#333333"
        />
        <path
          d="M2.68028 35.9178C2.68028 36.13 2.75623 36.3531 2.63721 36.5591V0.692139C2.73274 0.849536 2.68263 1.02259 2.68263 1.18704"
          fill="#333333"
        />
        <path
          d="M13.0511 23.4664C10.6338 22.4124 9.77003 21.1125 10.1428 19.1853C10.4497 17.5973 11.9125 16.3397 13.5554 16.3389C15.2883 16.3389 16.6344 17.3835 17.0667 19.0577C17.3274 20.0678 17.1348 21.0216 16.5561 21.8869C15.9798 22.7491 15.1364 23.2189 14.1349 23.4585C15.2946 23.5807 16.3416 23.9621 17.264 24.6504C18.8834 25.8587 19.762 27.4671 19.827 29.4991C19.8372 29.8116 19.8059 30.1264 19.8356 30.4357C19.8732 30.8241 19.7471 30.9494 19.3415 30.9463C16.6086 30.9282 13.8749 30.9369 11.1412 30.9369C10.0347 30.9369 8.92823 30.9282 7.82175 30.9423C7.51009 30.9463 7.39498 30.864 7.38715 30.5297C7.35818 29.2822 7.431 28.0677 8.0277 26.926C9.04099 24.9847 10.6314 23.8728 12.7723 23.5126C12.8342 23.5024 12.896 23.4914 13.0519 23.4648L13.0511 23.4664Z"
          fill="#0C6ED1"
        />
        <path
          d="M17.1263 3.90527C20.3016 3.90527 23.4777 3.90527 26.6531 3.90527C27.4189 3.90527 27.6773 4.16447 27.6781 4.94049C27.6797 7.37427 27.6797 9.80806 27.6781 12.2418C27.6781 13.0194 27.4189 13.2802 26.6562 13.2802C20.3048 13.2802 13.9541 13.2802 7.60258 13.2802C6.83674 13.2802 6.57832 13.021 6.57832 12.2442C6.57676 9.8104 6.57676 7.37662 6.57832 4.94284C6.57832 4.15116 6.83282 3.90527 7.63938 3.90527C10.8022 3.90527 13.9642 3.90527 17.1271 3.90527H17.1263ZM17.1067 11.7172C19.9829 11.7172 22.8583 11.7117 25.7346 11.725C26.0493 11.7266 26.1143 11.6334 26.1128 11.3374C26.0995 9.50266 26.1002 7.66714 26.1128 5.83241C26.1143 5.5552 26.065 5.46045 25.758 5.46045C20.0064 5.47141 14.254 5.47141 8.50232 5.46045C8.20084 5.46045 8.14211 5.54502 8.14368 5.82849C8.15699 7.66323 8.16091 9.49874 8.14133 11.3335C8.13741 11.6788 8.25253 11.725 8.55792 11.7234C11.4075 11.7117 14.2579 11.7164 17.1075 11.7164L17.1067 11.7172Z"
          fill="#333333"
        />
        <path
          d="M17.1317 35.639C13.9829 35.639 10.8334 35.639 7.68471 35.639C7.54141 35.639 7.39733 35.6445 7.25559 35.6273C6.90008 35.5858 6.63932 35.3172 6.59546 34.9625C6.55083 34.6054 6.73563 34.2812 7.06061 34.1449C7.24541 34.0674 7.44118 34.0713 7.63695 34.0713C13.961 34.0713 20.2851 34.0713 26.6084 34.0713C26.79 34.0713 26.9717 34.0635 27.1479 34.1293C27.5026 34.2608 27.7023 34.578 27.6593 34.9539C27.617 35.3235 27.3452 35.5936 26.9678 35.6296C26.8261 35.643 26.682 35.6383 26.5387 35.6383C23.4025 35.6383 20.2671 35.6383 17.1309 35.6383L17.1317 35.639Z"
          fill="#333333"
        />
        <path
          d="M25.2893 20.0144C24.7827 20.0144 24.276 20.0223 23.7702 20.0121C23.287 20.0027 22.9613 19.6918 22.9519 19.2549C22.9425 18.8273 23.269 18.4647 23.7381 18.4569C24.7897 18.4389 25.8414 18.4381 26.893 18.4569C27.3676 18.4655 27.6816 18.8234 27.6699 19.2603C27.6581 19.6989 27.3347 20.0035 26.8468 20.0121C26.3277 20.0215 25.8085 20.0144 25.2885 20.0137L25.2893 20.0144Z"
          fill="#333333"
        />
        <path
          d="M25.2843 23.9204C24.7777 23.9204 24.2711 23.9283 23.7652 23.9181C23.2844 23.9079 22.9578 23.5939 22.9508 23.157C22.9437 22.7294 23.2711 22.3708 23.7417 22.3629C24.7934 22.3457 25.845 22.3441 26.8967 22.3645C27.3697 22.3731 27.6829 22.7349 27.6688 23.1718C27.6547 23.6104 27.3313 23.9111 26.8426 23.9197C26.3235 23.9283 25.8043 23.922 25.2843 23.9212V23.9204Z"
          fill="#333333"
        />
        <path
          d="M25.3307 27.8265C24.8115 27.8265 24.2916 27.8336 23.7724 27.8242C23.2877 27.8148 22.9619 27.507 22.951 27.0701C22.94 26.6425 23.2657 26.2776 23.734 26.269C24.7857 26.2502 25.8373 26.2502 26.889 26.269C27.3635 26.2776 27.6799 26.6331 27.6697 27.0693C27.6596 27.5078 27.3354 27.8148 26.8499 27.8242C26.344 27.8336 25.8373 27.8265 25.3307 27.8265Z"
          fill="#333333"
        />
        <path
          d="M25.3021 31.7324C24.7955 31.7324 24.2889 31.7395 23.783 31.7308C23.2928 31.7222 22.9686 31.4239 22.9514 30.9869C22.9334 30.5445 23.2709 30.1804 23.7626 30.1733C24.801 30.1577 25.8401 30.1569 26.8785 30.1741C27.3569 30.1819 27.6741 30.528 27.6694 30.965C27.6647 31.4035 27.3405 31.7199 26.8597 31.7301C26.3405 31.7402 25.8213 31.7324 25.3014 31.7324H25.3021Z"
          fill="#333333"
        />
      </g>
      <defs>
        <clipPath id="clip0_3767_1809">
          <rect
            width="34.8082"
            height="40"
            fill="white"
            transform="translate(2.63721)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ResumeStepThreeIconSVG;
