import React, { useState, useRef, useEffect } from 'react';
import DOMPurify from 'dompurify';

const SeeMoreHtmlContent = ({ html }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isClamped, setIsClamped] = useState(false);
  const contentRef = useRef(null);

  // Sanitize the HTML content
  const sanitizedHtml = DOMPurify.sanitize((html === "null" || !html) ? "" : html);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const checkClamp = () => {
      const content = contentRef.current;
      if (content) { 
        setIsClamped(content.scrollHeight > content.clientHeight);
      }
    };

    checkClamp();
    window.addEventListener('resize', checkClamp);

    return () => {
      window.removeEventListener('resize', checkClamp);
    };
  }, [html]);

  return (
    <div className='html-wrapper mt-0 2sm:mt-1.5'>
      <div
        ref={contentRef}
        className={`html-content text-[13px] 2sm:text-sm 3md:text-base leading-5 2sm:leading:5 ${isExpanded ? 'expanded' : 'collapsed'}`}
        dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
      />
      {isClamped && (
        <button onClick={toggleExpand} className='text-[13px] 2sm:text-sm 3md:text-base'>
          {isExpanded ? 'See Less' : 'See More'}
        </button>
      )}
    </div>
  );
};

export default SeeMoreHtmlContent;
