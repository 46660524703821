const PlusCircleIconSVG = ({ className = "" }) => {
  return (
    <svg
    className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.6848 18.6848C14.4813 18.8589 18.5107 15.1117 18.6848 10.3152C18.8589 5.51876 15.1117 1.48932 10.3153 1.31522C5.51879 1.14113 1.48936 4.88831 1.31527 9.68477C1.14117 14.4812 4.88834 18.5107 9.6848 18.6848Z"
        stroke="#404040"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 6.52393V13.4763"
        stroke="#404040"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.52374 10H13.4761"
        stroke="#404040"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PlusCircleIconSVG;
