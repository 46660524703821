const PhoneIconSVG = ({ className = "", stroke = "#EF6B33" }) => {
  return (
    <svg
      // width="13"
      // height="13"
      // viewBox="0 0 13 13"
      width="11"
      height="11"
      viewBox="0 0 11 11"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1383_1922)">
        <path d="M6.38502 2.58334C6.792 2.66274 7.16602 2.86178 7.45922 3.15498C7.75242 3.44818 7.95145 3.8222 8.03086 4.22917M6.38502 0.916672C7.23055 1.0106 8.01902 1.38924 8.62096 1.99042C9.22289 2.59161 9.60253 3.37959 9.69752 4.225M9.28086 7.55V8.8C9.28133 8.91605 9.25756 9.03091 9.21107 9.13723C9.16458 9.24356 9.0964 9.339 9.01089 9.41745C8.92538 9.4959 8.82443 9.55562 8.7145 9.5928C8.60457 9.62998 8.4881 9.64378 8.37252 9.63334C7.09037 9.49402 5.85877 9.0559 4.77669 8.35417C3.76995 7.71445 2.91641 6.86091 2.27669 5.85417C1.57251 4.76717 1.13429 3.52959 0.997523 2.24167C0.98711 2.12645 1.0008 2.01032 1.03773 1.90068C1.07466 1.79104 1.13401 1.69029 1.21201 1.60485C1.29001 1.5194 1.38494 1.45113 1.49077 1.40439C1.5966 1.35764 1.711 1.33345 1.82669 1.33334H3.07669C3.2789 1.33135 3.47494 1.40295 3.62826 1.53481C3.78158 1.66667 3.88172 1.84977 3.91002 2.05001C3.96278 2.45003 4.06063 2.84281 4.20169 3.22084C4.25775 3.36997 4.26988 3.53205 4.23665 3.68787C4.20342 3.84369 4.12622 3.98672 4.01419 4.10001L3.48502 4.62917C4.07817 5.67232 4.94188 6.53602 5.98502 7.12917L6.51419 6.60001C6.62748 6.48798 6.7705 6.41078 6.92632 6.37754C7.08214 6.34431 7.24422 6.35645 7.39336 6.4125C7.77139 6.55357 8.16416 6.65141 8.56419 6.70417C8.76659 6.73273 8.95144 6.83467 9.08358 6.99063C9.21572 7.14658 9.28593 7.34566 9.28086 7.55Z" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_1383_1922">
          <rect width="10" height="10" fill="white" transform="translate(0.114258 0.5)"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default PhoneIconSVG;
