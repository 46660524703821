export const SET_FEATURED_MILESTONE = 'SET_FEATURED_MILESTONE'
export const SET_CURRENT_MILESTONES = 'SET_CURRENT_MILESTONES'
export const SET_PAST_MILESTONES = 'SET_PAST_MILESTONES'
export const SET_MILESTONES = 'SET_MILESTONES'

export const REFRESH_MILESTONES = 'REFRESH_MILESTONES'
export const GET_MILESTONES = 'GET_MILESTONES'
export const FINISH_GET_MILESTONES = 'FINISH_GET_MILESTONES'

export const SET_IS_FEATURED = 'SET_IS_FEATURED'
export const SET_ORGANIZE_BY = 'SET_ORGANIZE_BY'
export const SET_JOB_ROLE = 'SET_JOB_ROLE'
export const SET_CATEGORY = 'SET_CATEGORY'
export const SET_FILTERS = 'SET_FILTERS'

export const CLEAR_FILTERS = 'CLEAR_FILTERS'

export const ADD_MILESTONES = 'ADD_MILESTONES'

export const UPDATE_MILESTONES = 'UPDATE_MILESTONES'
export const UPDATE_FEATURED_MILESTONE = 'UPDATE_FEATURED_MILESTONE'
export const UPDATE_CURRENT_MILESTONE = 'UPDATE_CURRENT_MILESTONE'
export const UPDATE_PAST_MILESTONE = 'UPDATE_PAST_MILESTONE'

export const DELETE_MILESTONE = 'DELETE_MILESTONE'
export const SET_PINNED_MILESTONES = 'SET_PINNED_MILESTONES'
export const SET_PLAY_MUSIC = 'SET_PLAY_MUSIC'
export const UPDATE_PINNED_MILESTONES = 'UPDATE_PINNED_MILESTONES';
