export default function FabButton({ onClick = () => {}, children, ...props }) {
    return (
        <div className="hidden sm:block">
            <button
                title="Contact Sale"
                className=" fixed z-999 bottom-6.5 right-8 bg-primary w-16 h-16 rounded-full drop-shadow-lg flex justify-center items-center text-white text-xl hover:bg-primary hover:drop-shadow-2xl"
                onClick={onClick}
                {...props}
            >
                {children}
            </button>
        </div>
    );
}
