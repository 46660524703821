const UsersIconSVG = ({ className = "" }) => (
  <svg
    className={className}
    width="12"
    height="10"
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.27064 9.21103V8.24697C8.27064 7.7356 8.0675 7.24517 7.70591 6.88358C7.34432 6.52199 6.85389 6.31885 6.34252 6.31885H2.48628C1.97492 6.31885 1.48449 6.52199 1.1229 6.88358C0.761307 7.24517 0.558167 7.7356 0.558167 8.24697V9.21103"
      stroke="#777777"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.41445 4.3909C5.47932 4.3909 6.34257 3.52766 6.34257 2.46279C6.34257 1.39792 5.47932 0.534668 4.41445 0.534668C3.34958 0.534668 2.48633 1.39792 2.48633 2.46279C2.48633 3.52766 3.34958 4.3909 4.41445 4.3909Z"
      stroke="#777777"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.1628 9.21135V8.24729C11.1624 7.82008 11.0203 7.40507 10.7585 7.06743C10.4968 6.72979 10.1303 6.48864 9.71667 6.38184"
      stroke="#777777"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.78864 0.597168C8.20338 0.70336 8.57099 0.944567 8.8335 1.28276C9.09602 1.62096 9.2385 2.03691 9.2385 2.46503C9.2385 2.89316 9.09602 3.3091 8.8335 3.6473C8.57099 3.9855 8.20338 4.22671 7.78864 4.3329"
      stroke="#777777"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default UsersIconSVG;
