import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from './actions'

export const useResume = () => {

    const {
        resumes,
        initialize
    } = useSelector((state) => state.resume);

    const dispatch = useDispatch();

    const refreshResumes = useCallback(
        () => dispatch(actions.refreshResumes()),
        [dispatch]
    );

    const setResumes = useCallback(
        (payload) => dispatch(actions.setResumes(payload)),
        [dispatch]
    );

    const addResume = useCallback(
        (payload) => dispatch(actions.addResume(payload)),
        [dispatch]
    );

    const updateResume = useCallback(
        (payload) => dispatch(actions.updateResume(payload)),
        [dispatch]
    );

    return {
        initialize,
        resumes,
        setResumes,
        addResume,
        updateResume,
        refreshResumes
    };
} 