import React, { useLayoutEffect } from "react";
import { FaChevronRight } from "react-icons/fa";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from "react-router-dom";

export default function Breadcrumbs({
  title = "",
  subtitle = "",
  pageName = "",
  parent = null,
  showBanner = true,
  parentUrl = "",
  currentUrl = "",
}) {
  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const bannerHeadingTl = gsap.timeline({
      scrollTrigger: {
        trigger: '.plan-banner',
        start: 'top center',
        endTrigger: '.plan-banner',
        toggleActions: 'play reverse play reset',
      }
    })
    bannerHeadingTl.fromTo('.plan-banner-heading', { y: -20, opacity: 0 }, { y: 0, opacity: 1 })

    const bannerDescTl = gsap.timeline({
      scrollTrigger: {
        trigger: '.plan-banner',
        start: 'top center',
        endTrigger: '.plan-banner',
        toggleActions: 'play reverse play reset',
      }
    })
    bannerDescTl.fromTo('.plan-banner-description', { y: 20, opacity: 0 }, { y: 0, opacity: 1 })

    const breadcrumbsTl = gsap.timeline({
      scrollTrigger: {
        trigger: '.plan-banner',
        start: 'top center',
        endTrigger: '.plan-banner',
        toggleActions: 'play reverse play reset',
      }
    })
    breadcrumbsTl.fromTo('.plan-breadcrumbs', { opacity: 0 }, { opacity: 1, duration: 1 })
  }, [])

  return (
    <div className="plan-banner w-full">
      {showBanner && (
        <div className="breadcrumbs-banner">
          <p className="plan-banner-heading text-5.5xl font-bold tracking-tighter">{title}</p>
          <p className="plan-banner-description text-2xl">{subtitle}</p>
        </div>
      )}
      <div className="text-base bg-white py-2.5 px-7.5 3md:px-24 lg:px-37">
        <div className="plan-breadcrumbs w-full flex items-center space-x-4">
          <Link to="/" className="text-darkerGray leading-7.5 tracking-tightest">Home</Link>
          {parent ? (
            <>
              <FaChevronRight className="text-xs text-lightBlack" />
              {parentUrl ?
                <Link to={parentUrl} className="text-darkerGray leading-7.5 tracking-tightest">{parent}</Link>
                :
                <p className="text-darkerGray leading-7.5 tracking-tightest">
                  {parent}
                </p>
              }

              <FaChevronRight className="text-xs text-lightBlack" />
              {currentUrl ?
                <Link to={currentUrl} className="text-darkerGray leading-7.5 tracking-tightest">{pageName}</Link>
                :
                <p className="text-lightBlack leading-7.5 tracking-tightest">
                  {pageName}
                </p>
              }
            </>
          ) : (
            <>
              <FaChevronRight className="text-xs text-lightBlack" />
              {currentUrl ?
                <Link to={currentUrl} className="text-lightBlack leading-7.5 tracking-tightest">{pageName}</Link>
                :
                <p className="text-lightBlack leading-7.5 tracking-tightest">
                  {pageName}
                </p>
              }
            </>
          )}
        </div>
      </div>
    </div>
  );
}
