import React, { useEffect, useState } from "react";
import Button from "components/button/button";
import MilestoneList from "components/milestonelist/milestonelist";
import _ from "lodash";
import LoadingIndicator from "assets/svg/loading-indicator";
import PopInfo from "components/info-popup/pop-info";
import Toggle from "components/toggle/toggle";

export default function MilestonePanel({
  userData,
  onNext,
  onBack,
  isEdit = false,
  list = {},
  show_image,
  onChangeMilestones,
  onChangeToggleImage,
  onChangeToggleImageMilestone,
  show_image_milestone = true,
}) {
  const { http } = global.services;
  const [loading, setLoading] = useState(false);
  const [milestones, setMilestones] = useState({
    featured: [],
    current: [],
    past: [],
  });

  const title = [
    "All Featured Milestones",
    "All Current Milestones",
    "All Past Milestones",
  ];

  const handleChange = (key, data) => {
    onChangeMilestones(key, data);
  };

  const getMilestones = async (type) => {
    try {
      const { data } = await http.get("public/milestones/get-milestones", {
        user_profile_id: userData?.profile?.id,
        type,
      });

      if (type === "featured") {
        setMilestones((prev) => ({
          ...prev,
          featured: data,
        }));
        if (!_.isEmpty(data) && !isEdit) {
          onChangeMilestones("featured", data);
        }
      } else if (type === "current") {
        setMilestones((prev) => ({
          ...prev,
          current: data,
        }));
        if (!_.isEmpty(data) && !isEdit) {
          onChangeMilestones("current", data);
        }
      } else {
        setMilestones((prev) => ({
          ...prev,
          past: data,
        }));
        if (!_.isEmpty(data) && !isEdit) {
          onChangeMilestones("past", data);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAll = async () => {
    setLoading(true);
    await Promise.all([
      getMilestones("featured"),
      getMilestones("current"),
      getMilestones("past"),
    ])
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error(`Failed to fetch: ${error}`);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!_.isEmpty(userData)) {
      getAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <div className="w-full h-full border-r border-resumeBorder">
      <div
        className="w-full overflow-y-auto overflow-x-hidden custom-scroll"
        style={{ height: "calc(100vh - 174px)" }}>
        <div className="w-full px-2 2sm:px-15.5 py-10.6">
          <div className="flex items-center flex-col 2sm:flex-row">
            <div className="w-full flex flex-row gap-x-2 items-center">
              <h1 className="text-lightBlack text-sm 2sm:text-md font-semibold">
                Show/Hide Milestones
              </h1>
              <PopInfo containerClass="relative" type="show_hide_milestones" />
            </div>
            <div className="w-full 3md:w-14/20 flex items-center justify-start 2sm:justify-end">
              <div className="flex space-x-2">
                <div>
                  <Toggle
                    checked={show_image_milestone}
                    name="dateFormat"
                    onChange={(e) =>
                      onChangeToggleImageMilestone(e?.target?.checked)
                    }
                  />
                </div>
                <div className="flex items-center">
                  <p className="text-xs font-medium text-lightBlack">
                    Show/Hide Images
                  </p>
                </div>
              </div>
            </div>
          </div>

          {loading ? (
            <div
              className="flex flex-col justify-center items-center text-primary"
              style={{ height: "calc(100vh - 300px)" }}>
              <LoadingIndicator className="w-10 h-10" />
              <p className="text-secondary">
                Getting your milestone ready. Please wait ...
              </p>
            </div>
          ) : (
            <div className="w-full mt-6 border-b border-resumeBorder">
              {Object.keys(milestones).map((k, i) => {
                let content = isEdit ? list[k] : milestones[k];
                return (
                  <MilestoneList
                    key={`${k}-${i}`}
                    name={k}
                    title={title[i]}
                    content={content}
                    isEdit={isEdit}
                    milestones={milestones[k]}
                    onChange={(key, data) => handleChange(key, data)}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
      {/* footer */}
      <div className="w-full py-4 px-6 border-t border-resumeBorder">
        <div className="w-full flex justify-center 2sm:justify-end space-x-5">
          <Button
            buttonName="Back"
            buttonTextClass="text-sm font-semibold text-lightBlack"
            buttonRadius="6px"
            buttonClass="bg-thBg px-12 py-3 2sm:px-17.5 2sm:py-5"
            onClick={onBack}
          />
          <Button
            buttonName="Next"
            buttonTextClass="text-sm font-semibold text-white"
            buttonRadius="6px"
            buttonClass="bg-primary px-12 py-3 2sm:px-17.5 2sm:py-5"
            onClick={onNext}
          />
        </div>
      </div>
    </div>
  );
}
