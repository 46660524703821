import React, { useState, useContext } from "react";
import Modal from "components/global/modal";
import ModalHeader from "components/global/modal-header";
import Button from "components/button/button";
import CCIconSVG from "assets/svg/cc-icon-svg";
import CreditCardIconSVG from "assets/svg/credit-card-icon-svg";
import PaypalIconSVG from "assets/svg/paypal-icon-svg";
import CustomCheckbox from "components/checkbox/custom-checkbox";
import { SubscriptionContext } from "context/subscription";
import "./styles.css";
import { BASE_URL } from "configs/api";

export default function ChangePaymentMethod({
  hide,
  wallet,
  payment_method,
  subscription,
  ...props
}) {
  const [paymentMethod, setPaymentMethod] = useState({});
  const [typeModal, setTypeModal] = useState("change");
  const [isLoading, setLoading] = useState(false);
  const [choice, setChoice] = useState({
    isCredit: false,
    isPaypal: false,
    id: payment_method?.id || null,
    table: payment_method?.table || null,
  });
  const { http } = global.services;
  const { updateSubscription } = useContext(SubscriptionContext);

  const selectPaymentMethod = (method, item) => {
    // if (method === 'credit') {
    //   setChoice({
    //     isCredit: true,
    //     isPaypal: false,
    //   })
    // } else {
    //   setChoice({
    //     isCredit: false,
    //     isPaypal: true,
    //   })
    // }
    setChoice({ ...choice, id: item.id, table: item.table });

    setPaymentMethod({});
  };

  const proceed = async () => {
    if (typeModal === "change") {
      setTypeModal("confirm");
    } else {
      try {
        let params = {
          ...choice,
          current_subscription: { ...subscription },
          success_url: `${BASE_URL}/subscription/success`,
          cancel_url: `${BASE_URL}/settings`,
        };
        setLoading(true);
        const { data } = await http.post(
          "public/change-payment-method",
          params
        );
        if (data.url) {
          window.location.href = data.url;
        }
        payment_method = wallet.find(
          (item) => item.id === choice.id && item.table === choice.table
        );
        console.log(payment_method);
        updateSubscription({
          wallet,
          subscription,
          payment_method,
        });
        hide(paymentMethod);
        setLoading(false);
      } catch (err) {
        console.log(err);
        alert(err);
        setLoading(false);
      }
    }
  };

  const Footer = () => {
    return (
      <>
        <div className="w-full flex items-center justify-start 1sm:justify-end space-x-2">
          <Button
            buttonName="Cancel"
            buttonClass="bg-thBg py-4 px-8 hover:bg-gray"
            buttonTextClass="text-black text-sm font-semibold"
            onClick={() => hide()}
          />
          <Button
            key="save-btn-category"
            buttonName={`${typeModal === "change" ? "Continue" : "Confirm"}`}
            buttonClass="bg-primary py-4 px-8 hover:bg-darkPrimary"
            buttonTextClass="text-white text-sm font-semibold flex items-center"
            onClick={proceed}
            disabled={isLoading || wallet.length <= 1}
          />
        </div>
      </>
    );
  };

  return (
    <Modal
      footer={<Footer />}
      width="w-600"
      contentClassName="rounded-lg overflow-auto custom-scroll"
      wrapperClassName="overflow-hidden"
      footerClass="border-t border-gray">
      <div className="w-full">
        {typeModal === "change" ? (
          <>
            {/* header */}
            <ModalHeader
              title="Change Payment Method"
              hide={hide}
              showPopInfo
              popInfoType="change_payment_method"
            />
            <div className="p-8 px-12">
              {wallet.map((item) => {
                return (
                  <div
                    className={`${
                      choice.id === item.id && choice.table === item.table
                        ? "border-primary bg-planBg"
                        : "border-gray"
                    } border-2 rounded-md p-6 cursor-pointer mb-4`}>
                    <div className="flex justify-between items-center h-16">
                      {item.table === "credit_cards" ? (
                        <>
                          <CustomCheckbox
                            name={`credit`}
                            checked={
                              choice.id === item.id &&
                              choice.table === item.table
                            }
                            containerStyle="rounded-full flex items-center"
                            label={
                              <div>
                                <h1 className="font-bold text-lg">
                                  Credit Card
                                </h1>
                                <div className="flex flex-row items-center">
                                  <CCIconSVG className="w-8 h-8 mr-2" />
                                  <p className="font-semibold">
                                    **** **** **** {item.last_digits}
                                  </p>
                                </div>
                              </div>
                            }
                            onChange={() => selectPaymentMethod("credit", item)}
                          />
                          <div className="">
                            <CreditCardIconSVG />
                          </div>
                        </>
                      ) : (
                        <>
                          <CustomCheckbox
                            name={`paypal`}
                            checked={
                              choice.id === item.id &&
                              choice.table === item.table
                            }
                            containerStyle="rounded-lg flex items-center"
                            label={
                              <div>
                                <h1 className="font-bold text-lg">Paypal</h1>
                                <div className="flex flex-row items-center">
                                  <p className="font-semibold">
                                    {item.subscriber}
                                  </p>
                                </div>
                              </div>
                            }
                            onChange={() => selectPaymentMethod("paypal", item)}
                          />
                          <div className="">
                            <PaypalIconSVG />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
              {/* <div className={`${choice.isCredit ? 'border-primary bg-planBg' : 'border-gray'} border-2 rounded-md p-6 cursor-pointer mb-4`}>
                <div className="flex justify-between items-center h-16">
                  <CustomCheckbox
                    name={`credit`}
                    checked={choice.isCredit}
                    containerStyle="rounded-full flex items-center"
                    label={
                      <div>
                        <h1 className="font-bold text-lg">Credit Card</h1>
                        <div className="flex flex-row items-center">
                          <CCIconSVG className="w-8 h-8 mr-2" />
                          <p className="font-semibold">**** **** **** 2199</p>
                        </div>
                      </div>
                    }
                    onChange={() => selectPaymentMethod('credit')}
                  />
                  <div className="">
                    <CreditCardIconSVG />
                  </div>
                </div>
              </div>
              <div className={`${choice.isPaypal ? 'border-primary bg-planBg' : 'border-gray'} border-2 rounded-md p-6 cursor-pointer mb-4`}>
                <div className="flex justify-between items-center h-16">
                  <CustomCheckbox
                    name={`paypal`}
                    checked={choice.isPaypal}
                    containerStyle="rounded-lg flex items-center"
                    label={
                      <h1 className="font-bold text-lg">Paypal</h1>
                    }
                    onChange={() => selectPaymentMethod('paypal')}
                  />
                  <PaypalIconSVG />
                </div>
              </div> */}
            </div>
          </>
        ) : (
          <>
            <ModalHeader title="Confirm Change Payment Method" hide={hide} />
            {/* <div className="bg-siteBg px-10 py-2">
              <div className="py-6 border-b border-gray">
                <p className="text-darkerGray font-semibold text-lightgray">Current Method:</p>
                <div className="flex items-center gap-x-2">
                  <h1 className="font-bold text-lg">Credit Card</h1>
                  <div className="flex flex-row items-center">
                    <CCIconSVG className="w-8 h-8 mr-2" />
                    <p className="font-semibold">**** **** **** 2199</p>
                  </div>
                </div>
              </div>
              <div className="py-6">
                <div className="font-semibold">
                  <p className="text-darkerGray font-semibold">Payment Method:</p>
                  <div className="flex items-center gap-x-2">
                    <h1 className="font-bold text-lg">Paypal</h1>
                    <p>paypal.com/username</p>
                  </div>
                </div>
              </div>
            </div> */}

            {wallet.map((item) => {
              return (
                <div className="bg-siteBg px-10 py-2">
                  <div className="py-2 border-b border-gray">
                    <div className="flex items-center gap-x-2">
                      {item.table === "credit_cards" ? (
                        <div>
                          <p className="text-darkerGray font-semibold text-lightgray">
                            {`${
                              choice.id === item.id &&
                              choice.table === item.table
                                ? "Current Method:"
                                : "Payment Method:"
                            }`}
                          </p>
                          <div className="flex flex-row items-center gap-3">
                            <h1 className="font-bold text-lg">Credit Card</h1>
                            <CCIconSVG className="w-8 h-8" />
                            <p className="font-semibold">
                              **** **** **** {item.last_digits}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className="">
                          <p className="text-darkerGray font-semibold text-lightgray">
                            {`${
                              choice.id === item.id &&
                              choice.table === item.table
                                ? "Current Method:"
                                : "Payment Method:"
                            }`}
                          </p>
                          <div className="flex gap-3">
                            <h1 className="font-bold text-lg">Paypal</h1>
                            <p>{item.subscriber}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="px-10 pt-6 pb-8">
              <p className="text-darkerGray font-sm">
                Your bill will start charging your new method on 29 Jan 2024
              </p>
            </div>
          </>
        )}
        {/* end content */}
      </div>
    </Modal>
  );
}
