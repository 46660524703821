import {
	REFRESH_STATES,
  SET_STATES,
} from './types';

export const setRefreshStates = () => {
	return {
    type: REFRESH_STATES,
    payload: [],
  };
}

export const setStates = (params) => {
	return {
    type: SET_STATES,
    payload: params,
  };
}