const AdultContentIconSVG = ({ className = "", fill = "#FF4C61" }) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.04998 0.435C8.46998 -0.145 7.52998 -0.145 6.94998 0.435L0.435977 6.95C-0.144023 7.53 -0.144023 8.469 0.435977 9.048L6.95198 15.564C7.53198 16.144 8.47098 16.144 9.04998 15.564L15.566 9.048C16.146 8.468 16.146 7.529 15.566 6.95L9.04998 0.435ZM7.99998 4C8.53498 4 8.95398 4.462 8.89998 4.995L8.54998 8.502C8.53822 8.63977 8.47518 8.76811 8.37333 8.86164C8.27149 8.95516 8.13825 9.00705 7.99998 9.00705C7.8617 9.00705 7.72847 8.95516 7.62662 8.86164C7.52477 8.76811 7.46174 8.63977 7.44998 8.502L7.09998 4.995C7.08741 4.86923 7.10132 4.74223 7.14082 4.62217C7.18032 4.5021 7.24453 4.39165 7.32932 4.29791C7.41411 4.20418 7.51759 4.12924 7.6331 4.07793C7.74861 4.02662 7.87358 4.00007 7.99998 4ZM8.00198 10C8.26719 10 8.52155 10.1054 8.70908 10.2929C8.89662 10.4804 9.00198 10.7348 9.00198 11C9.00198 11.2652 8.89662 11.5196 8.70908 11.7071C8.52155 11.8946 8.26719 12 8.00198 12C7.73676 12 7.48241 11.8946 7.29487 11.7071C7.10733 11.5196 7.00198 11.2652 7.00198 11C7.00198 10.7348 7.10733 10.4804 7.29487 10.2929C7.48241 10.1054 7.73676 10 8.00198 10Z"
      fill={fill}
    />
  </svg>
);
export default AdultContentIconSVG;
