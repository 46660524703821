const VerifyIconSVG = ({ className = "", fill = "#0C6ED1" }) => (
  <svg
    className={className}
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.76 27.4401H13.34L10 24.0001H5.06L4 23.0001V18.1601L0.619995 14.7201V13.3001L4 9.86008V5.00008L5.06 4.00008H10L13.34 0.580078H14.76L18.2 4.00008H23.06L24.06 4.98008V9.86008L27.48 13.3001V14.7201L24 18.1601V23.0001L23 24.0001H18.2L14.76 27.4401ZM11.46 18.9601H12.88L20.42 11.4201L19 10.0001L12.18 16.8401L9.42 14.0801L8 15.5001L11.46 18.9601Z"
      fill={fill}
    />
  </svg>
);
export default VerifyIconSVG;
