import Modal from "components/global/modal";
import { FaTimes } from "react-icons/fa";
import Zoom from "./media-button/zoom";

const ImageModal = ({ hide, ...props }) => {
    const { source = null, viewUrl = "", isProfile = false } = props;
    return (
        <Modal
            width={`w-auto`}
            contentClassName="rounded-lg overflow-auto custom-style"
            wrapperClassName="overflow-visible custom-style "
            centerModal="flex items-center justify-center md:inline"
        >
            <div className="bg-black">
                <div className="flex-1 flex flex-col justify-center px-12 py-12 relative">
                    <div className="absolute z-9999 right-1 top-4 flex flex-row-reverse justify-between items-baseline mb-2">
                        <button onClick={() => hide()} className="mr-4">
                            <FaTimes className="text-white text-xl" />
                        </button>
                    </div>
                    <div className="image-zoom flex-1 flex flex-col">
                        <div className={`bg-black flex justify-center flex-1 border-4 border-white rounded-md`}>
                            <Zoom key={viewUrl || source}>
                                {({resetTransform}) => (
                                    <img
                                        src={viewUrl || source}
                                        className={`object-cove m-auto ${
                                            isProfile ? "w-[400px]" : ""
                                        }`}
                                        style={{ maxHeight: "555px", opacity: 0, transition: 'all 0.3s 0.25s' }}
                                        alt="viewImage"
                                        onLoad={(e) => {
                                            /**
                                             * sometimes the library do not center the image 
                                             * when it's not done looading the image, so we trigger
                                             * resetTransform to recenter it after it's loaded
                                             */
                                            e.target.style.opacity = 1;
                                            typeof resetTransform === 'function' && resetTransform();
                                        }}
                                    />

                                )}
                            </Zoom>
                        </div>
                    </div>
                    <div className="image-zoom-mobile border-4 border-white rounded-md">
                        <div className={`bg-black flex justify-center`}>
                            <img
                                src={viewUrl || source}
                                className={`image-zoom-mobile-image`}
                            
                                alt="viewImage"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ImageModal;
