import React, { useMemo, useState } from "react";
import { IoEllipsisHorizontal } from "react-icons/io5";
import {
  BsExclamationDiamondFill,
  BsPersonFillCheck,
  BsPersonPlus,
} from "react-icons/bs";
import { listFormat, randomExtension } from "utils/utils";
import _ from "lodash";
import { ProfileDefaultPlaceholder } from "constants/images";
import Button from "components/button/button";
import LoadingIndicator from "assets/svg/loading-indicator";

export default function FollowingItem({
  data = {},
  onProfileClick = () => {},
  onFollow = async () => {},
  type = "following",
}) {
  const fullName = data[type]
    ? `${data[type]?.user?.first_name} ${data[type]?.user?.last_name}`
    : null;
  const profileImage = data[type]?.profile_image_url
    ? data[type]?.profile_image_url
    : ProfileDefaultPlaceholder;
  const occupations = !_.isEmpty(data[type]?.occupations)
    ? listFormat(data[type]?.occupations)
    : null;
  const isAdultContent = data[type]?.show_adult === 0 ? false : true;
  const extension = randomExtension();
  const [submitLoading, setSubmitLoading] = useState(false);

  const onClickFollowing = async (id) => {
    try {
      setSubmitLoading(true);
      await onFollow(id);
      setSubmitLoading(false);
    } catch (err) {
      setSubmitLoading(false);
      console.error("Error Following", err);
    }
  };

  const follow = useMemo(() => {
    if (type === "following") {
      return true;
    } else {
      return data?.did_follow_back;
    }
  }, [data, type]);

  console.log("data => ", data);

  return (
    <div className="w-full bg-white rounded-md p-5 4sm:p-10 mb-5">
      <div className="w-full 1md:flex 1md:items-center 1md:justify-between">
        {/* left */}
        <div className="flex space-x-5 items-start">
          {/* image */}
          <div className="w-15 h-15 rounded-md overflow-hidden">
            <img
              src={profileImage}
              alt="milestone-avatar"
              className="w-full h-full"
            />
          </div>
          {/* details */}
          <div>
            <div className="flex flex-col md:flex-row md:space-x-2">
              <button
                className="flex items-center cursor-pointer"
                onClick={() =>
                  onProfileClick(
                    `${data[type]?.username}-${extension}/profile/${data[type]?.id}`
                  )
                }>
                <p className="text-base 4sm:text-lg font-bold leading-5.5 tracking-tighter">
                  {fullName}
                </p>
              </button>
              {isAdultContent && (
                <div className="flex flex-row items-center">
                  <BsExclamationDiamondFill className="text-red text-base mr-2" />
                  <p className="text-red text-sm leading-6 tracking-tighter font-medium">
                    Adult Content
                  </p>
                </div>
              )}
            </div>
            <p className="text-xs text-darkerGray leading-6">
              {data[type]?.address || ''}
            </p>
            <p className="text-base leading-5 hidden 4sm:block mt-2">
              {_.toString(occupations)}
            </p>
          </div>
        </div>
        {/* right */}
        <div className="flex flex-row space-x-3.6 mt-3.6 1md:mt-0">
          <Button
            buttonName={`${follow ? "Following" : "Follow"}`}
            buttonTextClass={`text-xs 3md:text-sm ${
              follow ? "text-white" : "text-lightBlack"
            } font-semibold flex items-center`}
            iconPosition="left"
            icon={
              follow ? (
                <BsPersonFillCheck className="mr-3 text-lg" />
              ) : (
                <BsPersonPlus className="mr-3 text-lg" />
              )
            }
            buttonRadius="6px"
            buttonClass={`relative py-2 3md:py-3.6 px-5 ${
              follow
                ? "bg-primary hover:bg-darkPrimary"
                : "bg-thBg hover:bg-gray"
            } `}
            loadingIcon={
              submitLoading ? (
                <LoadingIndicator className="text-blue-500 w-4 h-4 ml-2" />
              ) : null
            }
            onClick={() => onClickFollowing(data[type]?.id)}
          />
          {/* <button className="py-2 3md:py-3.6 px-4 bg-thBg rounded-md">
            <IoEllipsisHorizontal className="text-base 4sm:text-2xl text-lightBlack" />
          </button> */}
        </div>
      </div>
    </div>
  );
}
