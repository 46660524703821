const GraduationCapIconSVG = ({ className = "", fill = "#333333" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3708_9665)">
        <path
          d="M22.057 5.22998L14.683 1.71698C13.0598 0.747414 11.0395 0.730211 9.39997 1.67198L1.943 5.22998C1.91502 5.24399 1.886 5.25899 1.859 5.27498C0.0893762 6.28682 -0.524968 8.54165 0.486876 10.3113C0.82986 10.9112 1.33438 11.4027 1.943 11.73L4.00002 12.71V17.61C4.00124 19.8011 5.42694 21.7368 7.51902 22.388C8.97491 22.8092 10.4845 23.0154 12 23C13.5153 23.017 15.0249 22.8125 16.481 22.393C18.5731 21.7419 19.9988 19.8061 20 17.615V12.708L22.0001 11.752V20C22.0001 20.5522 22.4478 20.9999 23 20.9999C23.5523 20.9999 24 20.5522 24 20V7.99996C24.0067 6.8257 23.0795 5.74091 22.057 5.22998ZM18 17.615C18.0005 18.9256 17.1502 20.0849 15.9 20.478C14.6322 20.8402 13.3184 21.0161 12 21C10.6815 21.0161 9.36777 20.8402 8.09999 20.478C6.84978 20.0848 5.99947 18.9256 5.99999 17.615V13.663L9.317 15.243C10.1354 15.729 11.0702 15.9844 12.022 15.982C12.928 15.9884 13.8188 15.7486 14.599 15.288L18 13.663V17.615ZM21.2 9.92498L13.658 13.525C12.6064 14.1373 11.303 14.12 10.268 13.48L2.88899 9.96998C2.06647 9.52645 1.75925 8.50012 2.20278 7.67765C2.35278 7.39949 2.57769 7.16891 2.852 7.01198L10.347 3.43199C11.3989 2.82102 12.7017 2.83832 13.737 3.47699L21.111 6.98999C21.6533 7.29112 21.9926 7.85976 22 8.48001C22.001 9.06754 21.6984 9.61391 21.2 9.92498Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_3708_9665">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GraduationCapIconSVG;
