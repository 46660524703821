import React from "react";
import _ from "lodash";
import "./styles.css";

const numberClass = "page-transition border-2 border-gray px-4 py-2 rounded-md";

const Pagination = ({
  prevLabel = "Previous",
  nextLabel = "Next",
  spread = 5,
  pagination = {},
  loading = false,
}) => {
  const { size, total, lastPage, onChangePage, currentPage } = pagination;
  const disablePrev = currentPage === 1 || loading;
  const disableNext = currentPage === lastPage || loading;
  const disablePage = loading;
  const handleClickLabel = (type = "prev") => {
    if (type === "prev") {
      if (currentPage !== 1) {
        onChangePage(currentPage - 1);
      }
    } else {
      //next
      if (currentPage !== lastPage) {
        onChangePage(currentPage + 1);
      }
    }
  };

  const handleChangePage = (value = 1) => {
    if (!loading) {
      onChangePage(value);
    }
  };

  //
  const pageCount = Math.ceil(total / size);
  const pages = _.range(1, pageCount + 1);

  return (
    <div className="w-full 1sm:flex 1sm:items-center 1sm:justify-center">
      <div className="flex flex-col items-center mt-12 px-4 mx-auto">
        <div className="font-sans flex justify-end space-x-1 select-none">
          <span
            className={`flex items-center px-4 py-2 text-black bg-white border-2 border-gray rounded-md ${
              disablePrev
                ? "opacity-30"
                : "cursor-pointer hover:bg-primary hover:text-white font-bold"
            }`}
            onClick={() => handleClickLabel("prev")}
          >
            {prevLabel}
          </span>
          {pages.map((page, index) => {
            const active = currentPage === page;
            const current =
              currentPage === 1 || currentPage <= spread - 2
                ? 1
                : currentPage - 2;
            const range =
              currentPage <= spread - 2 ? spread + 1 : current + spread;
            const showNumbers = _.range(current, range);
            let result = [];

            if (!_.includes(showNumbers, 1) && page === 1) {
              result.push(
                <React.Fragment key={index}>
                  <span
                    className={`${
                      active ? "bg-primary text-white" : "bg-white text-black"
                    } ${numberClass} ${
                      disablePage
                        ? "opacity-30"
                        : "hover:bg-primary hover:text-white cursor-pointer"
                    }`}
                    onClick={() => handleChangePage(page)}
                  >
                    {page}
                  </span>
                  {!_.includes(showNumbers, 2) && (
                    <span className="px-4 py-2 text-black rounded-md bg-white border border-gray">
                      ...
                    </span>
                  )}
                </React.Fragment>
              );
            }

            if (showNumbers.includes(page)) {
              result.push(
                <span
                  key={index}
                  className={`${
                    active ? "bg-primary text-white" : "bg-white text-black"
                  } ${numberClass} ${
                    disablePage
                      ? "opacity-30"
                      : "hover:bg-primary hover:text-white cursor-pointer"
                  }`}
                  onClick={() => handleChangePage(page)}
                >
                  {page}
                </span>
              );
            }

            if (!_.includes(showNumbers, lastPage) && page === lastPage) {
              result.push(
                <React.Fragment key={index}>
                  {!_.includes(showNumbers, lastPage - 1) && (
                    <span className="px-4 py-2 text-black rounded-md bg-white border border-gray">
                      ...
                    </span>
                  )}

                  <span
                    className={`${
                      active ? "bg-primary text-white" : "bg-white text-black"
                    } ${numberClass} ${
                      disablePage
                        ? "opacity-30"
                        : "hover:bg-primary hover:text-white cursor-pointer"
                    }`}
                    onClick={() => handleChangePage(page)}
                  >
                    {page}
                  </span>
                </React.Fragment>
              );
            }
            return result;
          })}
          {/*  */}
          <span
            className={`page-transition flex items-center px-4 py-2 rounded-md bg-white border-2 border-gray ${
              disableNext
                ? "opacity-30 text-black"
                : "cursor-pointer hover:bg-primary hover:text-white font-bold"
            }`}
            onClick={() => handleClickLabel("next")}
          >
            {nextLabel}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
