const MilestoneIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_820_2958)">
        <path
          d="M2.91998 21.0094C2.34355 21.0068 1.79162 20.7759 1.38495 20.3673C0.97828 19.9588 0.749994 19.4058 0.75 18.8293V12.3094C0.75 12.0787 0.841665 11.8574 1.00482 11.6942C1.16798 11.5311 1.38926 11.4395 1.62 11.4395H5.08997V18.8293C5.08997 19.4058 4.86169 19.9588 4.45502 20.3673C4.04835 20.7759 3.49642 21.0068 2.91998 21.0094Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.08984 18.39V1.87C5.08984 1.63926 5.18151 1.41804 5.34467 1.25488C5.50782 1.09173 5.7291 1 5.95984 1H19.8799C20.1106 1 20.3319 1.09173 20.4951 1.25488C20.6582 1.41804 20.7499 1.63926 20.7499 1.87V18.39C20.7526 19.0788 20.4829 19.7407 19.9996 20.2314C19.5163 20.7222 18.8586 21.0021 18.1699 21.01H3.35986"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.58008 14.9102H17.2701"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.58008 17.5195H17.2701"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.14 4.48047H7.70996V10.5705H18.14V4.48047Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_820_2958">
          <rect
            width="21.5"
            height="21.5"
            fill="white"
            transform="translate(0 0.25)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MilestoneIconSVG;
