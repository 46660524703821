


const HeroOnlyFanIcon = ({ className = "" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"  
        viewBox="0 0 48 48" width="28px" height="28px" baseProfile="basic">
        <path fill="#35c1f1" d="M16,9C7.168,9,0,16.168,0,25s7.168,16,16,16s16-7.168,16-16C32,16.168,24.832,9,16,9z M16,29.8	c-2.656,0-4.8-2.144-4.8-4.8c0-2.656,2.144-4.8,4.8-4.8c2.656,0,4.8,2.144,4.8,4.8C20.8,27.656,18.656,29.8,16,29.8z"/><path d="M32,25c0,8.83-7.17,16-16,16c-0.35,0-0.69-0.01-1.03-0.04l3.78-12.02c1.24-0.87,2.05-2.3,2.05-3.94	c0-0.57-0.1-1.11-0.29-1.61c1.77-5.34,3.3-8.84,5.16-11.13C29.52,15.18,32,19.8,32,25z" opacity=".05"/><path d="M32,25c0,8.83-7.17,16-16,16c-0.18,0-0.35,0-0.52-0.01l3.98-12.65c0.82-0.86,1.34-2.04,1.34-3.34	c0-0.25-0.02-0.49-0.06-0.73c1.85-5.72,3.4-9.38,5.31-11.72C29.68,15.49,32,19.97,32,25z" opacity=".07"/><linearGradient id="ZrzX6RzRVkAs3aRqsVThda" x1="22.438" x2="35.216" y1="19.479" y2="26.857" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#199ae0"/><stop offset="1" stop-color="#0782d8"/></linearGradient><path fill="url(#ZrzX6RzRVkAs3aRqsVThda)" d="M33.984,21c4.065,1.17,8.864,0,8.864,0c-1.392,6.08-5.808,9.888-12.176,10.352	C28.224,37.032,22.576,41,16,41l4.8-15.256C25.734,10.062,28.263,9,39.965,9L48,9C46.656,14.92,42.024,19.443,33.984,21z"/></svg>
    );
  };
  
  export default HeroOnlyFanIcon;
  
  
  