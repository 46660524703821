import React from "react";
import { FaCheck } from "react-icons/fa";

const PasswordValidation = (props) => {
  const { passwordValidation } = props;

  return (
    <div className="w-full pb-3 pl-3 md:pl-5">
      <p className="text-lightBlack text-sm font-medium pb-1">Password must</p>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="flex flex-row items-center mb-2">
          {passwordValidation.passwordHasEightCharacters ? (
            <FaCheck className="text-green h-3 w-3 mr-1.5" />
          ) : (
            <div
              className={`p-1 border border-gray rounded-full mr-2 bg-passwordGray`}
            />
          )}
          <p
            className={`text-xs font-light ${
              passwordValidation.passwordHasEightCharacters
                ? "text-green"
                : "text-lightBlack"
            }`}
          >
            Be a minimum of 8 characters
          </p>
        </div>

        <div className="flex flex-row items-center mb-2">
          {passwordValidation.passwordHasUpperCaseLetter ? (
            <FaCheck className="text-green h-3 w-3 mr-1.5" />
          ) : (
            <div
              className={`p-1 border border-gray rounded-full mr-2 bg-passwordGray`}
            />
          )}
          <p
            className={`text-xs font-light ${
              passwordValidation.passwordHasUpperCaseLetter
                ? "text-green"
                : "text-lightBlack"
            }`}
          >
            Include at least one uppercase letter (A-Z)
          </p>
        </div>

        <div className="flex flex-row items-center mb-2">
          {passwordValidation.passwordHasNumber ? (
            <FaCheck className="text-green h-3 w-3 mr-1.5" />
          ) : (
            <div
              className={`p-1 border border-gray rounded-full mr-2 bg-passwordGray`}
            />
          )}
          <p
            className={`text-xs font-light ${
              passwordValidation.passwordHasNumber
                ? "text-green"
                : "text-lightBlack"
            }`}
          >
            Include at least one number (0-9)
          </p>
        </div>

        <div className="flex flex-row items-center mb-2">
          {passwordValidation.passwordHasLowerCaseLetter ? (
            <FaCheck className="text-green h-3 w-3 mr-1.5" />
          ) : (
            <div
              className={`p-1 border border-gray rounded-full mr-2 bg-passwordGray`}
            />
          )}
          <p
            className={`text-xs font-light ${
              passwordValidation.passwordHasLowerCaseLetter
                ? "text-green"
                : "text-lightBlack"
            }`}
          >
            Include at least one lowercase letter (a-z)
          </p>
        </div>
      </div>
    </div>
  );
};

export default PasswordValidation;
