import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useHistory } from "react-router-dom";
import EmptyLayout from "layouts/empty-layout";
import { SidebarMenu } from "components/global/tabselector";
import { TabPanel, useTabs } from "react-headless-tabs";
import TemplatePanel from "./panels/template";
import PersonalPanel from "./panels/personal";
import MoreInfoPanel from "./panels/moreinfo";
import MilestonePanel from "./panels/milestone";
import ShareDownloadPanel from "./panels/share";
import MoreInfoPanelTG from "./panels/moreinfo-tg";
import ResumePreview from "./preview/preview";
import _ from "lodash";
import { useUser } from "redux/modules/user/hooks";
import { useResume } from "redux/modules/resume/hooks";
import { resumeLinkId, randomExtension } from "utils/utils";
import toast from "react-hot-toast";
import { Context } from "context/context";
import useDebounce from "utils/customhooks";
import ResumeSideBarMobile from "./sidebar-mobile";

export const sideBarMenuList = [
  {
    label: "Template",
    value: "template",
  },
  {
    label: "Personal Info",
    value: "personal",
  },
  {
    label: "More Info",
    value: "more",
  },
  {
    label: "Milestones",
    value: "milestone",
  },
  {
    label: "Share/Download",
    value: "download",
  },
];

export default function ResumeBuilder(props) {
  const { http } = global.services;
  const history = useHistory();
  const query = useQuery();
  const isEdit = query && query.isEdit ? true : false;
  const resumeDetails = isEdit ? query.resumeDetails : null;
  const profile = JSON.parse(localStorage.getItem('user_data'))?.profile;

  const {
    state,
    setState,
    milestones,
    setMilestones,
    previewImage,
    setPreviewImage,
  } = useContext(Context);

  const extension = randomExtension();
  const { userData, socialMedia = [], setSocialMedia } = useUser();
  const { addResume, updateResume } = useResume();
  const [page, setPage] = useState(1);
  const [selectedTab, setSelectedTab] = useTabs([
    "template",
    "personal",
    "more",
    "milestone",
    "download",
  ]);
  const [initialMount, setInitialMount] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [showMobileSideBar, setShowMobileSideBar] = useState(false);

  const debounce = useDebounce(state, 2000);
  const debounceMilestone = useDebounce(milestones, 2000);

  const linkId = resumeLinkId();

  const [primaryColor, secondaryColor, tertiaryColor, quarternary] =
    useMemo(() => {
      if (!_.isEmpty(state.color)) {
        return state.color.value;
      }
      return ["", "", "", ""];
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.color]);

  /******** functions ********/
  const onChangeColor = useCallback((color) => {
    setState((prev) => ({
      ...prev,
      color,
    }));
    if (isEdit) {
      setInitialMount(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeTemplate = useCallback((name) => {
    setState((prev) => ({
      ...prev,
      templateName: name,
    }));

    setSelectedTab("personal");
    if (isEdit) {
      setInitialMount(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeToggleImage = useCallback((value) => {
    setState((prev) => ({
      ...prev,
      show_image: value,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeToggleImageMilestone = useCallback((value) => {
    setState((prev) => ({
      ...prev,
      show_milestone_image: value,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangePersonalInfo = useCallback(
    (personal) => {
      let personalData = {};

      personal.forEach((s) => {
        personalData[s.key] = s.value;
        if (s.key === "profile_image" && typeof s.value === "object") {
          setPreviewImage(URL.createObjectURL(s.value));
        } else if (s.key === "profile_image") {
          setPreviewImage(s.value);
        }
      });

      setState((prev) => ({
        ...prev,
        personal: personalData,
      }));
      setInitialMount(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onChangeSocialInfo = useCallback(
    (social) => {
      let socialData = {};

      social.forEach((s) => {
        socialData[s.key] = s.value;
      });

      setState((prev) => ({
        ...prev,
        social: socialData,
      }));

      setInitialMount(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onChangeMilestones = useCallback((key, value) => {
    setMilestones((prev) => ({
      ...prev,
      [key]: value,
    }));

    setInitialMount(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onNext = () => {
    if (selectedTab === "personal") {
      setPage(1);
      setSelectedTab("more");
    } else if (selectedTab === "more") {
      setPage(2);
      setSelectedTab("milestone");
    } else if (selectedTab === "milestone") {
      setPage(2);
      setSelectedTab("download");
    }
  };

  const onBack = () => {
    if (selectedTab === "personal") {
      setPage(1);
      setSelectedTab("template");
    } else if (selectedTab === "more") {
      setPage(1);
      setSelectedTab("personal");
    } else if (selectedTab === "milestone") {
      setPage(1);
      setSelectedTab("more");
    } else if (selectedTab === "download") {
      setPage(2);
      setSelectedTab("milestone");
    }
  };

  /** submit resume for creation */
  const onUpdateResume = async () => {
    setIsSubmit(true);
    let formData = new FormData();
    if (state.id) {
      formData.append("user_id", userData.user.id);
      formData.append("link_id", state.link_id);
    } else {
      formData.append("user_id", userData.user.id);
      formData.append("user_profile_id", userData.profile.id);
      formData.append("link_id", linkId);
    }
    console.log(state);
    console.log("============================================");
    // name
    if (state.templateName)
      formData.append("template_name", state.templateName);
    // color
    if (!_.isEmpty(state.color))
      formData.append("template_color", JSON.stringify(state.color));
    // personal
    if (!_.isEmpty(state.personal))
      formData.append("personal_info", JSON.stringify({...state.personal, profile_type_sub: profile?.profile_type?.type || 'personal'}));
    // more info
    if (!_.isEmpty(state.more))
      formData.append("more_info", JSON.stringify(state.more));
    // social
    if (!_.isEmpty(state.social))
      formData.append("social_media", JSON.stringify(state.social));
    // profile
    if (state.personal.profile_image)
      formData.append("profile_image", state.personal.profile_image);
    //show_milestone_image
    formData.append("show_milestone_image", state.show_milestone_image ? 1 : 0);

    let _featured = [];
    let _current = [];
    let _past = [];

    Object.keys(milestones).forEach((key) => {
      milestones[key]?.forEach?.((m) => {
        if (key === "featured") {
          _featured.push(m.id);
        } else if (key === "current") {
          _current.push(m.id);
        } else {
          _past.push(m.id);
        }
      });
    });

    formData.append("featured", JSON.stringify(_featured));
    formData.append("current", JSON.stringify(_current));
    formData.append("past", JSON.stringify(_past));

    if (state.id) {
      formData.append("_method", "PUT");
    }

    try {
      const url = state.id ? `public/resumes/${state.id}` : "public/resumes";

      const { data } = await http.post(url, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });

      if (state.id) {
        updateResume(data.data);
      } else {
        addResume(data.data);
        setState((prev) => ({
          ...prev,
          id: data.data.id,
          link_id: data.data.link_id,
        }));
      }
    } catch (err) {
      setIsSubmit(false);
    } finally {
      setIsSubmit(false);
    }
  };

  const onDone = () => {
    history.push(
      `/${userData.user.username}-${extension}/profile/${userData.profile.id}`
    );
  };

  const getSocialMedia = async () => {
    try {
      const { data } = await http.get("public/social-medias", {
        user_profile_id: userData?.profile?.id,
        user_id: userData?.user?.id,
      });
      setSocialMedia(data.social_media);
    } catch (err) {
      console.log(err);
    }
  };

  const getInfo = async (profile, user) => {
    try {
      const { data } = await http.get(`public/professional-informations`, {
        user_profile_id: profile.id,
      });
      let professional = {
        education: "",
        training: "",
        experience: "",
      };
      if (!_.isEmpty(data)) {
        //update state to false
        professional = {
          education: data.education ?? "",
          training: data.training ?? "",
          experience: data.experience ?? "",
        };
      }
      setState((prev) => ({
        ...prev,
        more: {
          username: user.username,
          bio: profile.bio,
          birthdate: new Date(profile.birthdate),
          birth_location: profile.birth_location,
          civil_status: profile.civil_status,
          children: profile.children,
          other_name: profile.other_name,
          hair_color: profile.hair_color,
          eye_color: profile.eye_color,
          height: profile.height,
          phone_number: profile.phone_number,
          email: user.email,
          occupations: profile?.occupations ?? [],
          ...professional,
        },
      }));
    } catch (error) {
      console.error("Error Fecthing Professional Information");
    }
  };

  const onChangeTabs = useCallback(
    (name) => {
      if (state.templateName) {
        if (name === "milestone") {
          setPage(2);
        } else {
          setPage(1);
        }
        setSelectedTab(name);
      } else {
        toast.error("Please Select Template");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  /******** useEffects ********/
  useEffect(() => {
    if (userData) {
      const { profile, user } = userData;
      getInfo(profile, user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    if (userData) {
      getSocialMedia();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    if (!initialMount) {
      onUpdateResume();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialMount, debounce, debounceMilestone]);

  return (
    <EmptyLayout
      title="Resume Builder"
      isOpen={showMobileSideBar}
      onClickMobileSideBar={() => setShowMobileSideBar((prev) => !prev)}>
      <div className="w-full h-full flex flex-row relative">
        <ResumeSideBarMobile
          isOpen={showMobileSideBar}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          onChangeTabs={(value) => onChangeTabs(value)}
        />
        {/* sidebar */}
        <div className="w-80.1 h-full py-6.5 pl-6.4 pr-5 border-r border-resumeBorder hidden 3md:block">
          <div className="w-full grid gap-y-1 ">
            {sideBarMenuList.map((obj, index) => (
              <SidebarMenu
                key={index}
                isActive={selectedTab === obj.value}
                colorType="blue"
                onClick={() => onChangeTabs(obj.value)}>
                <div className="w-full flex flex-row justify-start items-center space-x-3">
                  <div className="relative w-6 h-6 rounded-full border-2 resume-side-number">
                    {index + 1}
                  </div>
                  <span>{obj.label}</span>
                </div>
              </SidebarMenu>
            ))}
          </div>
        </div>
        {/* end: sidebar */}
        {/* begin tabs */}
        <div className="h-full overflow-y-auto overflow-x-hidden custom-scroll resume-builder-width-responsive">
          <TabPanel hidden={selectedTab !== "template"}>
            <TemplatePanel
              {...{
                onChangeColor,
                onChangeTemplate,
                color: state.color,
                // onChangeToggleImage,
                show_image: state.show_image,
                profileType: profile?.profile_type?.type,
              }}
            />
          </TabPanel>
          {selectedTab !== "template" && (
            <div className="w-full" style={{ height: "calc(100vh - 80px)" }}>
              <div className="w-full h-full grid grid-cols-1 3md:grid-cols-2">
                {/* left content */}
                <TabPanel hidden={selectedTab !== "personal"}>
                  <PersonalPanel
                    {...{
                      previewImage,
                      userData,
                      socialMedia,
                      onNext,
                      onBack,
                      onChangePersonalInfo,
                      onChangeSocialInfo,
                      profile,
                    }}
                    personalDetails={resumeDetails?.personal_info}
                    details={state.personal}
                    socials={state.social}
                    show_image={state.show_image}
                  />
                </TabPanel>
                <TabPanel hidden={selectedTab !== "more"}>
                  {
                    profile?.profile_type?.type === "group" || profile?.profile_type?.type === "team"
                      ? <MoreInfoPanelTG
                          {...{
                            userData,
                            onNext,
                            onBack,
                            setState,
                            setInitialMount,
                            profile
                          }}
                          details={state.more}
                          social={state.social}
                        />
                        : <MoreInfoPanel
                            {...{
                              userData,
                              onNext,
                              onBack,
                              setState,
                              setInitialMount,
                              profile
                            }}
                            details={state.more}
                            social={state.social}
                          />
                  }
                </TabPanel>
                <TabPanel hidden={selectedTab !== "milestone"}>
                  <MilestonePanel
                    {...{
                      userData,
                      onNext,
                      onBack,
                      isEdit,
                      onChangeMilestones,
                      onChangeToggleImage,
                      show_image: state.show_image,
                      onChangeToggleImageMilestone,
                      show_image_milestone: state.show_milestone_image,
                    }}
                    list={milestones}
                  />
                </TabPanel>
                <TabPanel hidden={selectedTab !== "download"}>
                  <ShareDownloadPanel
                    {...{
                      userData,
                      onBack,
                      isSubmit,
                      onDone,
                    }}
                    id={state.link_id || linkId}
                  />
                </TabPanel>
                {/* end left content */}
                {/* right content */}
                <div
                  className="w-full pt-8 pb-4 px-0 2sm:px-2 bg-modalGray overflow-auto custom-scroll"
                  style={{ height: "calc(100vh - 80px)" }}>
                  <ResumePreview
                    {...{
                      state,
                      previewImage,
                      milestones,
                      primaryColor,
                      secondaryColor,
                      tertiaryColor,
                      quarternary,
                      page,
                      setPage,
                      setSelectedTab,
                      profile,
                    }}
                  />
                </div>
                {/* end right contetn */}
              </div>
            </div>
          )}
        </div>
      </div>
    </EmptyLayout>
  );
}

const useQuery = () => {
  const { state } = useLocation();
  return React.useMemo(() => state, [state]);
};
