const PlayIconCircleSVG = ({ className = "" }) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="13.4346"
        cy="12.5449"
        r="11.75"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M17.4346 11.7977C18.1012 12.1826 18.1012 13.1448 17.4346 13.5297L12.4346 16.4165C11.7679 16.8014 10.9346 16.3203 10.9346 15.5505L10.9346 9.77697C10.9346 9.00717 11.7679 8.52605 12.4346 8.91095L17.4346 11.7977Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PlayIconCircleSVG;
