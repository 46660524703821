import React, { useMemo, useState } from "react";
import CustomCheckbox from "components/checkbox/custom-checkbox";
import Tag from "components/tag/tag";
import { BsExclamationDiamondFill } from "react-icons/bs";
import _ from "lodash";
import moment from "moment";

export default function MilestoneList({
  name = "",
  title = "",
  isEdit = false,
  content = [],
  milestones = [],
  onChange,
}) {
  const [selected, setSelected] = useState(content);

  const renderStartDate = (data) => {
    const { start_day, start_month, start_year } = data;
    if (start_day && start_month && start_year) {
      return (
        <span>
          {moment(`${start_year} ${start_month} ${start_day}`).format(
            "DD MMM YYYY"
          )}
        </span>
      );
    } else if (start_month && start_year) {
      return (
        <span>
          {moment(start_month).format("MMM")} {start_year}
        </span>
      );
    } else if (start_year) {
      return <span>{start_year}</span>;
    }

    return null;
  };

  const renderEndDate = (data) => {
    const { end_day, end_month, end_year } = data;
    if (end_day && end_month && end_year) {
      return (
        <>
          <span> - </span>
          <span>
            {moment(`${end_year} ${end_month} ${end_day}`).format(
              "DD MMM YYYY"
            )}
          </span>
        </>
      );
    } else if (end_month && end_year) {
      return (
        <>
          <span> - </span>
          <span>
            {moment(end_month).format("MMM")} {end_year}
          </span>
        </>
      );
    } else if (end_year) {
      return (
        <>
          <span> - </span>
          <span>{end_year}</span>
        </>
      );
    }

    return null;
  };

  const onToggleCheckAll = (value) => {
    if (value) {
      if (isEdit) {
        setSelected(milestones);
        onChange(name, milestones);
      } else {
        setSelected(content);
        onChange(name, content);
      }
    } else {
      setSelected([]);
      onChange(name, []);
    }
  };

  const onCherryPick = (value, record) => {
    let payload = _.clone(selected);
    let sorted = payload;
    if (value) {
      payload.push(record);
      sorted = _.sortBy(payload, ["id"]);
      setSelected(sorted);
      onChange(name, sorted);
    } else {
      _.remove(payload, function (n) {
        return n.id === record.id;
      });
      sorted = _.sortBy(payload, ["id"]);
      setSelected(sorted);
      onChange(name, sorted);
    }
  };

  const global = useMemo(() => {
    let checked = false;
    let size = 0;

    if (isEdit) {
      if (!_.isEmpty(selected)) {
        const filterNonSelected = _.filter(milestones, (e) => {
          let findObject = _.find(selected, ["id", e.id]);
          return _.isEmpty(findObject);
        });

        checked = _.isEmpty(filterNonSelected);
        size = _.size(filterNonSelected);
      }
    } else {
      const filterNonSelected = _.filter(milestones, (e) => {
        if (!_.isEmpty(selected)) {
          let findObject = _.find(selected, ["id", e.id]);
          return _.isEmpty(findObject);
        } else {
          return true;
        }
      });

      checked = _.isEmpty(filterNonSelected);
      size = _.size(filterNonSelected);
    }

    return {
      checked,
      size,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, content]);

  const showMinus = useMemo(() => {
    let size = _.size(content);
    return !global.checked && global.size > 0 && global.size !== size;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [global, content]);

  return (
    <section className="w-full">
      {/* header */}
      <div className="w-full flex-group-checkbox py-6 border-y border-resumeBorder">
        <CustomCheckbox
          name={name}
          checked={global.checked}
          showMinus={showMinus}
          onChange={(e) => {
            let value = e.target.checked;
            onToggleCheckAll(value);
          }}
        />
        <h3 className="text-lightBlack text-xl font-semibold">{title}</h3>
      </div>
      {/* start: list */}
      <ul className="w-full pl-0 2sm:pl-8">
        {(milestones || []).map((obj, index) => {
          let findOject = _.find(selected, { id: obj.id });
          let checked = !_.isEmpty(findOject);

          return (
            <li className="w-full flex-group-checkbox py-4" key={index}>
              <CustomCheckbox
                name="all-featured"
                checked={checked}
                onChange={(e) => {
                  let value = e.target.checked;
                  onCherryPick(value, obj);
                }}
              />
              <div className="w-full flex flex-col 2sm:flex-row space-x-3">
                <div className="w-15 h-15 bg-tagBorder rounded-sm relative overflow-hidden">
                  {obj?.isAdultContent && (
                    <div className="absolute matured-content-blured-small">
                      <button className="w-full flex justify-center">
                        <BsExclamationDiamondFill className="text-1xl cursor-pointer" />
                      </button>
                    </div>
                  )}
                  {obj.thumbnail && (
                    <img
                      alt="milestones"
                      src={obj.thumbnail.url}
                      className="w-full h-full object-contain object-center"
                    />
                  )}
                </div>
                <div style={{ width: "calc(100% - 72px)" }}>
                  <div className="w-full flex flex-row space-x-3 items-center">
                    <p className="text-lightBlack text-sm 2sm:text-xl font-medium">
                      {obj.title}
                    </p>
                    <Tag>{obj.category.name}</Tag>
                  </div>
                  <div className="w-full mt-2.4">
                    <p className="text-darkerGray text-sm font-medium leading-6">
                      {renderStartDate(obj)}
                      {renderEndDate(obj)}
                    </p>
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
      {/* end list */}
    </section>
  );
}
