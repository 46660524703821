import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Layout from "layouts/layout";
import { useLocation, useHistory } from "react-router-dom";
import Input from "components/input/input";
import Button from "components/button/button";
import { FaSearch, FaPlus, FaTimes } from "react-icons/fa";
import SearchItem from "components/search/search-item";
import AdvanceSearch from "components/search/advance-search";
import LoadMore from "components/pagination/load-more";
import { urlSerializer } from "utils/utils";
import { ProfileDefaultPlaceholder } from "constants/images";
import {
  REFRESH_SEARCH_PROFILE,
  GET_SEARCH_PROFILE,
  SET_SEARCH_PROFILE,
} from "redux/modules/search-profile/types";
import { gsap } from "gsap";
import PopInfo from "components/info-popup/pop-info";

export default function Search() {
  const { http } = global.services;
  const history = useHistory();
  const contentRef = useRef();
  const query = useQuery();
  const searchValue = query.get("keyword");
  const [search, setSearch] = useState(searchValue);
  const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
  const [state, setState] = useState({
    name: "",
    job_role: "",
    city: "",
    state: "",
    country: "",
    gender: "",
  });
  const {
    page,
    refresh,
    loadMore,
    initialize,
    isRequesting,
    results = [],
  } = useSelector((state) => state.searchProfile);
  // const {
  //   userData
  // } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const userData = JSON.parse(localStorage.getItem('user_data'));

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearch(value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUpdateCheckbox = (e, value) => {
    const isChecked = e.target.checked;
    setState({ ...state, gender: isChecked ? value : "" });
  };

  /**
   * This method will search filestone
   */
  const onSearch = (e) => {
    e.preventDefault();
    dispatch({ type: REFRESH_SEARCH_PROFILE, payload: [] });
  };

  /**
   * This method will search milestone
   */
  const getResults = async () => {
    if (isRequesting || !loadMore) return;

    dispatch({
      type: GET_SEARCH_PROFILE,
      payload: [],
    });

    // let formData = new FormData;

    // formData.append("page", page);
    // formData.append("keyword", JSON.stringify(search))

    // Object.keys(state).map(k => {
    //   formData.append(k, state[k]);
    // })

    let params = {
      page: page,
      keyword: JSON.stringify(search),
      show_adult: userData?.profile?.show_adult || 0,
      ...state,
    };

    try {
      const url = await urlSerializer("public/profiles/search", params);
      const response = await http.get(url);
      const { data } = response.data;
      const _page = data.length > 0 ? parseInt(page) + 1 : page;
      const _loadMore = data.length > 0 ? true : false;
      dispatch({
        type: SET_SEARCH_PROFILE,
        payload: { data: data, page: _page, loadMore: _loadMore },
      });
    } catch (err) {
      console.log("error@getResults", err);
      throw err;
    }
  };

  /**
   * This method handle on scroll event
   */
  const handleScroll = () => {
    if (contentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
      if (scrollTop + clientHeight >= scrollHeight) {
        getResults();
      }
    }
  };

  useEffect(() => {
    setSearch(searchValue);

    if (searchValue) {
      dispatch({
        type: REFRESH_SEARCH_PROFILE,
        payload: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  /**
   * This effect will run on initialization of component
   * run again refresh value is change
   */
  useEffect(() => {
    if (initialize) {
      getResults();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, initialize]);

  useLayoutEffect(() => {
    gsap.fromTo(
      ".searchbar",
      {
        x: -20,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
      }
    );
    gsap.fromTo(
      ".searchbar-buttons",
      {
        x: 20,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
      }
    );
    // gsap.fromTo(
    //   ".search-title",
    //   {
    //     y: -20,
    //     opacity: 0,
    //   },
    //   {
    //     y: 0,
    //     opacity: 1,
    //   }
    // );
    gsap.fromTo(
      ".search-item",
      {
        x: 50,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
      }
    );
  }, []);

  return (
    <Layout
      title="Search"
      contentRef={contentRef}
      handleScroll={handleScroll}
      containerStyle="lg:static lg:overflow-y-auto lg:overflow-x-hidden">
      <div className="auth-page-wrapper pt-15">
        <div className="w-full container-max-width">
          <form onSubmit={onSearch}>
            <div className="w-full flex space-x-7.5">
              <div className="searchbar w-full 2md:w-11/20 lg:w-12/20">
                <Input
                  inputContainerClass="flex-1"
                  value={search}
                  name="search"
                  placeholder="Search"
                  noLabelPaddingY="1rem"
                  type="text"
                  onChange={handleSearchChange}
                />
              </div>
              <div className="w-9/20 3sm:w-6/20 4sm:w-4/20 1md:w-3/20 2md:w-9/20 lg:w-8/20">
                <div className="searchbar-buttons w-full flex space-x-3.5">
                  <Button
                    type="submit"
                    buttonName={
                      <span className="hidden 2md:block">Search</span>
                    }
                    buttonTextClass="text-sm text-white font-semibold flex items-center justify-center"
                    buttonRadius="6px"
                    iconPosition="left"
                    icon={<FaSearch className="2md:mr-3" />}
                    buttonClass="relative py-5.5 px-5.5 2md:py-4.5 2md:w-full bg-primary hover:bg-darkPrimary"
                  />
                  <Button
                    type="button"
                    buttonName={
                      <span className="hidden 2md:block">{`${
                        showAdvanceSearch ? "Close" : ""
                      } Advanced Search`}</span>
                    }
                    buttonTextClass="text-sm text-white font-semibold flex items-center justify-center"
                    buttonRadius="6px"
                    iconPosition="left"
                    icon={
                      showAdvanceSearch ? (
                        <FaTimes className="2md:mr-3" />
                      ) : (
                        <FaPlus className="2md:mr-3" />
                      )
                    }
                    buttonClass="relative py-5.5 px-5.5 2md:py-4.5 2md:w-full bg-lightBlack hover:bg-lightBlack/95"
                    onClick={() => setShowAdvanceSearch((prev) => !prev)}
                  />
                </div>
              </div>
            </div>
          </form>
          {/* advance search forms */}
          <AdvanceSearch
            open={showAdvanceSearch}
            state={state}
            handleInputChange={handleInputChange}
            handleUpdateGender={handleUpdateCheckbox}
          />
          {/* end: advance search forms */}
        </div>
        {/* search result */}
        <div className="w-full container-max-width mt-15">
          <div className="w-full flex flex-row gap-x-2 items-center">
            <h1 className="text-3xl font-bold tracking-tighter leading-9 text-lightBlack">
              See All Results
            </h1>
            <PopInfo type="search_all_result"  
             direction="left"
            //  onClickCallBack={() => setOverflow("overflow-visible")}
             />
          </div>

          <div className="search-item w-full mt-12.5">
            {results.map((obj, index) => {
              // const fullName = obj.user
              //   ? `${obj.user.first_name} ${obj.user.last_name}`
              //   : null;
              // let occupations = !_.isEmpty(obj.occupations)
              //   ? listFormat(obj.occupations)
              //   : null;
              // let occupations = obj.occupations;
              // if (typeof obj.occupations === "string") {
              //   occupations = !_.isEmpty(obj.occupations)
              //     ? listFormat(obj.occupations)
              //     : null;
              // }

              return (
                <SearchItem
                  key={index}
                  data={obj}
                  image={obj.profile_image_webp_url || obj.profile_image_url || ProfileDefaultPlaceholder}
                  onProfileClick={(url) => history.push(url)}
                  // name={fullName}
                  // address={obj.address}
                  // work={occupations}
                  // showAdult={obj.show_adult ? true : false}
                />
              );
            })}
            <LoadMore
              result={results.length > 0 ? true : false}
              requesting={isRequesting}
              {...{
                initialize,
                loadMore,
              }}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};
