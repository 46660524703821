import React, { useLayoutEffect } from "react";
import { getDays } from "utils/constant";
import { gsap } from "gsap";

import SubscriptionGraph from "./subscription-graph";
import UpgradePremium from "components/subscription/upgrade-premium";
import LockOutlineIcon from "assets/svg/upgrade/lock-outline-icon";

export default function SubscriptionViews({ year, month, subscription = {} }) {
  useLayoutEffect(() => {
    gsap.fromTo(
      ".page-view-graph",
      { scale: 0, opacity: 0 },
      { scale: 1, opacity: 1 }
    );
    gsap.fromTo(".page-view-item", { x: 50, opacity: 0 }, { x: 0, opacity: 1 });
  }, []);

  return (
    <div className="mt-6 w-full bg-white rounded-md">
      <div className="pt-12.5 pb-10 px-7.5 1md:px-24.5">
        <div className="flex flex-row items-center space-x-3 mb-8">
          {subscription.name !== "Premium Account" && <LockOutlineIcon />}
          <h1 className="text-lg leading-5.5 text-lightBlack font-semibold">
            Page Views/Visitors
          </h1>
        </div>

        <div className="relative w-full">
          <div className="w-full h-full absolute z-40 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <UpgradePremium />
          </div>

          <div className="w-full space-y-12.5 3md:flex 3md:items-center 3md:space-x-12.5 3md:space-y-0 opacity-25">
            <div className="w-full 3md:w-15/20">
              <div className="page-view-graph mt-7.5 w-full">
                <SubscriptionGraph labels={getDays(year, month)} />
              </div>
            </div>

            <div className="w-full 3md:w-5/20">
              <div className="w-full gap-y-14 grid 1sm:grid-cols-3 1sm:gap-x-14 1sm:gap-y-0 3md:grid-cols-1 3md:gap-y-14 3md:gap-x-0">
                <div className="page-view-item w-full text-center 3md:text-left">
                  <p className="text-2xl leading-7.4 font-bold tracking-tighter text-lightBlack">
                    -
                  </p>
                  <p className="text-base leading-6 text-darkerGray">
                    Page Views Today
                  </p>
                </div>

                <div className="page-view-item w-full text-center 3md:text-left">
                  <p className="text-2xl leading-7.4 font-bold tracking-tighter text-lightBlack">
                    -
                  </p>
                  <p className="text-base leading-6 text-darkerGray">
                    Page Views last 30 days
                  </p>
                </div>

                <div className="page-view-item w-full text-center 3md:text-left">
                  <p className="text-2xl leading-7.4 font-bold tracking-tighter text-lightBlack">
                    -
                  </p>
                  <p className="text-base leading-6 text-darkerGray">
                    Total Page Views
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
