import {
	SET_PLANS,
} from './types'

const initialState = {
	plans: [],
	refresh: false,
	initialize: true
};

const reducer = (state = initialState, {type, payload}) => {

	switch (type) {
		
		case SET_PLANS:
			return {
				...state,
				refresh: false,
				initialize: false,
				plans: [...state.plans, ...payload]
			}

		default:
			return state;

	}

}

export default reducer