import React from "react";
import Modal from "components/global/modal";
import Button from "components/button/button";
import { FaTimes } from "react-icons/fa";
import SwitchProfileSVG from "assets/svg/switch-profile-svg"

export default function SwitchProfile({ hide, userData, ...props }) {
  return (
    <Modal
      width="7sm:w-96"
      contentClassName="rounded-md overflow-auto custom-scroll"
      wrapperClassName="overflow-visible shadow-none"
    >
      <div className="w-full">
        <div className="flex flex-row justify-end items-baseline mt-4">
          {/* close */}
          <button onClick={() => hide()} className="mr-4">
            <FaTimes className="text-xl" />
          </button>
        </div>
      </div>

      <div className="w-full text-center">
        <div className="flex justify-center mb-4">
          <SwitchProfileSVG />
        </div>
        <h1 className="text-lg font-semibold">Welcome to your new Profile!</h1>
        <p className="leading-6 px-4 py-2">
          You're now switched out of your account and into your profile 
          <span className="ml-1 font-semibold">{userData.profile.other_name}</span>.
        </p>
        <Button
          buttonName="Done"
          buttonClass="bg-thBg py-4 px-14 my-6"
          buttonTextClass="text-black text-sm font-semibold"
          onClick={() => hide()}
        />
      </div>
    </Modal>
  );
}
