import React,{useContext} from "react";
import Header from "components/global/header";
import Footer from "components/global/footer";
import { connect } from "react-redux";
import userActions from "redux/modules/user/actions";
import BreadCrumbsComponent from "components/breadcrumbs/breadcrumbs";
import _ from "lodash";
import Alerts from "components/global/alerts";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Context } from "context/context";
// import { useMilestones } from "redux/modules/milestone/hooks";

const Layout = (
  {
    children,
    title = null,
    userData,
    setUserData,
    breadscrumbs = {},
    handleScroll = () => {},
    contentRef = null,
    containerStyle = "",
    isYourProfile = false,
  },
  ref
) => {
  // const { total: totalMilestones } = useMilestones();
  const { metaData } = useContext(Context);
  return (
    <HelmetProvider>
      <Helmet>
          {/* <title>{title} - Milestones Page</title>  */}
          <title>{metaData.title || 'Milestones Page'}</title>
          <meta name="description" content={metaData.description || 'Milestones Page - Your creative history in one place'}/>
          <meta property="og:url" content={metaData.url || 'https://milestones.demosite.ninja/'}/>
          <meta property="og:type" content="website"/> 
          <meta property="og:title" content={metaData.title || 'Milestones Page'}/> 
          <meta property="og:description" content={metaData.description || 'Milestones Page - Your creative history in one place'}/> 
          <meta property="og:image" content={metaData.imageUrl || 'https://www.creativefabrica.com/wp-content/uploads/2021/04/05/Photo-Image-Icon-Graphics-10388619-1-580x386.jpg'}/>
          <meta name="twitter:card" content="summary_large_image"/> 
          <meta property="twitter:domain" content={metaData.url || 'https://milestones.demosite.ninja/'}/> 
          <meta property="twitter:url" content="https://milestones.demosite.ninja/customer-OnnB/profile/3"/> 
          <meta name="twitter:title" content={metaData.title || 'Milestones Page'}/> 
          <meta name="twitter:description" content={metaData.description || 'Milestones Page - Your creative history in one place'}/> 
          <meta name="twitter:image" content={metaData.imageUrl || 'https://www.creativefabrica.com/wp-content/uploads/2021/04/05/Photo-Image-Icon-Graphics-10388619-1-580x386.jpg'}>
          </meta>
      </Helmet>
      <div className="h-screen lg:flex w-full relative">
        <div
          ref={contentRef}
          // className="h-screen lg:static lg:overflow-y-auto lg:overflow-x-hidden lg:flex-grow bg-siteBg scroll-smooth custom-scroll"
          className={`h-screen lg:flex-grow bg-siteBg scroll-smooth custom-scroll ${containerStyle}`}
          onScroll={handleScroll}>
          <Header title={title} userData={userData} setUserData={setUserData} />
          {!_.isEmpty(breadscrumbs) && (
            <BreadCrumbsComponent
              title={breadscrumbs.title}
              subtitle={breadscrumbs.subtitle}
              showBanner={breadscrumbs.showBanner}
              pageName={title}
              parent={breadscrumbs.parent}
              parentUrl={breadscrumbs.parentUrl}
              currentUrl={breadscrumbs.currentUrl}
            />
          )}
          {title !== "Verify Account" &&
            !userData?.profile?.id3_verified &&
            userData?.profile?.total_milestones >= 10 &&
            isYourProfile && <Alerts userData={userData}/>}
          <main className="main-wrapper mx-auto flex flex-1 bg-siteBg">
            {children}
          </main>
          <Footer />
        </div>
      </div>
    </HelmetProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (params) => {
      dispatch(userActions.setUserData(params));
    },
  };
};

const LayoutComponent = React.forwardRef(Layout);
export default connect(mapStateToProps, mapDispatchToProps)(LayoutComponent);
