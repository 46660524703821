import React, { useMemo } from "react";
import { HiOutlineCalendarDays } from "react-icons/hi2";
import { renderStartDate, renderEndDate } from "../../resume";
import { BsExclamationDiamondFill } from "react-icons/bs";

export default function TemplateThree({
  color = {},
  personal_info = {},
  more_info = {},
  social_media = {},
  milestones = {},
  primaryColor,
  secondaryColor,
  tertiaryColor,
  quarternary,
  profile_image_withURL,
}) {
  const activeColor = useMemo(() => {
    if (color.label === "Voilet + Pink") {
      return tertiaryColor;
    }
    return primaryColor;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  return (
    <div className="w-full bg-black py-2 px-3" style={{ height: "400px" }}>
      {/* heading */}
      <div className="relative mb-1">
        <div className="flex flex-row gap-2">
          {personal_info?.profile_image && (
            <>
              <div className="shrink-0 py-2">
                {personal_info &&
                personal_info.hasOwnProperty("profile_image") &&
                personal_info.profile_image ? (
                  <img
                    src={profile_image_withURL || personal_info.profile_image}
                    className="w-18 object-cover rounded-full"
                    alt="resume-profile"
                  />
                ) : (
                  <div></div>
                )}
              </div>
            </>
          )}
          <div className="flex-1 pt-4 pb-2">
            {more_info && more_info.hasOwnProperty("username") && (
              <p className="font-template-three text-white font-bold tracking-wide leading-3.5">
                I’m <br />
                {more_info.username}
              </p>
            )}
            <div className="flex flex-wrap flex-row items-center text-gray-300 leading gap-x-1 mt-1 mb-1">
              {/* name */}
              {personal_info && personal_info.hasOwnProperty("name") && (
                <p className="text-xxs">{personal_info.name}</p>
              )}
              {personal_info &&
                personal_info.hasOwnProperty("name") &&
                personal_info.hasOwnProperty("gender") && (
                  <div className="w-0.5 h-0.5 bg-gray-400 rounded-full"></div>
                )}
              {/* gender */}
              {personal_info && personal_info.hasOwnProperty("gender") && (
                <p className="text-xxs">{personal_info.gender}</p>
              )}
              {personal_info &&
                personal_info.hasOwnProperty("gender") &&
                personal_info.hasOwnProperty("pronoun") && (
                  <div className="w-0.5 h-0.5 bg-gray-400 rounded-full"></div>
                )}
              {/* pronoun */}
              {personal_info && personal_info.hasOwnProperty("pronoun") && (
                <p className="text-xxs">{personal_info.pronoun}</p>
              )}
              {personal_info &&
                personal_info.hasOwnProperty("pronoun") &&
                personal_info.hasOwnProperty("address") && (
                  <div className="w-0.5 h-0.5 bg-gray-400 rounded-full"></div>
                )}
              {/* address */}
              {personal_info && personal_info.hasOwnProperty("address") && (
                <p className="text-xxs">{personal_info.address}</p>
              )}
            </div>
            <div className="flex flex-wrap flex-row items-center gap-x-0.5">
              {/* industry */}
              {more_info && more_info.hasOwnProperty("industry") && (
                <span className="text-white text-xxs bg-resumeTempThreeSecondary py-0.5 px-1 rounded-sm">
                  {more_info.industry}
                </span>
              )}
              {/* character */}
              {more_info && more_info.hasOwnProperty("character") && (
                <span className="text-white text-xxs bg-resumeTempThreeSecondary py-0.5 px-1 rounded-sm">
                  {more_info.character}
                </span>
              )}
              {/* style */}
              {more_info && more_info.hasOwnProperty("style") && (
                <span className="text-white text-xxs bg-resumeTempThreeSecondary py-0.5 px-1 rounded-sm">
                  {more_info.style}
                </span>
              )}
            </div>
          </div>
          {/* occupation */}
          {personal_info && (
            <div className="absolute right-1 top-0">
              <p
                className="text-5xs font-template-three"
                style={{ color: activeColor }}>
                {(personal_info.occupations || []).map((val, index) => {
                  const count = personal_info.occupations.length;
                  if (index >= count - 1 && index > 0) {
                    return " and " + val;
                  } else {
                    if (index >= count - 2) {
                      return val;
                    } else {
                      return val + ", ";
                    }
                  }
                })}
              </p>
            </div>
          )}
        </div>
      </div>
      {/* body */}
      <div className="w-full flex flex-col gap-2">
        {milestones.hasOwnProperty("featured") && (
          <div className="flex flex-row gap-x-3">
            <div className="shrink-0 text-resumeTempThreeTextPrimary uppercase font-bold tracking-widest text-xxs">
              <p className="w-10">featured milestones</p>
            </div>
            <div className="flex-1">
              {milestones.featured.map((mf, i) => (
                <div key={`t3-featured-${i}`} className="w-full mb-2">
                  <div className="flex flex-row items-center mb-0.5">
                    <div className="flex flex-1 flex-row items-center gap-x-0.5">
                      {mf.thumbnail && (
                        <div className="shrink-0">
                          <div className="relative w-3 h-3 bg-white rounded-full overflow-hidden">
                            {Boolean(mf.is_adult_content) && (
                              <div className="absolute matured-content-blured-small">
                                <BsExclamationDiamondFill className="text-5xs text-white" />
                              </div>
                            )}
                            <img
                              src={mf.thumbnail.url}
                              className="w-full h-full object-contain object-center"
                              alt="milestone-featured-thumbnail"
                            />
                          </div>
                        </div>
                      )}
                      <h3 className="font-template-three text-white text-5xs font-bold">
                        My Milestone Title
                      </h3>
                    </div>
                    <span className="text-white text-xxs bg-resumeTempThreeSecondary py-0.5 px-1 rounded-sm">
                      ACTING
                    </span>
                  </div>
                  <div className="flex items-center text-xxs text-darkGray gap-1 mb-1">
                    <HiOutlineCalendarDays className="w-1.5 h-1.5 text-darkerGray" />
                    {renderStartDate(mf)}
                    {renderEndDate(mf)}
                  </div>
                  <div className="w-full mb-1">
                    {mf.positions.map((p, i) => (
                      <div
                        key={`t3-featured-position-${i}`}
                        className="w-full leading-1 flex flex-row gap-x-1"
                        style={{ color: activeColor }}>
                        <>
                          <p className="text-xxs leading-none font-semibold">
                            <span>{p.position.name}</span>
                            {p.position.description && (
                              <>
                                <span> - </span>
                                <span>{p.position.description}</span>
                              </>
                            )}
                            {p.platform && <span>{` (${p.platform})`}</span>}
                          </p>
                          {p.date_note && (
                            <>
                              <span
                                className="text-xxs leading-none opacity-30"
                                style={`color: ${activeColor} `}>
                                &#8226;
                              </span>
                              <p className="text-xxs leading-none font-semibold">
                                {p.date_note}
                              </p>
                            </>
                          )}
                        </>
                      </div>
                    ))}
                  </div>
                  <div className="w-full text-white text-opacity-60">
                    <p
                      className="text-xxs font-light self-stretch no-underline"
                      dangerouslySetInnerHTML={{
                        __html: mf.description,
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {milestones.hasOwnProperty("current") && (
          <div className="flex flex-row gap-x-3">
            <div className="shrink-0 text-resumeTempThreeTextPrimary uppercase font-bold tracking-widest text-xxs">
              <p className="w-10">current milestones</p>
            </div>
            <div className="flex-1">
              {milestones.current.map((mc, i) => (
                <div key={`t3-current-${i}`} className="w-full mb-2">
                  <div className="flex flex-row items-center mb-0.5">
                    <div className="flex flex-1 flex-row items-center gap-x-0.5">
                      {mc.thumbnail && (
                        <div className="shrink-0">
                          <div className="relative w-3 h-3 bg-white rounded-full overflow-hidden">
                            {Boolean(mc.is_adult_content) && (
                              <div className="absolute matured-content-blured-small">
                                <BsExclamationDiamondFill className="text-5xs text-white" />
                              </div>
                            )}
                            <img
                              src={mc.thumbnail.url}
                              className="w-full h-full object-contain object-center"
                              alt="milestone-current-thumbnail"
                            />
                          </div>
                        </div>
                      )}
                      <h3 className="font-template-three text-white text-5xs font-bold">
                        My Milestone Title
                      </h3>
                    </div>
                    <span className="text-white text-xxs bg-resumeTempThreeSecondary py-0.5 px-1 rounded-sm">
                      ACTING
                    </span>
                  </div>
                  <div className="flex items-center text-xxs text-darkGray gap-1 mb-1">
                    <HiOutlineCalendarDays className="w-1.5 h-1.5 text-darkerGray" />
                    {renderStartDate(mc)}
                    {renderEndDate(mc)}
                  </div>
                  <div className="w-full mb-1">
                    {mc.positions.map((p, i) => (
                      <div
                        key={`t3-current-position-${i}`}
                        className="w-full leading-1 flex flex-row gap-x-1"
                        style={{ color: activeColor }}>
                        <>
                          <p className="text-xxs leading-none font-semibold">
                            <span>{p.position.name}</span>
                            {p.position.description && (
                              <>
                                <span> - </span>
                                <span>{p.position.description}</span>
                              </>
                            )}
                            {p.platform && <span>{` (${p.platform})`}</span>}
                          </p>
                          {p.date_note && (
                            <>
                              <span
                                className="text-xxs leading-none opacity-30"
                                style={`color: ${activeColor} `}>
                                &#8226;
                              </span>
                              <p className="text-xxs leading-none font-semibold">
                                {p.date_note}
                              </p>
                            </>
                          )}
                        </>
                      </div>
                    ))}
                  </div>
                  <div className="w-full text-white text-opacity-60">
                    <p
                      className="text-xxs font-light self-stretch no-underline"
                      dangerouslySetInnerHTML={{
                        __html: mc.description,
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {milestones.hasOwnProperty("past") && (
          <div className="flex flex-row gap-x-3">
            <div className="shrink-0 text-resumeTempThreeTextPrimary uppercase font-bold tracking-widest text-xxs">
              <p className="w-10">past milestones</p>
            </div>
            <div className="flex-1">
              {milestones.past.map((mp, i) => (
                <div key={`t3-past-${i}`} className="w-full mb-2">
                  <div className="flex flex-row items-center mb-0.5">
                    <div className="flex flex-1 flex-row items-center gap-x-0.5">
                      {mp.thumbnail && (
                        <div className="shrink-0">
                          <div className="relative w-3 h-3 bg-white rounded-full overflow-hidden">
                            {Boolean(mp.is_adult_content) && (
                              <div className="absolute matured-content-blured-small">
                                <BsExclamationDiamondFill className="text-5xs text-white" />
                              </div>
                            )}
                            <img
                              src={mp.thumbnail.url}
                              className="w-full h-full object-contain object-center"
                              alt="milestone-past-thumbnail"
                            />
                          </div>
                        </div>
                      )}
                      <h3 className="font-template-three text-white text-5xs font-bold">
                        My Milestone Title
                      </h3>
                    </div>
                    <span className="text-white text-xxs bg-resumeTempThreeSecondary py-0.5 px-1 rounded-sm">
                      ACTING
                    </span>
                  </div>
                  <div className="flex items-center text-xxs text-darkGray gap-1 mb-1">
                    <HiOutlineCalendarDays className="w-1.5 h-1.5 text-darkerGray" />
                    {renderStartDate(mp)}
                    {renderEndDate(mp)}
                  </div>
                  <div className="w-full mb-1">
                    {mp.positions.map((p, i) => (
                      <div
                        key={`t3-past-position-${i}`}
                        className="w-full leading-1 flex flex-row gap-x-1"
                        style={{ color: activeColor }}>
                        <>
                          <p className="text-xxs leading-none font-semibold">
                            <span>{p.position.name}</span>
                            {p.position.description && (
                              <>
                                <span> - </span>
                                <span>{p.position.description}</span>
                              </>
                            )}
                            {p.platform && <span>{` (${p.platform})`}</span>}
                          </p>
                          {p.date_note && (
                            <>
                              <span
                                className="text-xxs leading-none opacity-30"
                                style={`color: ${activeColor} `}>
                                &#8226;
                              </span>
                              <p className="text-xxs leading-none font-semibold">
                                {p.date_note}
                              </p>
                            </>
                          )}
                        </>
                      </div>
                    ))}
                  </div>
                  <div className="w-full text-white text-opacity-60">
                    <p
                      className="text-xxs font-light self-stretch no-underline"
                      dangerouslySetInnerHTML={{
                        __html: mp.description,
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
