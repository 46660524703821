import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { lineGraphMockData } from "constants/mock/graph";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function SubscriptionGraph({ labels }) {
  const data = {
    labels,
    datasets: [
      {
        label: "Views",
        data: labels.map((label) => lineGraphMockData[label] ?? 0),
        borderColor: "#B4B4B4",
        backgroundColor: "#B4B4B4",
        pointBorderWidth: 0,
        pointHoverBackgroundColor: "#FFFFFF",
        pointHoverBorderWidth: 10,
        hoverBorderWidth: 10,
        fill: true,
        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        // position: 'nearest',
        // external: customTooltip,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          zeroLineColor: "transparent",
          borderWidth: 0,
        },
      },
      y: {
        min: 0,
        max: 5000,
        grid: {
          borderDash: [8, 4],
          color: "#EDEFF5",
          borderWidth: 0,
        },
        beginAtZero: true,
        ticks: {
          stepSize: 1000,
          precision: 0,
        },
      },
    },
  };

  return <Line options={options} data={data} />;
}
