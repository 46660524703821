import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from './actions'

export const useMediaTypes = () => {

    const {
        refresh,
        initialize,
        types = [],
      } = useSelector((state) => state.mediaType);

    const dispatch = useDispatch();

    const setRefreshMediaTypes = useCallback(
        (payload) => dispatch(actions.setRefreshMediaTypes(payload)),
        [dispatch]
    );

    const setMediaTypes = useCallback(
        (payload) => dispatch(actions.setMediaTypes(payload)),
        [dispatch]
    );

    return {
        refresh,
        initialize,
        types,
        setRefreshMediaTypes,
        setMediaTypes
    };
} 