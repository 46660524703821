import ResumeLineDividerIconSVG from "assets/svg/resume-line-divider-icon";
import _ from "lodash";
import React from "react";
import { HiOutlineCalendarDays } from "react-icons/hi2";
import { renderEndDate, renderStartDate } from "../../resume";

export default function TemplateFourFullPageTwo(props) {
  const {
    milestones = {},
    primaryColor,
    tertiaryColor,
    show_image_milestone = true,
  } = props;

  return (
    <div className="w-full">
      <div className="w-full bg-white h-full">
        {/* ouside padding */}
        <div className="w-full h-full template-four-padding-full">
          {/* inner padding */}
          <div className="w-full h-full grid p-3.5 relative grid-row-auto">
            {/* body start */}
            <div
              className="w-full flex flex-col gap-y-7"
              style={{ minHeight: "1092px" }}>
              {/* Featured Milestones */}
              {milestones.hasOwnProperty("featured") &&
                !_.isEmpty(milestones.featured) && (
                  <>
                    <div className="w-full flex flex-row space-x-8.6">
                      <div className="w-18.6">
                        <p className="text-md font-medium text-resumeTempFourTextPrimary tracking-resumeFour">
                          Featured Milestones
                        </p>
                      </div>
                      <div style={{ width: "calc(100% - 112px)" }}>
                        <div className="w-full grid gap-y-3">
                          {/* content */}
                          {milestones.featured.map((mf, i) => (
                            <div
                              className="w-full flex flex-row items-center space-x-2.6"
                              key={`featured-${i}`}>
                              {/* style={{ width: "calc(100% - 111px)" }} */}
                              <div className="w-full">
                                {/* title and date */}
                                <div className="w-full">
                                  <div className="w-full flex flex-row items-center justify-between">
                                    {/* title */}
                                    <div className="flex flex-row items-center space-x-1">
                                      <p className="text-base text-resumeTempFourTextPrimary font-medium tracking-tight">
                                        {mf.title}
                                      </p>
                                      <div
                                        className="text-6xs font-bold "
                                        style={{ color: tertiaryColor + "E6" }}>
                                        <span
                                          className="template-four-padding-skills rounded text-opacity-90"
                                          style={{
                                            backgroundColor:
                                              tertiaryColor + "1F", // opcity 12%
                                          }}>
                                          {mf.category.name}
                                        </span>
                                      </div>
                                    </div>
                                    {/* date */}
                                    <div>
                                      <p className="text-6xs text-resumeTempFourTextPrimary leading-4 flex flex-row items-center space-x-1">
                                        <HiOutlineCalendarDays className="w-4 h-4 text-resumeTempFourIconColor" />
                                        <span className="mt-px">
                                          {renderStartDate(mf)}
                                          {renderEndDate(mf)}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                {/* sub title and episodes */}
                                <div className="w-full mt-0.5">
                                  {mf.positions.map((p, i) => (
                                    <div
                                      key={`featured-position-${i}`}
                                      className="w-full  flex flex-row space-x-1"
                                      style={{ color: primaryColor }}>
                                      <>
                                        <p className="text-xs leading-4 font-medium">
                                          <span>{p.position.name}</span>
                                          {p.position.description && (
                                            <>
                                              <span> - </span>
                                              <span>
                                                {p.position.description}
                                              </span>
                                            </>
                                          )}
                                          {mf.platform && (
                                            <span>{` (${mf.platform})`}</span>
                                          )}
                                        </p>
                                        {mf.date_note && (
                                          <>
                                            <span className="text-xs leading-4">
                                              &#8226;
                                            </span>
                                            <p className="text-xs leading-4 font-medium">
                                              {mf.date_note}
                                            </p>
                                          </>
                                        )}
                                      </>
                                    </div>
                                  ))}
                                </div>
                                {/* description */}
                                <div className="w-full mt-2">
                                  <p
                                    className="text-6xs text-resumeTempFourTextPrimary tracking-resume leading-4"
                                    dangerouslySetInnerHTML={{
                                      __html: mf.description,
                                    }}
                                  />
                                </div>
                              </div>
                              {/* image */}
                              {mf.thumbnail && show_image_milestone && (
                                <div className="h-23 w-23 bg-default rounded relative overflow-hidden">
                                  <img
                                    alt="milestones"
                                    src={mf.thumbnail.url}
                                    className="w-full h-full object-contain object-center"
                                  />
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    {/* Divider */}
                    <div className="w-full">
                      <ResumeLineDividerIconSVG full={true} />
                    </div>
                  </>
                )}
              {/* Current Milestones */}
              {milestones.hasOwnProperty("current") &&
                !_.isEmpty(milestones.current) && (
                  <>
                    <div className="w-full flex flex-row space-x-8.6">
                      <div className="w-18.6">
                        <p className="text-md font-medium text-resumeTempFourTextPrimary tracking-resumeFour">
                          Current Milestones
                        </p>
                      </div>
                      <div style={{ width: "calc(100% - 112px)" }}>
                        <div className="w-full grid gap-y-3">
                          {/* content */}
                          {milestones.current.map((mf, i) => (
                            <div
                              className="w-full flex flex-row items-center space-x-2.6"
                              key={`current-${i}`}>
                              <div className="w-full">
                                {/* title and date */}
                                <div className="w-full">
                                  <div className="w-full flex flex-row items-center justify-between">
                                    {/* title */}
                                    <div className="flex flex-row items-center space-x-1">
                                      <p className="text-base text-resumeTempFourTextPrimary font-medium tracking-tight">
                                        {mf.title}
                                      </p>
                                      <div
                                        className="text-6xs font-bold"
                                        style={{ color: tertiaryColor + "E6" }}>
                                        <span
                                          className="template-four-padding-skills rounded text-opacity-90"
                                          style={{
                                            backgroundColor:
                                              tertiaryColor + "1F", // opcity 12%
                                          }}>
                                          {mf.category.name}
                                        </span>
                                      </div>
                                    </div>
                                    {/* date */}
                                    <div>
                                      <p className="text-6xs text-resumeTempFourTextPrimary leading-4 flex flex-row items-center space-x-1">
                                        <HiOutlineCalendarDays className="w-4 h-4 text-resumeTempFourIconColor" />
                                        <span className="mt-px">
                                          {renderStartDate(mf)}
                                          {renderEndDate(mf)}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                {/* sub title and episodes */}
                                <div className="w-full mt-0.5">
                                  {mf.positions.map((p, i) => (
                                    <div
                                      key={`featured-position-${i}`}
                                      className="w-full flex flex-row space-x-1"
                                      style={{ color: primaryColor }}>
                                      <>
                                        <p className="text-xs leading-4 font-medium">
                                          <span>{p.position.name}</span>
                                          {p.position.description && (
                                            <>
                                              <span> - </span>
                                              <span>
                                                {p.position.description}
                                              </span>
                                            </>
                                          )}
                                          {mf.platform && (
                                            <span>{` (${mf.platform})`}</span>
                                          )}
                                        </p>
                                        {mf.date_note && (
                                          <>
                                            <span className="text-xs leading-4">
                                              &#8226;
                                            </span>
                                            <p className="text-xs leading-4 font-medium">
                                              {mf.date_note}
                                            </p>
                                          </>
                                        )}
                                      </>
                                    </div>
                                  ))}
                                </div>
                                {/* description */}
                                <div className="w-full mt-2">
                                  <p
                                    className="text-6xs text-resumeTempFourTextPrimary tracking-resume leading-4"
                                    dangerouslySetInnerHTML={{
                                      __html: mf.description,
                                    }}
                                  />
                                </div>
                              </div>
                              {/* image */}
                              {mf.thumbnail && show_image_milestone && (
                                <div className="h-23 w-23 bg-default rounded relative overflow-hidden">
                                  <img
                                    alt="milestones"
                                    src={mf.thumbnail.url}
                                    className="w-full h-full object-contain object-center"
                                  />
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    {/* Divider */}
                    <div className="w-full">
                      <ResumeLineDividerIconSVG full={true} />
                    </div>
                  </>
                )}
              {/* Past Milestones */}
              {milestones.hasOwnProperty("past") &&
                !_.isEmpty(milestones.past) && (
                  <div className="w-full flex flex-row space-x-8.6">
                    <div className="w-18.6">
                      <p className="text-md font-medium text-resumeTempFourTextPrimary tracking-resumeFour">
                        Past Milestones
                      </p>
                    </div>
                    <div style={{ width: "calc(100% - 112px)" }}>
                      <div className="w-full grid gap-y-3">
                        {/* content */}
                        {milestones.past.map((mf, i) => (
                          <div
                            className="w-full flex flex-row items-center space-x-2.6"
                            key={`past-${i}`}>
                            <div className="w-full">
                              {/* title and date */}
                              <div className="w-full">
                                <div className="w-full flex flex-row items-center justify-between">
                                  {/* title */}
                                  <div className="flex flex-row items-center space-x-1">
                                    <p className="text-base text-resumeTempFourTextPrimary font-medium tracking-tight">
                                      {mf.title}
                                    </p>
                                    <div
                                      className="text-6xs font-bold"
                                      style={{ color: tertiaryColor + "E6" }}>
                                      <span
                                        className="template-four-padding-skills rounded text-opacity-90"
                                        style={{
                                          backgroundColor: tertiaryColor + "1F", // opcity 12%
                                        }}>
                                        {mf.category.name}
                                      </span>
                                    </div>
                                  </div>
                                  {/* date */}
                                  <div>
                                    <p className="text-6xs text-resumeTempFourTextPrimary leading-4 flex flex-row items-center space-x-1">
                                      <HiOutlineCalendarDays className="w-4 h-4 text-resumeTempFourIconColor" />
                                      <span className="mt-px">
                                        {renderStartDate(mf)}
                                        {renderEndDate(mf)}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* sub title and episodes */}
                              <div className="w-full mt-0.5">
                                {mf.positions.map((p, i) => (
                                  <div
                                    key={`featured-position-${i}`}
                                    className="w-full flex flex-row space-x-1"
                                    style={{ color: primaryColor }}>
                                    <>
                                      <p className="text-xs leading-4 font-medium">
                                        <span>{p.position.name}</span>
                                        {p.position.description && (
                                          <>
                                            <span> - </span>
                                            <span>
                                              {p.position.description}
                                            </span>
                                          </>
                                        )}
                                        {mf.platform && (
                                          <span>{` (${mf.platform})`}</span>
                                        )}
                                      </p>
                                      {mf.date_note && (
                                        <>
                                          <span className="text-xs leading-4">
                                            &#8226;
                                          </span>
                                          <p className="text-xs leading-4 font-medium">
                                            {mf.date_note}
                                          </p>
                                        </>
                                      )}
                                    </>
                                  </div>
                                ))}
                              </div>
                              {/* description */}
                              <div className="w-full mt-2">
                                <p
                                  className="text-6xs text-resumeTempFourTextPrimary tracking-resume leading-4"
                                  dangerouslySetInnerHTML={{
                                    __html: mf.description,
                                  }}
                                />
                              </div>
                            </div>
                            {/* image */}
                            {mf.thumbnail && show_image_milestone && (
                              <div className="h-23 w-23 bg-default rounded relative overflow-hidden">
                                <img
                                  alt="milestones"
                                  src={mf.thumbnail.url}
                                  className="w-full h-full object-contain object-center"
                                />
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
            </div>
            {/* end */}
            {/* footer */}
            <div className="w-full flex items-end pt-10">
              <div className="w-full">
                <ResumeLineDividerIconSVG full={true} />
                <div className="w-full grid pt-3.5 justify-center">
                  <p className="text-xs text-resumeTempFourTextPrimary leading-4 opacity-90">
                    Page 2 of 2
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
