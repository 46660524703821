import Modal from "components/global/modal";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  InstapaperShareButton,
  EmailShareButton,
  HatenaShareButton,
  LineShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
  WeiboShareButton,
  EmailIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WorkplaceIcon,
} from "react-share";
import { FaTimes } from "react-icons/fa";

export default function ShareSocial({
  url,
  title = "turpis tincidunt id aliquet risus",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  image = "",
  hide = () => {},
  showMore = () => {},
}) {
  return (
    <Modal
      width="w-11/12 1md:w-11/20"
      contentClassName="rounded-lg overflow-auto custom-scroll"
      wrapperClassName="overflow-visible shadow-none">
      <div className="w-full px-8 pt-4 pb-8 prounded-md">
        <div className="flex flex-row justify-between items-baseline mb-8">
          <p className="text-2xl font-bold">Share</p>
          {/* close */}
          <button onClick={() => hide()} className="mr-4">
            <FaTimes className="text-xl" />
          </button>
        </div>
        <div className="grid grid-cols-3 gap-4 sm:grid-cols-3 2md:grid-cols-4 profile-social-share-container">
          <FacebookShareButton
            url={url}
            quote={description}
            className="flex flex-col sm:flex-row item-center">
            <FacebookIcon size={45} className="mr-2" />
            <span className="text-[10px] md:text-lg">Facebook</span>
          </FacebookShareButton>
          <TwitterShareButton
            url={url}
            title={title}
            description={description}
            className="flex flex-col sm:flex-row item-center">
            <TwitterIcon size={45} className="mr-2" />
            <span className="text-[10px] md:text-lg">Twitter</span>
          </TwitterShareButton>
          <LinkedinShareButton
            title={title}
            summary={description}
            url={url}
            className="flex flex-col sm:flex-row item-center">
            <LinkedinIcon size={45} className="mr-2" />
            <span className="text-[10px] md:text-lg">LinkedIn</span>
          </LinkedinShareButton>
          <WorkplaceShareButton
            url={url}
            quote={description}
            className="flex flex-col sm:flex-row item-center">
            <WorkplaceIcon size={45} className="mr-2" />
            <span className="text-[10px] md:text-lg">Workplace</span>
          </WorkplaceShareButton>
          <EmailShareButton
            url={url}
            subject={title}
            body={description}
            className="flex flex-col sm:flex-row item-center">
            <EmailIcon size={45} className="mr-2" />
            <span className="text-[10px] md:text-lg">Email</span>
          </EmailShareButton>
          <InstapaperShareButton
            url={url}
            title={title}
            description={description}
            className="flex flex-col sm:flex-row item-center">
            <InstapaperIcon size={45} className="mr-2" />
            <span className="text-[10px] md:text-lg">Instapaper</span>
          </InstapaperShareButton>
          <HatenaShareButton
            url={url}
            title={title}
            className="flex flex-col sm:flex-row item-center">
            <HatenaIcon size={45} className="mr-2" />
            <span className="text-[10px] md:text-lg">Hatena</span>
          </HatenaShareButton>
          <LineShareButton
            url={url}
            title={title}
            className="flex flex-col sm:flex-row item-center">
            <LineIcon size={45} className="mr-2" />
            <span className="text-[10px] md:text-lg">Line</span>
          </LineShareButton>
          <LivejournalShareButton
            url={url}
            title={title}
            description={description}
            className="flex flex-col sm:flex-row item-center">
            <LivejournalIcon size={45} className="mr-2" />
            <span className="text-[10px] md:text-lg">LiveJournal</span>
          </LivejournalShareButton>
          <MailruShareButton
            url={url}
            title={title}
            description={description}
            imageUrl={image}
            className="flex flex-col sm:flex-row item-center">
            <MailruIcon size={45} className="mr-2" />
            <span className="text-[10px] md:text-lg">Mail.ru</span>
          </MailruShareButton>
          <OKShareButton
            url={url}
            title={title}
            image={image}
            description={description}
            className="flex flex-col sm:flex-row item-center">
            <OKIcon size={45} className="mr-2" />
            <span className="text-[10px] md:text-lg">OK</span>
          </OKShareButton>
          {image && (
            <PinterestShareButton
              url={url}
              media={image}
              description={title}
              className="flex flex-col sm:flex-row item-center">
              <PinterestIcon size={45} className="mr-2" />
              <span className="text-[10px] md:text-lg">Pinterest</span>
            </PinterestShareButton>
          )}
          <PocketShareButton
            url={url}
            title={title}
            className="flex flex-col sm:flex-row item-center">
            <PocketIcon size={45} className="mr-2" />
            <span className="text-[10px] md:text-lg">Pocket</span>
          </PocketShareButton>
          <RedditShareButton
            url={url}
            title={title}
            className="flex flex-col sm:flex-row item-center">
            <RedditIcon size={45} className="mr-2" />
            <span className="text-[10px] md:text-lg">Reddit</span>
          </RedditShareButton>
          <TelegramShareButton
            url={url}
            title={title}
            className="flex flex-col sm:flex-row item-center">
            <TelegramIcon size={45} className="mr-2" />
            <span className="text-[10px] md:text-lg">Telegram</span>
          </TelegramShareButton>
          <TumblrShareButton
            url={url}
            title={title}
            caption={description}
            className="flex flex-col sm:flex-row item-center">
            <TumblrIcon size={45} className="mr-2" />
            <span className="text-[10px] md:text-lg">Tumblr</span>
          </TumblrShareButton>
          <ViberShareButton
            url={url}
            title={title}
            caption={description}
            className="flex flex-col sm:flex-row item-center">
            <ViberIcon size={45} className="mr-2" />
            <span className="text-[10px] md:text-lg">Viber</span>
          </ViberShareButton>
          <VKShareButton
            url={url}
            title={title}
            image={image}
            className="flex flex-col sm:flex-row item-center">
            <VKIcon size={45} className="mr-2" />
            <span className="text-[10px] md:text-lg">VK</span>
          </VKShareButton>
          <WhatsappShareButton
            url={url}
            title={title}
            className="flex flex-col sm:flex-row item-center">
            <WhatsappIcon size={45} className="mr-2" />
            <span className="text-[10px] md:text-lg">Whatsapp</span>
          </WhatsappShareButton>
          <WeiboShareButton
            url={url}
            title={title}
            image={image}
            className="flex flex-col sm:flex-row item-center">
            <WeiboIcon size={45} className="mr-2" />
            <span className="text-[10px] md:text-lg">Weibo</span>
          </WeiboShareButton>
        </div>
      </div>
    </Modal>
  );
}
