export const SET_MESSAGES = 'SET_MESSAGES'
export const ADD_MESSAGE = 'ADD_MESSAGE'

export const SET_ACTIVE_THREAD = 'SET_ACTIVE_THREAD'

export const PUSH_MESSAGE = 'PUSH_MESSAGE'

export const SET_CONVERSATIONS = 'SET_CONVERSATIONS'
export const ADD_CONVERSATION = 'ADD_CONVERSATION'

export const SET_UNREAD_MESSAGES = 'SET_UNREAD_MESSAGES';

export const SET_BLOCK_MESSAGES = 'SET_BLOCK_MESSAGES';