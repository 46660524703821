import {
  SET_COUNTRIES,
	REFRESH_COUNTRIES,
} from './types';

export const setRefreshCountries = () => {
	return {
    type: REFRESH_COUNTRIES,
    payload: [],
  };
}

export const setCountries = (params) => {
	return {
    type: SET_COUNTRIES,
    payload: params,
  };
}