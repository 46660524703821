import React, { useMemo } from "react";
import { HiOutlineCalendarDays } from "react-icons/hi2";
import { renderEndDate, renderStartDate } from "../../resume";
import { socialTemplate } from "utils/constant";
import _ from "lodash";
import { groupArraysWithType, A4_SIZE } from "../../resume";
import Milestone from "./milestone";

export default function TemplateThreePageTwo(props) {
  const {
    active,
    color = {},
    milestones = {},
    social = {},
    primaryColor,
    tertiaryColor,
    show_image_milestone = true,
    setTotalPage,
    profile,
    page
  } = props;

  const [mergeMilestone, setMergeMilestone] = React.useState([]);

  React.useEffect(() => {
      let groupAll = groupArraysWithType(arraysWithType, 7, 7);
      setMergeMilestone(groupAll);
      setTotalPage(groupAll.length + 1);
  }, [milestones]);

  const arraysWithType = [
    { array: milestones?.featured || [], type: 'featured' },
    { array: milestones?.current || [], type: 'current' },
    { array: milestones?.past || [], type: 'past' },
  ];

  const activeColor = useMemo(() => {
    if (color.label === "Voilet + Pink") {
      return tertiaryColor;
    }
    return primaryColor;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  const socMed = useMemo(() => {
    let arrays = Object.entries(social);
    return arrays.map((obj) => {
      let key = obj[0];
      let value = obj[1];
      return { key, value };
    });
  }, [social]);

  return (
    <div className={`w-full`} style={A4_SIZE}>
      <div
        className="w-full bg-resumeTempThreePrimary h-full"
        style={{ minHeight: "1123px" }}>
        <div className="w-full h-full">
          <div className="w-full h-full grid relative grid-row-auto">
            <div className="w-full h-full px-10">
              {/* page number */}
              <div className="w-full relative">
                <div className="w-6 h-6 bg-resumeTempThreeSecondary template-three-page-number">
                  <p className="text-sm text-resumeTempThreeTextSecondary tracking-resumeThree leading-3.2 font-semibold font-template-three">
                    {page}
                  </p>
                </div>
              </div>
              {/* body */}
              <div className="w-full mt-8.6">
                <div className="w-full grid gap-y-8">
                {
                  mergeMilestone.map((item,index)=>{
                    return (
                      page === (index + 2) &&
                      <div key={index}>
                        <Milestone
                          items={item}
                          milestones={milestones}
                          primaryColor={primaryColor}
                          show_image_milestone={show_image_milestone}
                          tertiaryColor={tertiaryColor}
                          activeColor={activeColor}
                          active={active}
                          profile={profile}
                        />
                      </div>
                    )
                  })
                }
                </div>
              </div>
            </div>
            {/* footer */}
            <div className="w-full flex items-end">
              <div className="w-full h-8.5 bg-resumeTempThreeTextPrimary bg-opacity-20 relative">
                <div className="w-full h-full flex flex-row justify-center items-center space-x-3 text-resumeTempThreeTextPrimary">
                  {(socMed || []).map((obj, index) => {
                    const SocialIcon = socialTemplate[obj.key];
                    if (SocialIcon) {
                      return (
                        <div
                          className="flex items-center space-x-1.5"
                          key={index}>
                          <SocialIcon
                            className="text-4xs"
                            style={{ color: activeColor }}
                          />
                          <p className="text-3xs leading-2">{obj.value}</p>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          className="flex items-center space-x-1.5"
                          key={index}>
                          <div className="w-3">
                            <img
                              className="text-4xs"
                              src={`https://s2.googleusercontent.com/s2/favicons?domain=${obj.value}&sz=64`}
                              alt=""
                            />
                          </div>
                          <p className="text-3xs leading-2">{obj.value}</p>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
