const STRIPE_PREMIUM_MONTH = process.env.REACT_APP_STRIPE_PLAN_PREMIUM_MONTH;
const STRIPE_PREMIUM_YEAR = process.env.REACT_APP_STRIPE_PLAN_PREMIUM_YEAR;
const PAYPAL_PREMIUM_MONTH = process.env.REACT_APP_PAYPAL_PREMIUM_MONTH;
const PAYPAL_PREMIUM_YEAR = process.env.REACT_APP_PAYPAL_PREMIUM_YEAR;

export {
  STRIPE_PREMIUM_MONTH,
  STRIPE_PREMIUM_YEAR,
  PAYPAL_PREMIUM_MONTH,
  PAYPAL_PREMIUM_YEAR,
};
