import React, { useState } from "react";
import Modal from "components/global/modal";
import Button from "components/button/button";
import CropProfile from "./crop-images/crop-profile";

export default function CropThumbnailModal({ hide, ...props }) {
  const { image, type, returnType, title } = props;
  const [cropFile, setCropFile] = useState(null);
  const [cropPreview, setCropPreview] = useState(null);

  const getCropData = () => {
    if (cropFile) {
      hide({
        imageFile: cropFile,
        preview: `data:${type};base64,${cropPreview}`,
      });
    }
  };

  const Footer = () => {
    return (
      <div className="relative grid gap-y-3 1sm:gap-y-0 1sm:flex 1sm:flex-row 1sm:items-center 1sm:justify-end 1sm:space-x-2">
        <Button
          buttonName="Cancel"
          buttonClass="bg-thBg py-4 px-8"
          buttonTextClass="text-black text-sm font-semibold"
          onClick={() => hide()}
        />
        <Button
          key="save-btn-category"
          buttonName="Save"
          buttonClass="bg-primary py-4 px-10"
          buttonTextClass="text-white text-sm font-semibold"
          onClick={() => getCropData()}
        />
      </div>
    );
  };

  return (
    <Modal
      footer={<Footer />}
      width="w-18/20 md:w-15/20 2md:w-11/20 3md:w-10/20 4md:w-9/20 1xl:w-7/20"
      contentClassName="rounded-lg overflow-auto custom-scroll"
      wrapperClassName="overflow-visible"
      footerClass="border-t border-gray">
      <div className="w-full">
        {/* header */}
        <div className="w-full">
          <div className="pl-10 pr-7 pt-3.5 flex justify-between items-center">
            <p className="text-base 2sm:text-3xl text-lightBlack font-bold tracking-tighter">
              {title || "Edit Thumbnail"}
            </p>
            {/* close */}
            <span className="close" onClick={() => hide()}></span>
          </div>
          <div className="px-10 py-5 w-full flex justify-center">
            <CropProfile
              setParentCrop={setCropFile}
              setCropPreview={setCropPreview}
              image={image}
              type={type}
              returnType={returnType}
              locked={false}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
