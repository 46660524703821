import moment from "moment";
import _ from "lodash";

export const csx = (classes) => classes.filter(Boolean).join(" ");

function generateRandomAlphanumericString(length) {
  const alphanumeric =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = alphanumeric.length;
  let result = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charactersLength);
    result += alphanumeric.charAt(randomIndex);
  }

  return result;
}

export const formatDate = (obj, dateFormat) => {
  let {
    start_month,
    start_day,
    start_year,
    end_month,
    end_day,
    end_year,
    is_start_date_approximately,
    is_end_date_approximately,
    is_present,
  } = obj;
  let getUser_data = localStorage.getItem('user_data');
      getUser_data = JSON.parse(getUser_data); 
  let date = "";
  let formatString;
  if(getUser_data?.user){
    formatString = !dateFormat ? "MMMM D YYYY" : "D MMMM YYYY";
  }else{
    formatString = localStorage.getItem('dateFormat');
  }
  

  if (is_start_date_approximately) {
    if (start_month && start_year) {
      formatString = (start_day ? formatString : "MMMM YYYY");
      date = `${moment()
        .month(start_month - 1)
        .year(start_year)
        .format(formatString)} (Approximately)`;
    } else if (start_year) {
      date = start_year + " (Approximately)";
    }
  } else {
    if (start_day && start_month && start_year) {
      if (
        Intl.DateTimeFormat().resolvedOptions().timeZone ===
          "America/New_York" ||
        Intl.DateTimeFormat().resolvedOptions().timeZone ===
          "America/Los_Angeles"
      ) {
        date = moment([start_year, start_month - 1, start_day]).format(
          formatString
        );
      } else {
        date = moment([start_year, start_month - 1, start_day]).format(
          formatString
        );
      }
    } else {
      if (start_month && start_year) {
        formatString = (start_day ? formatString : "MMMM YYYY");
        date = `${moment()
          .month(start_month - 1)
          .year(start_year)
          .format(formatString)}`;
      } else if (start_year) {
        date = start_year;
      }
    }
  }

  if (
    (start_day || start_month || start_year) &&
    (end_day || end_month || end_year || is_present)
  ) {
    date = date + " — ";
  }

  if (is_present) {
    date = date + "Present";
  } else {
    if (is_end_date_approximately) {
      if (end_month && end_year) {
        formatString = (end_day ? formatString : "MMMM YYYY");
        date =
          date +
          `${moment()
            .month(end_month - 1)
            .year(end_year)
            .format(formatString)} (Approximately)`;
      } else if (end_year) {
        date = date + end_year + " (Approximately)";
      }
    } else {
      if (end_day && end_month && end_year) {
        if (
          Intl.DateTimeFormat().resolvedOptions().timeZone ===
            "America/New_York" ||
          Intl.DateTimeFormat().resolvedOptions().timeZone ===
            "America/Los_Angeles"
        ) {
          date =
            date +
            moment([end_year, end_month - 1, end_day]).format(formatString);
        } else {
          date =
            date +
            moment([end_year, end_month - 1, end_day]).format(formatString);
        }
      } else {
        if (end_month && end_year) {
          formatString = (end_day ? formatString : "MMMM YYYY");
          date =
            date +
            `${moment()
              .month(end_month - 1)
              .year(end_year)
              .format(formatString)}`;
        } else if (end_year) {
          date = date + `${end_year}`;
        }
      }
    }
  }

  return date;
};

export const urlSerializer = (endpoint, obj) => {
  let params = new URLSearchParams(obj);
  let keysToDelete = [];
  params.forEach((value, key) => {
    if (value === "" || value === "null") {
      keysToDelete.push(key);
    }
  });
  keysToDelete.forEach((key) => {
    params.delete(key);
  });
  return `${endpoint}?${params.toString()}`;
};

export const listFormat = (arr) => {
  const lf = new Intl.ListFormat("en");
  return lf.format(arr);
};

export const capitalizeWords = (string) => {
  return string.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export const isImgUrl = (url) => {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
};

export const randomExtension = () => {
  return generateRandomAlphanumericString(4);
};

export const isValidYoutubeVideoLink = (url) => {
  const youtubeVimeoRegex =
    /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/|vimeo\.com\/)([a-zA-Z0-9_-]{11}|[0-9]+)/;

  return youtubeVimeoRegex.test(url);
};

export const removeUndefinedProperties = (obj) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value !== undefined) {
      acc[key] = value;
    }
    return acc;
  }, {});
};

export const CIVIL_STATUS = [
  { label: "Married", value: "Married" },
  { label: "Widowed", value: "Widowed" },
  { label: "Separated", value: "Separated" },
  { label: "Divorced", value: "Divorced" },
  { label: "Single", value: "Single" },
];

export const CHILD = [
  { label: "0 Children", value: 0 },
  { label: "1 Children", value: 1 },
  { label: "2 Children", value: 2 },
  { label: "3 Children", value: 3 },
  { label: "4 Children", value: 4 },
  { label: "5 Children", value: 5 },
  { label: "6 Children", value: 6 },
  { label: "7 Children", value: 7 },
  { label: "8 Children", value: 8 },
  { label: "9 Children", value: 9 },
  { label: "10 Children", value: 10 },
];

export const HAIR_COLOR = [
  { label: "Black", value: "Black" },
  { label: "Blonde", value: "Blonde" },
  { label: "Brown", value: "Brown" },
  { label: "Dark Brown", value: "Dark Brown" },
  { label: "Red", value: "Red" },
];

export const EYE_COLOR = [
  { label: "Amber ", value: "Amber" },
  { label: "Blue", value: "Blue" },
  { label: "Brown", value: "Brown" },
  { label: "Gray", value: "Gray" },
  { label: "Green", value: "Green" },
  { label: "Hazel", value: "Hazel" },
];

export const resumeLinkId = () => {
  return generateRandomAlphanumericString(8);
};

export async function copyTextToClipboard(text) {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
}

export const imageSquareOrPortrait = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      if (img.width > img.height) {
        if (img.width - img.height > 100) {
          resolve("landscape");
        } else {
          resolve("square");
        }
      } else if (img.width < img.height) {
        resolve("portrait");
      } else {
        resolve("square");
      }
    };
  });
};

export const dateOrder = (current, previous) =>{
  if(_.isEmpty(current) && _.isEmpty(current)){
    return;
  }
  const current_years = current.map(date => date.start_year);
  const minYear = Math.min(...current_years);
  const prev_years = previous.map(date => date.end_year || date.start_year);
  const maxYear = Math.max(...prev_years);
  let dateOrder = {
    Upcoming : _.isEmpty(current) ? 'Upcoming' : `${minYear} to present`,
    Previous : _.isEmpty(previous) ? 'Previous' : `Ordered as ${maxYear}`
  }
  localStorage.setItem("dateOrder", JSON.stringify(dateOrder));

}

export const formatFileSize = (bytes) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

export const personalDateFormat = (date, dateFormat)=>{
  return moment(date).format(!dateFormat ? "MMMM D YYYY" : "D MMMM YYYY");
}

export const display_name = (data)=>{  
  let user_data = JSON.parse(localStorage.getItem('user_data'));
  const {user,profile} = user_data;
  console.log("DISPLAY NAME")
  console.log(user_data);
  if((profile?.displayName)){
    switch (profile?.displayName){
      case "First Name":
        return user?.first_name;
      case "Surename":
        return user?.last_name;
      case "Other Name":
        return user?.other_name || profile?.other_name;
      case "Username":
        return user?.username;
      case "First Name Surname":
        return `${user?.first_name} ${user?.last_name}`;
      default:
        return user?.other_name || profile?.other_name;;
    } 
  }else{
    return user?.username;
  }
}
