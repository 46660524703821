import React from "react";
import AdultContentIconSVG from "assets/svg/adult-content-icon";
import VerifyIconXSVG from "assets/svg/verified-icon-xs";
import UsersIconSVG from "assets/svg/users";
import EditIconSVG from "assets/svg/edit-icon";
import { AiFillDelete } from "react-icons/ai";
import ProfileDefault from "assets/images/profile_default.png";
import { listFormat, capitalizeWords, display_name } from "utils/utils";
import _ from "lodash";

import UpgradePremium from "components/subscription/upgrade-premium";
import LockOutlineIcon from "assets/svg/upgrade/lock-outline-icon"

export default function HeaderProfileList({
  userData,
  profiles = [],
  hideTitle = false,
  editable = false,
  border = false,
  isSubscribed = false,
  onEdit = () => { },
  switchProfile = () => { },
  visitProfile = () => { },
  deletable=false,
  onDelete = ()=>{}
}) {
  
  const onClickSwitch = (obj) => {
    if (obj.id !== userData?.profile?.id) {
      switchProfile(obj);
    } else {
      visitProfile(obj)
    }
  };

  const verfiedFullName = (data)=>{
    if(data.profile_name === `${data?.first_name} ${data?.first_last}`){
      return true;
    }else{
      return false;
    }
  }

  return (
    <div className="w-full">
      {!hideTitle && (
        <div className="flex flex-row items-center px-8 pt-4.5 pb-2.5 space-x-2">
          {!isSubscribed && <LockOutlineIcon />}
          <h1 className="text-lg text-lightBlack font-bold leading-5.5 tracking-tighter">
            My Profiles ({profiles.length})
          </h1>
        </div>
      )}
      {isSubscribed ?
        <div className="w-full">
          {profiles.map((obj, index) => {
            const showAdult = obj.show_adult ? true : false;
            const occupations = !_.isEmpty(obj.occupations)
              ? listFormat(obj.occupations.map(capitalizeWords))
              : null;

            return (
              <div
                key={index}
                className={`w-full px-6 py-3 ${obj.id === userData?.profile?.id ? "bg-profileGray" : ""} ${border ? "border-b border-profileGray" : ""
                  }`}
              >
                <div
                  className={`w-full cursor-pointer ${editable
                    ? "flex 2sm:justify-between 2sm:items-center"
                    : ""
                    }`}
                  onClick={() => onClickSwitch(obj)}
                >
                  <div className="w-full flex items-center gap-x-5">
                    <div className="w-15 h-15 bg-passwordGray rounded-md min-width-12-p">
                      <img
                        src={obj.profile_image_url || ProfileDefault}
                        alt="milestone-profile"
                        className="rounded-md w-15 h-15"
                      />
                    </div>
                    <div className="w-[225px] 2sm:w-full">
                      <div className="w-full 2xs:flex 2xs:items-center 2xs:space-x-2">
                        <p className="text-lightBlack font-bold text-lg leading-5.5 tracking-tighter flex items-center">
                          {/* {userData?.profile?.username || obj?.other_name || userData?.user?.username} */}
                          <> {obj?.profile_name || obj?.other_name || userData?.user?.username}</>
                          {obj?.id3_verified && (
                            <VerifyIconXSVG className="ml-2" />
                          )}
                        </p>
                        {showAdult && (
                          <div className="flex items-center ">
                            <AdultContentIconSVG className="mr-2" />
                            <p className="font-bold text-sm leading-6 text-pink">
                              Adult Content
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="w-full 5sm:flex 5sm:items-center 5sm:space-x-2">
                        {occupations && (
                          <>
                            <div className="profile-occupations text-darkerGray text-xs leading-4.5 w-full">
                              {occupations}
                            </div>
                            {/* <p className="hidden 5sm:block text-sm text-passwordGray">
                              &#x2022;
                            </p> */}
                          </>
                        )}
                      </div>
                      <div>
                        <p className="text-darkerGray text-xs leading-4.5 flex items-center">
                          <UsersIconSVG className="mr-2" />
                          <>{profiles.length} Users</>
                        </p>
                        </div>
                    </div>
                  </div>
                  {(deletable && userData?.profile?.id !== obj.id) && (
                    <button
                      className="2sm:pl-2 py-2 2sm:ml-18 2sm:ml-0"
                      onClick={(e) => {
                        e.stopPropagation();
                        onDelete(obj);
                      }}
                    >
                      <AiFillDelete className="text-2xl text-red  mr-3 md:mr-2 cursor-pointer hover:text-3xl"/>
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        : <UpgradePremium />
      }
    </div>
  );
}
