import React, { useMemo } from "react";
import { groupArraysWithType, A4_SIZE } from "../../resume";
import ItemMilestone from "../template-two-tg/item-milestone";

export default function TemplateTwoPageTwo({ 
  color,
  milestones = {},
  primaryColor,
  tertiaryColor,
  quarternary,
  show_image_milestone = true,
  setTotalPage,
  profile,
  page
}) {

  const [mergeMilestone, setMergeMilestone] = React.useState([])

  React.useEffect(() => {
      let groupAll = groupArraysWithType(arraysWithType, 7, 7);
      console.log(groupAll);
      console.log(groupAll.length);
      setMergeMilestone(groupAll)
      setTotalPage(groupAll.length+1) 
  }, [milestones]);

  const arraysWithType = [
    { array: milestones?.featured || [], type: 'featured' },
    { array: milestones?.current || [], type: 'current' },
    { array: milestones?.past || [], type: 'past' },
  ];

  const [pageBGActive, pageBGInactive] = useMemo(() => {
    if (color.label === "Blue + Orange") {
      return [tertiaryColor, "#16438A"];
    } else if (color.label === "Green + Yellow") {
      return ["#FFFFFF", "#000000"];
    } else if (color.label === "Pink + Dark Pink") {
      return ["#FFFFFF", tertiaryColor];
    }
    return ["#FFFFFF", "#000000"];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  return (
    <div className={`w-full`} style={A4_SIZE}>
      {/* start body */}
      <div
        className="w-full grid bg-white relative grid-row-auto h-full relative"
        style={{ minHeight: "842px" }}>
        {/* body */}
        <div className="w-full px-15 pt-10">
          <div className="h-full bg-white">
            {
              mergeMilestone.map((item,index)=>{
                return (
                  page === (index+2) &&
                  <div key={index}>
                    <ItemMilestone
                      {...{
                        primaryColor,
                        tertiaryColor , 
                        profile,
                        show_image_milestone
                      }}
                      headerType = 'featured'
                      items={item}
                      />
                      <ItemMilestone
                      {...{
                        primaryColor,
                        tertiaryColor , 
                        profile,
                        show_image_milestone
                      }}
                      headerType = 'current'
                      items={item}
                      />
                    <ItemMilestone
                      {...{
                        primaryColor,
                        tertiaryColor , 
                        profile,
                        show_image_milestone
                      }}
                      headerType = 'past'
                      items={item}
                      />
                  </div>
                )
              })
            }
          </div>
        </div>
        {/* footer */}
        <div className="absolute bottom-0 right-0 w-full flex items-end">
          <div
            className="w-full h-6 px-15 relative"
            style={{ backgroundColor: quarternary }}>
            <div className="w-full h-full flex flex-row justify-between items-center">
              <p className="text-white font-semibold text-1xs">Page {page}</p>
              <div className="flex flex-start gap-1.5">
                <button
                  className="w-1.5 h-1.5 rounded-full"
                  style={{ backgroundColor: pageBGInactive }}
                />
                <button
                  className="w-1.5 h-1.5 rounded-full"
                  style={{ backgroundColor: pageBGActive }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
