import Button from "components/button/button";
import CustomCheckbox from "components/checkbox/custom-checkbox";
import ImageUpload from "components/image-upload/image-upload";
import PopInfo from "components/info-popup/pop-info";
import { Profile1 } from "constants/images";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { FiCamera } from "react-icons/fi";
import { personalDateFormat } from "utils/utils";

export default function PersonalPanel({
  previewImage,
  socials,
  userData,
  details,
  socialMedia,
  personalDetails,
  onNext,
  onBack,
  onChangePersonalInfo,
  onChangeSocialInfo,
  show_image,
  profile_image_withURL,
  profile,
}) {
  const [options, setOptions] = useState({
    personal: [],
    social: [],
  });
  const [selectedInfo, setSelectedInfo] = useState([]);
  const [selectedSocial, setSelectedSocial] = useState([]);
  const profileType = profile.profile_type.type ?? "individual";

  /*********** functions /***********/
  const onCherryPickInfo = (value, record) => {
    console.log(value);
    console.log(record);
    let payload = _.clone(selectedInfo);
    if (value) {
      payload.push(record);
      setSelectedInfo(payload);
      onChangePersonalInfo(payload);
    } else {
      _.remove(payload, function (n) {
        return n.id === record.id;
      });
      setSelectedInfo(payload);
      onChangePersonalInfo(payload);
    }
  };

  const onCherryPickSocial = (value, record) => {
    let payload = _.clone(selectedSocial);
    if (value) {
      payload.push(record);
      setSelectedSocial(payload);
      onChangeSocialInfo(payload);
    } else {
      _.remove(payload, function (n) {
        return n.id === record.id;
      });
      setSelectedSocial(payload);
      onChangeSocialInfo(payload);
    }
  };

  const changePicture = (event) => {
    const file = event.target.files[0];
    let data = _.find(options.personal, { id: 1 });
    var index = _.findIndex(options.personal, { id: 1 });
    let selected = _.findIndex(selectedInfo, { id: 1 });

    if (!_.isEmpty(data) && selected > -1) {
      let personal = [...options.personal];
      let info = [...selectedInfo];

      data.value = file;

      personal.splice(index, 1, data);
      setOptions((prev) => ({
        ...prev,
        personal: personal,
      }));

      info.splice(selected, 1, data);
      setSelectedInfo(info);
      onChangePersonalInfo(info);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(userData)) {
      const { profile, user } = userData;

      const name =
        user.first_name && user.last_name
          ? `${user.first_name} ${user.last_name}`
          : "";
      console.log("Details");
      console.log(details);
      const profileImage =
        !_.isEmpty(details) && details.withUrl
          ? details.profile_image
          : profile?.withUrl || profile?.profile_image_url || "";

      const gender =
        !_.isEmpty(details) && details.gender
          ? details.gender
          : profile?.gender || "";

      const pronoun =
        !_.isEmpty(details) && details.pronoun
          ? details.pronoun
          : profile?.pronoun || "";

      const address =
        !_.isEmpty(details) && details.address
          ? details.address
          : profile?.address || "";

      const occupations =
        !_.isEmpty(details) && details.occupations
          ? details.occupations
          : profile?.occupations || "";

      let profile_type = [];

      // if (details.profile_type) {
      //   profile_type = [
      //     {
      //       id: 7,
      //       label: "Profile Type",
      //       key: "profile_type",
      //       type: "array",
      //       value: details?.profile_type,
      //     },
      //   ];
      // } else if (profile.profile_type) {

      //   profile_type = [
      //     {
      //       id: 7,
      //       label: "Profile Type",
      //       key: "profile_type",
      //       type: "array",
      //       value: profile?.profile_type?.type
      //         ? [_.upperFirst(profile?.profile_type?.type)]
      //         : [],
      //     },
      //   ];
      // }

      const personal = profile?.profile_type?.type === "group" || profile?.profile_type?.type === "team"
        ? [
          {
            id: 1,
            label: "Profile Picture",
            key: "profile_image",
            type: "upload",
            value: profileImage,
          },
          {
            id: 2,
            label: "Formation Date",
            key: "birthdate",
            type: "text",
            value: personalDateFormat(profile.birthdate, profile?.dateFormat) || "",
          },
          {
            id: 3,
            label: "Formation Location",
            key: "birth_location",
            type: "text",
            value: profile.birth_location || "",
          },
          {
            id: 4,
              label: "Members",
              key: "members",
              type: "members_object",
              value: JSON.parse(profile.profile_type.members),
            },
          ]
          : [
            {
              id: 1,
              label: "Profile Picture",
              key: "profile_image",
              type: "upload",
              value: profileImage,
            },
            {
              id: 2,
              label: "Name/Other Name",
              key: "name",
              type: "text",
              value: name,
            },
            {
              id: 3,
              label: "Gender",
              key: "gender",
              type: "text",
              value: gender,
            },
            {
              id: 4,
                label: "Pronouns",
                key: "pronoun",
                type: "text",
                value: profile.pronoun || "",
              },
              {
                id: 5,
                label: "Address",
                key: "address",
                type: "text",
                value: profile.address || "",
              },
              {
                id: 6,
                label: "Occupations",
                key: "occupations",
                type: "array",
                value: profile.occupations || [],
              },
            ];

      let finalOptions = [...personal, ...profile_type];
      setOptions((prev) => ({
        ...prev,
        personal: finalOptions,
      }));

      if (_.isEmpty(details)) {
        setSelectedInfo(finalOptions);
        onChangePersonalInfo(finalOptions);
      } else {
        const editDetails = [];
        // profile image
        if (
          personalDetails &&
          personalDetails.hasOwnProperty("profile_image")
        ) {
          editDetails.push({
            id: 1,
            label: "Profile Picture",
            key: "profile_image",
            type: "upload",
            value: profileImage,
          });
        }
        // name
        if (personalDetails && personalDetails.hasOwnProperty("name")) {
          editDetails.push({
            id: 2,
            label: "Name/Other Name",
            key: "name",
            type: "text",
            value: name,
          });
        }
        // gender
        if (personalDetails && personalDetails.hasOwnProperty("gender")) {
          editDetails.push({
            id: 3,
            label: "Gender",
            key: "gender",
            type: "text",
            value: gender,
          });
        }
        // pronoun
        if (personalDetails && personalDetails.hasOwnProperty("pronoun")) {
          editDetails.push({
            id: 4,
            label: "Pronouns",
            key: "pronoun",
            type: "text",
            value: pronoun,
          });
        }
        // address
        if (personalDetails && personalDetails.hasOwnProperty("address")) {
          editDetails.push({
            id: 5,
            label: "Address",
            key: "address",
            type: "text",
            value: address,
          });
        }
        // occupation
        if (personalDetails && personalDetails.hasOwnProperty("occupations")) {
          editDetails.push({
            id: 6,
            label: "Occupations",
            key: "occupations",
            type: "array",
            value: occupations,
          });
        }
        // // profile type
        // if (personalDetails && personalDetails.hasOwnProperty("profile_type")) {
        //   editDetails.push({
        //     id: 7,
        //     label: "Profile Type",
        //     key: "profile_type",
        //     type: "array",
        //     value: details.profile_type,
        //   });
        // }

        setSelectedInfo(editDetails);
        onChangePersonalInfo(editDetails);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalDetails, userData]);

  useEffect(() => {
    let social = [];
    let editSocial = [];

    socialMedia.forEach((sm, i) => {
      if (socials.hasOwnProperty(sm.type.toLowerCase())) {
        let obj = {
          id: i + 1,
          label: sm.type,
          key: sm.type.toLowerCase(),
          selected: true,
          type: "text",
          value: socials[sm.type.toLowerCase()] || "",
        };
        social.push(obj);
        editSocial.push(obj);
      } else {
        let obj = {
          id: i + 1,
          label: sm.type,
          key: sm.type.toLowerCase(),
          selected: true,
          type: "text",
          value: sm.link || "",
        };
        social.push(obj);
      }
    });

    setOptions((prev) => ({
      ...prev,
      social,
    }));

    if (_.isEmpty(socials) && _.isEmpty(details)) {
      setSelectedSocial(social);
      onChangeSocialInfo(social);
    } else {
      setSelectedSocial(editSocial);
      onChangeSocialInfo(editSocial);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socialMedia]);
  useEffect(() => {
    console.log(show_image);
    if (options.personal.length > 0) {
      let personalTemp = _.clone(options.personal);
      if (!show_image) {
        personalTemp.shift();
        showHideImage(personalTemp);
      } else {
        showHideImage(personalTemp);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show_image, options]);

  const showHideImage = (cloneObject) => {
    console.log(cloneObject);
    setSelectedInfo(cloneObject);
    onChangePersonalInfo(cloneObject);
  };

  return (
    <div className="w-full h-full border-r border-resumeBorder">
      <div
        className="w-full overflow-y-auto overflow-x-hidden custom-scroll"
        style={{ height: "calc(100vh - 174px)" }}>
        <div className="w-full px-2 2sm:px-15.5 py-10.6">
          <div className="w-full flex flex-row gap-x-2 items-center">
            <h1 className="text-lightBlack text-base 2sm:text-xl font-semibold">
              Show/Hide Personal Info
            </h1>
            <PopInfo containerClass="relative" type="show_hide_personal_info" />
          </div>

          <div className="w-full mt-6 border-t border-resumeBorder">
            <ul className="w-full">
              {options.personal.map((obj, index) => {
                let findOject = _.find(selectedInfo, { id: obj.id });
                let checked = !_.isEmpty(findOject);
                return (
                  <li
                    className="w-full py-4 border-b border-resumeBorder"
                    key={index}>
                    <div className="w-full flex flex-col 2sm:flex-row space-x-8 2sm:space-x-3">
                      {/* checkbox and label */}
                      <div className="w-1/2 flex flex-row items-start">
                        <CustomCheckbox
                          name={`personal-${index}`}
                          checked={checked}
                          onChange={(e) => {
                            let value = e.target.checked;
                            onCherryPickInfo(value, obj);
                          }}
                        />
                        <p className="text-lightBlack font-medium leading-6">
                          {obj.label}
                        </p>
                      </div>
                      {/* end checkbox and label */}
                      <div className="w-1/2">
                        {obj.key === "profile_image" ? (
                          <DisplayData
                            type={obj.type}
                            value={previewImage}
                            onChange={changePicture}
                          />
                        ) : (
                          <DisplayData type={obj.type} value={obj.value} />
                        )}
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          {
            (profileType !== "group" && profileType !== "team") &&
              <div className="w-full mt-9">
                <h1 className="text-lightBlack text-xl font-semibold">
                  Social Media
                </h1>
                <div className="w-full mt-6 border-t border-resumeBorder">
                  <ul className="w-full">
                    {options.social.map((obj, index) => {
                      let findOject = _.find(selectedSocial, { id: obj.id });
                      let checked = !_.isEmpty(findOject);
                      return (
                        <li
                          className="w-full py-4 border-b border-resumeBorder"
                          key={index}>
                          <div className="w-full flex flex-col 2sm:flex-row space-x-8 2sm:space-x-3">
                            {/* checkbox and label */}
                            <div className="w-1/2 flex flex-row items-center">
                              <CustomCheckbox
                                name={`social-${index}`}
                                checked={checked}
                                onChange={(e) => {
                                  let value = e.target.checked;
                                  onCherryPickSocial(value, obj);
                                }}
                              />
                              <p className="text-lightBlack font-medium leading-6">
                                {obj.label}
                              </p>
                            </div>
                            {/* end checkbox and label */}
                            <div className="w-full 2sm:w-1/2 text-ellipsis overflow-hidden">
                              <DisplayData type={obj.type} value={obj.value} />
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
          }
        </div>
      </div>
      {/* footer */}
      <div className="w-full py-4 px-6 border-t border-resumeBorder">
        <div className="w-full flex justify-center 2sm:justify-end space-x-5">
          <Button
            buttonName="Back"
            buttonTextClass="text-sm font-semibold text-lightBlack"
            buttonRadius="6px"
            buttonClass="bg-thBg px-12 py-3 2sm:px-17.5 2sm:py-5"
            onClick={onBack}
          />
          <Button
            buttonName="Next"
            buttonTextClass="text-sm font-semibold text-white"
            buttonRadius="6px"
            buttonClass="bg-primary px-12 py-3 2sm:px-17.5 2sm:py-5"
            onClick={onNext}
          />
        </div>
      </div>
    </div>
  );
}

function DisplayData({ type, value, onChange }) {
  if (type === "upload") {
    return (
      <ImageUpload
        image={value || Profile1}
        accept="image/*"
        icon={<FiCamera className="text-white text-lg" />}
        id="profile-photo"
        onChange={(e) => onChange(e)}
      />
    );
  } else if (type === "array") {
    return (
      <div className="w-full flex flex-wrap gap-1.5">
        {(value || []).map((val, index) => (
          <label
            key={index}
            className="py-2 px-3 rounded-md text-center bg-thBg text-darkerGray text-sm font-medium">
            {val.name ? val.name : val}
          </label>
        ))}
      </div>
    );
  } else if (type === "members_object") {
    return (
      <div className="w-full flex flex-wrap gap-1.5 flex-column">
        {Object.keys(value || {}).map((key, index) => (
          // <p className="text-base text-darkerGray leading-6">{`${value[key].name}`}</p>
          <p className="text-base text-darkerGray leading-6" dangerouslySetInnerHTML={{ __html: value[key]?.mention || value[key].name }}></p>
          
        ))}
      </div>
    );
  } else {
    return value ? (
      <p className="text-base text-darkerGray leading-6">{`${value}`}</p>
    ) : null;
  }
}
