import React, { useState } from "react";
import Input from "components/input/input";
// import toast from "react-hot-toast";
import Button from "components/button/button";
import LoadingIndicator from "assets/svg/loading-indicator";
import Layout from "layouts/layout";
import LogoComponent from "components/logo/logo";
import { Link } from "react-router-dom";
import PasswordValidation from "components/password-validation/password-validation";
import { useLocation } from "react-router-dom";

const PasswordReset = () => {
  const { http } = global.services;

  const query = useQuery();

  const [state, setState] = useState({
    password: "",
    comfirm_password: "",
    loading: false,
    showPassword: false,
    showPasswordConfirm: false,
    validationErrors: null,
    success: false,
  });
  const [passwordValidation, setPasswordValidation] = useState({
    passwordHasUpperCaseLetter: false,
    passwordHasLowerCaseLetter: false,
    passwordHasNumber: false,
    passwordHasEightCharacters: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "password") {
      const hasUpperCase = /[A-Z]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasEightCharacters = value.length >= 8;
      const hasNumber = /\d/.test(value);

      setPasswordValidation((prevState) => ({
        ...prevState,
        passwordHasUpperCaseLetter: hasUpperCase,
        passwordHasLowerCaseLetter: hasLowerCase,
        passwordHasEightCharacters: hasEightCharacters,
        passwordHasNumber: hasNumber,
      }));
    }
    setState((prev) => ({
      ...prev,
      [name]: value,
      validationErrors: { ...state.validationErrors, [name]: null },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setState((prev) => ({ ...prev, validationErrors: null, loading: true }));
    try {
      await http.post("auth/reset-password", {
        email: query.get("email"),
        password: state.password,
        password_confirmation: state.comfirm_password,
        token: query.get("requestToken"),
      });

      setState((prev) => ({
        ...prev,
        password: "",
        comfirm_password: "",
        success: true,
      }));
    } catch (err) {
      const { data, status } = err;
      if (status === 422) {
        setState((prev) => ({
          ...prev,
          validationErrors: data,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          validationErrors: {
            message: "Something wen't wrong. Please try again.",
          },
        }));
      }
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  return (
    <Layout title="Password Reset">
      <div className="main-page-wrapper">
        <div className="login-container">
          {/*  */}
          <LogoComponent />
          {/*  */}
          <div className="w-full">
            <div className="form-container">
              <h2 className="form-column-title pb-2">Reset Password</h2>
              {state.success && (
                <p className="form-column-subtitle text-green">
                  Password successful reseted.
                </p>
              )}
              {state.validationErrors?.token && (
                <p className="form-column-subtitle text-red">Invalid token</p>
              )}
              {state.validationErrors?.message && (
                <p className="form-column-subtitle text-red">
                  {state.validationErrors.message}
                </p>
              )}

              <form
                className="form-column-container"
                id="create-account-component"
                onSubmit={handleSubmit}>
                <Input
                  inputContainerClass="form-input-container"
                  showInputValidation={true}
                  value={state.password}
                  outsideLabel="New Password"
                  outsideLabelClass="text-sm font-bold text-darkerGray"
                  noLabelPaddingY="1rem"
                  name="password"
                  masked={true}
                  validationPassed={Object.values(passwordValidation).every(
                    (item) => item === true
                  )}
                  visible={state.showPassword}
                  onChangeVisible={() =>
                    setState((prev) => ({
                      ...prev,
                      showPassword: !prev.showPassword,
                    }))
                  }
                  type={state.showPassword ? "text" : "password"}
                  onChange={handleInputChange}
                  errorMessage={state.validationErrors?.password}
                />
                <PasswordValidation passwordValidation={passwordValidation} />
                <Input
                  inputContainerClass="form-input-container"
                  value={state.comfirm_password}
                  outsideLabel="Confirm Password"
                  outsideLabelClass="text-sm font-bold text-darkerGray"
                  noLabelPaddingY="1rem"
                  name="comfirm_password"
                  masked={true}
                  visible={state.showPasswordConfirm}
                  onChangeVisible={() =>
                    setState((prev) => ({
                      ...prev,
                      showPasswordConfirm: !prev.showPasswordConfirm,
                    }))
                  }
                  type={state.showPasswordConfirm ? "text" : "password"}
                  onChange={handleInputChange}
                  errorMessage={
                    state.validationErrors?.password_confirmation &&
                    state.validationErrors?.password_confirmation[0]
                  }
                />

                <Button
                  buttonName="Reset Password"
                  disabled={state.loading}
                  buttonTextClass="text-sm text-white font-semibold"
                  buttonRadius="6px"
                  buttonClass="relative bg-primary py-3.5 mb-10"
                  buttonWidth="100%"
                  type="submit"
                  icon={
                    <div className="absolute right-3 bottom-0 top-0 flex items-center">
                      {state.loading && (
                        <LoadingIndicator className="text-white w-5 h-5" />
                      )}
                    </div>
                  }
                />
                {/* footer */}
                <div className="border-t border-gray text-sm font-semibold text-lightBlack py-5">
                  <div className="w-full flex justify-center items-center">
                    <Link to="/login" className="text-primary">
                      Back to Login
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export default PasswordReset;
