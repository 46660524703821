import { useState } from "react";
import Modal from "components/global/modal";
import Button from "components/button/button";
import LoadingIndicator from "assets/svg/loading-indicator";
import { AiFillDelete } from "react-icons/ai";
const DeleteAccount = ({
    hide,
    userData
})=>{
    const { http } = global.services;
    const [submitLoading, setSubmitLoading] = useState(false);

    const confirmDelete = async()=>{
        const {user} = userData;
        try {
            setSubmitLoading(true);
            const { data } = await http.delete(`public/user/${user.id}`);
            hide({ data, success: true });
          } catch (err) {
            console.log("error@delete", err);
            setSubmitLoading(false);
          } finally {
            setSubmitLoading(false);
          }
    }

    return (
        <>
        <Modal 
            width="w-19/20 md:w-16/20 2md:w-12/20 3md:w-1/3 1xl:w-8/20"
            contentClassName="rounded-lg overflow-auto custom-scroll"
            wrapperClassName="overflow-visible"
            footerClass="border-t border-gray">
            <div className="w-full">
              {/* header */}
              <div className="px-8.5 py-2 mt-4 flex gap-2 items-center">
                <div className="text-red text-xl"><AiFillDelete/></div>
                <h1 className="text-xl font-bold text-red">Delete Account</h1>
              </div>
              <div className="px-8.5 py-2 mb-2">
                <h1>Are you sure you want to delete your account?</h1>
                <h1 className="pt-2">If you delete your account, you'll be permanently removing if from our systems - you can't undo it.</h1>
              </div>
              </div>
              <div className="w-full flex items-center justify-start 1sm:justify-end space-x-2 border-t border-gray pt-4 py-4 pr-4">
                <Button
                  buttonName="Cancel"
                  buttonClass="bg-thBg py-4 px-8 hover:bg-gray"
                  buttonTextClass="text-black text-sm font-semibold"
                  onClick={hide}
                />
                <Button
                  key="save-btn-category"
                  buttonName="Delete account"
                  buttonClass="relative py-4 px-9 bg-red"
                  buttonTextClass="text-white text-sm font-semibold flex items-center"
                  onClick={confirmDelete}
                  loadingIcon={
                    submitLoading ? (
                      <LoadingIndicator className="text-white w-4 h-4 ml-2" />
                    ) : null
                  }
                />
              </div>

            </Modal>
        </>
    )
}

export default DeleteAccount;