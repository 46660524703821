import {
  REFRESH_MEDIA_STYPES,
	SET_MEDIA_TYPES,
} from './types';

export const setRefreshMediaTypes = () => {
	return {
    type: REFRESH_MEDIA_STYPES,
    payload: [],
  };
}

export const setMediaTypes = (params) => {
	return {
    type: SET_MEDIA_TYPES,
    payload: params,
  };
}