import React from "react";
import _ from "lodash";
import moment from "moment";
import { HiOutlineCalendarDays } from "react-icons/hi2";
import { renderStartDate, renderEndDate } from "../../resume";

export default function TemplateOne({
  more_info = {},
  personal_info = {},
  milestones = {},
  secondaryColor,
  tertiaryColor,
  profile_image_withURL,
}) {
  return (
    <div className="w-full">
      <div
        className="w-full flex flex-row overflow-hidden"
        style={{ height: "400px" }}>
        {/** left content */}
        <div
          className={"w-20/20 pt-4 px-4"}
          style={{ backgroundColor: secondaryColor }}>
          {/** photo */}
          {personal_info?.profile_image && (
            <>
              <div className="w-full flex justify-center">
                <div className="w-14 rounded-full relative overflow-hidden">
                  {personal_info &&
                  personal_info.hasOwnProperty("profile_image") &&
                  personal_info.profile_image ? (
                    <img
                      src={profile_image_withURL || personal_info.profile_image}
                      className="w-14 object-cover rounded-full"
                      alt="resume-profile"
                    />
                  ) : (
                    <div className="w-14 h-14 bg-white"></div>
                  )}
                </div>
              </div>
            </>
          )}
          {/** name */}
          <div className="border-b border-gray-300 py-0.5">
            {more_info && (
              <span
                className={"text-4xs font-bold"}
                style={{ color: tertiaryColor }}>
                {more_info.username}
              </span>
            )}
          </div>
          {/** personal information */}
          <div className="border-b border-gray-300 pb-1.5">
            <div className="my-1.5">
              <p className="text-5xs font-medium text-lightBlack tracking-light leading-0">
                Personal Information
              </p>
            </div>
            <div className="grid grid-cols-2 gap-x-2 gap-y-0.5 items-baseline text-1xs">
              {personal_info &&
                personal_info.hasOwnProperty("name") &&
                personal_info.name && (
                  <>
                    <div>
                      <span className="text-5xs text-darkGray font-medium">
                        Name
                      </span>
                    </div>
                    <div>
                      <p className="text-5xs text-lightblack">
                        {personal_info.name}
                      </p>
                    </div>
                  </>
                )}
              {personal_info && personal_info.hasOwnProperty("gender") && (
                <>
                  <div>
                    <span className="text-5xs text-darkGray font-medium">
                      Gender
                    </span>
                  </div>
                  <div>
                    <p className="text-5xs text-lightblack">
                      {personal_info.gender}
                    </p>
                  </div>
                </>
              )}
              {personal_info && personal_info.hasOwnProperty("pronoun") && (
                <>
                  <div>
                    <span className="text-5xs text-darkGray font-medium">
                      Pronouns
                    </span>
                  </div>
                  <div>
                    <p className="text-5xs text-lightblack">
                      {personal_info.pronoun}
                    </p>
                  </div>
                </>
              )}
              {personal_info && personal_info.hasOwnProperty("occupations") && (
                <>
                  <div>
                    <span className="text-5xs text-darkGray font-medium">
                      Occupation
                    </span>
                  </div>
                  <div>
                    <p className="text-5xs text-lightblack">
                      {personal_info.occupations}
                    </p>
                  </div>
                </>
              )}
              {more_info &&
                more_info.hasOwnProperty("industry") &&
                more_info.industry && (
                  <>
                    <div>
                      <span className="text-5xs text-darkGray font-medium">
                        Industry
                      </span>
                    </div>
                    <div>
                      <p className="text-5xs text-lightblack">
                        {more_info.industry}
                      </p>
                    </div>
                  </>
                )}
              {more_info && more_info.hasOwnProperty("type") && (
                <>
                  <div>
                    <span className="text-5xs text-darkGray font-medium">
                      Type
                    </span>
                  </div>
                  <div>
                    <p className="text-5xs text-lightblack">{more_info.type}</p>
                  </div>
                </>
              )}
              {more_info && more_info.hasOwnProperty("style") && (
                <>
                  <div>
                    <span className="text-2xs text-darkGray font-medium">
                      Style
                    </span>
                  </div>
                  <div>
                    <p className="text-1xs text-lightblack">
                      {more_info.style}
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
          {/** bio */}
          {more_info && more_info.hasOwnProperty("bio") && (
            <div className="border-b border-gray-300 py-1.5">
              <div className="mb-1.5">
                <p className="text-5xs font-medium text-lightBlack tracking-light">
                  Bio
                </p>
              </div>
              <p className="text-5xs text-lightblack">{more_info.bio}</p>
            </div>
          )}
          {/** more information */}
          <div className="border-b border-gray-300 pt-2 pb-2">
            <div className="mb-1.5">
              <p className="text-5xs font-medium text-lightBlack tracking-light">
                More Information
              </p>
            </div>
            <div className="grid grid-cols-2 gap-x-2 gap-y-0.5 items-baseline text-1xs">
              {more_info &&
                more_info.hasOwnProperty("birthdate") &&
                more_info.birthdate && (
                  <>
                    <div>
                      <span className="text-5xs text-darkGray font-medium">
                        Date of Birth
                      </span>
                    </div>
                    <div>
                      <p className="text-5xs text-lightblack">
                        {moment(more_info.birthdate).format("DD MMMM YYYY")}
                      </p>
                    </div>
                  </>
                )}
              {more_info &&
                more_info.hasOwnProperty("birth_location") &&
                more_info.birth_location && (
                  <>
                    <div>
                      <span className="text-5xs text-darkGray font-medium">
                        Birth Location
                      </span>
                    </div>
                    <div>
                      <p className="text-5xs text-lightblack">
                        {more_info.birth_location}
                      </p>
                    </div>
                  </>
                )}
              {more_info &&
                more_info.hasOwnProperty("civil_status") &&
                more_info.civil_status && (
                  <>
                    <div>
                      <span className="text-5xs text-darkGray font-medium">
                        Civil Status
                      </span>
                    </div>
                    <div>
                      <p className="text-5xs text-lightblack">
                        {more_info.civil_status}
                      </p>
                    </div>
                  </>
                )}
              {more_info &&
                more_info.hasOwnProperty("children") &&
                more_info.children !== 0 && (
                  <>
                    <div>
                      <span className="text-5xs text-darkGray font-medium">
                        Children
                      </span>
                    </div>
                    <div>
                      <p className="text-5xs text-lightblack">
                        {more_info.children}
                      </p>
                    </div>
                  </>
                )}
              {more_info &&
                more_info.hasOwnProperty("other_name") &&
                more_info.other_name && (
                  <>
                    <div>
                      <span className="text-5xs text-darkGray font-medium">
                        Other Name
                      </span>
                    </div>
                    <div>
                      <p className="text-5xs text-lightblack">
                        {more_info.other_name}
                      </p>
                    </div>
                  </>
                )}
              {more_info &&
                more_info.hasOwnProperty("phone_number") &&
                more_info.phone_number && (
                  <>
                    <div>
                      <span className="text-5xs text-darkGray font-medium">
                        Phone
                      </span>
                    </div>
                    <div>
                      <p className="text-5xs text-lightblack">
                        {more_info.phone_number}
                      </p>
                    </div>
                  </>
                )}
              {more_info &&
                more_info.hasOwnProperty("email") &&
                more_info.email && (
                  <>
                    <div>
                      <span className="text-5xs text-darkGray font-medium">
                        Email Address
                      </span>
                    </div>
                    <div className="break-all">
                      <p className="text-5xs text-lightblack">
                        {more_info.email}
                      </p>
                    </div>
                  </>
                )}
              {more_info &&
                more_info.hasOwnProperty("hair_color") &&
                more_info.hair_color && (
                  <>
                    <div>
                      <span className="text-5xs text-darkGray font-medium">
                        Hair Color
                      </span>
                    </div>
                    <div>
                      <p className="text-5xs text-lightblack">
                        {more_info.hair_color}
                      </p>
                    </div>
                  </>
                )}
              {more_info &&
                more_info.hasOwnProperty("eye_color") &&
                more_info.eye_color && (
                  <>
                    <div>
                      <span className="text-5xs text-darkGray font-medium">
                        Eye Color
                      </span>
                    </div>
                    <div>
                      <p className="text-5xs text-lightblack">
                        {more_info.eye_color}
                      </p>
                    </div>
                  </>
                )}
              {more_info &&
                more_info.hasOwnProperty("height") &&
                more_info.height && (
                  <>
                    <div>
                      <span className="text-5xs text-darkGray font-medium">
                        Height
                      </span>
                    </div>
                    <div>
                      <p className="text-5xs text-lightblack">
                        {more_info.height}cm
                      </p>
                    </div>
                  </>
                )}
            </div>
          </div>
        </div>
        {/** right content */}
        <div className="w-full bg-white pt-6 pb-3.7 px-3">
          <div style={{ width: "150px" }}>
            <div className="w-full mt-1">
              <div style={{ height: "740px" }} className="overflow-hidden">
                {/* featured milestones */}
                {milestones.hasOwnProperty("featured") &&
                  !_.isEmpty(milestones.featured) && (
                    <div className="w-full flex flex-col space-x-2 mb-2">
                      <div
                        className="px-2 py-1 rounded-sm mb-2"
                        style={{ backgroundColor: secondaryColor }}>
                        <p className="text-5xs font-medium text-lightBlack tracking-tight">
                          Featured Milestones
                        </p>
                      </div>
                      {milestones.featured.map((mf, i) => (
                        <div key={`t1-featured-${i}`} className="mb-1.5 pr-2">
                          <div className="flex justify-between items-center gap-1.5">
                            <div className="flex items-center gap-1">
                              <p className="text-5xs font-medium text-lightBlack">
                                {mf.title}
                              </p>
                              <p
                                className="font-medium uppercase rounded-sm border text-xxs px-0.5"
                                style={{ color: tertiaryColor }}>
                                {mf?.category?.name}
                              </p>
                            </div>
                            <div className="flex text-5xs text-darkGray gap-1">
                              <HiOutlineCalendarDays className="w-2 h-2 text-darkerGray" />
                              {renderStartDate(mf)}
                              {renderEndDate(mf)}
                            </div>
                          </div>
                          <div className="mb-1">
                            {mf.positions.map((p, i) => (
                              <div
                                key={`t1-past-position-${i}`}
                                className="text-5xs text-darkGray font-medium">
                                <span>
                                  <span className="text-5xs">
                                    {p.position.name}
                                  </span>
                                  {p.position.description && (
                                    <>
                                      <span className="text-5xs"> - </span>
                                      <span className="text-5xs">
                                        {p.position.description}
                                      </span>
                                    </>
                                  )}
                                  {p.platform && (
                                    <span className="text-5xs">{`(${p.platform})`}</span>
                                  )}
                                </span>
                                {p.date_note && (
                                  <>
                                    <span className="text-5xs"> | </span>
                                    <span className="text-5xs">{`(${p.date_note})`}</span>
                                  </>
                                )}
                              </div>
                            ))}
                          </div>
                          <p
                            className="text-5xs text-darkGray"
                            dangerouslySetInnerHTML={{
                              __html: mf.description,
                            }}></p>
                        </div>
                      ))}
                    </div>
                  )}
                {/* current milestones */}
                {milestones.hasOwnProperty("current") &&
                  !_.isEmpty(milestones.current) && (
                    <div className="w-full flex flex-col space-x-2 mb-2">
                      <div
                        className="px-2 py-1 rounded-sm mb-2"
                        style={{ backgroundColor: secondaryColor }}>
                        <p className="text-5xs font-medium text-lightBlack tracking-tight">
                          Current Milestones
                        </p>
                      </div>
                      {milestones.current.map((mc, i) => (
                        <div key={`t1-current-${i}`} className="mb-1.5 pr-2">
                          <div className="flex justify-between items-center gap-1.5">
                            <div className="flex items-center gap-1">
                              <p className="text-5xs font-medium text-lightBlack">
                                {mc.title}
                              </p>
                              <p
                                className="font-medium uppercase rounded-sm border text-xxs px-0.5"
                                style={{ color: tertiaryColor }}>
                                {mc?.category?.name}
                              </p>
                            </div>
                            <div className="flex text-5xs text-darkGray gap-1">
                              <HiOutlineCalendarDays className="w-2 h-2 text-darkerGray" />
                              {renderStartDate(mc)}
                              {renderEndDate(mc)}
                            </div>
                          </div>
                          <div className="mb-1">
                            {mc.positions.map((p, i) => (
                              <div
                                key={`t1-current-position-${i}`}
                                className="text-5xs text-darkGray font-medium">
                                <span>
                                  <span className="text-5xs">
                                    {p.position.name}
                                  </span>
                                  {p.position.description && (
                                    <>
                                      <span className="text-5xs"> - </span>
                                      <span className="text-5xs">
                                        {p.position.description}
                                      </span>
                                    </>
                                  )}
                                  {p.platform && (
                                    <span className="text-5xs">{`(${p.platform})`}</span>
                                  )}
                                </span>
                                {p.date_note && (
                                  <>
                                    <span className="text-5xs"> | </span>
                                    <span className="text-5xs">{`(${p.date_note})`}</span>
                                  </>
                                )}
                              </div>
                            ))}
                          </div>
                          <p
                            className="text-5xs text-darkGray"
                            dangerouslySetInnerHTML={{
                              __html: mc.description,
                            }}></p>
                        </div>
                      ))}
                    </div>
                  )}
                {/* past milestones */}
                {milestones.hasOwnProperty("past") &&
                  !_.isEmpty(milestones.past) && (
                    <div className="w-full flex flex-col space-x-2 mb-2">
                      <div
                        className="px-2 py-1.5 rounded-sm mb-2"
                        style={{ backgroundColor: secondaryColor }}>
                        <p className="text-5xs font-medium text-lightBlack tracking-tight">
                          Past Milestones
                        </p>
                      </div>
                      {milestones.past.map((mp, i) => (
                        <div key={`t1-past-${i}`} className="mb-1.5 pr-2">
                          <div className="flex justify-between items-center gap-1.5">
                            <div className="flex items-center gap-1">
                              <p className="text-5xs font-medium text-lightBlack">
                                {mp.title}
                              </p>
                              <p
                                className="font-medium uppercase rounded-sm border text-xxs px-0.5"
                                style={{ color: tertiaryColor }}>
                                {mp?.category?.name}
                              </p>
                            </div>
                            <div className="flex text-5xs text-darkGray gap-1">
                              <HiOutlineCalendarDays className="w-2 h-2 text-darkerGray" />
                              {renderStartDate(mp)}
                              {renderEndDate(mp)}
                            </div>
                          </div>
                          <div className="mb-1">
                            {mp.positions.map((p, i) => (
                              <div
                                key={`t1-past-position-${i}`}
                                className="text-5xs text-darkGray font-medium mb-0.5">
                                <span>
                                  <span className="text-5xs">
                                    {p.position.name}
                                  </span>
                                  {p.position.description && (
                                    <>
                                      <span className="text-5xs"> - </span>
                                      <span className="text-5xs">
                                        {p.position.description}
                                      </span>
                                    </>
                                  )}
                                  {p.platform && (
                                    <span className="text-5xs">{`(${p.platform})`}</span>
                                  )}
                                </span>
                                {p.date_note && (
                                  <>
                                    <span className="text-5xs"> | </span>
                                    <span className="text-5xs">{`(${p.date_note})`}</span>
                                  </>
                                )}
                              </div>
                            ))}
                          </div>
                          <p
                            className="text-5xs text-darkGray"
                            dangerouslySetInnerHTML={{
                              __html: mp.description,
                            }}></p>
                        </div>
                      ))}
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
