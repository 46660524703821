import React, { useEffect, useMemo, useState, useRef, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import Popup from "reactjs-popup";
import { Bell, Message } from "assets/svg/sidebar/icons";
import ProfileDefault from "assets/images/profile_default.png";
import mobileLogo from "assets/images/logo-white.png";
import mobileLogoAlt from "assets/images/logo-colored.png";
import { FaChevronLeft } from "react-icons/fa";
import { useNotifications } from "redux/modules/notification/hooks";
import { useMessages } from "redux/modules/messages/hooks";
import { Link, useHistory } from "react-router-dom";
import SearchIconSVG from "assets/svg/search-icon";
import Button from "components/button/button";
import { randomExtension, display_name } from "utils/utils";
import _ from "lodash";
import { listFormat, capitalizeWords } from "utils/utils";
import HeaderProfileList from "./component/header-profile-list";
import HeaderProfileDetails from "./component/header-profile-details";
import PlusCircleIconSVG from "assets/svg/plus-circle-icon";
import SettingsCircleIconSVG from "assets/svg/settings-cirlce";
import { useDialogHook } from "utils/customhooks";
import AddProfileModal from "components/account/add-profile";
import NotificationContainer from "./notification/notification-container";
import InvitationNotificationModal from "./notification/invitation-notification";
import InvitationAcceptedModal from "./notification/invitation-accepted";
import InvitationDeclinedModal from "./notification/invitiation-declined";
import { urlSerializer } from "utils/utils";
import { SET_PROFILES } from "redux/modules/user/types";
import NotificationDotIconSVG from "assets/svg/notification-dot-icon";
import { SubscriptionContext } from "context/subscription";
import HeaderMessagesUpgradePremium from "./component/header-messages-upgrade-premium";
import UpgradePremium from "components/subscription/upgrade-premium";
import styled from "styled-components";
import LoginSingup from "components/auth-mobile/LoginSignup";

const COLORS = {
  white: [
    "bg-landing",
    "site-header-input-alt",
    "text-lightBlack",
    "bg-lightBlack",
    "site-header-input-alt-mobile",
  ],
  default: [
    "bg-primary",
    "site-header-input",
    "text-white",
    "bg-primary border-2 border-white",
    "site-header-input-mobile",
  ],
};

export default function Header({ title, userData, setUserData }) {
  const variant = title === "Home" ? "white" : "default";
  const { http, localstorage } = global.services;
  const history = useHistory();
  const notificationRef = useRef();
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [openUpgradePremium, setOpenUpgradePremium] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const extension = randomExtension();
  const addProfileModal = useDialogHook(AddProfileModal);
  const invitationModal = useDialogHook(InvitationNotificationModal);
  const invitationAccepted = useDialogHook(InvitationAcceptedModal);
  const invitationDeclined = useDialogHook(InvitationDeclinedModal);
  const [showNotification, setShowNotification] = useState(false);
  const {
    isSubscribed,
    //subscription = {},
    resetSubscription,
  } = useContext(SubscriptionContext);
  const {
    hasUnreadNotification,
    updateMarkReadPushNotifications,
    updatePushNotificationUserAccess,
    notifications,
    setNotifications,
  } = useNotifications();

  const { unread } = useMessages();
  const dispatch = useDispatch();

  const { profiles, initialize } = useSelector((state) => state.user); 
  const fetchProfiles = async () => {
    try {
      const url = urlSerializer("public/user-profiles/get-profiles", {
        user_id: userData.user.id,
      });
      const { data: response } = await http.get(url);
      dispatch({
        type: SET_PROFILES,
        payload: response,
      });
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleLogout = async () => {
    try {
      await http.post("auth/logout");
      // localstorage.remove("user_data"); // Remove user data on storage
      localstorage.clear();
      resetSubscription();
      dispatch({ type: "DESTROY_SESSION" });
      history.push(`/login`); // Redirect to current page
      setUserData(null); // Remove user data on redux
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnSearch = async (e) => {
    e.preventDefault();
    if (!_.isEmpty(search)) {
      history.push(`/search?keyword=${search}`);
    }
  };

  const addProfile = () => {
    addProfileModal({ userData }, (result) => {
      console.log("result =>", result);
      if (result?.success) {
        history.push(
          `/${userData.user.username}-${extension}/setup-profile/${result.id}`
        );
      }
    });
  };

  const switchProfile = (profile) => {
    let data = {
      ...userData,
      profile: profile,
    };
    setUserData(data);

    setTimeout(() => {
      history.push(
        `/${userData.user.username}-${extension}/profile/${profile.id}`,
        {
          switchProfile: true,
        }
      );
    }, 700);
  };

  const myProfile = () => {
    history.push(
      `/${userData.user.username}-${extension}/profile/${userData.profile.id}`
    );
  };

  useEffect(() => {
    if (initialize && userData) {
      fetchProfiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialize, userData]);

  const occupation = useMemo(() => {
    if (
      userData?.profile &&
      userData?.profile?.occupations &&
      userData?.profile?.occupations.length > 0
    ) {
      var capitalizedArray = userData.profile.occupations.map(capitalizeWords);
      const list = capitalizedArray.map((o) => {
        if (typeof o === "object") {
          return o.value;
        } else if (typeof o === "string") {
          return o;
        } else {
          return null;
        }
      });

      if (list) {
        return listFormat(list);
      }
    }
    return "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.profile?.occupations]);

  // outside clicks
  const useOutsideAlerter = (ref, setOpenAttribute = () => {}) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpenAttribute(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setOpen);

  const messagesWrapperRef = useRef(null);
  useOutsideAlerter(messagesWrapperRef, setOpenUpgradePremium);
  // end: outside clicks

  const acceptInvite = (notification) => {
    const { from } = notification.user_access_notification;

    invitationModal({ notification }, (callBack) => {
      if (callBack.success) {
        updatePushNotificationUserAccess(callBack.data);
        invitationAccepted({ notification }, (cb) => {
          if (cb) {
            history.push(
              `/${from?.username}-${extension}/profile/${from?.profile?.id}`
            );
          }
        });
      } else if (callBack.success === false) {
        updatePushNotificationUserAccess(callBack.data);
        invitationDeclined({ notification });
      }
    });
  };

  async function getNotifications() {
    try {
      const url = urlSerializer("notifications", {
        limit: 5,
        offset: 0,
        user_id: userData.user.id,
        user_profile_id: userData.profile.id,
      });
      const { data } = await http.get(url);
      setNotifications(data);
    } catch (err) {
      console.log(err);
    }
  }

  const markRead = async () => {
    try {
      await http.put("notifications/read-all", {
        user_id: userData.user.id,
        user_profile_id: userData.profile.id,
      });
      updateMarkReadPushNotifications();
      getNotifications();
    } catch (err) {
      console.log(err);
    }
  };

  const markReadNotification = async (id) => {
    try {
      setNotifications(notifications.filter((notif) => notif.id !== id));
      await http.put(`notifications/${id}/read`);
      getNotifications();
    } catch (err) {
      console.log(err);
    }
  };

  const visitProfile = async (profile) => {
    history.push(
      `/${userData.user.username}-${extension}/profile/${profile.id}`
    );
  };

  const onEditAccount = async () => {
    history.push(`/settings`, {
      activeTab: "account",
    });
  };

  useEffect(() => {
    window.addEventListener(
      "scroll",
      () => {
        if (notificationRef.current) {
          notificationRef.current.close();
        }
      },
      true
    );
  }, []);

  return (
    <header
      style={{ height: "80px" }}
      className={`relative w-full ${COLORS[variant][0]} flex justify-between items-center site-header`}>
      {showSearch && (
        <div className="w-full flex items-center">
          <div className="mr-2">
            <button onClick={() => setShowSearch((prev) => !prev)}>
              <FaChevronLeft
                className={COLORS[variant][2] + " text-2xl mt-1"}
              />
            </button>
          </div>
          <div className={COLORS[variant][4]}>
            <form
              id="header-search-mobile"
              onSubmit={handleOnSearch}
              className="w-full">
              <SearchIconSVG
                className={`${COLORS[variant][2]} absolute top-2.5 left-5`}
              />
              <input
                className="pr-5.5"
                value={search}
                onChange={(e) => setSearch(e?.target?.value)}
                placeholder="Search"
              />
            </form>
          </div>
        </div>
      )}
      <div
        className={`header-left flex items-center gap-y-4 gap-x-10 ${
          showSearch && "hidden"
        }`}>
        <Link to="/">
          <img
            src={variant === "default" ? mobileLogo : mobileLogoAlt}
            alt={"Milestones Page Logo"}
            className="w-24 xs:w-33 1sm:w-40"
          />
        </Link>
        <div className={COLORS[variant][1]}>
          <form id="header-search" onSubmit={handleOnSearch} className="w-full">
            <SearchIconSVG
              className={`${COLORS[variant][2]} absolute top-2.5 left-5`}
            />
            <input
              className="pr-5.5"
              value={search}
              onChange={(e) => setSearch(e?.target?.value)}
              placeholder="Search"
            />
          </form>
        </div>
      </div>
      <div
        className={`header-right flex items-center justify-end gap-4 ${
          showSearch && "hidden"
        }`}>
        {/* account */}
        {_.isEmpty(userData) && (
          <>
            <LoginSingup variant={variant} buttonClass="pb-1"/>
            <div className="block 2md:hidden">
              <button onClick={() => setShowSearch((prev) => !prev)}>
                <SearchIconSVG className={COLORS[variant][2]} />
              </button>
            </div>
            <div className="hidden 2md:block">
              <Button
                buttonName="Sign In"
                buttonTextClass="text-xs text-white font-semibold"
                buttonRadius="6px"
                buttonClass={`sign-in-btn relative px-7.5 ${COLORS[variant][3]}`}
                path="/login"
                link={true}
              />
              <Button
                buttonName="Join Now"
                buttonTextClass={`text-xs ${COLORS[variant][2]} font-semibold`}
                buttonRadius="6px"
                buttonClass={`relative ${COLORS[variant][0]} py-3 px-7.5`}
                path="/sign-up"
                link={true}
              />
            </div>
          </>
        )}
        {/* notification */}
        {!_.isEmpty(userData) && (
          <div className="flex items-center 2md:pr-5">
            {/* search icon */}
            <div className="flex items-center 2md:hidden">
              <button
                className="mr-3 2xs:mr-5 1sm:mr-3.5"
                onClick={() => setShowSearch((prev) => !prev)}>
                <SearchIconSVG className={COLORS[variant][2]} />
              </button>
            </div>
            {/* message icon */}
            <div
              className={`header-message-icon 1sm:relative mr-3 ${
                openUpgradePremium ? "bg-darkPrimary" : ""
              }`}
              ref={messagesWrapperRef}
            >
              {
                isSubscribed
                  ? <Link
                    // className="mr-3 2xs:mr-5 1sm:mr-2 py-2 1sm:px-2.5 rounded-xl hover:bg-darkPrimary"
                    // className="flex rounded-xl hover:bg-darkPrimary"
                    to="/messages">
                    <div className="relative">
                      <Message fill={variant === "default" ? "white" : "#333333"} />
                      {!_.isEmpty(unread) && (
                        <NotificationDotIconSVG
                          className="absolute -top-2.5 right-0"
                          fill="#FC4F4F"
                        />
                      )}
                    </div>
                  </Link>
                  : <Link
                      className="profile-hover rounded-xl"
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenUpgradePremium(true);
                      }}
                    >
                      <div className="relative">
                        <Message fill={variant === "default" ? "white" : "#333333"} />
                        {!_.isEmpty(unread) && (
                          <NotificationDotIconSVG
                            className="absolute -top-2.5 right-0"
                            fill="#FC4F4F"
                          />
                        )}
                      </div>
                    </Link>
              }
              {
                openUpgradePremium && (
                  <div className="upgrade-premium-popup absolute right-0 z-50 bg-thBg rounded-md profile-dropdown w-full 1sm:w-520">
                    <HeaderMessagesUpgradePremium />
                    <UpgradePremium />
                  </div>
                )
              }
            </div>
            {/* notification icon */}
            <StyledPopup
              ref={notificationRef}
              position="bottom right"
              open={showNotification}
              onClose={() => setShowNotification(false)}
              arrow={false}
              // contentStyle={{ marginTop: 20, marginLeft: 80 }}
              trigger={(open) => {
                return (
                  <button
                    className={`mr-2 2xs:mr-4 1sm:mr-3.5 2md:mr-4.5 py-2 1sm:px-2.5 rounded-xl hover:bg-darkPrimary relative  ${
                      open ? "bg-darkPrimary" : ""
                    }`}
                    onClick={() => setShowNotification((prev) => !prev)}>
                    <Bell fill={variant === "default" ? "white" : "#333333"} />
                    {hasUnreadNotification && (
                      <NotificationDotIconSVG
                        className="absolute -top-0.5 right-3"
                        fill="#FC4F4F"
                      />
                    )}
                  </button>
                );
              }}>
              {/* notifications */}
              {/* viewModal is for testing only please remove */}
              <NotificationContainer
                acceptInvite={acceptInvite}
                onMarkRead={markRead}
                onMarkReadNotification={markReadNotification}
              />
            </StyledPopup>
            {/* account */}
            <div
              className={`1sm:relative w-full p-2 rounded-md profile-hover ${
                open ? "bg-darkPrimary" : ""
              }`}
              ref={wrapperRef}>
              <button
                className="flex items-center cursor-pointer"
                onClick={() => setOpen((prev) => !prev)}>
                {/* <Link
                  to={`/${userData.user.username}-${extension}/profile/${userData.profile.id}`}>
                </Link> */}
                <div className="profile-button">
                  <img
                    src={userData.profile?.profile_image_url || ProfileDefault}
                    alt="milestone-profile"
                    className="rounded-md h-full object-cover"
                  />
                </div>
                <div className="pl-3.5 hidden 1sm:flex">
                  <div className="profile-name">
                    <p className={`text-sm font-medium ${COLORS[variant][2]}`}>
                      {/* {userData?.profile?.username ||
                       userData?.user?.username ||
                        userData?.profile?.other_name ||
                        (userData?.user.email
                          ? userData?.user.email?.split("@")[0]
                          : "")} */}
                      {/* <>{display_name(userData) || userData?.user?.username}</> */}
                      <>{userData?.profile?.profile_name || userData?.profile?.other_name || userData?.user?.username}</>
                    </p>
                  </div>
                </div>
              </button>
              {/* dropdown header */}
              {open && (
                <div className="absolute right-0 z-50 bg-thBg rounded-md profile-dropdown top-22 1sm:top-19 w-full 1sm:w-520">
                  <HeaderProfileDetails
                    userData={userData}
                    occupation={occupation}
                    handleLogout={handleLogout}
                    editAccount={onEditAccount}
                    myProfile={myProfile}
                    profiles={profiles}
                  />
                  {/*  */}
                  {profiles.length > 0 && (
                    <HeaderProfileList
                      {...{
                        profiles,
                        userData,
                        switchProfile,
                        visitProfile,
                      }}
                      isSubscribed={isSubscribed}
                    />
                  )}
                  {/*  */}
                  <div className="w-full bg-white px-6 py-3 rounded-b-md">
                    <div className="w-full flex justify-between">
                      <button
                        className="text-sm font-semibold leading-4.4 py-4 flex items-center cursor-pointer text-lightBlack hover:text-darkPrimary"
                        onClick={addProfile}>
                        <PlusCircleIconSVG className="mr-2"/>
                        Add Profiles
                      </button>
                      {profiles.length > 0 && (
                        <Link
                          className="text-sm text-lightBlack hover:text-darkPrimary font-semibold leading-4.4 py-4 flex items-center"
                          to={`/${userData.user.username}-${extension}/manage`}>
                          <SettingsCircleIconSVG className="mr-2"/>
                          Manage Profiles
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/*  */}
          </div>
        )}
      </div>
    </header>
  );
}

const StyledPopup = styled(Popup)`
  &-content{
    margin-top: 20px;
    margin-left:80px;
  }

  @media (max-width: 429px) {
    &-content{
      margin-left:85px;
    }
  }
`;


