import React, { useMemo } from "react";
import { HiOutlineCalendarDays } from "react-icons/hi2";
import ResumeUnderlineIconSVG from "assets/svg/resume-underline-icon";
import ResumeDotDividerIconSVG from "assets/svg/resume-dot-divider-icon";
import { BsExclamationDiamondFill } from "react-icons/bs";
import { renderStartDate, renderEndDate } from "../../resume";
import _ from "lodash";

export default function TemplateTwoFullPageTwo({
  page = 2,
  color,
  milestones = {},
  primaryColor,
  tertiaryColor,
  quarternary,
  show_image_milestone=true
}) {
  const [pageBGActive, pageBGInactive] = useMemo(() => {
    if (color.label === "Blue + Orange") {
      return [tertiaryColor, "#16438A"];
    } else if (color.label === "Green + Yellow") {
      return ["#FFFFFF", "#000000"];
    } else if (color.label === "Pink + Dark Pink") {
      return ["#FFFFFF", tertiaryColor];
    }
    return ["#FFFFFF", "#000000"];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  return (
    <div className="w-full">
      {/* start body */}
      <div
        className="w-full grid bg-white relative grid-row-auto"
        style={{ minHeight: "1246px" }}>
        {/* body */}
        <div className="w-full px-21 pt-10.5">
          <div className="w-full grid gap-y-7">
            {/* Featured Milestones */}
            {milestones.hasOwnProperty("featured") &&
              !_.isEmpty(milestones.featured) && (
                <>
                  <div className="w-full flex flex-row space-x-8.6">
                    <div className="w-18.6">
                      <p className="text-sm font-medium text-lightBlack tracking-tight">
                        Featured Milestones
                      </p>
                      <ResumeUnderlineIconSVG
                        className="mt-3"
                        stroke={primaryColor}
                      />
                    </div>
                    <div style={{ width: "calc(100% - 112px)" }}>
                      <div className="w-full grid gap-y-3">
                        {milestones.featured.map((mf, i) => (
                          <div key={`featured-${i}`} className="w-full flex flex-row space-x-2.6">
                          { show_image_milestone && <div className="h-23 w-23 bg-default rounded relative overflow-hidden">
                            {Boolean(mf.is_adult_content) && (
                              <div className="absolute matured-content-blured-small">
                                <button className="w-full flex justify-center">
                                  <BsExclamationDiamondFill className="text-1xl cursor-pointer" />
                                </button>
                                <p className="text-1xs leading-6 font-bold">
                                  Matured Content
                                </p>
                              </div>  
                            )}
                              {mf.thumbnail && (
                                <img
                                  alt="milestones"
                                  src={mf.thumbnail.url}
                                  className="w-full h-full object-contain object-center"
                                />
                              )}
                            </div>}
                          <div style={{ width: "calc(100% - 111px)" }}>
                              {/* title and date */}
                              <div className="w-full">
                                <div className="w-full flex flex-row items-center justify-between">
                                  {/* title */}
                                  <div className="flex flex-row items-center space-x-1">
                                    <p className="text-sm text-lightBlack font-semibold tracking-tight">
                                      {mf.title}
                                    </p>
                                    <span
                                      className="uppercase px-1.5 py-0.5 rounded-sm text-1xs font-bold text-white"
                                      style={{
                                        backgroundColor: tertiaryColor,
                                      }}>
                                      {mf.category.name}
                                    </span>
                                  </div>
                                  {/* date */}
                                  <div>
                                    <p className="text-4xs text-darkerGray font-medium tracking-resume leading-1 flex flex-row space-x-1 items-center">
                                      <HiOutlineCalendarDays className="w-4 h-4 text-darkerGray" />
                                      {renderStartDate(mf)}
                                      {renderEndDate(mf)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* sub title and episodes */}
                              <div className="w-full mt-1">
                                {mf.positions.map((p, i) => (
                                  <div
                                    key={`featured-position-${i}`}
                                    className="w-full leading-1 flex flex-row space-x-1"
                                    style={{ color: primaryColor }}>
                                    <>
                                      <p className="text-6xs leading-2 font-semibold">
                                        <span>{p.position.name}</span>
                                        {p.position.description && (
                                          <>
                                            <span> - </span>
                                            <span>
                                              {p.position.description}
                                            </span>
                                          </>
                                        )}
                                        {mf.platform && (
                                          <span>{` (${mf.platform})`}</span>
                                        )}
                                      </p>
                                      {mf.date_note && (
                                        <>
                                          <span className="text-6xs leading-2 text-darkerGray opacity-30">
                                            &#8226;
                                          </span>
                                          <p className="text-6xs leading-2 font-semibold">
                                            {mf.date_note}
                                          </p>
                                        </>
                                      )}
                                    </>
                                  </div>
                                ))}
                              </div>
                              {/* description */}
                              <div className="w-full mt-2">
                                <p
                                  className="text-4xs text-darkerGray tracking-resume leading-4"
                                  dangerouslySetInnerHTML={{
                                    __html: mf.description,
                                  }}></p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  {/* Divider */}
                  <div className="w-full">
                    <ResumeDotDividerIconSVG full={true} />
                  </div>
                </>
              )}
            {/* Current Milestones */}
            {milestones.hasOwnProperty("current") &&
              !_.isEmpty(milestones.current) && (
                <>
                  <div className="w-full flex flex-row space-x-8.6">
                    <div className="w-18.6">
                      <p className="text-sm font-medium text-lightBlack tracking-tight">
                        Current Milestones
                      </p>
                      <ResumeUnderlineIconSVG
                        className="mt-3"
                        stroke={primaryColor}
                      />
                    </div>
                    <div style={{ width: "calc(100% - 112px)" }}>
                      <div className="w-full grid gap-y-3">
                        {milestones.current.map((mc, i) => (
                          <div key={`current-${i}`} className="w-full flex flex-row space-x-2.6">
                          { show_image_milestone && <div className="h-23 w-23 bg-default rounded relative overflow-hidden">
                            {Boolean(mc.is_adult_content) && (
                              <div className="absolute matured-content-blured-small">
                                <button className="w-full flex justify-center">
                                  <BsExclamationDiamondFill className="text-1xl cursor-pointer" />
                                </button>
                                <p className="text-1xs leading-6 font-bold">
                                  Matured Content
                                </p>
                              </div>  
                            )}
                              {mc.thumbnail && (
                                <img
                                  alt="milestones"
                                  src={mc.thumbnail.url}
                                  className="w-full h-full object-contain object-center"
                                />
                              )}
                            </div>}
                          <div style={{ width: "calc(100% - 111px)" }}>
                              {/* title and date */}
                              <div className="w-full">
                                <div className="w-full flex flex-row items-center justify-between">
                                  {/* title */}
                                  <div className="flex flex-row items-center space-x-1">
                                    <p className="text-sm text-lightBlack font-semibold tracking-tight">
                                      {mc.title}
                                    </p>
                                    <span
                                      className="uppercase px-1.5 py-0.5 rounded-sm text-1xs font-bold text-white"
                                      style={{
                                        backgroundColor: tertiaryColor,
                                      }}>
                                      {mc.category.name}
                                    </span>
                                  </div>
                                  {/* date */}
                                  <div>
                                    <p className="text-4xs text-darkerGray font-medium tracking-resume leading-1 flex flex-row space-x-1 items-center">
                                      <HiOutlineCalendarDays className="w-4 h-4 text-darkerGray" />
                                      {renderStartDate(mc)}
                                      {renderEndDate(mc)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* sub title and episodes */}
                              <div className="w-full mt-1">
                                {mc.positions.map((p, i) => (
                                  <div
                                    key={`current-position-${i}`}
                                    className="w-full leading-1 flex flex-row space-x-1"
                                    style={{ color: primaryColor }}>
                                    <>
                                      <p className="text-6xs leading-2 font-semibold">
                                        <span>{p.position.name}</span>
                                        {p.position.description && (
                                          <>
                                            <span> - </span>
                                            <span>
                                              {p.position.description}
                                            </span>
                                          </>
                                        )}
                                        {mc.platform && (
                                          <span>{` (${mc.platform})`}</span>
                                        )}
                                      </p>
                                      {mc.date_note && (
                                        <>
                                          <span className="text-6xs leading-2 text-darkerGray opacity-30">
                                            &#8226;
                                          </span>
                                          <p className="text-6xs leading-2 font-semibold">
                                            {mc.date_note}
                                          </p>
                                        </>
                                      )}
                                    </>
                                  </div>
                                ))}
                              </div>
                              {/* description */}
                              <div className="w-full mt-2">
                                <p
                                  className="text-4xs text-darkerGray tracking-resume leading-4"
                                  dangerouslySetInnerHTML={{
                                    __html: mc.description,
                                  }}></p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  {/* Divider */}
                  <div className="w-full">
                    <ResumeDotDividerIconSVG />
                  </div>
                </>
              )}
            {/* Past Milestones */}
            {milestones.hasOwnProperty("past") &&
              !_.isEmpty(milestones.past) && (
                <>
                  <div className="w-full flex flex-row space-x-8.6">
                    <div className="w-18.6">
                      <p className="text-sm font-medium text-lightBlack tracking-tight">
                        Past Milestones 
                      </p>
                      <ResumeUnderlineIconSVG className="mt-3" />
                    </div>
                    <div style={{ width: "calc(100% - 112px)" }}>
                      <div className="w-full grid gap-y-3">
                        {milestones.past.map((mp, i) => (
                          <div key={`past-${i}`} className="w-full flex flex-row space-x-2.6">
                            { show_image_milestone && <div className="h-23 w-23 bg-default rounded relative overflow-hidden">
                              {Boolean(mp.is_adult_content) && (
                                <div className="absolute matured-content-blured-small">
                                  <button className="w-full flex justify-center">
                                    <BsExclamationDiamondFill className="text-1xl cursor-pointer" />
                                  </button>
                                  <p className="text-1xs leading-6 font-bold">
                                    Matured Content
                                  </p>
                                </div>  
                              )}
                                {mp.thumbnail && (
                                  <img
                                    alt="milestones"
                                    src={mp.thumbnail.url}
                                    className="w-full h-full object-contain object-center"
                                  />
                                )}
                              </div>}
                            <div style={{ width: "calc(100% - 111px)" }}>
                              {/* title and date */}
                              <div className="w-full">
                                <div className="w-full flex flex-row items-center justify-between">
                                  {/* title */}
                                  <div className="flex flex-row items-center space-x-1">
                                    <p className="text-sm text-lightBlack font-semibold tracking-tight">
                                      {mp.title}
                                    </p>
                                    <span
                                      className="uppercase px-1.5 py-0.5 rounded-sm text-1xs font-bold text-white"
                                      style={{
                                        backgroundColor: tertiaryColor,
                                      }}>
                                      {mp.category.name}
                                    </span>
                                  </div>
                                  {/* date */}
                                  <div>
                                    <p className="text-4xs text-darkerGray font-medium tracking-resume leading-1 flex flex-row space-x-1 items-center">
                                      <HiOutlineCalendarDays className="w-4 h-4 text-darkerGray" />
                                      {renderStartDate(mp)}
                                      {renderEndDate(mp)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* sub title and episodes */}
                              <div className="w-full mt-1">
                                {mp.positions.map((p, i) => (
                                  <div
                                    key={`past-position-${i}`}
                                    className="w-full leading-1 flex flex-row space-x-1"
                                    style={{ color: primaryColor }}>
                                    <>
                                      <p className="text-6xs leading-2 font-semibold">
                                        <span>{p.position.name}</span>
                                        {p.position.description && (
                                          <>
                                            <span> - </span>
                                            <span>
                                              {p.position.description}
                                            </span>
                                          </>
                                        )}
                                        {mp.platform && (
                                          <span>{` (${mp.platform})`}</span>
                                        )}
                                      </p>
                                      {mp.date_note && (
                                        <>
                                          <span className="text-6xs leading-2 text-darkerGray opacity-30">
                                            &#8226;
                                          </span>
                                          <p className="text-6xs leading-2 font-semibold">
                                            {mp.date_note}
                                          </p>
                                        </>
                                      )}
                                    </>
                                  </div>
                                ))}
                              </div>
                              {/* description */}
                              <div className="w-full mt-2">
                                <p
                                  className="text-4xs text-darkerGray tracking-resume leading-4"
                                  dangerouslySetInnerHTML={{
                                    __html: mp.description,
                                  }}></p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  {/* Divider */}
                  <div className="w-full">
                    <ResumeDotDividerIconSVG full={true} />
                  </div>
                </>
              )}
            {/*  */}
          </div>
        </div>
        {/* footer */}
        <div className="w-full pt-10 flex items-end">
          <div
            className="w-full h-7.5 px-21 relative"
            style={{ backgroundColor: quarternary }}>
            <div className="w-full h-full flex flex-row justify-between items-center">
              <p className="text-white font-semibold text-6xs">Page {page}</p>
              <div className="flex flex-start gap-1.5">
                <button
                  className="w-2.5 h-2.5 rounded-full"
                  style={{ backgroundColor: pageBGInactive }}
                />
                <button
                  className="w-2.5 h-2.5 rounded-full"
                  style={{ backgroundColor: pageBGActive }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
