const ResumeDotDividerIconSVG = ({
  className = "",
  fill = "#D9D9D9",
  full = false,
}) => {
  let width = "472";
  let viewBox = "0 0 472 3";
  if (full) {
    width = "100%";
    viewBox = "0 0 693 3";
  }

  return (
    <svg
      className={className}
      width={width}
      height="3"
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.4">
        <ellipse cx="1.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="8.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="15.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="22.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="29.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="36.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="43.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="50.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="57.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="64.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="71.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="78.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="85.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="92.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="99.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="106.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="113.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="120.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="127.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="134.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="141.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="148.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="155.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="162.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="169.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="176.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="183.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="190.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="197.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="204.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="211.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="218.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="225.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="232.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="239.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="246.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="253.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="260.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="267.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="274.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="281.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="288.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="295.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="302.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="309.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="316.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="323.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="330.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="337.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="344.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="351.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="358.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="365.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="372.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="379.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="386.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="393.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="400.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="407.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="414.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="421.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="428.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="435.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="442.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="449.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="456.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="463.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        <ellipse cx="470.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
        {full && (
          <>
            <ellipse cx="470.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="477.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="484.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="491.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="498.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="505.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="512.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="519.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="526.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="533.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="540.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="547.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="554.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="561.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="568.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="575.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="582.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="589.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="596.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="603.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="610.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="617.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="624.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="631.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="638.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="645.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="652.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="659.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="666.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="673.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="680.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="687.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="694.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="701.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="708.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="715.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="722.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="729.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="736.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="743.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="750.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="757.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="764.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="771.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="778.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="785.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="792.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="799.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="806.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="813.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="820.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="827.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="834.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
            <ellipse cx="841.5" cy="1.5" rx="1.5" ry="1.5" fill={fill} />
          </>
        )}
      </g>
    </svg>
  );
};

export default ResumeDotDividerIconSVG;
