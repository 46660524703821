import React from "react";
import { FiCamera } from "react-icons/fi";
import ImageUpload from "components/image-upload/image-upload";
import PopInfo from "components/info-popup/pop-info";

export default function MetadataForm({
  previewProfile,
  cover,
  onUploadImage = () => {},
}) {
  return (
    <div className="pt-12.5 pb-10">
      <div className="w-full 3md:flex -mb-6">
        <div className="w-full mb-5 3md:w-6/20 3md:mb-0">
          <div className="w-full flex flex-row gap-x-2 items-center">
            <p className="text-lg text-lightBlack font-semibold">Images</p>
            <PopInfo containerClass="relative" type="images" />
          </div>
        </div>
        {/* forms */}
        <div className="w-full 3md:w-14/20">
          <ImageUpload
            label="Profile Picture"
            image={previewProfile}
            accept="image/*"
            icon={<FiCamera className="text-white text-lg" />}
            id="profile-photo"
            onChange={(e) => onUploadImage(e, "profile")}
          />
          <ImageUpload
            label="Cover Photo"
            accept="image/*"
            image={cover}
            icon={<FiCamera className="text-white text-lg mr-2" />}
            className="absolute px-4 text-xs text-white font-semibold right-2 top-2 h-9 bg-black bg-opacity-50 rounded-md flex items-center justify-center"
            innerLabel={cover ? "Edit Photo" : "Upload Photo"}
            wrapperClass="relative w-full 4sm:w-538 h-38 bg-thBg rounded-md"
            id="cover-photo"
            onChange={(e) => onUploadImage(e, "cover")}
          />
        </div>
      </div>
    </div>
  );
}
