const ChecksDoubleIconSVG = ({ className = "" }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0078 7.00779L5.13279 13.8828C5.01333 13.9983 4.85365 14.0629 4.68748 14.0629C4.5213 14.0629 4.36163 13.9983 4.24216 13.8828L0.804664 10.4453C0.704999 10.3239 0.654067 10.1697 0.661773 10.0128C0.669479 9.85587 0.735274 9.70744 0.846358 9.59636C0.957442 9.48527 1.10587 9.41948 1.26278 9.41177C1.41969 9.40407 1.57385 9.455 1.69529 9.55466L4.68748 12.5547L11.1172 6.11716C11.2386 6.0175 11.3928 5.96657 11.5497 5.97427C11.7066 5.98198 11.855 6.04777 11.9661 6.15886C12.0772 6.26994 12.143 6.41837 12.1507 6.57528C12.1584 6.73219 12.1075 6.88635 12.0078 7.00779ZM19.1953 6.11716C19.0768 5.9998 18.9168 5.93396 18.75 5.93396C18.5832 5.93396 18.4232 5.9998 18.3047 6.11716L11.875 12.5547L10.4922 11.1719C10.3733 11.0641 10.2175 11.0062 10.0571 11.0102C9.89674 11.0141 9.74401 11.0796 9.63056 11.1931C9.51712 11.3065 9.45164 11.4592 9.4477 11.6196C9.44375 11.78 9.50163 11.9358 9.60935 12.0547L11.4297 13.8828C11.5491 13.9983 11.7088 14.0629 11.875 14.0629C12.0412 14.0629 12.2008 13.9983 12.3203 13.8828L19.1953 7.00779C19.3127 6.88929 19.3785 6.72926 19.3785 6.56248C19.3785 6.3957 19.3127 6.23566 19.1953 6.11716Z"
      fill="currentColor"
    />
  </svg>
);
export default ChecksDoubleIconSVG;
