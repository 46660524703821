const BioIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.75 9.69296C23.556 10.607 24 11.781 24 13V18C24 20.757 21.757 23 19 23H5C2.243 23 0 20.757 0 18V13C0 10.243 2.243 7.99996 5 7.99996H9C9.553 7.99996 10 8.44796 10 8.99996C10 9.55196 9.553 9.99996 9 9.99996H5C3.346 9.99996 2 11.346 2 13V18C2 19.654 3.346 21 5 21H19C20.654 21 22 19.654 22 18V13C22 12.269 21.733 11.564 21.25 11.016C20.885 10.602 20.924 9.96996 21.339 9.60396C21.752 9.23996 22.384 9.27896 22.75 9.69296ZM5 15.5C5 16.328 5.672 17 6.5 17C7.328 17 8 16.328 8 15.5C8 14.672 7.328 14 6.5 14C5.672 14 5 14.672 5 15.5ZM11.5 17C12.328 17 13 16.328 13 15.5C13 14.672 12.328 14 11.5 14C10.672 14 10 14.672 10 15.5C10 16.328 10.672 17 11.5 17ZM12 11V9.41396C12 8.34596 12.416 7.34096 13.172 6.58596L18.879 0.878965C20.049 -0.291035 21.951 -0.291035 23.121 0.878965C23.687 1.44496 24 2.19896 24 2.99996C24 3.80096 23.687 4.55497 23.121 5.12196L17.414 10.829C16.659 11.584 15.654 12.001 14.586 12.001H13C12.447 12.001 12 11.553 12 11.001V11ZM14 9.99996H14.586C15.12 9.99996 15.622 9.79196 16 9.41396L21.707 3.70696C21.896 3.51796 22 3.26696 22 2.99996C22 2.73296 21.896 2.48196 21.707 2.29296C21.316 1.90196 20.684 1.90296 20.293 2.29296L14.586 7.99996C14.214 8.37296 14 8.88796 14 9.41396V9.99996Z"
        fill="#333333"
      />
    </svg>
  );
};

export default BioIconSVG;
