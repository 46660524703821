import React, { useEffect, useMemo, useState } from "react";
import BioIconSVG from "assets/svg/bio-icon";
import Button from "components/button/button";
import CalendarDayIconSVG from "assets/svg/calendar-day-icon";
import MapMarkerIconSVG from "assets/svg/map-marker-icon";
import TagIconSVG from "assets/svg/tag-icon";
import ChildHeadIconSVG from "assets/svg/child-head-icon";
import IDBadgeIconSVG from "assets/svg/id-badge-icon";
import HairIconSVG from "assets/svg/hair-icon";
import HeightIconSVG from "assets/svg/height-icon";
import { HiOutlinePencil } from "react-icons/hi";
import { FiCheck } from "react-icons/fi";
import TextArea from "components/textarea/textarea";
import toast from "react-hot-toast";
import LoadingIndicator from "assets/svg/loading-indicator";
import Input from "components/input/input";
import InputCm from "components/input/input-cm";
import { CHILD, CIVIL_STATUS, removeUndefinedProperties } from "utils/utils";
import _ from "lodash";
import Select from "components/select/select";
import DatePicker from "components/datepicker/datepicker";
import moment from "moment";
import PopInfo from "components/info-popup/pop-info";
import { personalDateFormat } from "utils/utils";

const buttonProps = {
  edit: {
    btnName: "Edit",
    icon: <HiOutlinePencil />,
    btnClass: "relative py-3.6 px-5.5 bg-modalGray hover:bg-gray",
    btnTextClass:
      "text-sm text-lightBlack font-semibold flex items-center space-x-1.6",
  },
  save: {
    btnName: "Save Changes",
    icon: <FiCheck className="text-lg" />,
    btnClass: "relative py-3.6 px-5.5 bg-primary hover:bg-darkPrimary",
    btnTextClass:
      "text-sm text-white font-semibold flex items-center space-x-1.6",
  },
};

const ICONS = {
  birthdate: <CalendarDayIconSVG className="m-auto" />,
  birth_location: <MapMarkerIconSVG className="m-auto" />,
  civil_status: <TagIconSVG className="m-auto" />,
  children: <ChildHeadIconSVG className="m-auto" />,
  height: <HeightIconSVG className="m-auto" />,
  hair_color: <HairIconSVG className="m-auto" />,
  other_name: <IDBadgeIconSVG className="m-auto" />,
};

export default function PersonalInformation(props) {
  const { profile, userData, setUserData } = props;
  const { http, localstorage } = global.services;
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    bio: profile?.bio,
    birthdate: profile?.birthdate ? new Date(profile?.birthdate) : null,
    birth_location: profile?.birth_location,
    civil_status: profile?.civil_status,
    children: profile?.children,
    other_name: profile?.other_name,
  });
  const [personal, setPersonal] = useState([]);

  useEffect(() => {
    if (!_.isEmpty(profile)) {
      setState({
        bio: profile?.bio,
        birthdate: profile?.birthdate ? new Date(profile?.birthdate) : null,
        birth_location: profile?.birth_location,
        civil_status: profile?.civil_status,
        children: profile?.children,
        other_name: profile?.other_name,
      });
      setPersonal([
        [
          {
            label: "Date of Birth",
            value: profile?.birthdate,
            icon: ICONS.birthdate,
            form: "datepicker",
            formProps: {
              name: "birthdate",
            },
          },
          {
            label: "Birth Location",
            value: profile?.birth_location,
            icon: ICONS.birth_location,
            form: "input",
            formProps: {
              name: "birth_location",
            },
          },
        ],
        [
          {
            label: "Civil Status",
            value: profile?.civil_status,
            icon: ICONS.civil_status,
            form: "select",
            formProps: {
              name: "civil_status",
            },
          },
          {
            label: "Children",
            value: profile?.children || null,
            icon: ICONS.children,
            form: "select",
            formProps: {
              name: "children",
            },
          },
        ],
        [
          {
            label: "Height in cm",
            value: profile?.height,
            icon: ICONS.height,
            form: "input_cm",
            formProps: {
              name: "height",
            },
          },
          {
            label: "Hair Colour",
            value: profile?.hair_color,
            icon: ICONS.hair_color,
            form: "input",
            formProps: {
              name: "hair_color",
            },
          },
        ],
        // [
        //   {
        //     label: "Other Name",
        //     value: profile?.other_name,
        //     icon: ICONS.other_name,
        //     form: "input",
        //     formProps: {
        //       name: "other_name",
        //     },
        //   },
        // ],
      ]);
    }
  }, [profile]);
  //=============================functions ======================================
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onClickSave = async () => {
    try {
      setLoading(true);
      const payload = removeUndefinedProperties(state);
      if (!_.isEmpty(payload)) {
        if (payload.birthdate) {
          //add 8 hours so that if save it's still the exact date
          payload.birthdate = moment(payload.birthdate).add(8, "hours");
        }
        const { data } = await http.put(
          `public/profiles/update-personal-information/${profile?.id}`,
          payload
        );
        if (data) {
          toast.success("Personal Information Successfully Updated");
          const resultValue = data.data;
          setPersonal([
            [
              {
                label: "Date of Birth",
                value: resultValue.birthdate,
                icon: ICONS.birthdate,
                form: "datepicker",
                formProps: {
                  name: "birthdate",
                },
              },
              {
                label: "Birth Location",
                value: resultValue.birth_location,
                icon: ICONS.birth_location,
                form: "input",
                formProps: {
                  name: "birth_location",
                },
              },
            ],
            [
              {
                label: "Civil Status",
                value: resultValue.civil_status,
                icon: ICONS.civil_status,
                form: "select",
                formProps: {
                  name: "civil_status",
                },
              },
              {
                label: "Children",
                value: resultValue.children,
                icon: ICONS.children,
                form: "select",
                formProps: {
                  name: "children",
                },
              },
            ],
            [
              {
                label: "Height in cm",
                value: resultValue.height,
                icon: ICONS.height,
                form: "input_cm",
                formProps: {
                  name: "height",
                },
              },
              {
                label: "Hair Colour",
                value: resultValue.hair_color,
                icon: ICONS.hair_color,
                form: "input",
                formProps: {
                  name: "hair_color",
                },
              },
            ],
            // [
            //   {
            //     label: "Other Name",
            //     value: resultValue.other_name,
            //     icon: ICONS.other_name,
            //     form: "input",
            //     formProps: {
            //       name: "other_name",
            //     },
            //   },
            // ],
          ]);
          //set userdata and update localstorange
          console.log("resultValue", resultValue);
          const updatedData = { ...userData, profile: resultValue };
          setUserData(updatedData);
          localstorage.set("user_data", JSON.stringify(updatedData));
          typeof props.onDoneUpdate === 'function' && props.onDoneUpdate(updatedData);
          //update state to false
          setLoading(false);
          setEdit(false);
        }
      }
    } catch (error) {
      toast.error(
        error?.data?.message || "It appears that something went wrong"
      );
      setLoading(false);
      setEdit(false);
    } finally {
      setLoading(false);
      setEdit(false);
    }
  };

  const displayValue = (name, value) => {
    if (name === "children") {
      if (value) {
        return `${value} Children`;
      } else {
        return "--";
      }
    } else if (name === "birthdate") {
      if (value) {
        return personalDateFormat(value, profile?.dateFormat);
      } else {
        return "--";
      }
    } else {
      return value || "--";
    }
  };

  const customClassNames = useMemo(() => {
    if (props.isYourProfile) {
      return "w-full flex flex-start items-start space-x-6";
    } else {
      if (profile?.bio) {
        return "w-full flex flex-start items-start space-x-6";
      } else {
        return "hidden";
      }
    }
  }, [props.isYourProfile, profile?.bio]);

  return (
    <div className="w-full bg-white rounded-md px-5 2md:pl-29 2md:pr-24.5 py-10 md:pb-14 md:pt-8">
      <div className="w-full flex justify-between flex-wrap items-center mb-8 ">
        <div className="flex flex-row gap-x-2 items-center">
          <h1 className="text-base 2sm:text-2xl font-semibold text-lightBlack">
            Personal Information
          </h1>
          <PopInfo containerClass="relative" type="personal_information" />
        </div>
        {props.isYourProfile && (
          <Button
            buttonName={
              edit ? buttonProps.save.btnName : buttonProps.edit.btnName
            }
            buttonTextClass={
              edit
                ? buttonProps.save.btnTextClass
                : buttonProps.edit.btnTextClass
            }
            iconPosition="left"
            icon={edit ? buttonProps.save.icon : buttonProps.edit.icon}
            buttonRadius="6px"
            buttonClass={
              edit ? buttonProps.save.btnClass : buttonProps.edit.btnClass
            }
            loadingIcon={
              loading ? (
                <LoadingIndicator className="text-white w-4 h-4" />
              ) : null
            }
            disabled={loading}
            onClick={() => {
              if (edit) {
                onClickSave();
              } else {
                setEdit((prev) => !prev);
              }
            }}
          />
        )}
      </div>
      <div className="w-full grid gap-y-12 max-w-screen-2smd">
        {/* ==================== BIO =================================== */}
        {(profile?.bio || edit ) && (
          <>
            <div className="w-full">
              <div className={customClassNames}>
                <div className="w-15 h-15 rounded-full bg-bGray grid">
                  <BioIconSVG className="m-auto" />
                </div>
                <div style={{ width: "calc(100% - 60px)" }}>
                  <h3 className="text-base 2sm:text-xl text-lightBlack font-semibold">Bio</h3>
                  {edit ? (
                    <TextArea
                      formContainer="mt-3"
                      defaultValue={profile?.bio || ""}
                      name="bio"
                      inputClass="p-4.5 text-base leading-6 text-lightBlack"
                      rows={4}
                      type="text"
                      onChange={handleInputChange}
                    />
                  ) : (
                    <p className="text-base leading-6.7 tracking-tightest text-darkerGray mt-1.5 whitespace-pre-line">
                      {profile?.bio || "--"}
                    </p>
                  )}
                </div>
              </div>
            </div>
            {/* ===================== Divider ============================================ */}
            <hr className="text-gray" />
          </>
        )}
        {/* ==================== OTHER INFORMATION =================================== */}
        <div className="w-full">
          <div className="w-full grid gap-y-12">
            {/* {(personal || []).map((parent, index) => { */}
              {/* return ( */}
                <div
                  // className="w-full space-y-4.5 4sm:flex 4sm:flex-start 4sm:space-x-4.5 4sm:space-y-0"
                  className="w-full grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-12"
                  >
                  {(personal?.flat() || []).map(
                    (obj, subIndex) =>
                      (
                        ((() => {
                          if(['string' , 'number'].includes(typeof obj?.value)) {
                            return true;
                          }
      
                          if(Array.isArray(obj?.value)) {
                            if((obj?.value.length || 0) > 0) {
                              return true;
                            }
                          }
                         
                          return false;
                        })() || edit)
                      ) && (
                        <div
                          className="w-full flex flex-start items-start space-x-6" 
                          key={subIndex}>
                          <div className="w-15 h-15 rounded-full bg-bGray grid">
                            {obj.icon}
                          </div>
                          <div style={{ width: "calc(100% - 60px)" }}>
                            <h3 className="text-base 2sm:text-xl text-lightBlack font-semibold">
                              {obj.label}
                            </h3>
                            {edit ? (
                              <div className="w-full mt-3">
                                <FormBuilder
                                  type={obj.form}
                                  name={obj.formProps.name}
                                  placeholder={obj.label}
                                  onChange={handleInputChange}
                                  defaultValue={obj.value}
                                  state={state}
                                />
                              </div>
                            ) : (
                              <p className="text-base leading-6.7 tracking-tightest text-darkerGray mt-1.5">
                                {displayValue(obj.formProps.name, obj.value)}
                              </p>
                            )}
                          </div>
                        </div>
                      )
                  )}
                </div>
              {/* ); */}
            {/* })} */}
          </div>
        </div>
      </div>
    </div>
  );
}

function FormBuilder(props) {
  const { type, name, placeholder, onChange, defaultValue, state } = props;
  let options = CIVIL_STATUS;
  let selectDefaultValue = { label: defaultValue, value: defaultValue };


  const checkMaxMin = (e) => {
    let inputValue = e.target.value;
      
    if (inputValue !== undefined && inputValue.toString().length >= 3) {
      e.target.value = e.target.value.substring(0,3);
    }
    else if (inputValue < 0) {
      e.target.value = 0
    }
    onChange(e)
  };
  
  if (name === "children") {
    options = CHILD;
    let valueObject = _.find(CHILD, { value: defaultValue });
    selectDefaultValue = valueObject;
  }
  if (type === "input") {
    return (
      <Input
        inputContainerClass="w-full"
        defaultValue={defaultValue}
        name={name}
        placeholder={placeholder}
        outsideLabelClass="text-sm font-bold text-darkerGray"
        noLabelPaddingY="1rem"
        type="text"
        onChange={onChange}
      />
    );
  } else if (type === "input_cm") {
    return (
      <InputCm
        inputContainerClass="w-full"
        defaultValue={defaultValue}
        name={name}
        disableUpDownArrow={true}
        placeholder={placeholder}
        outsideLabelClass="text-sm font-bold text-darkerGray"
        noLabelPaddingY="1rem"
        type="number"
        onChange={checkMaxMin}
      />
    );
  } else if (type === "select") {
    return (
      <Select
        formContainer="w-full"
        options={options}
        placeholder={placeholder}
        name={name}
        defaultValue={selectDefaultValue || null}
        height={58}
        onChange={(e) => onChange({ target: { name: name, value: e.value } })}
      />
    );
  } else if (type === "datepicker") {
    return (
      <DatePicker
        formContainer="w-full"
        icon="noLabel"
        selected={state[name] ? new Date(state[name]) : null}
        onChange={(date) =>
          onChange({ target: { name: name, value: new Date(date) } })
        }
        placeholderText={placeholder}
      />
    );
  }
  return <></>;
}
