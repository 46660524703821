import CloseIconSVG from "assets/svg/close-icon";
import LogoIconWhiteSVG from "assets/svg/logo-white-icon";
import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useHistory } from "react-router-dom";

const EmptyLayout = ({
  children,
  title,
  backButton = "Close",
  description = "Build Your Resume",
  onClickMobileSideBar = () => {},
  isOpen = false,
}) => {
  const history = useHistory();
  return (
    <HelmetProvider>
      {title && (
        <Helmet>
          <title>{title} - Milestones Page</title>
        </Helmet>
      )}
      <div className="w-screen h-screen bg-white lg:static lg:overflow-y-auto lg:overflow-x-hidden lg:flex-grow flex flex-col flex-1 custom-scroll">
        <header
          className="bg-primary text-white py-5 px-6.4"
          style={{ height: "80px" }}>
          <div className="w-full h-full flex justify-between items-center">
            <div className="flex flex-row space-x-4 items-center">
              <button
                className="block 3md:hidden"
                onClick={onClickMobileSideBar}>
                {isOpen ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="icon icon-tabler icons-tabler-outline icon-tabler-square-x">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M3 5a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-14z" />
                    <path d="M9 9l6 6m0 -6l-6 6" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="icon icon-tabler icons-tabler-outline icon-tabler-menu-2">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M4 6l16 0" />
                    <path d="M4 12l16 0" />
                    <path d="M4 18l16 0" />
                  </svg>
                )}
              </button>
              <LogoIconWhiteSVG />
              <h1 className="resume-heading-title">{description}</h1>
            </div>
            <button
              className="text-base font-semibold flex flex-row items-center space-x-2.5"
              onClick={() => history.goBack()}>
              <CloseIconSVG fill="#fff" />
              <span className="hidden 2sm:inline">{backButton}</span>
            </button>
          </div>
        </header>
        <main
          className="w-full"
          style={{ height: "calc(100vh - 80px)", overflow: "hidden" }}>
          {children}
        </main>
      </div>
    </HelmetProvider>
  );
};

export default EmptyLayout;
