import React, { useState } from "react";
import userActions from "redux/modules/user/actions";
import { connect } from "react-redux";
import Layout from "layouts/layout";
import { useTabs } from "react-headless-tabs";
import Button from "components/button/button";
import Input from "components/input/input";
import DatePicker from "components/datepicker/datepicker";
import Select from "components/select/select";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import toast from "react-hot-toast";
import LoadingIndicator from "assets/svg/loading-indicator";
import { randomExtension } from "utils/utils";
import CustomCheckbox from "components/checkbox/custom-checkbox";
import { Link } from "react-router-dom";
import ArrowLeftIcon from "assets/svg/arrow-left-icon";
import VerifyTeam from './team-account';
import { gender } from "utils/constant"; 
import InputAddress from "components/input/input-addres";
import { NZ_DRIVERS_LICENCE, NZ_PASSPORT, AU_DRIVERS_LICENCE, AU_MEDICARE, AU_PASSPORT, AU_PERSONAL_INFO,
  DRIVER_LICENSE, PASSPORT_ID, MEDICARE_ID, AU, NZ, UK_NAD,US_NAD, US, UK, IN_DEP, IN, EPIC, PAN, SOCIAL_SECURITY_US
 } from "configs/id3";

const options = [
  { value: DRIVER_LICENSE, label: DRIVER_LICENSE },
  { value: PASSPORT_ID, label: PASSPORT_ID },
];
const au_options = [
  { value: DRIVER_LICENSE, label: DRIVER_LICENSE },
  { value: PASSPORT_ID, label: PASSPORT_ID },
  { value: MEDICARE_ID, label: MEDICARE_ID },
];
const india_options = [
  { value: DRIVER_LICENSE, label: DRIVER_LICENSE },
  { value: EPIC, label: EPIC },
  { value: PAN, label: PAN },
];

const us_options = [
  { value: SOCIAL_SECURITY_US, label: SOCIAL_SECURITY_US }
];

const withIDs = [AU, NZ, IN, US];

const VerifyAccount = ({ userData, setUserData }) => {
  const history = useHistory();
  const { http, localstorage } = global.services;
  const [selectedTab, setSelectedTab] = useTabs(["personal", "details"]);
  const [selectedDate, setSelectedDate] = useState(
    userData?.profile?.birthdate ? new Date(userData?.profile?.birthdate) : null
  );
  const [dateExpiry, setDateExpiry] = useState(new Date());
  const [loading, setLoading] = useState(false);
 

  const [state, setState] = useState({
    firstname: userData?.user?.first_name,
    middlename: "",
    lastname: userData?.user?.last_name,
    address: userData?.profile?.address,
    email: userData?.user?.email,
    condition: true,
    loading: false,
    validationErrors: null,
    profile_type: userData?.profile?.profile_type?.type,
    country: userData?.profile?.country,
    street: userData?.profile?.street,
    city: userData?.profile?.city,
    region: userData?.profile?.region,
    zipPostcode: userData?.profile?.zipPostcode,
    building: userData?.profile?.building,
    gender: userData?.profile?.gender,
    date_expiry:null,
    identification:{ value: DRIVER_LICENSE, label: DRIVER_LICENSE }
  });
  // console.log("===============Verify account=======")
  // console.log(userData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
      validationErrors: { ...state.validationErrors, [name]: null },
    }));
  };

  const handleChangeDate = (name, date) => {
    console.log(date)
    setState((prev) => ({
      ...prev,
      validationErrors: { ...state.validationErrors, [name]: null },
    }));
    if(name === 'birthdate'){
      setSelectedDate(date);
    }else{
      setDateExpiry(date);
    }
  };

  const handleUpdateCheckbox = (e) => {
    const isChecked = e.target.checked;
    setState({ ...state, condition: isChecked });
  };

  const validatePersonalDetais = () => {
    let errors = {};
    if (_.isEmpty(state.firstname)) {
      errors = { ...errors, firstname: "First Name is required" };
    }
    if (_.isEmpty(state.lastname)) {
      errors = { ...errors, lastname: "Last Name is required" };
    }
    if (_.isEmpty(state.address)) {
      errors = { ...errors, address: "Address is required" };
    }
    if (_.isEmpty(state.country)) {
      errors = { ...errors, country: "Country is required" };
    }
    if (_.isEmpty(state.street)) {
      errors = { ...errors, street: "Street is required" };
    }
    if (_.isEmpty(state.city)) {
      errors = { ...errors, city: "City is required" };
    }
    if (_.isEmpty(state.region)) {
      errors = { ...errors, region: "Region is required" };
    }
    if (_.isEmpty(state.zipPostcode)) {
      errors = { ...errors, zipPostcode: "Post code is required" };
    }
    if (_.isEmpty(state.building)) {
      errors = { ...errors, building: "Building is required" };
    } 
    if (_.isEmpty(state.gender)) {
      errors = { ...errors, gender: "Gender is required" };
    }

    if (_.isEmpty(state.email)) {
      errors = { ...errors, email: "Email is required" };
    }
    if (!selectedDate) {
      errors = { ...errors, birthdate: "Birthdate is required" };
    }

    if (_.isEmpty(errors)) {
      return true;
    } else {
      setState((prev) => ({
        ...prev,
        validationErrors: { ...state.validationErrors, ...errors },
      }));
      return false;
    }
  };

  const validateIdDetais = () => {
    if(state.country === US || state.country === UK){
      return true;
    }
    let errors = {};

    if (_.isEmpty(state.identification)) {
      errors = { ...errors, identification: "Select ID option" };
    }
    if (_.isEmpty(state.license)) {
      errors = { ...errors, license: "Document Number is required" };
    }
    // if (_.isEmpty(state.cardnumber) && country) {
    //   errors = { ...errors, cardnumber: "Card Number is required" };
    // }

    if (_.isEmpty(errors)) {
      return true;
    } else {
      setState((prev) => ({
        ...prev,
        validationErrors: { ...state.validationErrors, ...errors },
      }));
      return false;
    }
  };

  // const onValidateBeforeNext = () => {
  //   const passOrFail = validatePersonalDetais();
  //   if (passOrFail) {
  //     setSelectedTab("details");
  //   }
  // };

  const onValidateBeforeSubmit = () => {
    const passOrFail = validateIdDetais();
    const detailsPassFail = validatePersonalDetais()
    if (passOrFail && detailsPassFail) {
      onHandleSubmit();
    }
  };

  const formatDate = (date_input)=>{
    const date = new Date(date_input); 
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const day = String(date.getDate()).padStart(2, '0'); 
    return`${year}-${month}-${day}`; 
  }
  const onHandleSubmit = async () => {
    const formattedDate = formatDate(selectedDate);
    const formattedDatEXP = formatDate(dateExpiry);
    const data = {
      p_first_name: state.firstname,
      p_last_name: state.lastname,
      p_birthdate: selectedDate,
      address: state.address,
      email: state.email,
      id_first_name: state.firstname,
      id_middle_name: state.middlename,
      id_last_name: state.lastname,
      id_birthdate: selectedDate,
      type: state?.identification?.value,
      document_number: state?.license,
      card_number: state?.cardnumber,
      is_official_holder: state.condition,
      user_id: userData?.user?.id,
      country: state?.country,
      street: state?.street,
      city: state?.city,
      region: state?.region,
      zipPostcode: state?.zipPostcode,
      building: state?.building,
      gender: state?.gender,
      formattedDate:formattedDate,
      document_expiry:formattedDatEXP,
      drivers_licence_version_number:state?.dl_version ?? null,
      medicare_reference_number : state?.md_refrenceNumber ?? null,
      medicare_card_colour : state?.md_cardColour,
      payload: JSON.stringify(verifyFor_AU_NZ(state?.country, state?.identification?.value,state, formattedDate, formattedDatEXP))
    };
    let verifiedUserData = null;
    // return;
    try {
      setLoading(true);
      const result = await http.put("public/user-valid-ids/verify-account", data);
      console.log(result);
      if (result.statusText === "OK") {
        verifiedUserData = result.data;
        toast.success(verifiedUserData.message);

        const newUserData = {
          ...userData,
          user: {
            ...userData.user, 
            ...result.data.user
          },
          profile: {
            ...userData.profile, 
            ...result.data.profile
          },
        };

        localstorage.set("user_data", JSON.stringify(newUserData));

        setTimeout(() => {
          history.push(
            `${verifiedUserData?.user?.username}-${randomExtension()}/profile/${
              verifiedUserData?.profile?.id
            }`
          );
        }, [1000]);
      }
    } catch (err) {
      console.log("error => ", err);
      if(err.status === 422){
        toast.error(err?.data.message);
      }else if(err.status === 429){
        toast.error(err?.data.message);
      }else{
        toast.error("Something went wrong. Please contact adminstrator.");
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const verifyFor_AU_NZ = (country,id_type,details,bdate, date_expiry)=>{
    /** Notice: The state.license is assing for ID3 documentNumber and shortPassportNumber for the NZ passport not all have same payload object*/
    
    let personObject = {
      "person": {
      "firstName": details?.firstname,
      "middleNames": details?.middlename ? details?.middlename.split(" ") : [],
      "lastNames": details?.lastname ? details?.lastname.split(" ") : [],
      "dateOfBirth": bdate,
      "gender": details?.gender,
      "currentAddress": {
          "country":country,
          "thoroughfare":details?.street,
          "locality":details?.city,
          "administrativeArea":details?.region,
          "postalCode":details?.zipPostcode,
          "building":details?.building
    }
  }
    }
    
    let payload_AU = {
        "profiles": [
          {
            "id": country === AU ? id_type === PASSPORT_ID ? AU_PASSPORT : 
                  id_type === DRIVER_LICENSE ? AU_DRIVERS_LICENCE : 
                  id_type === MEDICARE_ID ? AU_MEDICARE : AU_PERSONAL_INFO : null, //AU Identity
            "version": 0
          }
        ],
        "subject": {...personObject}
    }

    let payload_NZ = {
      "profiles": [
        {
          "id": id_type === PASSPORT_ID ? NZ_PASSPORT :  NZ_DRIVERS_LICENCE, //NZ Identity
          "version": 0
        }
      ],
      "subject": {...personObject}
    }
    let payload_UK = {
      "profiles": [
        {
          "id": UK_NAD,
          "version": 0
        }
      ],
      "subject": {...personObject}
    }
    let payload_IN = {
      "profiles": [
        {
          "id": IN_DEP,
          "version": 0
        }
      ],
      "subject": {...personObject}
    }

    let payload_US = {
      "profiles": [
        {
          "id": US_NAD,
          "version": 0
        }
      ],
      "subject": {...personObject}
    }

    if(AU === country){
      let additionalInformation = {};
      switch(id_type){
        case PASSPORT_ID:
          additionalInformation = {
              "identityDocuments": [
                {
                  "documentType": "Short Passport",
                  "documentNumber": details?.license,
                  "country": AU,
                  "countryOfOrigin": AU
                }
              ]
            }
            break;
        case DRIVER_LICENSE:
          additionalInformation = {
              "identityDocuments": [
                {
                  "documentType": "Driving Licence",
                  "documentNumber": details?.license,
                  "country": AU,
                  "state": details?.region,
                  "cardNumber": details?.cardNumber
                }
              ]
            }
          break;
        case MEDICARE_ID:
          additionalInformation = {
              "identityDocuments": [
                {
                  "documentType": "Medicare",
                  "documentNumber": details?.license,
                  "documentExpiry": date_expiry,
                  "country": AU,
                  "referenceNumber":details?.md_refrenceNumber,
                  "cardColour": details?.md_cardColour
                }
              ]
            }
          break;
        default:
          additionalInformation = {};
          break;
      }
      payload_AU.subject = {...payload_AU.subject, additionalInformation}
      return payload_AU;
    }
    else if(NZ === country){
      let additionalInformation = {};
      switch(id_type){
        case PASSPORT_ID:
          additionalInformation = {
              "identityDocuments": [
                {
                  "documentType": "International Passport",
                  "documentNumber": " ",
                  "countryOfOrigin": NZ,
                  "documentExpiry": date_expiry,
                  "shortPassportNumber": details?.license
                },
              ]
            }
            break;
        case DRIVER_LICENSE:
          additionalInformation = {
              "identityDocuments": [
                {
                  "documentType": "Driving Licence",
                  "documentNumber": details?.license,
                  "documentVersionNumber": details?.dl_version,
                  "country": NZ,
                  // "vehicleRegistration": "aB3456"
                }
              ]
            }
          break;
        default:
          additionalInformation = {};
          break;
      }
      payload_NZ.subject = {...payload_NZ.subject, additionalInformation}
      return payload_NZ;
    }
    else if(IN === country){
      let additionalInformation = {};
      additionalInformation = {
        "identityDocuments": [
          {
            "documentType":id_type ===  EPIC ? EPIC : id_type ===  PAN ? PAN : 'Driving Licence',
            "documentNumber": details?.license,
            "country": IN,
          },
        ]
      }  
      payload_IN.subject = {...payload_NZ.subject, additionalInformation}
      return payload_IN;
    }
    else if(UK === country){
      return payload_UK;
    }else if(US === country){
      let additionalInformation = {};
      additionalInformation = {
        "identityDocuments": [
          {
            "documentType": SOCIAL_SECURITY_US,
            "documentNumber": details?.license ?? null,
            "country": "US",
          },
        ]
      }  
      payload_US.subject = {...payload_US.subject, additionalInformation}
      return payload_US
    }
    else{
      return payload_AU;
    }
   
  }

  return (
    <Layout title="Verify Account">
      <div className="auth-page-wrapper pb-8.5">
        <div className="w-full bg-white rounded-md px-7.5 1md:px-24.5 pt-9">
          <Link
            to={`/${userData?.user?.username}-${randomExtension()}/profile/${userData?.profile?.id}`}>
            <div className="flex items-center font-bold gap-1 hover:text-lightPrimary mb-2 text-sm text-darkerGray">
              <ArrowLeftIcon className="font-bold" />
              <span>Back to Profile</span>
            </div>
          </Link>
          <h1 className="text-3xl text-lightBlack tracking-tighter font-bold">
            Get Verification Badge
          </h1>
          <br></br>
          {
            //   (state.profile_type === 'group' || state.profile_type === 'team') ?
            //   <br></br> :
            //   <div className="w-full mt-10 grid 2sm:grid-cols-2 gap-5">
            //   <TabSelector
            //     isActive={selectedTab === "personal"}
            //     gap="mr-0"
            //     onClick={() => setSelectedTab("personal")}
            //   >
            //     <div className="w-full flex justify-center items-center">
            //       {/* number */}
            //       {selectedTab === "personal" ? (
            //         <div className="w-6 h-6 rounded-full border-2 flex justify-center items-center text-sm mr-5">
            //           1
            //         </div>
            //       ) : (
            //         <div className="w-6 h-6 rounded-full bg-lightGreen flex justify-center items-center text-sm mr-5">
            //           <BiCheck className="text-white text-base" />
            //         </div>
            //       )}
            //       <p
            //         className={
            //           selectedTab === "personal" ? "" : "text-lightBlack"
            //         }
            //       >
            //         Personal Details
            //       </p>
            //     </div>
            //   </TabSelector>
            //   <TabSelector
            //     isActive={selectedTab === "details"}
            //     gap="mr-0"
            //     onClick={() => setSelectedTab("details")}
            //   >
            //     <div className="w-full flex justify-center items-center">
            //       <div className="w-6 h-6 rounded-full border-2 flex justify-center items-center text-sm mr-5">
            //         2
            //       </div>
            //       ID Details
            //     </div>
            //   </TabSelector>
            // </div>
          }
          
        </div>
        <div className="mt-6 w-full bg-white rounded-md">
          {
            state.profile_type === 'group' || state.profile_type === 'team'?
            <VerifyTeam/>
            :
            <> {/* verify individual */}
              {/* content */}
              <div className="pt-12.5 pb-10 px-7.5 1md:pl-24.5 1md:pr-56">
                {/* <TabPanel hidden={selectedTab !== "personal"}>  */}
                    <div className="w-full 3md:flex">
                      <div className="w-full mb-5 3md:w-6/20 3md:mb-0">
                        <p className="text-lg text-lightBlack font-semibold">
                          Your details
                        </p>
                      </div>
                      {/* forms */}
                      <div className="w-full 3md:w-14/20">
                        <div className="w-full grid 2md:grid-cols-3 gap-6">
                          <Input
                            inputContainerClass="form-input-container"
                            value={state?.firstname}
                            name="firstname"
                            outsideLabel="First Name"
                            placeholder="John"
                            outsideLabelClass="text-sm font-bold text-darkerGray"
                            noLabelPaddingY="1rem"
                            type="text"
                            onChange={handleInputChange}
                            errorMessage={state.validationErrors?.firstname}
                          />
                          <Input
                            inputContainerClass="form-input-container"
                            value={state?.middlename}
                            name="middlename"
                            outsideLabel="Middle Name"
                            placeholder="Paul"
                            outsideLabelClass="text-sm font-bold text-darkerGray"
                            noLabelPaddingY="1rem"
                            type="text"
                            onChange={handleInputChange}
                            errorMessage={state.validationErrors?.middlename}
                          />
                          <Input
                            inputContainerClass="form-input-container"
                            value={state?.lastname}
                            name="lastname"
                            outsideLabel="Last Name"
                            placeholder="Doe"
                            outsideLabelClass="text-sm font-bold text-darkerGray"
                            noLabelPaddingY="1rem"
                            type="text"
                            onChange={handleInputChange}
                            errorMessage={state.validationErrors?.lastname}
                          />
                        </div>  
                        <div className="w-full grid 2md:grid-cols-2 gap-6"> 
                          <DatePicker
                            label="Birthdate"
                            selected={selectedDate}
                            onChange={(date) => handleChangeDate("birthdate", date)}
                            placeholderText="Select Date"
                            errorMessage={state.validationErrors?.birthdate}
                          /> 
                          <Select
                              label="Gender"
                              options={gender}
                              placeholder="Select Gender"
                              name="gender"
                              className=""
                              value={
                                state.gender &&
                                gender.filter((g) => g.value === state.gender)
                              }
                              onChange={(e) =>
                                handleInputChange({
                                  target: { name: "gender", value: e.value },
                                })
                              }
                              errorMessage={state.validationErrors?.gender}
                            /> 
                        </div>
                          <Input
                            inputContainerClass="form-input-container"
                            value={state?.email}
                            name="email"
                            outsideLabel="Email Address"
                            placeholder="tedlasso@gmail.com"
                            outsideLabelClass="text-sm font-bold text-darkerGray"
                            noLabelPaddingY="1rem"
                            type="email"
                            forEmailVerification={true}
                            verified={userData?.user?.email_verified_at ? true : false}
                            onChange={handleInputChange}
                            errorMessage={state.validationErrors?.email}
                          /> 
                        
                      </div>
                    </div>
                    <div className="w-full 3md:flex">
                      <div className="w-full mb-5 3md:w-6/20 3md:mb-0">
                          <p className="text-lg text-lightBlack font-semibold">
                            Your Complete Address
                          </p>
                        </div>
                        {/* forms */}
                    <div className="w-full 3md:w-14/20"> 
                        {/* <div>
                            <Input
                            ref={ref} 
                              inputContainerClass="form-input-container"
                              value={state?.address}
                              name="address"
                              outsideLabel="Address"
                              placeholder="Enter Address"
                              outsideLabelClass="text-sm font-bold text-darkerGray"
                              noLabelPaddingY="1rem"
                              type="text" 
                              onChange={handleInputChange}
                              errorMessage={state.validationErrors?.address}
                            /> 
                          </div> */}
                          <InputAddress handleInputChange={handleInputChange} setState={setState} state={state}/>
 
                          <div className="w-full bg-[#F5F5F5] px-2 pt-2 rounded-md form-input-container">
                            <div className="w-full grid 2md:grid-cols-3 gap-6">
                              <Input
                                placeholder="10"
                                inputContainerClass="form-input-container"
                                value={state?.building}
                                name="building"
                                outsideLabel="House No."
                                outsideLabelClass="text-sm font-bold text-darkerGray"
                                noLabelPaddingY="1rem"
                                type="text"
                                onChange={handleInputChange}
                                errorMessage={state.validationErrors?.building}
                              /> 
                              <div className="col-span-2">
                                <Input
                                  placeholder="MCLAREN CRES"
                                  inputContainerClass="form-input-container"
                                  value={state?.street}
                                  name="street"
                                  outsideLabel="Street"
                                  outsideLabelClass="text-sm font-bold text-darkerGray"
                                  noLabelPaddingY="1rem"
                                  type="text"
                                  onChange={handleInputChange}
                                  errorMessage={state.validationErrors?.street}
                                />
                              </div>
                      
                            </div>
                            <div className="w-full grid 2md:grid-cols-2 gap-6"> 
                              <Input
                                placeholder="2607"
                                inputContainerClass="form-input-container"
                                value={state?.zipPostcode}
                                name="zipPostcode"
                                outsideLabel="Post Code"
                                outsideLabelClass="text-sm font-bold text-darkerGray"
                                noLabelPaddingY="1rem"
                                type="text"
                                onChange={handleInputChange}
                                errorMessage={state.validationErrors?.zipPostcode}
                              />
                              <Input
                                placeholder="PEARCE"
                                inputContainerClass="form-input-container"
                                value={state?.city}
                                name="city"
                                outsideLabel="City"
                                outsideLabelClass="text-sm font-bold text-darkerGray"
                                noLabelPaddingY="1rem"
                                type="text"
                                onChange={handleInputChange}
                                errorMessage={state.validationErrors?.city}
                              />  
                            </div>
                            <div className="w-full pb-2">
                              <Input
                                  placeholder="ACT"
                                  inputContainerClass="form-input-container"
                                  value={state?.region}
                                  name="region"
                                  outsideLabel="State"
                                  outsideLabelClass="text-sm font-bold text-darkerGray"
                                  noLabelPaddingY="1rem"
                                  type="text"
                                  onChange={handleInputChange}
                                  errorMessage={state.validationErrors?.region}
                                /> 
                            </div>
                            <Input
                                  placeholder="Australia"
                                  inputContainerClass="form-input-container"
                                  value={state?.country}
                                  name="country"
                                  // outsideLabel="County"
                                  outsideLabelClass="text-sm font-bold text-darkerGray"
                                  noLabelPaddingY="1rem"
                                  type="hidden"
                                  onChange={handleInputChange}
                                  errorMessage={state.validationErrors?.country}
                                /> 
                          </div>
                      </div>   
                    </div> 
                    {
                      withIDs.includes(state.country) &&
                      <div className="w-full 3md:flex">
                      {/* Provide your ID Details */}
                      <div className="w-full mb-5 3md:w-6/20 3md:mb-0">
                        <p className="text-lg text-lightBlack font-semibold">
                          Provide your ID Details
                        </p>
                      </div>
                      {/* forms */}
                      <div className="w-full 3md:w-14/20">
                        <div className="w-full grid 2md:grid-cols-2 gap-6">
                          <Select
                            label="Choose an ID option"
                            options={state?.country === AU ? au_options :
                                    state?.country === IN ? india_options :
                                    state?.country === US ? us_options : options}
                            placeholder="Select ID option"
                            name="identification"
                            onChange={(e) =>
                              handleInputChange({
                                target: { name: "identification", value: e },
                              })
                            }
                            errorMessage={state.validationErrors?.identification}
                          />
                        </div>
                        <div className="w-full grid 2md:grid-cols-2 gap-6">
                          <Input
                            inputContainerClass="form-input-container"
                            value={state?.license}// real value in backend is document_number
                            name="license"
                            outsideLabel={state.identification.value === DRIVER_LICENSE ? 'Driver’s License Number' 
                              : state.identification.value === PASSPORT_ID ? 'Passport Number' :
                               state.identification.value === EPIC ? 'Epic Number' : 
                               state.identification.value === PAN ? 'Pan Number' : 
                               state.identification.value === SOCIAL_SECURITY_US ? 'Social Security' : 'Medicare Number'}
                            showInfo={true}
                            outsideLabelClass="text-sm font-bold text-darkerGray flex"
                            noLabelPaddingY="1rem"
                            type="text"
                            onChange={handleInputChange}
                            errorMessage={state.validationErrors?.license}
                          />
                          {
                            state.identification.value === DRIVER_LICENSE && state?.country === AU ?
                            <Input
                            inputContainerClass="form-input-container"
                            value={state?.cardnumber}
                            name="cardnumber"
                            outsideLabel="Card Number"
                            showInfo={true}
                            outsideLabelClass="text-sm font-bold text-darkerGray flex"
                            noLabelPaddingY="1rem"
                            type="text"
                            onChange={handleInputChange}
                            errorMessage={state.validationErrors?.cardnumber}
                          /> :
                          state?.country === NZ && state.identification.value === DRIVER_LICENSE ?
                          <div >
                          <Input
                            inputContainerClass="form-input-container"
                            value={state?.dl_version}
                            name="dl_version"
                            outsideLabel="Version"
                            showInfo={true}
                            outsideLabelClass="text-sm font-bold text-darkerGray flex"
                            noLabelPaddingY="1rem"
                            type="text"
                            onChange={handleInputChange}
                            errorMessage={state.validationErrors?.dl_version}
                          />
                          </div>
                          :
                          state.identification.value === PASSPORT_ID && state?.country === NZ ?
                          <DatePicker
                              label="Date Expiry"
                              selected={dateExpiry}
                              onChange={(date) => handleChangeDate("date_expiry", date)}
                              placeholderText="Select Date"
                              errorMessage={state.validationErrors?.birthdate}
                            /> 
                            : state.identification.value === MEDICARE_ID ?
                            <Input
                            inputContainerClass="form-input-container"
                            value={state?.md_refrenceNumber}
                            name="md_refrenceNumber"
                            outsideLabel="Reference Number"
                            showInfo={true}
                            outsideLabelClass="text-sm font-bold text-darkerGray flex"
                            noLabelPaddingY="1rem"
                            type="text"
                            onChange={handleInputChange}
                            errorMessage={state.validationErrors?.md_refrenceNumber}
                          /> : <></>
                          }
                          
                        </div> 
                        {
                          state?.country === AU && state.identification.value === MEDICARE_ID &&
                          <div className="w-full grid 2md:grid-cols-2 gap-6">
                            <Input
                              inputContainerClass="form-input-container"
                              value={state?.md_cardColour}
                              name="md_cardColour"
                              outsideLabel="Card Colour"
                              showInfo={true}
                              outsideLabelClass="text-sm font-bold text-darkerGray flex"
                              noLabelPaddingY="1rem"
                              type="text"
                              onChange={handleInputChange}
                              errorMessage={state.validationErrors?.md_cardColour}
                            /> 
                            <DatePicker
                                label="Date Expiry"
                                selected={dateExpiry}
                                onChange={(date) => handleChangeDate("date_expiry", date)}
                                placeholderText="Select Date"
                                errorMessage={state.validationErrors?.birthdate}
                              /> 
                          </div>
                        }

                        <div>
                          <CustomCheckbox
                            label={
                              <p className="ml-3 mt-0.5 text-sm text-darkerGray">
                                I agree that my above information is checked with the
                                Issuer or Official Record Holder
                              </p>
                            }
                            name="condition"
                            checked={state.condition}
                            onChange={handleUpdateCheckbox}
                          />
                        </div>
                      </div>
                      </div>
                    }
                {/* </TabPanel> */}
                {/* details */}
                {/* <TabPanel hidden={selectedTab !== "details"}>
                 
                </TabPanel> */}
              </div>
              {/* footer */}
              <div className="py-5.5 px-7.5 1md:pl-24.5 1md:pr-56 border-t-2 border-gray">
                <div className="w-full flex justify-end">
                  <Button
                    buttonName={selectedTab === "personal" ? "Cancel" : "Back"}
                    buttonTextClass="text-xs text-lightBlack font-semibold"
                    buttonRadius="6px"
                    buttonClass="relative py-4 px-7.5 bg-thBg mr-3.5"
                    onClick={() => {
                      if (selectedTab === "personal") {
                        history.goBack();
                      } else {
                        setSelectedTab("personal");
                      }
                    }}
                  />
                  <Button
                    // buttonName={selectedTab === "personal" ? "Next" : "Complete"}
                    buttonName="Complete"
                    buttonTextClass="text-xs text-white font-semibold"
                    buttonRadius="6px"
                    buttonClass="relative py-4 px-15 bg-primary"
                    loading={loading ? 1 : 0}
                    loadingIcon={
                      loading && (
                        <div className="absolute right-3 bottom-0 top-0 flex items-center">
                          <LoadingIndicator className="text-white w-5 h-5" />
                        </div>
                      )
                    }
                    onClick={() => {
                      // if (selectedTab === "personal") {
                      //   onValidateBeforeNext();
                      // } else {
                      //   onValidateBeforeSubmit();
                      // }
                      onValidateBeforeSubmit();
                    }}
                  />
                </div>
              </div>
            </>
          }
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (params) => {
      dispatch(userActions.setUserData(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyAccount);
