import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={72}
    height={86}
    fill="none"
    {...props}
  >
    <path
      fill="#EBF5FF"
      d="M56.148 80.214H7.77c-1.013-.501-1.873-1.124-1.873-2.414.001-7.933.003-15.868.006-23.8l2.76-.046h4.57c.181.01.361.027.543.027h35.219c.206 0 .413-.02.62-.032.65.008 1.299.022 1.948.024 1.54.003 1.759-.215 1.759-1.745V14.214c0-.182.008-.365-.002-.545-.042-.783-.38-1.14-1.173-1.14-6.47 0-12.94.01-19.41.019l.005-.019c-.361-.205-.731-.18-1.104-.044l-4.84-.008c-1.14 0-2.278.003-3.418.005-.379-.143-.754-.157-1.119.045a.387.387 0 0 1-.083.026l-11.725-.03-4.552-.033c-.004-1.527-.01-3.054-.015-4.58l3.01-.064c.207.008.415.025.622.025h47.936c.234 0 .466-.025.7-.038.007.39.02.781.02 1.17 0 22.61-.008 45.22.02 67.831.002 1.621-.556 2.744-2.046 3.38h-.001ZM29.152 68.022h20.974c.39 0 .771.001 1.135-.193.847-.453.914-1.486.12-2.029-.361-.248-.767-.271-1.192-.271-14.035.005-28.07.003-42.104.005-.182 0-.367-.013-.546.015-.735.116-1.176.588-1.173 1.236.004.644.45 1.116 1.186 1.22.229.034.467.015.7.015H29.15l.003.002Zm-.058 5.313h20.988c.362 0 .72.025 1.07-.138.468-.215.796-.527.766-1.063-.03-.524-.274-.93-.787-1.159-.326-.145-.659-.13-.994-.13H8.162c-.209 0-.42-.016-.623.017-.737.118-1.174.592-1.168 1.239.006.643.456 1.113 1.192 1.218.23.033.467.014.7.014h20.833l-.002.002Zm-13.87-12.33c.362 0 .723.006 1.085 0 .67-.009 1.073-.328 1.134-1.012.06-.67-.372-1.267-1.037-1.315-.77-.057-1.556-.083-2.32.01-.64.08-1.063.498-1.017 1.236.044.7.4 1.05 1.147 1.082.336.014.671.003 1.008.001v-.001Zm-6.843 0c.337 0 .674.01 1.011 0 .668-.026 1.167-.487 1.186-1.083.018-.634-.403-1.202-1.042-1.246a15.427 15.427 0 0 0-2.33.005c-.639.052-1.04.624-1.004 1.259.039.696.407 1.038 1.168 1.064.337.013.674.002 1.011.002Zm13.732 0c.337 0 .674.01 1.011 0 .772-.026 1.142-.353 1.192-1.043.048-.668-.376-1.24-1.057-1.289a15.912 15.912 0 0 0-2.254 0 1.14 1.14 0 0 0-1.052 1.304c.088.64.481 1.01 1.15 1.029.337.01.675.001 1.012.001l-.002-.001Z"
    />
    <path
      fill="#D8EBFF"
      d="m5.902 54-.006 23.8c0 1.29.86 1.913 1.873 2.414H2.154c-.863-.454-1.725-.91-1.879-2.022l.007-18.35C.285 42.532.286 25.222.29 7.914l-.01-.064h5.624l-.014.061c.004 1.527.01 3.054.015 4.58-.705.164-.931.65-.931 1.327.005 12.95.005 25.901 0 38.851 0 .682.232 1.16.933 1.331L5.902 54Z"
    />
    <path
      fill="#C4E4F8"
      d="M.288 7.915.28 59.841c0 6.117-.003 12.233-.004 18.35-.05-.005-.1-.009-.151-.009V7.87l.163.045Z"
    />
    <path
      fill="#F1749E"
      d="M.288 7.915.125 7.87V2.246c.045-.01.092-.017.138-.025l.016 5.63.009.064Z"
    />
    <path
      fill="#90BFED"
      d="M32.737 12.55c6.47-.009 12.94-.02 19.41-.02.791 0 1.13.358 1.174 1.14.009.182.001.364.001.546v38.013c0 1.532-.216 1.748-1.759 1.745-.649 0-1.298-.014-1.947-.023-.002-.182-.014-.366-.005-.547.17-3.128-1.931-6.198-5.018-7.231-.663-.223-1.305-.51-1.965-.743-2.052-.723-4.034-1.637-6.122-2.26-.74-.303-1.47-.626-2.221-.898-.425-.153-.73-.376-.828-.832.017-.798.136-1.6-.07-2.39.032-.024.04-.056.023-.093.097-.226.296-.347.481-.491a8.406 8.406 0 0 0 2.457-3.068c.641-1.358.658-2.811.767-4.257.515.005 1.033.025 1.547.011 2.176-.063 3.457-2.251 2.47-4.21-.366-.91-1.123-1.308-2.01-1.532.004-2.186.02-4.372.012-6.557-.007-1.65-1.011-2.67-2.66-2.697-.816-.012-1.37-.398-1.532-1.166-.269-1.268-.976-2.076-2.205-2.443v.002ZM8.896.239c.285-.008.57-.025.856-.025h45.295c2.134 0 3.103.964 3.106 3.09.002 1.51 0 3.018 0 4.528-.233.012-.465.037-.7.037-15.978.002-31.956.002-47.935 0-.207 0-.416-.015-.622-.025V.237.24ZM10.454 12.524l11.725.03c-.064.044-.12.107-.191.129-2.756.83-4.228 2.808-4.228 5.686 0 1.765.022 3.53-.011 5.294-.013.726.128 1.385.492 2.002-.553.292-1.035.656-1.257 1.273-.993 1.913.27 4.127 2.41 4.213.543.022 1.089.006 1.633.006-.1 3.173.984 5.785 3.623 7.66-.006.781-.042 1.563-.011 2.343.024.56-.221.867-.718 1.06-.773.297-1.535.62-2.302.932a49.043 49.043 0 0 0-4.523 1.723c-.89.337-1.77.712-2.674 1.005-1.769.572-3.278 1.507-4.43 2.985-.013-.312-.035-.622-.035-.934-.002-11.31.001-22.62-.013-33.93 0-.578.094-1.07.51-1.48v.003Z"
    />
    <path
      fill="#3E8DDD"
      d="M17.096 44.877a48.606 48.606 0 0 1 4.523-1.723c.712 2.864 4.097 5.235 7.378 5.162 3.279.137 6.47-2.05 7.51-5.145 2.088.626 4.07 1.538 6.122 2.26.66.233 1.301.521 1.964.744 3.087 1.033 5.188 4.103 5.019 7.23-.01.182.001.364.005.548-.207.01-.414.03-.621.03H13.777c-.181 0-.362-.016-.544-.026.198-.304.143-.647.159-.981.152-3.103 1.187-5.793 3.664-7.801.108-.088.202-.173.042-.297l-.002-.001Z"
    />
    <path
      fill="#82B0DE"
      d="M10.454 12.524c-.414.41-.51.902-.51 1.48.014 11.31.011 22.62.013 33.93 0 .312.023.622.034.934-1.01 1.548-1.44 3.246-1.33 5.088L5.904 54c-.701-.17-.933-.649-.933-1.33.004-12.95.004-25.902 0-38.852 0-.677.224-1.163.931-1.328l4.551.033h.002Z"
    />
    <path
      fill="#2D4863"
      d="M32.737 12.55c1.23.366 1.937 1.173 2.205 2.442.161.768.716 1.152 1.533 1.166 1.65.027 2.653 1.046 2.659 2.697.008 2.185-.008 4.37-.013 6.556-.373.924-1.113 1.156-2.017 1.079-1.493-.412-2.253-1.446-2.408-3.087-.193.224-.284.309-.35.41-1.207 1.85-2.896 2.75-5.117 2.681-1.113-.034-2.231.024-3.346.04-.572-.16-1.154.067-1.727-.036-1.34-.73-1.654-1.897-1.58-3.338.085-1.632.03-3.272.018-4.909-.017-2.146.872-3.769 2.675-4.948.495-.323 1.127-.357 1.531-.823l4.84.008c.365.086.734.086 1.104.042l-.005.02h-.002Z"
    />
    <path
      fill="#A9D4FF"
      d="M29.154 68.022H8.257c-.233 0-.472.018-.7-.014-.736-.105-1.183-.577-1.186-1.222-.003-.646.438-1.12 1.173-1.235.179-.028.364-.016.545-.016 14.035 0 28.07 0 42.104-.004.424 0 .83.021 1.193.27.794.543.725 1.575-.12 2.03-.364.195-.745.192-1.135.192H29.157l-.003-.001ZM29.096 73.335H8.263c-.233 0-.471.017-.7-.014-.737-.104-1.186-.574-1.192-1.218-.006-.648.433-1.123 1.168-1.239.204-.033.416-.017.623-.017h41.975c.335 0 .668-.016.994.13.513.227.757.633.787 1.158.03.535-.298.847-.765 1.063-.351.162-.71.138-1.07.138H29.093l.002-.001Z"
    />
    <path
      fill="#1B2D3F"
      d="M26.8 12.479c-.405.466-1.037.5-1.533.823-1.8 1.179-2.691 2.802-2.674 4.948.012 1.637.067 3.276-.017 4.909-.076 1.439.24 2.608 1.578 3.337-1.22-.001-2.44-.039-3.66.005-.888.031-1.634-.205-2.255-.837-.364-.618-.505-1.276-.492-2.002.031-1.764.01-3.53.01-5.295 0-2.88 1.473-4.857 4.23-5.686.07-.02.126-.085.19-.128a.407.407 0 0 0 .085-.026c.376.048.75.041 1.12-.045 1.14 0 2.277-.003 3.417-.005v.002Z"
    />
    <path
      fill="#0C6ED1"
      d="M8.662 53.954c-.112-1.84.318-3.54 1.33-5.087 1.151-1.479 2.661-2.413 4.43-2.985.904-.293 1.784-.668 2.674-1.005.162.124.068.208-.042.296-2.477 2.008-3.512 4.699-3.664 7.801-.016.334.04.678-.158.982h-4.57v-.002Z"
    />
    <path
      fill="#3E8DDD"
      d="M8.895.239v7.607l-3.01.064.014-.061c0-1.555.06-3.114-.022-4.664C5.8 1.702 6.447.78 7.76.24h1.134l.001-.002Z"
    />
    <path
      fill="#A9D4FF"
      d="M15.226 61.006c-.336 0-.673.012-1.008-.003-.747-.032-1.103-.383-1.148-1.082-.047-.737.378-1.157 1.017-1.235.764-.094 1.551-.068 2.32-.011.666.048 1.097.646 1.037 1.315-.061.683-.464 1.005-1.133 1.013-.363.005-.723 0-1.085 0v.003ZM8.383 61.006c-.337 0-.675.01-1.012-.002-.76-.028-1.128-.368-1.168-1.064-.036-.635.366-1.207 1.004-1.259.773-.063 1.557-.06 2.331-.005.638.046 1.06.612 1.041 1.247-.019.595-.517 1.056-1.185 1.081-.337.013-.674.002-1.011.002ZM22.114 61.006c-.337 0-.674.008-1.011-.002-.67-.018-1.063-.389-1.15-1.028a1.142 1.142 0 0 1 1.051-1.304 16.139 16.139 0 0 1 2.255 0c.68.048 1.105.622 1.056 1.288-.05.69-.42 1.02-1.191 1.043-.337.01-.674.001-1.011.001l.001.002Z"
    />
    <path
      fill="#A5B5D5"
      d="M23.381 12.483a3.16 3.16 0 0 1-1.12.046c.366-.202.74-.187 1.12-.046ZM32.742 12.53c-.37.045-.738.045-1.103-.042.373-.136.743-.161 1.103.043Z"
    />
    <path
      fill="#0C6ED1"
      d="M7.762.24C6.448.783 5.8 1.703 5.879 3.186c.082 1.55.02 3.109.022 4.664H.277L.262 2.22C.589.987 1.466.238 2.702.224 4.39.204 6.076.233 7.763.24Z"
    />
    <path
      fill="#F7AAA0"
      d="M25.882 26.532c1.115-.015 2.232-.073 3.346-.039 2.221.069 3.91-.83 5.117-2.68.066-.103.157-.187.35-.412.153 1.643.915 2.677 2.407 3.087v1.846l.01 2.809c-.108 1.446-.124 2.899-.767 4.257a8.422 8.422 0 0 1-2.456 3.068c-.185.145-.385.265-.482.49-.172.055-.37.07-.51.17-1.026.716-2.017.495-2.986-.1-2.676-1.642-3.974-4.075-4.032-7.184-.033-1.77 0-3.54 0-5.31l.003-.002Z"
    />
    <path
      fill="#F59889"
      d="M25.882 26.532c-.003 1.77-.033 3.54 0 5.31.056 3.11 1.354 5.544 4.032 7.186.97.595 1.96.815 2.985.098.141-.098.339-.114.511-.17.02.038.011.07-.022.095.007.798-.127 1.6.07 2.39.098.456.404.676.829.832.75.272 1.481.595 2.221.896-1.04 3.095-4.231 5.282-7.51 5.146v-7.557c0-.33.073-.677-.462-.708-1.394-.08-2.668-.568-3.883-1.233-2.639-1.875-3.724-4.487-3.623-7.66.003-.67-.033-1.343.02-2.01.052-.65-.185-.868-.826-.82-1.31.1-2.43-.294-3.238-1.39.223-.62.704-.982 1.258-1.273.62.632 1.368.868 2.254.837 1.218-.044 2.44-.006 3.66-.005.575.104 1.156-.123 1.729.036h-.005Z"
    />
    <path
      fill="#E58274"
      d="m37.115 31.143-.01-2.808c1.55.135 2.97-.103 4.03-1.39.986 1.958-.295 4.148-2.471 4.209-.516.014-1.032-.006-1.548-.01h-.001Z"
    />
    <path
      fill="#F59889"
      d="M41.133 26.945c-1.06 1.287-2.48 1.525-4.03 1.39V26.49c.905.077 1.647-.155 2.018-1.079.888.225 1.646.623 2.012 1.534Z"
    />
    <path
      fill="#F7AAA0"
      d="M33.457 41.442c-.197-.792-.064-1.593-.07-2.39.207.79.088 1.592.07 2.39Z"
    />
    <path
      fill="#E58274"
      d="M24.65 38.819c1.214.665 2.489 1.152 3.883 1.232.534.032.462.378.462.709.002 2.52 0 5.039 0 7.556-3.28.071-6.666-2.298-7.378-5.164.767-.312 1.53-.635 2.302-.933.495-.19.74-.497.718-1.06-.032-.779.003-1.56.01-2.342l.002.002ZM16.981 26.938c.808 1.096 1.929 1.491 3.238 1.39.641-.048.878.17.826.82-.053.667-.017 1.341-.02 2.01-.544 0-1.09.016-1.634-.006-2.14-.086-3.404-2.3-2.41-4.212v-.002Z"
    />
    <circle cx={56.158} cy={71.812} r={11} fill="#fff" />
    <path
      fill="#18C967"
      d="M55.191 53.984c-8.74 0-15.86 7.121-15.86 15.86 0 8.74 7.12 15.862 15.86 15.862 8.74 0 15.86-7.122 15.86-15.861 0-8.74-7.12-15.861-15.86-15.861Zm7.581 12.213L53.78 75.19a1.188 1.188 0 0 1-1.68 0l-4.49-4.489a1.197 1.197 0 0 1 0-1.681c.46-.46 1.222-.46 1.682 0l3.648 3.648 8.152-8.153c.46-.46 1.221-.46 1.681 0 .46.46.46 1.206 0 1.682Z"
    />
  </svg>
)
export default SvgComponent
