import React, { useState } from "react";
import Button from "components/button/button";
import SearchIconSVG from "assets/svg/search-icon";

const InputSearchComponent = (
  {
    name = "",
    placeholder = "Search",
    disabledInput = false,
    buttonName = "Search",
    onSearch = () => {},
    ...props // make sure this pops here is for input only
  },
  ref
) => {
  const [value, setValue] = useState("");

  return (
    <div className="input-search">
      <div className="w-full flex">
        <SearchIconSVG className="absolute top-4 left-5 text-lightBlack" />
        <input
          className="pr-3"
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            setValue(e.target.value || "");
          }}
          ref={ref}
          name={name}
          disabled={disabledInput}
          {...props}
        />
        <Button
          buttonName={buttonName}
          buttonTextClass="text-sm text-white font-semibold"
          buttonRadius="6px"
          buttonClass="relative bg-primary px-7"
          onClick={() => onSearch(value)}
        />
      </div>
    </div>
  );
};

const InputSearch = React.forwardRef(InputSearchComponent);

export default InputSearch;
