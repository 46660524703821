export default function EmptyData({ icon, emptyMessage }) {
    return (
        <div className="w-full flex flex-col justify-center items-center">
            {icon}
            <p className="text-darkerGray font-semibold leading-6.7 mt-3">
                {emptyMessage}
            </p>
        </div>
    );
}
