import {
  REFRESH_CATEGORY_FILTER,
	SET_CATEGORY_FILTER,
} from './types';

export const refreshCategoryFilter = () => {
	return {
    type: REFRESH_CATEGORY_FILTER,
    payload: [],
  };
}

export const setCategoryFilter = (params) => {
	return {
    type: SET_CATEGORY_FILTER,
    payload: params,
  };
}