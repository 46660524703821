import React, { useMemo } from "react";
import UserGearIconSVG from "assets/svg/user-gear-icon";
import GraduationCapIconSVG from "assets/svg/graduation-cap-icon";
import ChatUserIconSVG from "assets/svg/chat-user-icon";
import BriefCaseExperienceIconSVG from "assets/svg/brief-case-icon";
import { Profile1 } from "constants/images";
import { FiBriefcase } from "react-icons/fi";
import { BiBuildingHouse } from "react-icons/bi";
import { FaRegUserCircle } from "react-icons/fa";
import ResumeUnderlineIconSVG from "assets/svg/resume-underline-icon";
import ResumeDotDividerIconSVG from "assets/svg/resume-dot-divider-icon";
import moment from "moment";
import _ from "lodash";
import { socialTemplate } from "utils/constant";

export default function TemplateTwoFullPageOne(props) {
  // ===================== props ============================
  const {
    previewImage,
    color,
    details = {},
    personal = {},
    social = {},
    page = 1,
    primaryColor,
    tertiaryColor,
    quarternary,
  } = props;
  // ===================== states ============================
  const [socialColor, socialBG] = useMemo(() => {
    if (color.label === "Yellow + Blue") {
      return ["#FFFFFF", primaryColor];
    } else if (color.label === "Pink + Dark Pink") {
      return [tertiaryColor, "#FFFFFF"];
    }
    return ["#FFFFFF", tertiaryColor];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  const [pageBGActive, pageBGInactive] = useMemo(() => {
    if (color.label === "Blue + Orange") {
      return [tertiaryColor, "#16438A"];
    } else if (color.label === "Pink + Dark Pink") {
      return ["#FFFFFF", tertiaryColor];
    }
    return ["#FFFFFF", "#000000"];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  const socMed = useMemo(() => {
    let arrays = Object.entries(social);
    return arrays.map((obj) => {
      let key = obj[0];
      let value = obj[1];
      return { key, value };
    });
  }, [social]);
  // ===================== return Node ============================

  return (
    <div className="w-full">
      {/* header  */}
      <div className="w-full">
        <div
          className="w-full pt-7 pb-6 pl-21 pr-17"
          style={{ backgroundColor: primaryColor }}>
          <div className="w-full flex flex-row gap-x-5.4 text-white items-center">
            {
                personal?.profile_image &&
                <>
                  <div className="w-30 h-30 rounded-md bg-white relative overflow-hidden">
                    {personal.hasOwnProperty("profile_image") ? (
                      <img
                        src={previewImage ?? personal.profile_image}
                        className="w-full h-full object-cover object-center"
                        alt="resume-profile"
                      />
                    ) : (
                      <img
                        src={Profile1}
                        className="w-full h-full object-cover object-center"
                        alt="resume-profile"
                      />
                    )}
                  </div>
                </>
            }
            <div style={{ width: "calc(100% - 111px)" }}>
              <h1 className="text-2.5xl font-bold tracking-verytight">
                {details.username}
              </h1>
              <div className="w-full flex flex-row gap-x-1.5 text-white items-center">
                {personal.hasOwnProperty("name") && personal.name && (
                  <p className="text-6xs leading-2 font-medium">
                    {personal.name}
                  </p>
                )}
                {personal.hasOwnProperty("name") &&
                  personal.name &&
                  personal.hasOwnProperty("gender") &&
                  personal.gender && (
                    <span className="text-6xs opacity-30">&#8226;</span>
                  )}
                {personal.hasOwnProperty("gender") && personal.gender && (
                  <p className="text-6xs leading-2 font-medium">
                    {personal.gender}
                  </p>
                )}
                {personal.hasOwnProperty("gender") &&
                  personal.gender &&
                  personal.hasOwnProperty("pronoun") &&
                  personal.pronoun && (
                    <span className="text-6xs opacity-30">&#8226;</span>
                  )}
                {personal.hasOwnProperty("pronoun") && personal.pronoun && (
                  <p className="text-6xs leading-2 font-medium">
                    {personal.pronoun}
                  </p>
                )}
                {personal.hasOwnProperty("address") && personal.address && (
                  <>
                    <span className="text-6xs opacity-30">&#8226;</span>
                    <p className="text-6xs leading-2 font-medium">
                      {personal.address}
                    </p>
                  </>
                )}
              </div>
              {/*  */}
              <div className="w-full text-white mt-2.5">
                <ul className="w-full grid gap-y-1.5">
                  {personal.hasOwnProperty("occupations") &&
                    personal.occupations.length > 0 && (
                      <li className="w-full flex space-x-1.5 items-center">
                        <FiBriefcase className="text-resume-icon-header opacity-70 w-3.5 h-3.5" />
                        <p className="text-6xs leading-2 font-medium">
                          {(personal.occupations || []).map((val, index) => {
                            const count = personal.occupations.length;
                            if (index >= count - 1 && count !== 1) {
                              return " and " + val;
                            } else {
                              if (index >= count - 2) {
                                return val;
                              } else {
                                return val + ", ";
                              }
                            }
                          })}
                        </p>
                      </li>
                    )}
                  {/* ========================== show dummy data for now .. replace it later ==================== */}
                  <li className="w-full flex space-x-1.5 items-center">
                    <BiBuildingHouse className="text-resume-icon-header opacity-70 w-3.5 h-3.5" />
                    <p className="text-6xs leading-2 font-medium">
                      Agency Company
                    </p>
                  </li>
                  {/* ========================== end: show dummy data for now .. replace it later ==================== */}
                  {personal.hasOwnProperty("profile_type") &&
                    personal.profile_type.length > 0 && (
                      <li className="w-full flex space-x-1.5 items-center">
                        <FaRegUserCircle className="text-resume-icon-header opacity-70 w-3.5 h-3.5" />
                        <div className="w-full flex flex-row gap-x-1.5 items-center">
                          {(personal.profile_type || []).map((value, index) => {
                            let seperator = (index + 1) % 2 === 0;
                            if (seperator) {
                              return (
                                <React.Fragment key={index}>
                                  <span className="text-6xs opacity-30">
                                    &#8226;
                                  </span>
                                  <p className="text-6xs leading-2 font-medium">
                                    {value}
                                  </p>
                                </React.Fragment>
                              );
                            }
                            return (
                              <p
                                className="text-6xs leading-2 font-medium"
                                key={index}>
                                {value}
                              </p>
                            );
                          })}
                        </div>
                      </li>
                    )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* end header */}
        <div className="w-full py-3" style={{ backgroundColor: quarternary }}>
          <div className="w-full flex flex-row gap-x-3 justify-center text-white">
            {(socMed || []).map((obj, index) => {
              const SocialIcon = socialTemplate[obj.key];
              if (SocialIcon) {
                return (
                  <div className="flex items-center space-x-1.5" key={index}>
                    <button
                      className="w-4 h-4 rounded-full"
                      style={{ backgroundColor: socialBG }}>
                      <SocialIcon
                        className="w-2 h-2 m-auto"
                        style={{ color: socialColor }}
                      />
                    </button>
                    <p className="text-4xs leading-2">{obj.value}</p>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
        {/* end extra header */}
        {/* start body */}
        <div
          className="w-full grid bg-white relative grid-row-auto"
          style={{ minHeight: "1029px" }}>
          {/* body */}
          <div className="w-full px-21 pt-10.5">
            <div className="w-full grid gap-y-7">
              <div className="w-full flex flex-row space-x-8.6">
                <div className="w-18.6">
                  <p className="text-sm font-medium text-lightBlack tracking-tight">
                    More Information
                  </p>
                  <ResumeUnderlineIconSVG
                    className="mt-3"
                    stroke={primaryColor}
                  />
                </div>
                <div style={{ width: "calc(100% - 112px)" }}>
                  <div className="w-full relative grid gap-y-2.5">
                    {/* =============1st======================== */}
                    {details.hasOwnProperty("bio") && details.bio && (
                      <div className="w-full flex flex-row space-x-0.5 border-b border-resumeTempTwoBorder pb-1.5">
                        <div className="w-18.5">
                          <p className="text-4xs text-darkerGray tracking-resume leading-4">
                            Bio
                          </p>
                        </div>
                        <div style={{ width: "calc(100% - 75px)" }}>
                          <p className="text-4xs text-lightBlack font-medium tracking-resumeValue leading-4">
                            {details.bio}
                          </p>
                        </div>
                      </div>
                    )}
                    {/* =============2nd======================== */}
                    <div className="w-full grid grid-cols-2 gap-x-3">
                      {/* ==============left============================== */}
                      <div className="w-full grid gap-y-2.5">
                        {/* ============= Date of Birth======================= */}
                        {details.hasOwnProperty("birthdate") &&
                          details.birthdate && (
                            <div className="w-full flex flex-row space-x-0.5 border-b border-resumeTempTwoBorder pb-1.5">
                              <div className="w-18.5">
                                <p className="text-4xs text-darkerGray tracking-resume leading-4">
                                  Date of Birth
                                </p>
                              </div>
                              <div style={{ width: "calc(100% - 75px)" }}>
                                <p className="text-4xs text-lightBlack font-medium tracking-resumeValue leading-4">
                                  {moment(details.birthdate).format(
                                    "DD MMMM YYYY"
                                  )}
                                </p>
                              </div>
                            </div>
                          )}
                        {/* ==============Civil Status====================== */}
                        {details.hasOwnProperty("civil_status") &&
                          details.civil_status && (
                            <div className="w-full flex flex-row space-x-0.5 border-b border-resumeTempTwoBorder pb-1.5">
                              <div className="w-18.5">
                                <p className="text-4xs text-darkerGray tracking-resume leading-4">
                                  Civil Status
                                </p>
                              </div>
                              <div style={{ width: "calc(100% - 75px)" }}>
                                <p className="text-4xs text-lightBlack font-medium tracking-resumeValue leading-4">
                                  {details.civil_status}
                                </p>
                              </div>
                            </div>
                          )}
                        {/* ================Other Name==================== */}
                        {details.hasOwnProperty("other_name") &&
                          details.other_name && (
                            <div className="w-full flex flex-row space-x-0.5 border-b border-resumeTempTwoBorder pb-1.5">
                              <div className="w-18.5">
                                <p className="text-4xs text-darkerGray tracking-resume leading-4">
                                  Other Name
                                </p>
                              </div>
                              <div style={{ width: "calc(100% - 75px)" }}>
                                <p className="text-4xs text-lightBlack font-medium tracking-resumeValue leading-4">
                                  {details.other_name}
                                </p>
                              </div>
                            </div>
                          )}
                        {/* ===============Eye Color===================== */}
                        {details.hasOwnProperty("eye_color") &&
                          details.eye_color && (
                            <div className="w-full flex flex-row space-x-0.5 border-b border-resumeTempTwoBorder pb-1.5">
                              <div className="w-18.5">
                                <p className="text-4xs text-darkerGray tracking-resume leading-4">
                                  Eye Color
                                </p>
                              </div>
                              <div style={{ width: "calc(100% - 75px)" }}>
                                <p className="text-4xs text-lightBlack font-medium tracking-resumeValue leading-4">
                                  {details.eye_color}
                                </p>
                              </div>
                            </div>
                          )}
                        {/* ================Phone==================== */}
                        {details.hasOwnProperty("phone_number") &&
                          details.phone_number && (
                            <div className="w-full flex flex-row space-x-0.5 border-b border-resumeTempTwoBorder pb-1.5">
                              <div className="w-18.5">
                                <p className="text-4xs text-darkerGray tracking-resume leading-4">
                                  Phone
                                </p>
                              </div>
                              <div style={{ width: "calc(100% - 75px)" }}>
                                <p className="text-4xs text-lightBlack font-medium tracking-resumeValue leading-4">
                                  {details.phone_number}
                                </p>
                              </div>
                            </div>
                          )}
                      </div>
                      {/* ==============right============================== */}
                      <div className="w-full grid gap-y-2.5">
                        {/* ============= Birth Location======================= */}
                        {details.hasOwnProperty("birth_location") &&
                          details.birth_location && (
                            <div className="w-full flex flex-row space-x-0.5 border-b border-resumeTempTwoBorder pb-1.5">
                              <div className="w-18.5">
                                <p className="text-4xs text-darkerGray tracking-resume leading-4">
                                  Birth Location
                                </p>
                              </div>
                              <div style={{ width: "calc(100% - 75px)" }}>
                                <p className="text-4xs text-lightBlack font-medium tracking-resumeValue leading-4">
                                  {details.birth_location}
                                </p>
                              </div>
                            </div>
                          )}
                        {/* ==============Children====================== */}
                        {details.hasOwnProperty("children") &&
                          details.children !== 0 && (
                            <div className="w-full flex flex-row space-x-0.5 border-b border-resumeTempTwoBorder pb-1.5">
                              <div className="w-18.5">
                                <p className="text-4xs text-darkerGray tracking-resume leading-4">
                                  Children
                                </p>
                              </div>
                              <div style={{ width: "calc(100% - 75px)" }}>
                                <p className="text-4xs text-lightBlack font-medium tracking-resumeValue leading-4">
                                  {details.children} Children
                                </p>
                              </div>
                            </div>
                          )}
                        {/* ================Hair Color==================== */}
                        {details.hasOwnProperty("hair_color") &&
                          details.hair_color && (
                            <div className="w-full flex flex-row space-x-0.5 border-b border-resumeTempTwoBorder pb-1.5">
                              <div className="w-18.5">
                                <p className="text-4xs text-darkerGray tracking-resume leading-4">
                                  Hair Color
                                </p>
                              </div>
                              <div style={{ width: "calc(100% - 75px)" }}>
                                <p className="text-4xs text-lightBlack font-medium tracking-resumeValue leading-4">
                                  {details.hair_color}
                                </p>
                              </div>
                            </div>
                          )}
                        {/* ===============Height===================== */}
                        {details.hasOwnProperty("height") && details.height && (
                          <div className="w-full flex flex-row space-x-0.5 border-b border-resumeTempTwoBorder pb-1.5">
                            <div className="w-18.5">
                              <p className="text-4xs text-darkerGray tracking-resume leading-4">
                                Height
                              </p>
                            </div>
                            <div style={{ width: "calc(100% - 75px)" }}>
                              <p className="text-4xs text-lightBlack font-medium tracking-resumeValue leading-4">
                                {details.height}cm
                              </p>
                            </div>
                          </div>
                        )}
                        {/* ================Email Address ==================== */}
                        {details.hasOwnProperty("email") && details.email && (
                          <div className="w-full flex flex-row space-x-0.5 border-b border-resumeTempTwoBorder pb-1.5">
                            <div className="w-18.5">
                              <p className="text-4xs text-darkerGray tracking-resume leading-4">
                                Email Address
                              </p>
                            </div>
                            <div style={{ width: "calc(100% - 75px)" }}>
                              <p className="text-4xs text-lightBlack font-medium tracking-resumeValue leading-4">
                                {details.email}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Divider */}
              <div className="w-full">
                <ResumeDotDividerIconSVG full={true} />
              </div>
              {/* professional */}
              {((details.hasOwnProperty("occupations") &&
                !_.isEmpty(details.occupations)) ||
                details.education ||
                details.training ||
                details.experience) && (
                <>
                  <div className="w-full flex flex-row space-x-8.6">
                    <div className="w-18.6">
                      <p className="text-sm font-medium text-lightBlack tracking-tight">
                        Professional Information
                      </p>
                      <ResumeUnderlineIconSVG
                        className="mt-3"
                        stroke={primaryColor}
                      />
                    </div>
                    <div style={{ width: "calc(100% - 112px)" }}>
                      <div className="w-full grid gap-y-6">
                        {/* ========== Abilities / Skills / Specialties  ======== */}
                        {details.hasOwnProperty("occupations") &&
                          !_.isEmpty(details.occupations) && (
                            <div className="w-full flex flex-row space-x-3.5">
                              <UserGearIconSVG
                                className="w-5.5 h-5.5"
                                fill={primaryColor}
                              />
                              <div style={{ width: "calc(100% - 36px)" }}>
                                <p className="text-sm text-lightBlack font-semibold tracking-tight">
                                  Abilities / Skills / Specialties
                                </p>
                                <div className="w-full flex flex-row flex-wrap gap-x-1.5 gap-y-0.5 text-1xs text-white font-bold mt-1">
                                  {details.occupations.map((oc, i) => (
                                    <span
                                      key={i}
                                      className="uppercase px-1.5 py-0.5 rounded-sm border"
                                      style={{
                                        backgroundColor: tertiaryColor,
                                      }}>
                                      {oc}
                                    </span>
                                  ))}
                                </div>
                              </div>
                            </div>
                          )}
                        {/* ========== Education ======== */}
                        {details.hasOwnProperty("education") &&
                          details.education && (
                            <div className="w-full flex flex-row space-x-3.5">
                              <GraduationCapIconSVG
                                className="w-5.5 h-5.5"
                                fill={primaryColor}
                              />
                              <div style={{ width: "calc(100% - 36px)" }}>
                                <p className="text-sm text-lightBlack font-semibold tracking-tight">
                                  Education
                                </p>
                                <div className="w-full mt-1">
                                  <p className="text-4xs text-darkerGray tracking-resume leading-4">
                                    {details.education}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                        {/* ========== Education ======== */}
                        {details.hasOwnProperty("training") &&
                          details.training && (
                            <div className="w-full flex flex-row space-x-3.5">
                              <ChatUserIconSVG
                                className="w-5.5 h-5.5"
                                fill={primaryColor}
                              />
                              <div style={{ width: "calc(100% - 36px)" }}>
                                <p className="text-sm text-lightBlack font-semibold tracking-tight">
                                  Training
                                </p>
                                <div className="w-full mt-1">
                                  <p className="text-4xs text-darkerGray tracking-resume leading-4">
                                    {details.training}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                        {/* ========== Experience ======== */}
                        {details.hasOwnProperty("experience") &&
                          details.experience && (
                            <div className="w-full flex flex-row space-x-3.5">
                              <BriefCaseExperienceIconSVG
                                className="w-5.5 h-5.5"
                                fill={primaryColor}
                              />
                              <div style={{ width: "calc(100% - 36px)" }}>
                                <p className="text-sm text-lightBlack font-semibold tracking-tight">
                                  Experience
                                </p>
                                <div className="w-full mt-1">
                                  <p className="text-4xs text-darkerGray tracking-resume leading-4">
                                    {details.experience}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  {/* Divider */}
                  <div className="w-full">
                    <ResumeDotDividerIconSVG full={true} />
                  </div>
                </>
              )}
            </div>
          </div>
          {/* footer */}
          <div className="w-full pt-10 flex items-end">
            <div
              className="w-full h-7.5 px-21 relative"
              style={{ backgroundColor: quarternary }}>
              <div className="w-full h-full flex flex-row justify-between items-center">
                <p className="text-white font-semibold text-6xs">Page {page}</p>
                <div className="flex flex-start gap-1.5">
                  <button
                    className="w-2.5 h-2.5 rounded-full"
                    style={{ backgroundColor: pageBGActive }}
                  />
                  <button
                    className="w-2.5 h-2.5 rounded-full"
                    style={{ backgroundColor: pageBGInactive }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
