const AU_PERSONAL_INFO = process.env.REACT_APP_AU_PERSONAL_INFO;
const AU_DRIVERS_LICENCE = process.env.REACT_APP_AU_DRIVERS_LICENCE;
const AU_PASSPORT = process.env.REACT_APP_AU_PASSPORT;
const AU_MEDICARE = process.env.REACT_APP_AU_MEDICARE;
const NZ_DRIVERS_LICENCE = process.env.REACT_APP_NZ_DRIVERS_LICENCE;
const NZ_PASSPORT = process.env.REACT_APP_NZ_PASSPORT;
const UK_NAD = process.env.REACT_APP_UK_NAD;
const US_NAD = process.env.REACT_APP_US_NAD;
const IN_DEP = process.env.REACT_APP_INDIA;
const DRIVER_LICENSE = 'Driver’s License';
const PASSPORT_ID = 'Passport ID';
const MEDICARE_ID = 'Medicare ID';
const EPIC = 'Epic';
const PAN = 'PAN';
const AU = 'Australia';
const NZ = 'New Zealand';
const UK = 'United Kingdom';
const US = 'United States';
const IN = 'India';
const SOCIAL_SECURITY_US = 'Social Security';
export {
    AU_PERSONAL_INFO,
    AU_DRIVERS_LICENCE,
    AU_PASSPORT,
    AU_MEDICARE,
    NZ_DRIVERS_LICENCE,
    NZ_PASSPORT,DRIVER_LICENSE,
    PASSPORT_ID,
    MEDICARE_ID,
    AU,
    NZ,
    UK_NAD,
    US_NAD,
    UK,
    US,
    IN,
    IN_DEP,
    EPIC,
    PAN,
    SOCIAL_SECURITY_US
};
