import { useEffect, useState } from "react";
import Layout from "layouts/layout";
import { TabSelector } from "components/global/tabselector";
import { useLocalStorage, useDialogHook } from "utils/customhooks";
import LineVerticalSVG from "assets/svg/line-vertical";
import { BiCheck } from "react-icons/bi";
import Button from "components/button/button";
import {
  CoverDefaultPlaceholder,
  ProfileDefaultPlaceholder,
} from "constants/images";
import CropThumbnailModal from "components/profile/milestones/crop-thumbnail";
import CropCoverModal from "components/profile/milestones/crop-cover";
import SocialMediaForm from "components/account/forms/social-media-form";
// import MetadataForm from "components/account/forms/metadata-form";
import ProfileSettingsForm from "components/account/forms/profile-settings-form";
import ImagesForm from "components/account/forms/images-form";
import update from "immutability-helper";
import { useParams, useHistory } from "react-router-dom";
import { urlSerializer, randomExtension } from "utils/utils";
import userActions from "redux/modules/user/actions";
import { connect, useDispatch } from "react-redux";
import _ from "lodash";
import toast from "react-hot-toast";
import { UPDATE_PROFILE } from "redux/modules/user/types";
import LoadingIndicator from "assets/svg/loading-indicator";

const SetupProfile = ({ userData, setUserData }) => {
  const { http, localstorage } = global.services;
  const { profileId, username } = useParams();

  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useLocalStorage(0, 1, 3, 4);

  const extension = randomExtension();
  const [previewProfile, setPreviewProfile] = useState(
    ProfileDefaultPlaceholder
  );
  const [cover, setCover] = useState(CoverDefaultPlaceholder);
  const cropProfile = useDialogHook(CropThumbnailModal);
  const cropCover = useDialogHook(CropCoverModal);

  const [keyword, setKeyword] = useState([]);
  const [socials, setSocials] = useState([{ social: "", link: "" }]);
  const [state, setState] = useState({
    profile_image_file: null,
    cover_image_file: null,
    username: null,
    other_name: null,
    condition: true,
    loading: false,
    validationErrors: null,
    adultContent: false,
    featured: true,
    showNotifications: true,
    metadata: {},
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
      validationErrors: { ...state.validationErrors, [name]: null },
    }));
  };

  const onTypeLinkSocial = (e, index) => {
    let payload = _.clone(socials);
    let result = update(payload, {
      [index]: {
        link: { $set: e.target.value },
      },
    }); //remove
    setSocials(result);
  };

  const onSelectSocial = (e, index) => {
    let value = e ? e.value : "";
    let payload = _.clone(socials);
    let result = update(payload, {
      [index]: {
        social: { $set: value },
      },
    }); //remove
    setSocials(result);
  };

  const onRemoveSocials = (index) => {
    let payload = _.clone(socials);
    let result = update(payload, { $splice: [[index, 1]] }); //remove
    setSocials(result);
  };

  const onAddSocials = () => {
    let payload = _.clone(socials);
    payload.push({ social: "", link: "" });
    setSocials(payload);
  };

  const handleInputObjectChange = (e, object) => {
    const { name, value } = e.target;

    setState((prev) => ({
      ...prev,
      [object]: { ...prev[object], [name]: value },
    }));
  };

  const onChangeKeywords = (e) => {
    let _value = e.map((v) => v.value);
    setKeyword((prev) => _value);
  };

  const onUploadImage = async (e, type = "profile") => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      function () {
        // convert image file to base64 string
        const image = reader.result?.toString() || "";
        let title =
          type === "profile" ? "Crop Profile Picture" : "Crop Cover Photo";
        const Iprops = {
          image: image,
          type: file.type,
          returnType: "file",
          title: title,
        };
        if (image) {
          if (type === "profile") {
            cropProfile(Iprops, (result) => {
              if (result) {
                const { imageFile, preview } = result;
                setState((prev) => ({
                  ...prev,
                  profile_image_file: imageFile,
                }));
                setPreviewProfile(preview);
              }
            });
          } else {
            cropCover(Iprops, (result) => {
              if (result) {
                const { imageFile, preview } = result;
                setState((prev) => ({ ...prev, cover_image_file: imageFile }));
                setCover(preview);
              }
            });
          }
        }
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const fetchProfile = async () => {
    try {
      const url = urlSerializer("public/user-profiles/get-profile", {
        id: profileId,
        username: username.split("-")[0],
      });
      const { data: response } = await http.get(url);

      let filter = {};
      if (typeof response.milestone_filter === "string") {
        filter = JSON.parse(response.milestone_filter);
      } else if (typeof response.milestone_filter === "object") {
        filter = response.milestone_filter;
      }

      if (
        !_.isEmpty(response.metadata) &&
        !_.isEmpty(response.metadata.keywords)
      ) {
        setKeyword((prev) => response.metadata.keywords);
      }

      if (response.profile_image_url)
        setPreviewProfile(response.profile_image_url);

      if (response.cover_image_url) setCover(response.cover_image_url);

      if (!_.isEmpty(response)) {
        setState((prev) => ({
          ...prev,
          id: response.id,
          username: userData.user.username,
          other_name: response.other_name,
          gender: response.gender,
          pronoun: response.pronoun,
          address: response.address,
          occupations: response.occupations ? response.occupations : [],
          adultContent: response.show_adult,
          featured: response.show_featured,
          showNotifications: response.show_notifications,
          metadata: response.metadata ? response.metadata : {},
          profileTypes: response.profile_type
            ? response.profile_type.type
            : null,
          profileStyle: response.profile_type
            ? response.profile_type.style
            : null,
          job_role: filter?.job_role || null,
          category: filter?.category || null,
          sort: filter?.sort || null,
        }));
      }
    } catch (err) {
      console.log("fetchProfile", err);
    }
  };

  const getSocialMedia = async () => {
    let socialMedia = [];

    try {
      const { data } = await http.get("public/social-medias", {
        user_profile_id: profileId,
      });
      if (data.length > 0) {
        data.forEach((sm) => {
          socialMedia.push({ social: sm.type, link: sm.link });
        });
        // console.log("socialMedia", socialMedia);
        setSocials(socialMedia);
      }
    } catch (err) {
      console.log("getSocialMedia", err);
    }
  };

  const next = () => {
    let error = {};

    if (selectedTab === 0) {
      socials.forEach((s, i) => {
        if (!s.social)
          error[`social.${i}.type`] = "The social field is required";
        if (!s.link) error[`social.${i}.link`] = "The link field is required";
      });

      if (!state.profileTypes) {
        error["profileTypes"] = "The profile type field is required";
      }
      if (!state.profileStyle) {
        error[`profileStyle`] = "The profile style field is required";
      }

      if (!error.social && !error.profileTypes && !error.profileStyle) {
        setSelectedTab(1);
      }
    } 
    // else if (selectedTab === 1) {
    //   error.metadata = {};
    //   if (!state.metadata.title) {
    //     error.metadata.title = "The title field is required";
    //   }
    //   if (!state.metadata.description) {
    //     error.metadata.description = "The description field is required";
    //   }
    //   if (!keyword.length) {
    //     error.metadata.keywords = "The keywords field is required";
    //   }

    //   if (
    //     state.metadata.title &&
    //     state.metadata.description &&
    //     keyword.length > 0
    //   ) {
    //     setSelectedTab(3);
    //   }
    // } 
    else if (selectedTab === 1) {
      if (!state.job_role) {
        error.job_role = "The job role field is required";
      }
      if (!state.category) {
        error.category = "The category field is required";
      }
      if (!state.sort) {
        error.sort = "The sort field is required";
      }

      if (state.job_role && state.category && state.sort) {
        setSelectedTab(3);
      }
    }

    let validation = !_.isEmpty(error) ? error : null;

    setState((prev) => ({
      ...prev,
      validationErrors: validation,
    }));
  };

  const skip = () => {
    if (selectedTab === 0) {
      const { profileTypes, profileStyle, ...otherState } = state;
      setState((prev) => ({
        ...otherState,
        validationErrors: null,
      }));
      setSocials([{ social: "", link: "" }]);
      setSelectedTab(1);
    } 
    // else if (selectedTab === 1) {
    //   setState((prev) => ({
    //     ...prev,
    //     metadata: {},
    //     validationErrors: null,
    //   }));
    //   setSelectedTab(3);
    // } 
    else if (selectedTab === 1) {
      setSelectedTab(3);
    }
  };

  const back = () => {
    // if (selectedTab === 1) {
    //   setSelectedTab(0);
    // } else 
    if (selectedTab === 1) {
      setSelectedTab(0);
    } else if (selectedTab === 3) {
      setSelectedTab(1);
    }
  };

  const done = async (e) => {
    e.preventDefault();
    setState((prev) => ({ ...prev, loading: true }));

    let payload = new FormData();
    let params = _.clone(state);

    // if (keyword.length > 0)
    //   params.metadata = {
    //     ...state.metadata,
    //     keywords: keyword,
    //   };

    Object.keys(params).forEach((key) => {
      if (key === 1) params[key] = JSON.stringify(params[key]);

      if (
        (key === "profile_image_file" || key === "cover_image_file") &&
        params[key]
      )
        payload.append(key, params[key]);

      if (typeof params[key] === "boolean" || !_.isEmpty(params[key])) {
        payload.append(key, params[key]);
      }
    });

    socials.forEach((social, index) => {
      if (social.social || social.link) {
        payload.append(`social[${index}][type]`, social.social);
        payload.append(`social[${index}][link]`, social.link);
      }
    });

    payload.append("user_id", userData.user.id);
    payload.append(`profileId`, profileId);
    // payload.append("_method", "PUT");

    try {
      const { data, status } = await http.post(
        `public/profiles/update-profile-images`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (status === 200) {
        toast.success(data.message);
        const newUserData = {
          ...userData,
          profile: data.profile,
        };

        localstorage.set("user_data", JSON.stringify(newUserData));
        setState((prev) => ({
          ...prev,
          profile_image_file: null,
          cover_image_file: null,
        }));
        setUserData(newUserData);
        dispatch({
          type: UPDATE_PROFILE,
          payload: data.profile,
        });

        setSelectedTab(0);
        history.push(
          `/${userData.user.username}-${extension}/profile/${data.profile.id}`,
          {
            switchProfile: true,
          }
        );
      }
    } catch (error) {
      if (error.status === 422)
        return setState((prev) => ({
          ...prev,
          validationErrors: error.data?.errors,
        }));

      toast.error(
        error.status === 401
          ? "Invalid Credentials"
          : error?.data?.message || "It appears that something went wrong"
      );
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    if (profileId) {
      fetchProfile();
      getSocialMedia();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId]);

  return (
    <Layout title="Profile" setUserData={setUserData}>
      <div className="auth-page-wrapper pb-8.5">
        <div className="w-full bg-white px-7.5 1md:px-24.5 pt-9 rounded-md">
          <h1 className="text-3xl text-lightBlack tracking-tighter font-bold">
            Finish setting up your Profile
          </h1>
          <p>
            Congratulations! You've created{" "}
            <span className="font-bold">{state.other_name}</span>. Now add more
            details to help people connect with you.
          </p>
          <div className="flex justify-center flex-wrap md:flex-nowrap md:justify-center items-center">
            <TabSelector
              disabled={true}
              isActive={selectedTab === 0}
              gap="3sm:mr-10"
              colorType={selectedTab > 0 ? "black" : "default"}
              paddingY="py-7.5"
              onClick={() => setSelectedTab(0)}>
              <div className="w-full flex justify-center items-center">
                {selectedTab > 0 ? (
                  <div className="w-6 h-6 rounded-full bg-lightGreen flex justify-center items-center text-sm mr-3">
                    <BiCheck className="text-white text-base" />
                  </div>
                ) : (
                  <div className="w-6 h-6 rounded-full border-2 flex justify-center items-center text-sm mr-3">
                    1
                  </div>
                )}
                Social Media
              </div>
            </TabSelector>
            <div className="hidden 3sm:block">
              <LineVerticalSVG />
            </div>
            {/* <TabSelector
              disabled={true}
              isActive={selectedTab === 1}
              gap="3sm:mx-10"
              colorType={selectedTab > 1 ? "black" : "default"}
              paddingY="py-7.5"
              onClick={() => setSelectedTab(1)}>
              <div className="w-full flex justify-center items-center">
                {selectedTab > 1 ? (
                  <div className="w-6 h-6 rounded-full bg-lightGreen flex justify-center items-center text-sm mr-3">
                    <BiCheck className="text-white text-base" />
                  </div>
                ) : (
                  <div className="w-6 h-6 rounded-full border-2 flex justify-center items-center text-sm mr-3">
                    2
                  </div>
                )}
                Metadata
              </div>
            </TabSelector> */}
            <div className="hidden 3sm:block">
              <LineVerticalSVG />
            </div>
            <TabSelector
              disabled={true}
              isActive={selectedTab === 1}
              gap="3sm:mx-10"
              colorType={selectedTab > 1 ? "black" : "default"}
              paddingY="py-7.5"
              onClick={() => setSelectedTab(1)}>
              <div className="w-full flex justify-center items-center">
                {selectedTab > 3 ? (
                  <div className="w-6 h-6 rounded-full bg-lightGreen flex justify-center items-center text-sm mr-3">
                    <BiCheck className="text-white text-base" />
                  </div>
                ) : (
                  <div className="w-6 h-6 rounded-full border-2 flex justify-center items-center text-sm mr-3">
                    2
                  </div>
                )}
                Profile Settings
              </div>
            </TabSelector>
            <div className="hidden 3sm:block">
              <LineVerticalSVG />
            </div>
            <TabSelector
              disabled={true}
              isActive={selectedTab === 3}
              gap="3sm:ml-10"
              colorType={selectedTab > 3 ? "black" : "default"}
              paddingY="py-7.5"
              onClick={() => setSelectedTab(3)}>
              <div className="w-full flex justify-center items-center">
                <div className="w-6 h-6 rounded-full border-2 flex justify-center items-center text-sm mr-3">
                  3
                </div>
                Images
              </div>
            </TabSelector>
          </div>
        </div>

        <div className="w-full bg-white rounded-md">
          <div className="px-7.5 1md:px-24.5 pt-2 mt-4.5">
            {selectedTab === 0 && (
              <SocialMediaForm
                {...{
                  state,
                  socials,
                  onAddSocials,
                  onTypeLinkSocial,
                  onSelectSocial,
                  onRemoveSocials,
                  handleInputChange,
                }}
              />
            )}
            {/* {selectedTab === 1 && (
              <MetadataForm
                {...{
                  state,
                  keyword,
                  handleInputObjectChange,
                  onChangeKeywords,
                }}
              />
            )} */}
            {selectedTab === 1 && (
              <ProfileSettingsForm
                {...{
                  state,
                  setState,
                  userData,
                  handleInputChange,
                }}
              />
            )}
            {selectedTab === 3 && (
              <ImagesForm
                {...{
                  previewProfile,
                  cover,
                  onUploadImage,
                }}
              />
            )}
          </div>
          {/* footer */}
          <div className="py-5.5 px-7.5 1md:px-24.5 border-t-2 border-gray">
            <div className="w-full flex justify-between">
              <div>
                {selectedTab < 4 && (
                  <Button
                    buttonName="Skip this step"
                    buttonTextClass="text-xs text-lightBlack font-semibold"
                    buttonRadius="6px"
                    buttonClass="relative py-4 px-7.5 bg-thBg mr-3.5"
                    onClick={skip}
                  />
                )}
              </div>
              <div>
                <Button
                  disabled={state.loading}
                  buttonName="Back"
                  buttonTextClass="text-xs text-lightBlack font-semibold"
                  buttonRadius="6px"
                  buttonClass="relative py-4 px-15 bg-thBg mr-3.5"
                  onClick={back}
                />
                {selectedTab === 3 ? (
                  <Button
                    disabled={state.loading}
                    buttonName="Done"
                    buttonTextClass="text-xs text-white font-semibold flex flex-row items-center gap-2"
                    buttonRadius="6px"
                    buttonClass="relative py-4 px-15 bg-primary"
                    onClick={done}
                    icon={
                      state.loading ? (
                        <LoadingIndicator className="text-white w-4 h-4" />
                      ) : null
                    }
                  />
                ) : (
                  <Button
                    buttonName="Next"
                    buttonTextClass="text-xs text-white font-semibold flex flex-row items-center gap-2"
                    buttonRadius="6px"
                    buttonClass="relative py-4 px-15 bg-primary"
                    onClick={next}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (params) => {
      dispatch(userActions.setUserData(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SetupProfile);
