import { usePlacesWidget } from "react-google-autocomplete"; 
import Select from "components/select/select";
import { country } from "utils/constant"; 
import React from "react";
import countryList from 'react-select-country-list'

const InputAddress = (
  {
    handleInputChange = ()=>{},
    name = "",
    placeholder = "Search",
    disabledInput = false,
    buttonName = "Search",
    onSearch = () => {},
    setState,
    state,
    ...props // make sure this pops here is for input only
  }, 
) => {  
  const [selectCountry,setSelectedCountry] = React.useState({
    label:'Australia',
    value:'AU',
  })
  const options = React.useMemo(() => countryList().getData(), [])
  const { ref } = usePlacesWidget({
    apiKey:process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place) => {
        let city="", region="", country="",street="",zipPostcode="",building="";
        console.log(place); 
        for (var i = 0; i < place.address_components.length; i++) {
            for (var j = 0; j < place.address_components[i].types.length; j++) {
                if (place.address_components[i].types.includes('locality')) {
                    city = place.address_components[i].long_name;
                  }
                  if (place.address_components[i].types.includes('administrative_area_level_1')) {
                    region = place.address_components[i].long_name;
                  }
                  if (place.address_components[i].types.includes('country')) {
                    country = place.address_components[i].long_name;
                  }
                  if (place.address_components[i].types.includes('street_address')) {
                    street = place.address_components[i].long_name;
                  }
                  if (place.address_components[i].types.includes('postal_code')) {
                    zipPostcode = place.address_components[i].long_name;
                  }
                  if (place.address_components[i].types.includes('street_number')) {
                    building = place.address_components[i].long_name;
                  }
            }
          }
          setState((prev) => ({
            ...prev,
            address: place.formatted_address,
            country:country,
            city:city,
            region:region,
            street:street,
            zipPostcode:zipPostcode,
            building:building,
            validationErrors: { ...state.validationErrors, address: null },
          }));
    },
    options: {
      componentRestrictions: { country: selectCountry.value }, // Restrict to Australia
      types: ["geocode"], // Limit to geocode types
    }
  }); 
  
  const changeHandler = e => {
    console.log(e);
    setSelectedCountry(e)
  }
  return (
    <div className="form-input-container">

      <Select
        label="Country"
        options={options}
        placeholder="Select Country"
        name="country"
        value={selectCountry}
        onChange={(e) => changeHandler(e) }
    /> 
    <label for="address" className="text-sm font-bold text-darkerGray leading-4">Address</label>
    <p className="text-xs text-[#777777] mb-1">Filing this field will auto-complete the rest of he address field below. You can still edit the fields below if needed.</p>
    <div className="input-wrapper">
      <input ref={ref}  
        className="input-class w-full" type="text" name="address" placeholder="Melbourne VIC, Australia" 
        value={state?.address || ""}
        onChange={handleInputChange}
        style={{
          padding:  "1rem",
        }}
        />
        {
          state.validationErrors?.address && 
          <p className="input-error-message">* {state.validationErrors?.address}</p>
        }
      </div>
    </div>
  );
}; 

export default InputAddress;
