import React from "react";
import { useDialogHook } from "utils/customhooks";
import ImageModal from "./image-modal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components";
import _ from "lodash";

const LazyImage = ({
  source,
  viewUrl,
  customClassName = "",
  secondView = false,
  imageHeight = null,
  withContainer = "w-full"
}) => {
  const imageGalleryModal = useDialogHook(ImageModal);

  const handleImageGallery = (e) => {
    e.stopPropagation();
    imageGalleryModal({ source, viewUrl });
  };

  return (
    <div className={`relative flex items-center justify-center h-full ${withContainer}`}>
      <ImageLazyLoad
        src={source}
        effect={`blur${
          !_.isEmpty(customClassName) ? ` ${customClassName}` : ""
        }`}
        onClick={handleImageGallery}
        alt="milestone-landscape"
        secondview={secondView.toString()}
        customheight={imageHeight}
      />
    </div>
  );
};

const ImageLazyLoad = styled(LazyLoadImage)`
  object-fit: contain;
  object-position: center;
  width: 100%;
  cursor: pointer;

  ${({ secondview }) => {
    if (secondview === "true") {
      return `
      height: 90px;

      @media (min-width: 515px) {
        height: 120px;
      }

      @media (min-width: 1165px) {
        height: 200px;
      }
      
      `;
    }
  }}

  ${({ customheight }) => {
    if (customheight) {
      return `
      @media (min-width: 1024px) {
        height: ${customheight}px !important;
      }
      `;
    }
  }}
`;

export default LazyImage;
