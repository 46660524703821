const ToolTip = ({
  children,
  value,
  arrowClass,
  color = "black",
  backgroundColor = "#DDDDDD",
  width="w-[300px]",
  ...props
}) => {
  let bgColor = {
    background: backgroundColor,
    color: color,
  };
  return (
    <div className="relative group flex justify-center">
      {children}
      {/** tooltip*/}
      <div className="absolute hidden group-hover:inline-block text-center transition-opacity duration-300 ease-in-out -top-9">
        <div className={`relative flex justify-center ${width}`}>
          <div
            className={`z-20 text-[10px] p-1 px-2 rounded-md  min-w-18 `}
            style={bgColor}>
            {value}
          </div>
          <div
            className={`absolute z-0 top-[18px] w-2 h-2 transform rotate-45 -transalte-x-1/2 ${arrowClass}`}
            style={bgColor}></div>
        </div>
      </div>
    </div>
  );
};

export default ToolTip;
