import React from "react";
import { Profile1 } from "constants/images";
import moment from "moment";
import FirstPage from "./page-one";
import SecondPage from "./page-two";

export default function TemplateOneFull({
  previewImage,
  details = {},
  personal = {},
  social = {},
  milestones = {},
  page = 1,
  primaryColor,
  secondaryColor,
  tertiaryColor,
  show_image_milestone = true
}) {
  return (
    <div className="w-full">
      <div
        className="w-full flex flex-row h-full overflow-hidden"
        style={{ minHeight: "1118px" }}>
        {/** ================ left content ================ */}
        <div
          className="py-9.4 pl-13.5 pr-11"
          style={{ backgroundColor: secondaryColor, width: "308.475px" }}>
            {
                personal?.profile_image &&
                <>
                <div className="w-full flex justify-center">
                  <div className="w-35.5 h-35.5 flex rounded-full bg-white relative mb-2">
                    {/** picture */}
                    {personal.hasOwnProperty("profile_image") ? (
                      <img
                        src={previewImage ?? personal.profile_image}
                        className="w-full object-cover rounded-full"
                        alt="resume-profile"
                      />
                    ) : (
                      <img
                        src={Profile1}
                        className="w-full object-cover rounded-full"
                        alt="resume-profile"
                      />
                    )}
                  </div>
                </div>
                </>
            }
          {/** username */}
          <div className="border-b border-gray-300 py-4">
            <span
              className={"font-bold text-2.5xl tracking-resumeOne"}
              style={{ color: tertiaryColor }}>
              {details.username}
            </span>
          </div>
          {/** ================ personal information ================ */}
          <div className="border-b border-gray-300 pt-2.5 pb-5">
            <div className="mb-2">
              <span className="text-sm font-bold text-resumeTempFourTextPrimary tracking-resumeOne">
                Personal Information
              </span>
            </div>
            <div className="w-full grid gap-y-2.5">
              {/** name */}
              {personal.hasOwnProperty("name") && personal.name && (
                <div className="w-full flex flex-row space-x-1 items-start">
                  <div style={{ width: "81px" }}>
                    <p className="text-6xs text-darkerGray font-medium">Name</p>
                  </div>
                  <div style={{ width: "calc(100% - 81px)" }}>
                    <p className="text-6xs text-resumeTempFourTextPrimary">
                      {personal.name}
                    </p>
                  </div>
                </div>
              )}
              {/** gender */}
              {personal.hasOwnProperty("gender") && (
                <div className="w-full flex flex-row space-x-1 items-start">
                  <div style={{ width: "81px" }}>
                    <p className="text-6xs text-darkerGray font-medium">
                      Gender
                    </p>
                  </div>
                  <div style={{ width: "calc(100% - 81px)" }}>
                    <p className="text-6xs text-resumeTempFourTextPrimary">
                      {personal.gender}
                    </p>
                  </div>
                </div>
              )}
              {/** pronoun */}
              {personal.hasOwnProperty("pronoun") && (
                <div className="w-full flex flex-row space-x-1 items-start">
                  <div style={{ width: "81px" }}>
                    <p className="text-6xs text-darkerGray font-medium">
                      Pronouns
                    </p>
                  </div>
                  <div style={{ width: "calc(100% - 81px)" }}>
                    <p className="text-6xs text-resumeTempFourTextPrimary">
                      {personal.pronoun}
                    </p>
                  </div>
                </div>
              )}
              {/** occupation */}
              {personal.hasOwnProperty("occupations") && (
                <div className="w-full flex flex-row space-x-1 items-start">
                  <div style={{ width: "81px" }}>
                    <p className="text-6xs text-darkerGray font-medium">
                      Occupation
                    </p>
                  </div>
                  <div style={{ width: "calc(100% - 81px)" }}>
                    <p className="text-6xs text-resumeTempFourTextPrimary">
                      {(personal.occupations || []).map((val, index) => {
                        const count = personal.occupations.length;
                        if (index >= count - 1 && count !== 1) {
                          return " and " + val;
                        } else {
                          if (index >= count - 2) {
                            return val;
                          } else {
                            return val + ", ";
                          }
                        }
                      })}
                    </p>
                  </div>
                </div>
              )}
              {/** industry */}
              {/* {details.hasOwnProperty("industry") && details.industry && (
                <div className="w-full flex flex-row space-x-1 items-start">
                  <div style={{ width: "81px" }}>
                    <p className="text-2xs text-darkerGray font-medium">
                      Industry
                    </p>
                  </div>
                  <div style={{ width: "calc(100% - 81px)" }}>
                    <p className="text-6xs text-resumeTempFourTextPrimary">
                      {details.industry}
                    </p>
                  </div>
                </div>
              )} */}
              {/* dummy data for now */}
              <div className="w-full flex flex-row space-x-1 items-start">
                <div style={{ width: "81px" }}>
                  <p className="text-6xs text-darkerGray font-medium">
                    Industry
                  </p>
                </div>
                <div style={{ width: "calc(100% - 81px)" }}>
                  <p className="text-6xs text-resumeTempFourTextPrimary">
                    Agency Company
                  </p>
                </div>
              </div>
              {/** type and Style */}
              {personal.hasOwnProperty("profile_type") &&
                personal.profile_type.length > 0 && (
                  <>
                    {(personal.profile_type || []).map((value, index) => {
                      return (
                        <div
                          className="w-full flex flex-row space-x-1 items-start"
                          key={index}>
                          <div style={{ width: "81px" }}>
                            <p className="text-6xs text-darkerGray font-medium">
                              {index ? "Style" : "Type"}
                            </p>
                          </div>
                          <div style={{ width: "calc(100% - 81px)" }}>
                            <p className="text-6xs text-resumeTempFourTextPrimary">
                              {value}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
            </div>
          </div>
          {/** bio */}
          {details.hasOwnProperty("bio") && (
            <div className="border-b border-gray-300 pt-2.5 pb-5">
              <div className="mb-2">
                <span className="text-sm font-bold text-resumeTempFourTextPrimary tracking-resumeOne">
                  Bio
                </span>
              </div>
              <p className="text-6xs text-resumeTempFourTextPrimary">
                {details.bio}
              </p>
            </div>
          )}
          {/** ================ more information ================ */}
          <div className="border-b border-gray-300 pt-2.5 pb-5">
            <div className="mb-2">
              <span className="text-sm font-bold text-resumeTempFourTextPrimary tracking-resumeOne">
                More Information
              </span>
            </div>
            <div className="w-full grid gap-y-2.5">
              {/** birthdate */}
              {details.hasOwnProperty("birthdate") && details.birthdate && (
                <div className="w-full flex flex-row space-x-1 items-start">
                  <div style={{ width: "81px" }}>
                    <p className="text-6xs text-darkerGray font-medium">
                      Date of Birth
                    </p>
                  </div>
                  <div style={{ width: "calc(100% - 81px)" }}>
                    <p className="text-6xs text-resumeTempFourTextPrimary">
                      {moment(details.birthdate).format("DD MMMM YYYY")}
                    </p>
                  </div>
                </div>
              )}
              {/** birth location */}
              {details.hasOwnProperty("birth_location") &&
                details.birth_location && (
                  <div className="w-full flex flex-row space-x-1 items-start">
                    <div style={{ width: "81px" }}>
                      <p className="text-6xs text-darkerGray font-medium">
                        Birth Location
                      </p>
                    </div>
                    <div style={{ width: "calc(100% - 81px)" }}>
                      <p className="text-6xs text-resumeTempFourTextPrimary">
                        {details.birth_location}
                      </p>
                    </div>
                  </div>
                )}
              {/** civil status */}
              {details.hasOwnProperty("civil_status") &&
                details.civil_status && (
                  <div className="w-full flex flex-row space-x-1 items-start">
                    <div style={{ width: "81px" }}>
                      <p className="text-6xs text-darkerGray font-medium">
                        Civil Status
                      </p>
                    </div>
                    <div style={{ width: "calc(100% - 81px)" }}>
                      <p className="text-6xs text-resumeTempFourTextPrimary">
                        {details.civil_status}
                      </p>
                    </div>
                  </div>
                )}
              {/** children */}
              {details.hasOwnProperty("children") && details.children !== 0 && (
                <div className="w-full flex flex-row space-x-1 items-start">
                  <div style={{ width: "81px" }}>
                    <p className="text-6xs text-darkerGray font-medium">
                      Children
                    </p>
                  </div>
                  <div style={{ width: "calc(100% - 81px)" }}>
                    <p className="text-6xs text-resumeTempFourTextPrimary">
                      {details.children}
                    </p>
                  </div>
                </div>
              )}
              {/** other name */}
              {details.hasOwnProperty("other_name") && details.other_name && (
                <div className="w-full flex flex-row space-x-1 items-start">
                  <div style={{ width: "81px" }}>
                    <p className="text-6xs text-darkerGray font-medium">
                      Other Name
                    </p>
                  </div>
                  <div style={{ width: "calc(100% - 81px)" }}>
                    <p className="text-6xs text-resumeTempFourTextPrimary">
                      {details.other_name}
                    </p>
                  </div>
                </div>
              )}
              {/** phone number */}
              {details.hasOwnProperty("phone_number") &&
                details.phone_number && (
                  <div className="w-full flex flex-row space-x-1 items-start">
                    <div style={{ width: "81px" }}>
                      <p className="text-6xs text-darkerGray font-medium">
                        Phone
                      </p>
                    </div>
                    <div style={{ width: "calc(100% - 81px)" }}>
                      <p className="text-6xs text-resumeTempFourTextPrimary">
                        {details.phone_number}
                      </p>
                    </div>
                  </div>
                )}
              {/** hair color */}
              {details.hasOwnProperty("hair_color") && details.hair_color && (
                <div className="w-full flex flex-row space-x-1 items-start">
                  <div style={{ width: "81px" }}>
                    <p className="text-6xs text-darkerGray font-medium">
                      Hair Color
                    </p>
                  </div>
                  <div style={{ width: "calc(100% - 81px)" }}>
                    <p className="text-6xs text-resumeTempFourTextPrimary">
                      {details.hair_color}
                    </p>
                  </div>
                </div>
              )}
              {/** eye color */}
              {details.hasOwnProperty("eye_color") && details.eye_color && (
                <div className="w-full flex flex-row space-x-1 items-start">
                  <div style={{ width: "81px" }}>
                    <p className="text-6xs text-darkerGray font-medium">
                      Eye Color
                    </p>
                  </div>
                  <div style={{ width: "calc(100% - 81px)" }}>
                    <p className="text-6xs text-resumeTempFourTextPrimary">
                      {details.eye_color}
                    </p>
                  </div>
                </div>
              )}
              {/** height */}
              {details.hasOwnProperty("height") && details.height && (
                <div className="w-full flex flex-row space-x-1 items-start">
                  <div style={{ width: "81px" }}>
                    <p className="text-6xs text-darkerGray font-medium">
                      Height
                    </p>
                  </div>
                  <div style={{ width: "calc(100% - 81px)" }}>
                    <p className="text-6xs text-resumeTempFourTextPrimary">
                      {details.height}cm
                    </p>
                  </div>
                </div>
              )}
              {/** email */}
              {details.hasOwnProperty("email") && details.email && (
                <div className="w-full flex flex-row space-x-1 items-start">
                  <div style={{ width: "81px" }}>
                    <p className="text-6xs text-darkerGray font-medium">
                      Email Address
                    </p>
                  </div>
                  <div
                    className="break-all"
                    style={{ width: "calc(100% - 81px)" }}>
                    <p className="text-6xs text-resumeTempFourTextPrimary">
                      {details.email}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/** ================ right content ================ */}
        <div
          className=" bg-white p-12"
          style={{ width: "calc(100% - 308.475px)" }}>
          <div className="w-full">
            {/** first page */}
            {page === 1 && (
              <FirstPage
                {...{
                  details,
                  social,
                  primaryColor,
                  secondaryColor,
                  tertiaryColor,
                }}
              />
            )}
            {/** second page */}
            {page === 2 && (
              <SecondPage
                {...{ milestones, primaryColor, secondaryColor, tertiaryColor, show_image_milestone }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
