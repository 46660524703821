
const HeroFiverrIcon = ({ className = "" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"  
    viewBox="0 0 24 24" width="38px" height="38px">
      <path d="M20.112 10.776v-.738h-1.401l-.616-.011c-.526 0-.886.346-1.027.749v-.738h-1.568v4.787h1.568v-1.026V12.21c0-.477.387-.864.864-.864h.611v3.48h1.568v-1.026V12.21c0-.477.387-.864.864-.864h.779v-1.308l-.616-.011C20.613 10.027 20.254 10.374 20.112 10.776zM8.952 10.138L8.111 12.905 7.269 10.138 5.701 10.138 7.405 14.925 7.496 14.925 8.725 14.925 8.817 14.925 10.52 10.138zM2.544 10.138V9.819c0-.279.227-.506.506-.506h.99V8H2.68C1.739 8 .976 8.763.976 9.704v.434H.059v1.269h.917v3.518h1.568v-3.518H4.04v3.518h1.568v-3.518-1.269H4.04 2.544zM12.695 10.074h-.191c-1.285 0-2.326 1.041-2.326 2.326v.263c0 1.285 1.041 2.326 2.326 2.326h.424c.88 0 1.633-.543 1.942-1.312l-1.383-.404c0 0-.166.474-.742.474-.651 0-.997-.357-1.042-.761h0v-.002c-.003-.029-.005-.059-.005-.089h1.769 1.555V12.4C15.021 11.115 13.98 10.074 12.695 10.074zM11.699 11.972c.019-.43.373-.774.808-.774h.15c.435 0 .789.343.808.774H11.699zM23.025 13.086c-.539 0-.975.437-.975.975 0 .539.437.975.975.975.539 0 .975-.437.975-.975C24 13.522 23.563 13.086 23.025 13.086z"/></svg>
  );
};

export default HeroFiverrIcon;


