import React, { useMemo } from "react";
import { Profile1 } from "constants/images";
import { HiOutlineCalendarDays } from "react-icons/hi2";
import { socialTemplate } from "utils/constant";
import { renderStartDate, renderEndDate } from "../../resume";
import moment from "moment";
import _ from "lodash";
import MilestoneTG from "./milestone";
import { personalDateFormat } from "utils/utils";


export default function TemplateThreePageOne(props) {
const {
    active,
    previewImage,
    color = {},
    details = {},
    personal = {},
    social = {},
    primaryColor,
    tertiaryColor,
    milestones = {},
    show_image_milestone = true,
    setTotalPage,
    page = 1,
    profile = {}
  } = props;

  const A4_SIZE = {
    width: "210mm", // 794 px
    height: "297mm", // 1123px
    overflow: "hidden",
    marginRight: "20px",
    heightTOpx:1123
    };

  const headerRef = React.useRef();
  const milestoneRef = React.useRef();
  const paperRef = React.useRef();
  const [mergeMilestone, setMergeMilestone] = React.useState([]);

  React.useEffect(() => {
    let groupAll = groupArraysWithType(arraysWithType);
    setMergeMilestone(groupAll)
    setTotalPage(groupAll.length) 
  }, [milestones]);

  const groupArraysWithType = (arraysWithType) => {
    const result = [];
    let group = [];
    let limit = 6;
  
    arraysWithType.forEach(({ array, type }) => {
      array.forEach((item, index) => {
        if (group.length < limit) {
          group.push({ ...item, type });
        }
        if (group.length === limit) {
          result.push(group);
          group = [];
          limit = 8;
        }
      });
    });
  
    if (group.length > 0) {
      result.push(group);
    }
  
    return result;
  };

  const arraysWithType = [
    { array: milestones?.featured || [], type: 'featured' },
    { array: milestones?.current || [], type: 'current' },
    { array: milestones?.past || [], type: 'past' },
  ];

  const activeColor = useMemo(() => {
    if (color.label === "Voilet + Pink") {
      return tertiaryColor;
    }
    return primaryColor;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  const socMed = useMemo(() => {
    let arrays = Object.entries(social);
    return arrays.map((obj) => {
      let key = obj[0];
      let value = obj[1];
      return { key, value };
    });
  }, [social]);

  return (
    <div className={`w-full template-three`} style={A4_SIZE} ref={paperRef}>
      <div
        className="w-full bg-resumeTempThreePrimary h-full"
        style={{ minHeight: "849px" }}>
        <div className="w-full h-full">
          <div className="w-full h-full grid relative grid-row-auto">
            <div className="w-full h-full px-10">
              {/* page number */}
              <div className="w-full relative">
                <div className="w-6 h-6 bg-resumeTempThreeSecondary template-three-page-number">
                  <p className="text-sm text-resumeTempThreeTextSecondary tracking-resumeThree leading-3.2 font-semibold font-template-three">
                    {page < 10 ? `0${page}` : page}
                  </p>
                </div>
                {/* absolute */}
                <div className="absolute right-1 top-3.7 font-template-three w-24.6">
                  <p
                    className="text-xs text-right leading-4.5"
                    style={{ color: activeColor }}>
                    {(personal.occupations || []).map((val, index) => {
                      const count = personal.occupations.length;
                      if (index >= count - 1 && count !== 1) {
                        return " and " + val;
                      } else {
                        if (index >= count - 2) {
                          return val;
                        } else {
                          return val + ", ";
                        }
                      }
                    })}
                  </p>
                </div>
              </div>
              {/* body */}
              <div className="w-full mt-3.4">
                <div className="w-full flex flex-row space-x-7">
                  {/* left */}
                  <div className={`w-40${!active ? " hidden" : ""}`}>
                  {
                    personal?.profile_image &&
                    <>
                      <div className="w-40 h-40 bg-white rounded-full relative overflow-hidden">
                        {(personal.hasOwnProperty("profile_image") && personal.profile_image) || previewImage ? (
                          <img
                            src={previewImage ?? personal.profile_image}
                            className="w-full h-full object-cover object-center"
                            alt="resume-profile"
                          />
                        ) : (
                          <img
                            alt="milestone-profile"
                            src={Profile1}
                            className="w-full h-full object-cover object-center"
                          />
                        )}
                      </div>
                    </>
                  }
                    {/* content */}
                    <div className="w-full mt-5.4 px-1.4">
                      {details.hasOwnProperty("bio") && details.bio && (
                        <div className="w-full border-bottom-resume-three">
                          {/* title */}
                          <p className="uppercase text-4xs text-resumeTempThreeTextPrimary font-medium tracking-resumeThreeWide">
                            bio
                          </p>
                          {/* content */}
                          <div className="w-full mt-2.5 mb-3">
                            <p className="text-white text-1xs leading-3.4 font-light self-stretch">
                              {details.bio}
                            </p>
                          </div>
                        </div>
                      )}
                      <div className="w-full mt-5">
                        {/* title */}
                        <p className="uppercase text-4xs text-resumeTempThreeTextPrimary font-medium tracking-resumeThreeWide">
                          group information
                        </p>
                        <div className="w-full mt-3 border-bottom-resume-three">
                          <div className="w-full grid gap-y-2 mb-3.5">
                            {/* Formation Date */}
                            {details.hasOwnProperty("birthdate") &&
                              details.birthdate && (
                                <div className="w-full flex flex-row items-center space-x-0.5 text-white">
                                  <div className="w-15.5">
                                    <p className="font-bold text-5xs uppercase leading-1.5">
                                      formation date
                                    </p>
                                  </div>
                                  <div style={{ width: "calc(100% - 64px)" }}>
                                    <p className="font-light text-5xs leading-3.4">
                                      {personalDateFormat(details.birthdate, profile?.dateFormat)}
                                    </p>
                                  </div>
                                </div>
                              )}
                            {/* Formation Location */}
                            {details.hasOwnProperty("birth_location") &&
                              details.birth_location && (
                                <div className="w-full flex flex-row items-center space-x-0.5 text-white">
                                  <div className="w-15.5">
                                    <p className="font-bold text-5xs uppercase leading-1.5">
                                      location
                                    </p>
                                  </div>
                                  <div style={{ width: "calc(100% - 64px)" }}>
                                    <p className="font-light text-5xs leading-3.4">
                                      {details.birth_location}
                                    </p>
                                  </div>
                                </div>
                              )}
                            {/* Members */}
                            {personal.hasOwnProperty("members") &&
                              personal.members.length > 0 && (
                                <div className="w-full flex flex-row items-center space-x-0.5 text-white">
                                  <div className="w-15.5">
                                    <p className="font-bold text-5xs uppercase leading-1.5">
                                      members
                                    </p>
                                  </div>
                                  <div style={{ width: "calc(100% - 64px)" }}>
                                    {
                                      personal.members.map((member, index) => {
                                        return (
                                          <p className="font-light text-5xs leading-3.4"
                                          dangerouslySetInnerHTML={{ __html: member?.mention || member.name }}
                                          >
                                            
                                          </p>
                                        )
                                      })
                                    }
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      {/* end */}
                    </div>
                  </div>
                  {/* right */}
                  {/* <div style={{ width: "calc(100% - 188px)" }}> */}
                  <div ref={headerRef}>
                    <div className="w-full">
                      {/* Name */}
                      <div className={`w-57${!active ? " hidden" : ""}`}>
                        <h1 className="font-template-three text-white text-5.4xl font-medium leading-12">
                          I’m {details.username}
                        </h1>
                      </div>
                      {/* Header Contact Info */}
                      <div className={`w-full mt-3.7 header-contact-info${!active ? " hidden" : ""}`}>
                        <div className="flex flex-row gap-x-1.5 text-white items-center font-medium">
                          {details.hasOwnProperty("phone_number") && details.phone_number && (
                            <div className="flex flex-row items-center text-white">
                              <div className="mr-1 contact-label">
                                <p className="font-bold uppercase leading-1.5">
                                  phone
                                </p>
                              </div>
                              <div className="w-15.5 contact-value">
                                <p className="leading-2">
                                  {personal.phone_number}
                                </p>
                              </div>
                            </div>
                          )}
                          {details.hasOwnProperty("phone_number") &&
                            details.hasOwnProperty("email") && (
                              <span className="text-1xs opacity-30">
                                &#8226;
                              </span>
                            )}
                          {details.hasOwnProperty("email") && (
                            <div className="w-full flex flex-row items-center space-x-0.5 text-white">
                              <div className="mr-1 contact-label">
                                <p className="font-bold uppercase leading-1.5">
                                  email address
                                </p>
                              </div>
                              <div className="w-15.5 contact-value">
                                <p className="leading-2">
                                  {details.email}
                                </p>
                              </div>
                            </div>
                            )}
                        </div>
                      </div>
                      {/* ================Milestones==================== */}
                      <div ref={milestoneRef} className="h-full bg-resumeTempThreePrimary">
                        {
                          mergeMilestone.map((item,index)=>{
                            return (
                              page === (index+1) &&
                              <div key={index}>
                                <MilestoneTG
                                  items={item}
                                  milestones={milestones}
                                  primaryColor={primaryColor}
                                  show_image_milestone={show_image_milestone}
                                  tertiaryColor={tertiaryColor}
                                  activeColor={activeColor}
                                  active={active}
                                  profile={profile}
                                />
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
                  {/* end right */}
                </div>
              </div>
            </div>
            {/* footer */}
            <div className="w-full flex items-end">
              <div className="w-full h-8.5 bg-resumeTempThreeTextPrimary bg-opacity-20 relative">
                <div className="w-full h-full flex flex-row justify-center items-center space-x-3 text-resumeTempThreeTextPrimary">
                  {(socMed || []).map((obj, index) => {
                    const SocialIcon = socialTemplate[obj.key];
                    if (SocialIcon) {
                      return (
                        <div
                          className="flex items-center space-x-1.5"
                          key={index}>
                          <SocialIcon
                            className="text-4xs"
                            style={{ color: activeColor }}
                          />
                          <p className="text-3xs leading-2">{obj.value}</p>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
