import React, { useMemo } from "react";
import { HiOutlineCalendarDays } from "react-icons/hi2";
import { renderStartDate, renderEndDate } from "../../resume";
import { BsExclamationDiamondFill } from "react-icons/bs";

export default function TemplateFour({
  color = {},
  personal_info = {},
  more_info = {},
  social_media = {},
  milestones = {},
  primaryColor,
  secondaryColor,
  tertiaryColor,
  quarternary,
  profile_image_withURL,
}) {
  const activeColor = useMemo(() => {
    if (color.label === "Voilet + Pink") {
      return tertiaryColor;
    }
    return primaryColor;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  return (
    <div className="w-full bg-white py-2 px-4" style={{ height: "400px" }}>
      <div className="flex flex-row items-center border-b border-gray-300 py-2 mb-2">
        <div className="flex-1">
          <h3 className="text-xs font-bold" style={{ color: tertiaryColor }}>
            tedlasso
          </h3>
          <div className="flex flex-wrap flex-row items-center text-resumeTempFourTextColor leading gap-x-1 mb-1">
            {personal_info.hasOwnProperty("name") && personal_info.name && (
              <p className="text-5xs">{personal_info.name}</p>
            )}
            {personal_info.hasOwnProperty("name") &&
              personal_info.hasOwnProperty("gender") && (
                <span className="text-5xs opacity-30">&#8226;</span>
              )}
            {personal_info.hasOwnProperty("gender") && personal_info.gender && (
              <p className="text-5xs">Male</p>
            )}
            {personal_info.hasOwnProperty("gender") &&
              personal_info.hasOwnProperty("pronoun") && (
                <span className="text-5xs opacity-30">&#8226;</span>
              )}
            {personal_info.hasOwnProperty("pronoun") &&
              personal_info.pronoun && (
                <p className="text-5xs">{personal_info.pronoun}</p>
              )}
            {personal_info.hasOwnProperty("pronoun") &&
              personal_info.hasOwnProperty("address") && (
                <span className="text-5xs opacity-30">&#8226;</span>
              )}
            {personal_info.hasOwnProperty("address") &&
              personal_info.address && (
                <p className="text-5xs">{personal_info.address}</p>
              )}
          </div>
        </div>
        {personal_info?.profile_image && (
          <>
            <div className="h-8 w-8 bg-white rounded-md overflow-hidden">
              {personal_info &&
                personal_info.hasOwnProperty("profile_image") &&
                personal_info.profile_image && (
                  <img
                    src={profile_image_withURL || personal_info.profile_image}
                    alt="resume-profile"
                  />
                )}
            </div>
          </>
        )}
      </div>

      <div className="w-full flex flex-col gap-2">
        {milestones.hasOwnProperty("featured") && (
          <div className="flex flex-row gap-x-3">
            <div className="shrink-0 text-resumeTempThreeTextPrimary uppercase font-bold tracking-widest text-xxs">
              <p className="w-10">featured milestones</p>
            </div>
            <div className="flex-1">
              {milestones.featured.map((mf, i) => (
                <div
                  key={`t4-featured-${i}`}
                  className="w-full flex flex-row gap-x-1 mb-2">
                  <div className="w-full gap-x-0.5">
                    <div className="flex flex-row items-center mb-0.5">
                      <div className="flex flex-1 flex-row items-center gap-x-0.5">
                        <h3 className="font-template-three text-resumeTempFourTextColor text-5xs font-bold">
                          My Milestone Title
                        </h3>
                        <div
                          className="text-xxs font-bold "
                          style={{ color: tertiaryColor + "E6" }}>
                          <span
                            className="template-four-padding-skills rounded-sm text-opacity-90"
                            style={{
                              backgroundColor: tertiaryColor + "1F", // opcity 12%
                            }}>
                            {mf.category.name}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center text-xxs text-darkGray gap-1 mb-1">
                        <HiOutlineCalendarDays className="w-1.5 h-1.5 text-darkerGray" />
                        {renderStartDate(mf)}
                        {renderEndDate(mf)}
                      </div>
                    </div>
                    <div className="w-full mb-1">
                      {mf.positions.map((p, i) => (
                        <div
                          key={`t4-current-position-${i}`}
                          className="w-full leading-1 flex flex-row gap-x-1"
                          style={{ color: activeColor }}>
                          <>
                            <p className="text-xxs leading-none font-semibold">
                              <span>{p.position.name}</span>
                              {p.position.description && (
                                <>
                                  <span> - </span>
                                  <span>{p.position.description}</span>
                                </>
                              )}
                              {p.platform && <span>{` (${p.platform})`}</span>}
                            </p>
                            {p.date_note && (
                              <>
                                <span
                                  className="text-xxs leading-none opacity-30"
                                  style={`color: ${activeColor} `}>
                                  &#8226;
                                </span>
                                <p className="text-xxs leading-none font-semibold">
                                  {p.date_note}
                                </p>
                              </>
                            )}
                          </>
                        </div>
                      ))}
                    </div>
                    <div className="w-full text-resumeTempFourTextColor text-opacity-60">
                      <p
                        className="text-xxs font-light self-stretch no-underline"
                        dangerouslySetInnerHTML={{
                          __html: mf.description,
                        }}
                      />
                    </div>
                  </div>
                  {mf.thumbnail && (
                    <div className="shrink-0">
                      <div className="relative h-10 w-10 bg-default rounded overflow-hidden">
                        {Boolean(mf.is_adult_content) && (
                          <div className="absolute matured-content-blured-small">
                            <button className="w-full flex justify-center">
                              <BsExclamationDiamondFill className="text-sm" />
                            </button>
                            <p className="text-xxs leading-2 font-bold">
                              Matured Content
                            </p>
                          </div>
                        )}
                        <img
                          alt="milestones"
                          src={mf.thumbnail.url}
                          className="w-full h-full object-contain object-center"
                        />
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        {milestones.hasOwnProperty("current") && (
          <div className="flex flex-row gap-x-3">
            <div className="shrink-0 text-resumeTempThreeTextPrimary uppercase font-bold tracking-widest text-xxs">
              <p className="w-10">current milestones</p>
            </div>
            <div className="flex-1">
              {milestones.current.map((mc, i) => (
                <div
                  key={`t4-current-${i}`}
                  className="w-full flex flex-row gap-x-1 mb-2">
                  <div className="w-full gap-x-0.5">
                    <div className="flex flex-row items-center mb-0.5">
                      <div className="flex flex-1 flex-row items-center gap-x-0.5">
                        <h3 className="font-template-three text-resumeTempFourTextColor text-5xs font-bold">
                          My Milestone Title
                        </h3>
                        <div
                          className="text-xxs font-bold "
                          style={{ color: tertiaryColor + "E6" }}>
                          <span
                            className="template-four-padding-skills rounded-sm text-opacity-90"
                            style={{
                              backgroundColor: tertiaryColor + "1F", // opcity 12%
                            }}>
                            {mc.category.name}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center text-xxs text-darkGray gap-1 mb-1">
                        <HiOutlineCalendarDays className="w-1.5 h-1.5 text-darkerGray" />
                        {renderStartDate(mc)}
                        {renderEndDate(mc)}
                      </div>
                    </div>
                    <div className="w-full mb-1">
                      {mc.positions.map((p, i) => (
                        <div
                          key={`t4-current-position-${i}`}
                          className="w-full leading-1 flex flex-row gap-x-1"
                          style={{ color: activeColor }}>
                          <>
                            <p className="text-xxs leading-none font-semibold">
                              <span>{p.position.name}</span>
                              {p.position.description && (
                                <>
                                  <span> - </span>
                                  <span>{p.position.description}</span>
                                </>
                              )}
                              {p.platform && <span>{` (${p.platform})`}</span>}
                            </p>
                            {p.date_note && (
                              <>
                                <span
                                  className="text-xxs leading-none opacity-30"
                                  style={`color: ${activeColor} `}>
                                  &#8226;
                                </span>
                                <p className="text-xxs leading-none font-semibold">
                                  {p.date_note}
                                </p>
                              </>
                            )}
                          </>
                        </div>
                      ))}
                    </div>
                    <div className="w-full text-resumeTempFourTextColor text-opacity-60">
                      <p
                        className="text-xxs font-light self-stretch no-underline"
                        dangerouslySetInnerHTML={{
                          __html: mc.description,
                        }}
                      />
                    </div>
                  </div>
                  {mc.thumbnail && (
                    <div className="shrink-0">
                      <div className="relative h-10 w-10 bg-default rounded overflow-hidden">
                        {Boolean(mc.is_adult_content) && (
                          <div className="absolute matured-content-blured-small">
                            <button className="w-full flex justify-center">
                              <BsExclamationDiamondFill className="text-sm" />
                            </button>
                            <p className="text-xxs leading-2 font-bold">
                              Matured Content
                            </p>
                          </div>
                        )}
                        <img
                          alt="milestones"
                          src={mc.thumbnail.url}
                          className="w-full h-full object-contain object-center"
                        />
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        {milestones.hasOwnProperty("past") && (
          <div className="flex flex-row gap-x-3">
            <div className="shrink-0 text-resumeTempThreeTextPrimary uppercase font-bold tracking-widest text-xxs">
              <p className="w-10">past milestones</p>
            </div>
            <div className="flex-1">
              {milestones.past.map((mp, i) => (
                <div
                  key={`t4-past-${i}`}
                  className="w-full flex flex-row gap-x-1 mb-2">
                  <div className="w-full gap-x-0.5">
                    <div className="flex flex-row items-center mb-0.5">
                      <div className="flex flex-1 flex-row items-center gap-x-0.5">
                        <h3 className="font-template-three text-resumeTempFourTextColor text-5xs font-bold">
                          My Milestone Title
                        </h3>
                        <div
                          className="text-xxs font-bold "
                          style={{ color: tertiaryColor + "E6" }}>
                          <span
                            className="template-four-padding-skills rounded-sm text-opacity-90"
                            style={{
                              backgroundColor: tertiaryColor + "1F", // opcity 12%
                            }}>
                            {mp.category.name}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center text-xxs text-darkGray gap-1 mb-1">
                        <HiOutlineCalendarDays className="w-1.5 h-1.5 text-darkerGray" />
                        {renderStartDate(mp)}
                        {renderEndDate(mp)}
                      </div>
                    </div>
                    <div className="w-full mb-1">
                      {mp.positions.map((p, i) => (
                        <div
                          key={`t4-past-position-${i}`}
                          className="w-full leading-1 flex flex-row gap-x-1"
                          style={{ color: activeColor }}>
                          <>
                            <p className="text-xxs leading-none font-semibold">
                              <span>{p.position.name}</span>
                              {p.position.description && (
                                <>
                                  <span> - </span>
                                  <span>{p.position.description}</span>
                                </>
                              )}
                              {p.platform && <span>{` (${p.platform})`}</span>}
                            </p>
                            {p.date_note && (
                              <>
                                <span
                                  className="text-xxs leading-none opacity-30"
                                  style={`color: ${activeColor} `}>
                                  &#8226;
                                </span>
                                <p className="text-xxs leading-none font-semibold">
                                  {p.date_note}
                                </p>
                              </>
                            )}
                          </>
                        </div>
                      ))}
                    </div>
                    <div className="w-full text-resumeTempFourTextColor text-opacity-60">
                      <p
                        className="text-xxs font-light self-stretch no-underline"
                        dangerouslySetInnerHTML={{
                          __html: mp.description,
                        }}
                      />
                    </div>
                  </div>
                  {mp.thumbnail && (
                    <div className="shrink-0">
                      <div className="relative h-10 w-10 bg-default rounded overflow-hidden">
                        {Boolean(mp.is_adult_content) && (
                          <div className="absolute matured-content-blured-small">
                            <button className="w-full flex justify-center">
                              <BsExclamationDiamondFill className="text-sm" />
                            </button>
                            <p className="text-xxs leading-2 font-bold">
                              Matured Content
                            </p>
                          </div>
                        )}
                        <img
                          alt="milestones"
                          src={mp.thumbnail.url}
                          className="w-full h-full object-contain object-center"
                        />
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
