import {
  REFRESH_RESUMES,
	SET_RESUMES,
  ADD_RESUME,
  UPDATE_RESUME
} from './types';

export const refreshResumes = () => {
	return {
    type: REFRESH_RESUMES,
    payload: null,
  };
}
export const setResumes = (params) => {
	return {
    type: SET_RESUMES,
    payload: params,
  };
}

export const addResume = (params) => {
	return {
    type: ADD_RESUME,
    payload: params,
  };
}

export const updateResume = (params) => {
	return {
    type: UPDATE_RESUME,
    payload: params,
  };
}