import React from "react";
import "./styles.css";

const bg = {
  default: {
    active: "bg-lightGreen",
    inactive: "bg-gray",
  },
  plans: {
    active: "bg-lightGreen",
    inactive: "bg-primary",
  },
};

const ToggleField = (
  {
    className = "w-14 h-8",
    autoFocus = false,
    name = "",
    checked = false,
    errorMessage = null,
    icon = "default",
    background = "default",
    hActiveClass = "w-6 h-6 ml-auto mr-1 rounded-full bg-white",
    hInactiveClass = "w-6 h-6 ml-1 rounded-full bg-white",
    ...props // make sure this pops here is for input only
  },
  ref
) => {
  const bgColors = checked ? bg[background]?.active : bg[background]?.inactive;

  return (
    <>
      {errorMessage && (
        <span className="text-red text-xs mb-0.5">* {errorMessage}</span>
      )}
      <label
        className={`${className} rounded-full flex justify-items-start items-center my-1 relative cursor-pointer ${bgColors} ${
          errorMessage && "border border-red"
        }`}>
        <input
          type="checkbox"
          className="hidden"
          defaultChecked={checked}
          name={name}
          autoFocus={autoFocus}
          ref={ref}
          {...props}
        />
        <span className={checked ? hActiveClass : hInactiveClass} />
      </label>
    </>
  );
};

const Toggle = React.forwardRef(ToggleField);

export default Toggle;
