export const ORGANIZE_BY = [
  // {
  //   label: "Date",
  //   value: "date",
  // },
  {
    label: "Job/Role",
    value: "job_role",
  },{
    label: "Date - Newest to oldest",
    value: "newest_to_oldest",
  },{
    label: "Date - Oldest to newest",
    value: "oldest_to_newest",
  },
];

export const DATE_NEWEST_TO_OLDEST = 'Date - Newest to oldest';
export const DATE_OLDEST_TO_NEWEST = 'Date - Oldest to newest';
