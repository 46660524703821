import React, { useState, useMemo } from "react";
import Button from "components/button/button";
import Input from "components/input/input";
import TextArea from "components/textarea/textarea";
import EnvelopIconSVG from "assets/svg/envelop-icon";
import PDFColoredIconSVG from "assets/svg/pdf-icon-colored";
import Divider from "components/divider/divider";
import DownloadSquareIconSVG from "assets/svg/download-square-icon";
import { copyTextToClipboard } from "utils/utils";
import CopyIconSVG from "assets/svg/copy-icon-svg";
import LoadingIndicator from "assets/svg/loading-indicator";
import toast from "react-hot-toast";
import PopInfo from "components/info-popup/pop-info";

export default function ShareDownloadPanel({
  userData,
  id,
  isSubmit,
  onDone,
  onBack,
}) {
  const { http } = global.services;
  const [isCopied, setIsCopied] = useState(false);
  const [state, setState] = useState({
    user_id: null,
    fileName: "",
    email: "",
    message: "",
    isSubmit: false,
  });
  const link = `${process.env.REACT_APP_API_DOMAIN}/resume/${id}`;

  const handleCopyClick = (copyText) => {
    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const download = () => {
    window.open(`${link}/download`, "_blank");
  };

  const sendResume = async () => {
    setState((prev) => ({ ...prev, isSubmit: true }));
    try {
      const { data } = await http.post(`public/resumes/${id}/mail`, state);
      toast.success(data.message);
    } catch (err) {
      console.log(err);
    } finally {
      setState((prev) => ({ ...prev, isSubmit: false }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useMemo(() => {
    setState((prev) => ({
      ...prev,
      user_id: userData.user.id,
      fileName: `${userData.user.username} Resume.pdf`,
      email: userData.user.email,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, id]);

  return (
    <div className="w-full h-full border-r border-resumeBorder">
      <div
        className="w-full overflow-y-auto overflow-x-hidden custom-scroll"
        style={{ height: "calc(100vh - 174px)" }}>
        <div className="w-full px-2 2sm:px-15.5 py-10.6">
          <div className="w-full flex flex-row gap-x-2 items-center">
            <h1 className="text-lightBlack text-xl font-semibold">
              Share Resume
            </h1>
            <PopInfo containerClass="relative" type="share_resume" />
          </div>
          <div className="w-full grid gap-4">
            <Input
              outsideLabel="Send to"
              inputContainerClass="w-full"
              defaultValue={state.email}
              name="sendTo"
              placeholder="Send To"
              outsideLabelClass="text-sm font-bold text-darkerGray"
              noLabelPaddingY="1rem"
              type="text"
              onChange={handleInputChange}
            />
            <TextArea
              formContainer="w-full"
              label="Message"
              defaultValue={state.message}
              name="message"
              inputClass="p-4.5 text-base leading-6 text-lightBlack"
              rows={4}
              type="text"
              onChange={handleInputChange}
            />
            <div className="w-full grid grid-cols-1 2sm:grid-cols-2 gap-3">
              <Button
                iconPosition="left"
                icon={<PDFColoredIconSVG />}
                buttonName={state.fileName}
                buttonTextClass="text-base leading-6 text-lightBlack flex justify-center gap-x-2.5 items-center"
                buttonRadius="6px"
                buttonClass="bg-thBg w-full py-4"
                onClick={() => console.log("selected")}
              />
              <Button
                iconPosition="left"
                buttonName="Submit Resume"
                disabled={state.isSubmit}
                buttonTextClass="text-base font-semibold text-white flex justify-center gap-x-2.5 items-center"
                buttonRadius="6px"
                buttonClass="bg-lightBlack w-full py-4"
                onClick={sendResume}
                icon={
                  state.isSubmit ? (
                    <LoadingIndicator className="text-white w-5 h-5" />
                  ) : (
                    <EnvelopIconSVG />
                  )
                }
              />
            </div>
          </div>
          <div className="w-full mt-9">
            <div className="w-full grid gap-y-12">
              <Divider text="or" />
              <div className="w-full">
                <div className="w-full">
                  <h1 className="text-lightBlack text-xl font-semibold">
                    Get Resume Link
                  </h1>
                  <p className="mt-2 text-darkerGray">
                    Share your resume with anyone with this link
                  </p>
                </div>
                <div className="w-full mt-6">
                  <div className="flex flex-col 2sm:flex-row gap-2">
                    <Input
                      inputContainerClass="w-full"
                      defaultValue={link}
                      name="link"
                      placeholder="Resume Link"
                      outsideLabelClass="text-sm font-bold text-darkerGray"
                      noLabelPaddingY="1rem"
                      type="text"
                      readOnly={true}
                    />
                    <Button
                      iconPosition="left"
                      icon={!isCopied ? <CopyIconSVG /> : null}
                      buttonName={isCopied ? "Copied" : "Copy Link"}
                      buttonTextClass="text-base font-semibold text-primary flex justify-center gap-x-2.5 items-center"
                      buttonRadius="6px"
                      buttonClass="bg-white border-2 border-primary w-full"
                      onClick={() => handleCopyClick(link)}
                    />
                  </div>
                </div>
              </div>
              <Divider text="or" />
              <div className="w-full grid gap-y-6">
                <h1 className="text-lightBlack text-md 2sm:text-xl font-semibold">
                  Download Resume
                </h1>
                <Button
                  iconPosition="left"
                  icon={<DownloadSquareIconSVG />}
                  buttonName="Download in PDF"
                  buttonTextClass="text-md 2sm:text-base font-semibold text-lightBlack flex justify-center gap-x-2.5 items-center"
                  buttonRadius="6px"
                  buttonClass="bg-white border-2 border-lightBlack w-full py-5.4"
                  onClick={download}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* footer */}
      <div className="w-full py-4 px-6 border-t border-resumeBorder">
        <div className="w-full flex justify-center 2sm:justify-end space-x-5">
          <Button
            disabled={isSubmit || state.isSubmit}
            buttonName="Back"
            buttonTextClass="text-sm font-semibold text-lightBlack"
            buttonRadius="6px"
            buttonClass="bg-thBg px-12 py-3 2sm:px-17.5 2sm:py-5"
            onClick={onBack}
          />
          <Button
            buttonName="Done"
            disabled={isSubmit || state.isSubmit}
            buttonTextClass="text-sm font-semibold text-white"
            buttonRadius="6px"
            buttonClass="relative bg-primary px-12 py-3 2sm:px-17.5 2sm:py-5"
            onClick={onDone}
            icon={
              <div className="absolute right-3 bottom-0 top-0 flex items-center">
                {isSubmit && (
                  <LoadingIndicator className="text-white w-5 h-5" />
                )}
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
}
