import React from "react";
import ReactPlayer from "react-player";
import AlertIconSVG from "assets/svg/alert-info-icon-sm-svg";

const ImageUploadComponent = (
  {
    id = "image-upload",
    label,
    className = "absolute right-2 top-2 w-9 h-9 bg-black bg-opacity-50 rounded-md flex items-center justify-center",
    wrapperClass = "relative w-30 h-30 bg-thBg rounded-md",
    labelClass = "text-sm font-bold text-darkerGray",
    icon = null,
    innerLabel = "",
    image = null,
    iconOnly = false,
    iconClass = "pr-3 flex items-center",
    previewVideo = null,
    onChange = () => {},
    error,
    ...props
  },
  ref
) => {
  return (
    <div className={iconOnly ? iconClass : "w-full mb-6 rounded-md"}>
      {label && (
        <label htmlFor={id} className={`${labelClass}`}>
          {label}
        </label>
      )}
      <div className={wrapperClass + `${label && " mt-3"}`}>
        {image && (
          <>
            {previewVideo ? (
              <ReactPlayer
                url={previewVideo}
                controls={false}
                width="100%"
                height="100%"
              />
            ) : (
              <img
                src={image}
                alt="milestone-profile"
                className="w-full h-full rounded-md object-cover"
              />
            )}
          </>
        )}
        <label htmlFor={id} className={"cursor-pointer " + className}>
          {icon && icon}
          {innerLabel}
        </label>
        {/* {removable && ( */}
        <input
          ref={ref}
          type="file"
          id={id}
          name={id}
          onChange={onChange}
          {...props}
        />
      </div>
      {error && (
        <p className="input-error-message mt-2 flex">
          <AlertIconSVG /> {error}
        </p>
      )}
    </div>
  );
};

const ImageUpload = React.forwardRef(ImageUploadComponent);

export default ImageUpload;
