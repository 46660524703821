import React, {
  useState,
  useLayoutEffect,
  lazy,
  useContext,
  useRef,
  Suspense,
} from "react";
import Input from "components/input/input";
import toast from "react-hot-toast";
import Button from "components/button/button";
import { Link, useHistory } from "react-router-dom";
import userActions from "redux/modules/user/actions";
import { connect } from "react-redux";
import { RECAPTCHA_KEY } from "configs/api";
import LoadingIndicator from "assets/svg/loading-indicator";
import Layout from "layouts/layout";
import { randomExtension } from "utils/utils";
import { gsap } from "gsap";
import { SubscriptionContext } from "context/subscription";
import ReCAPTCHA from "react-google-recaptcha";
import Modal from "components/global/modal";
import { FaTimes } from "react-icons/fa";

const LogoComponent = lazy(() => import("components/logo/logo"));

const Login = ({ setUserData }) => {
  const { getSubscription } = useContext(SubscriptionContext);
  const loginPageContainer = useRef(null);
  const history = useHistory();
  const { http, localstorage } = global.services;
  const [recaptcha, setRecaptcha] = useState(null);
  const [recapthcaError, setRecapthcaError] = useState(null);
  const [state, setState] = useState({
    email: "",
    password: "",
    showPassword: false,
    loading: false,
    validationErrors: null,
    recaptchaToken: "",
  });
  const [showSuspended, setShowSuspended] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
      validationErrors: { ...state.validationErrors, [name]: null },
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (RECAPTCHA_KEY) {
      if (!recaptcha) {
        return setRecapthcaError("Recaptcha is required.");
      }

      state.recaptchaToken = recaptcha;
    }

    setState((prev) => ({ ...prev, loading: true }));

    try {
      const req = await http.post("auth/login", state);
      const userData = req.data;
      const extension = randomExtension();
      if (userData.user.role !== "admin") {
        localstorage.set("user_data", JSON.stringify(userData));
        await getSubscription();
        await logOnline(userData);
        await getVerification(userData);
        return history.replace(
          `/${userData.user.username}-${extension}/profile/${userData.profile.id}`
        );
      } else {
        toast.error("This site is only accessible by customer users");
      }
    } catch (error) {
      console.log(error.data.message);
      if (error.status === 422)
        return setState((prev) => ({ ...prev, validationErrors: error.data }));

      if (error.data.message === "Suspended Account")
        return setShowSuspended(true);

      toast.error(error.data.message || "It appears that something went wrong");

      if (error.data.recaptchaError) window.grecaptcha.reset();
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  const getVerification = async (userData) => {
    try {
      const { data } = await http.get("public/user-valid-ids/verification", {
        user_id: userData.user.id,
      });
      let updatedData = { ...userData, account_verified: data };
      setUserData(updatedData);
    } catch (error) {
      console.log(error);
    }
  };

  const logOnline = async (userData) => {
    let profileId = userData.profile.id;
    try {
      let { data } = await http.post(`public/profiles/${profileId}/log-online`);
      const profile = data.data;
      setUserData({
        ...userData,
        profile,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useLayoutEffect(() => {
    if (loginPageContainer) {
      gsap
        .fromTo(
          ".signin-title",
          {
            y: -100,
            opacity: 0,
          },
          {
            y: 0,
            opacity: 1,
          }
        )
        .duration(0.7);
      gsap
        .fromTo(
          ".signin-form",
          {
            x: 100,
            opacity: 0,
          },
          {
            x: 0,
            opacity: 1,
          }
        )
        .duration(0.7);
      gsap
        .fromTo(
          ".create-milestone",
          {
            y: 100,
            opacity: 0,
          },
          {
            y: 0,
            opacity: 1,
          }
        )
        .duration(0.7);

      gsap
        .fromTo(
          ".recaptcha-v3",
          {
            y: 0,
            opacity: 0,
          },
          {
            y: 0,
            opacity: 1,
          }
        )
        .duration(0.7);
    }
  }, [loginPageContainer]);

  return (
    <Layout title="Login">
      {
        showSuspended &&
          <Modal
            width="7sm:w-96"
            contentClassName="rounded-md overflow-auto custom-scroll"
            wrapperClassName="overflow-visible shadow-none"
          >
            <div className="w-full">
              <div className="flex flex-row justify-end items-baseline mt-4">
                <button onClick={() => setShowSuspended(false)} className="mr-4">
                  <FaTimes className="text-xl" />
                </button>
              </div>
            </div>

            <div className="w-full text-center">
              <h1 className="text-lg font-semibold">Your account has been suspended!</h1>
              <p className="leading-6 px-4 py-2">
                <span>If you have any questions, reach us at </span><strong class="contact"><a href="mailto:support@milestones.com">support@milestones.com</a></strong><span> or call at </span><strong class="contact"><a href="tel:0412356789">0412356789</a></strong>
              </p>
              <Button
                buttonName="Done"
                buttonClass="bg-thBg py-4 px-14 my-6"
                buttonTextClass="text-black text-sm font-semibold"
                onClick={() => setShowSuspended(false)}
              />
            </div>
          </Modal>
      }

      <div className="main-page-wrapper" ref={loginPageContainer}>
        <div className="login-container">
          <Suspense fallback={<></>}>
            <LogoComponent />
          </Suspense>
          <div className="w-full">
            <div className="signin-form form-container">
              <h2 className="signin-title form-column-title pb-2 3sm:pb-8">Sign In</h2>
              <form
                className="form-column-container"
                id="login-page-component"
                onSubmit={handleLogin}>
                <Input
                  inputContainerClass="form-input-container"
                  value={state.email}
                  autoFocus={true}
                  name="email"
                  outsideLabel="E-mail Address"
                  outsideLabelClass="text-xs 1xs:text-sm font-bold text-darkerGray"
                  noLabelPaddingY="1rem"
                  type="email"
                  onChange={handleInputChange}
                  errorMessage={state.validationErrors?.email}
                />
                <Input
                  inputContainerClass="form-input-container"
                  value={state.password}
                  outsideLabel="Password"
                  outsideLabelClass="text-xs 1xs:text-sm font-bold text-darkerGray"
                  noLabelPaddingY="1rem"
                  name="password"
                  masked={true}
                  visible={state.showPassword}
                  onChangeVisible={() =>
                    setState((prev) => ({
                      ...prev,
                      showPassword: !prev.showPassword,
                    }))
                  }
                  type={state.showPassword ? "text" : "password"}
                  onChange={handleInputChange}
                  errorMessage={state.validationErrors?.password}
                />
                <div className="w-full my-5 recaptcha-v3" id="recaptcha-v3">
                  <div style={{ minHeight: 78 }}>
                    {RECAPTCHA_KEY && (
                      <>
                        <ReCAPTCHA
                          sitekey={RECAPTCHA_KEY}
                          className="recaptcha-container"
                          onChange={(value) => setRecaptcha(value)}
                        />
                        {recapthcaError && (
                          <p className="text-red text-xs mt-1">
                            {recapthcaError}
                          </p>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <Button
                  buttonName="Sign In"
                  disabled={state.loading}
                  buttonTextClass="text-xs 1xs:text-sm text-white font-semibold"
                  buttonRadius="6px"
                  buttonClass="relative bg-primary py-3.5"
                  buttonWidth="100%"
                  type="submit"
                  icon={
                    <div className="absolute right-3 bottom-0 top-0 flex items-center">
                      {state.loading && (
                        <LoadingIndicator className="text-white w-5 h-5" />
                      )}
                    </div>
                  }
                />
                <div className="py-5 flex justify-center mb-5">
                  <Link
                    to={"/forgot-password"}
                    className="forgot-password-link">
                    Forgot password?
                  </Link>
                </div>
                {/* footer */}
                <div className="border-t border-gray text-sm font-semibold text-lightBlack py-5 flex justify-center">
                  <div className="create-milestone md:w-full md:flex md:space-x-2 md:justify-center md:items-center">
                    <p className="text-center">Not yet registered?</p>
                    <Link to="/sign-up" className="text-lightGreen">
                      Create A Milestones Page Account
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (params) => {
      dispatch(userActions.setUserData(params));
    },
  };
};

export default connect(null, mapDispatchToProps)(Login);
