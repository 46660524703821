const MessageSendIconSVG = ({ className = "", opacity = "0.5" }) => {
  return (
    <svg
      width="24"
      height="24"
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={opacity}>
        <path
          d="M21.1298 11.3586L3.61382 2.10859C3.32382 1.95659 2.97382 2.00659 2.74082 2.23459C2.51082 2.46059 2.44782 2.81159 2.59082 3.10259L6.95282 12.0226L2.59082 20.9426C2.44782 21.2346 2.51082 21.5856 2.74082 21.8106C2.88582 21.9506 3.07382 22.0226 3.26382 22.0226C3.38382 22.0226 3.50382 21.9946 3.61382 21.9356L21.1308 12.6856C21.3758 12.5556 21.5308 12.2996 21.5308 12.0216C21.5308 11.7436 21.3758 11.4896 21.1308 11.3596L21.1298 11.3586ZM4.94782 4.51059L17.7518 11.2726H8.25482L4.94782 4.51259V4.51059ZM8.25482 12.7706H17.7528L4.94782 19.5356L8.25482 12.7726V12.7706Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default MessageSendIconSVG;
