export default function TextArea({
  label = "",
  labelClass = "text-sm font-semibold text-darkerGray",
  rows = 4,
  inputClass = "px-4 py-5",
  errorMessage = null,
  formContainer = "form-input-container",
  ...props // make sure this pops here is for input only
}) {
  return (
    <div className={formContainer}>
      {label && (
        <label htmlFor={label} className={labelClass}>
          {label}
        </label>
      )}
      <textarea
        className={`w-full ${inputClass} ${label && "mt-2"}`}
        type="text"
        rows={rows}
        style={{
          borderColor: errorMessage && "red",
        }}
        {...props}
      />
      {errorMessage && (
        <p className="input-error-message mt-2 -mb-1">* {errorMessage}</p>
      )}
    </div>
  );
}
