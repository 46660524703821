import React from "react";
import DetailedViewIconSVG from "assets/svg/detailed-view-icon";
import ListViewIconSVG from "assets/svg/list-view-icon";
import ColumnViewIconSVG from "assets/svg/column-view-icon";

const GroupButton = ({ active = "detailed", onClick = () => {} }) => {
  return (
    <div className="flex bg-light-gray rounded-md 3md:p-1.5">
      <button
        className={`py-3 px-3 3md:py-3.5 3md:px-4 bg-${
          active === "detailed" ? "white" : "listView"
        } rounded-md`}
        onClick={() => onClick("detailed")}>
        <DetailedViewIconSVG
          className="w-3.5 h-3.5 3md:w-5 3md:h-5"
          fill={active === "detailed" ? "black" : "#9A9A9A"}
        />
      </button>
      <button
        className={`py-3 px-3 3md:py-3.5 3md:px-4 bg-${
          active === "list" ? "white" : "listView"
        } rounded-md`}
        onClick={() => onClick("list")}>
        <ListViewIconSVG
          className="w-3.5 h-3.5 3md:w-5 3md:h-5"
          fill={active === "list" ? "black" : "#9A9A9A"}
        />
      </button>
      <button
        className={`py-3 px-3 3md:py-3.5 3md:px-4 bg-${
          active === "column" ? "white" : "listView"
        } rounded-md hidden 3md:block`}
        onClick={() => onClick("column")}>
        <ColumnViewIconSVG
          className="w-3.5 h-3.5 3md:w-5 3md:h-5"
          fill={active === "column" ? "black" : "#9A9A9A"}
        />
      </button>
    </div>
  );
};

export default GroupButton;
