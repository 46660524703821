const CardIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="24"
      height="17"
      viewBox="0 0 24 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M21.4771 0.375H1.97705C1.54607 0.375 1.13275 0.546205 0.828002 0.850951C0.523256 1.1557 0.352051 1.56902 0.352051 2V15C0.352051 15.431 0.523256 15.8443 0.828002 16.149C1.13275 16.4538 1.54607 16.625 1.97705 16.625H21.4771C21.908 16.625 22.3214 16.4538 22.6261 16.149C22.9308 15.8443 23.1021 15.431 23.1021 15V2C23.1021 1.56902 22.9308 1.1557 22.6261 0.850951C22.3214 0.546205 21.908 0.375 21.4771 0.375ZM12.5396 13.375H10.9146C10.6991 13.375 10.4924 13.2894 10.34 13.137C10.1877 12.9847 10.1021 12.778 10.1021 12.5625C10.1021 12.347 10.1877 12.1403 10.34 11.988C10.4924 11.8356 10.6991 11.75 10.9146 11.75H12.5396C12.755 11.75 12.9617 11.8356 13.1141 11.988C13.2664 12.1403 13.3521 12.347 13.3521 12.5625C13.3521 12.778 13.2664 12.9847 13.1141 13.137C12.9617 13.2894 12.755 13.375 12.5396 13.375ZM19.0396 13.375H15.7896C15.5741 13.375 15.3674 13.2894 15.215 13.137C15.0627 12.9847 14.9771 12.778 14.9771 12.5625C14.9771 12.347 15.0627 12.1403 15.215 11.988C15.3674 11.8356 15.5741 11.75 15.7896 11.75H19.0396C19.255 11.75 19.4617 11.8356 19.6141 11.988C19.7664 12.1403 19.8521 12.347 19.8521 12.5625C19.8521 12.778 19.7664 12.9847 19.6141 13.137C19.4617 13.2894 19.255 13.375 19.0396 13.375ZM1.97705 4.4375V2H21.4771V4.4375H1.97705Z"
        fill="white"
      />
    </svg>
  );
};

export default CardIconSVG;